import axios from "axios";
import { BASE_URL } from "../appconfig";

const useRefreshUserData = () => {

  const refreshUserData = async() => {
      try {
        const headers = {
          withCredentials: true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const response = await axios.get(`${BASE_URL}/Organization/User`, {
          headers,
        });
        
        return response;
      } catch (error) {
        console.error("Error fetching user data: ", error);
        return error.response ? error.response : null;
      }
  }

  return refreshUserData
};

export default useRefreshUserData;
