import React, { useEffect, useState } from "react";
import Spinner from "../../../common/Spinner";

import houseHoldIcon from "../../../../assets/houseHold.svg";
import businessIcon from "../../../../assets/ic_baseline-business.svg";
import blackHouseHoldIcon from "../../../../assets/black-household.svg";
import whiteBuisnessIcon from "../../../../assets/white-buisness.svg";

import useApi from "../../../../custom-hooks/useApi";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../../appconfig";
import { useSelector } from "react-redux";

const ConversionPopUp = ({ toggle, change, campaignData }) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [teamMemberOption, setTeamMemberOption] = useState(null);
  const [clientOption, setClientOption] = useState(null);
  const [applicationOption, setApplicationOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchClientTerm, setSearchClientTerm] = useState("");
  const [searchApplicationTerm, setSearchApplicationTerm] = useState("");
  const [searchClientInput, setSearchClientInput] = useState("");
  const [searchApplicationInput, setSearchApplicationInput] = useState("");
  const [associates, setAssociates] = useState([]);
  const [clients, setClients] = useState([]);
  const [applications, setApplications] = useState([]);
  const [formData, setFormData] = useState({
    type: type,
    AgentID: "",
    ClientName:"",
  });
  const [errors, setErrors] = useState({});

  const [selectedAssociate, setSelectedAssociate] = useState({
    AgentName: "",
    AgentID: "",
  });
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedApplication, setSelectedApplication] = useState({});
  const user = useSelector((state) => state.auth.user);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearchDebounced = debounce((term) => {
    setSearchTerm(term);
  }, 1000);
  const handleClientSearchDebounced = debounce((term) => {
    setSearchClientTerm(term);
  }, 1000);
  const handleApplicationSearchDebounced = debounce((term) => {
    setSearchApplicationTerm(term);
  }, 1000);

  const handleSearchChange = async (e) => {
    let term = e.target.value.toLowerCase();
    setSearchInput(term);
    setErrors((prevData) => {
      const newErrors = { ...prevData };
      delete newErrors.Associate;
      return newErrors;
    });
    if (term.length < 3) {
      setSearchTerm("");
      setAssociates([]);
    } else {
      handleSearchDebounced(term);
    }
  };
  const handleSearchClientChange = async (e) => {
    let term = e.target.value.toLowerCase();
    setSearchClientInput(term);
    setErrors((prevData) => {
      const newErrors = { ...prevData };
      delete newErrors.Client;
      return newErrors;
    });
    if (term.length < 3) {
      setSearchClientTerm("");
      setClients([]);
    } else {
      handleClientSearchDebounced(term);
    }
  };
  const handleSearchApplicationChange = async (e) => {
    let term = e.target.value.toLowerCase();
    setSearchApplicationInput(term);
    setErrors((prevData) => {
      const newErrors = { ...prevData };
      delete newErrors.Application;
      return newErrors;
    });
    if (term.length < 3) {
      setSearchApplicationTerm("");
      setApplications([]);
    } else {
      handleApplicationSearchDebounced(term);
    }
  };

  useEffect(() => {
    if (searchTerm.length >= 3) {
      fetchData();
    } else {
      setAssociates([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (searchClientTerm.length >= 3) {
      fetchClientsData();
    }
  }, [searchClientTerm]);

  useEffect(() => {
    if (searchApplicationTerm.length >= 3) {
      fetchApplicationData();
    } else {
      setSearchApplicationTerm("");
      setApplications([]);
    }
  }, [searchApplicationTerm]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let additionalQueries = {
        pageNumber: 1,
        pageSize: 100,
        sortfield: "AgentName",
        sortOrder: "asc",
      };

      let queryParams = {
        SearchTerm: searchTerm,
      };

      const response = await api.get("/Organization/Agent_Details", {
        params: { ...additionalQueries, ...queryParams },
      });

      const responseData = response.data;
      setAssociates(responseData?.organizations);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      let message = error.response.data.message || "Error fetching data!";
      console.log(message);
      setLoading(false);
    }
  };

  const fetchClientsData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${BASE_URL}/Account`, {
        params: {
          pageno: 1,
          SearchTerm: searchClientTerm,
        },
      });
      const responseData = response.data.data;
      setClients(responseData?.accounts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      let message = error.response.data.message || "Error fetching data!";
      console.log(message);
      setLoading(false);
    }
  };

  const fetchApplicationData = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${BASE_URL}/Application/ApplicationData?page=${1}&pageSize=10&searchQuery=${searchApplicationTerm}`, {
      });
      const responseData = response.data;
      setApplications(responseData?.Entity);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      let message = error.response.data.message || "Error fetching data!";
      console.log(message);
      setLoading(false);
    }
  };


  const handleSelectAssociate = (associate) => {
    setSelectedAssociate(associate);
    setSearchInput(`${associate.AgentName} (${associate.AgentID})`);
    setAssociates([]);
  };
  const handleSelectClient = (client) => {
    setSelectedClient(client);
    setSearchClientInput(`${client.FirstName} ${client.LastName}`);
    setClients([]);
  };
  const handleSelectApplication = (application) => {
    setSelectedApplication(application);
    setSearchApplicationInput(`${application.Application_Name}`);
    setApplications([]);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevData) => ({ ...prevData, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleTypeSelection = (selectedType) => {
    setErrors({});
    setTeamMemberOption(null);
    setClientOption(null);
    setApplicationOption(null);
    setType(selectedType);
    console.log(teamMemberOption, clientOption, applicationOption, selectedType);
  };

  const handlePartnerType = (selectedType) => {
    setTeamMemberOption(selectedType);
    if (selectedType === "existing") {
      formData.AgentID = "";
    } else if (selectedType === "new") {
      setSearchInput("");
      setSearchTerm("");
      setSelectedAssociate({
        AgentName: "",
        AgentID: "",
      });
    }
  };

  const handleClientType = (selectedType) => {
    setClientOption(selectedType);
    if (selectedType === "existing") {
      formData.ClientName = "";
    } else if (selectedType === "new") {
      setSearchClientInput("");
      setSearchClientTerm("");
      setSelectedClient({});
    }
  }

  const handleApplicationType = (selectedType) => {
    setApplicationOption(selectedType);
    if (selectedType === "existing") {
      formData.ClientName = "";
    } else if (selectedType === "new") {
      setSearchApplicationInput("");
      setSearchApplicationTerm("");
      setSelectedApplication({});
    }
  }

  const createNewTeamMember = async (payload) => {
    try {
      const response = await api.post(`/Organization/Agent_Details`, payload);
      if (response.status === 200 && response.data.success) {
        toast.success("Lead added to business partner", {
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred.",
        {
          theme: "dark",
        }
      );
    }
  };
  const updateTeamMember = async (payload) => {
    try {
      const response = await api.put(
        `${BASE_URL}/Organization/Agent_Details/${selectedAssociate.AgentID}`,
        payload
      );
      if (response.status === 200 && response.data.success) {
        toast.success("Lead added to business partner", {
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred.",
        {
          theme: "dark",
        }
      );
    }
  };
  const convertClient = async (payload) => {
    try {
      const response = await api.post(
        `${BASE_URL}/Account/Convert/Client`,
        payload
      );
      if (response?.status === 200 && response.data.data) {
        toast.success("Lead added to client", {
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred.",
        {
          theme: "dark",
        }
      );
    }
  };
  const updateClient = async (payload) => {
    try {
      const response = await api.put(
        `${BASE_URL}/Account/Convert/${selectedClient.AccountID}`,
        payload
      );
      if (response?.status === 200 && response.data.success) {
        toast.success("Lead added to client", {
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred.",
        {
          theme: "dark",
        }
      );
    }
  };
  const convertApplication = async (payload) => {
    try {
      const response = await api.post(
        `${BASE_URL}/Application/Convert`,
        payload
      );
      if (response?.status === 200 && response.data.data) {
        toast.success("Lead added to applications", {
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred.",
        {
          theme: "dark",
        }
      );
    }
  };
  const updateApplication = async (payload) => {
    try {
      const response = await api.put(
        `${BASE_URL}/Application/ConvertApplication/${selectedApplication.Underwriting_ID}`,
        payload
      );
      if (response?.status === 200 && response.data.success) {
        toast.success("Lead added to applications", {
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred.",
        {
          theme: "dark",
        }
      );
    }
  };

  const handleConvert = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!type) {
      newErrors.type = "Please select account type";
    }
    if (!teamMemberOption && !clientOption && !applicationOption) {
      toast.error("Please select an option to convert");
      return;
    }
    if (type === "household") {
      if (teamMemberOption === "existing" && !selectedAssociate.AgentID) {
        newErrors.Associate = "Please select an agent";
      }
      if (teamMemberOption === "new" && !formData.AgentID) {
        newErrors.AgentID = "Please enter agent code";
      }
    }
    if (clientOption === "existing" && !selectedClient.AccountID) {
      newErrors.Client = "Please select the client";
    }
    if (clientOption === "new" && type === "business" && !formData.ClientName) {
      newErrors.ClientName = "Please enter the client name";
    }
    if (applicationOption === "existing" && !selectedApplication.Underwriting_ID) {
      newErrors.Application = "Please select the application";
    }
      setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      return;
    }
  
    setLoading(true);
  
    try {
      if (teamMemberOption === "new" && formData.AgentID) {
        const json = {
          UplineID: user.AgentID,
          FirstName: campaignData.First_Name,
          LastName: campaignData.Last_Name,
          AgentID: formData.AgentID,
          AssociateID: "",
          CountryCode: "+1",
          AMADate: new Date().toISOString().slice(0, 10),
          WorkEmail: campaignData.Email_Address,
          Mobile: campaignData.Mobile_Phone,
          BaseShopID: user.BaseShopID,
          LeadID: campaignData.ID,
        };
        await createNewTeamMember(json);
      }
  
      if (teamMemberOption === "existing" && selectedAssociate.AgentID) {
        const json = {
          FirstName: campaignData.First_Name,
          LastName: campaignData.Last_Name,
          LeadID: campaignData.ID,
        };
        await updateTeamMember(json);
      }

      if (clientOption === "existing" && selectedClient.AccountID) {
        const json = {
          FirstName: campaignData.First_Name,
          LastName: campaignData.Last_Name,
          LeadID: campaignData.ID,
        };
        await updateClient(json);
      }

      if (clientOption === "new") {
        const Parentinfo = {
          ParentAccountName: `${campaignData.Last_Name} ${type}`,
          ContactID: campaignData.ContactID,
        };
        const ClientInfo = {
          AccountTypeID: type === "household" ? 67 : 68,
          FirstName: campaignData.First_Name,
          LastName: campaignData.Last_Name,
          LeadID: campaignData.ID,
          ...(type === "business" && { BusinessName: formData.ClientName }),
        };
        const json = { Parentinfo, ClientInfo, LeadID: campaignData.ID };
        await convertClient(json);
      }
      if (applicationOption === "existing" && selectedApplication.Underwriting_ID) {
        const json = {
          FirstName: campaignData.First_Name,
          LastName: campaignData.Last_Name,
          LeadID: campaignData.ID,
        };
        await updateApplication(json);
      }
      if (applicationOption === "new") {
        const json = {
          NameTitle:`${campaignData.First_Name} ${campaignData.Last_Name}`,
          ContactID: campaignData.ContactID,
          LeadID: campaignData.ID,
        };
        await convertApplication(json);
      }
    } catch (error) {
      console.error("Error during conversion:", error);
    } finally {
      setLoading(false);
      toggle();
      change();
    }
  };
  

  return (
    <div>
      {loading && <Spinner />}
      <div
        className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20"
        style={{ margin: 0 }}
      >
        <div
          className="bg-white px-8 py-4 rounded shadow-md relative"
          style={{ width: "600px" }}
        >
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-black font-inter text-2xl font-medium">
              Convert your lead
            </h2>
            <button
              onClick={toggle}
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <p className="text-neutral-400 font-inter text-sm">
            Converting a lead reduces manual entry by automatically creating or
            updating your lists of business partners, clients, and applications.
          </p>
          {/* <div className="mt-5 flex justify-center"> */}
          <div className="flex space-x-4 mt-5">
            <button
              className={`flex-1 p-4 border rounded-lg w-[267px] ${
                type === "household"
                  ? "bg-[#4871B7] text-white"
                  : "bg-[#E5E7EB]"
              }`}
              onClick={() => handleTypeSelection("household")}
            >
              <div className="flex space-x-2">
                <span className="material-icons">
                  <img
                    className="h-9 w-9"
                    src={
                      type === "household" ? houseHoldIcon : blackHouseHoldIcon
                    }
                    alt="convert-img"
                  />
                </span>
                <span
                  className={`mt-4 text-lg ${
                    type === "household" ? "text-white" : "text-[#4B5563]"
                  }`}
                >
                  Household
                </span>
              </div>
            </button>
            <div className="flex items-center justify-center">
              <span className="text-[#1F2937]">OR</span>
            </div>
            <button
              className={`flex-1 p-4 border rounded-lg ${
                type === "business" ? "bg-[#4871B7] text-white" : "bg-[#E5E7EB]"
              }`}
              onClick={() => handleTypeSelection("business")}
            >
              <div className="flex space-x-2">
                <span className="material-icons">
                  <img
                    className="h-10 w-9"
                    src={type === "business" ? whiteBuisnessIcon : businessIcon}
                    alt="convert-img"
                  />
                </span>
                <span
                  className={`mt-3 text-lg ${
                    type === "business" ? "text-white" : "text-[#4B5563]"
                  }`}
                >
                  Business
                </span>
              </div>
            </button>
          </div>
          <p
            className={`text-red-500 mt-1 text-xs h-4 ${
              errors.type ? "visible" : "invisible"
            }`}
          >
            {errors.type || " "}
          </p>
          {/* </div> */}

          {type === "household" && (
            <div className="mt-2">
              <h4 className="text-sm font-medium mb-2">Team Member</h4>
              <div className="flex space-x-[125px] mb-2">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="existingTeamMember"
                    name="teamMemberOption"
                    value="existing"
                    onChange={(e) => handlePartnerType("existing")}
                    className="mr-2"
                  />
                  <label
                    htmlFor="existingTeamMember"
                    className="text-[13px] text-[#3A4253]  font-semibold"
                  >
                    Existing Team Member
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="radio"
                    id="newTeamMember"
                    name="teamMemberOption"
                    value="new"
                    onChange={() => handlePartnerType("new")}
                    className="mr-2"
                  />
                  <label
                    htmlFor="newTeamMember"
                    className="text-[13px] text-[#3A4253]  font-semibold"
                  >
                    Create New Team Member
                  </label>
                </div>
              </div>
              <div className="flex items-center mb-4">
                <div>
                  <input
                    type="text"
                    placeholder="Search Team Members"
                    className="flex-1 p-2 border rounded bg-[#F3F4F6] placeholder-[#9CA3AF]"
                    onChange={handleSearchChange}
                    value={searchInput}
                    disabled={!teamMemberOption || teamMemberOption === "new"}
                  />
                  {searchInput.length > 0 && <div
                    className="bg-white mt-14 absolute left-0 w-full ml-6"
                    style={{
                      boxShadow: "0px 8px 40px 0px #00000030",
                      maxWidth: "15rem",
                      maxHeight: "60vh",
                      top: "calc(38%)",
                      left: "calc(1%)",
                      overflowY: "auto",
                    }}
                  >
                    {searchInput.length >= 3 && associates.length > 0
                      ? associates.map((item, index) => (
                          <div
                            key={index}
                            className="font-inter flex items-center align-middle bg-dropdown-item cursor-pointer"
                          >
                            <span
                              className="w-full text-black text-base font-normal px-5 py-3 block bg-white hover:bg-[#2BB574]"
                              style={{ zIndex: 1000 }}
                              onClick={() => handleSelectAssociate(item)}
                            >
                              {item?.AgentName} <b>({item?.AgentID})</b>
                            </span>
                          </div>
                        ))
                      : searchTerm.length > 0 &&
                        !selectedAssociate.AgentID && (
                          <div className="px-5 py-3 text-gray-500">
                            No Member found
                          </div>
                        )}
                  </div>}
                  <p
                    className={`text-red-500 mt-1 text-xs h-4 ${
                      errors.Associate ? "visible" : "invisible"
                    }`}
                  >
                    {errors.Associate || " "}
                  </p>
                </div>

                <div className="flex items-center justify-center mx-4 mb-4">
                  <span className="text-gray-400">OR</span>
                </div>
                <div>
                  <input
                    type="text"
                    name="AgentID"
                    placeholder={`Agent ID `}
                    onChange={handleChange}
                    value={formData.AgentID}
                    className=" p-2 border rounded bg-[#F3F4F6] placeholder-[#9CA3AF] "
                    disabled={
                      !teamMemberOption || teamMemberOption === "existing"
                    }
                  />
                  <p
                    className={`text-red-500 mt-1 text-xs h-4 ${
                      errors.AgentID ? "visible" : "invisible"
                    }`}
                  >
                    {errors.AgentID || " "}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="mt-4">
            <h4 className="text-sm font-medium mb-2">Client</h4>
            <div className="flex space-x-[180px] mb-2">
              <div className="flex items-center">
                <input
                  type="radio"
                  id="existingClient"
                  name="clientOption"
                  value="existing"
                  checked={clientOption === "existing"}
                  onChange={(e) => handleClientType("existing")}
                  className="mr-2"
                />
                <label
                  htmlFor="existingClient"
                  className="text-[13px] text-[#3A4253]  font-semibold"
                >
                  Existing Client
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="radio"
                  id="newClient"
                  name="clientOption"
                  value="new"
                  checked={clientOption === "new"}
                  onChange={(e) => handleClientType("new")}
                  className="mr-2"
                />
                <label
                  htmlFor="newClient"
                  className="text-[13px] text-[#3A4253]  font-semibold"
                >
                  Create New Client
                </label>
              </div>
            </div>
            <div className="flex items-center mb-4">
                <div>
                  <input
                    type="text"
                    placeholder="Search Client"
                    className="flex-1 p-2 border rounded bg-[#F3F4F6] placeholder-[#9CA3AF]"
                    onChange={handleSearchClientChange}
                    value={searchClientInput}
                    disabled={!clientOption || clientOption === "new"}
                  />
                  {searchClientInput.length > 0 && (
                    <div
                      className="bg-white mt-14 absolute left-0 w-full ml-6"
                      style={{
                        boxShadow: "0px 8px 40px 0px #00000030",
                        maxWidth: "15rem",
                        maxHeight: "60vh",
                        top: `${type === "household" ? "calc(57.5%)" : "calc(48%)"}`,
                        left: "calc(1%)",
                        overflowY: "auto",
                      }}
                    >
                      {searchClientInput.length >= 3 && clients.length > 0 ? (
                        clients.map((item, index) => (
                          <div
                            key={index}
                            className="font-inter flex items-center align-middle bg-dropdown-item cursor-pointer"
                          >
                            <span
                              className="w-full text-black text-base font-normal px-5 py-3 block bg-white hover:bg-[#2BB574]"
                              style={{ zIndex: 1000 }}
                              onClick={() => handleSelectClient(item)}
                            >
                              {item?.FirstName} {item?.LastName}{" "}
                              {item?.AccountTypeID === 67 ? "household" : "business"}
                            </span>
                          </div>
                        ))
                      ) : (
                        searchClientTerm.length > 0 &&
                        !selectedClient.AccountID && (
                          <div className="px-5 py-3 text-gray-500">No Client found</div>
                        )
                      )}
                    </div>
                  )}
                  <p
                    className={`text-red-500 mt-1 text-xs h-4 ${
                      errors.Client ? "visible" : "invisible"
                    }`}
                  >
                    {errors.Client || " "}
                  </p>
                </div>

                <div className="flex items-center justify-center mx-4 mb-4">
                  <span className="text-gray-400">OR</span>
                </div>
                <div>
                  <input
                    type="text"
                    name="ClientName"
                    placeholder={`${campaignData.Last_Name} ${type ? type : "household"}`}
                    onChange={handleChange}
                    value={formData.ClientName}
                    className="p-2 border rounded bg-[#F3F4F6] placeholder-[#9CA3AF]"
                    disabled={
                      !clientOption || clientOption === "existing"
                    }
                  />
                  <p
                    className={`text-red-500 mt-1 text-xs h-4 ${
                      errors.ClientName ? "visible" : "invisible"
                    }`}
                  >
                    {errors.ClientName || " "}
                  </p>
                </div>
              </div>
          </div>

          <div className="mt-4">
            <h4 className="text-sm font-medium mb-2">Application</h4>
            <div className="flex space-x-[145px] mb-2">
              <div className="flex items-center">
                <input
                  type="radio"
                  id="existingApplication"
                  name="applicationOption"
                  value="existing"
                  checked={applicationOption === "existing"}
                  onChange={(e) => handleApplicationType("existing")}
                  className="mr-2"
                />
                <label
                  htmlFor="existingApplication"
                  className="text-[13px] text-[#3A4253]  font-semibold"
                >
                  Existing Application
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="radio"
                  id="newApplication"
                  name="applicationOption"
                  value="new"
                  checked={applicationOption === "new"}
                  onChange={(e) => handleApplicationType("new")}
                  className="mr-2"
                />
                <label
                  htmlFor="newApplication"
                  className="text-[13px] text-[#3A4253]  font-semibold"
                >
                  {" "}
                  Create New Application
                </label>
              </div>
            </div>
            <div className="flex items-center mb-4">
                <div>
                  <input
                    type="text"
                    placeholder="Search Application"
                    className="flex-1 p-2 border rounded bg-[#F3F4F6] placeholder-[#9CA3AF]"
                    onChange={handleSearchApplicationChange}
                    value={searchApplicationInput}
                    disabled={!applicationOption || applicationOption === "new"}
                  />
                  {searchApplicationInput.length > 0 && <div
                    className="bg-white mt-14 absolute left-0 w-full ml-6"
                    style={{
                      boxShadow: "0px 8px 40px 0px #00000030",
                      maxWidth: "15rem",
                      maxHeight: "60vh",
                      top: `${type === "household" ? "calc(77%)" : "calc(71%)"}`,
                      left: "calc(1%)",
                      overflowY: "auto",
                    }}
                  >
                    {searchApplicationInput.length >= 3 && applications.length > 0
                      ? applications.map((item, index) => (
                          <div
                            key={index}
                            className="font-inter flex items-center align-middle bg-dropdown-item cursor-pointer"
                          >
                           <span
                              className="w-full text-black text-base font-normal px-5 py-3 block bg-white hover:bg-[#2BB574]"
                              style={{ zIndex: 1000 }}
                              onClick={() => handleSelectApplication(item)}
                            >
                              {item?.Application_Name}
                            </span>
                          </div>
                        ))
                      : searchApplicationTerm.length > 0 &&
                        !selectedApplication.Underwriting_ID && (
                          <div className="px-5 py-3 text-gray-500">
                            No application found
                          </div>
                        )}
                  </div>}
                  <p
                    className={`text-red-500 mt-1 text-xs h-4 ${
                      errors.Application ? "visible" : "invisible"
                    }`}
                  >
                    {errors.Application || " "}
                  </p>
                </div>

                <div className="flex items-center justify-center mx-4 mb-4">
                  <span className="text-gray-400">OR</span>
                </div>
                <div>
                  <input
                    type="text"
                    name="ClientName"
                    placeholder={`${campaignData.First_Name} ${campaignData.Last_Name}`}
                    onChange={handleChange}
                    className="p-2 border rounded bg-[#F3F4F6] placeholder-[#9CA3AF]"
                    disabled={true}
                  />
                  <p
                    className={`text-red-500 mt-1 text-xs h-4 ${
                      errors.ClientName ? "visible" : "invisible"
                    }`}
                  >
                    {errors.ClientName || " "}
                  </p>
                </div>
              </div>
          </div>

          <div className="flex space-x-4 mt-6">
            <button
              onClick={toggle}
              className="px-4 py-2 text-[#4871B7] rounded w-1/4"
            >
              Cancel
            </button>
            <button
              onClick={handleConvert}
              style={{
                background:
                  "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                height: "3rem",
              }}
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded w-3/4"
            >
              Convert
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversionPopUp;
