/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useApi from "../../../../../custom-hooks/useApi";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../common/Spinner";
import CampaignActivationRow from "./CampaignActivationRow";
import { fetchRcStatusList } from "../../../../../redux/thunks/commonThunk";
import SignOffDate from "./SignOffDate";

const CampaignActivation = ({ agentRootId }) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState([]);
  const api = useApi();
  const dispatch = useDispatch();
  const rcStatusList = useSelector((state) => state.common.rcStatusList);

  const handleFilterChange = async (e) => {
    let filter = e.target.value;
    setFilter(filter);
  };

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearchDebounced = debounce((term) => {
    setSearchTerm(term);
  }, 1000);

  const handleSearch = async (e) => {
    let term = e.target.value;
    if ((searchTerm.length >= 3 && term.length === 0) || term.length >= 3) {
      handleSearchDebounced(term);
    }
  };

  const fetchAgentCampaignList = async () => {
    setLoading(true);
    try {
      let queryParams = {};
      if (filter !== "0") {
        queryParams["Status"] = filter;
      }
      if (searchTerm.length >= 3) {
        queryParams["SearchName"] = searchTerm;
      }
      const response = await api.get(`/Campaign/OrgCampaign`, {
        params: queryParams,
      });
      if (response.status === 200) {
        setData(response.data.OrgCampaign);
      }
    } catch (error) {
      console.error("Error fetching entity types: ", error);
      if (error.response && error.response.status === 404) {
        setData([]);
      }
    } finally {
      setLoading(false);
    }
  };
  
  const getDefaultStatusId = () => {
    let statusId = "";
    if (rcStatusList.length > 0) {
      const pendingApproval = rcStatusList.find(
        (s) => s.Name.toString().toLowerCase() === "pending approval"
      );
      if (pendingApproval) {
        statusId = pendingApproval.ID;
      }
    }
    return statusId;
  };

  useEffect(() => {
    setFilter(getDefaultStatusId());
  }, [rcStatusList]);

  useEffect(() => {
    dispatch(fetchRcStatusList({ api }));
  }, []);

  useEffect(() => {
    if(rcStatusList && filter) {
      fetchAgentCampaignList();
    }
  }, [searchTerm, filter]);
  
  if (rcStatusList.length === 0) {
    return <Spinner />;
  }
  

  return (
    <>
      {loading && <Spinner />}

      <div className="flex flex-col font-inter bg-white border border-gray-300 rounded-lg mt-4 mx-6">
        <div className="w-full flex py-2  px-6 justify-between items-center mb-5 border-b border-gray-300">
          <h2 className="px-4 text-neutral-500 dark:text-gray-400 font-inter font-medium text-base">
            CAMPAIGN ACTIVATION
          </h2>
          <div className="flex flex-row justify-center items-center gap-2">
            <label
              htmlFor="campaign-activation-list-filter"
              className="text-sm items-center whitespace-nowrap"
            >
              Search by Name
            </label>
            <div className="relative">
              {/* SVG Icon inside Search Bar */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 absolute left-0 top-1/2 transform -translate-y-1/2 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
              <input
                type="text"
                placeholder="Search..."
                style={{
                  borderRadius: "0.5rem",
                  paddingLeft: "2.5rem", // Adjust for icon space
                }}
                className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center gap-2">
            <label
              htmlFor="campaign-activation-list-filter"
              className="text-sm items-center whitespace-nowrap"
            >
              Filter by Status
            </label>
            <select
              className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
              name="Status"
              defaultValue={getDefaultStatusId()}
              required
              onChange={(e) => handleFilterChange(e)}
            >
              <option
                value="0"
                className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                style={{ height: "1.5rem", width: "0.5rem" }}
              >
                All
              </option>

              {rcStatusList.length > 0 &&
                rcStatusList.map((item) => (
                  <option key={"status-" + item.ID} value={item.ID}>
                    {item.Name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="w-full pl-6 flex flex-col">
          <table className="min-w-full mx-auto text-left text-sm font-light mb-4">
            <thead className="promotion-table-head font-inter text-normal font-medium mt-5">
              <tr key="head-campaign-activation">

              <th
                  scope="col"
                  className="w-1/5 border-b border-gray-300 py-2 pl-4"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="w-3/12 border-b
                  border-gray-300 py-2 "
                >
                  Name
                </th>
               
                <th
                  scope="col"
                  className="w-1/5 border-b
                  border-gray-300 py-2 "
                >
                  {" "}
                  AgentId
                </th>

                <th
                  scope="col"
                  className="w-1/5 border-b
                  border-gray-300 py-2  "
                >
                  {" "}
                  Messages
                </th>
                
                <th
                  scope="col"
                  className="w-3/12 border-b border-gray-300 py-2  "
                >
                  {" "}
                 Phone
                </th>
                <th
                  scope="col"
                  className="w-2/6 border-b border-gray-300 py-2 "
                ></th>
              </tr>
            </thead>
            <tbody
              className="text-gray-700 font-inter text-sm font-medium leading-5 ml-5 mb-5"
              id="relatedEntity-body"
            >
              {/* Render the new row with input fields */}

              {data.length > 0 &&
                data.map((dataItem, index) => (
                  <CampaignActivationRow
                    agentRootId={agentRootId}
                    key={dataItem.AgentID + "-" + index}
                    dataItem={dataItem}
                    index={index}
                    fetchAgentCampaignList={fetchAgentCampaignList}
                  />
                ))}
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={5}
                    className="whitespace-nowrap py-2.5 px-4 font-inter"
                  >
                    No Entity Record is Present.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {data.length > 0 &&
            data.map((dataItem, index) => (
              <form
                id={`campaign-update-form-${index}`}
                key={dataItem.AgentID + "-form-" + index}
              >
                <input type="submit" style={{ display: "none" }} />
              </form>
            ))}
        </div>
      </div>
         <div className="flex flex-col font-inter bg-white border border-gray-300 rounded-lg mt-4 mx-6">
        <div className="w-full flex py-2  px-6 justify-between items-center mb-5 border-b border-gray-300"></div>
     <SignOffDate agentRootId={agentRootId} ></SignOffDate>
    </div>
   
    </>
  );
};

export default CampaignActivation;
