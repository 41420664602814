import React, { useEffect, useRef, useState } from "react";
// import './MyLeads.css';
import { IoChevronDownOutline, IoSearchOutline } from "react-icons/io5";
import CleintsTable from "./ClientsTable";
import useApi from "../../../custom-hooks/useApi";
import { BASE_URL } from "../../../appconfig";
import Spinner from "../../common/Spinner";
import NewClientPopup from "./NewClientPopup";
import ClientLeanding from "../../landingScreens/clientsLanding";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function Clients() {
  const visitedPages = useSelector((state) => state.common.visitedPages);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [loading, setLoading = false] = useState(false);
  const [changed, setChanged] = useState(false);
  const [filter, setFilter] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const [leadSourceType, setLeadSourceType] = useState([]);
  const [heading, setHeading] = useState("All Clients");
  const [menuItems, setMenuItems] = useState([]);
  const [actionsPopup, setActionsPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const MAX_VISIBLE_PAGES = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalcount, setTotalcount] = useState(0);
  const api = useApi();
  const dropdownRef = useRef(null);
  const actionsDropdownRef = useRef(null);
  const user = useSelector((state) => state.auth.user);
  const [showNewClientPopup, setShowNewClientPopup] = useState(false);

  const handleConvertClick = () => {
    setShowNewClientPopup(!showNewClientPopup);
  };

  const toggleDropdown = () => {
    setDropDownOpen(!dropDownOpen);
    setActionsPopup(false);
  };

  const isChanged = () => {
    setChanged(!changed);
  };
  const toggleNewActionsPopup = () => {
    setActionsPopup(!actionsPopup);
    setDropDownOpen(false);
  };

  const handleClickOutside = (event) => {
    setTimeout(() => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        actionsDropdownRef.current &&
        !actionsDropdownRef.current.contains(event.target)
      ) {
        setDropDownOpen(false);
        setActionsPopup(false);
      }
    }, 100);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchClientsData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${BASE_URL}/Account`, {
        params: {
          pageno: currentPage,
          SearchTerm: searchTerm,
          filter: filter,
        },
      });
      if (response?.status === 200) {
        const accountList = response?.data?.data;
        setFilteredData(accountList?.accounts);
        setTotalcount(accountList?.totalAccounts);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleFilterChange = (filterValue) => {
    setFilter(filterValue);
    if (filterValue === "my") {
      setHeading("My Clients");
    } else if (filterValue === "downline") {
      setHeading("My Direct's Clients");
    } else {
      setHeading("All Clients");
    }
    setCurrentPage(1);
    toggleDropdown();
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearchDebounced = debounce((term) => {
    setSearchTerm(term);
  }, 1000);

  const handleSearchChange = async (e) => {
    let term = e.target.value.toLowerCase();
    if ((searchTerm.length >= 3 && term.length === 0) || term.length >= 3) {
      handleSearchDebounced(term);
    }
  };

  useEffect(() => {
    fetchClientsData();
  }, [heading, searchTerm, currentPage, changed]);

  const handleExport = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const response = await api.get(`${BASE_URL}/Account`, {
        params: {
          pageno: currentPage,
          SearchTerm: searchTerm,
          filter: filter,
        },
      });
      if (
        response?.status === 200 &&
        response?.data?.data &&
        response?.data?.data?.accounts &&
        response?.data?.data?.accounts.length > 0
      ) {
        const responseData = response.data.data.accounts;
        const headerMapping = {
          Account: "Account",
          ParentAccount: "Parent Account",
          PrimaryAgent: "Primary Agent",
          Phone: "Phone",
          Residance: "Residance",
          FinancialProgram: "	Financial Program",
          NextInteraction: "Next Interaction",
        };

        // Convert JSON data to CSV format
        const csvData = [
          Object.values(headerMapping).join(","),
          ...responseData.map((item) =>
            [
              `${item.FirstName} ${item.LastName}` || "-",
              item.parent?.ParentAccountName || "-",
              `${item.agent?.FirstName} ${item.agent?.LastName}` || "-",
              item.contact?.Mobile_Phone || "-",
              item.contact?.HomeCity || "-",
              item.FinancialProgramInterests || "-",
              item.NextInteraction || "-",
            ].join(",")
          ),
        ].join("\n");

        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `ClientsData_${user.BaseShopID}_${user.AgentID}.csv`;
        link.click();
        window.URL.revokeObjectURL(url);
        setActionsPopup(false);
      } else {
        throw new Error("No Records Found.");
      }
    } catch (error) {
      console.error("Error downloading CSV:", error);
      toast.error(error.message, { theme: "dark" });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const calculateVisiblePages = () => {
      const pages = [];
      const startPage = Math.max(
        1,
        currentPage - Math.floor(MAX_VISIBLE_PAGES / 2)
      );
      const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      const remainingPages = MAX_VISIBLE_PAGES - pages.length;

      if (startPage > 1 && endPage < totalPages) {
        const padStart = Math.min(startPage - 1, remainingPages);
        const padEnd = Math.min(
          totalPages - endPage,
          remainingPages - padStart
        );

        for (let i = startPage - padStart; i >= 1; i--) {
          pages.unshift(i);
        }

        for (let i = endPage + 1; i <= endPage + padEnd; i++) {
          pages.push(i);
        }
      }

      return pages;
    };

    const visiblePageNumbers = calculateVisiblePages();

    if (visiblePageNumbers.length > 1) {
      return (
        <>
          <div className="flex items-center mx-5">
            {currentPage > 1 && currentPage <= totalPages && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 cursor-pointer"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            )}
            {visiblePageNumbers.map((pageNumber, index) => (
              <button
                key={`page-${pageNumber}-${index}`}
                style={{
                  borderRadius: "3rem",
                  backgroundColor:
                    currentPage === pageNumber ? "#3182CE" : "white",
                  color: currentPage === pageNumber ? "white" : "black",
                }}
                type="button"
                onClick={() => handlePageChange(pageNumber)}
                className="font-inter mt-2 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 me-2 mb-2 dark:hover:bg-gray-800 focus:outline-none dark:focus:ring-blue-800"
              >
                {pageNumber}
              </button>
            ))}
            {totalPages > 1 && currentPage < totalPages && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            )}
          </div>
        </>
      );
    }
  };
  const totalPages = Math.ceil(totalcount / itemsPerPage);

  return (
    <>
      {!visitedPages.includes(window.location.pathname) ? (
        <ClientLeanding />
      ) : (
        <div>
          {loading && <Spinner />}
          <div className="flex w-full drpc justify-between px-5 pt-5 mt-2 pr-10 relative">
            <div
              ref={dropdownRef}
              onClick={toggleDropdown}
              className="flex justify-center  items-center cursor-pointer"
            >
              <h1 className="pl-5 pr-2 py-3  text-[26px] font-semibold">
                {heading}
              </h1>
              <IoChevronDownOutline className=" mt-1 font-bold text-xl" />
            </div>
            {dropDownOpen && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="bg-white mt-10 absolute left-0 w-full drp"
                style={{
                  boxShadow: "0px 8px 40px 0px #00000030",
                  maxWidth: "15rem",
                  maxHeight: "60vh",
                  top: "calc(40%)",
                  left: "calc(1%)",
                }}
              >
                <div className="font-inter flex items-center align-middle bg-dropdown-item cursor-pointer">
                  <span
                    className="w-full text-black text-base font-normal px-5 py-3 block bg-white hover:bg-[#2BB574]"
                    style={{ zIndex: 1000 }}
                    onClick={() => handleFilterChange("my")}
                  >
                    My Clients
                  </span>
                </div>
                <div className="font-inter flex items-center align-middle bg-dropdown-item cursor-pointer">
                  <span
                    className="w-full text-black text-base font-normal px-5 py-3 block bg-white hover:bg-[#2BB574]"
                    style={{ zIndex: 1000 }}
                    onClick={() => handleFilterChange("all")}
                  >
                    All Clients
                  </span>
                </div>
                <div className="font-inter flex items-center align-middle bg-dropdown-item cursor-pointer">
                  <span
                    className="w-full text-black text-base font-normal px-5 py-3 block bg-white hover:bg-[#2BB574]"
                    style={{ zIndex: 1000 }}
                    onClick={() => handleFilterChange("downline")}
                  >
                    My Direct's Clients
                  </span>
                </div>
              </div>
            )}
            <div className="block text-right">
              <div className="flex justify-end gap-4">
                <div className="relative border h-[2.6rem] border-[#D1D5DB] border-solid rounded">
                  <IoSearchOutline className="w-[24px] h-[24px] absolute left-3 top-2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search..."
                    style={{
                      backgroundColor: "#F3F4F6",
                      width: "16rem",
                      height: "2.4rem",
                    }}
                    className="focus:outline-none pl-10 border-none rounded"
                    onChange={handleSearchChange}
                  />
                </div>
                <div ref={actionsDropdownRef} className="relative">
                  <button
                    onClick={toggleNewActionsPopup}
                    style={{
                      height: "2.7rem",
                    }}
                    className="gap-8 flex flex-row items-center bg-transparent text-[#4871B7] font-semibold py-2 px-4 border border-blue-500 rounded font-inter"
                  >
                    Actions
                    <IoChevronDownOutline className=" mt-1 font-bold text-xl ml-3 text-[#4871B7]" />
                  </button>

                  {actionsPopup && (
                    <div className="absolute right-0 z-10">
                      <div className="w-[240px] bg-white border rounded shadow-lg mt-1">
                        <ul className="flex flex-col items-start w-full text-left">
                          <li
                            onClick={handleExport}
                            className="w-full font-inter font-medium text-base leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-5 py-3"
                          >
                            Export
                          </li>
                          <li className="w-full font-inter font-medium text-base leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-5 py-3">
                            Import
                          </li>
                          <li className="w-full font-inter font-medium text-base leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-5 py-3">
                            Create SMS Campaign
                          </li>
                          <li className="w-full font-inter font-medium text-base leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-5 py-3">
                            Send Bulk Email
                          </li>
                          <li className="w-full font-inter font-medium text-base leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-5 py-3">
                            View Import History
                          </li>
                          <li className="w-full font-inter font-medium text-base leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-5 py-3">
                            Delete
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  style={{
                    background:
                      "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                    height: "2.8rem",
                  }}
                  onClick={handleConvertClick}
                  className="bg-transparent text-white text-sm font-semibold hover:text-white py-2 px-4  border hover:border-transparent rounded font-inter"
                >
                  + New Client
                </button>
              </div>
            </div>
          </div>

          <div className="px-10 flex gap-16 mb-6 mt-4">
            <div className="text-center text-[#9CA3AF] font-bold">
              <div className="text-center">Total Accounts</div>
              <div className="text-center">$5,10,100(11)</div>
            </div>
            <div className="text-center text-[#9CA3AF] font-bold">
              <div className="text-center">Unmanaged</div>
              <div className="text-center">$5,10,100(11)</div>
            </div>
            <div className="text-center text-[#9CA3AF] font-bold">
              <div className="text-center">Managed</div>
              <div className="text-center">$5,10,100(11)</div>
            </div>
            <div className="text-center text-[#9CA3AF] font-bold">
              <div className="text-center">Full Care</div>
              <div className="text-center">$0(11)</div>
            </div>
            <div className="text-center text-[#9CA3AF] font-bold">
              <div className="text-center">Life Insurance</div>
              <div className="text-center">$60,000(10)</div>
            </div>
            <div className="text-center text-[#9CA3AF] font-bold">
              <div className="text-center">Annuities</div>
              <div className="text-center">$0(0)</div>
            </div>
            <div className="text-center text-[#9CA3AF] font-bold">
              <div className="text-center">Other</div>
              <div className="text-center">$1000(1)</div>
            </div>
          </div>

          <div className="px-10">
            {filteredData?.length > 0 ? (
              <div>
                <CleintsTable
                  leadSource={leadSourceType}
                  data={filteredData}
                  ConstStatus={menuItems}
                  change={isChanged}
                />
                {totalPages > 0 && (
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            ) : (
              <div className="flex text-base font-semibold text-gray-500 p-5 w-full justify-center items-center">
                No Data Available ...!
              </div>
            )}
          </div>
          {showNewClientPopup ? (
            <NewClientPopup toggle={handleConvertClick} />
          ) : null}
        </div>
      )}
    </>
  );
}

export default Clients;
