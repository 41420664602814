import React, { useEffect, useState } from 'react';
import useApi from '../../../../custom-hooks/useApi';
import { BASE_URL } from '../../../../appconfig';
import { toast } from 'react-toastify';
import Spinner from '../../../common/Spinner';

function NewTaproot({ title, toggle, change }) {
  const [name, setName] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const api = useApi();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const json = {
      AgentRootID: name,
      IsTapRoot: true,
    };
    try {
      setLoading(true);   
      const response = await api.post(
        `${BASE_URL}/Backoffice/TapRoot`,
        json
      );
      if (response.status === 200 && response.data.success) {
        toast.success("Taproot created successfully", {
          theme: "dark",
        });
        change();
      } else {
        const errorMessage = response.data?.error || "Failed to create taproot";
        toast.error(errorMessage, {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        theme: "dark",
      });
      console.error('Error updating data:', error);
    } finally {
      setLoading(false);
      toggle();
    }
  };

  const handleDownlineChange = (e) => {
    const { value } = e.target;
    setName(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `${BASE_URL}/Organization/AgentShort_Detail?type=Full`
        );
        setData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      {loading && <Spinner />}
      <div
        className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20"
        style={{ margin: 0 }}
      >
        <div
          className="bg-white p-8 rounded shadow-md relative"
          style={{ width: '400px' }}
        >
          <div className="flex  items-center justify-between mb-2">
            <h2 className="text-black font-inter text-2xl font-medium">
              New Taproot
            </h2>

            <button
              onClick={toggle}
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <p
            style={{ fontSize: '13px' }}
            className="text-neutral-400 font-inter"
          >{`Taproot for ${title}`}</p>
          <div className="mt-5 flex justify-center">
            <form className="w-full max-w-lg" onSubmit={handleSubmit}>
              <div className="flex flex-wrap -mx-3">
                <div className="mt-2 mb-2 w-full px-3 font-inter">
                  <label
                    className="block tracking-wide text-black text-xs font-bold mb-2 "
                    htmlFor="name"
                  >
                    Ground Breaker
                    <span className="text-red-500"> *</span>
                  </label>
                  <select
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="role"
                    name="DownlineSelector"
                    defaultValue={''}
                    onChange={handleDownlineChange}
                    required
                  >
                    <option
                      value=""
                      hidden
                      style={{
                        display: 'none',
                        height: '1.5rem',
                        width: '.5rem',
                      }}
                    >
                      Select Agent
                    </option>
                    {data.length > 0 ? (
                      data.map((item) => (
                        <option key={item.AgentID} value={item.AgentID}>
                          {`${item.FirstName} ${item.LastName} (${item.AgentID})`}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No data available
                      </option>
                    )}
                  </select>
                </div>
              </div>
              <div className="mt-1 flex items-center justify-center font-inter">
                <button
                  style={{
                    background:
                      'linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)',
                    height: '3rem',
                  }}
                  className="w-full text-white p-2 rounded font-inter"
                  type="submit"
                >
                  Create Taproot
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewTaproot;
