import React, { useState } from "react";
import { ReactComponent as MoreIcon } from "../../../assets/more-horizontal.svg";
import { ReactComponent as SaveIcon } from "../../../assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../assets/cancel.svg";
import useApi from "../../../custom-hooks/useApi";
import { BASE_URL } from "../../../appconfig";
import { toast } from "react-toastify";
import Spinner from "../../common/Spinner";
import { useNavigate } from "react-router-dom";



function ApplicationRow({ data, index, change, ConstStatus, fetchClientsData}) {
  const api = useApi();
  const [isEditing, setIsEditing] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const navigate = useNavigate()

  const handleEditClick = () => {
    // setIsEditing(!isEditing);
    navigate(`/applications/application-details/${data?.Underwriting_ID}`)
  };
  const handleCancelClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClick = async (e) => {};

  const handleMoreClick = () => {
    setIsActionOpen(!isActionOpen);
  };

  const applicationStatus = (id) => {
    if(Number(id) === 73 || id === "New"){
        return 'New'
    }
    if(Number(id) === 74 || id === "Pending"){
        return 'Pending'
    }
    if(Number(id) === 75 || id === "Delivery"){
        return 'Delivery'
    }
    if(Number(id) === 76 || id === "Complete"){
        return 'Complete'
    }
    return '-'
  }
  
   
  const handleDeleteClick = async (Underwriting_ID) => {
    if (isDeleting) return; 
    setIsDeleting(true);
    
    try {
      const response = await api.delete(`${BASE_URL}/Application/delete/${Underwriting_ID}`);
  
      if (response.status === 200) {
        fetchClientsData()
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Failed to delete record", {
        theme: "dark",
      });
    } finally {
      setIsDeleting(false);
      setIsActionOpen(false)
    }
  };

  const handleCloneClick = async (Underwriting_ID) => {
    if (isClone) return; 
    setIsClone(true);
    
    try {
      const response = await api.post(`${BASE_URL}/Application/clone/${Underwriting_ID}`);
  
      if (response.status === 201) {
        fetchClientsData()
        toast.success("Record clone successfully", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Failed to clone record", {
        theme: "dark",
      });
    } finally {
      setIsClone(false);
      setIsActionOpen(false)
    }
  };

  return (
    <tr
      key={`the key is ${data?.ID}`}
      className="border-b dark:border-neutral-500 cursor-default"
    >
      <td className="truncate py-3 px-2 whitespace-nowrap font-inter">
        <span>{data?.pageNumber}</span>
        <input
          className="ml-3 rounded border-gray-400 focus:outline-none focus:ring-0 checked:bg-[#4871B7] checked:border-[#4871B7]"
          type="checkbox"
        />
      </td>
      <td className={`truncate py-3 px-2 whitespace-nowrap font-inter text-[#4871B7] ${data?.Application_Name ? 'underline' : ''}`}>
        <span>{`${data?.Application_Name ? data?.Application_Name : '-'}`}</span>
      </td>
      <td className={`truncate py-3 px-2 whitespace-nowrap font-inter text-[#4871B7] ${data?.WritingAgent_Name ? 'underline' : ''}`}>
        {data?.WritingAgent_Name ? data?.WritingAgent_Name : '-'}
      </td>
      <td
        style={{
          maxWidth: "160px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        className="truncate py-3 px-2  whitespace-normal font-inter"
      >
        {data?.Client_State ? data?.Client_State : '-'}
      </td>
      <td className={`truncate py-3 px-2 whitespace-nowrap font-inter text-[#4871B7] ${data?.Policy_Provider ? 'underline' : ''} `}>
        {data?.Policy_Provider ? data?.Policy_Provider : '-'}
      </td>
      <td className="w-[100px] truncate py-3 px-[0.4rem] whitespace-nowrap font-inter">
        {data?.Policy_No ? data?.Policy_No : '-'}
      </td>
      <td className="w-[180px] truncate py-4 px-4  whitespace-normal font-inter">
        {data?.Target_Premium ? data?.Target_Premium : '-'}
      </td>
      <td className="w-[180px] truncate py-4 pl-[0.2rem]  whitespace-normal font-inter">
        {data?.Application_Status? applicationStatus(data?.Application_Status): '-'}
      </td>
      <td className="whitespace-nowrap font-inter">
        {isEditing ? (
          <div className="flex flex-row items-center gap-2">
            <CancelIcon
              className="cursor-pointer"
              onClick={handleCancelClick}
            />
            <button
              className="disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleSaveClick}
            >
              <SaveIcon />
            </button>
          </div>
        ) : (
          <div className="flex flex-row gap-2 cursor-pointer">
            <MoreIcon onClick={handleMoreClick} />
            {isActionOpen && (
              <>
                <div className="absolute z-10 w-auto bg-white border rounded shadow-lg mt-5 mr-16 right-0"
                >
                  <ul className="border rounded"onMouseLeave={()=>setIsActionOpen(false)} >
                    <li
                      className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-[#2BB574] hover:text-white"
                      onClick={() => handleEditClick()}
                    >
                      Edit
                    </li>
                    <li
                      className={`border-0 rounded px-4 py-2 cursor-pointer ${
                        isDeleting ? "text-gray-400 cursor-not-allowed" : "hover:bg-[#2BB574] hover:text-white"
                      }`}
                      onClick={() => handleDeleteClick(data?.Underwriting_ID)}
                      disabled={isDeleting}
                    >
                      Delete
                      {isDeleting ? <Spinner /> : null}
                    </li>
                    <li
                      className="border-0 rounded px-4 py-2 cursor-pointe0 hover:bg-[#2BB574] hover:text-white"
                      onClick={() => handleCloneClick(data?.Underwriting_ID)}
                      disabled={isClone}
                    >
                      Clone
                      {isClone ? <Spinner /> : null}
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        )}
      </td>
    </tr>
  );
}

export default ApplicationRow;
