import React, { useEffect, useState } from "react";
import ChangePassword from "./Popups/ChangePassword";
import { BASE_URL } from "../../../../../appconfig";
import useApi from "../../../../../custom-hooks/useApi";
import { toast } from "react-toastify";
import Spinner from "../../../../common/Spinner";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { format } from "date-fns";



function PersonalInfo({ agentRootId, personalInfoFormData, editing, toggle }) {
  const [isPopup, setIsPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const [errors, setErrors] = useState({});
  const user = useSelector((state) => state.auth.user);
  const selectedDate = personalInfoFormData.Challenge_Date
    ? new Date(personalInfoFormData.Challenge_Date)
    : null;
  const [Challenge_Date, setEffectiveDate] = useState(selectedDate);

  const [formData, setFormData] = useState(personalInfoFormData);

  const handlePassword = () => {
    setIsPopup(!isPopup);
  };

  const validate = () => {
    const newErrors = {};
    const {
      FirstName,
      LastName,
      WorkEmail,
      Mobile,
      National_Producer_Number,
      HomeCity,
      Resident_Number,
    } = formData;

    if (!FirstName) newErrors.FirstName = "First Name is required";
    else if (FirstName.length < 3)
      newErrors.FirstName = "First Name must be at least 3 characters";
    if (!LastName) newErrors.LastName = "Last Name is required";
    if (!WorkEmail) newErrors.WorkEmail = "Email is required";
    if (!/\S+@\S+\.\S+/.test(WorkEmail))
      newErrors.WorkEmail = "Email is invalid";
    if (!Mobile) {
      newErrors.Mobile = "Mobile is required";
    } else if (!/^\d{10}$/.test(Mobile)) {
      newErrors.Mobile = "Mobile must be 10 digits and contain only numbers";
    }
    if (!HomeCity) newErrors.HomeCity = "Home City is required";
    if (!Challenge_Date)
      newErrors.Challenge_Date = "Effective Date is required";
    if (!Resident_Number)
      newErrors.Resident_Number = "Resident Number is required";
    if (!National_Producer_Number)
      newErrors.National_Producer_Number =
        "National Producer Number is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setTimeout(() => {
        setErrors({});
      }, 5000);
    }

    return Object.keys(newErrors).length === 0; // return true if no errors
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setLoading(true);
    const reqBody = {
      ...formData,
      Challenge_Date: format(Challenge_Date, "dd MMM yyyy"),
    };

    try {
      const response = await api.post(
        `${BASE_URL}/organization/UserPersonalInformation/${user.id}`,
        reqBody
      );
      if (response.status === 200) {
        toast.success("Details Updated", {
          theme: "dark",
        });
      } else {
        toast.error("Failed to update details", {
          theme: "dark",
        });
      }
      toggle();
    } catch (error) {
      toast.error(error?.response?.data?.error || "Failed to update details", {
        theme: "dark",
      });
      console.error("Error updating details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Spinner />}
      <div className="pl-6 mt-8 pr-6">
        {isPopup && <ChangePassword toggle={handlePassword} />}
        <form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="w-[30%]">
              <div className="mb-6">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  First Name
                </label>
                {editing ? (
                  <>
                    <input
                      className="shadow text-sm appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline border-none"
                      name="FirstName"
                      placeholder="First Name"
                      value={formData.FirstName}
                      onChange={handleChange}
                    />
                    {errors.FirstName && (
                      <p className="text-red-500 text-xs">{errors.FirstName}</p>
                    )}
                  </>
                ) : (
                  <span className="text-neutral-400">
                    {" "}
                    <p>{formData.FirstName}</p>
                  </span>
                )}
              </div>
              <div className="mb-6">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Work Email
                </label>
                {editing ? (
                  <>
                    <input
                      className="shadow text-sm appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline border-none"
                      name="WorkEmail"
                      defaultValue={formData.WorkEmail}
                      disabled
                    />
                    {errors.WorkEmail && (
                      <p className="text-red-500 text-xs">{errors.WorkEmail}</p>
                    )}
                  </>
                ) : (
                  <span className="text-neutral-400">
                    {" "}
                    <p>{formData.WorkEmail}</p>
                  </span>
                )}
              </div>
              <div className="mb-6">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Home City
                </label>
                {editing ? (
                  <>
                    <input
                      className="shadow text-sm appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline border-none"
                      name="HomeCity"
                      placeholder="Home City"
                      value={formData.HomeCity}
                      onChange={handleChange}
                    />
                    {errors.HomeCity && (
                      <p className="text-red-500 text-xs">{errors.HomeCity}</p>
                    )}
                  </>
                ) : (
                  <span className="text-neutral-400">
                    {" "}
                    <p>{formData.HomeCity}</p>
                  </span>
                )}
              </div>
              <div className="mb-6">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Resident Number
                </label>
                {editing ? (
                  <>
                    <input
                      className="shadow text-sm appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline border-none"
                      name="Resident_Number"
                      placeholder="Resident Number"
                      value={formData.Resident_Number}
                      onChange={handleChange}
                    />
                    {errors.Resident_Number && (
                      <p className="text-red-500 text-xs">
                        {errors.Resident_Number}
                      </p>
                    )}
                  </>
                ) : (
                  <span className="text-neutral-400">
                    {" "}
                    <p>{formData.Resident_Number}</p>
                  </span>
                )}
              </div>
              <div className="flex mt-4 gap-4 items-center justify-between">
                {editing ? (
                  <>
                    <button
                      style={{
                        background: "#2BB574",
                        height: "2.5rem",
                      }}
                      className="text-white w-full rounded py-2 px-3 font-inter"
                      type="submit"
                    >
                      Save
                    </button>
                    <button
                      style={{
                        background: "#cc0909",
                        height: "2.5rem",
                      }}
                      onClick={() => toggle()}
                      className="text-white rounded w-full py-2 px-3 font-inter"
                      type="button"
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <span className="text-neutral-400"> </span>
                )}
              </div>
            </div>
            <div className="w-[30%] ml-5">
              <div className="mb-6">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Last Name
                </label>
                {editing ? (
                  <>
                    <input
                      className="shadow text-sm appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline border-none"
                      name="LastName"
                      placeholder="Last Name"
                      value={formData.LastName}
                      onChange={handleChange}
                    />
                    {errors.LastName && (
                      <p className="text-red-500 text-xs">{errors.LastName}</p>
                    )}
                  </>
                ) : (
                  <span className="text-neutral-400">
                    {" "}
                    <p>{formData.LastName}</p>
                  </span>
                )}
              </div>
              <div className="mb-6">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Mobile
                </label>
                {editing ? (
                  <>
                    <input
                      className="shadow text-sm appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline border-none"
                      name="Mobile"
                      placeholder="Mobile"
                      value={formData.Mobile}
                      onChange={handleChange}
                    />
                    {errors.Mobile && (
                      <p className="text-red-500 text-xs">{errors.Mobile}</p>
                    )}
                  </>
                ) : (
                  <span className="text-neutral-400">
                    {" "}
                    <p>{formData.Mobile}</p>
                  </span>
                )}
              </div>
              <div className="mb-6">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Effective Date
                </label>
                {editing ? (
                  <>
                    <DatePicker
                      className="shadow text-sm appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline border-none"
                      selected={Challenge_Date}
                      onChange={(date) => setEffectiveDate(date)}
                      showYearDropdown
                      dateFormat="dd MMM yyyy"
                      yearDropdownItemNumber={50}
                      scrollableYearDropdown
                      maxDate={new Date()}
                      required
                    />

                    {errors.Challenge_Date && (
                      <p className="text-red-500 text-xs">
                        {errors.Challenge_Date}
                      </p>
                    )}
                  </>
                ) : (
                  <span className="text-neutral-400">
                    {" "}
                    <p>
                      {Challenge_Date
                        ? format(Challenge_Date, "dd MMM yyyy")
                        : ""}
                    </p>
                  </span>
                )}
              </div>
              <div className="mb-6">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  National Producer Number (NPN)
                </label>
                {editing ? (
                  <>
                    <input
                      className="shadow text-sm appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline border-none"
                      name="National_Producer_Number"
                      placeholder="National Producer Number"
                      value={formData.National_Producer_Number}
                      onChange={handleChange}
                    />
                    {errors.National_Producer_Number && (
                      <p className="text-red-500 text-xs">
                        {errors.National_Producer_Number}
                      </p>
                    )}
                  </>
                ) : (
                  <span className="text-neutral-400">
                    {" "}
                    <p>{formData.National_Producer_Number}</p>
                  </span>
                )}
              </div>
            </div>
            <div className="w-[20%] m-6 gap-4">
              <button
                style={{
                  height: "2.6rem",
                  border: "3px solid #4871B7",
                }}
                onClick={handlePassword}
                className="text-[#4871B7] rounded w-full py-2 px-3 font-inter"
                type="button"
              >
                Change Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default PersonalInfo;
