import React, { useState, useEffect, useRef } from "react";
import dropDown from "../../../assets/grayDrowDown.svg";
import greenCall from "../../../assets/greenCall.svg";
import mail from "../../../assets/mail.svg";
import mappin from "../../../assets/mappin.svg";
import { SmallSpinner } from "../../common/Spinner";

export function Dropdown({ placeholder, list, selectedValue, onSelect, disable = false }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (item) => {
    setIsOpen(false);
    onSelect(item)
  };

  return (
    <div className={`relative ${disable ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
      <div
        className="flex justify-between items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full"
        onClick={() => !disable && setIsOpen(!isOpen)}
      >
        <div className="text-gray-400 font-medium text-sm leading-5">
          {selectedValue ? selectedValue : placeholder}
        </div>
        <img src={dropDown} alt="" />
      </div>
      {!disable && isOpen && (
        <div
          className="absolute bg-white rounded-md shadow-md w-full z-10 pb-2"
          onMouseLeave={() => setIsOpen(false)}
        >
          <ul className="text-gray-600 font-medium text-[16px] leading-[19.36px]">
            {!list[0] ? <SmallSpinner /> : null}

            {list.map((item, index) => (
              <li
                key={index}
                className="p-[12px_16px] cursor-pointer bg-white hover:bg-[#4871B7] hover:text-white"
                onClick={() => handleSelect(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export function MultiOptionDropdown({
  placeholder,
  list,
  selectedValue,
  onSelect,
  objSelect = ()=>{},
  disable
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (item) => {
    setIsOpen(false);
    onSelect(`${item?.firstName} ${item?.lastName}`);
    objSelect(item)
    console.log("Selected item:", item); // Handle the selected item as needed
  };

  return (
    <div className={`relative ${disable ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
      <div
        className="flex justify-between items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full"
        onClick={() => !disable && setIsOpen(!isOpen)}
      >
        <div className="text-gray-400 font-medium text-sm leading-5">
          {selectedValue ? selectedValue : placeholder}
        </div>
        <img src={dropDown} alt="" />
      </div>
      {!disable && isOpen && (
        <div
          className="absolute bg-white rounded-md shadow-md w-full z-10 pb-2"
          onMouseLeave={() => setIsOpen(false)}
        >
          <ul className="text-gray-600 font-medium text-[16px] leading-[19.36px]">
            {!list[0]?.firstName ? <SmallSpinner /> : null}

            {
              <>
                <li className="p-[12px_16px] cursor-pointer text-gray-800 font-semibold bg-gray-200 mt-3">
                  Households
                </li>
                {list.map((item, index) =>
                {
                  if (item.AccountTypeID !== 67) return
                  return (
                    <li
                    key={index}
                    className="p-[12px_16px] cursor-pointer bg-white hover:bg-[#4871B7] hover:text-white"
                    onClick={() => handleSelect(item)}
                  >
                    {`${item?.firstName} ${item?.lastName}`}
                  </li>
                  )
                })}
              </>
            }

{
              <>
                <li className="p-[12px_16px] cursor-pointer bg-gray-200 text-gray-800 font-semibold">
                Businesses
                </li>
                {list.map((item, index) =>
                {
                  if (item.AccountTypeID !== 68) return
                  return (
                    <li
                    key={index}
                    className="p-[12px_16px] cursor-pointer bg-white hover:bg-[#4871B7] hover:text-white"
                    onClick={() => handleSelect(item)}
                  >
                    {`${item?.firstName} ${item?.lastName}`}
                  </li>
                  )
                })}
              </>
            }

          </ul>
        </div>
      )}
    </div>
  );
}

export function Info({ info, name, infoModal, setInfoModal = () => {} }) {
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const nameRef = useRef(null);

  const nameInfoModal = () => {
    setInfoModal(info);
  };

  useEffect(() => {
    if (infoModal === info && nameRef.current) {
      const rect = nameRef.current.getBoundingClientRect();
      setModalPosition({
        top: rect.bottom + window.scrollY, // Position below the name
        left: rect.left + window.scrollX, // Align with the left of the name
      });
    }
  }, [infoModal]);

  return (
    <div>
      <div className="text-gray-600 font-semibold text-base leading-5">
        {info}
      </div>
      <div
        ref={nameRef}
        onClick={nameInfoModal}
        className="truncate py-1 whitespace-nowrap font-inter text-[#4871B7] underline cursor-pointer"
      >
        {name}
      </div>
      {infoModal === info && (
        <div
          style={{
            top: `${modalPosition.top}px`,
            left: `${modalPosition.left}px`,
          }}
          className="absolute bg-[#111827] w-[300px] h-[395px] border border-[#3A4253] rounded-lg p-4 z-10"
        >
          <div className="flex justify-end">
            <button
              onClick={() => setInfoModal("")}
              className="text-gray-400 hover:text-white"
            >
              &times;
            </button>
          </div>
          {/* Add your modal content here */}
          <div className="text-white">
            {/* Modal content */}
            <div className="space-y-4">
              <div className="font-semibold text-[14px] leading-[18px] text-white">
                {name}
              </div>

              <div className="text-[11px] leading-[18px] text-gray-400">
                ExamOne specializes in paramedical services and laboratory
                testing, supporting life insurance underwriting with timely,
                accurate health data.
              </div>

              <div className="w-full h-[1px] bg-gray-300"></div>

              <div className="font-semibold text-[14px] leading-[18px] text-white">
                Contact Information
              </div>

              <div className="flex items-center gap-2">
                <img src={greenCall} alt="Phone" className="w-4 h-4" />
                <span className="text-[12px] leading-[26px] text-[#2BB574]">
                  (877) 933-9261
                </span>
              </div>
              <div className="flex items-center gap-2">
                <img src={mail} alt="Email" className="w-4 h-4" />
                <span className="text-[12px] leading-[26px] text-gray-300">
                  support@examone.com
                </span>
              </div>
              <div className="flex items-center gap-2">
                <img src={mappin} alt="Locations" className="w-4 h-4" />
                <span className="text-[12px] leading-[26px] text-white">
                  Locations
                </span>
              </div>

              <div className="flex gap-2 w-full">
                <button className="w-1/2 bg-[#4871B7] text-white py-2 px-4 rounded-[4px] text-[12px] leading-[18px]">
                  Register
                </button>
                <button className="w-1/2 bg-[#4871B7] text-white py-2 px-4 rounded-[4px] text-[12px] leading-[18px]">
                  Order Services
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function Text({ size, label }) {
  if (size === "mid-title") {
    return (
      <label
        htmlFor="choose-client"
        className="text-black font-semibold text-sm leading-6"
      >
        {label}
      </label>
    );
  }

  if (size === "small-title") {
    return (
      <div className="text-gray-600  font-semibold text-[13px] leading-[18px] mb-3">
        {label}
      </div>
    );
  }
}

export const ErrorMessage = ({ message }) => {
  return <div className="text-red-500 text-xs mt-1">{message}</div>;
};
