import React from "react";
import wavingHandSvg from "../../../../assets/Waving-hand-gestures-emoji-on-transparent-background-PNG 1.svg";
import crossSvg from "../../../../assets/x.svg";
import { useNavigate } from "react-router-dom";

const FirstCampaigns = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center  justify-center bg-gray-100">
      <div className="flex flex-row items-center justify-between bg-[#2BB673] w-full p-6">
        <div className="flex flex-row gap-2">
          <img src={wavingHandSvg} alt="" />
          <div className="flex flex-col gap-1 ">
            <div className="font-semibold text-white">Good to Go!</div>
            <div className="text-[13px] font-normal text-white">
              Your phone line is linked. You can now start managing and sending
              SMS message via Campaigns.
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <img src={crossSvg} alt="" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center m-5 mt-12 bg-white p-3 rounded-lg  w-[90%] h-[517px] border border-[#E5E7EB]">
        <div className="flex flex-col items-center">
          {/* Content Part */}
          <div className="flex flex-col justify-center items-center ml-16">
            <div className="w-[607px] flex flex-col justify-center items-center">
              <h1 className="text-[28px] font-semibold text-gray-800 mb-4 text-center">
                Send SMS Campaigns with WealthSmyth
              </h1>
              <p className="font-medium text-gray-500 mb-6 text-center">
                Run SMS outreach campaigns that reach hundreds of prospects and
                clients at once with personalized messages. To help familiarize
                you with how this works, open the Sample Campaign in the
                Training menu on the left.
              </p>
              <button
                onClick={() => navigate("/campaigns/sample-campaigns")}
                className="w-[547px] font-semibold bg-[#4971B8] text-white px-6 py-3 rounded-md w-full"
              >
                Click Sample Campaign
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstCampaigns;
