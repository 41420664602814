import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../../../common/Spinner";
import useApi from "../../../../custom-hooks/useApi";
import { BASE_URL } from "../../../../appconfig";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignGroupTypeList } from "../../../../redux/thunks/commonThunk";
import PhoneInput, {
  isValidPhoneNumber,
} from "react-phone-number-input";

const NewLeadPopUp = ({ toggleLeadPopup, changed }) => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const dispatch = useDispatch();
  const leadTypes = useSelector((state) => state.common.campaignGroupTypeList);
  const restrictedCountries = ["US", "CA"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneInputChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      Mobile_Phone: value,
      CountryCode: "+1",
    }));
    const form = document.querySelector("#new-lead-form");
      const mobileInput = form.querySelector('input[name="Mobile_Phone"]');
      mobileInput.setCustomValidity("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = document.querySelector("#new-lead-form");
    const mobileInput = form.querySelector('input[name="Mobile_Phone"]');
    if (!formData["Mobile_Phone"] || !isValidPhoneNumber(formData["Mobile_Phone"])) {
      mobileInput.setCustomValidity("Please enter a valid phone number.");
      mobileInput.reportValidity();
      return;
    } else {
      mobileInput.setCustomValidity("");
    }
    try {
      setLoading(true);
      let updatedPhoneNo = formData.Mobile_Phone;
      if (updatedPhoneNo.startsWith('+1')) {
        updatedPhoneNo = updatedPhoneNo.slice(2);
      }
      const updatedFormData = {
        ...formData,
        Mobile_Phone: updatedPhoneNo,
      };
      const response = await api.post(`${BASE_URL}/Campaign/Lead`, updatedFormData);
      if (response.status === 200 && response?.data?.success) {
        toast.success("Lead added successfully", {
          theme: "dark",
        });
        changed();
      } else {
        const errorMessage = response.message || "Failed to add a Lead";
        toast.error(errorMessage, {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        theme: "dark",
      });
      console.error("Error adding a Lead:", error);
    } finally {
      setLoading(false);
      toggleLeadPopup();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `${BASE_URL}/Organization/Constant/ReceiptStatus`
        );
        setData(response.data?.constant);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    dispatch(fetchCampaignGroupTypeList({ api }));
    fetchData();
  }, []);

  return (
    <div>
      {loading && <Spinner />}
      <div
        className="fixed inset-0 flex items-center justify-center text-left bg-gray-800 bg-opacity-50 z-20 w-50 h-50"
        style={{ margin: 0 }}
      >
        <div
          className="bg-white p-8 rounded shadow-md relative"
          style={{ width: "500px" }}
        >
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-black font-inter text-2xl font-medium">
              New Lead
            </h2>
            <button
              onClick={() => toggleLeadPopup()}
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="mt-5 flex justify-center">
            <form id="new-lead-form" className="w-full max-w-lg" onSubmit={handleSubmit}>
              <div className="flex flex-wrap ">
                <div className="mt-2 mb-2 w-full font-inter px-2">
                  <label
                    className="block tracking-wide text-black text-xs font-bold mb-2 "
                    htmlFor="name"
                  >
                    Lead Type
                  </label>
                  <select
                    className="appearance-none block w-1/2 bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                    name="LeadTypeID"
                    value={formData.LeadTypeID ? formData.LeadTypeID : ""}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select Lead Type
                    </option>
                    {leadTypes?.length > 0 ? (
                      leadTypes?.map((item) => (
                        <option key={item.ID} value={item.ID}>
                          {item.Name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No data available
                      </option>
                    )}
                  </select>
                </div>
                <div className="m-2 border-b dark:border-neutral-300 w-full h-[1px]"></div>
                <div className="flex">
                  <div className="mt-2 mb-5 w-1/2 px-3 font-inter">
                    <label
                      className="block tracking-wide text-black text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      First Name
                    </label>
                    <input
                      name="First_Name"
                      placeholder="First Name"
                      type="text"
                      required
                      onChange={handleChange}
                      className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                    />
                  </div>
                  <div className="mt-2 mb-5 w-1/2 px-3 font-inter">
                    <label
                      className="block tracking-wide text-black text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      Last Name
                    </label>
                    <input
                      name="Last_Name"
                      placeholder="Last Name"
                      type="text"
                      required
                      onChange={handleChange}
                      className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="mt-2 mb-5 w-1/2 px-3 font-inter">
                    <label
                      className="block tracking-wide text-black text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      Email Address
                    </label>
                    <input
                      name="Email_Address"
                      placeholder="Email Address"
                      type="text"
                      required
                      onChange={handleChange}
                      className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                    />
                  </div>
                  <div className="mt-2 mb-5 w-1/2 px-3 font-inter">
                    <label
                      className="block tracking-wide text-black text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      Phone
                    </label>
                    <PhoneInput
                    addInternationalOption={false}
                    required
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="US"
                    countries={restrictedCountries}
                    placeholder="Phone number"
                    name="Mobile_Phone"
                    onChange={handlePhoneInputChange}
                    countryOptions={restrictedCountries.map(country => ({
                      value: country,
                      label: country,
                    }))}
                    error={
                      formData.Mobile_Phone
                        ? isValidPhoneNumber(formData.Mobile_Phone)
                          ? undefined
                          : "Invalid phone number"
                        : "Phone number required"
                    }
                  />
                  </div>
                </div>
                <div className="w-full p-2">
                  <label
                    className="block tracking-wide text-black text-xs font-bold mb-2"
                    htmlFor="name"
                  >
                    Notes
                  </label>
                  <input
                    name="Notes"
                    placeholder="Notes"
                    type="text"
                    required
                    onChange={handleChange}
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                  />
                </div>
              </div>
              <div className="mt-5 flex justify-center font-inter">
                <button
                  style={{
                    background:
                      "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                    height: "3rem",
                    width: "280px",
                  }}
                  className="text-white p-2 rounded font-inter"
                  type="submit"
                >
                  Create New Lead
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLeadPopUp;
