import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import downIcon from '../../../../assets/downarrow.svg';
import { v4 as uuidv4 } from "uuid";

const AreaCodeDropdown = ({ data, handleChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedArea, setSelectedArea] = useState('Area Code');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(()=>{
    setSelectedArea('Area Code');
  },[data])

  const handleAreaClick = (area) => {
    handleChange(area.areaCode);
    setSelectedArea(area.areaCode);
    setIsDropdownOpen(false);
  };

  const filteredAreaCodes = data?.filter((area) =>
    area.city.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const noResultsMessage = searchTerm && filteredAreaCodes.length === 0 ? (
    <p className="px-4 py-2 text-sm text-gray-500">No matching area found.</p>
  ) : null;

  const noStateMessage = data.length === 0 ? (
    <p className="px-4 py-2 text-sm text-gray-500">Please select the state first.</p>
  ) : null;

  return (
    <div className="relative inline-block text-left">
      <div>
        <div className="relative">
          <h4 className='font-semibold text-base mb-2'>Choose your area code</h4>
          <div
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            type="button"
            className="group relative w-[215px] bg-[#F3F4F6] border border-gray-100 rounded-md shadow-sm pl-2 pr-4 py-2 text-left cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          >
            <div className="flex items-center justify-between">
              <p className="truncate mr-3 text-gray-400">{selectedArea}</p>
              <img src={downIcon} alt="down-arrow" className="ml-3" />
            </div>
          </div>
          {isDropdownOpen && (
            <div className="origin-top-right absolute right-0 mt-2 w-56 max-h-80 overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-1">
                <div className="relative px-1">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FiSearch className="text-gray-500" />
                  </span>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-input py-2 pl-8 pr-4 w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    placeholder="Search area code..."
                  />
                </div>
                {noResultsMessage}
                {noStateMessage}
                {filteredAreaCodes.map((area) => (
                  <div key={uuidv4()}>
                    <p onClick={() => handleAreaClick(area)} className="cursor-pointer block px-4 py-2 text-sm text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100">
                      {area.city}({area.areaCode})
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AreaCodeDropdown;

