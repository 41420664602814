import React, { useEffect, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa6';
import RelatedPeople from './RelatedPeople';
import RelatedBusinesEntity from './RelatedBusinessEntity';
import RelatedPeopleForm from './RelatedPeopleForm';
import { BASE_URL } from '../../../appconfig';
import useApi from "../../../custom-hooks/useApi";
import { toast } from 'react-toastify';
import Spinner from '../../common/Spinner';
import { ReactComponent as PlusIcon } from "../../../../src/assets/plus-square.svg";
import RelatedBusinessForm from './RelatedBusinessForm';


function RelationShip({ AccountID }) {
    const api = useApi()
    const [relations, setRelations] = useState([
    ]);
    const [businessList, setBusinessList] = useState([
    ]);
    const [loading, setLoading] = useState(false)
    const [isRelatedPeopleOpen, setIsRelatedPeopleOpen] = useState(true);
    const [isRelatedEntityOpen, setIsRelatedEntityOpen] = useState(true);
    const [relationshipList, setRelationshipList] = useState([])
    const [newRelatedPeopleForm, setNewRelatedPeopleForm] = useState(false)
    const [newRelatedBusinessForm, setNewRelatedBusinessForm] = useState(false)
    const [newForm, setNewForm] = useState(false);
    const [newPolicy, setNewPolicy] = useState(false);
    const [entityList, setEntityList] = useState([]);


    const [isNewPeople, setIsNewPeople] = useState(true)


    const handleCloewRelatedBusinessForm = () => {
        setNewRelatedBusinessForm(false);
    }
    const handleNewForm = () => {
        setNewRelatedPeopleForm(!newRelatedPeopleForm);
        setNewForm(!newForm);
    }
    const handleNewBusinessForm = () => {
        setNewRelatedBusinessForm(!newRelatedBusinessForm)
        setNewPolicy(!newPolicy);
    }
    const handleCloswRelatedPeopleForm = () => {
        setNewRelatedPeopleForm(false);
    }

    useEffect(() => {
        loadRelatedPeople();
        loadRelatedBusiness()
        loadRelations()
        fetchEntities()
    }, [])

    const loadRelatedPeople = async () => {
        try {
            setLoading(true)
            const response = await api.get(`${BASE_URL}/Account/Related_People/${AccountID}`);
            if (response?.status === 200) {
                setRelations(response?.data?.data);
            }
            setLoading(false)

        } catch (error) {
            setLoading(false)
            toast.error(error.message)
        }
    }
    const loadRelations = async () => {
        try {
            setLoading(true);
            const response = await api.get(`${BASE_URL}/static/relations`);
            if (response?.status === 200) {
                setRelationshipList(response?.data?.data);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    const fetchEntities = async () => {
        try {
            const response = await api.get(
                `${BASE_URL}/Account/Entities`
            );

            if (response?.status === 200) {
                setEntityList(response?.data?.data)
            }

        } catch (error) {
            throw error;
        }
    }


    const loadRelatedBusiness = async () => {
        try {
            setLoading(true)
            const response = await api.get(`${BASE_URL}/Account/Related_Business/${AccountID}`);
            if (response?.status === 200) {
                setBusinessList(response?.data?.data);
            }
            setLoading(false)

        } catch (error) {
            setLoading(false)
            toast.error(error.message)
        }
    }

    return (
        <div className='p-4'>
            {
                loading && <Spinner />
            }
            <div className="border border-[#D1D5DB] rounded-lg p-4  mb-4">
                <div className="flex justify-between">
                    <button
                        className="flex  justify-between w-full py-2 text-left text-lg font-medium text-gray-700 focus:outline-none"
                    >
                        <div className='flex'>
                            <span onClick={handleNewBusinessForm}  
                            className='mr-2 mt-1 text-[#334155]'
                            ><FaAngleDown /> </span>
                            <span className="text-[1F2937]  font-inter  font-semibold text-base"> Related Business Entity</span>
                        </div>
                    </button>

                    <button
                        onClick={handleNewBusinessForm} 
                        className="text-[#4871B7] w-[150px] bg-transparent text-sm font-semibold  py-2 px-4 rounded font-inter focus:outline-none focus:ring-0 border-0 flex items-center"
                    >
                        <PlusIcon className='mr-1' />

                        Add new
                    </button>
                </div>

                {isRelatedEntityOpen && (
                    <div className="">
                        {
                            loading && <Spinner />
                        }
                        <table className="w-full ">
                            <thead className="border-b border-[#D1D5DB]">
                                <tr>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Name
                                    </td>

                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Entity Type
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        State / Country
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Net Income
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                    </td>
                                </tr>
                                {
                                    newRelatedBusinessForm ? (
                                        <RelatedBusinessForm AccountID={AccountID} toggle={handleCloewRelatedBusinessForm} onSaveSuccess={loadRelatedBusiness} entityList={entityList} />
                                    ) : null
                                }
                            </thead>
                            <RelatedBusinesEntity AccountID={AccountID} businessList={businessList} reloadItems={loadRelatedBusiness} entityList={entityList} newForm={newPolicy} setEdit={handleCloewRelatedBusinessForm} />
                        </table>
                    </div>
                )}
            </div>

            <div className="border border-[#D1D5DB] rounded-lg p-4  mb-4">
                <div className="flex justify-between">
                    <button
                        className="flex  justify-between w-full py-2 text-left text-lg font-medium text-gray-700 focus:outline-none"
                    >
                        <div className='flex'>
                            <span className='mr-2 mt-1'
                                onClick={handleNewForm}
                            ><FaAngleDown /> </span>
                            <span className="text-[1F2937]  font-inter  font-semibold text-base" > Related People</span>
                        </div>
                    </button>

                    <button
                        onClick={handleNewForm}
                        className="text-[#4871B7] w-[150px] bg-transparent text-sm font-semibold  py-2 px-4 rounded font-inter focus:outline-none focus:ring-0 border-0 flex items-center"
                    >
                        <PlusIcon className='mr-1'/>
                        Add new
                    </button>
                </div>

                {isRelatedPeopleOpen && (
                    <div className="">
                        {
                            loading && <Spinner />
                        }
                        <table className="w-full table-auto">
                            <thead className="border-b border-[#D1D5DB]">
                                <tr>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        {
                                            newRelatedPeopleForm ? (
                                                <div className='flex gap-[140px]'>
                                                    <div>First Name</div>
                                                    <div>Last Name</div>
                                                </div>
                                            ) : (
                                                <p>Name</p>
                                            )
                                        }

                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Relationship
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Phone
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Email
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                    </td>
                                </tr>
                                {
                                    newRelatedPeopleForm ? (
                                        <RelatedPeopleForm AccountID={AccountID}  relationShipList={relationshipList} toggle={handleCloswRelatedPeopleForm} onSaveSuccess={loadRelatedPeople} />
                                    ) : null
                                }
                            </thead>
                            <RelatedPeople AccountID={AccountID} relationShipList={relationshipList}  relations={relations} reloadItems={loadRelatedPeople} isNewPeople={isNewPeople} newForm={newForm} setEdit={handleCloswRelatedPeopleForm} />
                        </table>
                    </div>

                )}
            </div>


        </div>
    )

}

export default RelationShip;
