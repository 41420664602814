import React, { useEffect, useState } from "react";
import CountryStateDropdown from "../campaigns/dropdowns/CountryStateDropdown";
import Spinner from "../../common/Spinner";
import { BASE_URL } from "../../../appconfig";
import useApi from "../../../custom-hooks/useApi";
import { BsInfoCircle } from "react-icons/bs";
import { LiaInfoCircleSolid } from "react-icons/lia";
import { RiUserAddLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { calculateAge } from "../../../utills";

const UpdateClient = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const user = useSelector((state) => state.auth);

  const [activeField, setActiveField] = useState("primaryAgent");
  const [statesData, setStatesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({ top: 15 });
  const [errors, setErrors] = useState({});

  const [entityTypeList, setEntityList] = useState([]);


  const [formData, setFormData] = useState({
    // AccountTypeID: null,
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    BusinessName: "",
    Mobile_Phone: "",
    Notes: "",
    Home_Street: "",
    HomeCity: "",
    HomeState: "",
    HomeZipCode: "",
    HomeCountry: "",
    NextInteraction: "",
    LastInteraction: "",
    PrimaryBusinessPartnerID: 1,
    FieldTrainerBusinessPartnerID: null,
    RefferedBy: "",
    CountryOfCitizenship: "",
    Gender: "Female",
    FinancialProgramInterests: "",
    Height: "",
    Weight: 0,
    BMI: 0,
    Smoking: false,
    HealthClass: "",
    CreditCategory: "",
    HealthConditions: false,
    CriminalHistory: false,
    AnnualIncome: 0.0,
    MortgageBalance: "",
    Education: "",
    TotalInsurableNeed: "",
    CurrentCoverage: "",
    CurrentGap: 0,
    AnnualRateOfInflation: "",
    AnnualRateOfReturns: "",
    PortfolioFees: "",
    CostOfInsurance: 0,
    PlanForYearsOfRetirement: 20,
    CurrentAge: null,
    TargetRetirementAge: 65,
    CurrentNetWorth: "",
    AnnualSavingsRate: 0,
    MinimumAnnualRetirementIncome: "",
    IdealAnnualRetirementIncome: "",
    EnduringFinancialLegacyAmount: "",
    RetirementProjection: "",
    RetirementNeedRangeLow: "",
    RetirementNeedRangeHigh: "",
    RetirementNeedGapLow: "",
    RetirementNeedGapHigh: "",
    MonthlySavingsRate: "",
    MonthlySavingsNeedLow: "",
    MonthlySavingsNeedHigh: "",
    MonthlySavingsGapLow: "",
    MonthlySavingsGapHigh: "",
    EntityTypeID: null,
    HasBuySellAgreement: false,
    ParentAccountID: null,
    Parent_Account_Name: "",
    Website: "",
    Parent_Notes: "",
    MarketingSegment: "",
    heightFeet: 0,
    heightInInches: 0,

  });

  const [parentAccountList, setParentAccountList] = useState([]);
  const [contactList, setContactList] = useState([]);

  const [isCreateNewParentAccount, setIsCreateNewParentAccount] = useState(false)
  const [selectedParentAccount, setSelectedParentAccount] = useState({
    ParentAccountName: "",
    BaseShopID: "",
    ParentAccountID: null
  })

  const [selectedContact, setSelectedContact] = useState({
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    Mobile_Phone: "",
    Country_Code: "",
    Website: "",
    Notes: "",
    MarketingSegment: "",
    id: null
  })


  const api = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();
    saveFormData(e)
  };
  const handleIncrement = (field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: parseInt(prevData[field] || 0) + 1,
    }));
  };
  const handleDecrement = (field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: Math.max(0, parseInt(prevData[field] || 0) - 1),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      [name]: ""
    })

    if (name === "Birthday") {
      const age = calculateAge(value);
      setFormData({
        ...formData,
        CurrentAge: age,
        Birthday: value
      })
    }
    else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleStateClick = (state) => {
    setErrors({
      ...errors,
      HomeState: ""
    })
    setFormData({
      ...formData,
      HomeCountry: state?.country?.id === 39 ? "Canada" : "United States",
      HomeState: state?.name
    })
    // setState(state);
  };

  const handleFocus = (e, name) => {
    setTooltipPosition({
      top: window.scrollY + 20,
    });
    setActiveField(name);
  };
  const handleBlur = () => {
    setActiveField("");
  };

  useEffect(() => {
    if (location?.state?.AccountID) {

      loadDefaultAccountData()
    }
  }, [])


  const loadDefaultAccountData = async () => {
    try {

      try {
        setLoading(true)
        const response = await api.get(`${BASE_URL}/Account/Profile/${location?.state?.AccountID}`);
        if (response?.status === 200) {
          const result = response?.data;
          setFormData({
            ...formData,
            AccountTypeID: null,
            First_Name: result?.data?.account?.FirstName,
            EntityTypeID:result?.data?.account?.EntityTypeID,
            Last_Name: result?.data?.account?.LastName,
            Email_Address: result?.data?.contact?.Email_Address,
            BusinessName: result?.data?.account?.BusinessName,
            Mobile_Phone: result?.data?.contact?.Mobile_Phone,
            Notes: result?.data?.account?.Notes,
            Home_Street: result?.data?.contact?.Home_Street,
            HomeCity: result?.data?.contact?.HomeCity,
            HomeState: result?.data?.contact?.HomeState,
            HomeZipCode: result?.data?.contact?.HomeZipCode,
            HomeCountry: result?.data?.contact?.HomeCountry,
            NextInteraction: result?.data?.account?.NextInteraction,
            LastInteraction: result?.data?.account?.LastInteraction,
            PrimaryBusinessPartnerID: result?.data?.fieldTrainer?.ID,
            FieldTrainerBusinessPartnerID: result?.data?.fieldTrainer?.ID,
            RefferedBy: result?.data?.account?.RefferedBy,
            CountryOfCitizenship: result?.data?.account?.CountryOfCitizenship,
            Gender: result?.data?.account?.Gender,
            FinancialProgramInterests: result?.data?.account?.FinancialProgramInterests,
            heightFeet: result?.data?.account?.HeightFeet,
            heightInInches: result?.data?.account?.HeightInInches,
            CurrentAge: result?.data?.account?.CurrentAge,
            Weight: result?.data?.account?.Weight,
            BMI: result?.data?.account?.BMI,
            Smoking: result?.data?.account?.Smoking,
            HealthClass: result?.data?.account?.HealthClass,
            CreditCategory: "",
            HealthConditions: result?.data?.account?.HealthConditions,
            CriminalHistory: result?.data?.account?.CriminalHistory,
            ParentAccountID: result?.data?.parent?.ParentAccountID,
            Parent_Account_Name: "",
            Website: "",
            Parent_Notes: "",
            Birthday: result?.data?.contact?.Birthday
          })

          setSelectedParentAccount({
            ParentAccountID: result?.data?.parent?.ParentAccountID,
            ParentAccountName: result?.data?.parent?.ParentAccountName,
            BaseShopID: ""
          })

          setSelectedFieldTrainer({
            AgentName: result?.data?.fieldTrainer?.FirstName + " " + result?.data?.fieldTrainer?.LastName,
            ID: result?.data?.fieldTrainer?.ID
          })

        }

        setLoading(false)

      } catch (error) {
        setLoading(false)

      }

    } catch (error) {
      throw error;
    }
  }


  useEffect(() => {
  }, [state]);

  useEffect(() => {
    loadEntities()
    let isMounted = true;

    const fetchCountryData = async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `${BASE_URL}/RingCentral/Get_All_States`
        );
        if (isMounted) {
          setStatesData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCountryData();
    return () => {
      isMounted = false;
    };
  }, []);
  const validatePositive = (value) => {
    return value >= 0 ? value : 0;
  };

  const loadEntities = async () => {
    try {
      const response = await api.get(`/Organization/Constant/Entity`);
      if (
        response.status === 200 &&
        response.data.constant &&
        response.data.constant.length > 0
      ) {
        setEntityList(response.data.constant)
      }

    } catch (error) {
      throw error;
    }
  }

  const saveFormData = (e) => {
    e.preventDefault();
    if (!validate()) return;

    const payload = {
      "ClientInfo": {
        // "AccountTypeID": formData?.BusinessName === "business" ? 68 : 67,
        "First_Name": formData.First_Name,
        "Last_Name": formData.Last_Name,
        "BusinessName": formData.BusinessName,
        "Mobile_Phone": formData.Mobile_Phone,
        "Notes": formData.Notes,
        "NextInteraction": formData.NextInteraction,
        "LastInteraction": formData.LastInteraction,
        "PrimaryBusinessPartnerID": formData.PrimaryBusinessPartnerID,
        "FieldTrainerBusinessPartnerID": formData.FieldTrainerBusinessPartnerID,
        "RefferedBy": formData.RefferedBy,
        "EntityTypeID":formData?.EntityTypeID,
        "CountryOfCitizenship": formData.CountryOfCitizenship,
        "Gender": formData.Gender,
        "FinancialProgramInterests": formData.FinancialProgramInterests,
        "HeightFeet": formData?.heightFeet,
        "HeightInInches": formData?.heightInInches,
        "Weight": formData.Weight,
        "BMI": formData.BMI,
        "Smoking": formData.Smoking,
        "HealthClass": formData.HealthClass,
        "CreditCategory": formData.CreditCategory,
        "HealthConditions": formData.HealthConditions,
        "CriminalHistory": formData.CriminalHistory,
        "CurrentAge": formData?.CurrentAge,
        "placeOfBirth": formData?.placeOfBirth,
        "HomeCity": formData?.HomeCity,
        "HomeState": formData?.HomeState,
        "HomeCountry": formData?.HomeCountry,
        "Home_Street": formData?.Home_Street,
        "HomeZipCode": formData?.HomeZipCode,
        "Birthday": formData?.Birthday,
      },
      "Parentinfo": {
        "ParentAccountID": formData?.ParentAccountID || null,
        "ParentAccountName": formData?.Parent_Account_Name,
        "Website": formData?.Website,
        "Notes": formData?.Parent_Notes,
        "MarketingSegment": formData?.MarketingSegment
      }
    };
    updateAccount(payload)
  }

  const updateAccount = async (payload) => {
    try {
      setLoading(true)
      const response = await api.put(`${BASE_URL}/Account/${location?.state?.AccountID}`, payload);
      if (response.status === 200) {
        toast.success("Client updated successfully", {
          theme: "dark",
        });
        navigate("/clients")
      } else {
        toast.error("Failed to create client", {
          theme: "dark",
        });
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error("Failed to update details", {
        theme: "dark",
      });

    }
  }

  const validate = () => {
    const newErrors = {};
    const {
      First_Name,
      Last_Name,
      Mobile_Phone,
      RefferedBy,
      Email_Address,
      Home_Street,
      HomeCity,
      HomeZipCode,
      CountryOfCitizenship,
      placeOfBirth,
      ParentAccountID,
      heightFeet,
      Weight,
      Birthday
    } = formData;

    const regex = /^[A-Za-z\s]+$/;

    if (!Birthday) {
      newErrors.Birthday = "Please select  Birth Date "
    }


    if (Weight <= 0) {
      newErrors.Weight = "Weight can not be 0"
    }

    if (heightFeet <= 0) {
      newErrors.heightFeet = "Height can not be 0"
    }

    if (!Email_Address.trim().length) {
      newErrors.Email_Address = "Email can not be blank"
    }

    if (
      !/\S+@\S+\.\S+/.test(Email_Address)
    ) {
      newErrors.Email_Address = "Email is not valid";
    }

    if (placeOfBirth?.trim().length && !regex.test(placeOfBirth)) {
      newErrors.placeOfBirth = "place Of Birth  is not valid";
    }

    // if (FinancialProgramInterests.trim().length && !regex.test(FinancialProgramInterests)) {
    //   newErrors.FinancialProgramInterests = "Financial Program Interests  is not valid";
    // }

    if (!CountryOfCitizenship?.trim().length) {
      newErrors.CountryOfCitizenship = "Country of citizen Code is not valid"
    }

    if (!regex.test(CountryOfCitizenship)) {
      newErrors.CountryOfCitizenship = "Country of citizen is not valid";
    }

    if (!HomeCity?.trim().length) {
      newErrors.HomeCity = "City Code is not valid"
    }

    if (!regex.test(HomeCity)) {
      newErrors.HomeCity = "City is not valid";
    }

    if (!HomeZipCode?.trim().length) {
      newErrors.HomeZipCode = "Zip Code is not valid"
    }

    // if (!HomeState?.trim().length) {
    //   newErrors.HomeState = "Please select an state"
    // }

    if (!Home_Street?.trim().length) {
      newErrors.Home_Street = "Address can not be blank"
    }


    if (!First_Name?.trim().length) {
      newErrors.First_Name = "Name can not be blank"
    }

    if (!regex.test(First_Name)) {
      newErrors.First_Name = "First name is not valid";
    }

    if (!Last_Name?.trim().length) {
      newErrors.Last_Name = "Last can not be blank"
    }

    if (!regex.test(Last_Name)) {
      newErrors.Last_Name = "Last name is not valid";
    }

    if (!/^\d{10}$/.test(Mobile_Phone)) {
      newErrors.Mobile_Phone = "Mobile  is not valid";
    }

    // if (Notes?.trim().length && !regex.test(Notes)) {
    //   newErrors.Notes = "Notes  is not valid";
    // }

    if (RefferedBy?.trim().length && !regex.test(RefferedBy)) {
      newErrors.RefferedBy = "RefferedBy  is not valid";
    }

    if (isCreateNewParentAccount) {

    }
    else if (!selectedContact?.id) {
      if (!ParentAccountID) {
        newErrors.ParentAccountID = "Please search for a parent account";
      }
    }

    setErrors(newErrors)

    return Object.keys(newErrors).length === 0; // return true if no errors
  }

  useEffect(() => {
    const heightInInches =
      parseInt(formData.heightFeet || 0) * 12 +
      parseInt(formData.heightInInches || 0);
    const weight = parseInt(formData.Weight || 0);
    if (heightInInches > 0 && weight > 0) {
      const bmi = ((weight / (heightInInches * heightInInches)) * 703).toFixed(
        1
      );
      setFormData((prevData) => ({
        ...prevData,
        BMI: bmi,
      }));
    }
  }, [formData.heightFeet, formData.heightInInches, formData.Weight]);

  const [searchTerm, setSearchTerm] = useState("");


  const handlSearchParent = async () => {
    try {

      if (searchTerm.trim().length > 0) {
        const response = await api.get(`Account/Search_Parent?searchTerm=${searchTerm}`)
        setParentAccountList(response?.data?.data)
      }

    } catch (error) {
      setParentAccountList([]);
      setContactList([]);
    }
  }

  const createNewParentAccount = () => {
    setParentAccountList([])
    setIsCreateNewParentAccount(true)
    setErrors({
      ...errors,
      ParentAccountID: ""
    })

    setFormData({
      ...formData,
      Parent_Account_Name: searchTerm + " " + (formData?.AccountID === 67 ? "Household" : "Business"),
      ParentAccountID: null
    })
  }

  // useEffect(() => {
  //   if (searchContactTerm?.trim()?.length > 0)
  //     searchContactName();
  // }, [searchContactTerm])

  const handleParentSelect = (selectedParentAccountItem) => {
    setSelectedParentAccount(
      {
        ParentAccountName: selectedParentAccountItem?.ParentAccountName,
        ParentAccountID: selectedParentAccountItem?.ParentAccountID,
        BaseShopID: selectedParentAccountItem?.BaseShopID
      }
    )

    setSelectedContact({
      First_Name: "",
      Last_Name: "",
      Email_Address: "",
      Mobile_Phone: "",
      Country_Code: "",
      Website: "",
      Notes: "",
      MarketingSegment: "",
      id: null
    })

    setFormData({
      ...formData,
      ParentAccountID: selectedParentAccountItem?.ParentAccountID

    })

    setSearchTerm("")
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearchDebounced = debounce((term, searchType) => {
    if (term?.length >= 3 && searchType === "parent")
      handlSearchParent()
    else if (
      term?.length >= 3 && searchType === "field-trainer"
    ) {
      fetchFieldTrainer();
    }
  }, 1000);


  // search for parent
  const setSearchData = (event) => {
    const term = event.target.value;
    handleSearchDebounced(term, "parent");
    setSearchTerm(term);
    setIsCreateNewParentAccount(false)
    setSelectedParentAccount({
      ParentAccountID: null,
      ParentAccountName: "",
      BaseShopID: ""
    })

  };

  const [fieldTrainerSearchTerm, setFieldTrainerSearchTerm] = useState("");
  const [fieldTrainerList, setFieldTrainerList] = useState([]);
  const [selectedFieldTrainer, setSelectedFieldTrainer] = useState({
    AgentName: "",
    ID: null
  });

  const handleFieldTrainerSearch = (event) => {
    setFieldTrainerError("")
    const term = event.target.value;
    if (term?.trim()?.length <= 1) {
      setFieldTrainerList([])
    }
    handleSearchDebounced(term, "field-trainer");
    setFieldTrainerSearchTerm(term);
    setSelectedFieldTrainer({
      AgentName: "",
      ID: null
    })

  };

  const [fieldTrainerError, setFieldTrainerError] = useState("")


  const fetchFieldTrainer = async () => {
    setLoading(true);
    try {
      let additionalQueries = {
        pageNumber: 1,
        pageSize: 100,
        sortfield: "AgentName",
        sortOrder: 'asc',
      };

      let queryParams = {
        SearchTerm: fieldTrainerSearchTerm
      }

      const response = await api.get(`/Organization/Search_Agent?searchTerm=${fieldTrainerSearchTerm}`);

      const responseData = response.data;
      setFieldTrainerList(response?.data?.organizations)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      let message = error.response.data.message || "Error fetching data!";
      setFieldTrainerError(`Field Trainer not found for ${fieldTrainerSearchTerm} `)
      setFieldTrainerList([])
      setFieldTrainerSearchTerm("")
      setLoading(false);
    }
  };


  const handlFieldTrainerSelect = async (selectedItem) => {
    setFieldTrainerSearchTerm("")
    setFieldTrainerList([])
    setFormData({
      ...formData,
      FieldTrainerBusinessPartnerID: parseInt(selectedItem?.ID)

    })


    setSelectedFieldTrainer({
      AgentName: selectedItem?.NameTitle,
      ID: selectedItem?.ID
    })
  }


  const toolTips = (name) => {
    switch (name) {
      case "primaryAgent":
        return (
          <div className="tooltip-content">
            <h3 className="font-bold text-[#4B5563] font-inter">Agents</h3>
            <div className="border-t-2 border-gray-300 mt-8 mb-5"></div>
            <p className="text-[#9CA3AF]">
              All clients have an account for themselves and a parent account
            </p>
            <p className="font-bold mt-5 text-[#6B7280]">
              Primary Agent:
            </p>
            <p className="text-[#9CA3AF]">
              Main customer relationship manager and point of contact.
            </p>
            <p className="font-bold mt-5 text-[#6B7280]">
              Field Trainer:</p>
            <p className="text-[#9CA3AF]">
              Trains agents on client and recruiting appointments.
            </p>
          </div>
        );
      case "parentAccount":
        return (
          <div className="tooltip-content">
            <h3 className="font-bold text-[#4B5563]">Accounts</h3>
            <div className="border-t-2 border-gray-300  mb-5"></div>
            <p className="text-[#9CA3AF]">
              All clients have an account for themselves and a parent account
            </p>
            <p className="font-bold mt-5 text-[#6B7280]">
              Account:
            </p>
            <p className="text-[#9CA3AF]">
              An individual’s client account
            </p>
            <p className="font-bold mt-5 text-[#6B7280]">
              Parent Account:</p>
            <p className="text-[#9CA3AF]">
              A group of individual accounts, for a household or business.
            </p>
          </div>
        );
      case "healthClass":
        return (
          <div className="tooltip-content">
            <h3 className="font-bold text-[#4B5563]">Health classification</h3>
            <div className="border-t-2 border-gray-300  mb-5"></div>
            <p className="text-[#9CA3AF]">
              An estimate helps us provide an accurate quote, but underwriting will determine the offer.  If you’re unsure, start with Standard (average health). In general:
            </p>
            <p className="font-bold mt-5 text-[#6B7280]">
              Preferred Plus:
            </p>
            <p className="text-[#9CA3AF]">
              Excellent health BMI 18-26
            </p>
            <p className="font-bold mt-5 text-[#6B7280]">
              Preferred:</p>
            <p className="text-[#9CA3AF]">
              Good health (minor issues like controlled cholesteral or blood pressure)
            </p>
            <p className="font-bold mt-5 text-[#6B7280]">
              Standard:</p>
            <p className="text-[#9CA3AF]">
              Average health (BMI 30-40)
            </p>
            <p className="font-bold mt-5 text-[#6B7280]">
              Substandard:</p>
            <p className="text-[#9CA3AF]">
              Poor health (BMI {">"} 40)
            </p>
            <p className="font-bold mt-5 text-[#6B7280]">
              Substandard (Table rating):
            </p>
            <p className="text-[#9CA3AF]">
              Major health issues
            </p>
          </div>
        );
      default:
        return null;
    }
  };


  return (
    <div className="flex w-full relative acinfo">
      {loading && <Spinner />}
      <form
        id="account-form"
        className="w-[65%] p-8 acinfo bg-white border m-5"
        onSubmit={(e) => handleSubmit(e, formData)}
      >
        <h2 className="text-xl font-bold mb-4">Account Information</h2>

        <div className="mb-4 acinfo">
          <label
            className="block text-[#4B5563] text-sm font-semibold mb-2"
            htmlFor="notes"
          >
            Notes
          </label>
          <textarea
            className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.Notes?.length > 0 ? 'border-red-500' : 'border-gray-300'
              }`} id="notes"
            name="Notes"
            value={formData?.Notes}
            onChange={handleChange}
            placeholder="Tech startup founder looking for tax advantages for self and possibly business.  Needs supplemental retirement savings.  Family of 6: Martha (wife), Sandy (20), Tom (18), Amy (16), Joe (14). "
          />
          {errors.Notes?.length > 0 && (
            <p className="text-red-500 text-xs mt-1">{errors.Notes}</p>
          )}
        </div>

        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="nextInteraction"
            >
              Next Interaction
            </label>
            <input
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
              id="nextInteraction"
              name="NextInteraction"
              type="date"
              value={formData?.NextInteraction}
              onChange={handleChange}
              placeholder="Enter next interaction date"
              min={new Date().toISOString().split('T')[0]}
            />
          </div>
          <div className="w-1/2">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="lastInteraction"
            >
              Last Interaction
            </label>
            <input
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
              id="lastInteraction"
              name="LastInteraction"
              type="date"
              value={formData?.LastInteraction}
              onChange={handleChange}
              placeholder="Enter last interaction date"
              max={new Date().toISOString().split('T')[0]}

            />
          </div>
        </div>

        <div className="mb-4 flex space-x-4">
          <div className="w-1/3">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="primaryAgent"
            >
              Primary Agent <span className="text-[#2BB574]">*</span>
            </label>
            <input
              disabled={true}
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
              id="primaryAgent"
              name="primaryAgent"
              type="text"
              value={user?.fullName}
              onFocus={(e) => handleFocus(e, "primaryAgent")}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter primary agent"

            />
          </div>
          <div className="w-1/3 relative">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="fieldTrainer"
            >
              Field Trainer
            </label>
            <input
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
              id="fieldTrainer"
              name="fieldTrainer"
              type="text"
              onFocus={(e) => handleFocus(e, "primaryAgent")}
              onBlur={handleBlur}
              value={fieldTrainerSearchTerm || selectedFieldTrainer?.AgentName}
              onChange={handleFieldTrainerSearch}
              placeholder="Enter field trainer"
            />

            {/* dropdown for field trainer */}
            {fieldTrainerList.length > 0 && (
              <div className="absolute z-10 w-full max-h-60 overflow-y-auto bg-white shadow rounded mt-1">
                {fieldTrainerList.map((item, index) => (
                  <div
                    key={index}
                    className="font-inter flex items-center align-middle cursor-pointer"
                    onClick={() => handlFieldTrainerSelect(item)}
                  >
                    <span className="w-full text-black text-base font-normal px-5 py-3 block hover:bg-[#2BB574]">
                      {item?.NameTitle} <b>({item?.UplineID})</b>
                    </span>
                  </div>
                ))}
              </div>
            )}
            {
              fieldTrainerError?.trim()?.length > 0 ? (
                <p className="text-red-500 text-xs mt-1">{fieldTrainerError}</p>
              ) : null
            }
          </div>

          <div className="w-1/3">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="referredBy"
            >
              Referred By
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors.RefferedBy?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`} id="RefferedBy"
              name="RefferedBy"
              type="text"
              value={formData?.RefferedBy}
              onChange={handleChange}
              placeholder="Enter referred by"

            />
            {errors.RefferedBy?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors?.RefferedBy}</p>
            )}
          </div>
        </div>

        <div className="border-t-2 border-gray-300 mt-8 mb-5"></div>

        <div className="border-t-2 border-gray-300 mt-8 mb-5"></div>
        <h2 className="text-xl font-bold mb-4">Parent Account Information</h2>
        <div className="mb-4 ">
          <div className="flex space-x-4">
            <div className="w-full">
              <label
                className="block text-[#4B5563] text-sm font-semibold mb-2"
                htmlFor="parentAccount"
              >
                Search for existing parent account
              </label>
              <input
                className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.ParentAccountID?.length > 0 ? 'border-red-500' : 'border-gray-300'
                  }`}
                id="parentAccount"
                name="parentAccount"
                type="text"
                value={searchTerm}
                onFocus={(e) => handleFocus(e, "parentAccount")}
                onBlur={handleBlur}
                onChange={setSearchData}
                placeholder="Search for existing parent account"
              />
              {errors?.ParentAccountID?.length > 0 && (
                <p className="text-red-500 text-xs mt-1">{errors.ParentAccountID}</p>
              )}

              {
                searchTerm?.trim().length > 0 && !isCreateNewParentAccount ? (
                  <div
                    className="bg-white mt-2 absolute left-12 w-[430px] rounded-lg"
                    style={{
                      boxShadow: "0px 8px 40px 0px #00000030",
                      maxWidth: "100vh",
                      maxHeight: "60vh",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      className="font-inter items-center align-middle cursor-pointer border  hover:bg-[#2BB574]"
                      onClick={createNewParentAccount}
                    >
                      <div
                        className="w-full flex text-[#4871B7] text-base font-normal  px-5 py-3 bg-white "
                      >
                        <RiUserAddLine /> <span className="ml-2">New Parent</span>
                      </div>
                      <div
                        className="font-inter flex items-center align-middle cursor-pointer"
                      // onClick={() => handleParentSelect(item)}
                      >
                        <span
                          className="w-full text-black text-base font-normal  px-5 py-3 block bg-white"
                        >
                          {
                            searchTerm
                            + " " + (formData?.AccountID === 67 ? "Household" : "Business")
                          }
                        </span>
                      </div>
                    </div>
                    {parentAccountList.map((item, index) => (
                      <div
                        key={index}
                        className="font-inter flex items-center align-middle bg-dropdown-item cursor-pointer"
                        onClick={() => handleParentSelect(item)}
                      >
                        <span
                          className="w-full text-black text-base font-normal px-5 py-3 block bg-white hover:bg-[#2BB574]"
                        >
                          {item?.ParentAccountName} <b>({item?.parentAcccountCode})</b>
                        </span>
                      </div>
                    ))}

                  </div>
                ) : null
              }
            </div>

            {
              isCreateNewParentAccount ? (
                <div className="w-full">
                  <label
                    className="block text-[#4B5563] text-sm font-semibold mb-2"
                    htmlFor="parentAccount"
                  >
                    New Parent Account
                  </label>
                  <input
                    className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border border-gray-300"
                    id="parentAccount"
                    name="parentAccount"
                    type="text"
                    value={formData?.Parent_Account_Name}
                    onFocus={(e) => handleFocus(e, "parentAccount")}
                    onBlur={handleBlur}
                    disabled
                    placeholder=""
                  />

                </div>
              ) : null
            }

            {
              selectedParentAccount?.ParentAccountID && (
                <div className="w-full">
                  <label
                    className="block text-[#4B5563] text-sm font-semibold mb-2"
                    htmlFor="Selected_Parent_Account"
                  >
                    Selected Parent Account
                  </label>
                  <input
                    disabled
                    className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border border-gray-300"
                    id="Selected_Parent_Account"
                    name="First_Name"
                    type="text"
                    value={selectedParentAccount?.ParentAccountName}
                    onBlur={handleBlur}
                    placeholder="First Name"

                  />
                </div>
              )
            }
          </div>

          {
            formData?.BusinessName?.length > 0 ? (
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label
                    className="block text-[#4B5563] text-sm font-semibold mb-2"
                    htmlFor="parentAccount"
                  >
                    Business Name
                  </label>
                  <input
                    className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.ParentAccountID?.length > 0 ? 'border-red-500' : 'border-gray-300'
                      }`}
                    id="BusinessName"
                    name="BusinessName"
                    type="text"
                    value={formData?.BusinessName}
                    onFocus={(e) => handleFocus(e, "BusinessName")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Business Name"
                  />
                  {errors?.BusinessName?.length > 0 && (
                    <p className="text-red-500 text-xs mt-1">{errors.BusinessName}</p>
                  )}
                </div>

                <div className="w-1/2">
                  <label
                    className="block text-[#4B5563] text-sm font-semibold mb-2"
                    htmlFor="parentAccount"
                  >
                    Business Type
                  </label>
                  <select
                    className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border border-gray-300"
                    id="EntityTypeID"
                    name="EntityTypeID"
                    value={formData.EntityTypeID}
                    onChange={handleChange}

                  >
                    {/* <option value="">Business Type</option> */}
                    {
                      entityTypeList?.length > 0 ? (
                        <>
                          {
                            entityTypeList?.map((entityTypeItem) => (
                              <option value={entityTypeItem?.ID}>{entityTypeItem?.Name}</option>

                            ))
                          }
                        </>
                      ) : null
                    }
                  </select>
                  {errors?.ParentAccountID?.length > 0 && (
                    <p className="text-red-500 text-xs mt-1">{errors.ParentAccountID}</p>
                  )}
                </div>
              </div>


            ) : null
          }

        </div>

        <div className="border-t-2 border-gray-300 mt-8 mb-5"></div>
        <h2 className="text-xl font-bold mb-4">
          {
            formData?.BusinessName?.length > 0 ? "Primary Contact" : "Client Information"
          }
        </h2>
        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="Parent_First_Name"
            >
              First Name
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.First_Name?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`}
              id="First_Name"
              name="First_Name"
              type="text"
              onBlur={handleBlur}
              value={formData?.First_Name}
              onChange={handleChange}
              placeholder="First Name"
            />
            {errors?.First_Name?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors.First_Name}</p>
            )}
          </div>

          <div className="w-1/2">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="Last_Name"
            >
              Last Name
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.First_Name?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`} id="Last_Name"
              name="Last_Name"
              type="text"
              value={formData.Last_Name}
              onChange={handleChange}
              placeholder="Enter last name"

            />
            {errors.Last_Name?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors.Last_Name}</p>
            )}
          </div>
        </div>

        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="Email_Address"
            >
              Email
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.Email_Address?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`} id="email"
              name="Email_Address"
              type="email"
              value={formData.Email_Address}
              onChange={handleChange}
              placeholder="Enter email"

            />
            {errors.Email_Address?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors.Email_Address}</p>
            )}
          </div>
          <div className="w-1/2">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="phone"
            >
              Phone
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors.Mobile_Phone?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`} id="Mobile_Phone"
              name="Mobile_Phone"
              type="text"
              value={formData.Mobile_Phone}
              onChange={handleChange}
              placeholder="Enter phone"

            />
            {errors.Mobile_Phone?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors.Mobile_Phone}</p>
            )}
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-[#4B5563] text-sm font-semibold mb-2"
            htmlFor="residenceAddress"
          >
            Residence Address
          </label>
          <input
            className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.First_Name?.length > 0 ? 'border-red-500' : 'border-gray-300'
              }`} id="Home_Street"
            name="Home_Street"
            type="text"
            value={formData.Home_Street}
            onChange={handleChange}
            placeholder="Enter residence address"

          />
          {errors.Home_Street?.length > 0 && (
            <p className="text-red-500 text-xs mt-1">{errors.Home_Street}</p>
          )}
        </div>

        <div className="mb-4 flex space-x-4">
          <div className="w-1/3">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="HomeCity"
            >
              City
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.HomeCity?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`} id="city"
              name="HomeCity"
              type="text"
              value={formData.HomeCity}
              onChange={handleChange}
              placeholder="Enter city"

            />
            {errors.HomeCity?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors?.HomeCity}</p>
            )}
          </div>
          <div className={`w-1/3 `}>
          {
            formData?.HomeState?.length>0?(
              <CountryStateDropdown
              isError={errors?.HomeState?.length > 0 ? true : false}
              stateClick={handleStateClick}
              data={statesData}
              textsm
              initialSelectedState={formData?.HomeState}
            />
            ):null
          }
         
            {errors.HomeState?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors.HomeState}</p>
            )}
          </div>
          <div className="w-1/3">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="zipCode"
            >
              Zip Code
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.HomeZipCode?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`}
              id="HomeZipCode"
              name="HomeZipCode"
              type="text"
              value={formData.HomeZipCode}
              onChange={handleChange}
              placeholder="Enter zip code"

            />
            {errors.HomeZipCode?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors.HomeZipCode}</p>
            )}
          </div>
        </div>

        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="countryOfCitizenship"
            >
              Country of Citizenship
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors.First_Name?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`} id="CountryOfCitizenship"
              name="CountryOfCitizenship"
              type="text"
              value={formData.CountryOfCitizenship}
              onChange={handleChange}
              placeholder="Enter country of citizenship"

            />
            {errors?.CountryOfCitizenship && (
              <p className="text-red-500 text-xs">{errors.CountryOfCitizenship}</p>
            )}
          </div>
          <div className="w-1/2">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="placeOfBirth"
            >
              Place of Birth
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.placeOfBirth?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`}
              id="placeOfBirth"
              name="placeOfBirth"
              type="text"
              value={formData?.placeOfBirth}
              onChange={handleChange}
              placeholder="Enter place of birth"

            />
            {errors.placeOfBirth?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors?.placeOfBirth}</p>
            )}
          </div>
        </div>

        <div className="mb-4 flex space-x-4">
          <div className="w-1/3">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="smoking"
            >
              Gender
            </label>
            <select
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border border-gray-300"
              id="gender"
              name="Gender"
              value={formData.Gender}
              onChange={handleChange}

            >
              <option value="Female">Female</option>
              <option value="Male">Male</option>
            </select>
          </div>
          <div className="w-1/3">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="birthDate"
            >
              Birth Date
            </label>
            <input
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
              id="Birthday"
              name="Birthday"
              placeholder="Enter birth date"
              type="date"
              value={formData.Birthday}
              onChange={handleChange}

            />
            {errors?.Birthday?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors?.Birthday}</p>
            )}
          </div>
          <div className="w-1/3">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="age"
            >
              Age
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.CurrentAge?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`} id="CurrentAge"
              name="CurrentAge"
              type="number"
              value={formData.CurrentAge}
              onChange={handleChange}
              placeholder="Enter age"
              min={1}
              max={120}
              disabled
            />
            {errors?.CurrentAge?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors?.CurrentAge}</p>
            )}
          </div>
        </div>

        <div className="mb-4 flex space-x-4">
          <div className="w-1/2">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="financialProgramInterest"
            >
              Financial Program Interest
            </label>
            <input
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
              id="FinancialProgramInterests"
              name="FinancialProgramInterests"
              type="text"
              value={formData.FinancialProgramInterests}
              onChange={handleChange}
              placeholder="Enter financial program interest"

            />
          </div>
          {/* <div className="w-1/2">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="marketingSegment"
            >
              Marketing Segment
            </label>
            <input
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
              id="marketingSegment"
              name="marketingSegment"
              type="text"
              value={formData.marketingSegment}
              onChange={handleChange}
              placeholder="Enter marketing segment"
              
            />
          </div> */}
        </div>
        <div className="border-t-2 border-gray-300 mt-8 mb-5"></div>
        <h2 className="text-xl font-bold mb-4">Health Factors</h2>

        <div className="mb-4 flex space-x-4">
          <div className="w-1/4">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="heightFeet"
            >
              Height (Feet)
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.First_Name?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`} id="heightFeet"
              name="heightFeet"
              type="number"
              min="0"
              max="10"
              value={validatePositive(formData.heightFeet)}
              onChange={handleChange}
              placeholder="Feet"

            />
            {errors?.heightFeet?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors.heightFeet}</p>
            )}
          </div>

          <div className="w-1/4">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="heightInches"
            >
              Height (Inches)
            </label>
            <input
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
              id="heightInInches"
              name="heightInInches"
              type="number"
              min="0"
              value={validatePositive(formData.heightInInches)}
              onChange={handleChange}
              placeholder="Inches"

            />
          </div>

          <div className="w-1/4">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="weight"
            >
              Weight (lbs)
            </label>
            <input
              className={`shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border ${errors?.Weight?.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`} id="weight"
              name="Weight"
              type="number"
              min="0"
              value={validatePositive(formData.Weight)}
              onChange={handleChange}
              placeholder="Enter weight"

            />
            {errors?.Weight?.length > 0 && (
              <p className="text-red-500 text-xs mt-1">{errors.Weight}</p>
            )}
          </div>

          <div className="w-1/4">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="bmi"
            >
              BMI
            </label>
            <input
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
              id="bmi"
              name="bmi"
              type="text"
              value={formData.BMI}
              readOnly
              placeholder="BMI"
            />
          </div>
        </div>

        <div className="mb-4 flex space-x-4">
          <div className="w-1/3">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="smoking"
            >
              Smoking
            </label>
            <select
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border border-gray-300"
              id="Smoking"
              name="Smoking"
              value={formData.Smoking}
              onChange={handleChange}

            >
              <option value="false">Non-Smoker</option>
              <option value="true">Smoker</option>
            </select>
          </div>
          <div className="w-1/3">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="healthClass"
            >
              Health Class
            </label>
            <select
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border border-gray-300"
              id="HealthClass"
              name="HealthClass"
              value={formData.HealthClass}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={(e) => handleFocus(e, "healthClass")}

            >
              {/* <option value="" disabled>Select health class</option> */}
              <option value="Preferred">Preferred</option>
              <option value="Preferred Plus">Preferred Plus</option>
              <option value="Standard">Standard</option>
              <option value="Substandard">Substandard</option>
              <option value="Substandard (Table rating)">Substandard (Table rating)
              </option>
            </select>
          </div>

          {/* <div className="w-1/3">
            <label
              className="block text-[#4B5563] text-sm font-semibold mb-2"
              htmlFor="prescription"
            >
              Prescription
            </label>
            <input
              className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
              id="prescription"
              name="prescription"
              type="text"
              value={formData.prescription}
              onChange={handleChange}
              placeholder="Enter prescription"
              
            />
          </div> */}
        </div>

        <div className="mb-4 flex space-x-10 pr-40">
          <div className="">
            <label
              className="block text-[#4B5563] text-sm font-bold mb-2 font-inter"
              htmlFor="healthConditions"
            >
              Health Conditions
            </label>
            <div className="flex items-center pr-20">
              <input
                className=" appearance-none  rounded border border-black-300 w-4 h-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="HealthConditions"
                name="HealthConditions"
                type="checkbox"
                value={formData.HealthConditions}
                onChange={handleChange}
              />
              <p className="ml-2 text-[#4B5563] font-semibold text-[13px]">Do Any of the following apply? </p>
            </div>
            <div className="flex justify-between mb-4 mt-4">
              <p className="text-[#9CA3AF] font-semibold">
                Disability
              </p>

              <LiaInfoCircleSolid className="cursor-pointer" />
            </div>
            <div className="flex justify-between mb-4">
              <p className="text-[#9CA3AF] font-semibold">
                Severe mental condition
              </p>

              <LiaInfoCircleSolid className="cursor-pointer" />
            </div>
            <div className="flex  justify-between mb-4">
              <p className="text-[#9CA3AF] font-semibold">
                HIV / AIDS
              </p>
              <LiaInfoCircleSolid className="cursor-pointer" />
            </div>


          </div>

          <div className="">
            <label
              className="block text-[#4B5563] text-sm font-bold mb-2"
              htmlFor="healthConditions"
            >
              Criminal History
            </label>
            <div className="flex items-center pr-10">
              <input
                className=" appearance-none  rounded border border-black-300 w-4 h-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="healthConditions"
                name="CriminalHistory"
                type="checkbox"
                value={formData.CriminalHistory}
                onChange={handleChange}
              />
              <p className="ml-2 text-[#4B5563] font-semibold text-[13px]">Do Any of the following apply? </p>
            </div>
            <div className="flex justify-between mb-4 mt-4">
              <p className="text-[#9CA3AF] font-semibold">
                Major moving violation
              </p>

              <LiaInfoCircleSolid className="cursor-pointer" />
            </div>
            <div className="flex justify-between mb-4">
              <p className="text-[#9CA3AF] font-semibold">
                Major criminal history
              </p>

              <LiaInfoCircleSolid className="cursor-pointer" />
            </div>
            <div className="flex  justify-between mb-4">
              <p className="text-[#9CA3AF] font-semibold">
                Illegal drug or alcohol abuse
              </p>
              <LiaInfoCircleSolid className="cursor-pointer" />
            </div>


          </div>
        </div>

        <button
          style={{
            background:
              "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
            height: "2.6rem",
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline border-none"
          type="submit"
        >
          Update  Account
        </button>
      </form>

      <div
        className="tooltip-wrapper transition-opacity duration-300 absolute"
        style={{
          top: `${tooltipPosition.top}px`,
          opacity: activeField ? 1 : 0,
        }}
      >



        {activeField && toolTips(activeField)}


      </div>
    </div>
  );
};

export default UpdateClient;
