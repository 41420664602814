import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import downIcon from "../../../../assets/downarrow.svg";
import { v4 as uuidv4 } from "uuid";

const CountryStateDropdown = ({
  data,
  stateClick,
  textsm,
  isError,
  isLabel = true,
  initialSelectedState = "State / Country ",
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState(initialSelectedState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleStateClick = (state) => {
    setSelectedState(state.name);
    stateClick(state);
    setIsDropdownOpen(false);
  };

  const filteredResults = Object.entries(data).reduce(
    (acc, [country, states]) => {
      const matchingStates = states.filter((state) =>
        state.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (matchingStates.length > 0) {
        acc.push({
          country,
          matchingStates,
        });
      }
      return acc;
    },
    []
  );

  const MAX_DISPLAY_ENTRIES = 10;

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left w-full" ref={dropdownRef}>
      <div>
        <div className="relative">
          {isLabel ? (
            <h4
              className={`font-semibold text-base mb-2 ${
                textsm ? "text-sm text-[#4B5563]" : ""
              }`}
            >
              Choose country / state
            </h4>
          ) : null}
          <div
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            type="button"
            className={`group relative ${
              textsm ? "w-[100%] h-[38px]" : "w-[215px]"
            }  bg-[#F3F4F6] ${
              isError
                ? "border border-red-500"
                : "border border-gray-100"
            }  rounded-md shadow-sm pl-2 pr-4 py-2 text-left cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500`}
          >
            <div className="flex items-center justify-between">
              <p className="truncate mr-3 text-gray-400">{selectedState}</p>
              <img src={downIcon} alt="down-arrow" className="ml-3" />
            </div>
          </div>
          {isDropdownOpen && (
            <div className="origin-top-right absolute right-0 mt-2 w-56 max-h-80 overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-1">
                <div className="relative px-1">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FiSearch className="text-gray-500" />
                  </span>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-input py-2 pl-8 pr-4 w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    placeholder="Search state..."
                  />
                </div>
                {filteredResults.length === 0 ? (
                  <p className="block px-4 py-2 text-sm text-gray-500">
                    No states found
                  </p>
                ) : (
                  filteredResults
                    .slice(0, MAX_DISPLAY_ENTRIES)
                    .map(({ country, matchingStates }) => (
                      <div key={country}>
                        <p className="block px-4 py-2 text-sm font-bold text-gray-700">
                          {country}
                        </p>
                        <div className="grid grid-cols-1 gap-1">
                          {matchingStates.map((state) => (
                            <p
                              key={uuidv4()}
                              className="cursor-pointer block px-4 py-2 text-sm text-gray-500 transition duration-150 ease-in-out hover:bg-gray-100"
                              onClick={() => handleStateClick(state)}
                            >
                              {state.name}
                            </p>
                          ))}
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountryStateDropdown;
