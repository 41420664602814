import React from "react";
import ProfileIcon from "../../../assets/profile.svg";
import TapUserIcon from '../../../assets/tap-user.svg';
import TapRoot from "./TapRoot";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMAGE_URL } from "../../../appconfig";
import { ReactComponent as SideBarIndicatorIcon } from "../../../assets/SideBarIndicator.svg";


function SideNav({ isSidebarOpen, toggleSidebar }) {
  const Fullname = useSelector((state) => state.auth.fullName);
  const user = useSelector((state) => state.auth.user);
  const premimumInfo = useSelector((state) => state?.auth?.premiumInfo);

  const navigate = useNavigate();

  const handleAgentNameClick = (organizationId) => {
    navigate(`/agent/${encodeURIComponent(btoa(String(organizationId)))}`);
  };

  return (
    <>
      <aside
        style={{
          width: "260px",
          marginLeft: isSidebarOpen ? "0" : "-12rem",
        }}
        className={`bg-gray-800 text-white min-h-screen h-full transition-all duration-300`}
      >
        <div
          className={`flex items-center p-5 ${isSidebarOpen
            ? "justify-start"
            : "justify-end"
            }`}
        >
          <SideBarIndicatorIcon
            className={`w-6 h-6 cursor-pointer transform ${isSidebarOpen ? "rotate-0" : "rotate-180"
              }`}
            onClick={() => toggleSidebar()}
          />
        </div>
        <div
          className={`flex items-center p-5 gap-2  ${isSidebarOpen ? "" : "justify-end"
            }`}
        >
          <img
            // height="30px"
            // width="30px"
            className="cursor-pointer rounded-[50%] w-[30px] h-[30px]"
            src={
              user.ImageName
                ? `${IMAGE_URL}/${user.ImageName}.jpg`
                : TapUserIcon
            }
            alt="profile"
          />

          {isSidebarOpen && (
            <div className="flex flex-col items-center justify-center">
              <span
                onClick={() => handleAgentNameClick(user.organizationId)}
                className="text-white text-sm cursor-pointer"
              >
                {Fullname}
              </span>
            </div>
          )}
        </div>
        {
          !premimumInfo?.isUpgraded ? (
            <div className="w-full px-6">
              <p className="">
                You have no active plan! upgrade to add taproots
              </p>
              <button
                className="mt-4 w-full font-semibold bg-[#FFCD29] text-gray-700 px-6  py-3 w-[422px] rounded-md text-left"
                type="button"

              >
                Upgrade Now
              </button>
            </div>

          ) : null
        }


        {
          premimumInfo?.isUpgraded && premimumInfo?.isExpired ? (
            <div className="w-full px-6">
              <p className="">
                Your plan has expired , upgrade to view existing taproots or add new!
              </p>
              <button
                className="mt-4 w-full font-semibold bg-[#FFCD29] text-gray-700 px-6  py-3 w-[422px] rounded-md text-left"
                type="button"

              >
                Upgrade Now
              </button>
            </div>

          ) : null
        }


        {
          premimumInfo?.isUpgraded && !premimumInfo?.isExpired ? (
            <div className="taproot-container">
              <TapRoot name={Fullname} isSidebarOpen={isSidebarOpen} />
            </div>
          ) : null
        }

      </aside>
    </>
  );
}

export default SideNav;
