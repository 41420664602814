import React, { useEffect,useState, useRef } from "react";
import useApi from "../../../../custom-hooks/useApi";
import { toast } from "react-toastify";
import Spinner from "../../../common/Spinner";
import {useSelector, useDispatch} from "react-redux";
import { fetchCampaignGroupTypeList } from "../../../../redux/thunks/commonThunk";

const CreateCampaignPopup = ({
    toggle, change,
}) => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const colorInputRef = useRef();
  const api = useApi();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);


  useEffect(() => {
    dispatch(fetchCampaignGroupTypeList({api}));
  }, [api,dispatch])

  const campaignGroupTypeList = useSelector((state) => state.common.campaignGroupTypeList);

  const handleColorChange = (e) => {
    const selectedHexColor = e.target.value;
    setSelectedColor(selectedHexColor);
    setFormData({...formData, 'Group_Color': selectedHexColor});
  };

  const handleInputFocus = () => {
    colorInputRef.current.click();
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
    setIsOpen(false);
  }

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // API call or other actions upon form submission
      const response = await api.post(`/Campaign/Group`, formData);
      if (response.status === 200 && response.data.success) {
        toast.success("Campaign created successfully", {
          theme: "dark",
        });
          change();
      } else {
        const errorMessage =
          response.data?.error || "Failed to create Campaign";
        toast.error(errorMessage, {
          theme: "dark",
        });
      }
      //toggleGroupMsgPopup();
    } catch (error) {
      toast.error(error.response.data.message, {
        theme: "dark",
      });
      console.error("Error updating data:", error);
    } finally {
      setLoading(false);
        toggle();
    }
  };

  return (
    <div>
      {loading && <Spinner />}
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20 w-50 h-50">
        <div
          className="bg-white p-8 rounded shadow-md relative"
          style={{ width: "400px" }}
        >
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-black font-inter text-2xl font-medium">
              New Campaign Group
            </h2>
            <button
              onClick={() => toggle()}
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <p className="text-black">
            Organize your prospecting with Campaign Groups to track and improve
            performance. Group by scenarios (e.g., Rollovers, Executive Plans),
            and add multiple campaigns (e.g., Coke, Pepsi) to each group.
          </p>
          <div className="mt-5 flex justify-center">
            <form className="w-full max-w-lg" onSubmit={handleSubmit}>
              <div className="flex flex-wrap -mx-3">
                <div className="mt-2 mb-5 w-full px-3 font-inter">
                  <label
                    className="block tracking-wide text-black text-xs font-bold mb-2"
                    htmlFor="name"
                  >
                    Campaign Group Name<span className="text-red-500"> *</span>
                  </label>
                  <input
                    name="Group_Name"
                    placeholder="Enter Campaign Group Name (e.g., Rollovers, CPAs, etc.)"
                    type="text"
                    required
                    onChange={handleChange}
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                    title="Enter Campaign Group Name (e.g., Rollovers, CPAs, etc.)"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3">
                <div className="mt-2 mb-5 w-full px-3 font-inter">
                  <label
                    className="block tracking-wide text-black text-xs font-bold mb-2"
                    htmlFor="name"
                  >
                    Target Audience<span className="text-red-500"> *</span>
                  </label>
                  <div className="relative">
                    <button
                      type="button"
                      onClick={toggleDropdown}
                      className="appearance-none block w-full bg-gray-100 text-neutral-700 text-sm border border-neutral-700 py-2.5 px-4 leading-tight focus:outline-none"
                    >
                      Select Campaign Type
                    </button>
                    {isOpen && (
                      <ul className="absolute z-10 mt-1 w-full text-gray-400 bg-[#f3f4f6] border border-gray-200 shadow-lg">
                        {campaignGroupTypeList.map((group) => (
                          <li
                            key={`campaign-group-type-list-${group.ID}`}
                            className="px-4 py-2 hover:bg-white hover:text-black cursor-pointer"
                            onClick={() =>
                              handleChange({
                                target: { name: "Group_Type", value: group.ID },
                              })
                            }
                          >
                            {group.Name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="mt-2 mb-5 w-full px-3 font-inter">
                  <label
                    className="block tracking-wide text-black text-xs font-bold mb-2"
                    htmlFor="color"
                  >
                    Select color<span className="text-red-500"> *</span>
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="Group_Color"
                      id="colorInput"
                      value={selectedColor}
                      onFocus={handleInputFocus}
                      required
                      className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                      readOnly
                    />
                    <input
                      type="color"
                      id="hiddenColorPicker"
                      ref={colorInputRef}
                      value={selectedColor}
                      onChange={handleColorChange}
                      style={{ marginLeft: "10px", height: "30px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mt-1 flex justify-center font-inter">
                <button
                  style={{
                    background:
                      "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                    height: "3rem",
                  }}
                  className="w-full text-white p-2 rounded font-inter"
                  type="submit"
                >
                  Create Campaign Group
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCampaignPopup;
