import React, { useState } from "react";
import ClientsRow from "./ClientsRow";

function CleintsTable({ data, ConstStatus, leadSource, change }) {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const handleMoreClick = (index) => {
    setOpenDropdownIndex(index === openDropdownIndex ? null : index);
  };
  return (
    <div className="min-h-[400px]">
      <table className="min-w-full mx-auto text-left z-0">
        <thead className="text-[#9CA3AF]">
          <tr className="font-medium text-[14px]">
            <th className="border-b border-gray-300 pl-2 pr-2 text-left pb-4"></th>
            <th className="border-b border-gray-300 pl-2 pr-2 text-left pb-4"></th>
            <th className="border-b border-gray-300 pl-2 pr-2 text-left pb-4">
              Account
            </th>
            <th className="border-b border-gray-300 text-left pb-4">
              Parent Account
            </th>
            <th className="border-b border-gray-300 text-left pb-4">
              Primary Agent
            </th>
            <th className="border-b border-gray-300 text-left pb-4">
              Phone Agent
            </th>
            <th className="border-b border-gray-300 text-left pb-4">
              Residence
            </th>
            <th className="border-b border-gray-300 text-left pb-4">
              Financial Program
            </th>
            <th className="border-b border-gray-300 text-left pb-4">
              Next Interaction
            </th>
            <th className="border-b border-gray-300 text-left pb-4"></th>
          </tr>
        </thead>
        <tbody className="text-gray-700 font-inter text-sm font-medium leading-5  mt-8">
          {data?.map((item, index) => (
            <ClientsRow
              key={index}
              data={item}
              index={index}
              leadSource={leadSource}
              change={change}
              ConstStatus={ConstStatus}
              isDropdownOpen={openDropdownIndex === index}
              handleMoreClick={() => handleMoreClick(index)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CleintsTable;
