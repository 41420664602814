import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../../../common/Spinner";
import useApi from "../../../../custom-hooks/useApi";
import DateTimePicker from "./DateAndTime";
import { BASE_URL } from "../../../../appconfig";
import CustomSelect from "./CustomSelectMsg";
import { ReactComponent as ArrowIcon } from "../../../../assets/chevron-down.svg";


const NewMessagePopup = ({ toggleMsgPopup, campaignId, handleMsgSent }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const api = useApi();
  const [message, setMessage] = useState("");
  const [dateAndTime, setDateAndTime] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
  };

  const handleGroupSelect = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleDateAndTime = (date) => {
    setDateAndTime(date);
  }
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };


  const maxLength = 160;
  const remainingCharacters = maxLength - message.length;
  const isOverLimit = remainingCharacters < 0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const json = {
      Campaign_ID: campaignId,
      Campaign_Message: message,
      Schedule_Date: dateAndTime,
      GroupID: selectedGroup,
    };
    try {
      setLoading(true);
      const response = await api.post(`${BASE_URL}/Campaign/Schedule`, json);
      if (response.status === 200 && response?.data?.success) {
        toast.success('Message composed successfully', {
          theme: 'dark',
        });
        handleMsgSent(true);
      } else {
        const errorMessage = response.message || 'Failed to compose a message';
        toast.error(errorMessage, {
          theme: 'dark',
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        theme: 'dark',
      });
      console.error('Error creating a message:', error);
    } finally {
      setLoading(false);
      toggleMsgPopup();
    }
  };

  useEffect(()=> {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `${BASE_URL}/Organization/Constant/RCSmsStatus`
        );
        setData(response.data?.constant);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  },[])

  const putVariableInMessage = async (e) => {
    const variable = e;
    const textbox = document.getElementById("messageTextarea");

    // Get the cursor position
    const cursorPosition = textbox.selectionStart;

    // Get the value before and after the cursor
    const valueBeforeCursor = message.substring(0, cursorPosition);
    const valueAfterCursor = message.substring(cursorPosition);

    // Update the message value with the selected variable inserted at the cursor position
    const newMessage = valueBeforeCursor + variable + valueAfterCursor;
    setMessage(newMessage);

    // Set the cursor position after the inserted variable
    textbox.setSelectionRange(
      cursorPosition + variable.length,
      cursorPosition + variable.length
    );
     setIsOpen(false);
  };

  return (
    <div>
      {loading && <Spinner />}
      <div
        className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20 w-50 h-50"
        style={{ margin: 0 }}
      >
        <div
          className="bg-white p-8 rounded shadow-md relative"
          style={{ width: "500px" }}
        >
          <div className="flex  items-center justify-between mb-2">
            <h2 className="text-black font-inter text-2xl font-medium">
              Compose A group Message
            </h2>
            <button
              onClick={() => toggleMsgPopup()}
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="mt-5 flex justify-center">
            <form className="w-full max-w-lg" onSubmit={handleSubmit}>
              <div className="flex flex-wrap -mx-3">
                <div className="mt-2 mb-2 w-full px-3 font-inter">
                  <label
                    className="block tracking-wide text-black text-xs font-bold mb-2 "
                    htmlFor="name"
                  >
                    Select Group
                    <span className="text-red-500"> *</span>
                  </label>
                  <select
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="role"
                    name="GroupSelector"
                    value={selectedGroup}
                    onChange={handleGroupSelect}
                    required
                  >
                    <option
                      value=""
                      hidden
                      style={{
                        display: "none",
                        height: "1.5rem",
                        width: ".5rem",
                      }}
                    >
                      Select Group
                    </option>
                    {data?.length > 0 ? (
                      data?.map((item) => (
                        <option key={item.ID} value={item.ID}>
                          {item.Name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No data available
                      </option>
                    )}
                  </select>
                </div>
                <div className="mt-2 w-full px-3 font-inter">
                  <div className="flex flex-row justify-between mt-2 mb-2">
                    <label
                      className="block tracking-wide text-black text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      Message
                      <span className="text-red-500"> *</span>
                    </label>

                    <div className="relative w-40">
                      <button
                        type="button"
                        className="block w-full bg-gray-100 text-neutral-700  border-gray-200 text-sm border py-2.5 leading-tight focus:outline-none focus:bg-white mb-2"
                        onClick={toggleDropdown}
                      >
                        <span className="inline-flex w-full justify-between px-2">
                          Choose Variable&nbsp;
                          <ArrowIcon />
                        </span>
                      </button>

                      {isOpen && (
                        <ul
                          id="variableDropdown"
                          className="w-full absolute text-gray-400 bg-[#f3f4f6] border border-gray-200  text-sm z-10 "
                        >
                          <li
                            className="px-4 py-2 cursor-pointer hover:text-gray-800 hover:bg-white"
                            onClick={() => putVariableInMessage("[First_Name]")}
                          >
                            First Name
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer hover:text-gray-800 hover:bg-white"
                            onClick={() => putVariableInMessage("[Last_Name]")}
                          >
                            Last Name
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer hover:text-gray-800 hover:bg-white"
                            onClick={() => putVariableInMessage("[Company]")}
                          >
                            Company
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer hover:text-gray-800 hover:bg-white"
                            onClick={() =>
                              putVariableInMessage("[Next Review Date]")
                            }
                          >
                            Next Review Date
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer hover:text-gray-800 hover:bg-white"
                            onClick={() => putVariableInMessage("[Birthday]")}
                          >
                            Birthday
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <textarea
                    id="messageTextarea"
                    rows={4}
                    required
                    placeholder="Message"
                    value={message}
                    onChange={handleMessageChange}
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                  ></textarea>
                  <div className="text-sm text-gray-500 mt-2">
                    {isOverLimit ? (
                      <>
                        <span className="text-red-500">{message.length}</span>
                        /160{" "}
                      </>
                    ) : (
                      <span>{message.length}/160</span>
                    )}
                  </div>
                </div>
                <div className="w-full p-3 cursor-pointer">
                  <DateTimePicker handleTime={handleDateAndTime} />
                </div>
                {/* checkbox */}
                <p className="text-gray-400 m-3 font-inter text-xs font-normal leading-5 pl-2">
                  This message will begin sending immediately
                </p>
                <div className="mt-2 w-full px-3 font-inter">
                  <div className="text-gray-500 md:w-1/2"></div>
                  <div className="flex">
                    <input
                      type="checkbox"
                      required
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mb-2"
                    />
                    <p className="text-gray-400 font-inter text-xs font-normal leading-5 pl-2">
                      I have permission to text each contact listed above.
                    </p>
                  </div>
                  <div className="text-gray-500 mb-2 md:w-1/2"></div>
                  <div className="flex mb-2">
                    <input
                      required
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mb-6"
                    />
                    <p className="text-gray-400 font-inter text-xs font-normal leading-5 mb-2 pl-2">
                      I understand that each contact will receive a “Text STOP
                      to opt out” message the first time they received a text.
                      This opt notice is 22 characters long and may result in
                      additional messages.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-1 flex justify-between font-inter">
                <button
                  onClick={() => toggleMsgPopup()}
                  className="text-green-500 p-2 rounded font-inter cursor-pointer"
                  type="submit"
                >
                  Cancel Message
                </button>
                <button
                  style={{
                    background:
                      "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                    height: "3rem",
                    width: "7.5rem",
                  }}
                  className="text-white p-2 rounded font-inter"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMessagePopup;
