import React from "react";
import JonnToTeamCard from "../memberEditonPrice/joinToTeamCard";
import PriceTable from "../memberEditonPrice/pricingTable";
import { AddOnCard, CloudCard } from "../memberEditonPrice/joinToTeamCard";
import plusSvg from '../../../assets/blueplus.svg'
import messageSquareSvg from '../../../assets/message-square.svg'
import cycleMovementSvg from '../../../assets/cycle-movement.svg'
import chartLineSvg from '../../../assets/chart-line.svg'
import {list, cloudCardListItems} from './listObjeect'

const memberEditonPrice = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center bg-white">
        <div className="flex flex-col">
          {/* Chat with us Part */}
          <div className="flex flex-col">
            <div className="my-10">
              <h1 className="text-[#1F2937] font-bold text-[48px] leading-[58.09px] px-20">
                WealthSmyth Membership Editions and Pricing
              </h1>
            </div>
            <div className="h-[84px] gap-[16px] flex items-center bg-gray-200">
              <p className="font-inter font-semibold text-[20px] leading-[24.2px] pl-20 pr-5">
                Have questions?
              </p>
              <button className="flex flex-row w-[182px] h-[44px] rounded-[50px] p-[10px_24px] gap-[7px] bg-[#4871B7] text-white">
                <span><img src={messageSquareSvg}/></span>
                Chat with Us!
              </button>
            </div>
          </div>

          {/* Jonin Team Card Part */}
          <div>
            <div className="my-10">
              <h3 className="font-bold text-[40px] leading-[48.41px] text-[#1F2937] ml-20">
                First, choose the right WealthSmyth edition for your needs.
              </h3>
            </div>
            <div className="m-20">
              <JonnToTeamCard />
            </div>
          </div>

          {/* Compare editions, top features, and options Part */}
          <div>
            <div className="mb-14">
              <h3 className="font-bold text-[40px] leading-[48.41px] text-[#1F2937] ml-20">
                Compare editions, top features, and options
              </h3>
            </div>
            <div className="flex flex-row">
              <div className="w-[53%]"></div>
              <div className="w-[47%] flex flex-row justify-between mr-20 mb-9">
                <p className="font-semibold text-[16px] leading-[19.36px] text-[#9CA3AF] w-[33.33%] text-center ">
                  Essentials
                </p>
                <p className="font-semibold text-[16px] leading-[19.36px] text-[#9CA3AF] w-[33.33%] text-center">
                  Professional
                </p>
                <p className="font-semibold text-[16px] leading-[19.36px] text-[#9CA3AF] w-[33.33%] text-center">
                  Owner
                </p>
              </div>
            </div>
            {/* table */}
            <div className="flex flex-row mx-20 border-t border-b border-[#E5E7EB] py-7">
              <div className="w-[50%]">
                <p className="font-inter font-semibold text-[16px] leading-[19.36px] text-[#1F2937]">
                  Pricing
                </p>
              </div>
              <div className="w-[50%] flex flex-row justify-between ">
                <p className="font-inter font-semibold text-[16px] leading-[19.36px] text-[#1F2937] w-[33.33%] text-center">
                  From $25/user/mo *{" "}
                </p>
                <p className="font-inter font-semibold text-[16px] leading-[19.36px] text-[#1F2937] w-[33.33%] text-center">
                  From $40/user/mo *
                </p>
                <p className="font-inter font-semibold text-[16px] leading-[19.36px] text-[#1F2937] w-[33.33%] text-center">
                  From $100/user/mo *{" "}
                </p>
              </div>
            </div>

            <div>
              <PriceTable list={list.digitalplaybook} />
              <PriceTable list={list.leadengagement} />
              <PriceTable list={list.clientaccounts} />
              <PriceTable list={list.trainingcenter} />
              <PriceTable list={list.datatransparency} />
              <PriceTable list={list.backofficemanagement} />
              <PriceTable list={list.productivityandsharing} />
              <PriceTable list={list.transformworkwithartificialintelligence} />
              <PriceTable list={list.cloudphonesystem} />
              <PriceTable list={list.workflowautomation} />
            </div>
          </div>

          {/* Expand the power of WealthSmyth*/}
          <div className="flex flex-row  bg-[#F3F4F6] py-20">
            <div className="flex flex-col mx-20 w-[40%]">
              <div>
                <h4 className="font-bold text-[34px] leading-[41.15px] text-black">
                  Expand the power of WealthSmyth with these related apps and
                  add-ons.
                </h4>
              </div>
              <div className="h-[84px] gap-[16px] flex items-center">
                <p className="font-inter text-gray-600 font-semibold text-[20px] leading-[24.2px] pr-5">
                  Have questions?
                </p>
                <button className="flex flex-row w-[182px] h-[44px] rounded-[50px] p-[10px_24px] gap-[7px] bg-[#4871B7] text-white">
                <span><img src={messageSquareSvg}/></span>
                Chat with Us!
              </button>
              </div>
            </div>
            <div className="flex flex-col w-[60%] gap-7 mr-20">
              <div className="flex flex-row gap-7">
                <AddOnCard
                  title={"Artificial Intelligence"}
                  about={
                    "Supercharge your productivity with industry-leading AI"
                  }
                />
                <AddOnCard
                  title={"Workflow Automation"}
                  about={
                    "Magically reclaim hours of your time with easy business automation"
                  }
                />
              </div>
              <div className="flex flex-row gap-7">
                <AddOnCard
                  title={"High Volume SMS"}
                  about={
                    "Business texting optimized for personalization and deliverability."
                  }
                />
                <AddOnCard
                  title={"Hire an Agency Assistant"}
                  about={
                    "Scale your business with an executive or group assistant"
                  }
                />
              </div>
              <div className="flex flex-row gap-7">
                <AddOnCard
                  title={"Hire an Appointment Setter"}
                  about={
                    "Scale your business with a sales development representative."
                  }
                />
                <AddOnCard
                  title={"Get Marketing Support"}
                  about={
                    "Expert advice and support for digital marketing execution"
                  }
                />
              </div>
            </div>
          </div>

          {/* WealthSmyth Private Enterprise Cloud Part */}
          <div className="flex flex-col items-center justify-center bg-[#F9FAFB] py-20">
            <h1 className="text-center text-black font-bold text-3xl leading-tight">
              WealthSmyth Private Enterprise Cloud
            </h1>
            <p className="text-center text-black text-base my-4 leading-relaxed">
              White-label WealthSmyth technology and create a new revenue-stream
              for your enterprise.
            </p>
            <div className="flex flex-row gap-20 mt-7">
            <CloudCard heading={'Modern Experience'} about={'Supercharge the independent financial business experience.'} listheading={'Advantages'} allList={cloudCardListItems.modernexperience} img={cycleMovementSvg}/>
            <CloudCard heading={'New Revenue Stream'} about={'Create a new source of digital income from your independent agents.'} listheading={'Advantages'} allList={cloudCardListItems.newrevenuestream} img={chartLineSvg}/>
            </div>
            
          </div>

          {/* FAQs */}
          <div className="flex flex-col items-center justify-center py-20">
            <h1 className="text-center text-black font-bold text-5xl leading-tight mb-20">
            FAQs
            </h1>
            <div className="flex flex-row mx-20 border-t border-b border-[#E5E7EB] py-7 w-[75%] items-center cursor-pointer">
              <div className="flex flex-row gap-3 ">
                <img src={plusSvg} alt=''/>
                <p className="text-center text-[#4871B7] font-medium text-xl leading-relaxed ">What is the business opportunity offered by WealthSmyth?</p>
              </div>
            </div>
            <div className="flex flex-row mx-20 border-t border-b border-[#E5E7EB] py-7 w-[75%] items-center cursor-pointer">
              <div className="flex flex-row gap-3 ">
                <img src={plusSvg} alt=''/>
                <p className="text-center text-[#4871B7] font-medium text-xl leading-relaxed ">Do I need prior experience in the financial services industry to start with WealthSmyth?</p>
              </div>
            </div>
            <div className="flex flex-row mx-20 border-t border-b border-[#E5E7EB] py-7 w-[75%] items-center cursor-pointer">
              <div className="flex flex-row gap-3 ">
                <img src={plusSvg} alt=''/>
                <p className="text-center text-[#4871B7] font-medium text-xl leading-relaxed ">How do I earn compensation with WealthSmyth?</p>
              </div>
            </div>
            <div className="flex flex-row mx-20 border-t border-b border-[#E5E7EB] py-7 w-[75%] items-center cursor-pointer">
              <div className="flex flex-row gap-3 ">
                <img src={plusSvg} alt=''/>
                <p className="text-center text-[#4871B7] font-medium text-xl leading-relaxed ">What kind of support and training does WealthSmyth provide to new associates?  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memberEditonPrice;
