import React, { useState } from "react";
import dropDown from "../../../../assets/grayDrowDown.svg";
import { SmallSpinner } from "../../../common/Spinner";

export const ProgressBar = ({ stages }) => (
  <div className="flex flex-col space-y-2 mt-9 ml-2">
    <div className="flex justify-between text-center">
      {stages.map((stage, index) => (
        <div
          key={index}
          className={`w-full text-[13px] font-semibold text-start ${stage.color}`}
        >
          {stage.icon && <img src={stage.icon} alt="" />}
          {stage.label}
        </div>
      ))}
    </div>
    <div className="flex space-x-2">
      {stages.map((stage, index) => (
        <div key={index} className={`w-full h-[12px] ${stage.barColor}`}></div>
      ))}
    </div>
  </div>
);

export const CommissionTable = ({ title, list, finalvalue }) => {
  return (
    <>
      <div className="text-sm font-bold text-gray-700">{title}</div>
      {list.map((val, idx) => (
        <div className="text-base font-medium text-gray-500">{val}</div>
      ))}
      <div className="text-semibold font-medium text-gray-700">
        {finalvalue}
      </div>
    </>
  );
};

export const EditableText = ({
  label,
  value,
  onChange,
  isEdit,
  error,
  disable = false,
}) => {
  const [text, setText] = useState(value);

  const handleChange = (e) => {
    setText(e.target.value);
    onChange(e.target.value);
  };

  const handleBlur = () => {
    onChange(text);
  };

  const handleRemove = () => {
    setText("");
    onChange("");
  };

  return (
    <div className="flex flex-row items-center gap-32 w-full">
      <div className="font-medium text-sm leading-[18px] text-gray-400 w-1/2 whitespace-nowrap">
        {label}
      </div>
      {!disable && isEdit ? (
        <div className="flex flex-col flex-grow">
          <div className="flex flex-row items-center bg-[#E5E7EB] h-[38px] p-[10px] border border-gray-300 w-[220px]">
            <input
              placeholder={value}
              type="text"
              value={text}
              onChange={handleChange}
              onBlur={handleBlur}
              className="h-[30px] flex-grow bg-transparent border-none text-sm font-inter text-[#4871B7] outline-none focus:outline-none focus:ring-0"
            />
            <button
              onClick={handleRemove}
              className="text-gray-500 text-lg"
              aria-label="Clear text"
            >
              ×
            </button>
          </div>
          {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
        </div>
      ) : (
        <div
          className={`truncate px-2 text-sm font-inter text-[#4871B7] w-1/2 text-left ${
            value ? "underline" : ""
          }`}
        >
          {value ? value : "-"}
        </div>
      )}
    </div>
  );
};

// EditableDropdown Component with Error Handling
export const EditableDropdown = ({
  label,
  value,
  onChange,
  onChangeId = () => {},
  list,
  isEdit,
  errors,
  disable = false,
}) => {
  const [selectedItem, setSelectedItem] = useState(value);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (item) => {
    setSelectedItem(item?.[list[1]]);
    onChange(item?.[list[1]]);
    onChangeId(item?.[list[2]]);
    setIsOpen(false);
  };

  return (
    <div className={`flex flex-row items-center gap-32 w-full`}>
      <div className="font-medium text-sm leading-[18px] text-gray-400 w-1/2 whitespace-nowrap">
        {label}
      </div>
      {isEdit ? (
        <div className="flex flex-col">
          <div
            className={`w-[217px] flex justify-between items-center h-[38px] border border-gray-300 bg-[#E5E7EB] pl-6 pr-1 gap-[10px] ${
              disable ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="truncate px-2 text-sm text-gray-600 font-medium leading-5">
              {selectedItem ? selectedItem : value}
            </div>
            <img src={dropDown} alt="" />
          </div>
          {!disable && isOpen && (
            <div className="absolute bg-[#F3F4F6] mt-10 shadow-md w-[220px] h-[500px] overflow-scroll z-10">
              <ul className="text-gray-600 font-medium text-[16px] leading-[19.36px]">
                {!list[0] ? <SmallSpinner /> : null}
                {list[0]?.map((item, index) => (
                  <li
                    key={index}
                    className="p-[12px_16px] cursor-pointer hover:bg-gray-200"
                    onClick={() => handleSelect(item)}
                  >
                    {item?.[list[1]]}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {errors && <div className="text-red-500 text-sm mt-1">{errors}</div>}
        </div>
      ) : (
        <div className="truncate px-2 text-sm font-inter text-[#4B5563] font-semibold w-1/2 text-left">
          {value ? value : "-"}
        </div>
      )}
    </div>
  );
};

export const EditableMultiDropdown = ({
  label,
  value,
  onChange,
  onChangeId = () => {},
  list,
  isEdit,
  errors,
  disable = false,
}) => {
  const [selectedItem, setSelectedItem] = useState(value);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (item) => {
    setSelectedItem(`${item?.firstName} ${item?.lastName}`);
    onChange(`${item?.firstName} ${item?.lastName}`);
    onChangeId(item?.AccountID);
    setIsOpen(false);
  };

  // Filter list into Households and Businesses
  const households = list[0]?.filter(item => item?.AccountTypeID === 67);
  const businesses = list[0]?.filter(item => item?.AccountTypeID === 68);
  return (
    <>
      <div className={`flex flex-row items-center gap-32 w-full`}>
        <div className="font-medium text-sm leading-[18px] text-gray-400 w-1/2 whitespace-nowrap">
          {label}
        </div>
        {isEdit ? (
          <div className="flex flex-col relative">
            <div
              className={`w-[217px] flex justify-between items-center h-[38px] border border-gray-300 bg-[#E5E7EB] pl-6 pr-1 gap-[10px] ${
                disable ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              onClick={() => !disable && setIsOpen(!isOpen)}
            >
              <div className="truncate px-2 text-sm text-gray-600 font-medium leading-5">
                {selectedItem ? selectedItem : value}
              </div>
              <img src={dropDown} alt="" />
            </div>
            {!disable && isOpen && (
              <div className="absolute bg-[#F3F4F6] mt-2 shadow-md w-[220px] h-[300px] overflow-scroll z-10">
                <ul className="text-gray-600 font-medium text-[16px] leading-[19.36px]">
                  {!list?.length && <SmallSpinner />}
                  {households.length > 0 && (
                    <>
                      <li className="p-[12px_16px] cursor-pointer bg-gray-200 text-gray-800 font-semibold">
                        Households
                      </li>
                      {households?.map((item, index) => (
                        <li
                          key={index}
                          className="p-[12px_16px] cursor-pointer hover:bg-gray-200"
                          onClick={() => handleSelect(item)}
                        >
                          {`${item?.firstName} ${item?.lastName}`}
                        </li>
                      ))}
                    </>
                  )}
                  {businesses.length > 0 && (
                    <>
                      <li className="p-[12px_16px] cursor-pointer bg-gray-200 text-gray-800 font-semibold">
                        Businesses
                      </li>
                      {businesses?.map((item, index) => (
                        <li
                          key={index}
                          className="p-[12px_16px] cursor-pointer hover:bg-gray-200"
                          onClick={() => handleSelect(item)}
                        >
                          {`${item?.firstName} ${item?.lastName}`}
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </div>
            )}
            {errors && (
              <div className="text-red-500 text-sm mt-1">{errors}</div>
            )}
          </div>
        ) : (
          <div className="truncate px-2 text-sm font-inter text-[#4B5563] font-semibold w-1/2 text-left">
            {value ? value : "-"}
          </div>
        )}
      </div>
    </>
  );
};

