import React, { useEffect, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa6';
import { TbEdit } from "react-icons/tb";
import { formatUSPhoneNumber } from '../../../utills';


function ClientDetails({ details }) {
    const [clientDetails, setClientDetails] = useState();

    const [isAccountInfoOpen, setIsAccountInfoOpen] = useState(true);
    const [isClientInfoOpen, setIsClientInfoOpen] = useState(true);
    const [ishealthFactorsOpen, setIsHealthFactorsOpen] = useState(true);


    const toggleAccountInfo = () => {
        setIsAccountInfoOpen(!isAccountInfoOpen);
    };

    const toggleClientInfo = () => {
        setIsClientInfoOpen(!isClientInfoOpen);
    };

    const toggleHealthFactors = () => {
        setIsHealthFactorsOpen(!ishealthFactorsOpen);
    };

    useEffect(() => {
        setClientDetails(details);
    }, [details]);

    const formatDate = (dateString) => {
        if (dateString !== "None") {
            const options = { year: 'numeric', month: 'short', day: '2-digit' };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', options);
        }
        else {
            return "None"
        }
    };

    return (
        <div className="p-4">

            <div className="border-b mb-4">
                <button
                    className="flex  justify-between w-full py-2 text-left text-lg font-medium text-gray-700 focus:outline-none"
                >
                    <div className='flex'>
                        <span className='mr-2 mt-1' onClick={toggleAccountInfo}
                        ><FaAngleDown /> </span>
                        <span> Account Information</span>
                    </div>
                    {/* <div className='text-[#4871B7] text-2xl '>
                        <TbEdit   />
                    </div> */}
                </button>
                {isAccountInfoOpen && (
                    <div className="p-4">
                        <div className="mb-2">
                            <p className='text-[#3A4253] font-bold'>Notes</p>
                            <p className='text-[#6B7280] font-medium mt-2'>
                                {details?.account?.Notes}
                            </p>
                        </div>
                        <div className="grid grid-cols-3">
                            <div className='flex gap-10'>
                                <p className='text-[#9CA3AF]'>Next Interaction</p>
                                <p className='text-[#4B5563] font-medium'>{formatDate(clientDetails?.account?.NextInteraction || "None")}</p>
                            </div>
                            <div className='flex gap-10'>
                                <p className='text-[#9CA3AF]'>Last Interaction</p>
                                <p className='text-[#4B5563] font-medium'>{formatDate(clientDetails?.account?.LastInteraction || "None")}</p>
                            </div>
                            <div className='flex gap-10'>
                                <p className='text-white ml-4'>Last Interaction</p>
                                <p className='text-white font-medium'>{formatDate(clientDetails?.account?.LastInteraction)}</p>
                            </div>

                        </div>

                        <div className="grid grid-cols-3 gap-4 mt-2">

                            <div className='flex gap-10'>
                                <p className='text-[#9CA3AF]'>Writing Agent</p>
                                <p className='text-[#4871B7] font-medium ml-4'>
                                    {clientDetails?.agent?.FirstName + " " + clientDetails?.agent?.LastName}
                                </p>
                            </div>
                            <div className='flex gap-10'>
                                <p className='text-[#9CA3AF]'>Field Trainer</p>
                                <p className='text-[#4B5563] font-medium ml-4'>
                                {clientDetails?.fieldTrainer?.FirstName && clientDetails?.fieldTrainer?.LastName 
                                    ? `${clientDetails.fieldTrainer.FirstName} ${clientDetails.fieldTrainer.LastName}` 
                                    : "-"}
                                </p>
                            </div>
                            <div className='flex gap-10'>
                                <p className='text-[#9CA3AF]'>Referred By</p>
                                <p className='text-[#4B5563] font-medium ml-4'>{clientDetails?.account?.RefferedBy || "None"}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="border-b mb-4">
                <button
                    className="flex  justify-between w-full py-2 text-left text-lg font-medium text-gray-700 focus:outline-none"
                >
                    <div className='flex'>
                        <span className='mr-2 mt-1' onClick={toggleClientInfo}
                        ><FaAngleDown /> </span>
                        <span> Client Information</span>
                    </div>
                    {/* <div className='text-[#4871B7] text-2xl '>
                        <TbEdit   />
                    </div> */}
                </button>
                {isClientInfoOpen && (
                    <div className="p-4">
                        <div className="mb-2">
                            <p className='text-[#3A4253] font-bold'>Notes</p>
                            <p className='text-[#6B7280] font-medium mt-2'>
                                {details?.notes}
                            </p>
                        </div>

                        <div className="grid grid-cols-2">
                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Parent Account</p>
                                <p className='text-[#4871B7] underline font-inter'>{clientDetails?.parent?.ParentAccountName}</p>
                            </div>
                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Residence</p>
                                <p className='text-[#4B5563] font-medium'>{clientDetails?.contact?.Home_Street || "-"}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>First Name</p>
                                <p className='text-[#3A4253]  font-inter'>{clientDetails?.account?.FirstName}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Address 2</p>
                                <p className='text-[#3A4253]  font-inter'>{clientDetails?.parent?.Address2 || "-"}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Last Name</p>
                                <p className='text-[#3A4253]  font-inter'>{clientDetails?.account?.LastName}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>City</p>
                                <p className='text-[#3A4253]  font-inter'>{clientDetails?.contact?.HomeCity || "-"}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Phone</p>
                                <p className='text-[#4871B7] font-medium underline'>{formatUSPhoneNumber(clientDetails?.contact?.Mobile_Phone)}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>State/Country</p>
                                <p className='text-[#3A4253]  font-inter'>
                                    {clientDetails?.contact?.HomeState || ""}
                                    {clientDetails?.contact?.HomeCountry ? "," + clientDetails?.contact?.HomeCountry : null}
                                </p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Email</p>
                                <p className='text-[#4871B7] font-medium underline'>{clientDetails?.contact?.Email_Address}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Zipcode</p>
                                <p className='text-[#4B5563] font-medium'>{clientDetails?.contact?.HomeZipCode || "-"}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Country of Citizenship</p>
                                <p className='text-[#4B5563] font-medium'>{clientDetails?.account?.CountryOfCitizenship}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Gender</p>
                                <p className='text-[#4B5563] font-medium'>{clientDetails?.account?.Gender}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Place of Birth</p>
                                <p className='text-[#4B5563] font-medium'>{clientDetails?.account?.placeOfBirth || "-"}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'></p>
                                <p className='text-[#4B5563] font-medium'></p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Birth Date</p>
                                <p className='text-[#4B5563] font-medium'>{formatDate(clientDetails?.contact?.Birthday) || "-"}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Age</p>
                                <p className='text-[#4B5563] font-medium'>{clientDetails?.account?.CurrentAge || "-"}</p>
                            </div>

                        </div>

                    </div>
                )}



            </div>

            <div className="border-b mb-4">
                <button
                    className="flex  justify-between w-full py-2 text-left text-lg font-medium text-gray-700 focus:outline-none"
                >
                    <div className='flex'>
                        <span className='mr-2 mt-1' onClick={toggleHealthFactors}
                        ><FaAngleDown /> </span>
                        <span>Health Factors</span>
                    </div>
                    {/* <div className='text-[#4871B7] text-2xl '>
                        <TbEdit   />
                    </div> */}
                </button>
                {ishealthFactorsOpen && (
                    <div className="p-4">

                        <div className="grid grid-cols-2">
                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Height</p>
                                <p className='text-[#3A4253]  font-inter'>{clientDetails?.account?.HeightFeet || 0}.{clientDetails?.account?.HeightInInches || 0}</p>
                            </div>
                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Smoker</p>
                                <p className='text-[#4B5563] font-medium'>{clientDetails?.account?.Smoking ? "Smoker" : "Non Smoker"}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Weight</p>
                                <p className='text-[#3A4253]  font-inter'>{clientDetails?.account?.Weight}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>BMI</p>
                                <p className='text-[#3A4253]  font-inter'>{clientDetails?.account?.BMI || "0"}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Health Conditions</p>
                                <p className='text-[#3A4253]  font-inter'>{clientDetails?.account?.HealthConditions ? "True" : "False"}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Health Class</p>
                                <p className='text-[#3A4253]  font-inter'>{clientDetails?.account?.HealthClass || "Preferred"}</p>
                            </div>

                            <div className='grid grid-cols-2 mt-4'>
                                <p className='text-[#9CA3AF]'>Criminal History</p>
                                <p className='text-[#4B5563] font-medium'>{clientDetails?.account?.CriminalHistory ? "True" : "False"}</p>
                            </div>

                        </div>

                    </div>
                )}
            </div>

        </div>
    );
}

export default ClientDetails;
