import React, { useState, useEffect } from "react";
import "./dropdown.css";

const Dropdown = ({children, fun = ()=>{}}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    // Calculate the position dynamically
    const hamburgerIcon = document.getElementById("hamburger-icon");
    const navBar = document.getElementById("nav-bar");
    const dropdown = document.getElementById("dropdown");

    if (hamburgerIcon && navBar && dropdown) {
      const navRect = navBar.getBoundingClientRect();
      const navBarPadding = window.getComputedStyle(navBar).paddingTop;

      setPosition({
        top: navRect.height - parseFloat(navBarPadding),
        left: 0,
      });
    }
  }, []);

  return (
    <div
      id="dropdown"
      className="flex-col items-center flex-wrap absolute bg-white border rounded shadow-md z-50"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: "90%",
      }}
      // onMouseLeave={fun}
    >
      {children}
    </div>
  );
};

export default Dropdown;
