import React, { useState, useEffect } from 'react';
import PromotionTable from './PromotionTable';
import { BASE_URL } from '../../../../../appconfig';
import './Promotions.css';
import useApi from '../../../../../custom-hooks/useApi';
import Spinner from '../../../../common/Spinner';

const Promotions = () => {
  const [data, setData] = useState([]);
  const [dataChange, setDataChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const MAX_VISIBLE_PAGES = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalcount, setTotalcount] = useState(0);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

  };

 
  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const calculateVisiblePages = () => {
      const pages = [];
      const startPage = Math.max(1, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
      const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);
      
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      const remainingPages = MAX_VISIBLE_PAGES - pages.length;
    
      if (startPage > 1 && endPage < totalPages) {
        const padStart = Math.min(startPage - 1, remainingPages);
        const padEnd = Math.min(totalPages - endPage, remainingPages - padStart);
    
        for (let i = startPage - padStart; i >= 1; i--) {
          pages.unshift(i);
        }
    
        for (let i = endPage + 1; i <= endPage + padEnd; i++) {
          pages.push(i);
        }
      }
    
      return pages;
    };
    

  const visiblePageNumbers = calculateVisiblePages();
  
  if (visiblePageNumbers.length > 1) {
    return (
      <>
        <div className={`flex items-center mx-5 ${currentPage < totalPages ? "sticky" : "absolute" } bottom-1 `}>
          {currentPage > 1 && currentPage <= totalPages && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 cursor-pointer"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          )}
          {visiblePageNumbers.map((pageNumber, index) => (
            <button
              key={`page-${pageNumber}-${index}`}
              style={{
                borderRadius: "3rem",
                backgroundColor:
                  currentPage === pageNumber ? "#3182CE" : "white",
                color: currentPage === pageNumber ? "white" : "black",
              }}
              type="button"
              onClick={() => handlePageChange(pageNumber)}
              className="font-inter mt-2 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 me-2 mb-2 dark:hover:bg-gray-800 focus:outline-none dark:focus:ring-blue-800"
            >
              {pageNumber}
            </button>
          ))}
          {totalPages > 1 && currentPage < totalPages && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 cursor-pointer"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          )}
        </div>
      </>
    );
  }
};

  const handleDataChange = () => {
    setDataChange(!dataChange);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`${BASE_URL}/Backoffice/Promotion?pageNumber=${currentPage}&pageSize=${itemsPerPage}`);
        setData(response.data.promotion);
        setTotalcount(response.data.totalcount);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [dataChange, api, currentPage]);
  
  const totalPages = Math.ceil(totalcount / itemsPerPage);

  return (
    <>
    <div className="bg-white border border-gray-300 rounded-lg px-4 py-4 m-6">
    {loading && <Spinner />}
    {data.length !== 0 && <PromotionTable data={data} change={handleDataChange} />} 
    </div> 
    {totalPages > 0 && (
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />)}
 </>
    );
};

export default Promotions;
