import React, { useState } from "react";
import Brand from "../../../assets/blackLogo.png";
import LoginCover from "../../../assets/forgotPass.png";
import EmailSent from "./EmailSent";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ForgotFields from "./ForgotFields";

function ForgotPass() {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleConfirmEmail = (e) => {
    setConfirmEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (email !== confirmEmail) {
      toast.error("Emails do not match");
      setLoading(false);
      return;
    }
    if (email) {
      setIsClicked(true);
    }
    setLoading(false);
  };

  const handleSignIn = () => {
    setLoading(true);
    setTimeout(() => {
      navigate("/signin");
    }, 500);
  };

  return (
    <div className="flex h-screen">
      {loading && <Spinner />}
      <div className="w-1/2 bg-white flex flex-col items-center justify-center mt-[3%] px-5 py-5">
        <img src={Brand} alt="brandlogo" className="w-[40%] mb-5" />
        {!isClicked ? (
          <>
            <div className="w-[50%] text-gray-900 text-lg font-semibold mb-3 text-center">
              Please enter your email address below to which we can send you
              instructions.
            </div>
            <form className="w-[40%] mt-3" onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="block text-gray-400 text-sm font-semibold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmail}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="mb-5">
                <label
                  className="block text-gray-400 text-sm font-semibold mb-2"
                  htmlFor="confirmEmail"
                >
                  Confirm Email
                </label>
                <input
                  className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
                  id="confirmEmail"
                  type="email"
                  value={confirmEmail}
                  onChange={handleConfirmEmail}
                  placeholder="Confirm Email"
                  required
                />
              </div>

              <div className="flex items-center justify-between mb-4">
                <button
                  style={{
                    background:
                      "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                    height: "2.6rem",
                  }}
                  className="text-white rounded w-full py-2 px-3 font-inter"
                  type="submit"
                >
                  Send Instructions
                </button>
              </div>
            </form>

            <div className="flex justify-between text-gray-700 text-sm mt-5">
              Have an account?{" "}
              <p
                onClick={handleSignIn}
                className="ml-3 font-semibold cursor-pointer text-blue-600 hover:no-underline hover:text-[#2BB574]"
              >
                Log in here
              </p>
            </div>
          </>
        ) : (
          // <EmailSent email={email} />
          <ForgotFields email={email}/>
        )}
      </div>
      <div className="w-1/2 relative bg-black flex justify-center items-center">
        <img
          src={LoginCover}
          alt="Login Cover"
          className="absolute inset-0 w-full h-full object-fill"
        />
      </div>
    </div>
  );
}

export default ForgotPass;
