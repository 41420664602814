const BASE_URL = 'https://wealthsmythprodwebbackend.azurewebsites.net';
// const BASE_URL = "https://wealthsmythdevwebbackend.azurewebsites.net";
// const BASE_URL='http://localhost:8080'
const FRONT_URL = "https://my.wealthsmyth.com";
const RETIREMENT_BASE_URL=''
// const FRONT_URL = "https://jolly-plant-08b346510.4.azurestaticapps.net";
//  const FRONT_URL='http://localhost:3000'
const IMAGE_URL="https://wealthsmythproddata.blob.core.windows.net/userprofilepic"
// const IMAGE_URL='https://wealthsmythdata.blob.core.windows.net/userprofilepic'
const CAMPAIGN_TEMPLATE_FILE_PATH = "Contact.csv";
const LEADS_TEMPLATE_FILE_PATH = "Leads.csv";
const TEAMS_TEMPLATE_FILE_PATH = "Teams.csv";
// const INTERCOM_APP_ID = "";
const INTERCOM_APP_ID = "mmas01oa";
export {
  RETIREMENT_BASE_URL,
  BASE_URL,
  FRONT_URL,
  IMAGE_URL,
  CAMPAIGN_TEMPLATE_FILE_PATH,
  LEADS_TEMPLATE_FILE_PATH,
  TEAMS_TEMPLATE_FILE_PATH,
  INTERCOM_APP_ID,
};