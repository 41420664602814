

// CombinedChart.js
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarlineChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL','AUG','SEP','OCT','NOV','DEC'],
        datasets: [
          {
            label: 'Recruits',
            data: [30,20,40,45,50,45,70,66,65,70,80,90],
            type: 'line',
            fill: false,
            borderColor: '#FF51B9',
            lineTension: 0.4,
            borderWidth: 1,
          
          },
          {
            label: 'Last Year',
            data: [5, 8, 16, 21,22 ,30,32, 18, 3, 7,18,22,],
            backgroundColor: '#4871B7',
            borderWidth: 1,
            barThickness: 24,
            barPercentage: 0.2,
          },
          {
            label: 'Current Year',
            data: [5, 14, 21, 26, 34,38,24, 6, 10,14,19,5],
            backgroundColor: '#2BB574',
            borderWidth: 1,
            barThickness: 24,
            barPercentage: 0.2,
          },
          
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            
        min: 0,
        max: 90,
        stepSize: 10,
            grid: {
              display: true // Remove horizontal gridlines
            },
           
          },
          right: {
            type: 'linear',
            position: 'right',
            beginAtZero: false,
        min: 1000,
        max: 9000,
        stepSize: 1000,
            grid: {
              display: false
            },
            scaleLabel: {
              display: true,
              labelString: 'Lhjkgjhj'
            },
          },
          x: {
            grid: {
              display: false // Keep vertical gridlines
            }
          }
        },
        plugins: {
          legend: {
            display: false,
           
           
          
           
         
          }
        }
      },
    });
  }, []);

  return (
    <div className="flex justify-center items-center">
        <div className="w-full h-[424px] bg-white rounded-md">
      <canvas className="w-full h-full" ref={chartRef} />
    </div>
      
    </div>
  );
};

export default BarlineChart;





  
