import React from 'react';
import { useSelector } from "react-redux";
import ProfileDetails from './backOffice/Tabs/Profile/ProfileDetails';

const UserProfile = () => {
  const authToken = useSelector((state) => state.auth.authToken);
  const user = useSelector((state) => state.auth.user);

  if(authToken) {
    return (
      user && user.Role !== "super_admin"? <ProfileDetails agentRootId={user && user.AgentID} />: <div className='text-black text-6xl m-auto'>Welcome to the Profile</div>
    )
  } else {
    // window.location.href = "/";
  }

}

export default UserProfile