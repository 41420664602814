import React from "react";

function Checklist() {
  return (
    <div>
      <div className="flex pl-5 w-full justify-between pt-2">
        <div className=" text-neutral-500 font-inter text-base">
          Complete your checklist to unlock the power of WealthSmyth Cloud.
        </div>
      </div>
      <div className="p-5">
        <div className="flex items-center">
          <input
            type="checkbox"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <div>
            <span className="ml-4 font-bold">On the Home tab</span>, review and
            update Key Fields for each Playbook stage, marking each one you’ve
            completed.
          </div>
        </div>
        <div className="ml-8 mb-5">About 5 min</div>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <div>
            <span className="ml-4 font-bold">Go to the Clients tab</span>, add yourself as a Client and set up your Financial Roadmap
          </div>
        </div>
        <div className="ml-8 mb-5">About 10 min</div>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <div>
            <span className="ml-4 font-bold">Go to the Leads tab</span>, add some prospects you’ve identified as possible Business Partners or Clients.
          </div>
        </div>
        <div className="ml-8 mb-5">About 5 min</div>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <div>
            <span className="ml-4 font-bold">Go to the Pipeline tab</span>, add any active Cases that are in progress.
          </div>
        </div>
        <div className="ml-8 mb-5">Various times</div>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <div>
            <span className="ml-4 font-bold">Go to the Team tab</span>, add any Business Partners in your downline.
          </div>
        </div>
        <div className="ml-8 mb-5">Various times</div>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <div>
            <span className="ml-4 font-bold">On the Team tab</span>, choose the Actions drop down and invite your Team to join you on WealthSmyth.
          </div>
        </div>
        <div className="ml-8 mb-5">About 1 min</div>
      </div>
    </div>
  );
}

export default Checklist;
