import React, { useState, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa6";
import info from "../../../../../assets/info.svg";
import tick from "../../../../../assets/green-tick.svg";
import GrantAccess from "./Popups/GrantAccess";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import useApi from "../../../../../custom-hooks/useApi";
import { BASE_URL } from "../../../../../appconfig";
import GFI from "../../../../../assets/Agencies/GFI.svg";
import { toast } from "react-toastify";
import Spinner from "../../../../common/Spinner";
import { useSelector } from "react-redux";

function ParentAgency({ edit1, edit2, toggle, data, change }) {
  const [loading, setLoading] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [agentCode, setAgentCode] = useState(null);
  const [registrationDate, setRegistrationDate] = useState(null);
  const [contractLevel, setContractLevel] = useState("");
  const [contractLevelData, setContractLevelData] = useState([]);
  const [uplineId, setUplineId] = useState("");
  const [uplineData, setUplineData] = useState("");
  const [baseData, setBaseData] = useState("");
  const [agency, setAgency] = useState("");
  const [agencyData, setAgencyData] = useState([]);
  const [isId, setIsId] = useState(false);
  const api = useApi();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (uplineId.length >= 5) { 
      fetchUpline(uplineId);
    }
  }, [uplineId]);

  const displayLevel = (id) => {
    const selectedLevel = contractLevelData.find(
      (level) => level?.Id?.toString() === id?.toString()
    );
    return selectedLevel ? selectedLevel.Level : null;
  };

  const displayAgency = (id) => {
    const selectedAgency = agencyData.find(
      (Id) => Id?.AgencyID?.toString() === id?.toString()
    );
    return selectedAgency ? selectedAgency.AgencyName : null;
  };

  useEffect(() => {
    fetchAgencyData();
    fetchLevelsData();

    if (data?.length !== 0) {
      setAgentCode(data?.AgentID);
      setRegistrationDate(new Date(data?.AgencyRegDate));
      setContractLevel(data?.ContractLevelId);
      setAgency(data?.AgencyId);
      if (data?.UplineID) {
        setUplineId(data?.UplineID);
        setIsId(true);
      }
      if (data?.UplineFirstName) {
        setUplineData(`${data?.UplineFirstName} ${data?.UplineLastName}`);
      } else {
        setUplineData(`-`);
      }
    }
  }, [data]);

  const fetchAgencyData = async () => {
    try {
      const response = await api.get(`${BASE_URL}/static/agencies`);
      setAgencyData(response.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchUpline = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `${BASE_URL}/Organization/UplineDetails/${uplineId}`
      );
      if (response?.data?.success) {
        setBaseData(response.data?.data?.BaseShopID);
        setUplineData(
          `${response.data?.data?.FirstName} ${response.data?.data?.LastName}`
        );
      }
    } catch (error) {
      setUplineData(error?.response?.data?.error);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchLevelsData = async () => {
    try {
      const response = await api.get(`${BASE_URL}/static/levels`);
      setContractLevelData(response.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agentCode || !contractLevel || !agency) {
      toast.error("please fill all fields");
      return;
    }
    setLoading(true);
    const json = {
      AgentID: agentCode,
      AgencyRegDate: format(registrationDate, "dd MMM yyyy"),
      ContractLevelId: contractLevel,
      AgencyId: agency,
      Progress: 2,
    };
    try {
      const response = await api.post(
        `${BASE_URL}/Organization/UserDetails-Stage1/${user.id}`,
        json
      );
      if (response.status === 200) {
        toast.success("Details Updated", {
          theme: "dark",
        });
        change();
        toggle();
      } else {
        toast.error("Failed to update details", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePopup = () => {
    if (uplineId.length < 5) {
      toast.error("Please select an upline first");
      return;
    } if (uplineData === "Agent not found") {
      toast.error("Please select a valid upline first");
      return;
    }
    setIsPopup(!isPopup);
  };

  return (
    <div>
      {loading && <Spinner />}
      <div className="flex pl-5 w-full justify-between pt-5">
        <div className="text-neutral-500 font-inter text-base">
          Ready to build your business and close more deals? Activate your
          Parent Agency Setting to automatically set up your list of providers,
          products and commission calculations. Connect to your Upline in order
          to work together as a team.
        </div>
      </div>
      {isPopup && (
        <GrantAccess
          toggle={handlePopup}
          uplineData={uplineData}
          BaseShopID={baseData}
          agency={displayAgency(agency)}
          uplineId={uplineId}
          change={toggle}
          refresh={change}
        />
      )}
      <div className="pl-6 mt-8 pr-6">
        <form onSubmit={handleSubmit} className="flex">
          <div className="w-[30%]">
            <div className="mb-6">
              <label className="block text-gray-600 text-sm font-bold mb-2">
                Agent Code
              </label>
              {edit1 ? (
                <input
                  className="shadow text-sm appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline border-none"
                  id="agentCode"
                  placeholder="Agent Code"
                  value={agentCode}
                  onChange={(e) => setAgentCode(e.target.value)}
                  required
                  minLength={5}
                  maxLength={10}
                  pattern="[A-Za-z0-9]+"
                  title="Agent Code should be alphanumeric and between 5-10 characters."
                />
              ) : (
                <span className="text-neutral-400">
                  {agentCode || "select agent code"}
                </span>
              )}
            </div>
            <div className="mb-6 text-left">
              <label className="block text-gray-600 text-sm font-bold mb-2">
                Original Agency Registration Date
              </label>
              {edit1 ? (
                <div className="flex w-[58%]">
                  <DatePicker
                    selected={registrationDate}
                    onChange={(date) => setRegistrationDate(date)}
                    className="border-none bg-gray-100  text-neutral-400 font-medium focus:outline-none focus:bg-white"
                    showYearDropdown
                    dateFormat="dd MMM yyyy"
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                    maxDate={new Date()}
                    required
                  />
                </div>
              ) : (
                <div className="text-neutral-400 flex items-center font-normal text-base">
                  {registrationDate
                    ? format(registrationDate, "dd MMM yyyy")
                    : "Select Registration Date"}
                </div>
              )}
            </div>
            <div className="mb-6">
              <div className="block text-gray-600 text-sm font-bold mb-2">
                Current Contract Level
              </div>
              {edit1 ? (
                <div className="flex drop-arrow items-center space-x-1 relative bg-gray-100">
                  <select
                    className="block outline-none bg-transparent cursor-pointer pr-6 shadow text-sm appearance-none rounded w-full py-2 px-3 text-gray-400 focus:outline-none focus:shadow-outline border-none"
                    value={contractLevel}
                    onChange={(e) => setContractLevel(e.target.value)}
                  >
                    <option value="" disabled>
                      Current contract level
                    </option>
                    {contractLevelData &&
                      contractLevelData?.map((level) => (
                        <option
                          className="text-gray-600"
                          key={level?.Id}
                          value={level?.Id}
                        >
                          {level?.Level}
                        </option>
                      ))}
                  </select>
                  <FaAngleDown className="text-gray-600 absolute right-2 pointer-events-none" />
                </div>
              ) : (
                <div className="text-neutral-400">
                  {displayLevel(contractLevel) || "Select Contract Level"}
                </div>
              )}
            </div>
            {edit1 && (
              <div className="flex mt-4 items-center justify-between">
                <button
                  style={{
                    background: "#2BB574",
                    height: "2.5rem",
                  }}
                  className="text-white w-full rounded py-2 px-3 font-inter"
                  type="submit"
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <div className="w-[70%] ml-[8%]">
            <div className="h-[60%] mb-6">
              <div className="flex justify-between w-full items-center">
                <div className="block text-gray-600 text-sm font-bold mb-2">
                  Agency
                </div>
                {edit1 && (
                  <div className="flex drop-arrow items-center space-x-1 mr-[50%] relative">
                    <select
                      className="block text-[#2BB574] text-sm font-bold outline-none appearance-none bg-transparent cursor-pointer border-none pr-6"
                      value={agency}
                      onChange={(e) => setAgency(e.target.value)}
                    >
                      <option value="" disabled>
                        Choose Agency
                      </option>
                      {agencyData &&
                        agencyData.map((agencyItem) => (
                          <option
                            className="text-gray-600"
                            key={agencyItem.Id}
                            value={agencyItem.AgencyID}
                          >
                            {agencyItem.AgencyName}
                          </option>
                        ))}
                    </select>
                    <FaAngleDown className="text-[#2BB574] absolute right-2 pointer-events-none" />
                  </div>
                )}
              </div>
              {!edit1 && (
                <div className="h-full p-3 mr-[50%]">
                  {agency === 1001 ? (
                    <img src={GFI} alt="gfi" />
                  ) : (
                    displayAgency(agency) || (
                      <span className="text-neutral-400">Select Agency</span>
                    )
                  )}
                </div>
              )}
            </div>
            {edit1 && (
              <div className="flex mt-[7%]">
                <img src={info} alt="info" className="mr-2" />
                <p className="text-sm text-gray-400">
                  You can set this option just once.
                </p>
              </div>
            )}
          </div>
        </form>
        <div className="border-t-2 border-gray-300 mt-5"></div>
        <div className="mt-5 flex w-[75%] justify-between">
          <div className="mb-2 mr-5 w-[40%]">
            <label className="block text-gray-600 text-sm font-bold mb-2">
              Direct Upline Name
            </label>
            <div
              className={uplineData === "Agent not found" ? "text-red-500" : ""}
            >
              {uplineData}
            </div>
          </div>
          <div className="mb-2 mr-8 w-[40%]">
            <label className="block text-gray-600 text-sm font-bold mb-2">
              Direct Upline Agent Code
            </label>
            {edit2 ? (
              <input
                value={uplineId}
                onChange={(e) => setUplineId(e.target.value)}
                className="shadow text-sm appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline border-none"
                id="directUplineAgentCode"
                placeholder="Upline Agent Code"
              />
            ) : (
              <div>{uplineId || "Enter upline Code"}</div>
            )}
          </div>
          <div className="w-[30%] flex items-center justify-center">
            {!isId ? (
              <button
                onClick={handlePopup}
                style={{
                  background:
                    "linear-gradient(261deg, #5392FF 13.57%, #2B5EB7 79.95%)",
                  height: "2.5rem",
                }}
                className="text-white rounded py-2 px-3 font-inter mt-7"
                type="button"
              >
                Connect to your Upline
              </button>
            ) : (
              <button className="flex items-center bg-[#c4f9e0] text-[#2BB574] rounded-3xl px-3 py-2">
                <img src={tick} className="mr-2" alt="tick" />
                You are connected
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParentAgency;
