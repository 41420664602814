import React, { useEffect, useState, useRef } from 'react';
import { FiMoreHorizontal, FiSave, FiX } from 'react-icons/fi';
import { formatUSPhoneNumber, formatDate } from '../../../utills';
import { ReactComponent as SaveIcon } from "../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../src/assets/cancel.svg";
import { toast } from 'react-toastify';
import useApi from "../../../custom-hooks/useApi";
import Spinner from '../../common/Spinner';
import { BASE_URL } from '../../../appconfig';
import CountryStateDropdown from '../campaigns/dropdowns/CountryStateDropdown';

function RelatedBusinessEntity({ businessList, reloadItems, entityList, setEdit, newForm }) {
    const api = useApi();

    const [dropdownIndex, setDropdownIndex] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [editData, setEditData] = useState({});
    const [statesData, setStatesData] = useState([]);
    const dropdownRef = useRef(null);
    const editButtonRefs = useRef([]);
    const deleteButtonRefs = useRef([]);


    const [loading, setLoading] = useState(false)

    const handleDropdown = (index) => {
        setDropdownIndex(dropdownIndex === index ? null : index);
    };

    const handleEdit = async (index, businessItem) => {        
        setEditIndex(index);
        setEdit();
        setEditData({
            ...editData,
            companyID:businessItem?.Company?.ID,
            companyName: businessItem?.Company?.companyName,
            companyState: businessItem?.Company?.companyState,
            EntityTypeID: businessItem?.EntityType?.ID,
            annualRevenue: businessItem?.Company?.annualRevenue,
            RelatedEntityID: businessItem?.RelatedEntityID

        });
        // handleStateClick(businessItem?.Company?.companyState)

        setDropdownIndex(null);
    };

    const handleCancelEdit = () => {
        setEditIndex(null);
        setEditData({});
    };

    useEffect(()=>{
        handleCancelEdit();
    },[newForm])

    const handleSaveEdit = async () => {
        if (!validateInputs()) {
            return;
        }
        try {            
            setLoading(true)
            const response = await api.put(`${BASE_URL}/Account/Related_Business/${editData?.RelatedEntityID}`, editData);
            if (response?.status === 200) {
                setEditIndex(null);
                toast.success(response?.data?.message);
                reloadItems()
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    };

    const validateInputs = () => {
        const newErrors = {};

        const { companyName, annualRevenue } = editData;

        if (!companyName || !annualRevenue) {
            toast.error('All fields are required.');
            return false;
        }

        if (annualRevenue < 0) newErrors.annualRevenue = "Net Income can not be negative";
        return true;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    const handleDelete = async (RelatedPersonId) => {
        try {
            setLoading(true);
            const response = await api.delete(`${BASE_URL}/Account/Related_Business/${RelatedPersonId}`);
            if (response?.status === 200) {
                toast.success(response?.data?.message)
                reloadItems();
                setDropdownIndex(null)
            }

            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error)
        }
    }

    const handleStateClick = (state) => {
        setEditData({
            ...editData,
            companyState: state?.name
        })
    };

    useEffect(() => {
        let isMounted = true;

        const fetchCountryData = async () => {
            try {
                setLoading(true);
                const response = await api.get(
                    `${BASE_URL}/RingCentral/Get_All_States`
                );
                if (isMounted) {
                    setStatesData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchCountryData();
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !editButtonRefs.current.some(ref => ref && ref.contains(event.target)) &&
            !deleteButtonRefs.current.some(ref => ref && ref.contains(event.target))
          ) {
            setDropdownIndex(null);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

    return (

        <tbody>
            {
                businessList?.length > 0 ? (
                    businessList.map((businessItem, index) => (
                        <tr key={index} className={`border-[#D1D5DB] ${index + 1 === businessList?.length ? "" : "border-b"}`}>
                            <td className="p-4 text-sm text-[#3A4253] font-inter font-normal">
                                {editIndex === index ? (
                                    <div className="flex gap-2 ">
                                        <input
                                            type="text"
                                            name="companyName"
                                            value={editData?.companyName}
                                            onChange={handleInputChange}
                                            className="w-[100px] appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                                        />
                                    </div>

                                ) : (
                                    businessItem?.Company?.companyName
                                )}
                            </td>
                            <td className="p-4 text-sm text-[#3A4253] font-normal font-inter ">
                                {editIndex === index ? (
                                    <select
                                        type="text"
                                        name="EntityTypeID"
                                        value={editData?.EntityTypeID}
                                        onChange={handleInputChange}
                                        // onChange={(e) => setEditData({ ...editData, RelationshipToAccount: e.target.value })}
                                        className="appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                                    >
                                        <option value="">Entity Type</option>

                                        {
                                            entityList?.length > 0 ? (
                                                <>
                                                    {
                                                        entityList?.map((entityItem) => (
                                                            <option value={entityItem?.ID}>{entityItem?.EntityTypeName}</option>
                                                        ))
                                                    }
                                                </>
                                            ) : null
                                        }

                                    </select>
                                ) : (
                                    businessItem?.EntityType?.EntityTypeName
                                )}
                            </td>
                            <td className="p-4 text-sm text-[#3A4253] font-inter font-normal">
                                {editIndex === index ? (
                                    <CountryStateDropdown
                                        stateClick={handleStateClick}
                                        data={statesData}
                                        textsm
                                        isLabel={false}
                                        initialSelectedState={businessItem?.Company?.companyState}
                                    />
                                ) : (
                                    businessItem?.Company?.companyState
                                )}
                            </td>
                            <td className="p-4 text-sm text-[#3A4253] font-inter font-normal">
                                {editIndex === index ? (
                                    <input
                                        type="text"
                                        name="annualRevenue"
                                        value={editData?.annualRevenue}
                                        onChange={handleInputChange}
                                        className="appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                                    />
                                ) : (
                                    businessItem?.Company?.annualRevenue || "-"
                                )}
                            </td>
                            <td className="relative p-4 font-medium text-[#4871B7] cursor-pointer"
                                ref={(el) => (dropdownRef.current = el)}
                            >
                                {editIndex === index ? (
                                    <div className="flex  gap-2">
                                        <button
                                            className="disabled:opacity-50 disabled:cursor-not-allowed"
                                            onClick={handleSaveEdit}
                                        >
                                            <SaveIcon />
                                        </button>
                                        <CancelIcon
                                            className="cursor-pointer"
                                            onClick={handleCancelEdit}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <FiMoreHorizontal onClick={() => handleDropdown(index)} />
                                        {dropdownIndex === index && (
                                            <div className="absolute right-0 mt-2 w-48 bg-white">
                                                <div className="absolute text-gray-600 z-10 w-auto bg-white border rounded shadow-lg  mr-14 right-0">
                                                    <ul className="border rounded">
                                                        <li
                                                            className="edit-button border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                                                            onClick={() => handleEdit(index, businessItem)}
                                                            ref={(el) => editButtonRefs.current[index] = el}
                                                        >
                                                            Edit
                                                        </li>
                                                        <li
                                                            onClick={() => handleDelete(businessItem?.RelatedEntityID)}
                                                            ref={(el) => deleteButtonRefs.current[index] = el}
                                                            className="delete-button border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                                                        >
                                                            Delete
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </td>
                        </tr>
                    ))
                ) : null
            }
        </tbody>

    )
}

export default RelatedBusinessEntity;
