export const list = {
    digitalplaybook: {
      title: "Digital playbook",
      pricingList: [
        {
          pricingItem:
            "Proven system for launching and growing your independent business",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Fast start guidance and support",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Promotion gamification and badges",
          essential: true,
          professional: false,
          owner: false,
        },
        {
          pricingItem: "Taprooting tools ",
          essential: true,
          professional: true,
          owner: false,
        },
        {
          pricingItem: "Field trainer certification",
          essential: true,
          professional: false,
          owner: false,
        },
        {
          pricingItem: "Leadership development",
          essential: true,
          professional: true,
          owner: false,
        },
      ],
    },
    leadengagement: {
      title: "Lead engagement",
      pricingList: [
        {
          pricingItem: "Get Leads from WealthSmyth Chat and Contact Us",
          essential: false,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Generate social leads with personal QR Code",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Generate leads with personal web page",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Import lead lists in CSV format",
          essential: true,
          professional: true,
          owner: false,
        },
        {
          pricingItem: "Manage all leads from one page console",
          essential: true,
          professional: false,
          owner: false,
        },
        {
          pricingItem: "Generate leads with SMS outreach campaigns",
          essential: false,
          professional: "upgrade",
          owner: true,
        },
        {
          pricingItem: "Automatic lead nurturing ",
          essential: true,
          professional: true,
          owner: "coming soon",
        },
      ],
    },
    clientaccounts: {
      title: "Client accounts",
      pricingList: [
        {
          pricingItem: "Household and Business Accounts",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Related people and entities",
          essential: false,
          professional: false,
          owner: true,
        },
        {
          pricingItem: "Applications and renewals",
          essential: false,
          professional: false,
          owner: true,
        },
        {
          pricingItem: "Providers",
          essential: false,
          professional: false,
          owner: true,
        },
        {
          pricingItem: "Points",
          essential: false,
          professional: false,
          owner: true,
        },
        {
          pricingItem: "Commissions, splits, and overrides",
          essential: false,
          professional: false,
          owner: true,
        },
      ],
    },
    trainingcenter: {
      title: "Training center",
      pricingList: [
        {
          pricingItem: "Mobile and web access to training content",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Organize training by audiences",
          essential: false,
          professional: false,
          owner: true,
        },
        {
          pricingItem: "Create on-demand online trainings",
          essential: false,
          professional: false,
          owner: true,
        },
        {
          pricingItem: "Create quizzes and certification exams",
          essential: false,
          professional: false,
          owner: true,
        },
      ],
    },
    datatransparency: {
      title: "Data transparency",
      pricingList: [
        {
          pricingItem: "Full data transparency to your downline",
          essential: false,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Personal business ratios",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Downline business ratios",
          essential: false,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Standard business reports",
          essential: false,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Effort and effectiveness reporting",
          essential: false,
          professional: "upgrade",
          owner: true,
        },
      ],
    },
    backofficemanagement: {
      title: "Back office management",
      pricingList: [
        {
          pricingItem: "Easily import team, lead, and client data ",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Manage user access to team resources",
          essential: false,
          professional: false,
          owner: true,
        },
        {
          pricingItem: "Delegate system management to staff",
          essential: false,
          professional: false,
          owner: true,
        },
        {
          pricingItem: "Manage promotions and advancement",
          essential: false,
          professional: false,
          owner: true,
        },
      ],
    },
    productivityandsharing: {
      title: "Productivity and sharing",
      pricingList: [
        {
          pricingItem: "Trusted user identity for team access and sharing",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Business email and calendar (you@wealthsmyth.com)",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem:
            "Web and mobile versions of Word, Excel, PowerPoint, and Outlook",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Chat, call, and video conference with Microsoft Teams",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "1 TB of cloud storage per user",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem:
            "Desktop versions of Word, Excel, PowerPoint, Outlook, and Teams",
          essential: "upgrade",
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Automatic spam and malware filtering",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Anytime phone and web support",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem: "Copilot for Microsoft 365 available as an add on",
          essential: "upgrade",
          professional: "upgrade",
          owner: "upgrade",
        },
      ],
    },
    transformworkwithartificialintelligence: {
      title: "Transform Work with Artificial Intelligence",
      pricingList: [
        {
          pricingItem:
            "Get answers fast: state-of-the-art Life Insurance GPT for indy agents",
          essential: true,
          professional: true,
          owner: true,
        },
        {
          pricingItem:
            "Track chats and meetings in real time and produce conversation summaries",
          essential: "upgrade",
          professional: "upgrade",
          owner: "upgrade",
        },
        {
          pricingItem:
            "Clear your inbox and draft professional replies in minutes, not hours",
          essential: "upgrade",
          professional: "upgrade",
          owner: "upgrade",
        },
        {
          pricingItem:
            "Transform your ideas and conversations into stunning presentations",
          essential: "upgrade",
          professional: "upgrade",
          owner: "upgrade",
        },
        {
          pricingItem:
            "Create first drafts, edit, and summarize your documents",
          essential: "upgrade",
          professional: "upgrade",
          owner: "upgrade",
        },
        {
          pricingItem: "Analyze and visualize data expertly",
          essential: "upgrade",
          professional: "upgrade",
          owner: "upgrade",
        },
      ],
    },
    cloudphonesystem: {
      title: "Cloud phone system",
      pricingList: [
        {
          pricingItem: "Local business phone number with line sharing option",
          essential: "upgrade",
          professional: "upgrade",
          owner: true,
        },
        {
          pricingItem: "Unlimited domestic calling in US/Canada",
          essential: "upgrade",
          professional: "upgrade",
          owner: true,
        },
        {
          pricingItem: "SMS and MMS",
          essential: "upgrade",
          professional: "upgrade",
          owner: true,
        },
        {
          pricingItem: "Call monitoring with whisper for sales training",
          essential: "upgrade",
          professional: "upgrade",
          owner: true,
        },
        {
          pricingItem: "Auto attendant and IVR",
          essential: "upgrade",
          professional: "upgrade",
          owner: true,
        },
      ],
    },
    workflowautomation: {
      title: "Workflow automation",
      pricingList: [
        {
          pricingItem: "Microsoft Power Automate Premium for one user",
          essential: false,
          professional: false,
          owner: "upgrade",
        },
        {
          pricingItem: "Create digital business processes (cloud flows)",
          essential: false,
          professional: false,
          owner: "upgrade",
        },
        {
          pricingItem: "Automate interactions across applications",
          essential: false,
          professional: false,
          owner: "upgrade",
        },
        {
          pricingItem: "Use pre-built and premium connectors",
          essential: false,
          professional: false,
          owner: "upgrade",
        },
        {
          pricingItem: "Infuse AI as needed",
          essential: false,
          professional: false,
          owner: "upgrade",
        },
      ],
    },
  };

export  const cloudCardListItems = {
    modernexperience : [
      'Branded Experience',
      'Guided Generative AI Training',
      'Shared Data Ownership',
      'Consistency and Compliance at Scale',
      'Gamification and Badges',
      '20+ Releases per Year',
    ],
    newrevenuestream: [
      'Leverage purchasing power to drive profits',
      'Managed support and operations service',
      'Outcome-based Pricing by WealthSmyth',
    ]
  }