import React, { useState } from 'react';
import downArrow from '../../../../src/assets/downarrow.svg';
import upArrow from '../../../../src/assets/uparrow.svg';
import TapUserIcon from '../../../../src/assets/tap-user.svg';
import deleteIcon from '../../../assets/delete.svg';
import NewAssociate from './popups/NewAssociate';
import { toast } from 'react-toastify';
import Spinner from '../../common/Spinner';
import { BASE_URL, IMAGE_URL } from '../../../appconfig';
import useApi from '../../../custom-hooks/useApi';
import { useNavigate } from 'react-router-dom';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const DownlineItem = ({ downline, isLastChild, onDelete, onAgentClick }) => (
  <div className="px-6">
    <div className="flex items-center">
      <div
        className={`${'h-9'} bg-vertical-line`}
        style={{
          backgroundColor: '#4B5563',
          width: '1.5px',
          marginLeft: '1.3px',
        }}
      />
      <div className="w-full px-2 flex items-center">
        <img
          className="cursor-pointer h-[30px] w-[30px] rounded-full"
          style={{
            marginRight: '0.5rem',
          }}
          src={downline.ImageName ? `${IMAGE_URL}/${downline.ImageName}.jpg` : TapUserIcon}
          alt="profile"
        />
        <div className="w-full flex items-center justify-between">
          <div onClick={() => onAgentClick(downline)} className="cursor-pointer text-white text-sm flex-1 pr-2 overflow-hidden whitespace-nowrap">
            {downline.AgentRootName}
          </div>
          {isLastChild && (<div>
            <img
              style={{
                marginLeft: '0.5rem',
              }}
              className="cursor-pointer h-[18px ] w-[18px]"
              src={deleteIcon}
              alt="delete-icon"
              onClick={() => onDelete(downline)}
            />
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

const DownlinesList = ({ downlines, deleteAssociate, onAgentClick }) => (
  <div className="mb-1">
    {downlines.map((downline, index) => (
      <DownlineItem
        key={Math.random()}
        isLastChild={index === downlines.length - 1}
        downline={downline}
        onDelete={() => deleteAssociate(downline)}
        onAgentClick={() => onAgentClick(downline)}
      />
    ))}
  </div>
);

const TreeNode = ({ node, isRoot, change, isSidebarOpen }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [selectedColor] = useState(isRoot ? getRandomColor() : "#D1D5DB");
  const [associatePopup, setAssociatePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const api = useApi();
  let uplineAgentId = "";
  let uplineAgent = "";

  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  const deleteAssociate = async ({ AgentRootID, TapRootID }) => {
    if (isDeleting) {
      return;
    }
    try {
      setIsDeleting(true);
      const response = await api.delete(
        `${BASE_URL}/Backoffice/TapRoot/${TapRootID}/${AgentRootID}`
      );

      if (response.status === 200) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        change();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to delete record", {
        theme: "dark",
      });
      console.error("Error deleting data:", error);
    } finally {
      setIsDeleting(false);
      setLoading(false);
    }
  };

  const togggleAssociate = () => {
    setAssociatePopup(!associatePopup);
  };

  if (node.associateArr && node.associateArr.length > 0) {
    uplineAgent = node.associateArr[node.associateArr.length - 1].AgentRootName;
    uplineAgentId = node.associateArr[node.associateArr.length - 1].AgentRootID;
  } else {
    uplineAgent = node.AgentRootName;
    uplineAgentId = node.AgentRootID;
  }

  const handleAgentNameClick = ({ organizationId }) => {
    navigate(`/agent/${encodeURIComponent(btoa(String(organizationId)))}`);
  };

  const newAssociate = () => {
    setExpanded(true);
  };

  return (
    <div>
      {loading && <Spinner />}
      <div className="flex items-center px-6">
        <div className="w-full flex items-center">
          <div
            className={`w-1 ${"h-9"} bg-vertical-line rounded`}
            style={{ backgroundColor: selectedColor, left: "-0.25rem" }}
          />
          {isSidebarOpen && (
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center">
                <img
                  className="ml-2 cursor-pointer h-[30px] w-[30px] rounded-full"
                  src={
                    node.ImageName ? `${IMAGE_URL}/${node.ImageName}.jpg` : TapUserIcon
                  }
                  alt="profile"
                />
                <p
                  onClick={() => handleAgentNameClick(node)}
                  className="ml-2 text-white text-sm cursor-pointer"
                >
                  {node.AgentRootName}
                </p>
              </div>
              <div className="items-center">
                {node.associateArr.length === 0 && (
                  <img
                    onClick={() => deleteAssociate(node)}
                    className="cursor-pointer h-[18px ] w-[18px]"
                    src={deleteIcon}
                    alt="delete"
                  />
                )}
              </div>
              <div className="cursor-pointer" onClick={toggleExpand}>
                {node.associateArr && (
                  <img
                    className="mr-2"
                    src={isExpanded ? downArrow : upArrow}
                    alt="down-up-arrow"
                  />
                )}
              </div>
            </div>
          )}
          {!isSidebarOpen && (
            <div className="w-full flex items-center justify-end">
              <div className="flex items-center">
                <img
                  className="ml-2 cursor-pointer h-[30px] w-[30px] rounded-full"
                  style={{
                    border: `2px solid ${selectedColor}`,
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)'
                  }}
                  src={
                    node.ImageName ? `${IMAGE_URL}/${node.ImageName}.jpg` : TapUserIcon
                  }
                  alt="profile"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isExpanded && node.associateArr && (
        <DownlinesList
          deleteAssociate={deleteAssociate}
          downlines={node.associateArr}
          onAgentClick={handleAgentNameClick}
        />
      )}
      <div className="mb-4 mt-2 flex items-center justify-center">
        <p
          onClick={togggleAssociate}
          className="text-green-600 text-xs font-bold mr-4 cursor-pointer"
        >
          + New Associate
        </p>
        {associatePopup && (
          <NewAssociate
            newAdded={newAssociate}
            change={change}
            uplineAgent={uplineAgent}
            uplineId={uplineAgentId}
            toggle={togggleAssociate}
          />
        )}
      </div>
    </div>
  );
};

const TreeView = ({ data, change, isSidebarOpen }) => (
  <div>
    <TreeNode
      node={data}
      isRoot
      change={change}
      isSidebarOpen={isSidebarOpen}
    />
  </div>
);

export default TreeView;