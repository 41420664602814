import React, { useState } from 'react';
import RetirementCalculator from './RetirementCalculator';
import ProperProtection from './ProperProtection';

function RoadMap({ AccountID  , account , loadAccount}) {
    const [activeButton, setActiveButton] = useState('Retirement Calculator');

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    return (
        <div>
            <div className="flex mt-10">
                <button
                    className={`px-4 py-2 rounded font-semibold font-inter ${activeButton === 'Retirement Calculator'
                        ? 'bg-[#4871B7] text-white'
                        : 'bg-[#E5E7EB] text-[#4871B7]'
                        }`}
                    onClick={() => handleButtonClick('Retirement Calculator')}
                >
                    Retirement Calculator
                </button>

                <button
                    className={`px-4 py-2 rounded font-semibold font-inter ${activeButton === 'Proper protections'
                        ? 'bg-[#4871B7] text-white'
                        : 'bg-[#E5E7EB] text-[#4871B7]'
                        }`}
                    onClick={() => handleButtonClick('Proper protections')}
                >
                    Proper protections
                </button>

            </div>
            {
                activeButton === "Retirement Calculator" ?
                    (
                        <div className="shadow">
                            <RetirementCalculator AccountID={AccountID}  account={account} onSave={loadAccount}/>

                        </div>
                    ) : null
            }

{
                activeButton === "Proper protections" ?
                    (
                        <div className="shadow">
                            <ProperProtection  AccountID={AccountID}  account={account} onSave={loadAccount} />

                        </div>
                    ) : null
            }
        </div>
    );
}

export default RoadMap;
