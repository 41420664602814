import React, { useState, useEffect, useRef } from "react";
import TableHeader from "./TableHeader";
import { useNavigate } from "react-router-dom";
import MenuBar from "./Menubar";
import Spinner from "../common/Spinner";
import { toast } from "react-toastify";
import useApi from "../../custom-hooks/useApi";
import { format, isValid } from "date-fns";
import { useSelector } from "react-redux";
import historyIcon from "../../assets/history.svg";
import EmailHistoryPopup from "./EmailHistoryPopup";

const MAX_VISIBLE_PAGES = 5;

const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
  const calculateVisiblePages = () => {
    const pages = [];
    const startPage = Math.max(
      1,
      Math.min(
        currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
        totalPages - MAX_VISIBLE_PAGES + 1
      )
    );
    const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const visiblePageNumbers = calculateVisiblePages();

  if (visiblePageNumbers.length > 1) {
    return (
      <>
        <div
          className={`flex items-center mx-5 ${
            currentPage < totalPages ? "sticky" : "absolute"
          } bottom-0 `}
        >
          {currentPage > 1 && currentPage <= totalPages && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 cursor-pointer"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          )}
          {visiblePageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              style={{
                borderRadius: "3rem",
                backgroundColor:
                  currentPage === pageNumber ? "#3182CE" : "white",
                color: currentPage === pageNumber ? "white" : "black",
              }}
              type="button"
              onClick={() => handlePageChange(pageNumber)}
              className="font-inter mt-2 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 me-2 mb-2 dark:hover:bg-gray-800 focus:outline-none dark:focus:ring-blue-800"
            >
              {pageNumber}
            </button>
          ))}
          {totalPages > 1 && currentPage < totalPages && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 cursor-pointer"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          )}
        </div>
      </>
    );
  }
};

const Table = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const [totalcount, setTotalcount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState({ key: "", value: "" });
  const [sortedColumn, setSortedColumn] = useState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [mailSent, setMailSent] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [emailHistoryPopup, setEmailHistoryPopup] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [queryParams, setQueryParams] = useState({});
  const [additionalQueryParams, setAdditionalQueryParams] = useState({});
  const user = useSelector(state => state.auth.user);
  // Ref to store the previous value of queryParams
  const prevQueryParams = useRef();
  const api = useApi();

  const setFilterData = (filter) => {
    setFilter(filter);
  };

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const toggleEmailHistory = () => {
    setEmailHistoryPopup(!emailHistoryPopup);
  }

  const handleSearchDebounced = debounce((term) => {
    setSearchTerm(term);
  }, 1000);

  const setSearchData = (term) => {
    if ((searchTerm.length >= 3 && term.length === 0) || term.length >= 3) {
      handleSearchDebounced(term);
    }
  };

  const handleCheckboxChange = (page, index, agent) => {
    const pageKey = `page${page}`;
    const updatedSelectedCheckboxes = { ...selectedCheckboxes };
    
    if (!updatedSelectedCheckboxes[pageKey]) {
      updatedSelectedCheckboxes[pageKey] = [];
    }
  
    const selectedIndex = updatedSelectedCheckboxes[pageKey].indexOf(index);
    if (selectedIndex === -1) {
      updatedSelectedCheckboxes[pageKey].push(index);
      setSelectedCheckboxes(updatedSelectedCheckboxes);
  
      // Add the agent object to selectedAgents
      setSelectedAgents([...selectedAgents, agent]);
    } else {
      updatedSelectedCheckboxes[pageKey].splice(selectedIndex, 1);
      setSelectedCheckboxes(updatedSelectedCheckboxes);
      setSelectedAgents(selectedAgents.filter(selectedAgent => selectedAgent.ID !== agent.ID));
    }
  };
  

  const handleHeaderCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    const updatedSelectedCheckboxes = {};
  
    if (isChecked) {
      data.forEach((_, index) => {
        const pageKey = `page${currentPage}`;
        if (!updatedSelectedCheckboxes[pageKey]) {
          updatedSelectedCheckboxes[pageKey] = [];
        }
        updatedSelectedCheckboxes[pageKey].push(index);
      });
    }
  
    setSelectedCheckboxes(updatedSelectedCheckboxes);

    if (isChecked) {
      setSelectedAgents([...data]); // Add all agents to selectedAgents
    } else {
      setSelectedAgents([]); // Reset selectedAgents
    }
  };

  const handleMailSent = () => {
    setMailSent(true);
  }
  
  useEffect(() => {
    setSelectAllChecked(false);
    setSelectedCheckboxes({});
    setSelectedAgents([]);
    setMailSent(false);
  }, [mailSent]);

  useEffect(() => {
    const anyCheckboxSelected = Object.values(selectedCheckboxes).flat().length > 0;
    setIsSelected(anyCheckboxSelected);
  }, [selectedCheckboxes]);

  const handleHistory = (agentId) => {
    setSelectedAgent(agentId);
    setEmailHistoryPopup(true);
  }

  const navigate = useNavigate();

  const headings = [
    "Name",
    "Level",
    "Date Last Published",
    "Current Status",
    "Downline Team",
    "Licensed Agents",
    "Points",
    "Upline",
  ];

  const [sortColumns, setSortColumns] = useState({
    Name: { sortOrder: "asc" },
    Upline: { sortOrder: "asc" },
    Level: { sortOrder: "asc" },
    "Current Status": { sortOrder: "asc" },
    "Date Last Published": { sortOrder: "asc" },
    "Downline Team": { sortOrder: "asc" },
    "Licensed Agents": { sortOrder: "asc" },
    Points: { sortOrder: "asc" },
  });

  const handleSortChange = (column) => {
    const allowedSortColumns = [
      "Name",
      "Upline",
      "Level",
      "Current Status",
      "Date Last Published",
      "Downline Team",
      "Licensed Agents",
      "Points",
    ];
    if (allowedSortColumns.includes(column)) {
      const currentSortOrder = sortColumns[column]?.sortOrder || "asc";
      const newSortOrder = currentSortOrder === "asc" ? "desc" : "asc";

      console.log("sortfeild:", column);
      console.log("sortOrder:", newSortOrder);

      setSortColumns((prevSortColumns) => ({
        ...Object.fromEntries(
          Object.keys(prevSortColumns).map((key) => [key, { sortOrder: "asc" }])
        ),
        [column]: { sortOrder: newSortOrder },
      }));

      // Update the sortedColumn state
      setSortedColumn(column);
    }
  };

  const deepEqual = (obj1, obj2) => {
    if (
      obj1 === null ||
      obj1 === undefined ||
      obj2 === null ||
      obj2 === undefined
    ) {
      return false;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Iterate over the keys and compare values
    for (const key of keys1) {
      // Check if the key exists in both objects
      if (!keys2.includes(key)) {
        return false;
      }

      // Recursively compare nested objects
      if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
        if (!deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      } else {
        // Compare primitive values
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    // If all keys and values match, the objects are equal
    return true;
  };

  useEffect(() => {
    const executeQuery = () => {
      const columnToApiField = {
        Name: "AgentName",
        Level: "TitleLevel",
        "Current Status": "StepID",
        "Date Last Published": "DateLastPublished",
        Upline: "UplineName",
        "Downline Team": "Recruits",
        "Licensed Agents": "LicenseAgent",
        Points: "Points",
      };
      const { sortOrder } = sortColumns[sortedColumn] || {
        sortOrder: "asc",
      };

      let additionalQueries = {
        pageNumber: currentPage,
        pageSize: itemsPerPage,
        sortfield: columnToApiField[sortedColumn],
        sortOrder: sortOrder,
      };
      let queries = {};
      if (
        filter.key !== "" &&
        filter.key !== null &&
        filter.key !== undefined
      ) {
        queries[filter.key] = filter.value;
      }
      if (searchTerm && searchTerm.length >= 3) {
        queries["SearchTerm"] = searchTerm;
      }

      // Check if queryParams have changed
      if (!deepEqual(queries, prevQueryParams.current)) {
        if (
          additionalQueries.pageNumber !== 1 ||
          additionalQueries.sortfield !== columnToApiField["Name"] ||
          additionalQueries.sortOrder !== "asc"
        ) {
          setCurrentPage(1);
          setSortColumns((prevSortColumns) => ({
            ...Object.fromEntries(
              Object.keys(prevSortColumns).map((key) => [
                key,
                { sortOrder: "asc" },
              ])
            ),
            Name: { sortOrder: "asc" },
          }));
          setSortedColumn("Name");
          return;
        }
      } else {
        additionalQueries.pageNumber = currentPage;
      }
      // Set queryParams and update the ref
      setQueryParams(queries);
      prevQueryParams.current = queries;

      setAdditionalQueryParams(additionalQueries);
    };
    executeQuery();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortColumns, sortedColumn, filter, searchTerm]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get("/Organization/Agent_Details", {
          params: { ...additionalQueryParams, ...queryParams },
        });

        const responseData = response.data;

        setData(responseData.organizations);
        setTotalcount(responseData.totalcount);
        toast.success(response.data.message, { theme: "dark" });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error("Error fetching data:", error);
        let message = error.response.data.message || "Error fetching data!";
        console.log(message);
        // toast.error(message, { theme: "dark" });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setData([]);
        setTotalcount(0);
      }
    };
    if (queryParams) {
      fetchData();
    }
  }, [queryParams, additionalQueryParams, api]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalcount / itemsPerPage);

  const exportAgentDetails = () => {
    const handleDownloadCSV = async () => {
      setLoading(true);
      try {
        const response = await api.get("/Organization/ExportAgentDetails", {
          params: { ...additionalQueryParams, ...queryParams },
        });

        if(response.status === 200 && response.data && response.data.organizations && response.data.organizations.length > 0) {
          const responseData = response.data.organizations;
          // Convert JSON data to CSV format
          // Define mapping for headers
          const headerMapping = {
            AgentID: "Agent ID",
            UplineID: "Upline ID",
            AgentName: "Agent Name",
            UplineName: "Upline Name",
            level_description: "Level",
            Recruits: "Downline Team",
            LicenseAgent: "Licensed Agents",
            DateLastPublished: "Date Last Published",
            Points: "Points",
            CurrentStatus: "Current Status",
          };

          // Convert JSON data to CSV format
          const csvData = [
            Object.values(headerMapping).join(","), // Headers
            ...responseData.map((item) =>
              Object.keys(item)
                .filter((key) => key !== "ID") // Exclude 'ID' column
                .map((key) => item[key])
                .join(",")
            ), // Rows
          ].join("\n");

          // Convert CSV string to Blob
          const blob = new Blob([csvData], { type: "text/csv" });

          // Create a temporary URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = `AgentDetails_${user.BaseShopID}_${user.AgentID}.csv`; // Set the filename for the downloaded file

          // Trigger the click event to initiate the download
          link.click();

          // Cleanup: Revoke the temporary URL
          window.URL.revokeObjectURL(url);
        } else {
          throw new Error("No Records Found.");
        }


      } catch (error) {
        console.error("Error downloading CSV:", error);
        toast.error(error.message, { theme: 'dark' });
      } finally {
        setLoading(false);
      }
    };

    if (queryParams && data && data.length > 0) {
      handleDownloadCSV();
    }
  };

  return (
    <>
      {loading && <Spinner />}
      {emailHistoryPopup && <EmailHistoryPopup agentId={selectedAgent} toggle={toggleEmailHistory} />}
      <MenuBar
        setFilterData={setFilterData}
        setSearchData={setSearchData}
        exportAgentDetails={exportAgentDetails}
        isSelected={isSelected}
        agentData={selectedAgents}
        mailsent={handleMailSent}
      />
      <div
        style={{ maxWidth: "95%", minWidth: "95%" }}
        className="flex flex-col justify-center mx-auto"
      >
        <div className=" sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-4 lg:px-6">
            <div className="overflow-hidden">
              <table className="min-w-full mx-auto text-left text-sm font-light z-0">
                <TableHeader
                  headings={headings}
                  sortColumns={sortColumns}
                  onSortChange={handleSortChange}
                  checked={selectAllChecked}
                  onCheck={handleHeaderCheckboxChange}
                />
                <tbody>
                  {data.map((agent, index) => (
                    <tr
                      key={index}
                      className="border-b dark:border-neutral-500"
                    >
                      <td className="whitespace-break-spaces px-3 py-3">
                        <div className="flex items-center">
                          <input
                            id={`checkbox-${currentPage}-${index}`}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={selectedCheckboxes[
                              `page${currentPage}`
                            ]?.includes(index)}
                            onChange={() =>
                              handleCheckboxChange(currentPage, index, agent)
                            }
                          />
                        </div>
                      </td>
                      <td
                        style={{ color: "#32A56D" }}
                        className={`whitespace-nowrap px-3 py-3 font-medium font-inter cursor-pointer`}
                        onClick={() =>
                          navigate(
                            `/agent/${encodeURIComponent(
                              btoa(String(agent.ID))
                            )}`
                          )
                        }
                      >
                        <span className="text-[#4871B7] hover:text-blue-700">
                          {agent.AgentName} ({agent.AgentID})
                        </span>
                      </td>

                      <td className="whitespace-nowrap px-3 py-3 font-inter">
                        {agent.level_description || "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 font-inter">
                        {agent.DateLastPublished != null
                          ? isValid(new Date(agent.DateLastPublished))
                            ? format(
                                new Date(agent.DateLastPublished),
                                "MM/dd/yy - h a"
                              )
                            : "-"
                          : "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 font-inter">
                        {agent.CurrentStatus || "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 font-inter">
                        {agent.Recruits || "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 font-inter">
                        {agent.LicenseAgent || "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-3 font-inter">
                        {agent.Points || "-"}
                      </td>
                      <td
                        style={{ color: "#32A56D" }}
                        className="whitespace-nowrap px-3 py-3 font-medium"
                      >
                        <span className="text-[#4871B7] hover:text-blue-700">
                          {agent.UplineName} ({agent.UplineID})
                        </span>
                      </td>
                      <td className="cursor-pointer">
                      <img
                        onClick={() => handleHistory(agent.AgentID)}
                        className="h-4 w-4"
                        src={historyIcon}
                        alt="history"
                        title="Email History"
                      />
                    </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {totalPages > 0 && (
        <Pagination
          //  className=" sticky bottom-0 "
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Table;
