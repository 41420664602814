// rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import agentReducer from "../slices/agentSlice"
import authReducer from "../slices/authSlice";
import commonReducer from "../slices/commonSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  agent: agentReducer,
  common: commonReducer,
  // Add other reducers as needed
});

export default rootReducer;
