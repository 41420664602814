import React, { memo } from 'react';

const YouTubeVideo = ({ videoURL, height }) => {
  return (
    <div className="w-full flex justify-end pr-5">
      {videoURL && (
        <iframe
          width="570"
          height={height}
          src={videoURL}
          title="YouTube video player"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default memo(YouTubeVideo);
