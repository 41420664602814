import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as MoreIcon } from "../../../../../src/assets/more-horizontal.svg";
import { ReactComponent as SaveIcon } from "../../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../../src/assets/cancel.svg";
import useApi from "../../../../custom-hooks/useApi";
import { BASE_URL } from "../../../../appconfig";
import { toast } from "react-toastify";
import ConversionPopUp from "../popups/ConversionPopUp";

function MyLeadsRow({ data, leadSource, index, change, ConstStatus }) {
  const [isEditing, setIsEditing] = useState(false);
  const [status, setStatus] = useState(data?.RecipientStatusID);
  const [notes, setNotes] = useState(data?.Notes);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isConversionPopupOpen, setIsConversionPopupOpen] = useState(false);
  const [changed, setChanged] = useState(false);

  const api = useApi();
  const actionRef = useRef(null);

  useEffect(() => {
    setNotes(data?.Notes);
    setStatus(data?.RecipientStatusID);
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionRef.current && !actionRef.current.contains(event.target)) {
        setIsActionOpen(false);
      }
    };

    if (isActionOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActionOpen]);

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleCancelClick = () => {
    setIsEditing(!isEditing);
  };

  const statusMap = ConstStatus.reduce((acc, status) => {
    acc[status.ID] = status.Name;
    return acc;
  }, {});

  const leadsMap = leadSource.reduce((acc, status) => {
    acc[status.ID] = status.Name;
    return acc;
  }, {});

  const getStatus = (statusID) => {
    return statusMap[statusID] || "-";
  };

  const leadSourceDisplay = (id) => {
    return leadsMap[id] || "-";
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    const json = {
      RecipientStatusID: status,
      Notes: notes,
    };
    try {
      const response = await api.put(
        `${BASE_URL}/Campaign/Lead/${data.ID}`,
        json
      );
      if (response.data.success) {
        change();
        toast.success("Data updated successfully", {
          theme: "dark",
        });
      } else {
        const errorMessage =
          response.data?.error || "Failed to update the data";
        toast.error(errorMessage, {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setIsEditing(false);
    }
  };

  const handleDeleteClick = async (e) => {
    try {
      const response = await api.delete(`${BASE_URL}/Campaign/Lead/${data.ID}`);
      if (response.data.success) {
        change();
        toast.success("Data deleted successfully", {
          theme: "dark",
        });
      } else {
        const errorMessage =
          response.data?.error || "Failed to delete the data";
        toast.error(errorMessage, {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    } finally {
      setIsEditing(false);
    }
  };

  const handleMoreClick = () => {
    setIsActionOpen(!isActionOpen);
  };

  const handleConvertClick = () => {
    setIsActionOpen(false);
    setIsConversionPopupOpen(!isConversionPopupOpen);
  };

  const toggleChange = () => {
    setChanged(!changed);
  };

  return (
    <tr key={`the key is ${data.ID}`} className="border-b dark:border-neutral-500">
      <td className="truncate py-3 px-2 whitespace-nowrap font-inter">
        <span>{`${data.First_Name} ${data.Last_Name}`}</span>
      </td>
      <td className="truncate py-3 px-2 whitespace-nowrap font-inter">
        {data.Campaign_Name ? data.Campaign_Name : leadSourceDisplay(data.LeadSourceID)}
      </td>
      <td style={{ maxWidth: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }} className="truncate py-3 px-2 whitespace-normal font-inter">
        {data.Email_Address}
      </td>
      <td className="truncate py-3 px-2 whitespace-nowrap font-inter">
        {data.CountryCode}{data.Mobile_Phone}
      </td>
      <td className="w-[200px] truncate py-3 px-[0.4rem] whitespace-nowrap font-inter">
        {isEditing && (
          <select
            className="w-[200px] appearance-none block justify-center bg-neutral-200 text-neutral-700 text-sm border-0 focus:outline-none focus:bg-white"
            name="Status"
            defaultValue={status}
            required
            onChange={handleStatusChange}
          >
            {ConstStatus?.map((item) => (
              item.ID !== "" && (
                <option key={item.ID} value={item.ID}>
                  {item.Name}
                </option>
              )
            ))}
          </select>
        )}
        {!isEditing && <span className="truncate whitespace-normal">{getStatus(data.RecipientStatusID)}</span>}
      </td>
      <td className="w-[180px] truncate py-4 pl-[0.2rem] whitespace-normal font-inter">
        {isEditing && (
          <input
            type="text"
            required
            form={`recipient-update-form-${data.ID}`}
            id="Notes"
            className="appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
            placeholder="Notes..."
            defaultValue={notes}
            onChange={handleNotesChange}
          />
        )}
        {!isEditing && <span className="truncate whitespace-normal">{data.Notes ? data.Notes : "-"}</span>}
      </td>
      <td className="whitespace-nowrap font-inter">
        {isEditing ? (
          <div className="flex flex-row items-center gap-2">
            <CancelIcon className="cursor-pointer" onClick={handleCancelClick} />
            <button className="disabled:opacity-50 disabled:cursor-not-allowed" onClick={handleSaveClick}>
              <SaveIcon />
            </button>
          </div>
        ) : (
          <div className="flex flex-row gap-2 cursor-pointer" ref={actionRef}>
            <MoreIcon onClick={handleMoreClick} />
            {isActionOpen && (
              <div className="absolute z-10 w-auto bg-white border rounded shadow-lg mt-5 mr-16 right-0">
                <ul className="border rounded">
                  <li className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white" onClick={() => handleEditClick()}>
                    Edit
                  </li>
                  <li className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white" onClick={() => handleDeleteClick()}>
                    Delete
                  </li>
                  <li className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white" onClick={() => handleConvertClick()}>
                    Convert
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </td>
      {isConversionPopupOpen && (
        <ConversionPopUp
          campaignData={data}
          toggle={handleConvertClick}
          change={change}
        />
      )}
    </tr>
  );
}

export default MyLeadsRow;
