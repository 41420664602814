import React, { useEffect, useState } from "react";
import PendingSvg from "../../../../../src/assets/pending.svg";
import DeliveredSvg from "../../../../../src/assets/delivered.svg";
import RepliedSvg from "../../../../../src/assets/replied.svg";
import ErrorsSvg from "../../../../../src/assets/errors.svg";
import { ReactComponent as EditIcon } from "../../../../../src/assets/edit.svg";
import { ReactComponent as SaveIcon } from "../../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../../src/assets/cancel.svg";
import useApi from "../../../../custom-hooks/useApi";
import { BASE_URL } from "../../../../appconfig";
import { toast } from "react-toastify";

function RecipientRow({ data, change, ConstStatus }) {
  const [filteredData, setFilteredData] = useState(data);
  const [statusData, setStatusData] = useState(ConstStatus);
  const [isEditing, setIsEditing] = useState(false);
  const [status, setStatus] = useState(data?.RecipientStatusID);
  const [notes, setNotes] = useState(data?.Notes);
  const api = useApi();

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };
  const handleCancelClick = () => {
    setIsEditing(!isEditing);
  };
  const getStatusImage = (statusID) => {
    switch (statusID) {
      case 49:
          return ErrorsSvg;
      case 50:
          return RepliedSvg;
      case 48:
      case 51:
          return DeliveredSvg;
      default:
          return PendingSvg;
    }
  };
  const getStatus = (statusID) => {
    switch (statusID) {
      case 45:
        return "Queued";
      case 46:
        return "Sending";
      case 47:
        return "Sent";
      case 48:
        return "Delivered";
      case 49:
        return "Failed";
      case 50:
        return "Received";
      case 51:
        return "Read";
      default:
        return "-";
    }
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const handleSaveClick = async (e) => {
    e.preventDefault();
    const json = {
      Campaign_ID: data.Campaign_ID,
      RecipientStatusID: status,
      Notes: notes,
    };
    try {
      const response = await api.put(
        `${BASE_URL}/Campaign/Recipient/${data.ID}`,
        json
      );
      if (response.data.success) {
        change();
        toast.success("Data updated successfully", {
          theme: "dark",
        });
      } else {
        const errorMessage =
          response.data?.error || "Failed to update the data";
        toast.error(errorMessage, {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear().toString()} ${
      date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
    }:${date.getMinutes().toString().padStart(2, "0")} ${
      date.getHours() >= 12 ? "PM" : "AM"
    }`;

    return formattedDate;
  };
  return (
    <tr key={`the key is ${data.ID}`} className="border-b dark:border-neutral-500">
      <td className="py-4 px-2 font-inter relative flex items-center">
        <div className="relative flex cursor-pointer">
          <img
            src={getStatusImage(data.RCSMSStatusID)}
            alt="status-icon"
            className="rounded-full mr-2"
          />
          <span className="tooltip-container relative">
            <span className="tooltip-content">
              {data.First_Name} {data.Last_Name}
            </span>
            <span className="tooltip hidden bg-black text-white text-xs px-2 py-1 mt-1 rounded whitespace-nowrap absolute top-full left-1/2 transform -translate-x-1/2">
              {data.Email_Address}
            </span>
          </span>
        </div>
      </td>
      <td className="truncate py-4 px-2 whitespace-nowrap font-inter">
       {data.CountryCode} {data.Mobile_Phone}
      </td>
      <td className="truncate py-4 px-2 whitespace-nowrap font-inter">
        {formatDate(data.updatedAt)}
      </td>
      {isEditing ? (
        <select
          className="appearance-none block py-2 px-2 w-[150px] justify-center bg-neutral-100 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
          name="Status"
          defaultValue={data.RecipientStatusID}
          required
          onChange={handleStatusChange}
        >
           {statusData?.map((item) => (
              <option key={item.ID} value={item.ID}>
                {item.Name}
              </option>
            ))}
        </select>
      ) : (
        <td className="truncate py-4 px-[0.2rem] pr-1 whitespace-nowrap font-inter">
          {getStatus(data.RCSMSStatusID)}
        </td>
      )}
    <td className="truncate py-4 px-[0.58rem] pr-1 whitespace-nowrap font-inter">
      {/* {isEditing &&
        <input
          type="text"
          required
          form={`recipient-update-form-${data.ID}`}
          id="Notes"
          className="appearance-none block w-[200px] bg-neutral-100 text-neutral-700 text-sm border-0 py-2 px-1 leading-tight focus:outline-none focus:bg-white"
          placeholder="Notes..."
          defaultValue={notes || ''}
          onChange={handleNotesChange}
        />} */}
        {!isEditing && <span>{data.DeliverabilityNotes ? data.DeliverabilityNotes : "-"}</span>}
    </td>
      {/* <td className="whitespace-nowrap font-inter">
        {isEditing ? (
          <div className="flex flex-row  items-center gap-2">
            <CancelIcon
              className="cursor-pointer"
              onClick={handleCancelClick}
            />
            <button
              className="disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleSaveClick}
            >
              <SaveIcon />
            </button>
          </div>
        ) : (
          <div
            className="flex flex-row gap-2 cursor-pointer"
            onClick={handleEditClick}
          >
            <EditIcon />
          </div>
        )}
      </td> */}
    </tr>
  );
}

export default RecipientRow;
