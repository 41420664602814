import React, { useEffect, useState } from "react";
import useApi from "../../../../../../custom-hooks/useApi";
import { BASE_URL } from "../../../../../../appconfig";
import { toast } from "react-toastify";
import Spinner from "../../../../../common/Spinner";
import TapUserIcon from "../../../../../../assets/tap-user.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ChangePassword({ toggle }) {
  const user = useSelector((state) => state.auth.user);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const password = e.target.password.value;
    const json = {
      email: user.email,
      Password: password,
    };
    try {
      setLoading(true);
      const response = await api.post(`${BASE_URL}/auth/change-pass`, json);
      if (response.status === 200 && response.data.success) {
        toast.success("Password changed successfully", {
          theme: "dark",
        });
        localStorage.clear();
        setTimeout(() => {
          navigate("/signin");
        }, 500);
      } else {
        const errorMessage = response.data?.error || "Failed to change password";
        toast.error(errorMessage, {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        theme: "dark",
      });
      console.error("Error changing password:", error);
    } finally {
      setLoading(false);
      toggle();
    }
  };

  return (
    <div>
      {loading && <Spinner />}
      <div
        className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20"
        style={{ margin: 0 }}
      >
        <form onSubmit={handleSubmit}
          className="bg-white p-8 rounded shadow-md relative"
          style={{ width: "680px" }}
        >
          <div className="flex  items-center justify-between mb-2">
            <h2 className="text-black font-inter text-2xl font-medium">
              Change Password
            </h2>

            <button
              onClick={toggle}
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="relative mb-6">
            <label
              className="text-[#9CA3AF] font-inter text-sm font-medium leading-[18px]"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="inline-flex h-11 px-4 py-3 items-start space-x-2.5 flex-shrink-0 border-none focus:outline-none mt-2 bg-[#F3F4F6] w-full p-[16px 24px] justify-center items-center space-x-2.5"
              id="password"
              type={passwordType}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              minLength="12"
              pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{12,}$"
              title="Password must be 12 or more characters long with at least one number and one symbol."
              required
            />
            <div className="absolute inset-y-0 right-0 mt-8 flex items-center pr-3">
            <div onClick={togglePassword} className="cursor-pointer">
              {passwordType === "password" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="bi bi-eye-slash"
                  viewBox="0 0 16 16"
                  fill="#3868b6"
                >
                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="bi bi-eye"
                  viewBox="0 0 16 16"
                  fill="#3868b6"
                >
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                </svg>
              )}
            </div>
          </div>
          </div>
          <div className="flex items-center mt-2">
            <input
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <div className="ml-3">Log me out of all devices.</div>
          </div>
          <div className="flex mt-7 items-center">
            <button
              style={{
                background:
                  "linear-gradient(261deg, #5392FF 13.57%, #2B5EB7 79.95%)",
                height: "2.5rem",
              }}
              className="text-white rounded w-[20%] py-2 px-3 font-inter"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
