import React from 'react'
import shareLandingSvg from '../../assets/landingScreensSvg/Inbound Magnet Graphic 1.svg'

const campaignsLanding = () => {
  return (
    <div className="flex items-center justify-center bg-gray-100">
    <div className="flex flex-col items-center m-5 mt-12 bg-white p-3 rounded-lg  w-[90%] h-[80vh] border border-[#E5E7EB]">
      <div className="flex flex-col items-center">
        {/* Image Part */}
        <div className="flex justify-center w-[60%]">
          <img
            src={shareLandingSvg}
            alt="Landing Image"
            className="rounded-l-lg object-cover"
          />
        </div>
        {/* Content Part */}
        <div className='flex flex-col justify-center items-center ml-16'>
        <div className="w-[70%] p-8 flex flex-col justify-center items-start">
          <h1 className="text-[2.5rem] font-semibold text-gray-800 mb-4">
          Attract Inbound Leads with Agent Pages
          </h1>
          <p className="text-base font-medium text-gray-500 mb-6">
          Attract potential clients and business partners with a landing page that is personalized to you and your audience, built to funnel leads to one place and simplify follow up.          
          </p>
          <button className="text-base font-semibold bg-[#4971B8] text-white px-6 py-3 w-[726px] rounded-md">
          Get Started
          </button>
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default campaignsLanding