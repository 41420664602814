import React from 'react';
import CampaignDetails from './campaignDetails/CampaignDetails';
import FirstCampaigns from './campaignDetails/FirstCampaigns';

function SummaryOrDetails({ campaign,campaignClick }) {
  if (campaign && Object.keys(campaign).length > 0) {
    return <CampaignDetails campaign={campaign} campaignClick={campaignClick} />;
  } 
  else {
    // return (
    //   <div className="flex text-base font-semibold text-gray-500 p-5 w-full justify-center items-center">
    //     No data available
    //   </div>
    // );
    return (<FirstCampaigns />);
  }
}

export default SummaryOrDetails;
