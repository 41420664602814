import React, { useState, useEffect, useRef } from "react";
import CustomDropdown from "./CustomDropdown";
import { toast } from "react-toastify";
import useApi from "../../custom-hooks/useApi";
import { isValid, format } from "date-fns";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Menubar.css";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import TeamImport from "./TeamImport";
import TeamImportHistoryPopUp from "./TeamImportHistoryPopUp";
import Spinner from "../common/Spinner";
import downArrow from "../../../src/assets/downarrow.svg";
import chevronDownSvg from "../../assets/chevron-down.svg";

const MenuBar = ({
  setFilterData,
  setSearchData,
  exportAgentDetails,
  isSelected,
  agentData,
  mailsent,
  campaign,
}) => {
  const fileInputRef = useRef(null);
  const openActiveRef = useRef(null);
  const showButtonRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const Fullname = useSelector((state) => state.auth.fullName);
  const [importHistoryPopup, setImportHistoryPopup] = useState(false);
  // const [importTeamPopup, setImportTeamPopup] = useState(false);
  const [newTeamPopup, setNewTeamPopup] = useState(false);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [isOpenActive, setIsOpenActive] = useState(false);
  const [formData, setFormData] = useState({
    UplineID: "",
    FirstName: "",
    LastName: "",
    AssociateID: "",
    AMADate: "",
    WorkEmail: "",
    Mobile: "",
    BaseShopID: "12ABC",
  });
  const [apiData, setApiData] = useState([]);
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setShowButton(false);
  };
  const api = useApi();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSearch = async (e) => {
    setSearchData(e.target.value);
  };

  const handleToggleButton = () => {
    setShowButton(!showButton);
    setIsPopupOpen(false);
    setIsOpenActive(false);
  };

  const handleUplineChange = (e) => {
    const { value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      UplineID: value,
    }));
    // }
  };

  const toggleHistory = () => {
    setImportHistoryPopup(!importHistoryPopup);
  };
  // const toggleImportPopup = () => {
  //   setImportTeamPopup(!importTeamPopup);
  // };
  const toggleNewTeamPopup = () => {
    setNewTeamPopup(!newTeamPopup);
  };
  const isChanged = () => {
    setChanged(!changed);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const form = document.querySelector("#new-team-form");
      const mobileInput = form.querySelector('input[name="Mobile"]');
      if (!formData["Mobile"] || !isValidPhoneNumber(formData["Mobile"])) {
        mobileInput.setCustomValidity("Please enter a valid phone number.");

        // Trigger validation error for the input
        mobileInput.reportValidity();
        return;
      } else {
        mobileInput.setCustomValidity("");
      }
      const response = await api.post(`/Organization/Agent_Details`, formData); // Replace with your second API endpoint
      if (response.status === 200 && response.data.success) {
        toast.success(response.data.message, {
          theme: "dark",
        });

        // alert(response.data.message);
        setFormData({
          UplineID: "",
          FirstName: "",
          LastName: "",
          AssociateID: "",
          AMADate: "",
          WorkEmail: "",
          Mobile: "",
          BaseShopID: "12ABC",
        });
        setAmaDate("");
        togglePopup();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        theme: "dark",
      });

      console.error("Error fetching second API data:", error);
    }

    // Add your form submission logic here
    console.log("Form submitted:", formData);
  };

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const response = await api.get(
          `/Organization/AgentShort_Detail?type=All`
        ); // Replace with your API endpoint
        setApiData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setApiData([]);
      }
    };

    fetchApiData();
  }, [api]);

  const setDate = (date, name) => {
    setAmaDate(date);

    try {
      console.log(date);

      if (isValid(date)) {
        const formattedDate = format(date, "yyyy-MM-dd");
        setFormData((prevData) => ({
          ...prevData,
          [name]: formattedDate,
        }));
      } else {
        console.error("Invalid date");
      }
    } catch (error) {
      console.error("Error parsing date:", error);
    }
  };
  const [AMADate, setAmaDate] = useState(formData.AMADate);

  const handlePhoneInputChange = (value) => {
    if (value !== undefined && isValidPhoneNumber(value.toString())) {
      const phoneNumber = parsePhoneNumber(value);

      if (phoneNumber) {
        // Extract country code and phone number
        const countryCode = "+" + phoneNumber.countryCallingCode;
        // const phoneNumberWithoutCountryCode = phoneNumber.nationalNumber;

        // Now you have the country code and phone number separately

        setFormData((prevData) => ({
          ...prevData,
          Mobile: value,
          CountryCode: countryCode,
        }));
      }
    } else if (value === "") {
      setFormData((prevData) => ({
        ...prevData,
        Mobile: value,
      }));
    }
    const form = document.querySelector("#new-team-form");
    const mobileInput = form.querySelector('input[name="Mobile"]');
    mobileInput.setCustomValidity("");
  };

  const handleCSVImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        // Process the CSV data here (e.g., parse CSV string, etc.)
        console.log(csvData);
      };
      reader.readAsText(file);
    }
  };

  // Function to trigger click event on file input
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleEmail = async () => {
    try {
      setLoading(true);
      const json = {
        agents: agentData,
      };
      const response = await api.post(`/Organization/EmailSchedule`, json);
      if (response.status === 200 && response.data.success) {
        mailsent();
        handleToggleButton();
        toast.success("Emails scheduled successfully!", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message, {
        theme: "dark",
      });
      console.error("Error sending email", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpenActive(!isOpenActive);
    setShowButton(false);
  };

  const toggleDropdownPropsFun = () => {
    setIsOpenActive(false);
    setShowButton(false);
  };

  const handleClickOutside = (event) => {
    if (
      openActiveRef.current &&
      !openActiveRef.current.contains(event.target)
    ) {
      setIsOpenActive(false);
    }
    if (
      showButtonRef.current &&
      !showButtonRef.current.contains(event.target)
    ) {
      setShowButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <nav
        style={{ width: "95%" }}
        className="pt-3 flex justify-between items-center mx-auto my-4"
      >
        {/* Left Corner - Dropdown Menu */}
        <CustomDropdown
          setFilterData={setFilterData}
          onClick={toggleDropdownPropsFun}
        />
        {/* Right Corner - Search Bar and Buttons */}
        <div className="flex items-center gap-2">
          <div className=" flex items-center gap-4">
            <div className="relative border border-[#D1D5DB] border-solid rounded text-[#6B7280] font-sm">
              {/* SVG Icon inside Search Bar */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 absolute left-0 top-1/2 transform -translate-y-1/2 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
              <input
                type="text"
                placeholder="Search..."
                style={{
                  backgroundColor: "#E5E7EB",
                  width: "18rem",
                  height: "2.5rem",
                  paddingLeft: "2.5rem", // Adjust for icon space
                }}
                className="p-3 focus:outline-none border-none font-sm"
                onChange={handleSearch}
              />
            </div>
            <div className="relative" ref={openActiveRef}>
              <button
                className="gap-8 flex flex-row items-center bg-transparent text-[#4871B7] font-semibold py-2 px-4 border border-blue-500 rounded font-inter"
                onClick={toggleDropdown}
              >
                Actions
                <img
                  src={chevronDownSvg}
                  alt=""
                  className={`hover:text-white transform transition-transform duration-200`}
                />
              </button>

              {isOpenActive && (
                <div
                  className="fixed w-[290px]  bg-white shadow-lg rounded z-50 py-2 mt-3"
                  onMouseLeave={() => setIsOpenActive(false)}
                >
                  <div className="">
                    <h3 className="font-inter font-medium text-gray-400 px-4 py-3">
                      List Views
                    </h3>
                    <ul className="">
                      <li
                        onClick={() => {
                          toggleNewTeamPopup();
                          toggleDropdown();
                        }}
                        className="font-inter font-medium  leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-9 py-3 "
                      >
                        Import
                      </li>
                      <li
                        onClick={exportAgentDetails}
                        className="font-inter font-medium  leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-9 py-3"
                      >
                        Export
                      </li>
                      <li className="font-inter font-medium  leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-9 py-3">
                        Send Email
                      </li>
                      <li
                        onClick={() => {
                          toggleHistory();
                          toggleDropdown();
                        }}
                        className="font-inter font-medium  leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-9 py-3"
                      >
                        View Import History
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="">
            <div className="flex">
              {newTeamPopup && (
                <div>
                  <TeamImport
                    changed={isChanged}
                    toggleNewTeamPopup={toggleNewTeamPopup}
                  />{" "}
                </div>
              )}
              {importHistoryPopup && (
                <TeamImportHistoryPopUp toggle={toggleHistory} />
              )}
            </div>
          </div>

          {/* Button 2 */}
          <div ref={showButtonRef}>
            <div className="flex">
              <button
                style={{
                  background:
                    "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                  height: "2.5rem",
                  width: "14rem",
                }}
                className="text-white p-2 font-inter"
                onClick={togglePopup}
              >
                + New Business Partners
              </button>
              <div
                onClick={handleToggleButton}
                className="border border-black w-7 flex justify-center items-center cursor-pointer"
              >
                <img
                  src={downArrow}
                  alt="down-arrow"
                />
              </div>
            </div>
            {showButton && (
              <div
                style={{
                  height: "2.5rem",
                  width: "9rem",
                }}
                onClick={isSelected ? handleEmail : undefined}
                onMouseLeave={() => setShowButton(false)}
                className={`absolute z-50 px-1 py-2 mt-1 ml-28 rounded font-inter text-center ${
                  isSelected
                    ? "bg-gradient-to-r from-blue-500 to-green-500 text-white cursor-pointer opacity-100"
                    : "bg-gray-400 text-gray-600 cursor-not-allowed "
                }`}
              >
                Schedule Email
              </div>
            )}
          </div>
          {/* Popup Form */}
          {isPopupOpen && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20"
              style={{ margin: 0 }}
            >
              <div
                className="bg-white p-8 rounded shadow-md relative"
                style={{ width: "680px" }}
              >
                <div className="flex  items-center justify-between mb-2">
                  <h2 className="text-black font-inter text-2xl font-medium">
                    Add New Team Member
                  </h2>

                  <button
                    onClick={togglePopup}
                    className="text-gray-600 hover:text-blue-500 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                </div>
                <p className="text-xs font-inter mb-10">
                  Base Shop for {Fullname} ({user.BaseShopID})
                </p>

                <div className="flex justify-center">
                  <form
                    className="w-full max-w-lg"
                    onSubmit={handleSubmit}
                    id="new-team-form"
                  >
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 font-inter">
                        <label
                          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
                          htmlFor="role"
                        >
                          Upline Agent Name
                          <span className="text-red-500"> *</span>
                        </label>
                        <div className="relative">
                          <input type="hidden" name="UplineID" />
                          <select
                            className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                            id="role"
                            name="UplineIDSelector"
                            defaultValue={""}
                            onChange={handleUplineChange}
                            required
                          >
                            <option
                              value=""
                              selected
                              disabled
                              className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                              style={{ height: "1.5rem", width: ".5rem" }}
                            >
                              Select Upline Agent
                            </option>
                            {apiData.length > 0 &&
                              apiData.map((item) => (
                                <option
                                  key={item.AgentID} // Use a unique identifier, in this case, AssociateID
                                  value={item.AgentID}
                                >
                                  {`${item.FirstName} ${item.LastName} (${item.AgentID})`}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-gray-300 mb-8"></div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 font-inter">
                        <label
                          className="block uppercase tracking-wide text-black text-xs font-bold mb-2 "
                          htmlFor="firstName"
                        >
                          First Name
                          <span className="text-red-500"> *</span>
                        </label>
                        <input
                          className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="firstName"
                          name="FirstName"
                          type="text"
                          placeholder="First Name"
                          value={formData.FirstName || ""}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-3 font-inter">
                        <label
                          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
                          htmlFor="lastName"
                        >
                          Last Name
                          <span className="text-red-500"> *</span>
                        </label>
                        <input
                          className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                          id="lastName"
                          name="LastName"
                          type="text"
                          placeholder="Last Name"
                          value={formData.LastName || ""}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6 font-inter">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
                          htmlFor="agentId"
                        >
                          Agent ID
                          <span className="text-red-500"> *</span>
                        </label>
                        <input
                          className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="agentId"
                          name="AgentID"
                          type="text"
                          placeholder="Agent ID"
                          value={formData.AgentID || ""}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
                          htmlFor="amaDate"
                        >
                          Registration Date
                          <span className="text-red-500"> *</span>
                        </label>
                        <DatePicker
                          showIcon
                          toggleCalendarOnIconClick
                          name="AMADate"
                          className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                          selected={AMADate}
                          onChange={(date) => setDate(date, "AMADate")}
                          placeholderText="mm/dd/yyyy"
                          placeholder="m/d/Y"
                          dateFormat="M/d/yyyy"
                          showYearDropdown
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6 font-inter">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
                          htmlFor="emailAddress"
                        >
                          Email Address
                          <span className="text-red-500"> *</span>
                        </label>
                        <input
                          className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                          id="emailAddress"
                          name="WorkEmail"
                          type="email"
                          placeholder="Email Address"
                          value={formData.WorkEmail || ""}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-3 font-inter">
                        <label
                          className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
                          htmlFor="phone"
                        >
                          Phone
                          <span className="text-red-500"> *</span>
                        </label>
                        <PhoneInput
                          defaultCountry="US"
                          international
                          countryCallingCodeEditable={false}
                          placeholder="Enter phone number"
                          name="Mobile"
                          // className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                          value={formData.Mobile || ""}
                          onChange={handlePhoneInputChange}
                          required
                          error={
                            formData.Mobile
                              ? isValidPhoneNumber(formData.Mobile)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number required"
                          }
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-center font-inter">
                      <button
                        style={{
                          background:
                            "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                          height: "2.5rem",
                          width: "12rem",
                        }}
                        className="text-white p-2 rounded font-inter"
                        type="submit"
                      >
                        Create Team Member
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default MenuBar;
