import React, { useState, useEffect } from "react";
import StageForm from "./StageForm";
import { useSelector, useDispatch } from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  updateAgentData
} from "../../../../redux/thunks/agentThunk";
import { setExchangeLegSelected, setFormData, setShowCongratulations } from "../../../../redux/slices/agentSlice";
import useApi from "../../../../custom-hooks/useApi";
import { ReactComponent as VectorIcon } from "../../../../assets/Vector.svg";
import Confetti from "react-dom-confetti";
import { isValid, format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  setAuthToken,
  setRefreshToken,
  setFullName,
  setPermission,
  setRole,
  setUser,
  setRefreshInterval,
  setRefreshExp,
  setTimeInterval
} from "../../../../redux/slices/authSlice";
import { jwtDecode } from "jwt-decode";
import useRefreshAuthToken from "../../../../custom-hooks/useRefreshAuthToken";
import useRefreshUserData from "../../../../custom-hooks/useRefreshUserData";
import {toast} from "react-toastify";

const Stage7 = ({ handleChange }) => {
  const agentId = useSelector((state) => state.agent.agentId);
  const dispatch = useDispatch();
  const api = useApi();
  const navigate = useNavigate();

  const formData = useSelector((state) => state.agent.formData);
    const stepId = useSelector((state) => state.agent.currentStep);

  const currentStepData = useSelector((state) => state.agent.currentStepData);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const user = useSelector((state) => state.auth.user);
  const refreshIntervalState = useSelector(
    (state) => state.auth.refreshInterval
  );
  const refreshAuthToken = useRefreshAuthToken();
  const refreshUserData = useRefreshUserData();
  const refreshExp = useSelector((state) => state.auth.refreshExp);
  const timeInterval = useSelector((state) => state.auth.timeInterval);

  const showCongratulations = useSelector(
    (state) => state.agent.showCongratulations
  );
  const [confettiActive, setConfettiActive] = useState(false);

  const confettiConfig = {
    angle: 90,
    spread: "80",
    startVelocity: "50",
    elementCount: "150",
    dragFriction: "0.11",
    duration: "5000",
    stagger: "5",
    width: "12px",
    height: "12px",
    perspective: "768px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };


  const [isEditing, setEditing] = useState(true);

  useEffect(() => {
    dispatch(setFormData({StepID: stepId}));
  }, [dispatch, stepId, currentStepData]);
  useEffect(() => {
    dispatch(setFormData({ refreshToken: refreshToken }));
  }, [dispatch, refreshToken, currentStepData]);
  
  const toggleEditing = () => {
    
    dispatch(updateAgentData({ stepId, agentId, formData, currentStepData, api }));

  };
  const exchangeLegData = useSelector((state) => state.agent.exchangeLegData);

  const cancelEditing = () => {
    dispatch(setFormData(currentStepData));
    setEditing(false);
  };

  const handleExchangeLegChange = (e) => {
    if (e.target.value) {
      dispatch(setExchangeLegSelected(true));
    }
    handleChange(e);
  }
   const setDate = (date, name) => {
     try {
       console.log(date);

       if (isValid(date)) {
         const formattedDate = format(date, "yyyy-MM-dd");
         dispatch(setFormData({ [name]: formattedDate }));
       } else {
         console.error("Invalid date");
       }
     } catch (error) {
       console.error("Error parsing date:", error);
     }
   };

  const updateAuthToken = async () => {
    try {
      const response = await refreshAuthToken();
      if (response.status === 200 && response.data.accessToken) {
        dispatch(setAuthToken(response.data.accessToken));
      } else {
        console.log("Error occured while refreshing token: ", response);
        toast.error("Error while refreshing the token", { theme: "dark" });
        setTimeout(() => {
          navigate("/logout");
        }, 2000);
      }
    } catch (error) {
      let errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Auth Token expired. Logging out.";
      console.error("Error while refreshing the token: ", error);
      toast.error(errorMessage, { theme: "dark" });
      setTimeout(() => {
        navigate("/logout");
      }, 2000);
    }
  };

  const switchNewBaseShop = async (e) => {

    console.log(
      "Clearing old refreshIntervalState, as new refreshToken is fetched"
    );
    clearInterval(refreshIntervalState);

    let decodedAccessToken = jwtDecode(localStorage.getItem("accessToken"));
    // setTimeInterval to refresh token - exp time - current time - 2 min(offset for refreshing token 2 min earlier);
    window.timeInterval = decodedAccessToken.exp * 1000 - new Date().getTime() - 1000 * 60 * 2;
    dispatch(setTimeInterval(window.timeInterval));
    let decodedRefreshToken = jwtDecode(localStorage.getItem("refreshToken"));
    // to clear the refresh interval if refreshToken expires.
    window.refreshExp = new Date(decodedRefreshToken.exp * 1000).getTime();
    dispatch(setRefreshExp(window.refreshExp));
    dispatch(setRole(decodedAccessToken.user.Role));
    dispatch(setUser({...user, ...decodedAccessToken.user}));

    let userResponse = refreshUserData();

    if (userResponse && userResponse.data.user && userResponse.data.Permission) {
      let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {};
      dispatch(
        setUser({
          ...user,
          roleid: userResponse.data.user.roleid,
          organizationId: userResponse.data.user.organizationId,
          TeamName: userResponse.data.user.TeamName,
          email: userResponse.data.user.email,
        })
      );
      dispatch(
        setFullName(
          userResponse.data.user.First_name +
            " " +
            userResponse.data.user.Last_name
        )
      );
      dispatch(setPermission(userResponse.data.Permission));
      
      if (window.timeInterval > 0) {
        console.log(
          "registering new refreshInterval in every " +
            window.timeInterval +
            "ms"
        );
        window.refreshInterval = setInterval(() => {
          updateAuthToken();
          console.log(
            "called updateAuthToken will refresh accessToken in 1 min."
          );
          if (window.refreshExp <= new Date().getTime()) {
            console.log(
              "RefrehExp: " +
                window.refreshExp +
                ", is less then current time: " +
                new Date().getTime() +
                ", Clearing refreshTokenInterval"
            );
            clearInterval(window.refreshInterval);
          }
        }, window.timeInterval);
        dispatch(setRefreshInterval(window.refreshInterval));
      }
      dispatch(setAuthToken(localStorage.getItem("accessToken")));
      dispatch(setRefreshToken(localStorage.getItem("refreshToken")));
      dispatch(setShowCongratulations(false));
      window.location.replace("/dashboard");
    }
  }

  function redirectToTeams() {
    window.location.href = '/dashboard';
  }

  useEffect(() => {
    if(showCongratulations) {
      setTimeout(() => {
        setConfettiActive(true);
      }, 1000);
    }
  }, [showCongratulations]);

  if (formData!==null && currentStepData !== null ) {
    return (
      <>
        <StageForm
          stageNum={stepId}
          isEditable={false}
          cancelEditing={cancelEditing}
          isEditing={isEditing}
          toggleEditing={toggleEditing}
        >
          <input type="hidden" value={stepId} name="StepID" />
          <input type="hidden" value={refreshToken} name="refreshToken" />
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 1 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Senior Agents&nbsp;
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              <span>{currentStepData.Senior_Agents || "-"}</span>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 2 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">Licensed Agents</span>
            </div>
            <div className="mb-2 md:w-1/2">
              <span>{currentStepData.Licensed_Agents || "-"}</span>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 3 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Net Points&nbsp;
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              <span>{currentStepData.Net_Points || "-"}</span>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 4 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Cash Flow&nbsp;
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              <span>{currentStepData.Cash_Flow || "-"}</span>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 5 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                $100K Cash Flow&nbsp;
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              <div
                className={`w-4 h-4 border border-gray-300 rounded mr-2 ${
                  formData.Cash_Flow_100k ? "bg-blue-700" : ""
                }`}
              >
                {formData.Cash_Flow_100k && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="12"
                    viewBox="0 0 10 8"
                    fill="none"
                    className=" text-white mx-auto my-auto"
                  >
                    <path
                      d="M9 1L3.5 6.5L1 4"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 6 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Exchange Leg&nbsp;
                <span className="font-bold font-inter text-red-700">*</span>
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              {!isEditing && <span>{formData?.ExchangeLeg || "-"}</span>}
              {isEditing && (
                <select
                  className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                  name="ExchangeLeg"
                  value={
                    formData.ExchangeLeg != null && formData.ExchangeLeg !== "-"
                      ? formData.ExchangeLeg
                      : ""
                  }
                  onChange={(e) => handleExchangeLegChange(e)}
                  required
                >
                  <option
                    value=""
                    disabled
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    style={{ height: "1.5rem", width: ".5rem" }}
                  >
                    Select Upline Agent
                  </option>
                  {exchangeLegData.length > 0 &&
                    exchangeLegData.map((item) => (
                      <option
                        key={item.AgentID} // Use a unique identifier, in this case, AssociateID
                        value={item.AgentID}
                      >
                        {`${item.FirstName} ${item.LastName} (${item.AgentID})`}
                      </option>
                    ))}
                </select>
              )}
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 7 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Sign Off Date&nbsp;
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              <span>
                {currentStepData.Sign_Off_Date_Phase5 !== null
                  ? isValid(new Date(currentStepData.Sign_Off_Date))
                    ? format(
                        new Date(currentStepData.Sign_Off_Date),
                        "M/d/yyyy"
                      )
                    : "-"
                  : "-"}
              </span>
            </div>
          </div>
        </StageForm>
        {showCongratulations && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20">
            <div
              className="flex flex-col items-center bg-white p-8 rounded shadow-md relative"
              style={{ width: "450px", height: "400px" }}
            >
              <div className="flex items-center justify-end w-full mb-2">
                <button
                  className="text-gray-600 hover:text-blue-500 focus:outline-none"
                  onClick={redirectToTeams}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
              <div>
                <VectorIcon />
                <Confetti active={confettiActive} config={confettiConfig} />
              </div>
              <div>
                <h2 className="text-black font-inter text-2xl font-medium">
                  Congratulations!
                </h2>
              </div>
              <p className="text-xs font-inter mb-10">
                You are now a Sr. Marketing Director
              </p>
              <div className="flex items-center justify-center w-full">
                <button
                  style={{
                    background:
                      "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                    height: "2.5rem",
                    width: "12rem",
                  }}
                  className="text-white p-2 rounded font-inter"
                  onClick={(e) => switchNewBaseShop(e)}
                >
                  Start Your Base Shop
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};
export default Stage7;



