import { ReactComponent as Stepbarlogo } from "../../../../assets/stepbar.svg";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const StepBar = ({ completedStep, allSteps, currentStep, navigateStep }) => {
  const [sortedSteps, setSortedSteps] = useState([]);
  const completedStages = useSelector((state) => state.agent.completedStages);
  useEffect(() => {
    if (allSteps && allSteps.length) {
      setSortedSteps([...allSteps].sort((a, b) => a.Step_Order - b.Step_Order));
    }
  }, [allSteps]);
  if (!allSteps || !currentStep || !navigateStep || !sortedSteps) {
    return null;
  }
  return (
    <ol className="flex bg-transparent border-collapse border-0 flex-grow items-center w-full">
      {sortedSteps.map((step) => (
        <li
          key={`step-bar-${step.Step_ID}`}
          data-step-id={step.Step_ID}
          className={`relative flex ${
            step.Step_ID  > 3 ? "flex-[20%]" : "flex-[6%]"
          } items-center text-blue-600 dark:text-blue-500 cursor-pointer pr-2`}
          onClick={() => navigateStep(step.Step_ID)}
        >
          <span
            className={`flex items-center w-full after:rounded-sm after:w-full after:content-[''] ${
              (parseInt(completedStages.length) > 0 &&
                completedStages.includes(parseInt(step.Step_ID))) ||
              (completedStep &&
                parseInt(completedStep) === parseInt(step.Step_ID))
                ? parseInt(Math.max(...completedStages)) ===
                    parseInt(step.Step_ID) ||
                  (completedStep &&
                    parseInt(completedStep) === parseInt(step.Step_ID))
                  ? "after:bg-gradient-to-br after:from-green-500 after:to-blue-600"
                  : "after:bg-green-500"
                : parseInt(step.Step_ID) === parseInt(currentStep)
                ? "after:bg-gray-500"
                : "after:bg-gray-300"
            } after:h-3 after:inline-block hover:after:bg-gray-500`}
          ></span>
          {((completedStages.length > 0 &&
            parseInt(Math.max(...completedStages)) ===
              parseInt(step.Step_ID)) ||
            (completedStep > 0 &&
              parseInt(completedStep) === parseInt(step.Step_ID) &&
              !completedStages.includes(9))) && (
            <span className="flex items-center ml-[-2px] justify-center w-6 h-6 bg-gradient-to-br from-blue-500 to-green-600 rounded-full lg:h-5 lg:w-5 dark:bg-blue-800 shrink-0">
              <Stepbarlogo />
            </span>
          )}
        </li>
      ))}
    </ol>
  );
};
export default StepBar;