import React, { useEffect, useState } from "react";
import useApi from "../../../../../../custom-hooks/useApi";
import { BASE_URL } from "../../../../../../appconfig";
import { toast } from "react-toastify";
import Spinner from "../../../../../common/Spinner";
import TapUserIcon from "../../../../../../assets/tap-user.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function GrantAccess({ uplineData, uplineId, BaseShopID, toggle, agency, change, refresh }) {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const api = useApi();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const json = {
      UplineID: uplineId,
      BaseShopID: BaseShopID,
      FirstName: user.FirstName,
      LastName: user.LastName,
      NameTitle: `${user.FirstName} ${user.LastName}`,
      Progress: 3,
    };
    try {
      const response = await api.post(
        `${BASE_URL}/Organization/UserDetails-Stage1/${user.id}`,
        json
      );
      if (response.status === 200) {
        toast.success("Details Updated, Please Login again", {
          theme: "dark",
        });
        refresh();
        change();
        toggle();
        setTimeout(() => {
          navigate("/");
        }, 1500);
      } else {
        toast.error("Failed to update details", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Spinner />}
      <div
        className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20"
        style={{ margin: 0 }}
      >
        <div
          className="bg-white p-8 rounded shadow-md relative"
          style={{ width: "600px" }}
        >
          <div className="flex  items-center justify-between mb-2">
            <h2 className="text-black font-inter text-2xl font-medium">
              Grant access
            </h2>

            <button
              onClick={toggle}
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <p
            style={{ fontSize: "13px" }}
            className="text-neutral-600 font-inter mt-5"
          >
            Grant access to your Profile and Business Data in order to scale
            your business and work together as a team.{" "}
          </p>
          <div
            style={{ borderRadius: "8px" }}
            className="w-full border-2 border-gray-300 mt-3"
          >
            <div className="p-4 text-neutral-400">
              Distribution Agency and Direct Upline
            </div>
            <div className="border-t-2 border-gray-300"></div>
            <div className="flex items-center p-5">
              <div>
                <img src={TapUserIcon} alt="logo" />
              </div>
              <div className="ml-3">
                <div className="text-neutral-900 text-lg font-semibold">
                  {agency}
                </div>
                <div>{uplineData === "Agent not found" ? `${uplineId}` : `${uplineData} (${uplineId})`}</div>
              </div>
            </div>
          </div>
          <div className="text-xs font-bold my-3">Full control</div>
          <div className="text-neutral-500 text-xs mt-2">
            <div>
              Grant non-exclusive rights to view, edit, and act on Profile and
              Business Data (Contacts, Leads, Clients, and Business Partners).{" "}
            </div>
            <div className="mt-3">
              Each User given permission to access the Data must agree to
              WealthSmyth Terms of Use and Privacy Policy.{" "}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex mt-4">
              <input
                required
                type="checkbox"
                className="mt-1 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <div className="text-xs ml-3 mt-0.5">
                I give permission to access my Profile and Business data under
                the{" "}
                <span className="text-[#2BB574]">WealthSmyth Terms of Use</span>{" "}
                and <span>Privacy Policy terms.</span>
              </div>
            </div>
            <div className="flex justify-end mt-7 items-center">
              <div
                onClick={toggle}
                className="text-[#4871B7] mr-8 cursor-pointer"
              >
                Cancel
              </div>
              <button
                style={{
                  background:
                    "linear-gradient(261deg, #5392FF 13.57%, #2B5EB7 79.95%)",
                  height: "2.5rem",
                }}
                className="text-white rounded w-[30%] py-2 px-3 font-inter"
                type="submit"
              >
                Grant Access
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default GrantAccess;
