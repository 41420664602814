import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';

import { ReactComponent as SaveIcon } from "../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../src/assets/cancel.svg";

import useApi from "../../../custom-hooks/useApi";
import { BASE_URL } from '../../../appconfig';
import Spinner from '../../common/Spinner';
import CountryStateDropdown from '../campaigns/dropdowns/CountryStateDropdown';


const RelatedBusinessForm = ({ AccountID, toggle, onSaveSuccess, entityList }) => {
    const api = useApi()
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        companyName: "",
        companyState: "",
        EntityTypeID: null,
        annualRevenue: null
    });
    const [statesData, setStatesData] = useState([]);


    // const [entityList, setEntityList] = useState([]);


    const [errors, setErrors] = useState({});


    useEffect(() => {
        let isMounted = true;

        const fetchCountryData = async () => {
            try {
                setLoading(true);
                const response = await api.get(
                    `${BASE_URL}/RingCentral/Get_All_States`
                );
                if (isMounted) {
                    setStatesData(response.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchCountryData();
        return () => {
            isMounted = false;
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors(prevData => ({ ...prevData, [name]: "" }))

        setFormData(prevData => ({ ...prevData, [name]: value }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        if (!formData.companyName) newErrors.companyName = "Company name is required";
        if (!formData.companyState) newErrors.companyState = "State name is required";
        if (!formData.annualRevenue) newErrors.annualRevenue = "Net Income number is required";
        if (formData.annualRevenue < 0) newErrors.annualRevenue = "Net Income can not be negative";
        if (!formData.EntityTypeID) newErrors.EntityTypeID = "Entity Type is required";

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }
        setLoading(true);
        await saveRelatedBusiness();
        setLoading(false);
    };

    const saveRelatedBusiness = async () => {
        try {

            const response = await api.post(`${BASE_URL}/Account/Related_Business/${AccountID}`, formData);
            if (response?.status === 200) {
                setTimeout(() => {
                    setLoading(false);
                    toast.success(response?.data?.message);
                    toggle();
                    onSaveSuccess()
                }, 1000);
            }

        } catch (error) {
            toast.error(error.message);
        }
    }


    const handleStateClick = (state) => {
        setErrors({
            ...errors,
            companyState: ""
        })
        setFormData({
            ...formData,
            //   HomeCountry: state?.country?.id === 39 ? "Canada" : "United States",
            companyState: state?.name
        })
    };

    return (
        <tr className='border=[#D1D5DB] border-b' >
            {loading && <Spinner />}

            <td className="px-3 py-2">

                <input
                    name="companyName"
                    placeholder="Company Name"
                    type="text"
                    value={formData.companyName}
                    onChange={handleChange}
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                />
                {errors.companyName && <p className="text-red-500 text-xs">{errors.companyName}</p>}
            </td>
            <td className="px-3 py-2">

                <select
                    name="EntityTypeID"
                    value={formData.EntityTypeID}
                    onChange={handleChange}
                    className="appearance-none block w-full bg-neutral-100 text-neutral-400 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                >
                    <option value="">Entity Type</option>
                    {
                        entityList?.length > 0 ? (
                            <>
                                {
                                    entityList?.map((entityItem) => (
                                        <option key={entityItem?.ID} value={entityItem?.ID}>{entityItem?.EntityTypeName}</option>
                                    ))
                                }
                            </>
                        ) : null
                    }
                </select>
                {errors.EntityTypeID && <p className="text-red-500 text-xs">{errors.EntityTypeID}</p>}
            </td>
            <td className="px-3 py-2">

                <CountryStateDropdown
                    isError={errors?.companyState?.length > 0}
                    stateClick={handleStateClick}
                    data={statesData}
                    textsm
                    isLabel={false}
                />
                {errors.companyState && <p className="text-red-500 text-xs">{errors.companyState}</p>}
            </td>
            <td className="px-3 py-2">

                <input
                    name="annualRevenue"
                    placeholder="Net Income"
                    type="number"
                    value={formData.annualRevenue}
                    onChange={handleChange}
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                />
                {errors.annualRevenue && <p className="text-red-500 text-xs">{errors.annualRevenue}</p>}
            </td>

            <td className="p-4 flex gap-2">
                <div className="flex  gap-2">
                    <button
                        className="disabled:opacity-50 disabled:cursor-not-allowed"
                        onClick={handleSubmit}
                    >
                        <SaveIcon />
                    </button>
                    <CancelIcon
                        className="cursor-pointer"
                        onClick={toggle}
                    />
                </div>

            </td>

        </tr>
    );
};

export default RelatedBusinessForm;
