import React from 'react'
import PerformanceHeader from './PerformanceHeader'
import Performancebody from './Performancebody'

function Performance() {
  return (
    <div className='px-4 mb-6'>
    <PerformanceHeader/> 
    <Performancebody/>
    </div>
  )
}

export default Performance