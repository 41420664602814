import React, { useRef, useState, useEffect } from "react";
import { IoChevronDownOutline, IoSearchOutline } from "react-icons/io5";
import Spinner from "../../common/Spinner";
import ApplicationTable from "./ApplicatonTable";
import { useNavigate } from "react-router-dom";
import Modal from "./ApplicatonComponent/NewApplicaotnModal";
import Pagination from "./ApplicatonComponent/Pagination";
import NewLeadPopUp from "../campaigns/popups/NewLeadPopup";
import Filter from "./ApplicatonComponent/Filter";
import { BASE_URL } from "../../../appconfig";
import useApi from "../../../custom-hooks/useApi";


const Applications = () => {
  const navigate = useNavigate();
  const api = useApi();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [actionsPopup, setActionsPopup] = useState(false);
  const dropdownRef = useRef(null);
  const actionPopupRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedValue, setSelectedValue] = useState([70, "Total"]);
  const [changed, setChanged] = useState(false);
  const [heading, setHeading] = useState("All Applications");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const itemsPerPage = 10;
  const [originalList, setOriginalList] = useState([])
  const [fixedList, setFixedList] = useState([])
  const [list, setList] = useState();
  const [statusFilterList, setStatusFilterList] = useState();
  const [filteredData, setFilteredData] = useState();
  const applicationList = [
    "All Applications",
    "My Applications",
    "My Direct’s Applications",
    "This Week",
    "This Month",
    "This Quarter",
    "Ethos",
    "Foresters",
    "National Life Group",
    "North American",
  ];
  const [totalPages, setTotalPages] = useState()

  const handleClickOutside = (event) => {
    if (dropdownRef?.current && !dropdownRef?.current.contains(event?.target)) {
      setDropDownOpen(false);
    }
    if (
      actionPopupRef?.current &&
      !actionPopupRef?.current.contains(event?.target)
    ) {
      setActionsPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchClientsData();
  }, []);

  useEffect(() => {
    fetchClientsData();
  }, [currentPage ,searchQuery , heading]);

  useEffect(() => {
    filterByStatus(originalList);
  }, [selectedValue]);

  useEffect(() => {
    searchItems(statusFilterList);
  }, [searchQuery]);

  // useEffect(() => {
  //   localStorage.setItem("currentPage", currentPage);
  //   paginateList(list, currentPage);
  // }, [currentPage]);

  const filterByStatus = (baseList) => {
    let filtered;
    if (selectedValue[1] === "Total" || selectedValue[0] === 71) {
      filtered = baseList;
    } else {
      filtered = baseList?.filter((item) => Number(item?.Application_Status) === selectedValue[0] || item?.Application_Status === selectedValue[1]);
    }
    setStatusFilterList(filtered);
    searchItems(filtered);
  };

  const searchItems = (baseList) => {
    let filtered;
    if (searchQuery?.trim() === "") {
      filtered = baseList;
    } else {
      filtered = baseList?.filter((item) =>
        item.Application_Name
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase())
      );
    }
    setList(filtered);
    // setCurrentPage(1);
    // paginateList(filtered, 1);
  };

  const paginateList = (baseList, page) => {
    let newList;
    if (page > 1) {
      newList = baseList?.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
    } else {
      newList = baseList?.slice(0, itemsPerPage);
    }
    setFilteredData(newList);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };



  const fetchClientsData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${BASE_URL}/Application/ApplicationData?page=${currentPage}&pageSize=10&searchQuery=${searchQuery}&filter=${heading}`);
      if (response?.status === 200) {
        let accountList = response?.data?.Entity;
        setTotalPages(response?.data?.totalPages)
        setCurrentPage(response?.data?.currentPage)

        accountList = accountList.map((item, index) => {
          // Calculate the correct number based on the current page
          item.pageNumber = (currentPage - 1) * 10 + (index + 1);
          return item;
      });
        // setOriginalList(accountList)
        setFixedList(accountList)
        filterByStatus(accountList)
      }

      setLoading(false)

    } catch (error) {
      setFixedList([])
      setLoading(false)
    }

  };

  const isChanged = () => {
    setChanged(!changed);
  };

  const toggleDropdown = () => {
    setDropDownOpen(!dropDownOpen);
    setActionsPopup(false);
  };

  const toggleNewActionsPopup = () => {
    setActionsPopup(!actionsPopup);
    setDropDownOpen(false);
  };

  const handleConvertClick = () => {
    // navigate("/applications/new-application");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearchDebounced = debounce((term) => {
    setSearchQuery(term);
  }, 1000);

  const handleSearchChange = async (e) => {
    let term = e.target.value.toLowerCase();
    if ((searchQuery?.length >= 3 && term?.length === 0) || term?.length >= 3) {
      handleSearchDebounced(term);
      setCurrentPage(1);
    }
  };

  const applicationListHandler = (item) => {
    setHeading(`${item}`);
    setDropDownOpen(false);
  };

  const actioinHandler = () => {
    setActionsPopup(false);
  };

  // const totalPages = Math.ceil(list?.length / itemsPerPage);
  // console.log(totalPages)

  return (
    <div className="h-screen">
      <div>
        {loading ? <Spinner /> : null}
        <div className="flex w-full  justify-between px-5 pt-5 mt-2 mb-7 pr-10 relative">
          <Modal isOpen={isModalOpen} onClose={closeModal} />
          <div
            onClick={toggleDropdown}
            className="flex justify-center  items-center cursor-pointer"
            ref={dropdownRef}
          >
            <h1 className="pl-5 pr-3  text-[26px] font-semibold">{heading}</h1>
            <IoChevronDownOutline className=" mt-1 font-bold text-xl" />
          </div>
          {dropDownOpen && (
            <div
              className="bg-white mt-10 absolute left-0 w-full"
              style={{
                boxShadow: "0px 8px 40px 0px #00000030",
                maxWidth: "15rem",
                maxHeight: "60vh",
                top: "calc(40%)",
                left: "calc(1%)",
              }}
              onMouseLeave={() => setDropDownOpen(false)}
            >
              {applicationList.map((item, index) => (
                <div
                  key={index}
                  className="font-inter flex items-center align-middle bg-dropdown-item cursor-pointer"
                  onClick={() => applicationListHandler(item)}
                >
                  <span
                    className="w-full text-black text-base font-normal px-5 py-3 block bg-white hover:bg-[#2BB574]"
                    style={{ zIndex: 1000 }}
                  >
                    {item}
                  </span>
                </div>
              ))}
            </div>
          )}
          <div className="block text-right">
            <div className="flex justify-end gap-4">
              <div className="relative w-[320px] h-[50px]">
                <IoSearchOutline className="absolute left-3 w-6 h-6 top-[10px] text-gray-500" />
                <input
                  type="text"
                  placeholder="Search Applications"
                  className="w-full h-[2.7rem] focus:outline-none pl-10 text-sm  border border-gray-300 border-solid rounded gap-4  bg-gray-200"
                  onChange={handleSearchChange}
                />
              </div>
              <div ref={actionPopupRef}>
                <button
                  onClick={toggleNewActionsPopup}
                  style={{
                    height: "2.7rem",
                  }}
                  className="flex flex-row justify-between w-[193px] h-[2.7rem]  bg-transparent text-[#4871B7] border-[#4871B7] text-sm font-semibold  py-2 px-4  border rounded font-inter flex items-center"
                >
                  Actions{" "}
                  <IoChevronDownOutline className=" mt-1 font-bold text-xl ml-3 text-[#4871B7]" />
                </button>

                {actionsPopup && (
                  <>
                    <div
                      className="absolute z-10 w-[240px] bg-white border rounded shadow-lg mt-1 mr-40 right-[45px]"
                      onClick={actioinHandler}
                      onMouseLeave={() => setActionsPopup(false)}
                    >
                      <ul className="border">
                        <li className="border-0 px-4 py-2 cursor-pointer hover:bg-[#2BB574] hover:text-white text-left">
                          Export
                        </li>
                        <li className="border-0 px-4 py-2 cursor-pointer hover:bg-[#2BB574] hover:text-white text-left">
                          Import
                        </li>
                        <li className="border-0 px-4 py-2 cursor-pointer hover:bg-[#2BB574] hover:text-white text-left">
                          View Import History
                        </li>
                        <li className="border-0 px-4 py-2 cursor-pointer hover:bg-[#2BB574] hover:text-white text-left">
                          Delete
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>

              <button
                style={{
                  background:
                    "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                }}
                onClick={handleConvertClick}
                className="w-[193px] h-[2.75rem] bg-transparent text-white text-sm font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded font-inter"
              >
                + New Application
              </button>
            </div>
          </div>
        </div>

        <div className="w-[60%] mx-11 pr-10 flex justify-between mb-6 mt-7 cursor-default">
          <Filter
            title={"Total"}
            id={71}
            value={"$10,000 (5)"}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
          <Filter
            title={"New"}
            id={73}
            value={"$5,000 (3)"}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
          <Filter
            title={"Pending"}
            id={74}
            value={"$4,000 (2)"}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
          <Filter
            title={"Delivery"}
            id={75}
            value={"$3,000 (2)"}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
          <Filter
            title={"Complete"}
            id={76}
            value={"$3,000 (2)"}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
          <Filter
            title={"Closed - Lost"}
            id={77}
            value={"$3,000 (2)"}
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
          />
        </div>

        <div className="px-10">
          {fixedList?.length > 0 ? (
            <div>
                  
                  <ApplicationTable data={fixedList} change={isChanged} fetchClientsData={fetchClientsData}/>
              {totalPages > 0 ? (
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              ) : null}
            </div>
          ) : (
            <div className="flex text-base font-semibold text-gray-500 p-5 w-full justify-center items-center">
              No application found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Applications;
