import React, { useRef, useEffect, useState } from "react";
import BrandLogo from "./assets/logo_black.png";
import { BASE_URL, FRONT_URL } from "./appconfig";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Cross } from "./assets/cross.svg";
import Service from "../src/assets/layers-wp.svg";
import Business from "../src/assets/users-wp.svg";
import Agency from "../src/assets/search-wp.svg";
import About from "../src/assets/radix-icons_rocket-wp.svg";




const HamburgerMenu = ({ isOpen, toggleMenu }) => {
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isTallScreen, setIsTallScreen] = useState(950);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      toggleMenu(false);
    }
  };
  const loginNavigate = () => {
    setLoading(true);
    setTimeout(() => {
      navigate("/signin");
    }, 500);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 950) {
        setIsTallScreen(950)
      };
      if (window.innerHeight > 950) {
        setIsTallScreen(951)
      };
      if (window.innerHeight > 1050) {
        setIsTallScreen(1050)
      };
    };

    handleResize(); // Check the initial height
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }
  const loginWithRedirect = (provider) => {
    const checkbox = document.getElementById("myCheckbox");
    if (checkbox.checked) {
      setLoading(true);
      try {
        window.location.href = `${BASE_URL}/auth/login?url=${FRONT_URL}&provider=${provider}`;
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    } else {
      checkbox.reportValidity();
    }
  };
  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };
  if (!isDropdownOpen) {
    return;
  }
  return (
    <div className=" text-white absolute -right-4 text-lg cursor-pointer ">
      <ul
        ref={dropdownRef}
        className={`w-[35rem] h-[95vh] absolute border-2 border-gray-300 left-auto right-0 bg-[#F3F4F6] shadow-lg z-[1000] ${
          isTallScreen === 950 ? "mt-8" : null
        } ${isTallScreen === 951 ? "mt-8" : null} ${
          isTallScreen === 1050 ? "mt-10" : null
        }`}
        style={{ width: "35rem", height: "calc(100vh - 65px" }}
      >
        <div className="flex justify-between mt-6 mx-6 mb-7">
          <div className="flex items-center">
            <img src={BrandLogo} alt="brandlogo" style={{ height: "1.5rem" }} />
          </div>
          <div className="flex items-center">
            <Cross
              onClick={handleCloseDropdown}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <hr className="mx-6 " />
        <div className="text-neutral-500 mx-6 font-inter text-sm font-normal my-4">
          <li
            onClick={() =>
              window.open(
                "https://www.wealthsmyth.com/client-services/",
                "_blank"
              )
            }
            className="font-medium flex text-xl font-inter px-6 py-4 mt-2 cursor-pointer text-gray-600 hover:text-blue-500"
          >
            <img src={Business} alt=" Client Services" className="flex mr-3"></img>
            Client Services
          </li>
          <li
            onClick={() =>
              window.open(
                "https://www.wealthsmyth.com/business-partnership/",
                "_blank"
              )
            }
            className="font-medium flex text-xl font-inter px-6 py-4 mt-2 cursor-pointer text-gray-600 hover:text-blue-500 "
          >
            <img
              src={About}
              alt=" Client Services"
              className="flex mr-3"
            ></img>
            Business Partnership
          </li>
          <li
            onClick={() =>
              window.open(
                "https://www.wealthsmyth.com/agency-platform/",
                "_blank"
              )
            }
            className="font-medium flex text-xl font-inter px-6 py-4 mt-2 cursor-pointer text-gray-600   hover:text-blue-500 "
          >
            <img src={Service} alt=" Client Services" className="flex mr-3"></img>
            Agency Platform
          </li>
          <li
            onClick={() =>
              window.open("https://www.wealthsmyth.com/about-us/", "_blank")
            }
            className="font-medium flex text-xl font-inter  px-6 py-4 cursor-pointer text-neutral-600  hover:text-blue-500 "
          >
            <img
              src={Agency}
              alt=" Client Services"
              className="flex mr-3"
            ></img>
            About Us
          </li>
        </div>
        <hr className="mx-6 " />

        <div className=" text-neutral-500  font-inter text-sm font-normal my-2">
          <ul>
            <li
              onClick={() =>
                window.open(
                  "https://www.wealthsmyth.com/software-editions-and-pricing/",
                  "_blank"
                )
              }
              className="font-bold text-lg font-inter px-6 py-3  cursor-pointer text-gray-600  "
            >
              Pricing
            </li>
            <li
              onClick={() =>
                window.open("https://help.wealthsmyth.com/en", "_blank")
              }
              className="font-bold text-lg font-inter px-6 py-3  cursor-pointer text-gray-600 "
            >
              Help Center
            </li>
            <li
              onClick={() =>
                window.open("https://www.wealthsmyth.com/contact/", "_blank")
              }
              className="font-bold text-lg font-inter px-6 py-3 cursor-pointer text-gray-600 "
            >
              Contact Us
            </li>
          </ul>
        </div>
        <div className="flex gap-6 mx-6">
          <div className="font-medium text-xl  border border-blue-500 cursor-pointer flex w-[230px] h-[60px] p-[16px 24px] justify-center items-center space-x-2.5 flex-shrink-0 bg-white text-[#4871B7] rounded">
            <button onClick={loginNavigate} className="text-[#4871B7">
              Login
            </button>
          </div>
          <div className="font-medium text-xl  cursor-pointer flex w-[230px] h-[60px] p-[16px 24px] justify-center items-center space-x-2.5 flex-shrink-0 bg-blue-500  rounded">
            <button onClick={loginNavigate} className="text-white">
              Sign Up
            </button>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default HamburgerMenu;
