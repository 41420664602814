import React, { useState, useEffect, useRef } from "react";
import { FiMoreHorizontal, FiSave, FiX } from "react-icons/fi";
import { formatUSPhoneNumber, formatDate } from "../../../utills";
import { ReactComponent as SaveIcon } from "../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../src/assets/cancel.svg";
import { toast } from "react-toastify";
import useApi from "../../../custom-hooks/useApi";
import Spinner from "../../common/Spinner";
import { BASE_URL } from "../../../appconfig";

function RelatedPeople({ relations, relationShipList, reloadItems, setEdit, newForm }) {
  const api = useApi();

  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const dropdownRef = useRef(null);
  const editButtonRefs = useRef([]);
  const deleteButtonRefs = useRef([]);

  const [loading, setLoading] = useState(false);

  const handleDropdown = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleEdit = async (index, relationItem) => {
    setEditIndex(index);
    setEdit();
    setEditData({
      ...relationItem,
      ContactDetails: {
        ...relationItem.ContactDetails,
        Mobile_Phone: relationItem.ContactDetails.Mobile_Phone,
      },
    });
    setDropdownIndex(null);
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditData({});
  };

  useEffect(()=>{
    handleCancelEdit();
},[newForm])

  const handleSaveEdit = async () => {    
    if (!validateInputs()) {
      return;
    }
    try {
      setLoading(true);
      const response = await api.put(
        `${BASE_URL}/Account/Related_People/${editData?.RelatedPersonId}`,
        {
          First_Name: editData?.ContactDetails?.First_Name,
          Last_Name: editData?.ContactDetails?.Last_Name,
          Mobile_Phone: `${editData?.ContactDetails?.Mobile_Phone}`,
          Email_Address: editData?.ContactDetails?.Email_Address,
          RelationshipToAccount: editData?.RelationshipToAccount
        }
      );
      if (response?.status === 200) {
        setEditIndex(null);
        toast.success(response?.data?.message);
        reloadItems();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log({ error });
      toast.error(error?.message);
    }
  };

  const validateInputs = () => {
    const { First_Name, Last_Name, Mobile_Phone, Email_Address } =
      editData.ContactDetails;

    if (!First_Name || !Last_Name || !Mobile_Phone || !Email_Address) {
      toast.error("All fields are required.");
      return false;
    }

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(Mobile_Phone)) {
      toast.error("Invalid phone number. It should be 10 digits.");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(Email_Address)) {
      toast.error("Invalid email address.");
      return false;
    }

    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      ContactDetails: { ...editData.ContactDetails, [name]: value },
    });
  };

  const handleDelete = async (RelatedPersonId) => {
    try {
      setLoading(true);
      const response = await api.delete(
        `${BASE_URL}/Account/Related_People/${RelatedPersonId}`
      );
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        reloadItems();
        setDropdownIndex(null);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !editButtonRefs.current.some(ref => ref && ref.contains(event.target)) &&
        !deleteButtonRefs.current.some(ref => ref && ref.contains(event.target))
      ) {
        setDropdownIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <tbody>
      {relations?.length > 0
        ? relations.map((relationItem, index) => (
            <tr
              key={index}
              className={`border-[#D1D5DB] ${
                index + 1 === relations?.length ? "" : "border-b"
              }`}
            >
              <td className=" text-sm text-[#3A4253] font-inter font-normal p-4">
                {editIndex === index ? (
                  <div className="flex gap-2 ">
                    <input
                      type="text"
                      name="First_Name"
                      value={editData.ContactDetails?.First_Name}
                      onChange={handleInputChange}
                      className="w-[100px] appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                    />
                    <input
                      type="text"
                      name="Last_Name"
                      value={editData.ContactDetails?.Last_Name}
                      onChange={handleInputChange}
                      className="w-[100px] appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                    />
                  </div>
                ) : (
                  relationItem?.ContactDetails?.First_Name +
                  " " +
                  relationItem?.ContactDetails?.Last_Name
                )}
              </td>
              <td className="text-sm text-[#3A4253] font-normal font-inter p-4">
                {editIndex === index ? (
                  <select
                    name="RelationshipToAccount"
                    value={editData?.RelationshipToAccount}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        RelationshipToAccount: e.target.value,
                      })
                    }
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                  >
                    {relationShipList?.map((relationShipItem, index) => (
                      <option key={index} value={relationShipItem?.Relation}>
                        {relationShipItem?.Relation}
                      </option>
                    ))}
                  </select>
                ) : (
                  relationItem?.RelationshipToAccount
                )}
              </td>
              <td className="py-4 text-sm text-[#3A4253] font-inter font-normal p-4">
                {editIndex === index ? (
                  <input
                    type="text"
                    name="Mobile_Phone"
                    value={editData.ContactDetails?.Mobile_Phone}
                    onChange={handleInputChange}
                    className="appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                  />
                ) : (
                  formatUSPhoneNumber(
                    relationItem?.ContactDetails?.Mobile_Phone
                  )
                )}
              </td>
              <td className="py-4 text-sm text-[#3A4253] font-inter font-normal p-4">
                {editIndex === index ? (
                  <input
                    type="email"
                    name="Email_Address"
                    value={editData?.ContactDetails?.Email_Address}
                    onChange={handleInputChange}
                    className="appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                  />
                ) : (
                  relationItem?.ContactDetails?.Email_Address
                )}
              </td>
              <td className="relative p-4 font-medium text-[#4871B7] cursor-pointer"
                 ref={(el) => (dropdownRef.current = el)}
              >
                {editIndex === index ? (
                  <div className="flex  gap-2">
                    <button
                      className="disabled:opacity-50 disabled:cursor-not-allowed"
                      onClick={handleSaveEdit}
                    >
                      <SaveIcon />
                    </button>
                    <CancelIcon
                      className="cursor-pointer"
                      onClick={handleCancelEdit}
                    />
                  </div>
                ) : (
                  <>
                    <FiMoreHorizontal onClick={() => handleDropdown(index)} />
                    {dropdownIndex === index && (
                      <div className="absolute right-0 mt-2 w-48 bg-white">
                        <div className="absolute text-gray-600 z-10 w-auto bg-white border rounded shadow-lg  mr-14 right-0">
                          <ul className="border rounded">
                            <li
                              className="edit-button border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                              onClick={() => handleEdit(index, relationItem)}
                              ref={(el) => editButtonRefs.current[index] = el}
                            >
                              Edit
                            </li>
                            <li
                              onClick={() =>
                                handleDelete(relationItem?.RelatedPersonId)
                              }
                              ref={(el) => deleteButtonRefs.current[index] = el}
                              className="delete-button border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))
        : null}
    </tbody>
  );
}

export default RelatedPeople;
