import { useSelector } from "react-redux";
import SidebarMenu from "../SidebarMenu";
import Intercom from "@intercom/messenger-js-sdk";
import { useEffect } from "react";
import { INTERCOM_APP_ID } from "../../../appconfig";

const MainLayout = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const fullName = useSelector((state) => state.auth.fullName);

  useEffect(() => {
    if (user && user.id && user.email && fullName) {
      Intercom({
        app_id: INTERCOM_APP_ID,
        name: fullName,
        email: user.email.toLowerCase(),
        user_hash: user.userHash
      });
    }
  }, [user, fullName]);

  return (
    <div className="flex flex-1 flex-row flex-grow w-full bg-gray-100">
      <div className="w-1/12 flex flex-col" style={{ maxWidth: "92px" }}>
        <SidebarMenu />
      </div>
      <div className="flex flex-col flex-grow h-full overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
