import React from 'react'

function Payments() {
  return (
    <div className=' flex justify-center bg-green-500'>
    <div className=' text-black text-4xl font-inter '>Payments & Subscriptions</div>
    </div>
  )
}

export default Payments