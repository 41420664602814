import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import Token from "./Token.js";
import Error from "./components/common/error/Error.jsx";
import Dashboard from "./components/pages/Dashboard";
import UserProfile from "./components/pages/UserProfile";
import MemberEditonPrice from "./components/pages/memberEditonPrice/memberEditonPrice.js";
import Applications from "./components/pages/applications/Applications.js";
import FirstCampaigns from "./components/pages/campaigns/campaignDetails/FirstCampaigns.jsx";
import AddNewApplications from "./components/pages/applications/AddNewApplication.js";
import NewHouseholdApplication from "./components/pages/applications/NewHouseholdApplication.js";
import NewBusinessApplication from "./components/pages/applications/NewBusinessApplication.js";
import NewBusiness from "./components/pages/applications/NewBusiness.js";
import Header from "./components/common/header/Header";
import AgentDetail from "./components/pages/agents/AgentDetailForm";
import MainLayout from "./components/common/Layout/MainLayout";
import BackOfficeTopBar from "./components/pages/backOffice/BackOfficeTopBar";
import { useSelector } from "react-redux";
import Logout from "./Logout.jsx";
import Campaigns from "./components/pages/campaigns/Campaigns.jsx";
import SampleCampaign from "./components/pages/campaigns/campaignDetails/SampleCampaign.jsx";
import useTokenRefresh from "./custom-hooks/useTokenRefresh.js";
import Spinner from "./components/common/Spinner.jsx";
import SuperAdmin from "./components/table/SuperAdmin.js";
import "react-phone-number-input/style.css";
import Payments from "./components/pages/backOffice/Tabs/Profile/Payments.jsx";
import VerifyEmail from "./VerifyEmail.js";
import ProfileDetailsStatic from "./components/pages/backOffice/Tabs/Profile/ProfileStatic.jsx";
import NewLogin from "./components/common/newLogin/NewLogin.jsx";
import SignUpByEmail from "./SignUp.js";
import SignUp from "./SignIn.js";
import ForgotPass from "./components/common/newLogin/ForgotPass.jsx";
import MyLeads from "./components/pages/campaigns/leads/MyLeads.jsx";
import Clients from "./components/pages/clients/Clients.jsx";
import PipelineLanding from "../src/components/landingScreens/pipelineLanding.js";
import ShareLanding from "../src/components/landingScreens/shareLanding.js";
import NewClient from "./components/pages/clients/NewClient.jsx";
import Client from "./components/pages/clients/Client.jsx";
import NoPhone from "./components/pages/campaigns/NoPhone.jsx";
import SummaryOrDetails from "./components/pages/campaigns/SummaryOrDetails.jsx";
import UpdateClient from "./components/pages/clients/UpdateClient.jsx";
import PublicRouteLayout from "./components/common/Layout/PublicRouteLayout.jsx";

function App() {
  const [loading, setLoading] = useState(true);
  const isLoading = useSelector((state) => state.common.loading);
  const initializeTokenRefresh = useTokenRefresh();
  useEffect(() => {
    const initializeApp = async () => {
      // wait for app initialization
      await initializeTokenRefresh();
    };

    initializeApp();
  }, [initializeTokenRefresh]);

  const [isAdmin, setIsAdmin] = useState(false);
  const userRole = useSelector((state) => state.auth.role);
  const adminRoles = useSelector((state) => state.auth.adminRoles);

  useEffect(() => {
    setIsAdmin(adminRoles.includes(userRole));
    setLoading(false);
  }, [adminRoles, userRole]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <BrowserRouter>
      {isLoading && <Spinner />}
      <ToastContainer autoClose={1500} />
      <Routes>
        {/* Include routes that don't require Header and MainLayout */}
        <Route path="/error" element={<Error />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/signin"
          element={
            <>
              <PublicRouteLayout>
                <NewLogin />
              </PublicRouteLayout>
            </>
          }
        />
        <Route path="/verifyemail" element={
          <>
              <PublicRouteLayout>
              <VerifyEmail />
              </PublicRouteLayout>
            </>
              } />
        <Route path="/" element={
          <>
              <PublicRouteLayout>
              <SignUp />
              </PublicRouteLayout>
            </>
          } />
        <Route path="/forgot-password" element={
          <>
              <PublicRouteLayout>
              <ForgotPass />
              </PublicRouteLayout>
            </>
          } />
        <Route path="/signup-email" element={
          <>
              <PublicRouteLayout>
              <SignUpByEmail />
              </PublicRouteLayout>
            </>
          } />
        <Route path="/profile-static/:id" element={<ProfileDetailsStatic />} />
        {/* Include routes that require Header and MainLayout */}
        <Route
          path="/dashboard/:userid"
          element={
            <>
              <Header />
              <MainLayout>
                <Token />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/super-admin"
          element={
            <>
              <Header />
              <MainLayout>
                <SuperAdmin />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/dashboard"
          element={
            <>
              <Header />
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/profile"
          element={
            <>
              <Header />
              <MainLayout>
                <UserProfile />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/profile/:organizationId"
          element={
            <>
              <Header />
              <MainLayout>
                <UserProfile />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/campaigns"
          element={
            <>
              <Header />
              <MainLayout>
                <Campaigns />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/campaigns/sample-campaigns"
          element={
            <>
              <Header />
              <MainLayout>
                <SampleCampaign />
              </MainLayout>
            </>
          }
        />

        <Route
          path="/add-phoneno"
          element={
            <>
              <Header />
              <MainLayout>
                <NoPhone />
              </MainLayout>
            </>
          }
        />

        <Route
          path="/summary"
          element={
            <>
              <Header />
              <MainLayout>
                <SummaryOrDetails />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/campaigns/first-campaigns"
          element={
            <>
              <Header />
              <MainLayout>
                <FirstCampaigns />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/leads"
          element={
            <>
              <Header />
              <MainLayout>
                <MyLeads />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/clients"
          element={
            <>
              <Header />
              <MainLayout>
                <Clients />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/client"
          element={
            <>
              <Header />
              <MainLayout>
                <Client />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/clients/new"
          element={
            <>
              <Header />
              <MainLayout>
                <NewClient />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/clients/edit"
          element={
            <>
              <Header />
              <MainLayout>
                <UpdateClient />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/pipeline"
          element={
            <>
              <Header />
              <MainLayout>
                <PipelineLanding />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/member-editon-price"
          element={
            <>
              <Header />
              <MainLayout>
                <MemberEditonPrice />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/clients"
          element={
            <>
              <Header />
              <MainLayout>
                <Clients />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/applications"
          element={
            <>
              <Header />
              <MainLayout>
                <Applications />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/applications/application-details/:id"
          element={
            <>
              <Header />
              <MainLayout>
                <NewBusiness />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/applications/new-application"
          element={
            <>
              <Header />
              <MainLayout>
                <AddNewApplications />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/applications/new-household-application"
          element={
            <>
              <Header />
              <MainLayout>
                <NewHouseholdApplication />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/applications/new-business-application"
          element={
            <>
              <Header />
              <MainLayout>
                <NewBusinessApplication />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/agent/:agentId"
          element={
            <>
              <Header />
              <MainLayout>
                <AgentDetail />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/share"
          element={
            <>
              <Header />
              <MainLayout>
                <ShareLanding />
              </MainLayout>
            </>
          }
        />
        <Route
          path="/back-office"
          element={
            <>
              <Header />
              <MainLayout>
                {isAdmin ? <BackOfficeTopBar /> : <Error accessError />}
              </MainLayout>
            </>
          }
        />
        <Route
          path="/payments"
          element={
            <>
              <Header />
              <MainLayout>
                <Payments />
              </MainLayout>
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
