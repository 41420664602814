import React from 'react'
import { useSelector } from 'react-redux';



function PerformanceHeader() {
    const agentData = useSelector((state) => state.agent.data);
  return (
    <div>
        
               <div className="grid grid-cols-1 gap-4  py-1 font-inter">
            {agentData && (
              <div className="bg-white border shadow-md rounded p-4 font-inter">
                <div
                  className="grid grid-cols-6 p-2 gap-4 font-inter text-base"
                  style={{
                    gridTemplateColumns:
                      "repeat(3, minmax(0, 1fr)) minmax(0, 1fr) repeat(2, minmax(0, 1fr))",
                  }}
                >
                  <div className="flex flex-col">
                    <span className="mb-1 font-semibold">Level</span>
                    <span className="text-gray-500 truncate">
                      {/* {agentData.level_description || "-"} */}
                      Training Associate
                    </span>
                  </div>

                  {/* Column 2 */}
                  <div className="flex flex-col ml-2">
                    <span className="mb-1 font-semibold">Cash Flow Mil.</span>
                    <span className="text-gray-500">
                    $100K
                      7hfk
                    </span>
                  </div>
                  <div className="flex flex-col w-40 ">
                    <span className="mb-1 font-semibold ">BS/NP (Last 12 Mo)</span>
                    <span className="text-gray-500">
                      252K
                    </span>
                  </div>
                  <div className="flex flex-col ml-6">
                    <span className="mb-1 font-semibold ">Persistency</span>
                    <span className="text-gray-500 break-all truncate">
                      97%
                    </span>
                  </div>

                  {/* Column 2 */}
                  <div className="flex flex-col">
                    <span className="mb-1 font-semibold">Licensed Agents</span>
                    <span className="text-gray-500">
                   11
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="mb-1 font-semibold">Base Shop Team</span>
                    <span className="text-gray-500">
                   24
                    </span>
                  </div>
                </div>
              </div>
            )}
           
           
          </div>





          </div>
   
  )
}

export default PerformanceHeader