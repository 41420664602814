import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./store";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain="dev-3yvihoj8y6xtx06m.us.auth0.com"
    clientId="jC9u3ny1jBzCGjSZOeDj2QegTdD3nLO3"
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <React.StrictMode>
      <Provider store={store}>
        <div className="min-h-screen flex flex-col">
          <App />
        </div>
      </Provider>
    </React.StrictMode>
  </Auth0Provider>
);
reportWebVitals();
