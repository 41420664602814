import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";

import { ReactComponent as SaveIcon } from "../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../src/assets/cancel.svg";

import useApi from "../../../custom-hooks/useApi";
import { BASE_URL } from "../../../appconfig";
import Spinner from "../../common/Spinner";

const RelatedPeopleForm = ({
  AccountID,
  relationShipList,
  toggle,
  onSaveSuccess,
}) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    Mobile_Phone: "",
    RelationshipToAccount: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevData) => ({ ...prevData, [name]: "" }));

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.First_Name) newErrors.First_Name = "First name is required";
    if (!formData.Last_Name) newErrors.Last_Name = "Last name is required";
    if (!formData.Email_Address)
      newErrors.Email_Address = "Email address is required";
    if (!formData.Mobile_Phone)
      newErrors.Mobile_Phone = "Phone number is required";
    if (!formData.RelationshipToAccount)
      newErrors.RelationshipToAccount = "Please select a relationship";
    if (!/^\d{10}$/.test(formData?.Mobile_Phone)) {
      newErrors.Mobile_Phone = "Invalid phone number";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.Email_Address && !emailRegex.test(formData.Email_Address)) {
      newErrors.Email_Address = "Invalid email address";
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    setLoading(true);
    saveRelatedPeople();
  };

  const saveRelatedPeople = async () => {
    try {
      const response = await api.post(
        `${BASE_URL}/Account/Related_People/${AccountID}`,
        formData
      );
      if (response?.status === 200) {
        setTimeout(() => {
          setLoading(false);
          toast.success(response?.data?.message);
          toggle();
          onSaveSuccess();
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <tr className="border=[#D1D5DB] border-b">
      {loading && <Spinner />}
      <td className="flex gap-2  p-4">
        <div className="w-1/2">
          <input
            name="First_Name"
            placeholder="First Name"
            type="text"
            value={formData.First_Name}
            onChange={handleChange}
            className="w-full bg-neutral-200 text-neutral-700 text-sm border border-gray-300 rounded p-2"
          />
          {errors.First_Name && (
            <p className="text-red-500 text-xs">{errors.First_Name}</p>
          )}
        </div>
        <div className="w-1/2">
          <input
            name="Last_Name"
            placeholder="Last Name"
            type="text"
            value={formData.Last_Name}
            onChange={handleChange}
            className="w-full bg-neutral-200 text-neutral-700 text-sm border border-gray-300 rounded p-2"
          />
          <p
            className={`text-red-500 mt-1 text-xs h-4 ${
              errors.Last_Name ? "visible" : "invisible"
            }`}
          >
            {errors.Last_Name || " "}
          </p>
        </div>
      </td>
      <td className="px-3 py-2">
        <select
          name="RelationshipToAccount"
          value={formData.RelationshipToAccount}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-400 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        >
          <option value="">Relationship</option>
          {relationShipList?.length > 0 ? (
            <>
              {relationShipList?.map((relationShipItem) => (
                <option
                  key={relationShipItem?.index}
                  value={relationShipItem?.Relation}
                >
                  {relationShipItem?.Relation}
                </option>
              ))}
            </>
          ) : null}
        </select>
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.RelationshipToAccount ? "visible" : "invisible"
          }`}
        >
          {errors.RelationshipToAccount || " "}
        </p>
      </td>
      <td className="p-4">
        <input
          type="number"
          name="Mobile_Phone"
          placeholder="Phone"
          value={formData.Mobile_Phone}
          onChange={handleChange}
          className="w-full bg-neutral-200 text-neutral-700 text-sm border border-gray-300 rounded p-2"
        />
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.Mobile_Phone ? "visible" : "invisible"
          }`}
        >
          {errors.Mobile_Phone || " "}
        </p>
      </td>

      <td className="p-4">
        <input
          name="Email_Address"
          placeholder="Email"
          type="email"
          value={formData.Email_Address}
          onChange={handleChange}
          className="w-full appearance-none block bg-neutral-200 text-neutral-700 text-sm border border-gray-300 rounded p-2"
        />
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.Email_Address ? "visible" : "invisible"
          }`}
        >
          {errors.Email_Address || " "}
        </p>
      </td>
      <td className="p-4 flex gap-2">
        <div className="flex  gap-2">
          <button
            className="disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleSubmit}
          >
            <SaveIcon />
          </button>
          <CancelIcon className="cursor-pointer" onClick={toggle} />
        </div>
      </td>
    </tr>
  );
};

export default RelatedPeopleForm;
