import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import menu from "./assets/menuTop.svg";
import Brand from "./assets/brand.png";
import Penny3 from "./assets/Penny3.png";
import googleLogo from "./assets/flat-color-icons_google.svg";
import microsoftLogo from "./assets/logos_microsoft-icon.svg";
import BackgroundImg from "./assets/background.svg";
import { BASE_URL, FRONT_URL } from "./appconfig";
import Spinner from "./components/common/Spinner";
import HamburgerMenu from "../src/HamburgerMenu";
import { useDispatch, useSelector } from "react-redux";
import { logoutApp } from "./redux/slices/authSlice";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
    const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  useEffect(() => {
    const redirectUser = async () => {
      if (user && user.BaseShopID) {
        navigate(`/dashboard/${user.id}`);
      } else {
        await dispatch(logoutApp());
      }
    };
    redirectUser();
  }, [dispatch, navigate, user]);

  const loginWithRedirect = (provider) => {
    const checkbox = document.getElementById("myCheckbox");
    if (checkbox.checked) {
      setLoading(true);
      try {
        window.location.href = `${BASE_URL}/auth/login?url=${FRONT_URL}&provider=${provider}`;
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    } else {
      checkbox.reportValidity();
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const checkbox = document.getElementById("myCheckbox");
    if (checkbox.checked && (email || !checkbox.required)) {
      setTimeout(() => {
        navigate("/signup-email", { state: { email: email } });
      }, 500);
    } else {
      checkbox.reportValidity();
      setLoading(false);
    }
  };

  const loginNavigate = () => {
    setLoading(true);
    setTimeout(() => {
      navigate("/signin");
    }, 500);
  };

  const toggleHamburgerMenu = () => {
    setIsHamburgerMenuOpen((prev) => !prev);
  };

  return (
    <div className="h-screen">
      {loading && <Spinner />}
      <nav
        style={{ backgroundColor: "#4971B8"}}
        className="flex items-center justify-between p-4 relative h-[70px]"
        id="nav-bar"
      >
        <img src={Brand} alt="brandlogo" style={{ height: "1.5rem" }} />

        <div className="flex group items-center space-x-6 relative">
          <div className="cursor-pointer flex w-[115px] h-[42px] p-[16px 24px] justify-center items-center space-x-2.5 flex-shrink-0 bg-white rounded">
            <button onClick={loginNavigate} className="text-[#4871B7]">
              Log In
            </button>
          </div>
          <img
            onClick={toggleHamburgerMenu}
            className="cursor-pointer pl-1 w-6"
            src={menu}
            alt="bellicon"
          />
          {isHamburgerMenuOpen && (
            <HamburgerMenu
              isOpen={isHamburgerMenuOpen}
              toggleMenu={toggleHamburgerMenu}
            />
          )}
        </div>
      </nav>
      <div
        className="w-full max-w-full h-[699px] flex bg-gradient-to-r from-[rgba(0,0,0,0.83)] to-[rgba(7,23,51,0.56)] via-transparent"
        style={{ backgroundImage: `url(${BackgroundImg})` }}
      >
        <div className="w-3/5 flex flex-col ml-10 items-center justify-center">
          <h1 className="w-[766px] text-white font-inter mb-5 text-5xl font-bold leading-normal">
            Sign up for WealthSmyth
            <br />- Free Forever
          </h1>
          <p className="w-[766px] text-white mb-10 font-inter text-2xl font-medium leading-[36px]">
            Power your independent agency and take growth
            <br /> to the next level with one easy-to-use platform
            <br />
            and automated workflows powered by AI.
          </p>
          <div className=" w-[766px] flex flex-col items-start space-y-4">
            <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
              <div className="flex items-center mb-5 space-x-2">
                <input
                  required
                  type="checkbox"
                  id="myCheckbox"
                  className="mr-2"
                  checked={isChecked}
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                  }}
                />
                <label
                  htmlFor="myCheckbox"
                  className="text-white font-inter text-[13px] font-normal leading-[18px]"
                >
                  By signing up, I agree to WealthSmyth’s{" "}
                  <a
                    href="https://wealthsmyth.com/terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white font-inter text-[13px] font-bold leading-[18px]"
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://wealthsmyth.com/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white font-inter text-[13px] font-bold leading-[18px]"
                  >
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
              <div className="flex gap-5">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    loginWithRedirect("google");
                  }}
                  className="w-[260px] h-[72px] px-5 py-2.5 flex justify-center items-center gap-4 flex-shrink-0 border border-white text-white ml-2"
                >
                  <img
                    src={googleLogo}
                    alt="Google Logo"
                    className="h-6 w-6"
                  ></img>
                  Signup with Google
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    loginWithRedirect("microsoft");
                  }}
                  className="w-[260px] h-[72px] px-5 py-2.5 flex justify-center items-center gap-4 flex-shrink-0 border border-white text-white"
                >
                  <img
                    src={microsoftLogo}
                    alt="Microsoft Logo"
                    className="h-6 w-6"
                  ></img>
                  Signup with Microsoft
                </button>
              </div>
              <div className="flex items-center justify-center mt-4">
                <div className="w-[261px] h-[3px] bg-white"></div>
                <span className="px-2 text-white font-inter text-[13px] font-normal leading-[18px]">
                  or
                </span>
                <div className="w-[261px] h-[3px] bg-white"></div>
              </div>
              <div className="flex items-center">
                <input
                  type="email"
                  placeholder="Enter your work email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-[375px] h-[56px] px-4 border border-gray-300 text-neutral-700 font-inter text-20 font-semibold leading-normal focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required={isChecked}
                />
                <button
                  className="w-[175px] h-[56px] px-6 flex justify-center items-center gap-2 bg-gradient-to-br from-[#03733E] to-[#1ED77F] text-white"
                  type="submit"
                >
                  Sign up for free
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="w-[40%] ml-10 flex flex-col items-center">
          <img src={Penny3} alt="AI-loginlogo" className="h-[100%]" />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
