import React, { useEffect, useState } from "react";
import CampSideBar from "./CampSideBar";
import { useNavigate } from "react-router";
import NoPhone from "./NoPhone";
import useApi from "../../../custom-hooks/useApi";
import { BASE_URL } from "../../../appconfig";
import SummaryOrDetails from "./SummaryOrDetails";
import { useSelector } from "react-redux";
import CampaignLanding from "../../landingScreens/campaignsLanding";

const Campaigns = () => {
  const visitedPages = useSelector((state) => state.common.visitedPages)
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [data, setData] = useState([]);
  const [isRejected, setIsRejected] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [campaignClicked, setCampaignClicked] = useState(null);
  const navigate = useNavigate();
  const api = useApi();
  const userRole = useSelector((state) => state.auth.role);
  const adminRoles = useSelector((state) => state.auth.adminRoles);
  const [isAdmin, setIsAdmin] = useState(false);

  const [firstCampaign, setFirstCampaign] = useState(null);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleCampaignDetails = (campaign) => {
    setCampaignClicked(campaign);
  };

  useEffect(() => {
    setIsAdmin(userRole === "super_admin");
  }, [adminRoles, userRole]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/Campaign/ShortCampaign`);
        setData(response.data?.check);
        setIsPending(response.data?.check?.Status === 23);
        setIsRejected(response.data?.check?.Status === 25);
        setIsApproved(response.data?.check?.Status === 24);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  if (isAdmin) {
    navigate("/super-admin");
  }

  return (
    <>
      {!visitedPages.includes(window.location.pathname) ? (
        <CampaignLanding />
      ) : (
        <div className="flex">
          <div>
            <CampSideBar
              setFirstCampaign={setFirstCampaign}
              campaignClick={toggleCampaignDetails}
              CountryCode={data.CountryCode}
              number={data.PhoneNo}
              status={data.Status}
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />
          </div>
          <div className="w-full bg-gray-100">
            {!isApproved && (
              <NoPhone isPending={isPending} isRejected={isRejected} />
            )}
            {isApproved && (
              <SummaryOrDetails
                campaign={campaignClicked || firstCampaign}
                campaignClick={toggleCampaignDetails}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Campaigns;
