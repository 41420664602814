import React from 'react'
import teamLandingSvg from '../../assets/landingScreensSvg/Group 634.svg'
import { setVisitedPages } from '../../redux/slices/commonSlice';
import { useDispatch } from 'react-redux';

const TeamLanding = () => {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center justify-center bg-gray-100">
      <div className="flex flex-col items-center m-5 m-8 bg-white p-3 rounded-lg w-[90%] h-[85vh] border border-[#E5E7EB] ">
        <div className="flex flex-col items-center">
          {/* Image Part */}
          <div className="flex justify-center w-full">
            <img
              src={teamLandingSvg}
              alt="Landing Image"
              className="rounded-l-lg object-cover"
            />
          </div>
          {/* Content Part */}
          <div className="w-full p-8 pl-0 flex flex-col justify-start">
            <h1 className="text-2xl font-semibold text-gray-800 mb-4">
              Radical Downline Transparency
            </h1>
            <p className="text-base font-medium text-gray-500 mb-6">
              View your complete downline, the full list of independent team
              members at every stage of performance and development.
            </p>
            <button
              className="text-base bg-[#4971B8] text-white px-6 py-3 w-[365px] rounded-md"
              type="button"
              onClick={() =>
                dispatch(setVisitedPages(window.location.pathname))
              }
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamLanding