import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarGraph = () => {
  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }

    const ctx = chartContainer.current.getContext('2d');
    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['First Paycheck', 'Net License ($1K)', '$10K', '$50K', '$100K' ,'$250K' ,'$500K' ,'$1M'],
        datasets: [
          {
            label: 'Licensed Agents',
            data: [75, 64, 40, 30, 20,13,8,5],
            backgroundColor: '#4871B7',
            barThickness: 42,
  barPercentage: 0.5,
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: true 
            }
          },
          x: {
            grid: {
              display: false 
            }
          }
        },
        plugins: {
            legend: {
              display: false,
              position: 'top',
              align: 'end',
             
           
            }
          }
        
      },
    });

    return () => {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <div className="w-[854px] h-[424px] bg-white  rounded-md">
      <canvas className="w-full h-full" ref={chartContainer} />
    </div>
  );
};

export default BarGraph;

