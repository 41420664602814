// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    authToken: localStorage.getItem("accessToken") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
    fullName: localStorage.getItem("Fullname") || null,
    user: JSON.parse(localStorage.getItem("user")) || null,
    permission: JSON.parse(localStorage.getItem("permission")) || null,
    role: localStorage.getItem("role") || null,
    adminRoles: ['admin','super_admin'],
    refreshInterval: null,
    refreshExp: null,
    timeInterval: null,
    premiumInfo: JSON.parse(localStorage.getItem("premiumInfo")) || null
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setAuthToken: (state, action) => {
      localStorage.setItem("accessToken", action.payload);
      state.authToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      localStorage.setItem("refreshToken", action.payload);
      state.refreshToken = action.payload;
    },
    setFullName: (state, action) => {
      localStorage.setItem("Fullname", action.payload);
      state.fullName = action.payload;
    },
    setUser: (state, action) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
    },
    setPremiumInfo: (state, action) => {
      localStorage.setItem("premiumInfo", JSON.stringify(action.payload));
      state.premiumInfo = action.payload;
    },
    setPermission: (state, action) => {
      localStorage.setItem(
        "permission",
        JSON.stringify(action.payload)
      );
      state.permission = action.payload;

    },
    setRole: (state, action) => {
      localStorage.setItem("role", action.payload);
      state.role = action.payload;
    },
    resetAll: (state) => {
      state.authToken = null;
      state.fullName = null;
      state.user = null;
      state.permission = null;
      state.role = null;
    },
    logoutApp: (state) => {
      state.authToken = null;
      state.refreshToken = null;
      state.fullName = null;
      state.user = null;
      state.permission = null;
      state.role = null;
      localStorage.clear();
    },
    setRefreshInterval: (state, action) => {
      state.refreshInterval = action.payload;
    }, 
    setRefreshExp: (state, action) => {
      state.refreshExp = action.payload;
    },
    setTimeInterval: (state, action) => {
      state.timeInterval = action.payload;
    }
  }
});

export const {
  setAuthenticated,
  setAuthToken,
  setRefreshToken,
  setFullName,
  setPermission,
  setRole,
  setUser,
  setPremiumInfo,
  resetAll,
  logoutApp,
  setRefreshInterval,
  setRefreshExp,
  setTimeInterval,
} = authSlice.actions;
export default authSlice.reducer;
