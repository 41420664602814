import React from 'react'
import pipelineLandingSvg from '../../assets/landingScreensSvg/pipelineLandingSvg.svg'
import users from '../../assets/landingScreensSvg/clients/users.svg'
import filterSvg from "../../assets/landingScreensSvg/leads/filter.svg";

const pipelineLanding = () => {
  return (
    <div className="flex items-center justify-center bg-gray-100">
    <div className="flex flex-col items-center  m-5 mt-14 bg-white p-3 rounded-lg  w-[90%] h-[80vh] border border-[#E5E7EB] ">
      <div className="flex flex-row items-center w-full">
        {/* Image Part */}
        <div className="flex w-[60%]">
          <img
            src={pipelineLandingSvg}
            alt="Landing Image"
            className="rounded-l-lg object-cover"
          />
        </div>
        {/* Content Part */}
        <div className="p-8 flex flex-col justify-start w-[40%]">
          <div>
            <h1 className="text-2xl font-semibold text-gray-800 mb-2">
            Grow Your Agency
            </h1>
            <p className="text-base font-medium text-gray-500 mb-6">
            The Pipeline Center allows you to keep track of where cases stand in the process from initiation to delivery.
            </p>
          </div>

          <div>
            <div className="flex flex-row my-2">
              <img
                src={users}
                alt="Landing Image"
              />
              <h1 className="text-xl font-semibold text-gray-800 ml-2">
              What’s a Case?
              </h1>
            </div>
            <p className="text-base font-medium text-gray-500 mb-6 ml-8">
            A Case is a record that helps you keep track of all the information about an application for a financial product from initiation to delivery and commission payout.
            </p>
          </div>

          <div>
            <div className="flex flex-row my-2">
              <img
                src={filterSvg}
                alt="Landing Image"
              />
              <h1 className="text-xl font-semibold text-gray-800 ml-2">
              Parent Agency
              </h1>
            </div>
            <p className="text-base font-medium text-gray-500 mb-6 ml-8">
            Your Parent Agency Setting determines which providers, products, and contract rates are available for your Cases.
            </p>
          </div>
          <button className="text-base font-semibold bg-[#4971B8] text-white px-6 py-3 mt-16 w-full rounded-md">
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default pipelineLanding