import React, { useState } from "react";
import StageForm from "./StageForm";
import { useSelector, useDispatch } from "react-redux";
import {
  updateAgentData
} from "../../../../redux/thunks/agentThunk";
import { setFormData } from "../../../../redux/slices/agentSlice";
import useApi from "../../../../custom-hooks/useApi";
import { isValid, format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Stage2 = ({ handleChange }) => {

  const stepId = useSelector((state) => state.agent.currentStep);
  const agentId = useSelector((state) => state.agent.agentId);
  const dispatch = useDispatch();
  const api = useApi();  
  const setDate = (date, name) => {

    try {
      console.log(date);

      if (isValid(date)) {
        const formattedDate = format(date, "yyyy-MM-dd");
        dispatch(setFormData({ [name]: formattedDate }));
      } else {
        console.error("Invalid date");
      }
    } catch (error) {
      console.error("Error parsing date:", error);
    }
  };
  
  const formData = useSelector((state) => state.agent.formData);
  const currentStepData = useSelector((state) => state.agent.currentStepData);
  
  const [isEditing, setEditing] = useState(false);
  
  const toggleEditing = () => {
    if (isEditing) {
      dispatch(updateAgentData({ stepId, agentId, formData, currentStepData, api }));
    }
    setEditing(!isEditing);
  };
  
  const cancelEditing = () => {
    dispatch(setFormData(currentStepData));
    setEditing(false);
  };

  if (formData!==null && currentStepData !== null ) {
    return (
      <>
        <StageForm
          stageNum={stepId}
          isEditable={true}
          cancelEditing={cancelEditing}
          isEditing={isEditing}
          toggleEditing={toggleEditing}
        >
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 1 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">Field Training List</span>
            </div>
            <div className="mb-2 md:w-1/2">
              <span>{currentStepData.Field_Training_List || "-"}</span>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 2 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Exam Passed Date&nbsp;
                <span className="font-bold font-inter text-red-700">*</span>
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              {!isEditing && (
                <span>
                  {currentStepData.Exam_Passed !== null
                    ? isValid(new Date(currentStepData.Exam_Passed))
                      ? format(
                          new Date(currentStepData.Exam_Passed),
                          "M/d/yyyy"
                        )
                      : "-"
                    : "-"}
                </span>
              )}
              {isEditing && (
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  name="Exam_Passed"
                  className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  selected={
                    formData.Exam_Passed ? new Date(formData.Exam_Passed) : null
                  }
                  onChange={(date) => setDate(date, "Exam_Passed")}
                  placeholderText="mm/dd/yyyy"
                  placeholder="m/d/Y"
                  dateFormat="M/d/yyyy"
                  showYearDropdown
                  dropdownMode="select"
                  required
                />
              )}
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 3 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                License Date&nbsp;
                <span className="font-bold font-inter text-red-700">*</span>
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              {!isEditing && (
                <span>
                  {currentStepData.LicenseDate !== null
                    ? isValid(new Date(currentStepData.LicenseDate))
                      ? format(
                          new Date(currentStepData.LicenseDate),
                          "M/d/yyyy"
                        )
                      : "-"
                    : "-"}
                </span>
              )}
              {isEditing && (
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  name="LicenseDate"
                  className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  selected={
                    formData.LicenseDate ? new Date(formData.LicenseDate) : null
                  }
                  onChange={(date) => setDate(date, "LicenseDate")}
                  placeholderText="mm/dd/yyyy"
                  placeholder="m/d/Y"
                  dateFormat="M/d/yyyy"
                  showYearDropdown
                  dropdownMode="select"
                  required
                />
              )}
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 4 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                National Producer Number&nbsp;
                <span className="font-bold font-inter text-red-700">*</span>
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              {!isEditing && (
                <span>{currentStepData.National_Producer_Number || "-"}</span>
              )}
              {isEditing && (
                <input
                  className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                  name="National_Producer_Number"
                  type="text"
                  placeholder="National_Producer_Number"
                  value={
                    formData.National_Producer_Number !== null
                      ? formData.National_Producer_Number
                      : ""
                  }
                  onChange={(e) => handleChange(e)}
                  required
                />
              )}
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 5 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                State Resident Number&nbsp;
                <span className="font-bold font-inter text-red-700">*</span>
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              {!isEditing && (
                <span>{currentStepData.Resident_Number || "-"}</span>
              )}
              {isEditing && (
                <input
                  className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                  name="Resident_Number"
                  type="text"
                  placeholder="Resident_Number"
                  value={
                    formData.Resident_Number !== null
                      ? formData.Resident_Number
                      : ""
                  }
                  onChange={(e) => handleChange(e)}
                  required
                />
              )}
            </div>
          </div>
        </StageForm>
      </>
    );
  }
};
export default Stage2;
