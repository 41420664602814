/* eslint-disable react-hooks/exhaustive-deps */
// useTokenRefresh.js
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useRefreshAuthToken from "./useRefreshAuthToken";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import {
  setAuthToken,
  setRefreshInterval,
  setTimeInterval,
} from "../redux/slices/authSlice";

let isTokenRefreshInitialized = false; // Singleton flag

const useTokenRefresh = () => {
  const dispatch = useDispatch();
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const refreshAuthToken = useRefreshAuthToken();
  const refreshIntervalState = useSelector(
    (state) => state.auth.refreshInterval
  );
  const refreshExp = useSelector((state) => state.auth.refreshExp);
  const [initialized, setInitialized] = useState(false);

  const initializeTokenRefresh = async () => {
    if (
      window.location.pathname === "/logout" ||
      isTokenRefreshInitialized ||
      !accessToken
    ) {
      return;
    }
    const updateAuthToken = async () => {
      try {
        const response = await refreshAuthToken();
        if (response.status === 200 && response.data.accessToken) {
          dispatch(setAuthToken(response.data.accessToken));
        } else {
          console.log("Error occurred while refreshing token: ", response);
          // toast.error("Error while refreshing the token", {
          //   theme: "dark",
          // });
          // setTimeout(() => {
          console.log("logging out from updateToken else");
          window.location.replace("/logout");
          // }, 2000);
        }
      } catch (error) {
        let errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Auth Token expired. Logging out.";
        console.error("Error while refreshing the token: ", error);
        toast.error(errorMessage, { theme: "dark" });
        // setTimeout(() => {
        console.log("logging out from updateToken catch");

        window.location.replace("/logout");
        // }, 2000);
      }
    };

    const setTokenRefreshInterval = async () => {
      let decodedAccessToken = jwtDecode(accessToken);
      if (
        decodedAccessToken.exp * 1000 - 1000 * 60 * 2 <=
        new Date().getTime()
      ) {
        const refreshTokenResponse = await refreshAuthToken();
        dispatch(setAuthToken(refreshTokenResponse.data.accessToken));
        decodedAccessToken = jwtDecode(refreshTokenResponse.data.accessToken);
      }

      if (decodedAccessToken) {
        // setTimeInterval to refresh token - exp time - current time - 2 min(offset for refreshing token 2 min earlier);
        const timeInterval =
          decodedAccessToken.exp * 1000 - new Date().getTime() - 1000 * 60 * 2;
        dispatch(setTimeInterval(timeInterval));

        const refreshInterval = setInterval(() => {
          updateAuthToken();
          if (refreshExp <= new Date().getTime()) {
            console.log(
              "RefreshExp: " +
                refreshExp +
                ", is less than current time: " +
                new Date().getTime() +
                ", Clearing refreshTokenInterval"
            );
            clearInterval(refreshInterval);
          }
        }, timeInterval); // Refresh every accessTokenExpMin - 2 min
        // Save the interval ID to clear it when needed
        dispatch(setRefreshInterval(refreshInterval));
      } else {
        console.error(
          "Couldn't register the refresh Timer as token not available: ",
          decodedAccessToken
        );
        return;
      }
    };

    try {
      if (refreshToken && accessToken) {
        await setTokenRefreshInterval();
        isTokenRefreshInitialized = true;
        setInitialized(true);
      }
    } catch (error) {
      // Handle initialization error
      console.error("Error during token refresh initialization: ", error);
      setInitialized(true);
    }
  };

  useEffect(() => {
    initializeTokenRefresh();
    return () => {
      // Clear the refresh interval when the component unmounts
      clearInterval(refreshIntervalState);
    };
  }, [refreshToken, dispatch]);

  return initializeTokenRefresh;
};

export default useTokenRefresh;
