import React, { useState } from "react";
import BrandLogo from "./assets/logo_black.png";
import Banner from "./assets/signupbanner.png";
import { useLocation, useNavigate } from "react-router-dom";
import useApi from "./custom-hooks/useApi";
import Spinner from "./components/common/Spinner";
import { toast } from "react-toastify";
import { BASE_URL } from "./appconfig";
import { useDispatch } from "react-redux";
import {
  setAuthToken,
  setFullName,
  setRefreshToken,
  setRole,
  setUser,
} from "./redux/slices/authSlice";

const SignUpByEmail = () => {
  const location = useLocation();
  const email = location.state?.email || "";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== repeatPassword) {
      toast.error("Passwords do not match", {
        theme: "dark",
      });
      return;
    }
    const json = {
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
    };
    try {
      setLoading(true);
      const response = await api.post(`${BASE_URL}/auth/create-email`, json);
      if (response.status === 200 && response.data.data.accessToken) {
        toast.success("Logged In successfully", {
          theme: "dark",
        });
        dispatch(setAuthToken(response.data.data.accessToken));
        dispatch(setRefreshToken(response.data.data.refreshToken));
        dispatch(
          setFullName(
            response.data.data.user.First_name +
              " " +
              response.data.data.user.Last_name
          )
        );
        let user = localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : {};
        const id = response?.data?.data?.user?.id;
        dispatch(
          setUser({
            ...user,
            id: response.data.data.user.id,
            roleid: response.data.data.user.roleid,
            FirstName: response.data.data.user.First_name,
            LastName: response.data.data.user.Last_name,
            organizationId: response.data.data.user.BaseShopID,
            email: response.data.data.user.email,
            SignedInUsingEmail: response.data.data.user.SignedInUsingEmail,
            ImageName: response.data.data.user.ImageName,
            AgentID: response.data.data.user.AgentCode,
          })
        );
        dispatch(setRole(response.data.data.user.roleid));

        navigate(`/profile/${id}`);
      } else {
        const errorMessage = response.data?.error || "Failed to sign up";
        toast.error(errorMessage, {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.user?.message, {
        theme: "dark",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen max-h-screen max-w-full flex bg-white via-transparent">
      {loading && <Spinner />}
      <div className="w-2/5 flex flex-col ml-10 items-center justify-center">
        <div className="flex flex-col items-center mb-10">
          <img
            src={BrandLogo}
            alt="brandlogo"
            style={{ height: "1.5rem" }}
            className="w-auto h-[36px]"
          />
        </div>
        <p className="w-[766px] text-black mb-10 font-inter text-2xl font-medium leading-[36px] text-center text-[20px] font-normalr">
          Password must be 12 or more characters
          <br /> long with at least one number and one symbol.
        </p>
        <div className="w-full max-w-xs mt-6">
          <form
            className="bg-white px-8 pt-6 pb-8 mb-4"
            onSubmit={handleSubmit}
          >
            <div className="mb-6">
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label
                    className="text-[#9CA3AF] font-inter text-sm font-medium leading-[18px]"
                    htmlFor="firstName"
                  >
                    First Name
                  </label>
                  <input
                    className="inline-flex h-11 px-4 py-3 items-start space-x-2.5 flex-shrink-0 border-none focus:outline-none mt-2 bg-[#F3F4F6] w-full p-[16px 24px] justify-center items-center space-x-2.5"
                    id="firstName"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="w-1/2">
                  <label
                    className="text-[#9CA3AF] font-inter text-sm font-medium leading-[18px]"
                    htmlFor="lastName"
                  >
                    Last Name
                  </label>
                  <input
                    className="inline-flex h-11 px-4 py-3 items-start space-x-2.5 flex-shrink-0 border-none focus:outline-none mt-2 bg-[#F3F4F6] w-full p-[16px 24px] justify-center items-center space-x-2.5"
                    id="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="relative mb-6">
              <label
                className="block text-gray-400 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="inline-flex h-11 px-4 py-3 items-start space-x-2.5 flex-shrink-0 border-none focus:outline-none mt-2 bg-[#F3F4F6] w-full p-[16px 24px] justify-center items-center space-x-2.5"
                id="password"
                type={passwordType}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                minLength="12"
                pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{12,}$"
                title="Password must be 12 or more characters long with at least one number and one symbol."
                required
              />
              <div className="absolute inset-y-0 right-0 mt-10 flex items-center pr-3">
                <div onClick={togglePassword} className="cursor-pointer">
                  {passwordType === "password" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                      fill="#3868b6"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                      fill="#3868b6"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  )}
                </div>
              </div>
            </div>
            <div className="relative mb-6">
              <label
                className="text-[#9CA3AF] font-inter text-sm font-medium leading-[18px]"
                htmlFor="repeatPassword"
              >
                Repeat Password
              </label>
              <input
                className="inline-flex h-11 px-4 py-3 items-start space-x-2.5 flex-shrink-0 border-none focus:outline-none mt-2 bg-[#F3F4F6] w-full p-[16px 24px] justify-center items-center space-x-2.5"
                id="repeatPassword"
                type={passwordType2}
                placeholder="Repeat Password"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                minLength="12"
                pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{12,}$"
                title="Password must be 12 or more characters long with at least one number and one symbol."
                required
              />
              <div className="absolute inset-y-0 right-0 mt-10 flex items-center pr-3">
                <div onClick={togglePassword2} className="cursor-pointer">
                  {passwordType2 === "password" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                      fill="#3868b6"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                      fill="#3868b6"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-6">
              <button
                style={{
                  background:
                    "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                }}
                className="text-white rounded font-inter flex w-full py-2 px-5 justify-center items-center space-x-2.5"
                type="submit"
              >
                Confirm Password
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="w-3/5 max-h-full flex flex-col items-center justify-end">
        <img
          src={Banner}
          className="h-screen max-h-screen w-full max-w-full"
          alt="AI-loginlogo"
        />
      </div>
    </div>
  );
};

export default SignUpByEmail;
