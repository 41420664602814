import React, { useEffect, useRef, useState } from "react";
import LeadsTable from "./LeadsTable";
import useApi from "../../../../custom-hooks/useApi";
import { BASE_URL } from "../../../../appconfig";
import NewLeadPopUp from "../popups/NewLeadPopup";
import Spinner from "../../../common/Spinner";
import LeadImport from "../popups/LeadImport";
import "./MyLeads.css";
import ImportHistoryPopUp from "../popups/ImportHistoryPopUp";
import { IoChevronDownOutline } from "react-icons/io5";
import LeadsLanding from "../../../landingScreens/leadsLanding";
import { useSelector } from "react-redux";
import chevronDownSvg from "../../../../assets/chevron-down.svg";

function MyLeads() {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [isOpenActiveDown, setIsOpenActiveDown] = useState(false);
  const [loading, setLoading = false] = useState(false);
  const [importHistoryPopup, setImportHistoryPopup] = useState(false);
  const [changed, setChanged] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [leadSourceType, setLeadSourceType] = useState([]);
  const [headingData, setHeadingData] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [newLeadPopup, setNewLeadPopup] = useState(false);
  const [importLeadPopup, setImportLeadPopup] = useState(false);
  const [filter, setFilter] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  const MAX_VISIBLE_PAGES = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalcount, setTotalcount] = useState(0);
  const dropdownRef = useRef(null);
  const actionDropdownRef = useRef(null);
  const api = useApi();
  const filteredItem = headingData.find((item) => item.ID === filter);
  const heading = filteredItem ? filteredItem.Name : "All Leads";
  const visitedPages = useSelector((state) => state.common.visitedPages);

  const toggleDropdown = () => {
    setDropDownOpen(!dropDownOpen);
    setIsOpenActiveDown(false);
  };
  const toggleHistory = () => {
    setImportHistoryPopup(!importHistoryPopup);
  };
  const isChanged = () => {
    setChanged(!changed);
  };
  const toggleNewLeadPopup = () => {
    setNewLeadPopup(!newLeadPopup);
  };
  const toggleImportPopup = () => {
    setImportLeadPopup(!importLeadPopup);
  };
  const fetchStatusData = async () => {
    try {
      const response = await api.get(
        `${BASE_URL}/Organization/Constant/ReceiptStatus`
      );
      const allOption = { ID: "", Name: "All" };
      const filteredItems = response?.data?.constant.filter(
        (item) => ![52, 36, 37].includes(item.ID)
      );
      setHeadingData(filteredItems);
      const itemsWithAllOption = [allOption, ...filteredItems];
      setMenuItems(itemsWithAllOption);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const leadSourceTypes = async () => {
    try {
      const response = await api.get(
        `${BASE_URL}/Organization/Constant/leadSource`
      );
      setLeadSourceType(response?.data?.constant);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropDownOpen(false);
    }
    if (actionDropdownRef.current && !actionDropdownRef.current.contains(event.target)) {
      setIsOpenActiveDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const fetchLeadsData = async () => {
    setLoading(true);
    try {
      let queryParams = {};
      if (filter !== "0") {
        queryParams["RecipientStatusID"] = filter;
      }
      if (searchQuery.length >= 3) {
        queryParams["SearchName"] = searchQuery;
      }
      const response = await api.get(
        `${BASE_URL}/Campaign/Lead?pageNumber=${currentPage}&pageSize=${itemsPerPage}`,
        {
          params: queryParams,
        }
      );
      if (response.status === 200) {
        setTotalcount(response?.data?.totalcount);
        setLeadsData(response?.data?.Lead);
        setFilteredData(response?.data?.Lead);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLeadsData([]);
      setFilteredData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (filterValue) => {
    setFilter(filterValue);
    setCurrentPage(1);
    toggleDropdown();
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearchDebounced = debounce((term) => {
    setSearchQuery(term);
  }, 1000);

  const handleSearchChange = async (e) => {
    let term = e.target.value.toLowerCase();
    if ((searchQuery.length >= 3 && term.length === 0) || term.length >= 3) {
      handleSearchDebounced(term);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    fetchLeadsData();
  }, [filter, searchQuery, currentPage, changed]);

  useEffect(() => {
    fetchStatusData();
    leadSourceTypes();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const calculateVisiblePages = () => {
      const pages = [];
      const startPage = Math.max(
        1,
        currentPage - Math.floor(MAX_VISIBLE_PAGES / 2)
      );
      const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      const remainingPages = MAX_VISIBLE_PAGES - pages.length;

      if (startPage > 1 && endPage < totalPages) {
        const padStart = Math.min(startPage - 1, remainingPages);
        const padEnd = Math.min(
          totalPages - endPage,
          remainingPages - padStart
        );

        for (let i = startPage - padStart; i >= 1; i--) {
          pages.unshift(i);
        }

        for (let i = endPage + 1; i <= endPage + padEnd; i++) {
          pages.push(i);
        }
      }

      return pages;
    };

    const visiblePageNumbers = calculateVisiblePages();

    if (visiblePageNumbers.length > 1) {
      return (
        <>
          <div className="flex items-center mx-5">
            {currentPage > 1 && currentPage <= totalPages && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 cursor-pointer"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            )}
            {visiblePageNumbers.map((pageNumber, index) => (
              <button
                key={`page-${pageNumber}-${index}`}
                style={{
                  borderRadius: "3rem",
                  backgroundColor:
                    currentPage === pageNumber ? "#3182CE" : "white",
                  color: currentPage === pageNumber ? "white" : "black",
                }}
                type="button"
                onClick={() => handlePageChange(pageNumber)}
                className="font-inter mt-2 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 me-2 mb-2 dark:hover:bg-gray-800 focus:outline-none dark:focus:ring-blue-800"
              >
                {pageNumber}
              </button>
            ))}
            {totalPages > 1 && currentPage < totalPages && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            )}
          </div>
        </>
      );
    }
  };
  const totalPages = Math.ceil(totalcount / itemsPerPage);

  const toggleActiveDropdown = () => {
    setIsOpenActiveDown(!isOpenActiveDown);
  };
  return (
    <>
      {!visitedPages.includes(window.location.pathname) ? (
        <LeadsLanding />
      ) : (
        <div className="h-screen">
          {loading && <Spinner />}
          <div className="flex w-full  justify-between px-5 my-8 relative">
            <div>
            <div
              onClick={toggleDropdown}
              className="flex justify-center  items-center cursor-pointer ml-5 pt-2"
              ref={dropdownRef}
            >
              <h1 className=" text-[26px] font-semibold">
                {heading}
              </h1>
              <div ><IoChevronDownOutline className=" mt-1 font-bold text-xl ml-1"/></div>
              {/* <DownArrow />  */}
            </div>
            {dropDownOpen && (
              <div
                className="bg-white mt-10 absolute left-0 w-full z-10"
                style={{
                  boxShadow: "0px 8px 40px 0px #00000030",
                  maxWidth: "15rem",
                  maxHeight: "60vh",
                  top: "calc(40%)",
                  left: "calc(1%)",
                }}
                onMouseLeave={() => setDropDownOpen(false)}
              >
                {menuItems?.map((item, index) => (
                  <div
                    key={index}
                    className="font-inter flex items-center align-middle bg-dropdown-item cursor-pointer z-10"
                  >
                    <span
                      className="w-full text-black text-base font-normal px-5 py-3 block bg-white hover:bg-[#2BB574]"
                      style={{ zIndex: 1000 }}
                      onClick={() => handleFilterChange(item.ID)}
                    >
                      {item.Name}
                    </span>
                  </div>
                ))}
              </div>
            )}
            </div>
            <div className="block text-right flex flex-row items-center gap-4">
              <div className="flex flex-row items-center gap-4">
                <div className="relative flex flex-row items-center border border-[#D1D5DB] border-solid rounded gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="gray"
                    className="w-6 h-6 absolute left-0 top-1/2 transform -translate-y-1/2 ml-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder="Search..."
                    style={{
                      backgroundColor: "#E5E7EB",
                      width: "16rem",
                      height: "2.5rem",
                    }}
                    className="focus:outline-none pl-10 border-none"
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="relative" ref={actionDropdownRef}>
                  <button
                    className="gap-8 flex flex-row items-center bg-transparent text-[#4871B7] font-semibold py-2 px-4 border border-blue-500 rounded font-inter"
                    onClick={toggleActiveDropdown}
                    
                  >
                    Actions
                    <img
                      src={chevronDownSvg}
                      alt=""
                      className={`hover:text-white transform transition-transform duration-200`}
                    />
                  </button>

                  {isOpenActiveDown && (
                    <div
                      className="fixed w-[290px]  bg-white shadow-lg rounded z-50 py-2 mt-3"
                      onMouseLeave={() => setIsOpenActiveDown(false)}
                    >
                      <div className="flex flex-col items-start">
                        <h3 className="font-inter font-medium text-base text-gray-400 px-4 py-3">
                          List Views
                        </h3>
                        <ul className="flex flex-col items-start w-full text-left">
                          <li className="w-full font-inter font-medium text-base leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-9 py-3 ">
                            Close - Do Not Convert
                          </li>
                          <li className="w-full font-inter font-medium text-base leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-9 py-3 ">
                            Export
                          </li>
                          <li
                            onClick={() => {
                              toggleImportPopup();
                              toggleActiveDropdown();
                            }}
                            className="w-full font-inter font-medium text-base leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-9 py-3 "
                          >
                            Import
                          </li>
                          <li
                            onClick={() => {
                              toggleHistory();
                              toggleActiveDropdown();
                            }}
                            className="w-full font-inter font-medium text-base leading-tight hover:bg-[#2BB574] hover:text-white text-gray-600 cursor-pointer pl-9 py-3"
                          >
                            View Import History
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  onClick={toggleNewLeadPopup}
                  style={{
                    background:
                      "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                  }}
                  className="bg-transparent text-white text-sm font-semibold hover:text-white py-3 px-14 mr-4 border hover:border-transparent rounded font-inter"
                >
                  New
                </button>
                {newLeadPopup && (
                  <div>
                    <NewLeadPopUp
                      changed={isChanged}
                      toggleLeadPopup={toggleNewLeadPopup}
                    />{" "}
                  </div>
                )}
                {importLeadPopup && (
                  <LeadImport toggleImportPopup={toggleImportPopup} />
                )}
                {importHistoryPopup && (
                  <ImportHistoryPopUp toggle={toggleHistory} />
                )}
              </div>
            </div>
          </div>
          <div className="px-10">
            {filteredData?.length > 0 ? (
              <div>
                <LeadsTable
                  leadSource={leadSourceType}
                  data={filteredData}
                  ConstStatus={menuItems}
                  change={isChanged}
                />
                {totalPages > 0 && (
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            ) : (
              <div className="flex text-base font-semibold text-gray-500 p-5 w-full justify-center items-center">
                No Data Available ...!
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default MyLeads;