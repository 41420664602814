import React, { useState, useEffect, useCallback } from "react";
import dropDown from "../../../../assets/grayDrowDown.svg";
import { FiSearch } from "react-icons/fi";
import useApi from "../../../../custom-hooks/useApi";
import { BASE_URL } from "../../../../appconfig";

const SearchDropdown = ({ onSelect, disable = false, splitCase = "No" }) => {
  const api = useApi();
  const [searchTerm, setSearchTerm] = useState("");
  const [agents, setAgents] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");

  useEffect(()=>{
    if(splitCase === "No"){
      console.log('yes')
      setSelectedAgent('')
      setIsDropdownOpen(false)
    }
  },[splitCase])

  const fetchAgents = useCallback(() => {
    if (searchTerm) {
      const fetchData = async () => {
        try {
          const response = await api.get(
            `${BASE_URL}/Organization/Agent_Details?pageNumber=1&pageSize=10&sortfield=AgentName&sortOrder=asc&SearchTerm=${searchTerm}`
          );
          setAgents(response?.data?.organizations || []);
        } catch (error) {
          console.error("Error fetching agents:", error);
        }
      };

      fetchData();
    }
  }, [api, searchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchAgents();
    }, 200); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [fetchAgents]);

  const handleAgentClick = (agent) => {
    setSelectedAgent(`${agent?.AgentName} (${agent?.AgentID})`);
    onSelect(agent);
    setIsDropdownOpen(false);
  };

  return (
    <div
      className={`relative inline-block text-left w-full ${
        disable ? "cursor-not-allowed" : "cursor-pointer"
      }`}
    >
      <div>
        <div
          className="flex justify-between items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full"
          onClick={() => !disable && setIsDropdownOpen(!isDropdownOpen)}
        >
          <div className="text-gray-400 font-medium text-sm leading-5">
            {selectedAgent || "Select Writing Agent"}
          </div>
          <img src={dropDown} alt="dropdown icon" />
        </div>

        {isDropdownOpen && (
          <div
            className="absolute bg-white max-h-[500px] overflow-scroll rounded-md shadow-md w-full z-10 pb-2"
            onMouseLeave={() => setIsDropdownOpen(false)}
          >
            <div className="py-1">
              <div className="relative px-1">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <FiSearch className="text-gray-500" />
                </span>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-input py-2 pl-8 pr-4 w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  placeholder="Search agent..."
                />
              </div>
              {agents?.length === 0 ? (
                <p className="block px-4 py-2 text-sm text-gray-500">
                  No agents found
                </p>
              ) : (
                agents?.map((agent) => (
                  <p
                    key={agent?.ID}
                    className="hover:bg-[#4871B7] hover:text-white cursor-pointer block px-4 py-2 text-sm text-gray-500 ease-in-out"
                    onClick={() => handleAgentClick(agent)}
                  >
                    {`${agent?.AgentName} (${agent?.AgentID})`}
                  </p>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchDropdown;

export const EditSearchDropdown = ({
  label,
  value,
  onChange,
  onChangeId,
  list,
  isEdit,
  errors,
  disable = false,
}) => {
  const api = useApi();
  const [searchTerm, setSearchTerm] = useState("");
  const [agents, setAgents] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedItem, setSelectedItem] = useState(value);

  const fetchAgents = useCallback(() => {
    if (searchTerm) {
      const fetchData = async () => {
        try {
          const response = await api.get(
            `${BASE_URL}/Organization/Agent_Details?pageNumber=1&pageSize=10&sortfield=AgentName&sortOrder=asc&SearchTerm=${searchTerm}`
          );
          setAgents(response.data.organizations || []);
        } catch (error) {
          console.error("Error fetching agents:", error);
        }
      };

      fetchData();
    }
  }, [api, searchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchAgents();
    }, 200); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [fetchAgents]);

  const handleAgentClick = (agent) => {
    setSelectedAgent(`${agent?.AgentName} (${agent?.AgentID})`);
    setSelectedItem(`${agent?.AgentName} (${agent?.AgentID})`)
    onChange(`${agent?.AgentName} (${agent?.AgentID})`);
    // onChangeId(agent?.AgentID)
    setIsDropdownOpen(false);
  };

  return (
    <div className={`flex flex-row items-center  w-full`}>
      <div className="font-medium text-sm leading-[18px] text-gray-400 w-1/2 whitespace-nowrap">
        {label}
      </div>

      {!disable && isEdit ? (
        <div className="flex flex-col w-[217px]">
          <div
            className={`w-[217px] flex justify-between items-center h-[38px] border border-gray-300 bg-[#E5E7EB] pl-6 pr-1 gap-[10px] ${
              disable ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            onClick={() => !disable && setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className="truncate px-2 text-sm text-gray-600 font-medium leading-5">
              {selectedItem ? selectedItem : value}
            </div>
            <img src={dropDown} alt="" />
          </div>
          {isDropdownOpen && (
            <div>
              <div
                className="absolute bg-white w-[217px] overflow-scroll rounded-md shadow-md z-10 pb-2"
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <div className="py-1">
                  <div className="relative px-1">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <FiSearch className="text-gray-500" />
                    </span>
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-input py-2 pl-8 pr-4 w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder="Search agent..."
                    />
                  </div>
                  {agents.length === 0 ? (
                    <p className="block px-4 py-2 text-sm text-gray-500">
                      No agents found
                    </p>
                  ) : (
                    agents.map((agent) => (
                      <p
                        key={agent?.ID}
                        className="hover:bg-[#4871B7] hover:text-white cursor-pointer block px-4 py-2 text-sm text-gray-500 ease-in-out"
                        onClick={() => handleAgentClick(agent)}
                      >
                        {`${agent?.AgentName} (${agent?.AgentID})`}
                      </p>
                    ))
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="truncate px-2 text-sm font-inter text-[#4B5563] font-semibold w-1/2 text-left">
            {value ? value : "-"}
          </div>
        </>
      )}
    </div>
  );
};
