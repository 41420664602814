import React from "react";
import { useNavigate } from "react-router-dom";

 const Modal = ({ isOpen, onClose }) => {
    const navigate = useNavigate()
  
    if (!isOpen) return null;
  
    const handleConvertClick = () => {
      navigate("/applications/new-application");
    };
  
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="flex flex-col bg-white rounded-lg shadow-lg w-96 relative p-6 w-[629px]">
          <div className="flex flex-row items-center justify-between w-full ">
            <div className="text-gray-800 font-semibold text-2xl mt-3">Agent Assisted Application</div>
          <button
            className="text-[23px] text-gray-600 hover:text-gray-900"
            onClick={onClose}
          >
            &times;
          </button>
          </div>
        
  
          <div className="font-semibold text-gray-700 pt-9">Pledge for Truthful & Accurate Answers</div>
  
          <div className="w-full h-[1px] bg-gray-300 my-6"></div>
          <div className="flex flex-col gap-5">
            <div className="font-semibold text-gray-700">As a licensed insurance agent,</div>
            <div className="font-normal text-[13px] text-gray-500">
            I pledge to help my clients answer the insurance application and questions with as much completeness, accuracy, and truthfulness as possible.<br/><br/>
            To the best of my abilities, I will ensure my clients do not willfully omit or misrepresent information regarding their health or behaviors.<br/><br/>
            I understand that insurance carriers will review all issued policies for accuracy and compare the application answers to doctors and hospital records, health insurance records, and pharmaceutical records.<br/><br/>
            If there is a discrepancy, my client’s policy may be invalidated or their family’s claim may be denied by the carrier.  In such a case, my agent commission will be fully charged back and I may be investigated by the carrier.<br/><br/>
            </div>
          </div>
  
          <button
                  style={{
                    background:
                      "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                  }}
                  onClick={handleConvertClick}
                  className="w-full h-[51px] bg-transparent text-white text-sm font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded font-inter"
                >
                  I agree
                </button>
          
        </div>
      </div>
    );
  };
  
  export default Modal