import React, { useState, useRef, useEffect } from "react";
import DownArrow from "../../assets/DownArrow";
// import { toast } from "react-toastify";
// import axios from "axios";
// import Spinner from "../common/Spinner";


const CustomDropdown = ({setFilterData, onClick}) => {

const [isDropdownOpen, setDropdownOpen] = useState(false);
const dropdownRef = useRef(null);
const [position, setPosition] = useState({ top: 0, left: 0 });
const [selectedFilter, setSelectedFiler] = useState("All Business Partners"); 

const toggleDropdown = () => {
  onClick()
 const rect = dropdownRef.current.getBoundingClientRect();
 setPosition({
  top: rect.bottom + window.scrollY,
  left: rect.left + window.scrollX,
 });
 setDropdownOpen(!isDropdownOpen);
};
const closeDropdown = (e) => {
 if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
  setDropdownOpen(false);
 }
};
const performFilter = async(e) => {
  
 let label = e.target.innerText;
 let fkey = e.target.getAttribute("data-filter-key") || "";
 let fvalue = e.target.getAttribute("data-filter-value") || "";
 let filter = {
  key: fkey,
  value: fvalue
 };
 setFilterData(filter);
 setSelectedFiler(label);
 setDropdownOpen(false);
}

 useEffect(() => {
  window.addEventListener("click", closeDropdown);
  return () => window.removeEventListener("click", closeDropdown);
}, []);


 const menuItems = [
   { label: "All Business Partners", value: "", filterKey: "" },
   { label: "Active Downline", value: "Active", filterKey: "TeamMemberType" },
   {
     label: "Inactive Downline",
     value: "InActive",
     filterKey: "TeamMemberType",
   },
   { label: "Direct Downline", value: "Direct", filterKey: "TeamMemberType" },
   { label: "Licensed Agents", value: "true", filterKey: "IsLicensed" },
   { label: "Unlicensed Agents", value: "false", filterKey: "IsLicensed" },
   { label: "Stage: Pre-License", value: "1", filterKey: "StepID" },
   {
     label: "Stage: Go Through the Client Experience",
     value: "2",
     filterKey: "StepID",
   },
   { label: "Stage: Start Promotion Process", value: "3", filterKey: "StepID" },
   { label: "Stage: Get Licensed", value: "4", filterKey: "StepID" },
   { label: "Stage: Field Training", value: "5", filterKey: "StepID" },
   {
     label: "Stage: Promotion and Net License",
     value: "6",
     filterKey: "StepID",
   },
   {
     label: "Stage: Become a Certified Field Trainer",
     value: "7",
     filterKey: "StepID",
   },
   {
     label: "Stage: Become a Marketing Director",
     value: "8",
     filterKey: "StepID",
   },
   { label: "Stage: Become an Executive", value: "9", filterKey: "StepID" },
   //  { label: "Training Associates", value: "1", filterKey: "TitleLevel" },
   //  { label: "Associates", value: "10", filterKey: "TitleLevel" },
   //  { label: "Marketing Directors", value: "17", filterKey: "TitleLevel" },
   //  { label: "Sr. Marketing Directors", value: "20", filterKey: "TitleLevel" },
 ];


 return (
   <>
   {/* {loading && <Spinner/>} */}
     <div className="relative group flex items-center z-20" ref={dropdownRef}>
       <button
         style={{
           height: "1.5rem",
           fontWeight: "500",
           fontSize: "1.3rem",
           display: "flex",
           alignItems: "center",
         }}
         className="focus:outline-none font-inter"
         onClick={toggleDropdown}
       >
         <span className="pr-1">{selectedFilter}</span>
         <DownArrow />
       </button>
       {isDropdownOpen && (
         <div
           className="fixed bg-white z-10 mt-5"
           style={{
             boxShadow: "0px 8px 40px 0px #00000030",
             maxWidth: "15rem",
             maxHeight: "60vh",
             overflowY: "auto",
             top: position.top,
             left: position.left,
           }}
           onMouseLeave={() => setDropdownOpen(false)}
         >
           
           {menuItems.map((item, index) => (
             <div
               key={index}
               className="font-inter flex items-center align-middle bg-dropdown-item cursor-pointer"
               onMouseOver={(e) => (e.target.style.color = "white")}
               onMouseOut={(e) => (e.target.style.color = "black")}
               onClick={performFilter}
             >
               <span
                 style={{
                   width: "100%",
                   color: "black",
                   fontSize: "1rem",
                   fontWeight: "400",
                   padding: "0.72rem 1rem",
                   display: "block",
                 }}
                 data-filter-key={item.filterKey}
                 data-filter-value={item.value}
               >
              {item.label}
               </span>
             </div>
           ))}
         </div>
       )}
     </div>
   </>
 );
};
export default CustomDropdown;