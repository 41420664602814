import React, { useEffect, useState } from "react";
import CalIcon from "../../../../../src/assets/calender.svg";
import downArrow from "../../../../../src/assets/down-arrow-black.svg";
import AddIcon from "../../../../../src/assets/add-square.svg";
import PendingSvg from "../../../../../src/assets/pending.svg";
import DeliveredSvg from "../../../../../src/assets/delivered.svg";
import RepliedSvg from "../../../../../src/assets/replied.svg";
import ErrorsSvg from "../../../../../src/assets/errors.svg";
import { ReactComponent as EditIcon } from "../../../../../src/assets/edit.svg";
import { ReactComponent as SaveIcon } from "../../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../../src/assets/cancel.svg";
import NewMessagePopup from "../popups/NewMessagePopup";
import RecipientTable from "./RecipientTable";
import { toast } from "react-toastify";
import 'react-datepicker/dist/react-datepicker.css';
import { BASE_URL } from "../../../../appconfig";
import useApi from "../../../../custom-hooks/useApi";
import "./CampaignDetails.css";
import { ReactComponent as BackIcon } from "../../../../../src/assets/chevron-left.svg";
import Spinner from "../../../common/Spinner";
import CampaignStatus from "./CampaignStatus";
import CampaignImportHistory from "../popups/CampaignImportHistory";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { FiPauseCircle } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";
import { RiAddBoxLine } from "react-icons/ri";

function CampaignDetails({ campaign,campaignClick }) {
  const [isExpanded, setExpanded] = useState(false);
  const [msgPopUp, setMsgPopUp] = useState(false);
  const [msgData, setMsgData] = useState([]);
  const [importHistoryPopup, setImportHistoryPopup] = useState(false);
  const [loading, setLoading = false] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [msgSummary, setMsgSummary] = useState([]);
  const [newMsg, setNewMsg] = useState(false);
  const [recipientData, setRecipientData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [filter, setFilter] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  
  const api = useApi();
  const MAX_VISIBLE_PAGES = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalcount, setTotalcount] = useState(0);
  const [campaignStatusList, setCampaignStatusList] = useState([]);
  const [actionableStatus, setActionableStatus] = useState([]);
  const [currentCampaign, setCurrentCampaign] = useState(campaign);

  useEffect(() => {
    setCurrentCampaign(campaign);
  }, [campaign])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const toggleHistory = () => {
    setImportHistoryPopup(!importHistoryPopup);
  }
  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const calculateVisiblePages = () => {
      const pages = [];
      const startPage = Math.max(1, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
      const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);
      
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      const remainingPages = MAX_VISIBLE_PAGES - pages.length;
    
      if (startPage > 1 && endPage < totalPages) {
        const padStart = Math.min(startPage - 1, remainingPages);
        const padEnd = Math.min(totalPages - endPage, remainingPages - padStart);
    
        for (let i = startPage - padStart; i >= 1; i--) {
          pages.unshift(i);
        }
    
        for (let i = endPage + 1; i <= endPage + padEnd; i++) {
          pages.push(i);
        }
      }
    
      return pages;
    };
    

  const visiblePageNumbers = calculateVisiblePages();

  if (visiblePageNumbers.length > 1) {
    return (
      <>
        <div className="flex items-center mx-5">
          {currentPage > 1 && currentPage <= totalPages && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 cursor-pointer"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          )}
          {visiblePageNumbers.map((pageNumber, index) => (
            <button
              key={`page-${pageNumber}-${index}`}
              style={{
                borderRadius: "3rem",
                backgroundColor:
                  currentPage === pageNumber ? "#3182CE" : "white",
                color: currentPage === pageNumber ? "white" : "black",
              }}
              type="button"
              onClick={() => handlePageChange(pageNumber)}
              className="font-inter mt-2 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 me-2 mb-2 dark:hover:bg-gray-800 focus:outline-none dark:focus:ring-blue-800"
            >
              {pageNumber}
            </button>
          ))}
          {totalPages > 1 && currentPage < totalPages && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 cursor-pointer"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          )}
        </div>
      </>
    );
  }
};

  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };
  const toggleMsgPopup = () => {
    setMsgPopUp(!msgPopUp);
  };
  const isMsgSent = () => {
    setNewMsg(!newMsg);
  };
  const isUpdated = () => {
    setDataUpdated(!dataUpdated);
  }
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearchDebounced = debounce((term) => {
    setSearchQuery(term);
  }, 1000);

  const handleSearchChange = async (e) => {
   let term = e.target.value.toLowerCase();;
    if ((searchQuery.length >= 3 && term.length === 0) || term.length >= 3) {
      handleSearchDebounced(term);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    const fetchMsgData = async () => {
      try {
        const response = await api.get(
          `${BASE_URL}/Campaign/Schedule/${campaign.ID}`
        );
        const fetchedMsgData = response?.data?.message;
        const sortedFetchedMsgData = fetchedMsgData.sort((a, b) => {
          if (a.updatedAt < b.updatedAt) return 1;
          if (a.updatedAt > b.updatedAt) return -1;
          return 0;
        });
        setMsgData(sortedFetchedMsgData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setMsgData([]);
      }
    };

    fetchMsgData();
  }, [campaign, newMsg]);

  const fetchRecipientsData = async () => {
    setLoading(true);
    try {
      let queryParams = {};
      if (filter !== "0") {
        queryParams["RCSmsStatus"] = filter;
      }
      if (searchQuery.length >= 3) {
        queryParams["SearchName"] = searchQuery;
      }
      const response = await api.get(
        `${BASE_URL}/Campaign/Recipient/${campaign.ID}?pageNumber=${currentPage}&pageSize=${itemsPerPage}`, {
          params: queryParams,
        }
      );
      if (response.status === 200) {
        setTotalcount(response?.data?.totalcount);
        setRecipientData(response?.data?.Receipt);
        setFilteredData(response?.data?.Receipt);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setRecipientData([]);
      setFilteredData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecipientsData();
    fetchMsgSummary();
  }, [campaign, dataUpdated, currentPage, searchQuery, filter]);

  useEffect(()=>{
    const fetchCampaignStatusList = async ()=>{
      try{
        const response = await api.get("/Organization/Constant/CampaignStatus");
        if (
          response.status === 200 &&
          response.data &&
          response.data.constant 
        ) {
          const statusList = response.data.constant;
          setCampaignStatusList(statusList);
          const filteredList = statusList
            .filter((status) =>
              ["active", "paused", "closed"].includes(
                status.Name.toString().toLowerCase()
              )
            )
            .map((status) => status.ID);
            setActionableStatus(filteredList);
        }
      } catch(error){
        console.log(error.message);
      }
    }
   
    fetchCampaignStatusList();
  },[campaign]);

  const fetchStatusData = async () => {
    try {
      const response = await api.get(
        `${BASE_URL}/Organization/Constant/RCSmsStatus`
      );
      setStatusData(response?.data?.constant);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchMsgSummary = async () => {
    try {
      const response = await api.get(
        `${BASE_URL}/Campaign/Msg_Summary/${campaign.ID}`
      );
      setMsgSummary(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setMsgSummary([]);
    }
  };

  const formatDate = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
    const formattedTime = `${formattedHours
      .toString()
      .padStart(2, "0")}:${minutes} ${amOrPm}`;
    const formattedDate = `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} ${formattedTime}`;
    return formattedDate;
  };

  const MessageCard = ({ data }) => {
    const {
      CreatedBy,
      updatedAt,
      Campaign_Message,
      GroupID,
      Schedule_Date,
      ID,
      GroupName,
    } = data;
      const words = Campaign_Message.split(" ");

    const [isEditing, setIsEditing] = useState(false);
    const [editedMessage, setEditedMessage] = useState(Campaign_Message);
    const receiptStatusName =
      statusData.find((item) => item.ID === GroupID)?.Name || "";
  
    const handleMessageChange = (e) => {
      setEditedMessage(e.target.value);
    };
  
    const handleCancelClick = () => {
      setIsEditing(false);
    };
  
    const handleEditClick = () => {
       if (
         data.MessageStatusName &&
         !["in progress", "completed"].includes(
           data.MessageStatusName.toLowerCase()
         )
       ) {
         setIsEditing(true); // Allow editing
       } else {
         // Display a toast or any other notification to indicate that editing is not allowed
         setIsEditing(false); // Allow editing
       }
      
    };
  
    const handleSaveClick = async (e) => {
      e.preventDefault();

      const json = {
        Campaign_Message:editedMessage
      };
      try {
        const response = await api.put(
          `${BASE_URL}/Campaign/Schedule/${ID}`,
          json
        );
        if (response.data.success) {
          toast.success("Data updated successfully", {
            theme: "dark",
          });
          isMsgSent();
        } else {
          const errorMessage =
            response.data?.error || "Failed to update the data";
          toast.error(errorMessage, {
            theme: "dark",
          });
        }
      } catch (error) {
        console.error("Error updating data:", error);
      } finally {
        setIsEditing(false);
      }
    };
  
    return (
      <div>
        <div className="flex mt-7 items-center justify-between">
          <div className="w-[60%] flex items-center">
            { GroupName ?  <span className="text-gray-500 text-base">Message sent to {GroupName}</span> :
            <span className="text-gray-500 text-base">Sent to Full Recipient List
            </span> }
           
          </div>
          <div className="w-[40%] flex items-center justify-start gap-4">
            <span
              className={`w-[25%] ml-3 pb-1.5 rounded-full font-semibold text-xs px-2 py-2 text-center ${
                data.MessageStatusName.toString().toLowerCase() === "completed"
                  ? `bg-[#dcf0e6] text-[#159959]`
                  : data.MessageStatusName.toString().toLowerCase() ===
                    "in progress"
                  ? `bg-[#4871B7] text-[#4871B7] bg-opacity-[15%]`
                  : `bg-[#6B7280] text-[#6B7280] bg-opacity-[15%]`
              }`}
            >
              {data.MessageStatusName}
            </span>
            <span className="`w-[70%] text-gray-400 font-normal text-base">
              {Schedule_Date
                ? formatDate(Schedule_Date)
                : formatDate(updatedAt)}
            </span>
          </div>
        </div>
        {isEditing && (
          <div className="flex items-center mt-2">
            <textarea
              rows={4}
              required
              placeholder="Message"
              value={editedMessage}
              onChange={handleMessageChange}
              className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
            ></textarea>
            <div className="flex ml-2 items-center gap-2">
              <button
                className="disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handleSaveClick}
              >
                <SaveIcon />
              </button>
              <CancelIcon
                className="cursor-pointer"
                onClick={handleCancelClick}
              />
            </div>
          </div>
        )}
        {!isEditing && (
          <div className="flex items-center mt-2">
            <p className="text-gray-400 text-sm inline">
              {/* Map through each word */}
              {words.map((word, index) => (
                <React.Fragment key={index}>
                  {/* Display word */}
                  {word}
                  {/* Add space between words */}
                  {index !== words.length - 1 && " "}
                </React.Fragment>
              ))}

              {words.length > 0 && (
                <span
                  style={{ display: "inline-flex", alignItems: "center" }}
                  onClick={handleEditClick}
                >
                  {/* Display EditIcon */}
                  &nbsp;
                  <EditIcon
                    className={`text-sm ${
                      ["in progress", "completed"].includes(
                        data.MessageStatusName.toLowerCase()
                      )
                        ? "opacity-40 cursor-not-allowed"
                        : ""
                    }`}
                  />
                </span>
              )}
            </p>
            {/* <div className="ml-2 flex items-center gap-2 cursor-pointer">
            </div> */}
          </div>
        )}
      </div>
    );}

  const handleFilterChange = (e) => {
    let filter = e.target.value;
    setFilter(filter);
    setCurrentPage(1);
  };

  useEffect(()=>{
    fetchStatusData();
  },[])

  const totalPages = Math.ceil(totalcount / itemsPerPage);

  const refreshCurrentCampaign = (updatedCampaign) => {
    setCurrentCampaign(updatedCampaign);
  }
  const [currentCampaignStatus, setCurrentCampaignStatus] = useState("");
  useEffect(() => {
    if(campaignStatusList && campaignStatusList.length > 0) {
      let status = campaignStatusList.find(s => s.ID === currentCampaign.Campaign_StatusID)
      if(status) {
        setCurrentCampaignStatus(status.Name);
      }
    }
  }, [campaignStatusList, currentCampaign]);

  const Sent = (msgSummary["47"] || 0) + (msgSummary["48"] || 0) + (msgSummary["51"] || 0) + (msgSummary["50"] || 0);
  const Delivered = (msgSummary["48"] || 0) + (msgSummary["51"] || 0);

  return (
    <>
      <div className="flex items-center font-inter mt-2 text-green-500 px-2 py-2">
        <div className="inline-flex flex-row cursor-pointer items-center">
          <span
            className="inline-flex items-center ml-2"
            onClick={() => campaignClick(null)}
          >
            <BackIcon className="w-4 h-4 text-green-500 dark:text-green" />
            Back
          </span>
        </div>
      </div>
      <div className="px-7 mt-5">
        {loading && <Spinner />}{" "}
        <div className="flex">
          <img src={CalIcon} alt="cal-icon" />
          <span className="text-xs text-gray-500 ml-1">24 Jan 2025</span>
        </div>
        <div className="flex text-[28px] justify-between items-center mt-4 min-w-full">
          <h1 className="text-[28px] font-semibold">
            {campaign.Campaign_Name}
            {campaignStatusList && currentCampaignStatus && (
              <>
                {/* <span
                  className={
                    currentCampaignStatus.toLowerCase() === "active"
                      ? `text-[#198955]`
                      : currentCampaignStatus.toLowerCase() === "paused"
                      ? `text-orange-400`
                      : currentCampaignStatus.toLowerCase() === "closed"
                      ? `text-[#EA2D38]`
                      : `text-[#9CA3AF]`
                  }
                >
                   </span> */}
                  
                  
                    {currentCampaignStatus === "Active" || currentCampaignStatus === "Closed" || currentCampaignStatus ==="Paused" ? null : '- ' + currentCampaignStatus} 
               
              </>
            )}
          </h1>
          {/* {actionableStatus &&
            actionableStatus.length > 0 &&
            currentCampaign &&
            actionableStatus.includes(currentCampaign.Campaign_StatusID) && (
              <div>
                <CampaignStatus
                  campaign={currentCampaign}
                  statusList={campaignStatusList}
                  refreshCurrentCampaign={refreshCurrentCampaign}
                />
              </div>
            )} */}
        </div>
        <div
          onClick={toggleHistory}
          className="px-2 text-green-400 font-semibold hover:text-blue-500 text-base cursor-pointer text-end"
        >
          View Import History
        </div>
        {importHistoryPopup && (
          <CampaignImportHistory
            campaign={campaign.ID}
            toggle={toggleHistory}
          />
        )}
        <div className="bg-white border border-gray-300 mt-3">
          <div className="grid grid-cols-8 gap-5 py-5 font-inter text-center text-base">
            <div className="flex flex-col flex-1">
              <span className="mb-1 font-semibold">Sent</span>
              <span className="text-gray-500">{Sent}</span>
            </div>
            <div className="flex flex-col flex-1">
              <span className="mb-1 font-semibold">Delivered</span>
              <span className="text-gray-500">{Delivered}</span>
            </div>
            <div className="flex flex-col flex-1">
              <span className="mb-1 font-semibold">Replies</span>
              <span className="text-gray-500">{msgSummary["50"] || 0}</span>
            </div>
            <div className="flex flex-col flex-1">
              <span className="mb-1 font-semibold">Conversations</span>
              <span className="text-gray-500">{msgSummary["33"] || 0}</span>
            </div>
            <div className="flex flex-col flex-1">
              <span className="mb-1 font-semibold">Appt set</span>
              <span className="text-gray-500">{msgSummary["34"] || 0}</span>
            </div>
            <div className="flex flex-col flex-1">
              <span className="mb-1 font-semibold">Appt done</span>
              <span className="text-gray-500">{msgSummary["35"] || 0}</span>
            </div>
            <div className="flex flex-col flex-1">
              <span className="mb-1 font-semibold">Agent Apps</span>
              <span className="text-gray-500">{msgSummary["36"] || 0}</span>
            </div>
            <div className="flex flex-col flex-1">
              <span className="mb-1 font-semibold">Client Apps</span>
              <span className="text-gray-500">{msgSummary["37"] || 0}</span>
            </div>
          </div>
        </div>
        <h4 className="text-gray-400 font-medium mt-3">
          Statistics on this page auto-update every five minutes. Last updated at
          14:21PM.
        </h4>
        <div className="bg-white border border-gray-300 mt-3 p-5 flex flex-col">
          <div className=" flex justify-between">
            <div
              onClick={toggleExpand}
              className="cursor-pointer flex p-2 max-w-[200px]"
            >
              <span className="font-semibold">Campaign Progress</span>
              <img
                className={`ml-2 transition-transform transform ${
                  isExpanded ? "rotate-0" : "rotate-180"
                }`}
                src={downArrow}
                alt="down-up-arrow"
              />
            </div>


            {actionableStatus &&
            actionableStatus.length > 0 &&
            currentCampaign &&
            actionableStatus.includes(currentCampaign.Campaign_StatusID) && (
              <div>
                <CampaignStatus
                  campaign={currentCampaign}
                  statusList={campaignStatusList}
                  refreshCurrentCampaign={refreshCurrentCampaign}
                />
              </div>
            )}
          
          </div>
          {isExpanded && (
            <div>        
              <div className="mt-1 border-t border-[#E5E7EB] h-1"></div>
            
              <div className="px-2">
                <span className="text-base font-semibold ">
                  {msgData.map((message, index) => (
                    <MessageCard key={index} data={message} />
                  ))}
                </span>
              </div>
              {/* {msgPopUp && (
                <NewMessagePopup
                  toggleMsgPopup={toggleMsgPopup}
                  campaignId={campaign.ID}
                  handleMsgSent={isMsgSent}
                />
              )} */}
            </div>
          )}

{msgPopUp && (
                <NewMessagePopup
                  toggleMsgPopup={toggleMsgPopup}
                  campaignId={campaign.ID}
                  handleMsgSent={isMsgSent}
                />
              )}
        </div>

        <div className="flex items-center pl-[1.27rem] py-2">
                <div
                  className="flex max-w-[200px] cursor-pointer text-green-600"
                  onClick={toggleMsgPopup}
                >
                  {/* <img  src={AddIcon} alt="add-icon" /> */}
                   <RiAddBoxLine className=" mt-[0.09rem]" style={{fontSize:"22px"}}/> 
                  <span className="text-base font-semibold text-green-600 ml-1">
                    New message
                  </span>     
                </div>
              </div>
        <div className="bg-white border border-gray-300 mt-3 p-5">
          <div className="flex justify-between">
            <span className="text-[20px] font-semibold"> Recipients</span>
            <div className="flex items-center justify-center">
              <img
                src={PendingSvg}
                alt="pending-svg"
                className="w-[24px] h-[24px] rounded-full bg-[#4971B8] mr-2"
              />
              <span className="text-[#9CA3AF] font-semibold pb-1 mr-4 ">
                Pending
              </span>
              <img
                src={DeliveredSvg}
                alt="pending-svg"
                className="w-[24px] h-[24px] rounded-full bg-[#4971B8] mr-2"
              />
              <span className="text-[#4971B8] font-semibold pb-1 mr-4">
                Delivered
              </span>
              <img
                src={RepliedSvg}
                alt="pending-svg"
                className="w-[24px] h-[24px] rounded-full bg-[#4971B8] mr-2"
              />
              <span className="text-[#198955] font-semibold pb-1 mr-4">
                Replied
              </span>
              <img
                src={ErrorsSvg}
                alt="pending-svg"
                className="w-[24px] h-[24px] rounded-full bg-[#4971B8] mr-2"
              />
              <span className="text-[#EA2D38] font-semibold pb-1 mr-4">
                Errors
              </span>
            </div>
          </div>
          <div className="mt-5 border-t border-[#E5E7EB] h-1"></div>
          <>
            <div className="flex justify-between p-4">
              <div className="mt-2 flex flex-row justify-center items-center gap-2">
                <label
                  htmlFor="campaign-activation-list-filter"
                  className="text-sm items-center whitespace-nowrap"
                >
                  Filter by Status
                </label>
                <select
                  className="appearance-none block w-[250px] bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                  name="Status"
                  defaultValue=""
                  required
                  onChange={(e) => handleFilterChange(e)}
                >
                  <option value="">All</option>
                  {statusData?.map((item) => (
                    <option key={item.ID} value={item.ID}>
                      {item.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-row justify-center items-center gap-2 mt-2">
                <input
                  type="text"
                  id="search"
                  onChange={handleSearchChange}
                  className="appearance-none block w-[250px] bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Search by name"
                />
              </div>
            </div>
          </>
          {filteredData?.length > 0 ? (
            <div style={{ height: "680px", overflowY: "auto" }}>
              <RecipientTable
                ConstStatus={statusData}
                change={isUpdated}
                data={filteredData}
              />
              {totalPages > 0 && (
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              )}
            </div>
          ) : (
            <div className="flex text-base font-semibold text-gray-500 p-5 w-full justify-center items-center">
              No Data Available ...!
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CampaignDetails;
