import React, { useEffect, useState } from "react";
import { ReactComponent as MoreIcon } from "../../../../../assets/more-horizontal.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../../assets/cancel.svg";
import useApi from "../../../../../custom-hooks/useApi";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../../../common/Spinner";
import { toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";
import { BsArrowRightCircleFill } from "react-icons/bs";
import "react-datepicker/dist/react-datepicker.css";

const SignOffDateRow = ({
  agentRootId,
  dataItem,
  index,
  fetchAgentSignOffRequestList,
}) => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const api = useApi();
  const [isEditing, setIsEditing] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const role = useSelector((state) => state.auth.role);
  const adminRoles = useSelector((state) => state.auth.adminRoles);
  const signOffStatusList = useSelector(
    (state) => state.common.signOffStatusList
  );
  const [status, setstatus] = useState('');
  const restrictedCountries = ["US", "CA"];
  const dispatch = useDispatch();
  const [SignOffDate, setSignOffDate] = useState();

  const handleUpdateCampaign = () => {

      updateCampaignActivation();

  };

  const stepIdMap = {
    5: "Phase 3: Become a Certified Field Trainer",
    6: "Phase 4: Become a Marketing Director",
    7: "Phase 5: Become an Executive",
  };

  const updateCampaignActivation = async (id) => {
    try {
      setLoading(true);
      const updatedFormData = {
        ...formData,
      };
    
      const response = await api.put(
        `/Agent/ApproveSignOffRequest/${dataItem.ID}`,
        updatedFormData
      );
      if (response.status === 200) {
        fetchAgentSignOffRequestList();
        setTimeout(() => {
          setIsEditing(false);
          toast.success(response.data.message, { theme: "dark" });
          setFormData(null);
          setLoading(false);
        }, 2000);
      } else {
        toast.error("Create Failed", { theme: "dark" });
        console.error("Create Failed: ", response);
        setLoading(false);
      }
    } catch (error) {
      console.error("Creation of entity failed: ", error);
      let errorMessage =
        error && error?.response && error.response?.data?.message
          ? error.response.data.message
          : "Creation of Entity Failed";
      toast.error(errorMessage, { theme: "dark" });
      setLoading(false);
    }
  };

  const deleteCampaign = async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(
        `/Campaign/OrgCampaign/${dataItem.ID}/${agentRootId}`
      );
      if (response.status === 200) {
        fetchAgentSignOffRequestList();
        toast.success(response.data.message, { theme: "dark" });
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        toast.error("Delete Failed", { theme: "dark" });
        console.error("Delete Failed: ", response);
        setLoading(false);
      }
    } catch (error) {
      console.error("Deletion of entity failed: ", error);
      let errorMessage =
        error && error?.response && error.response?.data?.message
          ? error.response.data.message
          : "Deletion of Entity Failed";
      toast.error(errorMessage, { theme: "dark" });
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };
  const handleMoreClick = () => {
    setIsActionOpen(!isActionOpen);
    setIsEditing(false);
  };
   const handleEditClick = () => {
     setFormData(dataItem);
     setIsEditing(true);
   };
  const handleDeleteClick = () => {
    deleteCampaign();
  };
  const handleConvertClick = () => {
    console.log("Still to write logic.");
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      StatusID: e.target.value // Update StatusID with the selected value
    });
  }

  return (
    <>
      {loading && <Spinner />}
      <tr key={dataItem.AgentID}>
        <td className="w-1/9 whitespace-nowrap  py-5 font-inter ">
          {!isEditing && (
            <span className="py-2.5">
              {signOffStatusList.length > 0 &&
              signOffStatusList.find((s) => s.ID === dataItem.StatusID)
                ? //     ? rcStatusList.find((s) => s.ID === dataItem.Status)["Name"]
                  (() => {
                    const status = signOffStatusList.find(
                      (s) => s.ID === dataItem.StatusID
                    );
                    switch (status.Name) {
                      case "Pending":
                        return (
                          <BsArrowRightCircleFill className=" ml-6 text-gray-500 text-lg" />
                        );
                      case "Approved":
                        return (
                          <FaCheckCircle className=" ml-6 text-lime-600 text-lg" />
                        );
                      default:
                        return "-";
                    }
                  })()
                : "-"}
            </span>
          )}
          {isEditing && (
            <select
              className="appearance-none block w-[11rem] bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
              name="Status"
              form={`campaign-update-form-2${index}`}
              value={formData.StatusID ? formData.StatusID : ""}
              required
              onChange={(e) => handleChange(e)}
            >
              <option
                value=""
                disabled
                className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                style={{ height: "1.5rem", width: "0.5rem" }}
              >
                Select Status
              </option>

              {signOffStatusList.length > 0 &&
                signOffStatusList.map((item) => (
                  <option key={"status" + item.ID} value={item.ID}>
                    {item.Name}
                  </option>
                ))}
            </select>
          )}
        </td>

        <td className="w-1/9 whitespace-nowrap py-2.5 font-inter ">
          <span className="py-2.5">{dataItem.Name.trim() || "-"}</span>
        </td>
        <td className="w-1/9 whitespace-nowrap py-2.5  font-inter">
          <span className="py-2.5">{stepIdMap[dataItem.StepID] || "-"}</span>
        </td>
        <td className="w-1/9 whitespace-nowrap py-2.5  font-inter">
          <span className="py-2.5">{dataItem.AgentID || "-"}</span>
        </td>
        <td className="w-1/9 whitespace-nowrap py-2.5   font-inter">
          <span className="py-2.5">{dataItem.SignOffDate || "-"}</span>
        </td>

        <td className="w-1/9 whitespace-nowrap px-5 py-5 font-inter">
          {isEditing ? (
            <div className="flex flex-row items-center gap-4">
              <SaveIcon
                title="Save"
                className="cursor-pointer"
                onClick={handleUpdateCampaign}
              />
              <CancelIcon
                title="Cancel"
                className="cursor-pointer"
                onClick={handleCancelClick}
              />
            </div>
          ) : (
            <div className="flex flex-row gap-4 cursor-pointer">
              <MoreIcon onClick={handleMoreClick} />

              {isActionOpen && adminRoles.includes(role) && (
                <>
                  <div className="absolute z-10 w-auto bg-white border rounded shadow-lg mt-5 mr-16 right-0">
                    <ul className="border rounded">
                      <li
                        className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                        onClick={() => handleEditClick()}
                      >
                        Edit
                      </li>
                      <li
                        className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                        onClick={() => handleDeleteClick()}
                      >
                        Delete
                      </li>
                      <li
                        className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                        onClick={() => handleConvertClick()}
                      >
                        Convert
                      </li>
                    </ul>
                  </div>
                </>
              )}
              {isActionOpen &&
                !adminRoles.includes(role) &&
                user.AgentID === agentRootId && (
                  <>
                    <div className="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg">
                      <ul className="border rounded">
                        <li
                          className="border rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                          onClick={() => handleEditClick()}
                        >
                          Edit
                        </li>
                        <li
                          className="border rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                          onClick={() => handleConvertClick()}
                        >
                          Convert
                        </li>
                      </ul>
                    </div>
                  </>
                )}
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default SignOffDateRow;
