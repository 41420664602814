import React from "react";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, IMAGE_URL } from "../../../../../appconfig";
import { FaAngleDown } from "react-icons/fa6";
import ParentAgency from "./ParentAgency";
import { IoIosArrowUp } from "react-icons/io";
import TapUserIcon from "../../../../../assets/tap-user.svg";
import EditIcon from "../../../../../assets/editBlue.svg";
import EditIconGreen from "../../../../../assets/edit.svg";
import SaveIcon from "../../../../../assets/save.svg";
import { useNavigate } from "react-router-dom";
import PersonalInfo from "./PersonalInfo";
import Checklist from "./Checklist";
import useApi from "../../../../../custom-hooks/useApi";
import Spinner from "../../../../common/Spinner";
import axios from "axios";
import { toast } from "react-toastify";
import { setUser } from "../../../../../redux/slices/authSlice";
import { setLoading } from "../../../../../redux/slices/commonSlice";

function ProfileDetails({ agentRootId }) {
  const Fullname = useSelector((state) => state.auth.fullName);
  const loading = useSelector((state) => state.common.loading);
  const [activeTab, setActiveTab] = useState(1);
  const role = useSelector((state) => state.auth.role);
  const user = useSelector((state) => state.auth.user);
  const [url, setUrl] = useState(null);
  const [activeRecom, setActiveRecom] = useState(true);
  const [activeProfile, setActiveProfile] = useState(false);
  const [activeCheck, setActiveCheck] = useState(false);
  const [isPAEditing1, setIsPAEditing1] = useState(false);
  const [isPAEditing2, setIsPAEditing2] = useState(false);
  const [editing, setIsEditing] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [progress, setProgress] = useState(1);
  const [userData, setUserData] = useState([]);
  const api = useApi();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const [personalInfoFormData, setPersonalInfoFormData] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await api.get(
          `${BASE_URL}/organization/UserInformation/${user.id}`
        );
        if (response.status === 200) {
          const data = response.data.data;
          setPersonalInfoFormData({ ...data });
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [api, user.id]);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await api.get(
          `${BASE_URL}/Organization/UserDetails/${user.id}`
        );
        setUserData(response.data?.data || []);
        setProgress(response.data?.data?.Progress || 1);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [isChanged, progress]);

  const togglePAEditing = () => {
    if (progress === 1) {
      setIsPAEditing1(!isPAEditing1);
      setIsPAEditing2(false);
    } else if (progress === 2) {
      setIsPAEditing2(!isPAEditing2);
      setIsPAEditing1(false);
    } else {
      setIsPAEditing1(false);
      setIsPAEditing2(false);
    }
  };
const toggleEditing = () => {
    setIsEditing(!editing);
  };
  useEffect(() => {
    if (user && "ImageName" in user) {
      if (user.ImageName) {
        setUrl(`${IMAGE_URL}/${user.ImageName}.jpg?${new Date().getTime()}`);
      } else {
        setUrl(null);
      }
    } else {
      setUrl(null);
    }
  }, [user]);

  if (!role || !Fullname) {
    return;
  }

  const toggleChange = () => {
    setIsChanged(!isChanged);
  };
  function handleImageChange(e) {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onload = function (e) {
        setUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };
  const handleSave = async () => {
    if (!image) {
      return;
    }
    await dispatch(setLoading(true));
    try {
      const apiRes = await api.get("/Agent/GetSignedUrl");

      if (apiRes.data?.sasUrl) {
        const { imageName, sasUrl } = apiRes.data;
        await uploadImageAndUpdateProfile(sasUrl, imageName);
      } else {
        throw new Error("Error getting signed url");
      }
    } catch (error) {
      console.error("Error saving image:", error);
    } finally {
      await dispatch(setLoading(false));
    }
  };

  function imageToBlob() {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/jpeg");
      };
      img.onerror = reject;
    });
  }

  async function uploadImageAndUpdateProfile(sasUrl, imageName) {
    try {
      const blob = await imageToBlob();
      const contentType = "image/jpeg";
      const response = await axios.put(sasUrl, blob, {
        headers: {
          "Content-Type": contentType,
          "x-ms-blob-type": "BlockBlob",
        },
      });

      if (response.status === 200 || response.status === 201) {
        const res = await api.post("/Agent/UpdateProfileImage", {
          fileName: imageName,
        });
        if (res.status === 200) {
          const newUser = { ...user, ImageName: imageName };
          await dispatch(setUser(newUser));
          setIsEditMode(false);
          toast.success("Image updated successfully");
        } else {
          throw new Error("Error updating image");
        }
      } else {
        throw new Error("Error uploading image");
      }
    } catch (error) {
      console.error("Error processing image", error);
      // Handle the error appropriately
    }
  }

  return (
    <div className=" bg-neutral-100 ">
      {loading && <Spinner />}
      <div>
        <div className="pt-12 ml-4">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: ".5rem",
            }}
          >
            {url ? (
              <>
                <img
                  src={url}
                  alt="User"
                  style={{
                    width: "120px",
                    height: "120px",
                    flexShrink: 0,
                    borderRadius: "100%",
                    overflow: "hidden",
                  }}
                />
                {isEditMode ? (
                  <img
                    onClick={handleSave}
                    className="cursor-pointer absolute ml-20 mb-20"
                    src={SaveIcon}
                    alt="edit"
                    tooltip="Change Profile Picture"
                  />
                ) : (
                  <img
                    onClick={() => {
                      toggleEditMode();
                      if (!isEditMode) {
                        document.getElementById("imageUpload").click();
                      }
                    }}
                    className="cursor-pointer absolute ml-20 mb-20"
                    src={EditIconGreen}
                    alt="edit"
                    tooltip="Change Profile Picture"
                  />
                )}
                <input
                  type="file"
                  id="imageUpload"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </>
            ) : (
              <img
                style={{
                  cursor: "pointer",
                  marginRight: "0.5rem",
                  width: "120px",
                  height: "120px",
                }}
                src={TapUserIcon}
                alt="profile"
              />
            )}
            <div className="pl-8 w-[25%]">
              <div className="flex flex-col justify-center">
                <span className=" text-neutral-800 text-4xl font-semibold">
                  {Fullname && Fullname.toString()}
                </span>
              </div>
              <p className=" text-neutral-600 font-medium text-base py-3 font-inter">
                Onboarding
              </p>

              <div className="flex justify-between w-full">
                <p className=" text-neutral-400 font-semibold text-sm font-inter">
                  Progress
                </p>
                <p className=" text-neutral-400 font-semibold text-base font-inter">
                  2%
                </p>
              </div>
              <div className=" mt-2">
                <div className=" bg-neutral-200 h-[0.8rem] rounded-full mb-3">
                  <div
                    id="progressBar"
                    className="bg-green-400 h-full rounded-full"
                    style={{ width: "6%" }}
                  ></div>
                </div>
                {/* <input type="range" className="w-full appearance-none bg-green-500 rounded-full h-4" min="0" max="100" value="0" id="slider"/> */}
              </div>
            </div>
          </div>
          <div className=" flex text-neutral-400 py-4 font-inter text-xl space-x-4 ml-2 ">
            <div
              className={`cursor-pointer p-4 ${
                activeTab === 1
                  ? " text-blue-500 text-secondary font-inter text-lg font-semibold border-b-4 border-blue-700 inline-flex items-center"
                  : "text-neutral-400 font-inter text-lg font-medium"
              }`}
            >
              Profile
            </div>
            <div
              onClick={() => navigate("/payments")}
              className={`cursor-pointer p-4 ${
                activeTab === 2
                  ? " text-blue-500 text-secondary font-inter text-lg font-semibold border-b-4 border-blue-700 inline-flex items-center p-2"
                  : "text-neutral-400 font-inter text-lg font-medium"
              }`}
            >
              Payments & subscriptions
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-white py-6 ml-5 mr-5 shadow-md rounded-md">
        <div className=" rounded-md">
          <div className="flex space-x-4 border-none pl-8 cursor-pointer">
            {activeRecom ? (
              <p className="mt-[0.6rem] text-neutral-800  ">
                <FaAngleDown onClick={() => setActiveRecom(!activeRecom)} />
              </p>
            ) : (
              <p className="mt-[0.4rem] text-neutral-800 text-xl font-bold ">
                {" "}
                <IoIosArrowUp
                  onClick={() => setActiveRecom(!activeRecom)}
                />{" "}
              </p>
            )}
            <div className="flex">
              <div
                onClick={() => setActiveRecom(!activeRecom)}
                className=" text-neutral-800 font-semibold text-xl"
              >
                Parent Agency
              </div>
              {activeRecom && progress !== 3 && (
                <img
                  onClick={togglePAEditing}
                  className="ml-5"
                  src={EditIcon}
                  alt="edit"
                />
              )}
            </div>
          </div>
        </div>
        {activeRecom && (
          <ParentAgency
            edit1={isPAEditing1}
            edit2={isPAEditing2}
            toggle={togglePAEditing}
            data={userData}
            change={toggleChange}
          />
        )}
      </div>
      <div className=" bg-white mt-5 py-6 ml-5 mr-5 shadow-md rounded-md">
        <div
          className=" rounded-md"
          // onClick={() => setActiveProfile(!activeProfile)}
        >
          <div className="flex space-x-4 border-none pl-8 cursor-pointer">
            {activeProfile ? (
              <p className="mt-[0.6rem] text-neutral-800  ">
                <FaAngleDown onClick={() => setActiveProfile(!activeProfile)} />
              </p>
            ) : (
              <p className="mt-[0.4rem] text-neutral-800 text-xl font-bold ">
                {" "}
                <IoIosArrowUp
                  onClick={() => setActiveProfile(!activeProfile)}
                />{" "}
              </p>
            )}
            <div className="flex">
              <div
                className=" text-neutral-800 font-semibold text-xl"
                onClick={() => setActiveProfile(!activeProfile)}
              >
                Personal Information
              </div>
              {activeProfile && !editing && (
                <img
                  className="ml-5"
                  src={EditIcon}
                  alt="edit"
                  onClick={() => toggleEditing()}
                />
              )}
            </div>
          </div>
        </div>
        {activeProfile && (
          <PersonalInfo
            agentRootId={agentRootId}
            personalInfoFormData={personalInfoFormData}
            editing={editing}
            toggle={toggleEditing}
          />
        )}
      </div>
      <div className=" bg-white mt-5 py-6 ml-5 mr-5 mb-5 shadow-md rounded-md">
        <div
          className=" rounded-md"
          onClick={() => setActiveCheck(!activeCheck)}
        >
          <div className="flex space-x-4 border-none pl-8 cursor-pointer">
            {activeCheck ? (
              <p className="mt-[0.6rem] text-neutral-800  ">
                <FaAngleDown />
              </p>
            ) : (
              <p className="mt-[0.4rem] text-neutral-800 text-xl font-bold ">
                {" "}
                <IoIosArrowUp />{" "}
              </p>
            )}
            <div className="flex">
              <div className=" text-neutral-800 font-semibold text-xl">
                Complete your checklist
              </div>
            </div>
          </div>
        </div>
        {activeCheck && <Checklist />}
      </div>
    </div>
  );
}

export default ProfileDetails;
