import React, { useEffect, useState } from "react";
import applicationsInfoSvg from "../../../assets/Applicatonsinfo.svg";
import {
  Text,
  Dropdown,
  Info,
  ErrorMessage,
  MultiOptionDropdown,
} from "./FormComponent";
import useApi from "../../../custom-hooks/useApi";
import { BASE_URL } from "../../../appconfig";
import Spinner from "../../common/Spinner";
import { toast } from "react-toastify";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import CountryStateDropdown from "./ApplicatonComponent/CountryStateDropdown";
import { useNavigate } from "react-router-dom";
import SearchDropdown from "./ApplicatonComponent/SearchDropdown";

const AddNewApplication = () => {
  const navigate = useNavigate();
  const api = useApi();
  const list = ["option1", "option2", "option3", "option4"];
  const [allDropdownList, setAllDropdownList] = useState({
    policyExistingClient: [],
    insuredExistingClient: [],
  });
  const [policyExistingClient, setPolicyExistingClient] = useState("");
  const [policyExistingClientObj, setPolicyExistingClientObj] = useState("");
  const [primaryExistingClient, setPrimaryExistingClient] = useState("");
  const [primaryNewClient, setPrimaryNewClient] = useState("");
  const [existingClient, setExistingClient] = useState("choose");
  const [insuredClient, setInsuredClient] = useState("choose");
  const [insuredExistingClient, setInsuredExistingClient] = useState("");
  const [insuredExistingClientObj, setInsuredExistingClientObj] = useState();
  const [primaryRadio, setPrimaryRadio] = useState("choose");
  const [policyNewClientType, setPolicyNewClientType] = useState("");
  const [insuredNewClientType, setInsuredNewClientType] = useState("");
  const [policyProvider, setPolicyProvider] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [product, setProduct] = useState("");
  const [healthAssessor, setHealthAssessor] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("New");
  const [dateSubmitted, setDateSubmitted] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [targetPremium, setTargetPremium] = useState("");
  const [premiumMode, setPremiumMode] = useState("");
  const [bankDraftTerms, setBankDraftTerms] = useState("");
  const [writingAgent, setWritingAgent] = useState("");
  const [splitCase, setSplitCase] = useState("");
  const [splitAgent1, setSplitAgent1] = useState("");
  const [splitAgent2, setSplitAgent2] = useState("");
  const [errors, setErrors] = useState({});
  const [infoModal, setInfoModal] = useState("");
  const [primaryFirstName, setPrimaryFirstName] = useState("");
  const [primaryLastName, setPrimaryLastName] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryMobileNumber, setPrimaryMobileNumber] = useState("");
  const [policyFirstName, setPolicyFirstName] = useState("");
  const [policyLastName, setPolicyLastName] = useState("");
  const [policyEmail, setPolicyEmail] = useState("");
  const [policyMobileNumber, setPolicyMobileNumber] = useState("");
  const [policyStateCountry, setPolicyStateCountry] = useState("");
  const [policyDob, setPolicyDob] = useState("");
  const [policyBusinessName, setPolicyBusinessName] = useState("");
  const [policyEntityType, setPolicyEntityType] = useState("");
  const [policyStateCountrys, setPolicyStateCountrys] = useState("");
  const [insuredFirstName, setInsuredFirstName] = useState("");
  const [insuredLastName, setInsuredLastName] = useState("");
  const [insuredEmail, setInsuredEmail] = useState("");
  const [insuredMobileNumber, setInsuredMobileNumber] = useState("");
  const [insuredBusinessName, setInsuredBusinessName] = useState("");
  const [insuredEntityType, setInsuredEntityType] = useState("");
  const [insuredRelationship, setInsuredRelationship] = useState("");
  const [insuredStateCountry, setInsuredStateCountry] = useState("");
  const [insuredDob, setInsuredDob] = useState("");
  const [loading, setLoading] = useState(false);
  const [statesData, setStatesData] = useState([]);
  const [providerID, setProviderID] = useState();
  const [providerData, setproviderData] = useState([]);
  const [allData, setAllData] = useState({});
  const [idinsuredExist, setIDInsuredExist] = useState({});
  // const [PolicyHouseHoldView, setPolicyHouseHoldView] = useState(false)
  // const [PolicyBusinessView, setPolicyBusinessView] = useState(false)
  // const [PolicyHouseHoldView, setPolicyHouseHoldView] = useState(false)
  // const [PolicyBusinessView, setPolicyBusinessView] = useState(false)

  // useEffect (() => {

  //   if (existingClient === 'create') {
  //     setPolicyHouseHoldView(true)
  //   } else {
  //     setPolicyHouseHoldView(false)
  //   }

  //   if (insuredClient === 'create') {

  //   } else {

  //   }

  // }, [existingClient, insuredClient])

  useEffect(() => {
    let isMounted = true;

    const fetchCountryData = async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `${BASE_URL}/RingCentral/Get_All_States`
        );
        if (isMounted) {
          setStatesData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCountryData();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    fetchAndSetDropdownData(
      "/Application/ClientList",
      "policyExistingClient",
      null,
      formatClientList,
      "Entity"
    );
    fetchProviderList();
    fetchAndSetDropdownData(
      "/Organization/PremiumMode",
      "premiumModeList",
      null,
      formatGenericList,
      "premium"
    );
    fetchAndSetDropdownData(
      "/Organization/BankDraftTerms",
      "bankDraftTermsList",
      null,
      formatGenericList,
      "bankDraft"
    );
    fetchAndSetDropdownData(
      "/Organization/Constant/PolicyStatus",
      "policyStatusList",
      null,
      formatGenericList,
      "policystatus"
    );
    fetchAndSetDropdownData(
      "/Organization/Constant/ApplicationStatus",
      "applicationStatusList",
      null,
      formatGenericList,
      "constant"
    );
    fetchAndSetDropdownData(
      "/Organization/Constant/Entity",
      "entityTypeList",
      null,
      formatEntityList,
      "constant"
    );
    fetchAndSetDropdownData(
      "/static/relations",
      "relationshipList",
      null,
      formatRelationshipList,
      "data"
    );
    fetchAndSetDropdownData(
      "/Organization/Constant/SplitCase",
      "splitcaseList",
      null,
      formatGenericList,
      "constant"
    );
  }, []);

  useEffect(() => {
    if (providerData?.data?.provider) {
      const obj = providerData?.data?.provider.filter(
        (item) => item?.ProviderName === policyProvider
      );
      setProviderID(obj[0]?.ProviderID);
      fetchProductData(
        `/Organization/Product/${obj[0]?.ProviderID}`,
        "productList",
        null,
        formatProductList,
        "product"
      );
    }
  }, [policyProvider]);

  useEffect(() => {
    if (allDropdownList?.policyExistingClient && policyExistingClient) {
      let id = allDropdownList?.policyExistingClient.filter(
        (item) =>
          `${item?.firstName} ${item?.lastName}` === policyExistingClient
      );
      id = id?.AccountID || id[0]?.AccountID;
      fetchAndSetDropdownData(
        `/Application/ClientList?ownerId=${id}`,
        "insuredExistingClient",
        null,
        formatClientList,
        "Entity"
      );
    }
  }, [policyExistingClient]);

  const fetchProviderList = async () => {
    try {
      const providerResponse = await api.get(
        `${BASE_URL}/Organization/Provider`
      );
      if (providerResponse?.status === 200) {
        setproviderData(providerResponse);
        const providerList = providerResponse?.data?.provider?.map(
          (item) => item?.ProviderName
        );
        setAllDropdownList((prev) => ({
          ...prev,
          providerList,
        }));
        setAllData((prev) => ({
          ...prev,
          providerList: providerResponse?.data?.provider || [],
        }));
      }
    } catch (error) {
      console.log("Error fetching Provider List:", error);
    }
  };

  const fetchAndSetDropdownData = async (
    endpoint,
    key1,
    key2,
    formatter,
    objName
  ) => {
    try {
      const response = await api.get(`${BASE_URL}${endpoint}`);
      if (response?.status === 200) {
        const formattedData = formatter(response?.data?.[objName]);
        setAllDropdownList((prev) => ({
          ...prev,
          [key1]: formattedData.key1,
          ...(key2 ? { [key2]: formattedData.key2 } : {}),
        }));
        setAllData((prev) => ({
          ...prev,
          [key1]: response?.data?.[objName] || [],
          ...(key2 ? { [key2]: response?.data?.[objName] || [] } : {}),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductData = async (endpoint, key1, key2, formatter, objName) => {
    try {
      const response = await api.get(`${BASE_URL}${endpoint}`);
      if (response?.status === 200) {
        const formattedData = response?.data?.[objName] || [];
        let productList;
        setAllDropdownList((prev) => ({
          ...prev,
          [key1]: formattedData?.map((item) => item?.ProductName),
        }));
        setAllData((prev) => ({
          ...prev,
          [key1]: response?.data?.[objName] || [],
        }));
      }
    } catch (error) {
      setAllDropdownList((prev) => ({
        ...prev,
        productList: [],
      }));
      console.log(error);
    }
  };

  const formatClientList = (accountList) => ({
    key1: accountList,
  });

  const formatProductList = (productList) => ({
    key1: productList?.map((item) => item?.Product) || [],
  });

  const formatGenericList = (list) => ({
    key1: list?.map((item) => item?.Name) || [],
  });

  const formatEntityList = (list) => ({
    key1: list?.map((item) => item?.Name) || [],
  });
  const formatRelationshipList = (list) => ({
    key1: list?.map((item) => item?.Relation) || [],
  });

  useEffect(() => {
    setPrimaryFirstName("");
    setPrimaryLastName("");
    setPrimaryEmail("");
    setPrimaryMobileNumber("");
    setPolicyFirstName("");
    setPolicyLastName("");
    setPolicyEmail("");
    setPolicyMobileNumber("");
    setPolicyBusinessName("");
    setPolicyEntityType("");
    setPolicyStateCountrys("");
    setPrimaryNewClient("");
  }, [policyNewClientType]);

  useEffect(() => {
    if (insuredNewClientType === "Household") {
      setInsuredBusinessName("");
      setInsuredEntityType("");
    }
  }, [insuredNewClientType]);

  const validateFields = () => {
    let tempErrors = {};
    if (!insuredRelationship)
      tempErrors.insuredRelationship = "Field is Required";
    // Policy information
    if (!policyProvider)
      tempErrors.policyProvider = "Policy Provider is required";
    if (!policyNumber) tempErrors.policyNumber = "Policy Number is required";
    if (!product) tempErrors.product = "Product is required";
    // if (!healthAssessor) tempErrors.healthAssessor = "Health Assessor is required";
    if (!applicationStatus)
      tempErrors.applicationStatus = "Application Status is required";
    if (!dateSubmitted) tempErrors.dateSubmitted = "Date Submitted is required";
    if (!targetPremium) tempErrors.targetPremium = "Target Premium is required";
    if (!premiumMode) tempErrors.premiumMode = "Premium Mode is required";
    if (!bankDraftTerms)
      tempErrors.bankDraftTerms = "Bank Draft Terms are required";

    // Who will be the Policy Owner?
    if (existingClient === "choose") {
      if (!policyExistingClient)
        tempErrors.policyExistingClient = "Policy Existing Client is required";
    }

    if (existingClient === "create") {
      if (!policyNewClientType)
        tempErrors.policyNewClientType = "Account Type is required";

      if (policyNewClientType === "Household") {
        if (!policyFirstName) tempErrors.policyFirstName = "Field is Required";
        if (!policyLastName) tempErrors.policyLastName = "Field is Required";
        if (!policyEmail) tempErrors.policyEmail = "Field is Required";
        if (!policyMobileNumber)
          tempErrors.policyMobileNumber = "Field is Required";
        const isValidPolicyPhoneNumber = isValidPhoneNumber(
          policyMobileNumber.toString()
        );
        if (!isValidPolicyPhoneNumber) {
          toast.error("Invalid Phone Number");
          return false;
        }
        if (!policyStateCountry)
          tempErrors.policyStateCountry = "Field is Required";
        if (!policyDob) tempErrors.policyDob = "Field is Required";
      }

      if (policyNewClientType === "Business") {
        if (!policyBusinessName)
          tempErrors.policyBusinessName = "Field is Required";
        if (!policyEntityType)
          tempErrors.policyEntityType = "Field is Required";
        if (!policyStateCountrys)
          tempErrors.policyStateCountrys = "Field is Required";
      }
    }

    // Who will be the Primary Contact?

    if (existingClient === "create") {
      if (policyNewClientType === "Business") {
        // if (!primaryExistingClient)
        //   tempErrors.primaryExistingClient =
        //     "Primary Contact Existing Client is required";
        // if (!primaryNewClient)
        //   tempErrors.primaryNewClient = "Account Type is required";
        if (!primaryFirstName)
          tempErrors.primaryFirstName = "Field is Required";
        if (!primaryLastName) tempErrors.primaryLastName = "Field is Required";
        if (!primaryEmail) tempErrors.primaryEmail = "Field is Required";
        if (!primaryMobileNumber)
          tempErrors.primaryMobileNumber = "Field is Required";
        const isValidPrimaryPhoneNumber = isValidPhoneNumber(
          primaryMobileNumber.toString()
        );
        if (!isValidPrimaryPhoneNumber) {
          toast.error("Invalid Primary Phone Number");
          return false;
        }
      }
    }

    // Who will be the Insured?

    if (insuredClient === "choose") {
      if (!insuredExistingClient)
        tempErrors.insuredExistingClient = "Insured Client is required";
    }

    if (insuredClient === "create") {
      const isValidInsuredPhoneNumber = isValidPhoneNumber(
        insuredMobileNumber.toString()
      );
      if (!isValidInsuredPhoneNumber) {
        toast.error("Invalid Insured Phone Number");
        return false;
      }
      if (!insuredNewClientType)
        tempErrors.insuredNewClientType = "Account Type is required";
      if (!insuredFirstName) tempErrors.insuredFirstName = "Field is Required";
      if (!insuredLastName) tempErrors.insuredLastName = "Field is Required";
      if (!insuredEmail) tempErrors.insuredEmail = "Field is Required";
      if (!insuredMobileNumber)
        tempErrors.insuredMobileNumber = "Field is Required";
      if (!insuredStateCountry)
        tempErrors.insuredStateCountry = "Field is Required";
      if (!insuredDob) tempErrors.insuredDob = "Field is Required";
      if (insuredNewClientType === "Business") {
        if (!insuredBusinessName)
          tempErrors.insuredBusinessName = "Field is Required";
        if (!insuredEntityType)
          tempErrors.insuredEntityType = "Field is Required";
      }
    }

    // Writing Agent and Split Agent Validation
    if (
      writingAgent?.AgentID === splitAgent1?.AgentID ||
      writingAgent?.AgentID === splitAgent2?.AgentID
    ) {
      tempErrors.writingAgent =
        "Writing Agent cannot be the same as Split Agent 1 or Split Agent 2";
      toast.error(
        "Writing Agent cannot be the same as Split Agent 1 or Split Agent 2"
      );
    }

    if (splitAgent1?.AgentID === splitAgent2?.AgentID && splitAgent1 !== "") {
      tempErrors.splitAgent2 =
        "Split Agent 1 and Split Agent 2 cannot be the same";
      toast.error("Split Agent 1 and Split Agent 2 cannot be the same");
    }

    if (splitCase !== "" && splitCase !== "No") {
      if (!splitAgent1) {
        tempErrors.splitAgent1 =
          "Split Agent 1 is required if Split Case is selected";
        toast.error("Split Agent 1 is required if Split Case is selected");
      }
    }

    if (!writingAgent) tempErrors.writingAgent = "Writing Agent is required";
    if (!splitCase) tempErrors.splitCase = "Split Case is required";

    setErrors(tempErrors);

    setTimeout(() => {
      setErrors({});
    }, 5000);
    const policyNumberValid = policyNumber.length >= 10;
    const targetPremiumValid = targetPremium > 200;

    if (!policyNumberValid) {
      toast.error("Policy number must be at least 10 digits");
      return false;
    }

    if (!targetPremiumValid) {
      toast.error("Target Premium value must be more than 200");
      return false;
    }

    if (Object.keys(tempErrors).length !== 0) {
      toast.error(
        "Check all details and ensure all required fields are filled."
      );
    }
    return Object.keys(tempErrors).length === 0;
  };

  const FormSubmit = async () => {
    let owner = {};
    let insured = {
      relationship: String(
        allData?.relationshipList?.find(
          (item) => `${item?.Relation}` === insuredRelationship
        )?.RelationshipID
      ),
    };
    let policy = {
      provider: String(
        allData?.providerList?.find(
          (item) => `${item?.ProviderName}` === policyProvider
        )?.ProviderID
      ),
      policyNumber: String(policyNumber),
      product: String(
        allData?.productList?.find((item) => `${item?.ProductName}` === product)
          ?.ProductID
      ),
      // healthAssessor: Number(healthAssessor),
      // healthAssessor: 2,
      dateSubmitted: String(dateSubmitted),
      targetPremium: String(targetPremium),
      premiumMode: String(
        allData?.premiumModeList?.find(
          (item) => `${item?.Name}` === premiumMode
        )?.ID
      ),
      bankDraftTerms: String(
        allData?.bankDraftTermsList?.find(
          (item) => `${item?.Name}` === bankDraftTerms
        )?.ID
      ),
    };

    // Conditional logic to modify owner and insured objects
    if (existingClient === "create" && insuredClient === "create") {
      // Both owner and insured are new clients
      owner.isNewClient = true;
      owner.accountType =
        policyNewClientType === "Business" ? String(68) : String(67);

      if (policyNewClientType === "Business") {
        // Business Client
        owner.businessName = String(policyBusinessName);
        owner.entityType = Number(
          allData?.entityTypeList?.find(
            (item) => `${item?.Name}` === policyEntityType
          )?.ID
        );
        owner.state = String(policyStateCountrys?.HomeState);
        owner.country = String(policyStateCountrys?.HomeCountry);
        owner.firstName = String(primaryFirstName);
        owner.lastName = String(primaryLastName);
        owner.email = String(primaryEmail);
        owner.mobileNo = String(primaryMobileNumber);
      } else {
        // Household Client
        owner.firstName = String(policyFirstName);
        owner.lastName = String(policyLastName);
        owner.email = String(policyEmail);
        owner.mobileNo = String(policyMobileNumber);
        owner.state = String(policyStateCountry?.HomeState);
        owner.country = String(policyStateCountry?.HomeCountry);
        owner.dob = String(policyDob);
      }

      insured.isNewClient = true;
      insured.accountType =
        insuredNewClientType === "Business" ? String(68) : String(67);
      insured.firstName = String(insuredFirstName);
      insured.lastName = String(insuredLastName);
      insured.email = String(insuredEmail);
      insured.mobileNo = String(insuredMobileNumber);
      insured.state = String(insuredStateCountry?.HomeState);
      insured.country = String(insuredStateCountry?.HomeCountry);
      insured.dob = String(insuredDob);
      if (insuredNewClientType === "Business") {
        insured.businessName = String(insuredBusinessName);
        insured.entityType = Number(
          allData?.entityTypeList?.find(
            (item) => `${item?.Name}` === insuredEntityType
          )?.ID
        );
      }
    } else if (existingClient === "choose" && insuredClient === "create") {
      // Existing owner, new insured
      owner.isNewClient = false;
      owner.account = String(policyExistingClientObj?.AccountID);
      insured.isNewClient = true;
      insured.accountType =
        insuredNewClientType === "Business" ? String(68) : String(67);
      insured.firstName = String(insuredFirstName);
      insured.lastName = String(insuredLastName);
      insured.email = String(insuredEmail);
      insured.mobileNo = String(insuredMobileNumber);
      insured.state = String(insuredStateCountry?.HomeState);
      insured.country = String(insuredStateCountry?.HomeCountry);
      insured.dob = String(insuredDob);
      if (insuredNewClientType === "Business") {
        insured.businessName = String(insuredBusinessName);
        insured.entityType = Number(
          allData?.entityTypeList?.find(
            (item) => `${item?.Name}` === insuredEntityType
          )?.ID
        );
      }
    } else if (existingClient === "choose" && insuredClient === "choose") {
      // Both owner and insured are existing clients
      owner.isNewClient = false;
      owner.account = String(policyExistingClientObj?.AccountID);
      insured.isNewClient = false;
      insured.account = String(insuredExistingClientObj?.AccountID);
    } else if (existingClient === "create" && insuredClient === "choose") {
      // New owner, existing insured
      owner.isNewClient = true;
      owner.accountType =
        policyNewClientType === "Business" ? String(68) : String(67);

      if (policyNewClientType === "Business") {
        // Business Client
        owner.businessName = String(policyBusinessName);
        owner.entityType = Number(
          allData?.entityTypeList?.find(
            (item) => `${item?.Name}` === policyEntityType
          )?.ID
        );
        owner.state = String(policyStateCountrys?.HomeState);
        owner.country = String(policyStateCountrys?.HomeCountry);
        owner.firstName = String(primaryFirstName);
        owner.lastName = String(primaryLastName);
        owner.email = String(primaryEmail);
        owner.mobileNo = String(primaryMobileNumber);
      } else {
        // Household Client
        owner.firstName = String(policyFirstName);
        owner.lastName = String(policyLastName);
        owner.email = String(policyEmail);
        owner.mobileNo = String(policyMobileNumber);
        owner.state = String(policyStateCountry?.HomeState);
        owner.country = String(policyStateCountry?.HomeCountry);
        owner.dob = String(policyDob);
      }

      insured.isNewClient = false;
      insured.account = String(String(insuredExistingClientObj?.AccountID));
    }

    console.log("Form submitted", {
      owner,
      insured,
      policy,
    });
    setLoading(true);

    try {
      const response = await api.post(
        `${BASE_URL}/Application/new`,
        {
          writingAgent: String(writingAgent?.AgentID),
          hasSplitCase: splitCase !== "" && splitCase !== "No" ? true : false,
          splitCase:
            splitCase !== "" && splitCase !== "No"
              ? String(
                  allData?.splitcaseList?.find(
                    (item) => `${item?.Name}` === splitCase
                  )?.ID
                )
              : false,
          splitAgent1:
            splitCase !== "" && splitCase !== "No"
              ? String(splitAgent1?.AgentID)
              : "",
          splitAgent2:
            splitCase !== "" && splitCase !== "No"
              ? String(splitAgent2?.AgentID)
              : "",
          owner,
          insured,
          policy,
        },
        { "Content-Type": "application/json" }
      );

      if (response.status === 201) {
        toast.success("Details Updated", {
          theme: "dark",
        });
        navigate("/applications");
      } else {
        toast.error("Failed to update details", {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || "Failed to update details", {
        theme: "dark",
      });
      console.error("Error updating details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneInputChangeOwner = (value) => {
    if (value !== undefined) {
      setPolicyMobileNumber(value);
    }
  };

  const handlePhoneInputChangePrimary = (value) => {
    if (value !== undefined) {
      setPrimaryMobileNumber(value);
    }
  };

  const handlePhoneInputChangeInsured = (value) => {
    if (value !== undefined) {
      setInsuredMobileNumber(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateFields()) {
      FormSubmit();
    }
  };

  return (
    <div>
      {loading ? <Spinner /> : null}
      <div className="flex flex-col m-10 gap-8 cursor-default">
        {/* Heading */}
        <div className="flex flex-col gap-3">
          <div className="text-gray-400 font-medium text-lg leading-5">
            Application
          </div>
          <div className="text-gray-800 font-semibold text-[28px] leading-8">
            New Application
          </div>
        </div>

        {/* info */}
        <div className="w-full flex flex-row items-center rounded-md border border-gray-300 p-6 gap-20 bg-white">
          <Info
            info={"Writing Agent"}
            name={"Writing Agent"}
            infoModal={infoModal}
            setInfoModal={setInfoModal}
          />
          <Info
            info={"Split Agent"}
            name={"Gary Gunnison"}
            infoModal={infoModal}
            setInfoModal={setInfoModal}
          />
          <Info
            info={"Provider"}
            name={"National Life Group"}
            infoModal={infoModal}
            setInfoModal={setInfoModal}
          />
          <Info
            info={"Health Assessor"}
            name={"Exam One"}
            infoModal={infoModal}
            setInfoModal={setInfoModal}
          />
          <div>
            <div className="text-gray-600 font-semibold text-base leading-5">
              Submitted Date
            </div>
            <div className="truncate py-1 whitespace-nowrap font-inter t">
              Nov 27, 2023
            </div>
          </div>
          <div>
            <div className="text-gray-600 font-semibold text-base leading-5">
              Issue Date
            </div>
            <div className="truncate py-1 whitespace-nowrap font-inter text-[#4871B7] underline h-8"></div>
          </div>
        </div>

        {/* new applicaton */}
        <div className=" flex flex-col gap-5 mt-2">
          <div className="text-gray-800 font-semibold text-2xl leading-[29.05px]">
            New Application
          </div>
          <div className="flex flex-row w-full gap-10">
            {/* left side Application form */}
            <div className="w-[70%] flex flex-col rounded-md border border-gray-300 p-6  bg-white gap-10">
              <div className="flex flex-row items-center gap-4 justify-center text-gray-800 font-semibold text-xl leading-[24.2px] w-full text-center">
                Client Information <img src={applicationsInfoSvg} alt="" />
              </div>

              <div className="flex flex-col gap-6 my-6">
                <Text size="base-title" label="Who is the Client Team?" />
                <div className="flex flex-row gap-10 w-full justify-between">
                  <div className="flex flex-col w-full">
                    <Text size="small-title" label="Writing Agent" />
                    {/* <input
                          type="text"
                          placeholder="Search"
                          value={writingAgent}
                          onChange={(e) => setWritingAgent(e.target.value)}
                          className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                        /> */}
                    <SearchDropdown onSelect={setWritingAgent} />
                    {errors.writingAgent && (
                      <ErrorMessage message={errors.writingAgent} />
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <Text size="small-title" label="Split Case?" />
                    <Dropdown
                      placeholder={"Select"}
                      list={["No", ...(allDropdownList?.splitcaseList || [])]}
                      selectedValue={splitCase}
                      onSelect={setSplitCase}
                    />
                    {errors.splitCase && (
                      <ErrorMessage message={errors.splitCase} />
                    )}
                  </div>
                </div>
                <div className="flex flex-row gap-10 w-full justify-between">
                  <div className="flex flex-col w-full">
                    <Text size="small-title" label="Split Agent 1" />
                    {/* <input
                          type="text"
                          placeholder="Search"
                          value={splitAgent1}
                          onChange={(e) => setSplitAgent1(e.target.value)}
                          className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                        /> */}
                    <SearchDropdown
                      onSelect={setSplitAgent1}
                      splitCase={splitCase}
                      disable={
                        splitCase === "No" || splitCase === "" ? true : false
                      }
                    />
                    {errors.splitAgent1 && (
                      <ErrorMessage message={errors.splitAgent1} />
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <Text size="small-title" label="Split Agent 2" />
                    {/* <input
                          type="text"
                          placeholder="Search"
                          value={splitAgent2}
                          onChange={(e) => setSplitAgent2(e.target.value)}
                          className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                        /> */}
                    <SearchDropdown
                      onSelect={setSplitAgent2}
                      splitCase={splitCase}
                      disable={
                        splitCase === "No" || splitCase === "" ? true : false
                      }
                    />
                    {errors.splitAgent2 && (
                      <ErrorMessage message={errors.splitAgent2} />
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="flex flex-col gap-6">
                  <div className="text-gray-600 font-medium text-base leading-[19.36px]">
                    Who will be the Policy Owner?
                  </div>
                  <div className="flex flex-row gap-10 w-full justify-between">
                    <div className="flex flex-col gap-3 w-full">
                      <div className="flex flex-row gap-2 items-center">
                        <input
                          type="radio"
                          id="choose-client"
                          name="policy-owner"
                          className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                          checked={existingClient === "choose"}
                          onChange={() => {
                            setExistingClient("choose");
                            setPolicyNewClientType("");
                          }}
                        />
                        <Text
                          size={"mid-title"}
                          label={"Choose Existing Client"}
                        />
                      </div>
                      <MultiOptionDropdown
                        placeholder={"Search for Client"}
                        list={allDropdownList?.policyExistingClient || []}
                        selectedValue={policyExistingClient}
                        onSelect={setPolicyExistingClient}
                        objSelect={setPolicyExistingClientObj}
                        disable={existingClient !== "choose" && true}
                        setIDInsuredExist={setIDInsuredExist}
                      />
                      {existingClient === "choose" &&
                        errors.policyExistingClient && (
                          <ErrorMessage message={errors.policyExistingClient} />
                        )}
                    </div>
                    <div className="flex items-end mb-3 font-medium">OR</div>
                    <div className="flex flex-col gap-3 w-full">
                      <div className="flex flex-row gap-2 items-center">
                        <input
                          type="radio"
                          id="choose-client"
                          name="policy-owner"
                          className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                          checked={existingClient === "create"}
                          onChange={() => {
                            setExistingClient("create");
                            setPolicyExistingClient("");
                          }}
                        />
                        <Text size={"mid-title"} label={"Create New Client"} />
                      </div>
                      <Dropdown
                        placeholder={"Choose Account Type"}
                        list={["Household", "Business"]}
                        selectedValue={policyNewClientType}
                        onSelect={setPolicyNewClientType}
                        disable={existingClient !== "create" && true}
                      />
                      {existingClient === "create" &&
                        errors.policyNewClientType && (
                          <ErrorMessage message={errors.policyNewClientType} />
                        )}
                    </div>
                  </div>

                  {policyNewClientType === "Household" ? (
                    <>
                      <div className="flex flex-row gap-10 w-full justify-between">
                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text size="small-title" label="First Name" />
                          </div>
                          <input
                            type="text"
                            placeholder="First Name"
                            value={policyFirstName}
                            onChange={(e) => setPolicyFirstName(e.target.value)}
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                          />
                          {errors.policyFirstName && (
                            <ErrorMessage message={errors.policyFirstName} />
                          )}
                        </div>
                        <div className="flex items-end mb-3 font-medium w-10"></div>

                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text size="small-title" label="Last Name" />
                          </div>
                          <input
                            type="text"
                            placeholder="Last Name"
                            value={policyLastName}
                            onChange={(e) => setPolicyLastName(e.target.value)}
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                          />
                          {errors.policyLastName && (
                            <ErrorMessage message={errors.policyLastName} />
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row gap-10 w-full justify-between">
                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text size="small-title" label="Email Address" />
                          </div>
                          <input
                            type="text"
                            placeholder="Email Address"
                            value={policyEmail}
                            onChange={(e) => setPolicyEmail(e.target.value)}
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                          />
                          {errors.policyEmail && (
                            <ErrorMessage message={errors.policyEmail} />
                          )}
                        </div>
                        <div className="flex items-end mb-3 font-medium w-10"></div>

                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text size="small-title" label="Mobile Phone" />
                          </div>
                          <PhoneInput
                            defaultCountry="US"
                            international
                            countryCallingCodeEditable={false}
                            placeholder="Enter phone number"
                            name="Mobile"
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                            value={policyMobileNumber || ""}
                            onChange={handlePhoneInputChangeOwner}
                            required
                            error={
                              policyMobileNumber
                                ? isValidPhoneNumber(policyMobileNumber)
                                  ? undefined
                                  : "Invalid phone number"
                                : "Phone number required"
                            }
                          />
                          {policyMobileNumber ? (
                            isValidPhoneNumber(
                              policyMobileNumber
                            ) ? undefined : (
                              <ErrorMessage message={"Invalid phone number"} />
                            )
                          ) : (
                            <></>
                          )}
                          {errors.policyMobileNumber && (
                            <ErrorMessage message={errors.policyMobileNumber} />
                          )}
                          {/* { && (<ErrorMessage message={"Invalid phone number"} />)} */}
                        </div>
                      </div>

                      <div className="flex flex-row gap-10 w-full justify-between">
                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text
                              size="small-title"
                              label="State / Country of Residence"
                            />
                          </div>
                          <CountryStateDropdown
                            stateClick={setPolicyStateCountry}
                            data={statesData}
                          />
                          {errors.policyStateCountry && (
                            <ErrorMessage message={errors.policyStateCountry} />
                          )}
                        </div>
                        <div className="flex items-end mb-3 font-medium w-10"></div>

                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text size="small-title" label="Date of Birth" />
                          </div>
                          <input
                            type="date"
                            placeholder="Date of Birth"
                            max={new Date().toISOString().split("T")[0]}
                            value={policyDob}
                            onChange={(e) => setPolicyDob(e.target.value)}
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                          />
                          {errors.policyDob && (
                            <ErrorMessage message={errors.policyDob} />
                          )}
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-gray-300 my-12"></div>
                    </>
                  ) : null}

                  {policyNewClientType === "Business" ? (
                    <>
                      {" "}
                      <div className="flex flex-row justify-between gap-5 mt-5">
                        <div className="w-full">
                          <Text size="small-title" label="Business Name" />
                          <input
                            type="text"
                            placeholder="Business Name"
                            value={policyBusinessName}
                            onChange={(e) =>
                              setPolicyBusinessName(e.target.value)
                            }
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                          />
                          {errors.policyBusinessName && (
                            <ErrorMessage message={errors.policyBusinessName} />
                          )}
                        </div>
                        <div className="w-full">
                          <Text size="small-title" label="Entity Type" />
                          <Dropdown
                            placeholder={"Entity Type"}
                            list={allDropdownList?.entityTypeList}
                            selectedValue={policyEntityType}
                            onSelect={setPolicyEntityType}
                          />
                          {errors.policyEntityType && (
                            <ErrorMessage message={errors.policyEntityType} />
                          )}
                        </div>
                        <div className="w-full">
                          <Text size="small-title" label="State / Countrys" />
                          <CountryStateDropdown
                            stateClick={setPolicyStateCountrys}
                            data={statesData}
                          />
                          {errors.policyStateCountrys && (
                            <ErrorMessage
                              message={errors.policyStateCountrys}
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-full h-[1px] bg-gray-300 my-8"></div>
                    </>
                  ) : null}
                </div>

                {policyNewClientType === "Business" ? (
                  <div className="flex flex-col gap-6">
                    <div className="text-gray-600 font-medium text-base leading-[19.36px]">
                      Who will be the Primary Contact?
                    </div>
                    <div className="flex flex-row gap-10 w-full justify-between">
                      {/* <div className="flex flex-col gap-3 w-full">
                        <div className="flex flex-row gap-2 items-center">
                          <input
                            type="radio"
                            id="choose-client"
                            name="primary-client"
                            checked={primaryRadio === "choose"}
                            className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                            onChange={() => {
                              setPrimaryRadio("choose");
                              setPrimaryNewClient("");
                              setPrimaryFirstName("");
                              setPrimaryLastName("");
                              setPrimaryEmail("");
                              setPrimaryMobileNumber("");
                            }}
                          />
                          <Text
                            size={"mid-title"}
                            label={"Choose Existing Client"}
                          />
                        </div>
                            <MultiOptionDropdown
                        placeholder={"Search for Client"}
                        list={allDropdownList?.insuredExistingClient}
                        selectedValue={primaryExistingClient}
                        onSelect={setPrimaryExistingClient}
                        disable={primaryRadio !== "choose" && true}
                      />
                        {primaryRadio === "choose" &&
                          errors.primaryExistingClient && (
                            <ErrorMessage
                              message={errors.primaryExistingClient}
                            />
                          )}
                      </div> */}
                      {/* <div className="flex items-end mb-3 font-medium">OR</div> */}
                      {/* <div className="flex flex-col gap-3 w-full">
                        <div className="flex flex-row gap-2 items-center">
                          <input
                            type="radio"
                            id="choose-client"
                            name="primary-client"
                            checked={primaryRadio === "create"}
                            className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                            onChange={() => {
                              setPrimaryRadio("create");
                              setPrimaryExistingClient("");
                            }}
                          />
                          <Text
                            size={"mid-title"}
                            label={"Create New Client"}
                          />
                        </div>
                        <Dropdown
                          placeholder={"Choose Account Type"}
                          list={["Household", "Business"]}
                          selectedValue={primaryNewClient}
                          onSelect={setPrimaryNewClient}
                          disable={primaryRadio !== "create" && true}
                        />

                        {primaryRadio === "create" &&
                          errors.primaryNewClient && (
                            <ErrorMessage message={errors.primaryNewClient} />
                          )}
                      </div> */}
                    </div>
                    {true ? (
                      <>
                        <div className="flex flex-row gap-10 w-full justify-between">
                          <div className="flex flex-col w-full">
                            <div className="pl-2">
                              <Text size="small-title" label="First Name" />
                            </div>
                            <input
                              type="text"
                              placeholder="First Name"
                              className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                              value={primaryFirstName}
                              onChange={(e) =>
                                setPrimaryFirstName(e.target.value)
                              }
                            />
                            {errors.primaryFirstName && (
                              <ErrorMessage message={errors.primaryFirstName} />
                            )}
                          </div>
                          <div className="flex items-end mb-3 font-medium w-10"></div>

                          <div className="flex flex-col w-full">
                            <div className="pl-2">
                              <Text size="small-title" label="Last Name" />
                            </div>
                            <input
                              type="text"
                              placeholder="Last Name"
                              value={primaryLastName}
                              onChange={(e) =>
                                setPrimaryLastName(e.target.value)
                              }
                              className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                            />
                            {errors.primaryLastName && (
                              <ErrorMessage message={errors.primaryLastName} />
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row gap-10 w-full justify-between">
                          <div className="flex flex-col w-full">
                            <div className="pl-2">
                              <Text size="small-title" label="Email Address" />
                            </div>
                            <input
                              type="text"
                              placeholder="Email Address"
                              value={primaryEmail}
                              onChange={(e) => setPrimaryEmail(e.target.value)}
                              className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                            />
                            {errors.primaryEmail && (
                              <ErrorMessage message={errors.primaryEmail} />
                            )}
                          </div>
                          <div className="flex items-end mb-3 font-medium w-10"></div>

                          <div className="flex flex-col w-full">
                            <div className="pl-2">
                              <Text size="small-title" label="Mobile Phone" />
                            </div>
                            <PhoneInput
                              defaultCountry="US"
                              international
                              countryCallingCodeEditable={false}
                              placeholder="Enter phone number"
                              name="Mobile"
                              className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                              value={primaryMobileNumber || ""}
                              onChange={handlePhoneInputChangePrimary}
                              required
                              error={
                                policyMobileNumber
                                  ? isValidPhoneNumber(primaryMobileNumber)
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                            />
                            {primaryMobileNumber ? (
                              isValidPhoneNumber(
                                primaryMobileNumber
                              ) ? undefined : (
                                <ErrorMessage
                                  message={"Invalid phone number"}
                                />
                              )
                            ) : (
                              <></>
                            )}
                            {errors.primaryMobileNumber && (
                              <ErrorMessage
                                message={errors.primaryMobileNumber}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="w-full h-[1px] bg-gray-300 my-8"></div>
                  </div>
                ) : null}

                <div className="flex flex-col gap-6 my-8">
                  <div className="text-gray-600 font-medium text-base leading-[19.36px]">
                    Who will be the Insured?
                  </div>
                  <div className="flex flex-row gap-10 w-full justify-between">
                    <div className="flex flex-col gap-3 w-full">
                      <div className="flex flex-row gap-2 items-center">
                        <input
                          type="radio"
                          id="choose-client"
                          name="client"
                          className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                          checked={insuredClient === "choose"}
                          onChange={() => {
                            setInsuredClient("choose");
                            setInsuredNewClientType("");
                            setInsuredFirstName("");
                            setInsuredLastName("");
                            setInsuredEmail("");
                            setInsuredMobileNumber("");
                            setInsuredStateCountry("");
                            setInsuredDob("");
                            setInsuredBusinessName("");
                            setInsuredEntityType("");
                          }}
                        />
                        <Text
                          size={"mid-title"}
                          label={"Choose Existing Client"}
                        />
                      </div>
                      <MultiOptionDropdown
                        placeholder={"Search for Client"}
                        list={
                          !policyExistingClient
                            ? allDropdownList?.policyExistingClient
                            : allDropdownList?.insuredExistingClient || []
                        }
                        selectedValue={insuredExistingClient}
                        onSelect={setInsuredExistingClient}
                        objSelect={setInsuredExistingClientObj}
                        disable={insuredClient !== "choose"}
                      />
                      {insuredClient === "choose" &&
                        errors.insuredExistingClient && (
                          <ErrorMessage
                            message={errors.insuredExistingClient}
                          />
                        )}
                    </div>
                    <div className="flex items-end mb-3 font-medium">OR</div>
                    <div className="flex flex-col gap-3 w-full">
                      <div className="flex flex-row gap-2 items-center">
                        <input
                          type="radio"
                          id="choose-client"
                          name="client"
                          className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                          checked={insuredClient === "create"}
                          onChange={() => {
                            setInsuredClient("create");
                            setInsuredExistingClient("");
                          }}
                        />
                        <Text size={"mid-title"} label={"Create New Client"} />
                      </div>
                      <Dropdown
                        placeholder={"Choose Account Type"}
                        list={["Household", "Business"]}
                        selectedValue={insuredNewClientType}
                        onSelect={setInsuredNewClientType}
                        disable={insuredClient !== "create"}
                      />
                      {insuredClient === "create" &&
                        errors.insuredNewClientType && (
                          <ErrorMessage message={errors.insuredNewClientType} />
                        )}
                    </div>
                  </div>

                  {insuredNewClientType === "Household" ||
                  insuredNewClientType === "Business" ? (
                    <>
                      <div className="flex flex-row gap-10 w-full justify-between">
                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text size="small-title" label="First Name" />
                          </div>
                          <input
                            type="text"
                            placeholder="First Name"
                            value={insuredFirstName}
                            onChange={(e) =>
                              setInsuredFirstName(e.target.value)
                            }
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                          />
                          {errors.insuredFirstName && (
                            <ErrorMessage message={errors.insuredFirstName} />
                          )}
                        </div>
                        <div className="flex items-end mb-3 font-medium w-10"></div>

                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text size="small-title" label="Last Name" />
                          </div>
                          <input
                            type="text"
                            placeholder="Last Name"
                            value={insuredLastName}
                            onChange={(e) => setInsuredLastName(e.target.value)}
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                          />
                          {errors.insuredLastName && (
                            <ErrorMessage message={errors.insuredLastName} />
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row gap-10 w-full justify-between">
                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text size="small-title" label="Email Address" />
                          </div>
                          <input
                            type="text"
                            placeholder="Email Address"
                            value={insuredEmail}
                            onChange={(e) => setInsuredEmail(e.target.value)}
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                          />
                          {errors.insuredEmail && (
                            <ErrorMessage message={errors.insuredEmail} />
                          )}
                        </div>
                        <div className="flex items-end mb-3 font-medium w-10"></div>

                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text size="small-title" label="Mobile Phone" />
                          </div>
                          <PhoneInput
                            defaultCountry="US"
                            international
                            countryCallingCodeEditable={false}
                            placeholder="Enter phone number"
                            name="Mobile"
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                            value={insuredMobileNumber || ""}
                            onChange={handlePhoneInputChangeInsured}
                            required
                            error={
                              insuredMobileNumber
                                ? isValidPhoneNumber(insuredMobileNumber)
                                  ? undefined
                                  : "Invalid phone number"
                                : "Phone number required"
                            }
                          />
                          {insuredMobileNumber ? (
                            isValidPhoneNumber(
                              insuredMobileNumber
                            ) ? undefined : (
                              <ErrorMessage message={"Invalid phone number"} />
                            )
                          ) : (
                            <></>
                          )}
                          {errors.insuredMobileNumber && (
                            <ErrorMessage
                              message={errors.insuredMobileNumber}
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row gap-10 w-full justify-between">
                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text
                              size="small-title"
                              label="State / Country of Residence"
                            />
                          </div>
                          <CountryStateDropdown
                            stateClick={setInsuredStateCountry}
                            data={statesData}
                          />
                          {errors.insuredStateCountry && (
                            <ErrorMessage
                              message={errors.insuredStateCountry}
                            />
                          )}
                        </div>
                        <div className="flex items-end mb-3 font-medium w-10"></div>
                        <div className="flex flex-col w-full">
                          <div className="pl-2">
                            <Text size="small-title" label="Date of Birth" />
                          </div>
                          <input
                            type="date"
                            placeholder="Date of Birth"
                            max={new Date().toISOString().split("T")[0]}
                            value={insuredDob}
                            onChange={(e) => setInsuredDob(e.target.value)}
                            className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                          />
                          {errors.insuredDob && (
                            <ErrorMessage message={errors.insuredDob} />
                          )}
                        </div>
                      </div>
                      {/* new business */}
                      {insuredNewClientType === "Business" ? (
                        <div className="flex flex-row gap-10 w-full justify-between">
                          <div className="flex flex-col w-full">
                            <div className="pl-2">
                              <Text size="small-title" label="Business Name" />
                            </div>
                            <input
                              type="text"
                              placeholder="Business Name"
                              value={insuredBusinessName}
                              onChange={(e) =>
                                setInsuredBusinessName(e.target.value)
                              }
                              className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                            />
                            {errors.insuredBusinessName && (
                              <ErrorMessage
                                message={errors.insuredBusinessName}
                              />
                            )}
                          </div>
                          <div className="flex items-end mb-3 font-medium w-10"></div>
                          <div className="flex flex-col w-full">
                            <div className="pl-2">
                              <Text size="small-title" label="Entity Type" />
                            </div>
                            <Dropdown
                              placeholder={"Entity Type"}
                              list={allDropdownList?.entityTypeList}
                              selectedValue={insuredEntityType}
                              onSelect={setInsuredEntityType}
                            />
                            {errors.insuredEntityType && (
                              <ErrorMessage
                                message={errors.insuredEntityType}
                              />
                            )}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  <div className="flex flex-row gap-10 w-full justify-between">
                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text size="small-title" label="Relationship" />
                      </div>
                      <Dropdown
                        placeholder={"Select"}
                        list={allDropdownList?.relationshipList || []}
                        selectedValue={insuredRelationship}
                        onSelect={setInsuredRelationship}
                      />
                      {errors.insuredRelationship && (
                        <ErrorMessage message={errors.insuredRelationship} />
                      )}
                    </div>
                    <div className="flex items-end mb-3 font-medium w-10"></div>

                    <div className="flex flex-col w-full"></div>
                  </div>
                </div>

                <div className="w-full h-[1px] bg-gray-300 mt-12"></div>

                <div className="text-gray-800 font-semibold text-xl leading-[24.2px] w-full text-start mt-9 mb-5">
                  Policy information
                </div>
                <div className="flex flex-col gap-6">
                  <div>
                    <Text size="small-title" label="Provider" />
                    <Dropdown
                      placeholder={"Select Provider"}
                      list={allDropdownList?.providerList || []}
                      selectedValue={policyProvider}
                      onSelect={setPolicyProvider}
                    />
                    {errors.policyProvider && (
                      <ErrorMessage message={errors.policyProvider} />
                    )}
                  </div>
                  <div>
                    <Text size="small-title" label="Policy Number" />
                    <input
                      type="text"
                      placeholder="Policy Number"
                      className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      value={policyNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[a-zA-Z0-9]*$/.test(value)) {
                          setPolicyNumber(value);
                        }
                      }}
                      pattern="[a-zA-Z0-9]*"
                    />
                    {errors.policyNumber && (
                      <ErrorMessage message={errors.policyNumber} />
                    )}
                  </div>
                  <div>
                    <Text size="small-title" label="Product" />
                    <Dropdown
                      placeholder={"Select"}
                      list={allDropdownList?.productList || []}
                      selectedValue={product}
                      onSelect={setProduct}
                      disable={!providerID}
                    />
                    {errors.product && (
                      <ErrorMessage message={errors.product} />
                    )}
                  </div>
                  {/* <div>
                    <Text size="small-title" label="Health Assessor" />
                    <Dropdown
                      placeholder={"Select"}
                      list={['health 1', 'health 2']}
                      selectedValue={healthAssessor}
                      onSelect={setHealthAssessor}
                    />
                    {errors.healthAssessor && (
                      <ErrorMessage message={errors.healthAssessor} />
                    )}
                  </div> */}
                  <div>
                    <Text size="small-title" label="Application Status" />
                    <Dropdown
                      placeholder={"New"}
                      list={allDropdownList?.applicationStatusList || []}
                      selectedValue={applicationStatus}
                      onSelect={setApplicationStatus}
                      disable={true}
                    />
                    {errors.applicationStatus && (
                      <ErrorMessage message={errors.applicationStatus} />
                    )}
                  </div>
                  <div>
                    <Text size="small-title" label="Date Submitted" />
                    <input
                      type="date"
                      placeholder="Date Submitted"
                      max={new Date().toISOString().split("T")[0]}
                      className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      value={dateSubmitted}
                      onChange={(e) => setDateSubmitted(e.target.value)}
                    />
                    {errors.dateSubmitted && (
                      <ErrorMessage message={errors.dateSubmitted} />
                    )}
                  </div>

                  <div className="flex flex-row justify-between gap-5">
                    <div className="w-full">
                      <Text size="small-title" label="Target Premium" />
                      <input
                        type="number"
                        placeholder="Value"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                        value={targetPremium}
                        onChange={(e) => setTargetPremium(e.target.value)}
                      />
                      {errors.targetPremium && (
                        <ErrorMessage message={errors.targetPremium} />
                      )}
                    </div>
                    <div className="w-full">
                      <Text size="small-title" label="Premium Mode" />
                      <Dropdown
                        placeholder={"Select"}
                        list={allDropdownList?.premiumModeList || []}
                        selectedValue={premiumMode}
                        onSelect={setPremiumMode}
                      />
                      {errors.premiumMode && (
                        <ErrorMessage message={errors.premiumMode} />
                      )}
                    </div>
                    <div className="w-full">
                      <Text size="small-title" label="Bank Draft Terms" />
                      <Dropdown
                        placeholder={"Select"}
                        list={allDropdownList?.bankDraftTermsList || []}
                        selectedValue={bankDraftTerms}
                        onSelect={setBankDraftTerms}
                      />
                      {errors.bankDraftTerms && (
                        <ErrorMessage message={errors.bankDraftTerms} />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row gap-6 flex-wrap mt-6">
                    <button
                      style={{
                        background:
                          "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                      }}
                      onClick={handleSubmit}
                      className="w-[11.625rem] h-[3.19rem] bg-transparent text-white text-sm font-semibold hover:text-white py-2 px-4 mr-4 border  rounded font-inter"
                    >
                      Save
                    </button>

                    <button
                      onClick={() => navigate("/applications")}
                      className="w-[11.625rem] h-[3.19rem] bg-transparent  text-sm font-semibold hover:text-white hover:bg-[#2BB574]  py-2 px-4 mr-4 border border-[#2BB574] text-[#2BB574]  rounded font-inter"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* right side Account about */}
            <div className="w-[30%] h-fit rounded-md border border-gray-300 p-6 bg-white">
              <div className="">
                <div className="text-gray-600 font-semibold text-base leading-[19.36px] ">
                  Accounts
                </div>
                <div className="w-full h-[1px] bg-gray-300 my-5"></div>
                <div className="text-gray-400 font-normal text-sm leading-[18px] mb-5">
                  All clients have an account for themselves and a parent
                  account
                </div>
                <div className="text-gray-500 font-semibold text-[13px] leading-[18px]">
                  Account:
                </div>
                <div className="text-gray-400 font-normal text-sm leading-[18px] mb-5">
                  An individual’s client account.
                </div>
                <div className="text-gray-500 font-semibold text-[13px] leading-[18px]">
                  Parent Account:
                </div>
                <div className="text-gray-400 font-normal text-sm leading-[18px] mb-5">
                  A group of individual accounts, for a household or business.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewApplication;
