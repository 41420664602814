import React from "react";

const Tabs = ({ renderTabContent, tabList, changeActiveTab, activeTab, className }) => {
  const handleTabClick = (tabNumber) => {
    changeActiveTab(tabNumber);
  };

  return (
    <>
      <div className={className}>
        <div className="flex">

            {tabList.map((tabNumber) => (
            <div
                key={tabNumber}
                className={`cursor-pointer p-4 ${
                activeTab === tabNumber
                    ? " text-blue-500 text-secondary font-inter text-lg font-semibold border-b-4 border-blue-700 inline-flex items-center p-2"
                    : "text-neutral-400 font-inter text-lg font-medium"
                }`}
                onClick={() => handleTabClick(tabNumber)}
            >
                {tabNumber}
            </div>
            ))}
        </div>
        <div className="mt-4">{renderTabContent()}</div>
      </div>
    </>
  );
};

export default Tabs;