import React, { useEffect, useRef, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { ReactComponent as SaveIcon } from "../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../src/assets/cancel.svg";
import { toast } from "react-toastify";
import useApi from "../../../custom-hooks/useApi";
import { BASE_URL } from "../../../appconfig";
import Spinner from "../../common/Spinner";

function FinancePolicy({ AccountID, newCreated, productList, newForm, setEdit }) {
  const api = useApi();
  const [policyList, setPolicyList] = useState([
  ]); 
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const dropdownRef = useRef(null);
  const editButtonRefs = useRef([]);
  const deleteButtonRefs = useRef([]);
  const [insuredList, setInsuredList] = useState([]);
    const [providerList, setProviderList] = useState([]);

  const handleDropdown = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleEdit = async (index, policiyItem) => {
    setEditIndex(index);
    setEdit();
    setEditData({
      ...policiyItem,
    });
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditData({});
  };

  const loadFinancePolicy = async () => {
    try {
        setLoading(true)
        const response = await api.get(`${BASE_URL}/Account/FinancialPolicy/${AccountID}`);
        if (response?.status === 200) {
            setPolicyList(response?.data?.data);
        }
        setLoading(false)

    } catch (error) {
        setLoading(false)
        // toast.error(error.message)
    }
}

const fetchClientsData = async () => {
    try {
        setLoading(true);
        const response = await api.get(`${BASE_URL}/Account/Related_People/${AccountID}`);
        if (response?.status === 200) {
            const result = response?.data?.data;
            setInsuredList(result)
        }
        setLoading(false)

    } catch (error) {
        setLoading(false)
    }

};

const fetchProvidersData = async () => {
    try {
        setLoading(true);
        const response = await api.get(`${BASE_URL}/Organization/Provider`
        );
        if (response?.status === 200) {
            setProviderList(response?.data?.provider)
        }
        setLoading(false)

    } catch (error) {
        setLoading(false)
    }
};


  const handleSaveEdit = async () => {
    try {
      setLoading(true);
      const response = await api.put(
        `${BASE_URL}/Account/FinancialPolicy/${editData?.ID}`, editData
      );
      if (response?.status === 200) {
        setEditIndex(null);
        setNewItem(!newItem);
        toast.success(response?.data?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log({ error });
      toast.error(error?.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleDelete = async (ID) => {
    try {
      setLoading(true);
      const response = await api.delete(
        `${BASE_URL}/Account/FinancialPolicy/${ID}`
      );
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setNewItem(!newItem);
        setDropdownIndex(null);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !editButtonRefs.current.some(ref => ref && ref.contains(event.target)) &&
        !deleteButtonRefs.current.some(ref => ref && ref.contains(event.target))
      ) {
        setDropdownIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    fetchClientsData();
    fetchProvidersData();
  },[])

  useEffect(()=>{
    handleCancelEdit();
  },[newForm])

  useEffect(()=>{
    loadFinancePolicy();
  },[newItem, newCreated])

  return (
    <tbody>
        {loading && <Spinner />}
      {policyList?.length > 0
        ? policyList.map((policiyItem, index) => (
            <tr
              key={`${policiyItem.name}-${index}`}
              className={`border-[#D1D5DB] ${
                index + 1 === policyList?.length ? "" : "border-b"
              }`}
            >
              <td className="p-2  text-sm text-[#3A4253] font-inter font-normal">
                {editIndex === index ? (
                  <select
                  name="InsuredID"
                  value={editData.InsuredID}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      InsuredID: e.target.value,
                    })
                  }
                  className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
              >
                  <option value="">Select Insured Person</option>
                  {
                      insuredList?.length > 0 ? (
                          <>
                              {
                                  insuredList?.map((insuredItem) => (
                                      <option
                                          key={insuredItem?.ContactID} value={insuredItem?.RelatedPersonId} >
                                          {insuredItem?.ContactDetails?.First_Name + " " + insuredItem?.ContactDetails?.Last_Name}
                                      </option>
                                  ))
                              }
                          </>
                      ) : null
                  }
              </select>
                ) : (
                  policiyItem?.RelatedPersonDetails?.ContactDetails
                    ?.First_Name +
                  " " +
                  policiyItem?.RelatedPersonDetails?.ContactDetails?.Last_Name
                )}
              </td>
              <td className="p-2  text-sm text-[#3A4253] font-inter font-normal">
                {editIndex === index ? (
                   <select
                   name="ProductTypeID"
                   value={editData.ProviderID}
                   onChange={(e) =>
                    setEditData({
                      ...editData,
                      ProviderID: e.target.value,
                    })
                  }
                   className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
               >
                   {
                       providerList?.length > 0 ? (
                           <>
                               {
                                   providerList?.map((productItem) => (
                                       <option key={productItem?.ProviderID} value={productItem?.ProviderID}>{productItem?.ProviderName?.slice(0, 20)}</option>
                                   ))
                               }
                           </>
                       ) : null
                   }
               </select>
                ) : (
                  policiyItem?.ProviderDetails?.ProviderName
                )}
              </td>
              <td className="p-2 text-sm text-[#3A4253]  font-inter font-normal">
                {editIndex === index ? (
                  <select
                  name="ProductTypeID"
                  value={editData.ProductTypeID}
                  onChange={(e) =>
                    setEditData({
                      ...editData,
                      ProductTypeID: e.target.value,
                    })
                  }
                  className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
              >
                  {
                      productList?.length > 0 ? (
                          <>
                              {
                                  productList?.map((productItem) => (
                                      <option key={productItem?.ProductTypeID} value={productItem?.ProductTypeID}>{productItem?.ProductTypeName?.slice(0, 20)}</option>
                                  ))
                              }
                          </>
                      ) : null
                  }
              </select>
                ) : (
                  policiyItem?.ProductTypeDetails?.ProductTypeName
                )}
              </td>
              <td className="p-2 text-sm text-[#3A4253]  font-inter font-normal">
                {editIndex === index ? (
                 <div className="flex gap-2 ">
                 <input
                   type="text"
                   name="PremiumAmount"
                   value={editData.PremiumAmount}
                   onChange={handleInputChange}
                   className="w-full appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                 />
               </div>
                ) : (
                   `$ ${policiyItem?.PremiumAmount}`
                )}
              </td>
              <td className="p-2 text-sm text-[#3A4253]  font-inter font-normal">
                {editIndex === index ? (
                  <div className="flex gap-2 ">
                  <input
                    type="text"
                    name="CashValue"
                    value={editData.CashValue}
                    onChange={handleInputChange}
                    className="w-full appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                  />
                </div>
                ) : (
                     `$ ${policiyItem?.CashValue}`
                )}
              </td>
              <td className="p-2 text-sm text-[#3A4253]  font-inter font-normal">
                {editIndex === index ? (
                 <div className="flex gap-2 ">
                 <input
                   type="text"
                   name="DeathBenefit"
                   value={editData.DeathBenefit}
                   onChange={handleInputChange}
                   className="w-full appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                 />
               </div>
                ) : (
                  `$ ${policiyItem?.DeathBenefit}`
                )}
              </td>
              <td ref={(el) => (dropdownRef.current = el)} className="relative p-4   font-medium text-[#4871B7] cursor-pointer">
                {editIndex === index ? (
                  <div className="flex  gap-2">
                    <button
                      className="disabled:opacity-50 disabled:cursor-not-allowed"
                      onClick={handleSaveEdit}
                    >
                      <SaveIcon />
                    </button>
                    <CancelIcon
                      className="cursor-pointer"
                      onClick={handleCancelEdit}
                    />
                  </div>
                ) : (
                  <>
                    <FiMoreHorizontal onClick={() => handleDropdown(index)} />
                    {dropdownIndex === index && (
                      <div className="absolute right-0 mt-2 w-48 bg-white">
                        <div className="absolute z-10 w-auto bg-white border rounded shadow-lg  mr-14 right-0">
                          <ul className="border rounded text-gray-600">
                            <li
                              className="edit-button border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                              onClick={() => handleEdit(index, policiyItem)}
                              ref={(el) => editButtonRefs.current[index] = el}
                            >
                              Edit
                            </li>
                            <li
                              onClick={() =>
                                handleDelete(policiyItem?.ID)
                              }
                              ref={(el) => deleteButtonRefs.current[index] = el}
                              className="delete-button border-0 rounded px-4 pb-1 cursor-pointer hover:bg-green-500 hover:text-white"
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))
        : null}
    </tbody>
  );
}

export default FinancePolicy;
