import React, { useEffect, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa6';
import { BASE_URL } from '../../../appconfig';
import useApi from "../../../custom-hooks/useApi";
import Spinner from '../../common/Spinner';
import FinancePolicyForm from './FinancePolicyForm';
import FinancialAccount from './FinancialAccount';
import FinanceAccountForm from './FinanceAccountForm';
import FinancialPolicy from './FinancialPolicy';

import { ReactComponent as PlusIcon } from "../../../../src/assets/plus-square.svg";


function FinancialTab({ AccountID, clients, ContactID }) {
    const api = useApi()
    const [accountList, setAccountList] = useState([
    ]);
    const [loading, setLoading] = useState(false)
    const [isFinancePolicyOpen, setIsFinancePolicyOpen] = useState(true);
    const [isFinanceAccountOpen, setIsFinanceAccountOpen] = useState(true);
    const [newFinancePolicyForm, setNewFinancePolicyForm] = useState(false);
    const [newFinanceAccountForm, setNewFinanceAccountForm] = useState(false);
    const [relationshipList, setRelationshipList] = useState([])
    const [relations, setRelations] = useState([
    ]);
    const [newForm, setNewForm] = useState(false);
    const [newPolicy, setNewPolicy] = useState(false);
    const [newAdded, setNewAdded] = useState(false);
    const [productList, setProductList] = useState([]);


    const handleCloseFinanceAccountForm = () => {
        setNewFinanceAccountForm(false);
    }

    const handleCloseFinancialPolicyForm = () => {
        setNewFinancePolicyForm(false);
    }
    
    const handleNewForm = () => {
        setNewFinanceAccountForm(!newFinanceAccountForm);
        setNewForm(!newForm);
    }
    const handleNewPolicy = () => {
        setNewFinancePolicyForm(!newFinanceAccountForm);
        setNewPolicy(!newPolicy);
    }

    useEffect(() => {
        loadFinanceAccount();
        // fetchEntities()

        loadProductType()
        loadRelations()
        loadRelatedPeople()
    }, [])

    const handleNew = () => {
        setNewAdded(!newAdded);
    }

    const loadFinanceAccount = async () => {
        try {
            setLoading(true)
            const response = await api.get(`${BASE_URL}/Account/FinancialAccount/${AccountID}`);
            if (response?.status === 200) {
                setAccountList(response?.data?.data);
            }
            setLoading(false)

        } catch (error) {
            setLoading(false)
            // toast.error(error.message)
        }
    }

    // const loadFinancePolicy = async () => {
    //     try {
    //         setLoading(true)
    //         const response = await api.get(`${BASE_URL}/Account/FinancialPolicy/${AccountID}`);
    //         if (response?.status === 200) {
    //             setPolicyList(response?.data?.data);
    //         }
    //         setLoading(false)

    //     } catch (error) {
    //         setLoading(false)
    //         // toast.error(error.message)
    //     }
    // }

    const loadProductType = async () => {
        try {
            setLoading(true);
            const response = await api.get(`${BASE_URL}/Organization/ProductTypes`
            );
            if (response?.status === 200) {
                setProductList(response?.data?.data.result)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    };

    const loadRelations = async () => {
        try {
            setLoading(true);
            const response = await api.get(`${BASE_URL}/static/relations`);
            if (response?.status === 200) {
                setRelationshipList(response?.data?.data);
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const loadRelatedPeople = async () => {
        try {
            setLoading(true)
            const response = await api.get(`${BASE_URL}/Account/Related_People/${AccountID}`);
            if (response?.status === 200) {
                setRelations(response?.data?.data);
            }
            setLoading(false)

        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <div className='p-4'>
            {
                loading && <Spinner />
            }
            <div className="border border-[#D1D5DB] rounded-lg p-4  mb-4">
                <div className="flex justify-between">
                    <button
                        className="flex  justify-between w-full py-2 text-left text-lg font-medium text-gray-700 focus:outline-none"
                    >
                        <div className='flex'>
                            <span onClick={handleNewPolicy} 
                             className='mr-2 mt-1 text-[#334155]'
                            ><FaAngleDown /> </span>
                            <span className="text-[1F2937]  font-inter  font-semibold text-base">Policies</span>
                        </div>
                    </button>

                    <button
                        onClick={handleNewPolicy}
                        className="text-[#4871B7] w-[150px] bg-transparent text-sm font-semibold  py-2 px-4 rounded font-inter focus:outline-none focus:ring-0 border-0 flex items-center"
                    >
                        <PlusIcon className='pr-1'/>
                        Add new
                    </button>

                </div>

                {isFinancePolicyOpen && (
                    <div className="">
                        {
                            loading && <Spinner />
                        }
                        <table className="w-full policy-table">
                            <thead className="border-b border-[#D1D5DB]">
                                <tr>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Insured
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Provider
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Product Type
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Premium
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Cash Value
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Death Benefit
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                    </td>
                                </tr>
                                {
                                    newFinancePolicyForm ? (
                                        <FinancePolicyForm ContactID={ContactID} productList={productList} relations={relations} clients={clients} AccountID={AccountID} toggle={handleCloseFinancialPolicyForm} onSaveSuccess={handleNew} />
                                    ) : null
                                }
                            </thead>
                           <FinancialPolicy AccountID={AccountID} newCreated={newAdded} productList={productList} newForm={newPolicy} setEdit={handleCloseFinancialPolicyForm}/>
                        </table>
                    </div>
                )}
            </div>

            <div className="border border-[#D1D5DB] rounded-lg p-4  mb-4">
                <div className="flex justify-between">
                    <button
                        className="flex  justify-between w-full py-2 text-left text-lg font-medium text-gray-700 focus:outline-none"
                    >
                        <div className='flex'>
                            <span className='mr-2 mt-1'
                                onClick={handleNewForm}
                            ><FaAngleDown /> </span>
                            <span className="text-[1F2937]  font-inter  font-semibold text-base" >Financial Accounts</span>
                        </div>
                    </button>

                    <button
                        onClick={handleNewForm}
                        className="text-[#4871B7] w-[150px] bg-transparent text-sm font-semibold  py-2 px-4 rounded font-inter focus:outline-none focus:ring-0 border-0 flex items-center"
                    >
                        <PlusIcon className='mr-1' />
                        Add New
                    </button>
                </div>

                {isFinanceAccountOpen && (
                    <div className="">
                        {
                            loading && <Spinner />
                        }
                        <table className="w-full ">
                            <thead className="border-b border-[#D1D5DB]">
                                <tr>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Financial Account
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Account Type
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Contribution
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Balance
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                        Managed By
                                    </td>
                                    <td className="p-4 text-sm font-inter font-medium text-[#9CA3AF]">
                                    </td>
                                </tr>
                                {
                                    newFinanceAccountForm ? (
                                        <FinanceAccountForm productList={productList} AccountID={AccountID} relationShipList={relationshipList} toggle={handleCloseFinanceAccountForm} onSaveSuccess={loadFinanceAccount} />
                                    ) : null
                                }
                            </thead>
                            <FinancialAccount AccountID={AccountID} productList={productList} relationShipList={relationshipList} accountList={accountList} reloadItems={loadFinanceAccount} newForm={newForm} setEdit={handleCloseFinanceAccountForm}/>
                        </table>
                    </div>
                )}
            </div>





        </div>
    )

}

export default FinancialTab;
