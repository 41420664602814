import React, { useState } from "react";
import loginCover from "../../../assets/loginCover.png";
import Brand from "../../../assets/blackLogo.png";
import { ReactComponent as Icon2 } from "../../../assets/loginCover2.svg";
import google from "../../../assets/google.svg";
import ms from "../../../assets/microsoft.svg";
import Spinner from "../Spinner";
import { BASE_URL, FRONT_URL } from "../../../appconfig";
import useApi from "../../../custom-hooks/useApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  setAuthToken,
  setRefreshToken,
  setFullName,
  setRole,
  setUser,
  setPremiumInfo,
} from "../../../redux/slices/authSlice";
import { useDispatch } from "react-redux";

function NewLogin() {
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    const json = {
      Email: email,
      Password: password,
    };
    try {
      setLoading(true);
      const response = await api.post(`${BASE_URL}/auth/email-login`, json);
      if (response.status === 200 && response.data.data.accessToken) {
        console.log({ response });

        toast.success("Logged In successfully", {
          theme: "dark",
        });
        dispatch(setAuthToken(response.data.data.accessToken));
        dispatch(setRefreshToken(response.data.data.refreshToken));
        dispatch(
          setFullName(
            response.data.data.user.First_name +
            " " +
            response.data.data.user.Last_name
          )
        );
        let user = localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : {};

        let premiumInfo = localStorage.getItem("premiumInfo")
        dispatch(
          setUser({
            ...user,
            id: response.data.data.user.id,
            roleid: response.data.data.user.roleid,
            FirstName: response.data.data.user.First_name,
            LastName: response.data.data.user.Last_name,
            organizationId: response.data.data.user.organizationId,
            BaseShopID: response.data.data.user.BaseShopID,
            email: response.data.data.user.email,
            SignedInUsingEmail: response.data.data.user.SignedInUsingEmail,
            ImageName: response.data.data.user.ImageName,
            AgentID: response.data.data.user.AgentCode,
          }));

        dispatch(setPremiumInfo({
          ...premiumInfo,
          isUpgraded: response?.data?.data?.premiumInfo?.isUpgraded,
          isExpired: response?.data?.data?.premiumInfo?.isExpired
        })

        )
        dispatch(setRole(response.data.data.user.Role));
        const id = response?.data?.data?.user?.id;
        navigate(`/profile/${id}`);
      } else {
        const errorMessage = response?.data?.error || "Failed to log in";
        toast.error(errorMessage, {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message, {
        theme: "dark",
      });
      console.error("Error updating data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSIgnUp = () => {
    setLoading(true);
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  const handleForgot = () => {
    setLoading(true);
    setTimeout(() => {
      navigate("/forgot-password");
    }, 500);
  };

  const loginWithRedirect = (provider) => {
    setLoading(true);
    try {
      window.location.href = `${BASE_URL}/auth/login?url=${FRONT_URL}&provider=${provider}`;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen">
      {loading && <Spinner />}
      <div className="w-1/2 bg-white flex flex-row justify-center items-center">
        <div className="flex flex-col items-center px-5 py-5">
          {/* Brand Logo */}
          <img src={Brand} alt="brandlogo" className="w-[40%] mb-5" />

          <div className="w-[30%] flex items-center justify-between mb-5 px-5 ms-3">
            <button
              className="text-[#2BB574] font-bold hover:text-[#2BB574] hover:border-transparent relative"
              style={{ borderBottom: "3px solid #2BB574", paddingBottom: "4px" }}
            >
              Login
            </button>
            <button
              onClick={handleSIgnUp}
              className="ml-4 mb-2 text-[#6B7280] font-bold hover:text-[#6B7280] hover:border-transparent"
            >
              Signup
            </button>
          </div>

          {/* Login with Google */}
          <div className="w-[40%] flex justify-center items-center flex-row xl:flex-col gap-3 mb-2">
            <div
              onClick={() => loginWithRedirect("google")}
              className="w-full flex border rounded py-2 text-gray-700 text-base font-bold  cursor-pointer justify-center items-center"
            >
              <div className="flex flex-row xl:mr-4 gap-3">
                <img src={google} alt="Google logo" className="w-6 xl:h-6 h-5 " />
                <span className="xl:flex hidden">Log in with Google</span>
              </div>
            </div>

            {/* Login with Microsoft */}
            <div
              onClick={() => loginWithRedirect("microsoft")}
              className="w-full flex border rounded py-2 text-gray-700 text-base font-bold  cursor-pointer justify-center items-center"
            >
              <div className="flex flex-row gap-3">
                <img src={ms} alt="Microsoft logo" className="w-5 h-5" />
                <span className="xl:flex hidden">Log in with Microsoft</span>
              </div>

            </div>
          </div>
          <div className="m-4 text-gray-700 text-sm font-semibold">OR</div>
          <form className="w-[40%]" onSubmit={handleLogin}>
            <div className="mb-4">
              <label
                className="block text-gray-400 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none"
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                required
              />
            </div>
            <div className="relative mb-6">
              <label
                className="block text-gray-400 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                className="shadow appearance-none bg-gray-100 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-none pr-10"
                id="password"
                name="password"
                type={passwordType}
                placeholder="********"
                required
              />
              <div className="absolute inset-y-0 right-0 mt-8 flex items-center pr-3">
                <div onClick={togglePassword} className="cursor-pointer">
                  {passwordType === "password" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-eye-slash"
                      viewBox="0 0 16 16"
                      fill="#3868b6"
                    >
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                      <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-eye"
                      viewBox="0 0 16 16"
                      fill="#3868b6"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between mb-4">
              <label className="flex items-center text-gray-400">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-500"
                />
                <span className="ml-2 text-sm">Remember me</span>
              </label>

              <p
                onClick={handleForgot}
                className="cursor-pointer text-sm text-blue-500 hover:text-gray-600"
              >
                Forgot password?
              </p>
            </div>

            <div className="flex items-center justify-between">
              <button
                style={{
                  background:
                    "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                  height: "2.6rem",
                }}
                className="text-white w-full py-2 px-3 font-inter"
                type="submit"
              >
                Log In
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-1/2 relative bg-black flex justify-center items-center">
        {/* Overlay SVG */}
        <div className="relative w-full h-full">
          <img
            src={loginCover}
            alt="Login Cover"
            className="absolute inset-0 w-full h-full object-cover"
          />
          <Icon2 className="absolute bottom-0 inset-0 w-full h-full" />
        </div>
      </div>
    </div>
  );
}

export default NewLogin;
