import React, { useState } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import Promotions from './Tabs/promotion/Promotions';
import { useSelector } from 'react-redux';
import Profile from './Tabs/Profile/Profile';
import CampaignActivation from './Tabs/CampaignActivation/CampaignActivation';
import Tabs from './Tabs/Tabs';
import SideNav from './SideNav';
import Performance from './Tabs/Performance/Performance';

const BackOfficeTopBar = () => {

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const Fullname = useSelector((state) => state.auth.fullName);
  const user = useSelector((state) => state.auth.user);
  const back = useSelector((state) => state.agent.backOffice);
  const [activeTab, setActiveTab] = useState("Performance");

  const tabList = [
    "Performance",
    "Badges",
    "Promotions",
    "Approvals",
  ];

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
  }

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const getAgentRootId = () => {
    return user.AgentID;
  };

  const renderTabContent = () => {
    const agentRootId = getAgentRootId();
        switch (activeTab) {
          case "Performance":
            return <Performance/>;
          // case "Profile":
          //   if (agentRootId) {
          //     return (
          //       <Profile
          //         agentRootId={agentRootId}
          //         className={`bg-white border border-gray-300 rounded-lg px-4 py-4 m-6`}
          //       />
          //     );
          //   } else {
          //     return <div></div>;
          //   }
          case "Badges":
            return <div></div>;
          case "Promotions":
            return <Promotions />;
          case "Approvals":
            return <CampaignActivation agentRootId={agentRootId} />;
          default:
            return null;
        }
  };

  return (
    <>
      <div className="flex">
        <div>
          <SideNav
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div className="w-full bg-gray-100">
          <div className="flex">
            <div className="px-[11.70px] py-4 mt-2">
              {/* <HamburgerMenu
                isOpen={isSidebarOpen}
                menuClicked={toggleSidebar}
                width={22}
                height={15}
                strokeWidth={2}
                rotate={0}
                color="#4971B8"
                borderRadius={0}
                className="cursor-pointer"
                animationDuration={0.5}
              /> */}
            </div>
            <div className="py-2 mt-2 w-full font-inter text-4xl font-semibold leading-9 tracking-normal text-left">
            Back Office
              {/* {user && user.TeamName
                ? `${user.TeamName}'s Base Shop`
                : "Back Office"}{" "} */}
              {/* <span className="text-base">
                ({back.name ? back.name : Fullname})
              </span> */}
            </div>
          </div>
          <Tabs
            renderTabContent={renderTabContent}
            tabList={tabList}
            changeActiveTab={changeActiveTab}
            activeTab={activeTab}
            className={`flex flex-col ml-2`}
          />
        </div>
      </div>
    </>
  );
};

export default BackOfficeTopBar;
