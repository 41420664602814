import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';

const DateTimePicker = ({ handleTime }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const currentDate = new Date();

  useEffect(() => {
    handleTime(formatDateString(selectedDate));
  }, [selectedDate]);

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const formatDateString = (dateString) => {
    if (!dateString) {
      return null;
    }
    const dateObject = new Date(dateString);
    const utcDate = new Date(dateObject.toUTCString());
    const year = utcDate.getUTCFullYear();
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = utcDate.getUTCDate().toString().padStart(2, '0');
    const hours = utcDate.getUTCHours().toString().padStart(2, '0');
    const minutes = utcDate.getUTCMinutes().toString().padStart(2, '0');
    const seconds = utcDate.getUTCSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const customInput = (
    <div className="px-3 py-2 items-center appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white">
      {selectedDate ? (
        <span className="flex justify-center items-center">
          <span className="font-semibold mr-3 text-base">scheduled : </span>
          <FaCalendarAlt style={{ color: '#4971B8' }} className="mr-2" />
          <span className="text-neutral-400">{`${formatDate(selectedDate)}`} </span>
          <FaClock style={{ color: '#4971B8' }} className="ml-3 mr-2" />
          <span className="text-neutral-400">{`${selectedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' })}`}</span>
        </span>
      ) : (
        <span className="text-neutral-500 font-normal">Select Date and Time</span>
      )}
    </div>
  );

  return (
    <div className="relative">
      <DatePicker
        isClearable
        selected={selectedDate}
        onChange={handleDateChange}
        showTimeSelect
        minDate={currentDate}
        dateFormat="dd/MM/yyyy hh:mm aa zzz"
        customInput={customInput}
        placeholderText="Select Date and Time"
        className="w-full"
      />
    </div>
  );
};

export default DateTimePicker;
