import { useEffect, useState, useRef } from "react";
import useApi from "../../custom-hooks/useApi";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setAuthToken, setAuthenticated, setRefreshToken, setUser } from "../../redux/slices/authSlice";
import Spinner from "../common/Spinner";


function BaseShopSearch() {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredApiData, setFilteredApiData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const api = useApi();
  const role = useSelector((state) => state.auth.role);
  const adminRoles = ["super_admin"];
 const dropdownRef = useRef(null);
 const user = useSelector((state) => state.auth.user);
 const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
const refreshToken = useSelector((state) => state.auth.refreshToken);

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const response = await api.get(`/Organization/BaseShop`);
        setApiData(response.data.response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setApiData([]);
      }
    };
    if (role && adminRoles.includes(role)) {
      fetchApiData();
    } else {
      console.log("API call conditions not met. Skipping API call.");
    }
  }, [api]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsSearchVisible(false)
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  },[] );

  useEffect(() => {
    const filteredData = apiData.filter((item) =>
      item.AgentID.toString().toUpperCase().includes(searchQuery.toUpperCase())
    );

    setFilteredApiData(filteredData);
  }, [searchQuery, apiData]);

   useEffect(() => {
    const storedAgentID = localStorage.getItem("selectedAgentID");
     if (storedAgentID) {
       setSearchQuery(storedAgentID);
     }
   }, []);

  const performShopSwitch = async (e) => {
    let newBaseShopID = e.target.getAttribute("data-baseshop-id") || "";
  
    if (newBaseShopID !== user.BaseShopID) {
    
      localStorage.setItem("selectedAgentID", newBaseShopID);
  
      
      changeBaseShop(role, newBaseShopID, refreshToken);
    }
  };
  

  const changeBaseShop = async (role, newBaseShopID, refreshToken) => {
    setLoading(true);
    try {
      if (role && adminRoles.includes(role) && newBaseShopID && refreshToken) {
        let reqBody = {
          BaseShopID: newBaseShopID,
          refreshToken: refreshToken,
        };
        const response = await api.post(`/auth/NewBaseShopToken/`, reqBody);
        if (response.status === 200) {
          toast.success("BaseShopId changed successfully.", { theme: "dark" });
          dispatch(setAuthenticated(true));
          dispatch(setAuthToken(response.data.accessToken));
          dispatch(setRefreshToken(response.data.refreshToken));
          dispatch(setUser({ ...user, BaseShopID: newBaseShopID }));
          setTimeout(() => {
            setLoading(false);
            window.location.assign("/");
          }, 2000);
        }
      } else {
        let errorMsg = "";
        if (!role || !adminRoles.includes(role)) {
          errorMsg += "BaseShopId can be changed only by Super Admin. ";
        } else if (!newBaseShopID || !refreshToken) {
          errorMsg += "BaseShopId and RefreshToken must be provided. ";
        } else {
          errorMsg += "Request cannot be processed.";
        }
        toast.error(errorMsg, {
          theme: "dark",
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message, {
        theme: "dark",
      });
    }
  };

  return (
    <div>
       {loading && <Spinner />}
       <div className="flex "> 
       <label className=" capitalize pt-1 text-xl text-white font-semibold">
            {user !== null && user !== undefined
              ? "BaseShopID - " 
              : "BaseShopID"}
          </label>
        <div className="relative" ref={dropdownRef}>
       
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 absolute left-0 top-1/2 transform -translate-y-1/2 ml-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        
          <input
           value={searchQuery}
            type="text"
            placeholder="BaseShopID"
            style={{
              backgroundColor: "#E5E7EB",
              width: "11rem",
              height: "2.5rem",
              borderRadius: "0.5rem",
              paddingLeft: "2.5rem", // Adjust for icon space
            }}
            className="p-2 focus:outline-none"
            onChange={(e) => setSearchQuery(e.target.value)}
            onClick={()=> setIsSearchVisible(true)}
          />
        </div>
    <div className="w-40 absolute left-32 top-10 max-h-48 mt-2 overflow-y-auto bg-slate-100" style={{ zIndex: 9999999999999 }}>
      {filteredApiData.map((item) => (
  isSearchVisible && <div
    key={item.AgentID}
    className={`font-inter flex items-center align-middle bg-dropdown-item ${
      item.AgentID.toString().toUpperCase() ===
      user.BaseShopID.toString().toUpperCase()
        ? "active"
        : "cursor-pointer"
    }`}
    onClick={() => {
      setSearchQuery(item.AgentID);
      performShopSwitch({
        target: {
          getAttribute: (attr) => (attr === "data-baseshop-id" ? item.AgentID : null),
        },
      });
    }}
  >
    <span
      style={{
        width: "120px",
        color: "black",
        fontSize: "1rem",
        fontWeight: "400",
        padding: "0.72rem 1rem",
        display: "block",
      }}
      data-baseshop-id={item.AgentID}
    >
      {`${item.AgentID}`}
    </span>
  </div>
))}

      </div>
      </div>
    </div>
  );
}

export default BaseShopSearch;










































