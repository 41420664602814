// TableHeader.js
import React from "react";

const TableHeader = ({ headings, sortColumns, onSortChange, checked, onCheck }) => {

  const columnsWithoutSort = [
    " ",
  ];

  return (
    <thead style={{ backgroundColor: "#E5E7EB" }}>
      <tr>
        <td className="px-3 py-3">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={checked}
              onChange={onCheck}
              className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
          </div>
        </td>
        {headings.map((heading, index) => (
          <th
            key={index}
            scope="col"
            className={`py-3 font-inter ${
              index === 0 || index === 1 ? "px-1" : "px-3"
            }`}
          >
            {columnsWithoutSort.includes(heading) ? (
              // No text, render SVG icon
              <>{heading}</>
            ) : (
              // Render text and SVG icon
              <>
                <div
                  onClick={() => onSortChange(heading)}
                  className={`inline-flex items-center cursor-pointer`}
                >
                  <span className="inline-flex gap-2 items-center justify-center">
                    {heading}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`ml-1 z-10 ${
                        heading.split(" ").length > 1 ? "w-4 h-4" : "w-4 h-4"
                      } sort-icon ${
                        sortColumns[heading]?.sortOrder === "asc"
                          ? "rotate-180"
                          : ""
                      }`}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </div>
              </>
            )}
          </th>
        ))}
        <th className="px-3 py-3"></th>
      </tr>
    </thead>
  );
};

export default TableHeader;
     