import React, { useEffect, useState } from "react";
import { ReactComponent as PauseIcon } from "../../../../assets/pausebutton.svg";
import { ReactComponent as StartIcon } from "../../../../assets/playbutton.svg";
import { ReactComponent as StopIcon } from "../../../../assets/stop-button.svg";
import { ReactComponent as ReloadIcon } from "../../../../assets/reload.svg";
import useApi from "../../../../custom-hooks/useApi";
import { toast } from "react-toastify";
import Spinner from "../../../common/Spinner";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { FiPauseCircle } from "react-icons/fi";
import { IoCloseSharp } from "react-icons/io5";

const CampaignStatus = ({ campaign, statusList, refreshCurrentCampaign }) => {
  const pausedStatusId = statusList.find(
    (s) => s.Name.toString().toLowerCase() === "paused"
  )
    ? statusList.find((s) => s.Name.toString().toLowerCase() === "paused")[
        "ID"
      ] === campaign.Campaign_StatusID
    : false;
  const closedStatusId = statusList.find(
    (s) => s.Name.toString().toLowerCase() === "closed"
  )
    ? statusList.find((s) => s.Name.toString().toLowerCase() === "closed")[
        "ID"
      ] === campaign.Campaign_StatusID
    : false;
    const activeStatusId = statusList.find(
      (s) => s.Name.toString().toLowerCase() === "active"
    )
      ? statusList.find((s) => s.Name.toString().toLowerCase() === "active")[
          "ID"
        ] === campaign.Campaign_StatusID
      : false;

 
  const [isPaused, setIsPaused] = useState(pausedStatusId);
  const [isClosed, setIsClosed] = useState(closedStatusId);
  const [isActive, setIsActive] = useState(activeStatusId);

  const [loading, setIsLoading] = useState(false);
  const api = useApi();

  useEffect(() => {

    let pausedStatusId = statusList.find(
      (s) => s.Name.toString().toLowerCase() === "paused"
    )
      ? statusList.find((s) => s.Name.toString().toLowerCase() === "paused")[
          "ID"
        ] === campaign.Campaign_StatusID
      : false;
    let closedStatusId = statusList.find(
      (s) => s.Name.toString().toLowerCase() === "closed"
    )
      ? statusList.find((s) => s.Name.toString().toLowerCase() === "closed")[
          "ID"
        ] === campaign.Campaign_StatusID
      : false;
      let activeStatusId = statusList.find(
        (s) => s.Name.toString().toLowerCase() === "active"
      )
        ? statusList.find((s) => s.Name.toString().toLowerCase() === "active")[
            "ID"
          ] === campaign.Campaign_StatusID
        : false;

      setIsPaused(pausedStatusId);
      setIsClosed(closedStatusId);
      setIsActive(activeStatusId);

  }, [campaign, statusList])

  const togglePlay = async () => {
    const isUpdated = await updateCampaignStatus("active");
  };

  const togglePause = async () => {
    const isUpdated = await updateCampaignStatus("paused");
    if (isUpdated) {
      setIsPaused((prevPaused) => !prevPaused);
    }
  };

  const toggleClose = async () => {
    const isUpdated = await updateCampaignStatus("closed");
       if (isUpdated) {
         setIsClosed((prevPaused) => !prevPaused);
       }
  }

  const updateCampaignStatus = async (statusName) => {
    let isUpdated = false;
    setIsLoading(true);
    try {
      const status = statusList.find(
        (status) =>
          status.Name.toString().toLowerCase() ===
          statusName.toString().toLowerCase()
      );
      if (!status) {
        throw new Error("Unknown Campaign Status: ", statusName);
      }
      const requestBody = {
        Campaign_StatusID: status.ID,
      };

      const response = await api.put(
        `/Campaign/${campaign.ID}/UpdateStatus`,
        requestBody
      );

      if (response.status === 200 && response.data.success && response.data.campaign) {
        isUpdated = true;
        toast.success(
          response.data.message || "Campaign Updated Successfully",
          { theme: "dark" }
        );
        refreshCurrentCampaign(response.data.campaign);
      }
    } catch (error) {
      console.error(error.message);
      toast.error(error.response.data.message || "Campaign Update Failed", {
        theme: "dark",
      });
    } finally {
      setIsLoading(false);
    }
    return isUpdated;
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="flex gap-4 ">
       {campaign.Campaign_StatusID === 31 && ( <div
          onClick={togglePlay}
          title={"Activate Campaign"}
          className={`bg-yellow-300  rounded-full flex items-center py-2 px-5 cursor-pointer`}
          disabled={!isActive}
        >
          <span className="text-white mr-1 mt-[0.056rem] text-lg">
            <AiOutlinePlayCircle />
          </span>
          <span className="text-white">Resume</span>
        </div>)}

        {campaign.Campaign_StatusID === 29 && (<div
          onClick={togglePause}
          title={"Pause Campaign"}
          className={`bg-orange-500 rounded-full flex items-center py-2 px-5 cursor-pointer`}
        >
          <span className="text-white mr-1  mt-[0.056rem] text-lg">
            <FiPauseCircle />
          </span>
          <span className="text-white">Sending... Click to Pause</span>
        </div>)}

        {campaign.Campaign_StatusID === 32 && (
          <div
            onClick={toggleClose}
            title={"Stop Campaign"}
            className={`bg-green-600 rounded-full flex items-center py-2 px-5 cursor-pointer`}
            disabled={!isClosed}
          >
            <span className="text-white mr-2 mt-[0.056rem] text-lg">
              <IoCloseSharp />
            </span>
            <span className="text-white">Success</span>
          </div>
        )}
      </div>
    </>
  );
};

export default CampaignStatus;
