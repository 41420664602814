import React, { useEffect, useState } from "react";
import addIcon from "../../../../assets/add-round.svg";
import useApi from "../../../../custom-hooks/useApi";
import Spinner from "../../../common/Spinner";
import CreateCampaignPopup from "./CreateCampaignPopup";
import CampaignTreeView from "./CampaignTreeView";
import ToggleButton from "../../../common/ToggleButton";
import campaignsSvg from "../../../../assets/campaginBinocular.svg";
import downArrow from "../../../../assets/downarrow.svg";
import { useNavigate } from "react-router-dom";

function MyCampaigns({
  status,
  campaignClick,
  number,
  name,
  isSidebarOpen,
  setFirstCampaign,
}) {
  const navigate = useNavigate();
  const [campaignGroupList, setCampaignGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTapPopupOpen, setIsTapPopupOpen] = useState(false);
  const [changed, setChanged] = useState(false);
  const [campCreated, setCampCreated] = useState(null);
  const [showInactiveGroups, setShowInactiveGroups] = useState(false);
  const [expandedState, setExapandedState] = useState({});
  const api = useApi();
  const [isArrowUp, setIsArrowUp] = useState(false);

  const toggleArrow = () => {
    setIsArrowUp(!isArrowUp);
  };

  const updateExpandedState = (obj) => {
    setExapandedState(obj);
  };

  const updateToggleGroupsState = (value) => {
    setShowInactiveGroups(value);
  };

  const toggleTapPopup = () => {
    setIsTapPopupOpen(!isTapPopupOpen);
  };

  const toggleChange = () => {
    setChanged(!changed);
  };

  const newCampaign = (ID) => {
    setCampCreated(ID);
  };

  const groupBy = (values, keyFinder) => {
    // using reduce to aggregate values
    return values.reduce((a, b) => {
      // depending upon the type of keyFinder
      // if it is function, pass the value to it
      // if it is a property, access the property
      const key = typeof keyFinder === "function" ? keyFinder(b) : b[keyFinder];

      // aggregate values based on the keys
      if (!a[key]) {
        a[key] = [b];
      } else {
        a[key] = [...a[key], b];
      }

      return a;
    }, {});
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const queryParams = {
          Active: !showInactiveGroups,
        };
        const response = await api.get(`/Campaign/all`, {
          params: queryParams,
        });
        if (
          response.data &&
          response.data.campaignGroups &&
          response.data.campaignGroups.length > 0
        ) {
          setCampaignGroupList(response.data.campaignGroups);
          setFirstCampaign(response.data.campaignGroups[0]?.campaigns[0]); // Set the first campaign
        }
      } catch (error) {
        setCampaignGroupList([]);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [api, changed, showInactiveGroups, setFirstCampaign]);

  return (
    <div>
      {loading && <Spinner />}
      <div className="flex items-center justify-between p-5">
        <p className="text-sm">My Campaign Groups</p>
        {status === 24 && number !== null && (
          <img
            onClick={() => setIsTapPopupOpen(true)}
            src={addIcon}
            alt="add-icon"
            className="w-5 h-5 cursor-pointer"
          />
        )}
      </div>
      {isSidebarOpen && (
      <div className="flex flex-row gap-4 py-[14px] px-[25px] w-full">
        <div className=" flex justify-center w-[3px] h-unset rounded-full bg-gray-700">
          <div className="w-[5px] h-[30px] bg-[#2BB574] rounded-full"></div>
        </div>
        <div className="flex flex-col gap-4 w-full" onClick={toggleArrow}>
          <div
            className="flex items-center justify-between gap-3"
          >
            <div  className={`flex items-center  gap-3  ${
              isSidebarOpen ? "" : "justify-end"
            }`}>
            <img
              className="cursor-pointer rounded-[50%] w-[30px] h-[30px]"
              src={campaignsSvg}
              alt="profile"
            />
            
              <div className="flex flex-col items-left justify-center">
                <span className="text-white text-sm cursor-pointer font-medium">
                  Training
                </span>
              </div>
           
            </div>
          
            <div>
              <img
                src={downArrow}
                alt="Arrow"
                className={`transform transition-transform duration-200 ${
                  isArrowUp ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>
          </div>
          {
            isArrowUp ? (
              <div className="flex items-center justify-center bg-gray-700 h-[46px] w-full cursor-pointer" 
              onClick={() => navigate('/campaigns/sample-campaigns')}>
                <div className="font-medium text-sm text-white">Sample Campaign</div>
              </div>
            ) : null
          }
        </div>
      </div>
    )}
      {isTapPopupOpen && (
        <CreateCampaignPopup
          title={name}
          toggle={toggleTapPopup}
          change={toggleChange}
        />
      )}
      {isSidebarOpen && (
        <>
          {/* <div className="flex gap-2 justify-between p-5 items-center"> */}
          {/* <ToggleButton actionCallback={updateToggleGroupsState} defaultState={false} /> */}
          <div className="flex pb-5 px-5 items-center">
            <span
              onClick={updateToggleGroupsState}
              className="text-sm  hover:text-blue-700 text-white cursor-pointer hover:underline"
            >
              Show All{" "}
            </span>
          </div>
        </>
      )}
      {campaignGroupList &&
        campaignGroupList.length > 0 &&
        campaignGroupList.map((group, k) => (
          <CampaignTreeView
            newCamp={newCampaign}
            campId={campCreated}
            status={status}
            number={number}
            campaignClick={campaignClick}
            key={Math.random()}
            change={toggleChange}
            group={group}
            isSidebarOpen={isSidebarOpen}
            expandedState={expandedState}
            updateExpandedState={updateExpandedState}
          />
        ))}
    </div>
  );
}

export default MyCampaigns;
