import React from 'react'
import Badges from '../../../../../assets/Badges-image.png';
import Level from '../../../../../assets/Level-image.png';
import LevelNoLogo from '../../../../../assets/LevelNoLogo.png'

export default function BadgeComponent() {
  return (
    <div className="flex justify-around mx-3 mt-4">
      <div className="w-[53%]  bg-white border border-gray-300 flex py-8 px-3  rounded-md">
        <div className="flex flex-col gap-7 pr-2 ">
          <h4 className="text-black font-semibold text-xl pl-2">Level </h4>
          {/* <img src={Level} alt="Level" /> */}
         <div><img src={LevelNoLogo} alt="Level" style={{width:"" , height:"90px"}} /> </div> 
        </div>

        <div className=" flex flex-col gap-8">
          <h5 className=" text-neutral-400 w-[100%]">
            Get your Life License to start earning and building
            <br/>
             your business.
          </h5>
          <div className=" flex gap-4">
            <div className=" bg-neutral-100 border-2 border-gray-100 px-2 py-1">
              <div className=" font-semibold">0</div>
              <div className="text-neutral-400">Downline Team</div>
            </div>
            <div className=" bg-neutral-100 border border-gray-100 px-2 py-1">
              <div className=" font-semibold">0</div>
              <div className="text-neutral-400">Licensed Agents</div>
            </div>
            <div className=" bg-neutral-100 border border-gray-100 px-2 py-1">
              <div className=" font-semibold">0</div>
              <div className="text-neutral-400">Net Points</div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[43%] bg-white border border-gray-300 flex py-8 px-3  rounded-md">
        <div className="flex flex-col gap-7 pr-3">
          <h4 className="text-black font-semibold text-xl">Badges </h4>
          <img src={Badges} alt="Badges" style={{width:"150px" , height:"90px"}} />
        </div>

        <div className=" flex flex-col gap-8">
          <h5 className=" text-neutral-400 w-[100%]">
            Sign up to for your State Life Licensing Course and start earning
            badges.
          </h5>
          <div className=" flex gap-4">
            <div className=" bg-neutral-100 border-2 border-gray-100 px-2 py-1">
              <div className=" font-semibold">1</div>
              <div className="text-neutral-400">Badges</div>
            </div>

            <div className=" bg-neutral-100 border-2 border-gray-100 px-2 py-1">
              <div className=" font-semibold">100</div>
              <div className="text-neutral-400">Activity Points</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
