import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "./appconfig";
import {
  setAuthToken,
  setRefreshToken,
  setFullName,
  setPermission,
  setRole,
  setUser,
  setAuthenticated,
  setRefreshInterval,
  setRefreshExp,
  setTimeInterval,
} from "./redux/slices/authSlice";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import useRefreshAuthToken from "./custom-hooks/useRefreshAuthToken";
import useRefreshUserData from "./custom-hooks/useRefreshUserData";

const Token = () => {
  const { userid } = useParams();
  const authToken = useSelector((state) => state.auth.authToken);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshAuthToken = useRefreshAuthToken();
  const refreshUserData = useRefreshUserData();

  useEffect(() => {
    const updateAuthToken = async () => {
      try {
        const response = await refreshAuthToken();
        if (response.status === 200 && response.data.accessToken) {
          dispatch(setAuthToken(response.data.accessToken));
        } else {
          console.log("Error occured while refreshing token: ", response);
          toast.error("Error while refreshing the token", { theme: "dark" });
          setTimeout(() => {
            navigate("/logout");
          }, 2000);
        }
      } catch (error) {
        let errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Auth Token expired. Logging out.";
        console.error("Error while refreshing the token: ", error);
        toast.error(errorMessage, { theme: "dark" });
        setTimeout(() => {
          navigate("/logout");
        }, 2000);
      }
    };

    const callAzure = async () => {
      try {
        const headers = {
          withCredentials: true,
        };

        const response = await axios.get(
          `${BASE_URL}/auth/Token?userid=${userid}`,
          { headers }
        );
        console.log(response.data.message);
        if (response.status === 200 && response.data.message === "success") {
          dispatch(setAuthenticated(true));
          dispatch(setAuthToken(response.data.accessToken));
          let decodedAccessToken = jwtDecode(response.data.accessToken);
          // setTimeInterval to refresh token - exp time - current time - 2 min(offset for refreshing token 2 min earlier);
          window.timeInterval =
            decodedAccessToken.exp * 1000 -
            new Date().getTime() -
            1000 * 60 * 2;
          dispatch(setTimeInterval(window.timeInterval));
          dispatch(setRefreshToken(response.data.refreshToken));
          let decodedRefreshToken = jwtDecode(response.data.refreshToken);
          // to clear the refresh interval if refreshToken expires.
          window.refreshExp = new Date(
            decodedRefreshToken.exp * 1000
          ).getTime();
          dispatch(setRefreshExp(window.refreshExp));
          dispatch(setRole(decodedAccessToken.user.Role));
          dispatch(setPermission(null));
          dispatch(setUser(decodedAccessToken.user));

          let userResponse = await refreshUserData();

          if (userResponse) {
            let user = localStorage.getItem("user")
              ? JSON.parse(localStorage.getItem("user"))
              : {};
            dispatch(
              setUser({
                ...user,
                roleid: userResponse.data.user.roleid,
                organizationId: userResponse.data.user.organizationId,
                TeamName: userResponse.data.user.TeamName,
                email: userResponse.data.user.email,
                ImageName:userResponse.data.user.ImageName
              })
            );
            dispatch(
              setFullName(
                userResponse.data.user.First_name +
                  " " +
                  userResponse.data.user.Last_name
              )
            );
            dispatch(setPermission(userResponse.data.Permission));
            
            if (window.timeInterval > 0) {
              console.log(
                "registering new refreshInterval in every " +
                  window.timeInterval +
                  "ms"
              );
              window.refreshInterval = window.setInterval(() => {
                updateAuthToken();
                console.log(
                  "called updateAuthToken will refresh accessToken in 1 min. Next call will be in: " +
                    (new Date().getTime() + window.timeInterval)
                );

                if (window.refreshExp <= new Date().getTime()) {
                  console.log(
                     "RefrehExp: " +
                       window.refreshExp +
                       ", is less then current time: " +
                       new Date().getTime() +
                       ", Clearing refreshTokenInterval"
                  );
                  clearInterval(window.refreshInterval);
                }
              }, window.timeInterval);
              dispatch(setRefreshInterval(window.refreshInterval));
            }
            if(user && user.Role !== "super_admin"){
              navigate(
                `/agent/${encodeURIComponent(
                  btoa(String( userResponse.data.user.organizationId))
                )}`
              )
            }
             else if (user && user.Role === "super_admin"){
                 navigate("/super-admin") 
             }
          } else {
            console.log("Couldn't get User data. Ending session");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (!authToken) {
      callAzure();
    } else {
      navigate("/dashboard");
    }
  });

  if (authToken) {
    // Return a placeholder or null if callAzure is already called
    navigate("/dashboard");
    return null;
  }

  return null;
};

export default Token;
