import React from "react";
import checkCIrcleSvg from "../../../assets/check-circle.svg";

const pricingTable = ({ list }) => {
  return (
    <div className="border-b border-[#E5E7EB] py-7 mx-20">
      <div className="font-inter font-semibold text-[24px] leading-[29px] py-7">
        {list.title}
      </div>
      {list.pricingList.map((val, idx) => {
        return (
          <div className="flex flex-row pb-6" key={idx}>
            <div className="w-[50%]">
              <p className="font-inter font-medium text-[16px] leading-[19.36px] text-[#4B5563]">
                {val.pricingItem}
              </p>
            </div>
            <div className="w-[50%] flex flex-row justify-between ">
              <p className="flex justify-center font-inter font-semibold text-[16px] leading-[19.36px] text-[#1F2937] w-[33.33%] text-center">
                {val.essential === true ? (
                  <img src={checkCIrcleSvg} alt="Check Circle" />
                ) : val.essential === "upgrade" ? (
                  <p className="font-semibold text-[16px] leading-[19.36px] text-[#4871B7]">
                    Update
                  </p>
                ) : val.essential === "coming soon" ? (
                  <p className="font-semibold text-[16px] leading-[19.36px] text-[#4871B7]">
                    Coming Soon
                  </p>
                ) : null}
              </p>
              <p className="flex justify-center font-inter font-semibold text-[16px] leading-[19.36px] text-[#1F2937] w-[33.33%] text-center">
                {val.professional === true ? (
                  <img src={checkCIrcleSvg} alt="Check Circle" />
                ) : val.professional === "upgrade" ? (
                  <p className="font-semibold text-[16px] leading-[19.36px] text-[#4871B7]">
                    Update
                  </p>
                ) : val.professional === "coming soon" ? (
                  <p className="font-semibold text-[16px] leading-[19.36px] text-[#4871B7]">
                    Coming Soon
                  </p>
                ) : null}
              </p>
              <p className="flex justify-center font-inter font-semibold text-[16px] leading-[19.36px] text-[#1F2937] w-[33.33%] text-center">
                {val.owner === true ? (
                  <img src={checkCIrcleSvg} alt="Check Circle" />
                ) : val.owner === "upgrade" ? (
                  <p className="font-semibold text-[16px] leading-[19.36px] text-[#4871B7]">
                    Update
                  </p>
                ) : val.owner === "coming soon" ? (
                  <p className="font-semibold text-[16px] leading-[19.36px] text-[#4871B7]">
                    Coming Soon
                  </p>
                ) : null}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default pricingTable;
