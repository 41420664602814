import React, { useEffect, useState } from "react";
import { ReactComponent as SideBarIndicatorIcon } from "../../../assets/SideBarIndicator.svg";
import { ReactComponent as AddButtonIcon } from "../../../assets/Frame 212.svg";
import { ErrorMessage, Info } from "./FormComponent";
import greencheck from "../../../assets/greenCheck.svg";
import editsvg from "../../../assets/editSvg.svg";
import downArrow from "../../../assets/down-arrow-black.svg";
import TapUserIcon from "../../../assets/tap-user.svg";
import { useSelector } from "react-redux";
import { IMAGE_URL } from "../../../appconfig";
import {
  ProgressBar,
  CommissionTable,
  EditableText,
  EditableDropdown,
  EditableMultiDropdown,
} from "./ApplicatonComponent/EditScreenComponent";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../appconfig";
import useApi from "../../../custom-hooks/useApi";
import Spinner from "../../common/Spinner";
import { EditSearchDropdown } from "./ApplicatonComponent/SearchDropdown";

const NewBusiness = () => {
  const api = useApi();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [detailsData, setDetailsData] = useState();
  const [commissionDropdown, setCommissionDropdown] = useState(true);
  const Fullname = useSelector((state) => state.auth.fullName);
  const user = useSelector((state) => state.auth.user);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [allDropdownList, setAllDropdownList] = useState({});
  const [loading, setLoading] = useState(false);
  const [valuesId, setValuesId] = useState({
    clientRoles: {
      policyOwnerId: "",
      primaryContactId: "",
      insuredId: "",
      healthAssessorId: "",
    },
    agencyRoles: {
      writingAgentId: "",
      splitAllocationId: "",
      splitAgent1Id: "",
      splitAgent2Id: "",
    },
    policyInformation: {
      policyNumberId: "",
      providerId: "",
      productId: "",
      targetPremiumId: "",
      premiumModeId: "",
    },
    additionalInfo: {
      grossPointsId: "",
      netPointsId: "",
      dateSubmittedId: "",
      dateIssuedId: "",
      bankDraftTermsId: "",
    },
  });
  const [values, setValues] = useState({
    clientRoles: {
      policyOwner: "",
      primaryContact: "",
      insured: "",
      healthAssessor: "",
    },
    agencyRoles: {
      writingAgent: "",
      splitAllocation: "",
      splitAgent1: "",
      splitAgent2: "",
    },
    policyInformation: {
      policyNumber: "",
      provider: "",
      product: "",
      targetPremium: "",
      premiumMode: "",
    },
    additionalInfo: {
      grossPoints: "",
      netPoints: "",
      dateSubmitted: "",
      dateIssued: "",
      bankDraftTerms: "",
    },
  });

  useEffect(() => {
    const fetchClientsData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`${BASE_URL}/Application/get/${id}`);
        if (response?.status === 200) {
          setDetailsData(response?.data?.result);
          let accountList = response?.data?.result?.agentUnderWriting;
          let policyList = response?.data?.result?.policy;
          updateDetails(accountList, policyList);
          setValuesId((prev) => ({
            ...prev,
            clientRoles: {
              ...prev.clientRoles,
              policyOwnerId: Number(policyList?.ID),
            },
          }));
          setValuesId((prev) => ({
            ...prev,
            policyInformation: {
              ...prev.policyInformation,
              providerId: Number(policyList?.ProviderID),
            },
          }));
          fetchProductData(
            `/Organization/Product/${policyList?.ProviderID}`,
            "productList",
            null,
            formatProductList,
            "product"
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchClientsData();
  }, []);

  const [commissionData, setCommissionData] = useState({
    writingAgent: {
      name: "William Madison",
      title: "Executive Marketing Director",
      split: "50% Split",
      compensation: ["Commission", "Overrides"],
      amount: ["$ 5,000", "$ 2,000"],
      points: ["Gross Points", "Net Points"],
      total: {
        amount: "$ 7,000",
        persistency: "40%",
      },
    },
    splitAgent1: {
      name: "William Madison",
      title: "Executive Marketing Director",
      split: "25% Split",
      compensation: ["Commission", "Overrides"],
      amount: ["$ 5,000", "$ 2,000"],
      points: ["Gross Points", "Net Points"],
      total: {
        amount: "$ 7,000",
        persistency: "40%",
      },
    },
    splitAgent2: {
      name: "William Madison",
      title: "Executive Marketing Director",
      split: "25% Split",
      compensation: ["Commission", "Overrides"],
      amount: ["$ 5,000", "$ 2,000"],
      points: ["Gross Points", "Net Points"],
      total: {
        amount: "$ 7,000",
        persistency: "40%",
      },
    },
  });
  useEffect(() => {
    fetchProviderList();
    fetchAndSetDropdownData(
      "/Application/ClientList",
      "policyExistingClient",
      null,
      formatClientList,
      "Entity"
    );
    fetchAndSetDropdownData(
      "/Organization/PremiumMode",
      "premiumModeList",
      null,
      formatGenericList,
      "premium"
    );
    fetchAndSetDropdownData(
      "/Organization/BankDraftTerms",
      "bankDraftTermsList",
      null,
      formatGenericList,
      "bankDraft"
    );
    fetchAndSetDropdownData(
      "/Organization/Constant/SplitCase",
      "splitcaseList",
      null,
      formatGenericList,
      "constant"
    );
  }, []);

  useEffect(() => {
    if (valuesId?.policyInformation.providerID) {
      fetchProductData(
        `/Organization/Product/${valuesId?.policyInformation?.providerID}`,
        "productList",
        null,
        formatProductList,
        "product"
      );
    }
  }, [valuesId?.policyInformation]);

  useEffect(() => {
    if (valuesId?.clientRoles?.policyOwnerId) {
      let id = valuesId?.clientRoles?.policyOwnerId;
      fetchAndSetDropdownData(
        `/Application/ClientList?ownerId=${Number(id)}`,
        "insuredExistingClient",
        null,
        formatClientList,
        "Entity"
      );
    }
  }, [valuesId?.clientRoles?.policyOwnerId]);

  const fetchProviderList = async () => {
    try {
      const providerResponse = await api.get(
        `${BASE_URL}/Organization/Provider`
      );
      if (providerResponse?.status === 200) {
        // setproviderData(providerResponse)
        setAllDropdownList((prev) => ({
          ...prev,
          providerList: providerResponse?.data?.provider || [],
        }));
      }
    } catch (error) {
      console.log("Error fetching Provider List:", error);
    }
  };
  const fetchProductData = async (endpoint, key1, key2, formatter, objName) => {
    try {
      const response = await api.get(`${BASE_URL}${endpoint}`);
      if (response?.status === 200) {
        // console.log(endpoint, response)
        const formattedData = response?.data?.[objName] || [];
        setAllDropdownList((prev) => ({
          ...prev,
          productList: formattedData,
        }));
      }
    } catch (error) {
      console.log(error);
      setAllDropdownList((prev) => ({
        ...prev,
        productList: [],
      }));
    }
  };
  const fetchAndSetDropdownData = async (
    endpoint,
    key1,
    key2,
    formatter,
    objName
  ) => {
    try {
      const response = await api.get(`${BASE_URL}${endpoint}`);
      if (response?.status === 200) {
        const formattedData = response?.data?.[objName];
        setAllDropdownList((prev) => ({
          ...prev,
          [key1]: formattedData,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatClientList = (accountList) => ({
    key1: accountList,
  });

  const formatGenericList = (list) => ({
    key1: list?.map((item) => item?.Name) || [],
  });

  const formatProductList = (productList) => ({
    key1: productList?.map((item) => item?.Product) || [],
  });

  const handleValueChange = (key, newValue, tableKey) => {
    setValues((prevValues) => ({
      ...prevValues,
      [tableKey]: {
        ...prevValues[tableKey], // Preserve existing fields
        [key]: newValue, // Update the specific field
      },
    }));
  };
  const handleIdChange = (key, newValue, tableKey) => {
    setValuesId((prevValues) => ({
      ...prevValues,
      [tableKey]: {
        ...prevValues[tableKey],
        [key]: newValue, // Update the specific field
      },
    }));
  };

  const list = [
    { name: "Primary Contact" },
    { name: "Family Member 1" },
    { name: "Family Member 2" },
  ];

  function commissionDropdownHandler() {
    setCommissionDropdown(!commissionDropdown);
  }

  const progressStages = [
    {
      label: "",
      icon: greencheck,
      color: "text-[#4871B7]",
      barColor: "bg-gradient-to-r from-[#2BB673] to-[#2BB673]",
    },
    {
      label: "Pending",
      color: "text-[#4871B7]",
      barColor: "bg-gradient-to-r from-[#2BB673] to-[#4971B8]",
    },
    { label: "Delivery", color: "text-gray-500", barColor: "bg-gray-300" },
    { label: "Complete", color: "text-gray-500", barColor: "bg-gray-300" },
  ];

  function toggleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  function editApplicatonDetails() {
    setIsEdit(!isEdit);
  }

  const validateFields = () => {
    let tempErrors = {};

    // Validate clientRoles fields
    if (!values.clientRoles.policyOwner) {
      tempErrors.policyOwner = "Policy Owner is required";
    }
    // if (!values.clientRoles.primaryContact) {
    //   tempErrors.primaryContact = "Primary Contact is required";
    // }
    if (!values.clientRoles.insured) {
      tempErrors.insured = "Insured is required";
    }
    // if (!values.clientRoles.healthAssessor) {
    //   tempErrors.healthAssessor = "Health Assessor is required";
    // }

    // Validate agencyRoles fields
    if (!values.agencyRoles.writingAgent) {
      tempErrors.writingAgent = "Writing Agent is required";
    }
    if (!values.agencyRoles.splitAllocation) {
      tempErrors.splitAllocation = "Split Allocation is required";
    }
    if (
      values.agencyRoles.writingAgent === values.agencyRoles.splitAgent1 ||
      values.agencyRoles.writingAgent === values.agencyRoles.splitAgent2
    ) {
      tempErrors.writingAgent =
        "Writing Agent cannot be the same as Split Agent 1 or Split Agent 2";
    }
    if (
      values.agencyRoles.splitAgent1 &&
      values.agencyRoles.splitAgent1 === values.agencyRoles.splitAgent2
    ) {
      tempErrors.splitAgent2 =
        "Split Agent 1 and Split Agent 2 cannot be the same";
    }

    // Validate policyInformation fields
    if (!values.policyInformation.policyNumber) {
      tempErrors.policyNumber = "Policy Number is required";
    }
    if (!values.policyInformation.provider) {
      tempErrors.provider = "Provider is required";
    }
    if (!values.policyInformation.product) {
      tempErrors.product = "Product is required";
    }
    if (!values.policyInformation.targetPremium) {
      tempErrors.targetPremium = "Target Premium is required";
    }

    // Validate additionalInfo fields
    if (!values.additionalInfo.grossPoints) {
      tempErrors.grossPoints = "Gross Points are required";
    }
    if (!values.additionalInfo.netPoints) {
      tempErrors.netPoints = "Net Points are required";
    }
    if (!values.additionalInfo.dateIssued) {
      tempErrors.dateIssued = "Date Issued is required";
    }
    if (!values.additionalInfo.dateSubmitted) {
      tempErrors.dateSubmitted = "Date Submitted is required";
    }
    if (!values.additionalInfo.bankDraftTerms) {
      tempErrors.bankDraftTerms = "Bank Draft Terms are required";
    }

    setErrors(tempErrors);

    setTimeout(() => {
      setErrors({});
    }, 5000);
    if (Object.keys(tempErrors).length !== 0) {
      console.log("errors", tempErrors);
      toast.error("Please correct the errors before saving");
    }

    return Object.keys(tempErrors).length === 0;
  };

  const FormSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        writingAgent: valuesId?.agencyRoles?.writingAgentId,
        hasSplitCase: !!values?.agencyRoles?.splitAllocation,
        splitCase: values?.agencyRoles?.splitAllocation || "",
        splitAgent1: valuesId?.agencyRoles?.splitAgent1Id || "",
        splitAgent2: valuesId?.agencyRoles?.splitAgent2Id || "",
        owner: {
          isNewClient: false, // Assuming this is determined elsewhere
          account: valuesId?.clientRoles?.policyOwnerId,
        },
        insured: {
          relationship: "10", // Assuming this is a static value or fetched elsewhere
          isNewClient: false, // Assuming this is determined elsewhere
          account: valuesId?.clientRoles?.insuredId,
        },
        policy: {
          provider: valuesId?.policyInformation?.providerId,
          policyNumber: values?.policyInformation?.policyNumber,
          product: valuesId?.policyInformation?.productId,
          dateSubmitted: values?.policyInformation?.dateSubmitted,
          targetPremium: values?.policyInformation?.targetPremium,
          premiumMode: valuesId?.policyInformation?.premiumModeId,
          bankDraftTerms: valuesId?.additionalInfo?.bankDraftTermsId,
        },
      };
  
      const response = await api.put(`edit/${id}`, payload);
      toast.success("Details updated successfully!");
    } catch (error) {
      toast.error(error?.response?.data?.error || "Failed to update details", {
        theme: "dark",
      });
    } finally {
      setLoading(false);
    }
  };
  

  function handleSave() {
    if (validateFields()) {
      setIsEdit(false);
    }
    FormSubmit();
    console.log("-==>value>>", values);
    console.log("-==>valueId>>", valuesId);
  }

  function updateDetails(data, policyList) {
    setValues((prev) => ({
      ...prev, // Spread the previous state to maintain existing values
      clientRoles: {
        ...prev.clientRoles, // Maintain existing clientRoles values
        policyOwner: data?.ClientInformation_Owner,
        primaryContact: data?.ClientInformation_Owner,
        insured: data?.Insured,
        // healthAssessor: data?.,
      },
      agencyRoles: {
        ...prev.agencyRoles, // Maintain existing agencyRoles values
        writingAgent: data?.WritingAgent_Name,
        splitAllocation: data?.WritingAgent_Split,
        splitAgent1: data?.SplitAgent1_Name_1,
        splitAgent2: data?.SplitAgent2_Name_2,
      },
      policyInformation: {
        ...prev.policyInformation, // Maintain existing policyInformation values
        policyNumber: data?.Policy_No,
        provider: data?.Policy_Provider,
        product: data?.Product,
        targetPremium: data?.Target_Premium,
        premiumMode: allDropdownList?.premiumModeList?.find(
          (item) => item.ID === Number(policyList?.PremiumMode)
        )?.Name,
      },
      additionalInfo: {
        ...prev.additionalInfo, // Maintain existing additionalInfo values
        grossPoints: data?.Gross_Points,
        netPoints: data?.Net_Points,
        dateSubmitted: data?.Date_Submitted,
        dateIssued: data?.Issue_Date,
        bankDraftTerms: allDropdownList?.bankDraftTermsList?.find(
          (item) => item?.ID === Number(policyList?.DraftTerms)
        )?.Name,
      },
    }));

    setValuesId((prev) => ({
      ...prev, // Spread the previous state to maintain existing IDs
      clientRoles: {
        ...prev.clientRoles, // Maintain existing clientRoles IDs
        policyOwnerId: policyList?.OwnerContactID,
        primaryContactId: policyList?.OwnerContactID,
        insuredId: policyList?.InsuredID,
        // healthAssessorId: data?., // Add healthAssessorId if needed
      },
      agencyRoles: {
        ...prev.agencyRoles, // Maintain existing agencyRoles IDs
        writingAgentId: data?.WritingAgent_ID,
        splitAllocationId: data?.WritingAgent_Split, // Assuming splitAllocationId is based on split data
        splitAgent1Id: data?.SplitAgent1_ID,
        splitAgent2Id: data?.SplitAgent2_ID,
      },
      policyInformation: {
        ...prev.policyInformation, // Maintain existing policyInformation IDs
        policyNumberId: policyList?.PolicyNumber,
        providerId: policyList?.ProviderID,
        productId: policyList?.ProductID,
        targetPremiumId: policyList?.PremiumAmount, // Assuming targetPremiumId refers to the premium amount ID
        premiumModeId: policyList?.PremiumMode,
      },
      additionalInfo: {
        ...prev.additionalInfo, // Maintain existing additionalInfo IDs
        grossPointsId: data?.Gross_Points, // Assuming ID and value are the same
        netPointsId: data?.Net_Points, // Assuming ID and value are the same
        dateSubmittedId: policyList?.DateSubmitted, // Assuming ID and value are the same
        dateIssuedId: policyList?.DateIssued, // Assuming ID and value are the same
        bankDraftTermsId: policyList?.DraftTerms,
      },
    }));
  }
  return (
    <div className="flex flex-row w-full h-full">
      {loading ? <Spinner /> : null}
      <aside
        style={{
          width: "300px",
          marginLeft: isSidebarOpen ? "0" : "-14rem",
        }}
        className={`bg-gray-800 text-white  h-auto transition-all duration-300 `}
      >
        <div
          className={`flex items-center p-5 ${
            isSidebarOpen ? "justify-start" : "justify-end"
          }`}
        >
          <SideBarIndicatorIcon
            className={`w-6 h-6 cursor-pointer transform ${
              isSidebarOpen ? "rotate-0" : "rotate-180"
            }`}
            onClick={() => toggleSidebar()}
          />
        </div>

        <div
          className={`flex items-center p-5 gap-3  ${
            isSidebarOpen ? "" : "justify-end"
          }`}
        >
          <img
            className="cursor-pointer rounded-[50%] w-[30px] h-[30px]"
            src={
              user.ImageName
                ? `${IMAGE_URL}/${user.ImageName}.jpg`
                : TapUserIcon
            }
            alt="profile"
            // { ...CampSideBar.user?.CampaignName ? user.CampaignName.charAt(0) : ''}
          />
          {isSidebarOpen && (
            <div className="flex flex-col items-left justify-center">
              <span className="text-white cursor-pointer font-medium">
                {Fullname}
              </span>
            </div>
          )}
        </div>
        {isSidebarOpen ? (
          <div className="flex flex-row justify-between py-[14px] px-[30px]">
            <div className="text-sm font-medium text-white">
              My Applications
            </div>
            <div>
              <AddButtonIcon className="w-6 h-6" />
            </div>
          </div>
        ) : null}

        <div className="flex flex-row gap-4 py-[14px] px-[30px]">
          <div className=" flex justify-center w-[3px] h-unset rounded-full bg-gray-700">
            <div className="w-[5px] h-[20%] bg-[#2BB574] rounded-full"></div>
          </div>
          <div className="flex flex-col gap-6">
            {list.map((value, idx) => {
              return (
                <div
                  key={idx}
                  className={`flex items-center  gap-3  ${
                    isSidebarOpen ? "" : "justify-end"
                  }`}
                >
                  <img
                    className="cursor-pointer rounded-[50%] w-[30px] h-[30px]"
                    src={
                      user.ImageName
                        ? `https://i.pinimg.com/736x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg`
                        : TapUserIcon
                    }
                    alt="profile"
                    // { ...CampSideBar.user?.CampaignName ? user.CampaignName.charAt(0) : ''}
                  />
                  {isSidebarOpen && (
                    <div className="flex flex-col items-left justify-center">
                      <span className="text-white text-sm cursor-pointer font-medium">
                        {value.name}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
            <div className="text-sm font-bold text-[#2BB574] cursor-default">
              + New Member
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-4 py-[14px] px-[30px] mt-5">
          <div className=" flex justify-center w-[3px] h-unset rounded-full bg-gray-700">
            <div className="w-[5px] h-[20%] bg-[#F64AD1] rounded-full"></div>
          </div>
          <div className="flex flex-col gap-6">
            {[
              { name: "Primary Contact" },
              { name: "Business Associate 1" },
            ].map((value, idx) => {
              return (
                <div
                  key={idx}
                  className={`flex items-center  gap-3  ${
                    isSidebarOpen ? "" : "justify-end"
                  }`}
                >
                  <img
                    className="cursor-pointer rounded-[50%] w-[30px] h-[30px]"
                    src={
                      user.ImageName
                        ? `https://i.pinimg.com/736x/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg`
                        : TapUserIcon
                    }
                    alt="profile"
                    // { ...CampSideBar.user?.CampaignName ? user.CampaignName.charAt(0) : ''}
                  />
                  {isSidebarOpen && (
                    <div className="flex flex-col items-left justify-center">
                      <span className="text-white text-sm cursor-pointer font-medium">
                        {value.name}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
            <div className="text-sm font-bold text-[#2BB574] cursor-default">
              + New Member
            </div>
          </div>
        </div>
      </aside>
      <div className="px-10 py-10 w-full">
        <div className="flex flex-col gap-2">
          <div className="text-gray-400 font-medium text-lg leading-5">
            Application
          </div>
          <div className="text-gray-800 font-semibold text-[28px] leading-8">
            William Henry - NLG - IUL
          </div>
        </div>
        {/* info */}
        <div className="w-full flex flex-row items-center justify-between rounded-md border border-gray-300 p-6 bg-white mt-9">
          <Info
            info={"Writing Agent"}
            name={detailsData?.agentUnderWriting?.WritingAgent_Name || "-"}
          />
          <Info
            info={"Split Agent"}
            name={detailsData?.agentUnderWriting?.WritingAgent_Split || "-"}
          />
          <Info
            info={"Provider"}
            name={detailsData?.agentUnderWriting?.Policy_Provider || "-"}
          />
          <div>
            <div className="text-gray-600 font-semibold text-base leading-5">
              Target Premium
            </div>
            <div className="truncate py-1 whitespace-nowrap font-inter t">
              {detailsData?.agentUnderWriting?.Target_Premium || "-"}
            </div>
          </div>
          <div>
            <div className="text-gray-600 font-semibold text-base leading-5">
              Submitted Date
            </div>
            <div className="truncate py-1 whitespace-nowrap font-inter t">
              {detailsData?.agentUnderWriting?.Date_Submitted || "-"}
            </div>
          </div>
          <div>
            <div className="text-gray-600 font-semibold text-base leading-5">
              Date Issued
            </div>
            <div className="truncate py-1 whitespace-nowrap font-inter text-[#4871B7] underline h-8">
              {detailsData?.agentUnderWriting?.Issue_Date || "-"}
            </div>
          </div>
        </div>

        <ProgressBar stages={progressStages} />

        <div className="w-full flex flex-col  rounded-md border border-gray-300 px-8 py-5 pb-10 bg-white mt-9">
          <div className="w-full flex flex-row justify-between items-center mt-3">
            <div className="justify-start text-gray-800 font-semibold text-xl leading-[24.2px]">
              Application Details
            </div>
            <div onClick={editApplicatonDetails} className="cursor-pointer">
              <img src={editsvg} alt="" className="cursor-pointer" />
            </div>
          </div>
          <div className="w-full flex flex-col gap-3 p-5 bg-[#F9FAFB] border border-gray-200 rounded mt-7">
            <div className="text-gray-700 font-semibold leading-[19.36px]">
              Notes
            </div>
            <div className="text-gray-500 font-medium  leading-[19.36px]">
              WH - 12/3 - Application submitted to NLG; Medical records
              requested; waiting to hear back from client.
            </div>
          </div>

          <div className="w-ful h-[1px] bg-gray-300 my-8"></div>

          <div className="flex flex-row w-full">
            <div className="w-1/2">
              <div className="font-medium text-gray-600 leading-[19.36px] mb-8">
                Client Roles
              </div>
              <div className="flex flex-row w-full">
                <div
                  className={`flex flex-col ${
                    isEdit ? "gap-3" : "gap-8"
                  } w-full mr-10`}
                >
                  <EditableMultiDropdown
                    label="Policy Owner"
                    value={values?.clientRoles?.policyOwner}
                    onChange={(newValue) =>
                      handleValueChange("policyOwner", newValue, "clientRoles")
                    }
                    onChangeId={(newValue) =>
                      handleIdChange("policyOwner", newValue, "clientRoles")
                    }
                    isEdit={isEdit}
                    list={[
                      allDropdownList?.policyExistingClient || [],
                      "firstName",
                      "AccountID",
                    ]}
                    error={errors?.policyNumber}
                  />
                  <EditableText
                    label="Primary Contact"
                    value={values?.clientRoles?.primaryContact}
                    onChange={(newValue) =>
                      handleValueChange(
                        "primaryContact",
                        newValue,
                        "clientRoles"
                      )
                    }
                    isEdit={isEdit}
                    disable={true}
                    error={errors?.primaryContact}
                  />
                  <EditableDropdown
                    label="Insured"
                    value={values?.clientRoles?.insured}
                    onChange={(newValue) =>
                      handleValueChange("insured", newValue, "clientRoles")
                    }
                    onChangeId={(newValue) =>
                      handleIdChange("insuredId", newValue, "clientRoles")
                    }
                    list={[
                      allDropdownList?.policyExistingClient || [],
                      "firstName",
                      "AccountID",
                    ]}
                    isEdit={isEdit}
                    error={errors?.insured}
                  />
                  <EditableText
                    label="Health Assessor"
                    value={values?.clientRoles?.healthAssessor}
                    onChange={(newValue) =>
                      handleValueChange(
                        "healthAssessor",
                        newValue,
                        "clientRoles"
                      )
                    }
                    isEdit={isEdit}
                    disable={true}
                    error={errors?.healthAssessor}
                  />
                </div>
              </div>
            </div>

            <div className="w-1/2">
              <div className="font-medium text-gray-600 leading-[19.36px]  mb-8">
                Agency Roles
              </div>
              <div className="flex flex-row w-full">
                <div
                  className={`flex flex-col ${
                    isEdit ? "gap-3" : "gap-8"
                  } w-full mr-10`}
                >
                  <EditSearchDropdown
                    label="Writing Agent"
                    value={values?.agencyRoles?.writingAgent}
                    onChange={(newValue) =>
                      handleValueChange(
                        "primaryContact",
                        newValue,
                        "agencyRoles"
                      )
                    }
                    isEdit={isEdit}
                    list={[]}
                    errors={errors?.writingAgent}
                  />

                  <EditableDropdown
                    label="Split Allocation"
                    value={values?.agencyRoles?.splitAllocation}
                    onChange={(newValue) =>
                      handleValueChange(
                        "healthAssessor",
                        newValue,
                        "agencyRoles"
                      )
                    }
                    onChangeId={(newValue) =>
                      handleValueChange("insured", newValue, "clientRoles")
                    }
                    isEdit={isEdit}
                    list={[
                      [
                        { ID: 0, Name: "No" },
                        ...(allDropdownList?.splitcaseList || []),
                      ],
                      "Name",
                      "ID",
                    ]}
                    errors={errors?.splitAllocation}
                  />
                  <EditSearchDropdown
                    label="Split Agent 1"
                    value={values?.agencyRoles?.splitAgent1}
                    onChange={(newValue) =>
                      handleValueChange(
                        "primaryContact",
                        newValue,
                        "agencyRoles"
                      )
                    }
                    isEdit={isEdit}
                    list={[]}
                    errors={errors?.splitAgent1}
                  />
                  <EditSearchDropdown
                    label="Split Agent 2"
                    value={values?.agencyRoles?.splitAgent2}
                    onChange={(newValue) =>
                      handleValueChange(
                        "primaryContact",
                        newValue,
                        "agencyRoles"
                      )
                    }
                    isEdit={isEdit}
                    list={[]}
                    errors={errors?.splitAgent2}
                  />
                  <div className="truncate px-2 text-sm  whitespace-nowrap font-inter font-bold"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="justify-start text-gray-800 font-semibold text-xl leading-[24.2px] mt-12">
            Policy Information
          </div>

          <div className="w-ful h-[1px] bg-gray-300 my-7"></div>

          <div className="flex flex-row w-full">
            <div className="w-1/2">
              <div className="flex flex-row w-full">
                <div
                  className={`flex flex-col ${
                    isEdit ? "gap-3" : "gap-8"
                  } w-full mr-10`}
                >
                  <EditableText
                    label="Policy Number"
                    value={values?.policyInformation?.policyNumber}
                    onChange={(newValue) =>
                      handleValueChange(
                        "primaryContact",
                        newValue,
                        "policyInformation"
                      )
                    }
                    disable={values?.policyInformation?.policyNumber}
                    isEdit={isEdit}
                    errors={errors?.policyNumber}
                  />
                  <EditableDropdown
                    label="Provider"
                    value={values?.policyInformation?.provider}
                    onChange={(newValue) =>
                      handleValueChange(
                        "providerId",
                        newValue,
                        "policyInformation"
                      )
                    }
                    onChangeId={(newID) =>
                      handleIdChange("providerID", newID, "policyInformation")
                    }
                    isEdit={isEdit}
                    errors={errors?.provider}
                    list={[
                      allDropdownList?.providerList || [],
                      "ProviderName",
                      "ProviderID",
                    ]}
                  />
                  <EditableDropdown
                    label="Product"
                    value={values?.policyInformation?.product}
                    onChange={(newValue) =>
                      handleValueChange(
                        "primaryContact",
                        newValue,
                        "policyInformation"
                      )
                    }
                    onChangeId={(newID) =>
                      handleIdChange("productId", newID, "policyInformation")
                    }
                    isEdit={isEdit}
                    disable={!allDropdownList?.productList}
                    errors={errors?.product}
                    list={[
                      allDropdownList?.productList || [],
                      "ProductName",
                      "ProductID",
                    ]}
                  />
                  <EditableText
                    label="Target Premium"
                    value={values?.policyInformation?.targetPremium}
                    onChange={(newValue) =>
                      handleValueChange(
                        "targetPremium",
                        newValue,
                        "policyInformation"
                      )
                    }
                    isEdit={isEdit}
                    errors={errors?.targetPremium}
                  />
                  <EditableDropdown
                    label="Premium Mode"
                    value={values?.policyInformation?.premiumMode}
                    onChange={(newValue) =>
                      handleValueChange(
                        "primaryContact",
                        newValue,
                        "policyInformation"
                      )
                    }
                    onChangeId={(newID) =>
                      handleIdChange(
                        "premiumModeId",
                        newID,
                        "policyInformation"
                      )
                    }
                    isEdit={isEdit}
                    errors={errors?.targetPremium}
                    list={[
                      allDropdownList?.premiumModeList || [],
                      "Name",
                      "ID",
                    ]}
                  />
                  <div className="truncate px-2 text-sm  whitespace-nowrap font-inter font-bold"></div>
                </div>
              </div>
            </div>

            <div className="w-1/2">
              <div className="flex flex-row w-full">
                <div
                  className={`flex flex-col ${
                    isEdit ? "gap-3" : "gap-8"
                  } w-full mr-10`}
                >
                  <div className="flex flex-row items-center gap-32 w-full">
                    <div className="font-medium text-sm leading-[18px] text-gray-400 w-1/2 whitespace-nowrap">
                      Gross Points
                    </div>
                    <div
                      className={
                        "truncate px-2 text-sm whitespace-nowrap font-inter text-[#4B5563] font-semibold w-1/2 text-left"
                      }
                    >
                      {allDropdownList?.additionalInfo?.grossPoints || "-"}
                    </div>
                  </div>
                  <EditableText
                    label="Net Points"
                    value={values?.additionalInfo?.netPoints}
                    onChange={(newValue) =>
                      handleValueChange(
                        "primaryContact",
                        newValue,
                        "additionalInfo"
                      )
                    }
                    isEdit={isEdit}
                    disable={true}
                    errors={errors?.netPoints}
                  />
                  <div
                    className={`flex flex-row items-center ${
                      isEdit ? "gap-3" : "gap-32"
                    } w-full`}
                  >
                    <EditableText
                      label="Date Issued"
                      value={
                        values?.additionalInfo?.dateIssued
                          ? values?.additionalInfo?.dateIssued
                          : "-"
                      }
                      onChange={(newValue) =>
                        handleValueChange(
                          "dateIssued",
                          newValue,
                          "additionalInfo"
                        )
                      }
                      isEdit={isEdit}
                      disable={true}
                      errors={errors?.dateIssued}
                    />
                  </div>
                  <div
                    className={`flex flex-row items-center ${
                      isEdit ? "gap-3" : "gap-32"
                    } w-full`}
                  >
                    <div className="font-medium text-sm leading-[18px] text-gray-400 w-1/2 whitespace-nowrap">
                      Date Submitted
                    </div>
                    <>
                      {isEdit ? (
                        <>
                          <input
                            type="date"
                            placeholder="Date Submitted"
                            max={new Date().toISOString().split("T")[0]}
                            value={values?.additionalInfo?.dateSubmitted}
                            onChange={(e) =>
                              handleValueChange(
                                "dateSubmitted",
                                e.target.value,
                                "additionalInfo"
                              )
                            }
                            // className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                            className="w-[217px] flex justify-between items-center h-[38px] border border-gray-300 bg-[#E5E7EB] pl-6 pr-1 gap-[10px] cursor-pointer outline-none focus:ring-0"
                          />
                          {errors?.dateIssued && (
                            <ErrorMessage message={errors?.dateSubmitted} />
                          )}
                        </>
                      ) : (
                        <div className="truncate px-2 text-sm font-inter text-[#4B5563] font-semibold w-1/2 text-left">
                          {values?.additionalInfo?.dateSubmitted
                            ? values?.additionalInfo?.dateSubmitted
                            : "-"}
                        </div>
                      )}
                    </>
                  </div>
                  <EditableDropdown
                    label="Bank Draft Terms"
                    value={values?.additionalInfo?.bankDraftTerms}
                    onChange={(newValue) =>
                      handleValueChange(
                        "primaryContact",
                        newValue,
                        "additionalInfo"
                      )
                    }
                    onChangeId={(newID) =>
                      handleIdChange(
                        "bankDraftTermsId",
                        newID,
                        "additionalInfo"
                      )
                    }
                    isEdit={isEdit}
                    errors={errors?.bankDraftTerms}
                    list={[
                      allDropdownList?.bankDraftTermsList || [],
                      "Name",
                      "ID",
                    ]}
                  />

                  <div className="truncate px-2 text-sm  whitespace-nowrap font-inter font-bold"></div>
                </div>
              </div>
            </div>
          </div>
          {isEdit ? (
            <div className="flex flex-row gap-6 flex-wrap my-4 mt-8">
              <button
                onClick={handleSave}
                style={{
                  background:
                    "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                }}
                className="w-[11.625rem] h-[3.19rem] bg-transparent text-white text-sm font-semibold hover:text-white py-2 px-4 mr-4 border  rounded font-inter"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setIsEdit(false);
                  navigate("/applications");
                }}
                className="w-[11.625rem] h-[3.19rem] bg-transparent  text-sm font-semibold hover:text-white hover:bg-[#2BB574] py-2 px-4 mr-4 border border-[#2BB574] text-[#2BB574]  rounded font-inter"
              >
                Cancel
              </button>
            </div>
          ) : null}
        </div>

        <div className="w-full flex flex-col  rounded-md border border-gray-300  py-5 bg-white mt-9">
          <div
            className="w-full flex flex-row justify-between items-center mt-3 px-8"
            onClick={commissionDropdownHandler}
          >
            <div className="justify-start text-gray-800 font-semibold text-xl leading-[24.2px]">
              Commissions
            </div>
            <img src={downArrow} alt="" />
          </div>
          {commissionDropdown ? (
            <>
              {["writingAgent", "splitAgent1", "splitAgent2"].map(
                (agentKey) => (
                  <div key={agentKey}>
                    <div className="flex flex-col gap-3 justify-center w-full h-[100px] bg-[#F3F4F6] px-8 mt-8">
                      <div className="font-semibold text-lg text-gray-600">
                        {agentKey === "writingAgent"
                          ? "Writing Agent"
                          : `Split Agent ${
                              agentKey === "splitAgent1" ? "1" : "2"
                            }`}
                      </div>
                      <div className="flex flex-row gap-10">
                        <div className="text-sm font-medium text-gray-500">
                          {commissionData[agentKey].name}
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                          {commissionData[agentKey].title}
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                          {commissionData[agentKey].split}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex flex-row gap-[7rem] mx-8 my-8">
                        <div className="flex flex-col gap-4">
                          <CommissionTable
                            title={"Compensation"}
                            list={commissionData[agentKey].compensation}
                            finalvalue={"Total"}
                          />
                        </div>
                        <div className="flex flex-col gap-4">
                          <CommissionTable
                            title={"Amount"}
                            list={commissionData[agentKey].amount}
                            finalvalue={commissionData[agentKey].total.amount}
                          />
                        </div>
                        <div className="flex flex-col gap-4">
                          <CommissionTable
                            title={"Points"}
                            list={commissionData[agentKey].points}
                            finalvalue={"Persistency"}
                          />
                        </div>
                        <div className="flex flex-col gap-4">
                          <CommissionTable
                            title={"Amount"}
                            list={commissionData[agentKey].amount}
                            finalvalue={
                              commissionData[agentKey].total.persistency
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NewBusiness;
