import React from 'react';

const CircularProgressBar = ({ progress1, progress2 }) => {
  const getPath = (progress, radius) => {
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;
    return `M 50 50 m 0 -${radius} a ${radius} ${radius} 0 1 1 0 ${2 * radius} a ${radius} ${radius} 0 1 1 0 -${2 * radius} Z`;
  };

  const radius = 40;

  return (
    <svg viewBox="0 0 100 100" className="w-[100px] h-[100px]">
      <path
        d={getPath(progress1, radius)}
        stroke="#4caf50"
        fill="none"
        strokeWidth="8"
        strokeDasharray={`${progress1 / 100 * 2 * Math.PI * radius} ${2 * Math.PI * radius}`}
        strokeDashoffset="0"
      />
      <path
        d={getPath(progress2, radius)}
        stroke="#2196f3"
        fill="none"
        strokeWidth="8"
        strokeDasharray={`${progress2 / 100 * 2 * Math.PI * radius} ${2 * Math.PI * radius}`}
        strokeDashoffset={`${progress1 / 100 * 2 * Math.PI * radius}`}
      />
    </svg>
  );
};

export default CircularProgressBar;
