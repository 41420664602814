import React, { useState } from "react";
import StageForm from "./StageForm";
import { useSelector, useDispatch } from "react-redux";
import { updateAgentData } from "../../../../redux/thunks/agentThunk";
import { setFormData } from "../../../../redux/slices/agentSlice";
import useApi from "../../../../custom-hooks/useApi";
import { isValid, format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Stage6 = ({ handleChange }) => {
  const agentId = useSelector((state) => state.agent.agentId);
  const dispatch = useDispatch();
  const api = useApi();

  const formData = useSelector((state) => state.agent.formData);
    const stepId = useSelector((state) => state.agent.currentStep);

    const currentStepData = useSelector((state) => state.agent.currentStepData);

  const [isEditing, setEditing] = useState(false);

  const toggleEditing = () => {
    if (isEditing) {
      dispatch(updateAgentData({ stepId, agentId, formData, currentStepData, api }));
    }
    setEditing(!isEditing);
  };

  const cancelEditing = () => {
    dispatch(setFormData(currentStepData));
    setEditing(false);
  };

  const setDate = (date, name) => {
    
    setGrand_Opening_Date(date);

    try {
      console.log(date);

      if (isValid(date)) {
        const formattedDate = format(date, "yyyy-MM-dd");
        dispatch(setFormData({ [name]: formattedDate }));
      } else {
        console.error("Invalid date");
      }
    } catch (error) {
      console.error("Error parsing date:", error);
    }
  };

  const [Grand_Opening_Date, setGrand_Opening_Date] = useState(
    formData &&
      formData.Grand_Opening_Date !== null &&
      formData.Grand_Opening_Date !== undefined
      ? new Date(formData.Grand_Opening_Date)
      : ""
  );

  if (formData!==null && currentStepData !== null ) {
    return (
      <>
        <StageForm
          stageNum={stepId}
          isEditable={false}
          cancelEditing={cancelEditing}
          isEditing={isEditing}
          toggleEditing={toggleEditing}
        >
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 1 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Business Partners&nbsp;
                
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              <span>{currentStepData.Business_Partners || "-"}</span>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 2 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">Licensed Agents</span>
            </div>
            <div className="mb-2 md:w-1/2">
              <span>{currentStepData.Licensed_Agents || "-"}</span>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 3 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Net Points&nbsp;
                
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              <span>{currentStepData.Net_Points || "-"}</span>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 4 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Cash Flow&nbsp;
                
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              <span>{currentStepData.Cash_Flow || "-"}</span>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 5 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                $50K Cash Flow&nbsp;
                
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
                <div
                  className={`w-4 h-4 border border-gray-300 rounded mr-2 ${
                    formData.Cash_Flow_50k ? "bg-blue-700" : ""
                  }`}
                >
                  {formData.Cash_Flow_50k && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="12"
                      viewBox="0 0 10 8"
                      fill="none"
                      className=" text-white mx-auto my-auto"
                    >
                      <path
                        d="M9 1L3.5 6.5L1 4"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 6 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Sign Off &nbsp;
                
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
                <span>
                  {currentStepData.Sign_Off_Date_Phase4 !== null
                    ? isValid(new Date(currentStepData.Sign_Off_Date))
                      ? format(
                          new Date(currentStepData.Sign_Off_Date),
                          "M/d/yyyy"
                        )
                      : "-"
                    : "-"}
                </span>
            </div>
          </div>
        </StageForm>
      </>
    );
  }
};
export default Stage6;


