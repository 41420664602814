import React from "react";
import menu from "./assets/menuTop.svg";
import Brand from "./assets/brand.png";
import Penny3 from "./assets/Penny3.png";
import googleLogo from "./assets/flat-color-icons_google.svg";
import microsoftLogo from "./assets/logos_microsoft-icon.svg";
import BackgroundImg from "./assets/background.svg";

const VerifyEmail = () => {
  return (
    <div className="h-screen">
      <nav
        style={{ backgroundColor: "#4971B8", height: "3.5rem" }}
        className="flex items-center justify-between p-4 relative"
        id="nav-bar"
      >
        <img src={Brand} alt="brandlogo" style={{ height: "1.5rem" }} />

        <div className="flex group items-center space-x-2 relative">
          <div className="flex w-[115px] h-[45px] p-[16px 24px] justify-center items-center space-x-2.5 flex-shrink-0 bg-white rounded">
            <button className="text-[#4871B7]">Log In</button>
          </div>
          <img
            style={{ cursor: "pointer", marginLeft: "2rem", width: "1.5rem" }}
            src={menu}
            alt="bellicon"
          />
        </div>
      </nav>
      <div
        className="w-full max-w-full max-h-full  flex bg-gradient-to-r from-[rgba(0,0,0,0.83)] to-[rgba(7,23,51,0.56)] via-transparent"
        style={{ backgroundImage: `url(${BackgroundImg})` }}
      >
        <div className="w-3/5 flex flex-col ml-10  items-center justify-center">
          <h1 className="w-[766px] text-white font-inter mb-5 text-5xl font-bold leading-normal">
            Please verify your
            <br />
            email address.
          </h1>
          <p className="w-[766px] text-white mb-10 font-inter text-2xl font-medium leading-[36px]">
            Complete sign up through the email we sent to your
            <br /> email address, or choose a different option below.
          </p>
          <div className=" w-[766px] flex flex-col items-start space-y-4">
            <div className="flex items-center mb-5 space-x-2">
              <input type="checkbox" id="myCheckbox" className="mr-2" />
              <label
                for="myCheckbox"
                className="text-white font-inter text-[13px] font-normal leading-[18px]"
              >
                By signing up, I agree to WealthSmyth’s{" "}
                <span className="text-white font-inter text-[13px] font-bold leading-[18px]">
                  Terms of Service
                </span>{" "}
                and{" "}
                <span className="text-white font-inter text-[13px] font-bold leading-[18px]">
                  Privacy Policy.
                </span>
              </label>
            </div>
            <div className="flex gap-5">
              <button className="w-[260px] h-[72px] px-5 py-2.5 flex justify-center items-center gap-4 flex-shrink-0 border border-white text-white">
                <img src={googleLogo} alt="Google Logo" className="h-6 w-6"></img>
                Signup with Google
              </button>
              <button className="w-[260px] h-[72px] px-5 py-2.5 flex justify-center items-center gap-4 flex-shrink-0 border border-white text-white">
                <img
                  src={microsoftLogo}
                  alt="Google Logo"
                  className="h-6 w-6"
                ></img>
                Signup with Microsoft
              </button>
            </div>
       
          </div>
        </div>
        <div className="w-1/3 max-h-[90%] ml-10  flex flex-col items-center">
          <img src={Penny3} alt="AI-loginlogo" />
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
