import React from "react";
import CircularProgressBar from "./CircularStatus";

function CampSummary() {
    const progress1 = 70;
    const progress2 = 40;

  return (
    <div>
      <h1 className="px-7 py-3 text-[28px] font-semibold">My Campaigns</h1>
      <div className="bg-white border border-gray-300 rounded-lg px-4 py-4 m-6">
        <div className="flex justify-between font-semibold">
        <h2 className="text-[20px] px-3 py-1 "> Performance Level Last 30 Days</h2>
        <h2 className="text-[20px] px-3 py-1 pr-12">Activity Level Last 14 Days</h2>
        </div>
        <div className="flex items-center rounded activity-container p-2">
        <div className="flex space-x-10">
          <div className="text-left items-center rounded justify-center w-[213px] px-5 py-2 h-[88px] bg-[#159959]">
            <div className="text-white text-[34px] font-bold leading-40">37</div>
            <div className="text-white text-base leading-20">Part Timers</div>
          </div>
          <div className="w-[213px] px-5 py-2 h-[88px] rounded bg-[#4971B8]">
            <div className="text-white font-bold text-[34px] leading-40">38</div>
            <div className="text-white text-base leading-20">Full Timers</div>
          </div>
        </div>    
        <div className="bg-[#5f93e3] h-[150px] w-[1px] ml-3"></div>
        <div className="ml-8 flex space-x-10">  
        <CircularProgressBar progress1={progress1} progress2={progress2} />
        <div className="flex flex-col justify-end gap-4">
          <div className="flex gap-4">
          <div className="w-4 h-4 bg-[#159959]">  </div>
          <div className=" text-gray-400 text-sm "> New Prospects</div>
          </div>
          <div className="flex gap-4">
           <div className="bg-[#4971B8] w-4 h-4"></div>
           <div className=" text-gray-400 text-sm "> Follow Ups</div>
           </div>
        </div>
        </div>
        </div>
        <div>
          <table className="min-w-full mx-auto text-left z-0">
            <thead className="font-inter text-base text-gray-400">
              <tr className="px-4 py-4">
                <th className="border-b border-gray-300 py-2 px-4">Date</th>
                <th className="border-b border-gray-300 py-2 px-4">
                  Total Activity Points
                </th>
                <th className="border-b border-gray-300 py-2 px-4">
                  Part Timers % of Target
                </th>
                <th className="border-b border-gray-300 py-2 px-4">
                  Full Timers % of Target
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-700 font-inter text-sm font-medium leading-5">
              <tr className="border-b cursor-pointer dark:border-neutral-500">
                <td className="p-5 font-inter">Sunday, December 10, 2023</td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  14
                </td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  0%
                </td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  0%
                </td>
              </tr>
              <tr className="border-b cursor-pointer dark:border-neutral-500">
                <td className="p-5 font-inter">Sunday, December 10, 2023</td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  14
                </td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  0%
                </td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  0%
                </td>
              </tr>
              <tr className="border-b cursor-pointer dark:border-neutral-500">
                <td className="p-5 font-inter">Sunday, December 10, 2023</td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  14
                </td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  0%
                </td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  0%
                </td>
              </tr>
              <tr className="border-b cursor-pointer dark:border-neutral-500">
                <td className="p-5 font-inter">Sunday, December 10, 2023</td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  14
                </td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  0%
                </td>
                <td className="truncate p-5 whitespace-nowrap font-inter">
                  0%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CampSummary;
