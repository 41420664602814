// commonSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCountriesData,
  fetchStatesData,
  fetchEntityTypeData,
  fetchRelationshipData,
  fetchRcStatusList,
  fetchSignOffStatusList,
  fetchCampaignGroupTypeList,
} from "../thunks/commonThunk";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    countries: [],
    states: [],
    countryId: 0,
    entityTypes: [],
    rcStatusList: [],
    signOffStatusList: [],
    relationTypes: [],
    campaignGroupTypeList: [],
    loading: false,
    error: null,
    visitedPages: localStorage.getItem("visitedPages") ? localStorage.getItem("visitedPages").split(",") : [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setStates: (state, action) => {
      state.states = action.payload;
    },
    setCountryId: (state, action) => {
      state.countryId = action.payload;
    },
    setEntityTypes: (state, action) => {
      state.entityTypes = action.payload;
    },
    setRcStatusList: (state, action) => {
      state.rcStatusList = action.payload;
    },
    setSignOffStatusList: (state, action) => {
      state.signOffStatusList = action.payload;
    },
    setCampaignGroupTypeList: (state, action) => {
      state.campaignGroupTypeList = action.payload;
    },
    setRelationTypes: (state, action) => {
      state.relationTypes = action.payload;
    },
    setVisitedPages: (state, action) => {
      if(!state.visitedPages.includes(action.payload)) {
        state.visitedPages = [...state.visitedPages, action.payload];
      }
      localStorage.setItem("visitedPages", state.visitedPages);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountriesData.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchCountriesData.fulfilled, (state, action) => {
        state.countries = action.payload;
        state.loading = false;
      })
      .addCase(fetchCountriesData.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchStatesData.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchStatesData.fulfilled, (state, action) => {
        state.states = action.payload;
        state.loading = false;
      })
      .addCase(fetchStatesData.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchEntityTypeData.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchEntityTypeData.fulfilled, (state, action) => {
        state.entityTypes = action.payload;
        state.loading = false;
      })
      .addCase(fetchEntityTypeData.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchRcStatusList.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchRcStatusList.fulfilled, (state, action) => {
        state.rcStatusList = action.payload;
        state.loading = false;
      })
      .addCase(fetchRcStatusList.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchSignOffStatusList.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchSignOffStatusList.fulfilled, (state, action) => {
        state.signOffStatusList = action.payload;
        state.loading = false;
      })
      .addCase(fetchSignOffStatusList.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchCampaignGroupTypeList.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchCampaignGroupTypeList.fulfilled, (state, action) => {
        state.campaignGroupTypeList = action.payload;
        state.loading = false;
      })
      .addCase(fetchCampaignGroupTypeList.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchRelationshipData.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchRelationshipData.fulfilled, (state, action) => {
        state.relationTypes = action.payload;
        state.loading = false;
      })
      .addCase(fetchRelationshipData.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { setCountries, setStates, setCountryId, setLoading, setVisitedPages } =
  commonSlice.actions;
export default commonSlice.reducer;
