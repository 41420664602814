import { createAsyncThunk } from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {
  setCompletedData,
  setShowCongratulations,
  setPromotionStageStatus,
  setCompletedStages,
} from "../slices/agentSlice";

const stepUrlMap = {
  1: "OnboardingWeek1",
  2: "OnboardingWeek2",
  3: "OnboardingWeek3",
  4: "TrainingAppointments",
  5: "BecomeCFT",
  6: "BecomeMD",
  7: "BecominganExecutive",
};

export const fetchAgentData = createAsyncThunk(
  "agent/fetchAgentData",
  async ({ agentId, api, updateOtherData = true }, thunkAPI) => {
    try {
      const response = await api.get(`/Agent/Agent_Details/${agentId}`);
      if (response.status === 200) {
        const { dispatch } = thunkAPI;
        if (updateOtherData) {
          dispatch(setCompletedData(response.data.organizations[0].max_stepid));
          dispatch(setPromotionStageStatus(response.data.stageStatus));
          dispatch(setCompletedStages(response.data.Steps));
        }
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllAgentData = createAsyncThunk(
  "agent/fetchAllAgentData",
  async (api, thunkAPI) => {

    try {
      const response = await api.get(
        `/Organization/AgentShort_Detail?type=All`
      ); // Replace with your API endpoint
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchAgentStepData = createAsyncThunk(
  "agent/fetchAgentStage",
  async ({ currentStep, agentId, api }, thunkAPI) => {
    try {
      let stepName = stepUrlMap[currentStep];
      const response = await api.get(`/Agent/${stepName}/${agentId}`);
      return response.data.organization;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllStepData = createAsyncThunk(
  "agent/fetchAllStepData",
  async ({ api }, thunkAPI) => {
    try {
      const response = await api.get(`/Agent/StageDetails`);
      return response.data.AgentStep;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllPartnersMapping = createAsyncThunk(
  "agent/fetchAllPartnersMapping",
  async ({ api }, thunkAPI) => {
    try {
      const response = await api.get(`/Organization/Constant/Partner`);
      return response.data.constant;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchExchangeLegData = createAsyncThunk(
  "agent/fetchExchangeLegData",
  async ({ agentId, api }, thunkAPI) => {
    try {
      const response = await api.get(
        `/Organization/AgentShort_Detail?type=Direct&ExchangeLeg=true&id=${agentId}`
      ); // TO-DO Replace with new API endpoint
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateAgentData = createAsyncThunk(
  "agent/updateAgentData",
  async ({ stepId, agentId, formData, currentStepData, api }, thunkAPI) => {
    try {
      let stepName = stepUrlMap[stepId];
      const { dispatch } = thunkAPI;
      const response = await api.put(
        `/Agent/${stepName}/${agentId}`,
        formData
      ); // Replace with your API endpoint
      if(response.status === 200 && response.data.success ) {
        toast.success(response.data.message, { theme: "dark" });
        let updateOtherData = false;
        if (stepId === 7) {
          dispatch(setCompletedData(7));
          updateOtherData = true
          dispatch(fetchAgentData({ agentId, api, updateOtherData }, false));
          if(response.data.user !== null && response.data.accessToken !== null && response.data.refreshToken !== null) {
            localStorage.setItem('accessToken',response.data.accessToken);
            localStorage.setItem('refreshToken',response.data.refreshToken);
          }
          setTimeout(() => {
            dispatch(setShowCongratulations(true));
          }, 2000)
        } else {
          dispatch(fetchAgentData({ agentId, api, updateOtherData }, false));
          let currentStep = stepId;
          setTimeout(() => {
            dispatch(fetchAgentStepData({ currentStep, agentId, api}));
          }, 1000)
        }
        return formData;
      } else {
        toast.error(response?.data?.message || "Update failed", {
          theme: "dark",
        });
        return formData;
      }
    } catch (error) {
      console.log("catchformdata");
      console.error("Error fetching data:", error);
      let message = error?.response?.data?.message || "Update failed";
      toast.error(message, { theme: "dark" });
      console.log(stepId,"stepId")
      if(stepId === 1){
        return thunkAPI.rejectWithValue(formData);
      }
      else{
        return thunkAPI.rejectWithValue(currentStepData);
      }
      
    }
  }
);

export const updateAgentCompletedStep = createAsyncThunk(
  "agent/updateAgentCompletedStep",
  async ({ stageNum, agentId, api }, thunkAPI) => {
    try {
      const {dispatch} = thunkAPI;
      const reqBody = {
        StepID: stageNum
      }
      const headers = {
        "Content-Type": "application/json",
      }
      const response = await api.post(
        `/Agent/MarkStep_Update/${agentId}`,
        reqBody,
        headers
      ); // Replace with your API endpoint
      if (response.status === 200 && response.data.success) {
        toast.success(response.data.message, { theme: "dark" });
        if(stageNum !== 7){
          setTimeout(() => {
            dispatch(fetchAgentData({ agentId, api }));
          }, 1000)
        }
        return stageNum;
      } else {
        toast.error(response?.data?.message || "Update failed", {
          theme: "dark",
        });
        return parseInt(stageNum);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      let message = error?.response?.data?.message || "Update failed";
      toast.error(message, { theme: "dark" });
      return thunkAPI.rejectWithValue(stageNum);
    }
  }
);

export const requestStepSignOff = createAsyncThunk(
  "agent/requestStepSignOff",
  async ({ stageNum, agentId, api }, thunkAPI) => {
    try {
      const { dispatch } = thunkAPI;
      const reqBody = {
        StepID: stageNum,
      };
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await api.post(
        `/Agent/RequestSignOff/${stageNum}`,
        reqBody,
        headers
      ); // Replace with your API endpoint
      if (response.status === 200 && response.data.success) {
        toast.success(response.data.message, { theme: "dark" });
       
          setTimeout(() => {
            dispatch(fetchAgentData({ agentId, api }));
          }, 1000);
        return stageNum;
      } else {
        toast.error(response?.data?.message || "Update failed", {
          theme: "dark",
        });
        return parseInt(stageNum);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      let message = error?.response?.data?.message || "Update failed";
      toast.error(message, { theme: "dark" });
      return thunkAPI.rejectWithValue(stageNum);
    }
  }
);


