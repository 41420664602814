import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../appconfig";
import useApi from "../../../custom-hooks/useApi";

import './Client.css';
import ClientDetails from "./ClientDetails";
import Spinner from "../../common/Spinner";
import LifeStyleGoals from "./LifeStyleGoals";
import { TbEdit } from "react-icons/tb";
import RelationShip from "./RelationShip";
import FinancialTab from "./FinancialTab";
import RoadMap from "./RoadMap";

import "./Client.css"
import { formatNumber } from "../../../utills";

function Client() {
    const api = useApi();
    const location = useLocation();
    const navigate = useNavigate()

    const [clientDetails, setClientDetails] = useState();
    const [loading, setLoading] = useState(false);

    const [activeTab, setActiveTab] = useState("details")

    useEffect(() => {
        loadAccount()
    }, [])

    const handleTabChange = (tabName) => {
        setActiveTab(tabName)
    }


    const loadAccount = async () => {
        try {
            setLoading(true)
            const response = await api.get(`${BASE_URL}/Account/Profile/${location?.state?.AccountID}`);

            if (response?.status === 200) {
                setClientDetails(response?.data)
            }

            setLoading(false)

        } catch (error) {
            setLoading(false)

        }
    }

    function formatUSPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove non-digit characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phoneNumber; // Return the original input if it doesn't match the expected pattern
    }

    const handleEditClient = () => {
        navigate("/clients/edit", { state: { AccountID: clientDetails?.data?.account?.AccountID } })
    }

    return (
        <div className="mx-4 p-4">
            {
                loading ? (<Spinner />) : null
            }
            <div className="bg-white p-6 rounded-lg">
                <p className='text-[#9CA3AF] text-base'>Account</p>
                <p className='text-[#1F2937] text-xl font-bold font-inter'>{clientDetails?.data?.account?.FirstName + " " + clientDetails?.data?.account?.LastName}</p>
                <div className="border-[1px] border-[#D1D5DB] mt-4 ">

                </div>
                <table className="w-full mt-4">
                    <thead>
                        <tr className="text-[#3A4253] font-semibold text-base font-inter">
                            <th className="text-left text-[#3A4253] font-semibold text-base font-inter">Phone</th>
                            <th className="text-left text-[#3A4253] font-semibold text-base font-inter">Retirement Need</th>
                            <th className="text-left text-[#3A4253] font-semibold text-base font-inter">Retirement Projection</th>
                            <th className="text-left text-[#3A4253] font-semibold text-base font-inter">Insurable Need</th>
                            <th className="text-left text-[#3A4253] font-semibold text-base font-inter">In-Force</th>
                            <th className="text-left text-[#3A4253] font-semibold text-base font-inter">Applications</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-[#4871B7] font-medium text-base ">
                            <td className="underline">{formatUSPhoneNumber(clientDetails?.data?.contact?.Mobile_Phone)}</td>
                            <td className="text-[#6B7280] text-base px-10">
                                {clientDetails?.data?.account?.RetirementNeed
                                    ? `$${formatNumber(clientDetails.data.account.RetirementNeed)}`
                                    : "-"
                                }
                            </td>

                            <td className="text-[#6B7280] text-base px-10">
                                {clientDetails?.data?.account?.RetirementProjection
                                    ? `$${formatNumber(clientDetails.data.account.RetirementProjection)}`
                                    : "-"
                                }
                            </td>
                            <td className="text-[#6B7280] text-base px-10">
                                {clientDetails?.data?.account?.RetirementProjection
                                    ? `$${formatNumber(clientDetails.data.account.TotalInsurableNeed)}`
                                    : "-"
                                }
                            </td>                            <td className="text-[#6B7280]  text-base px-10">-</td>
                            <td className="text-[#6B7280]  text-base px-10">-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="p-2 mt-10 bg-white">
                <div className="flex justify-between">
                    <div className="flex gap-2">
                        <button
                            onClick={() => {
                                handleTabChange("details")
                            }}
                            className={`px-4 py-2 rounded ${activeTab === "details"
                                ? 'text-[#4871B7] text-lg   font-bold'
                                : 'bg-white text-[#9CA3AF]'
                                }`}>
                            Details
                            <div className={`  ${activeTab === "details" ? "border bg-[#4871B7] h-[4px]" : ""}`}>

                            </div>
                        </button>
                        <button
                            onClick={() => {
                                handleTabChange("roadmap")
                            }}
                            className={`px-4 py-2 rounded ${activeTab === "roadmap"
                                ? 'text-[#4871B7] font-bold  '
                                : 'bg-white text-[#9CA3AF]'
                                }`}>
                            RoadMap
                            <div className={`  ${activeTab === "roadmap" ? "border bg-[#4871B7] h-[4px]" : ""}`}>

                            </div>
                        </button>
                        <button onClick={() => {
                            handleTabChange("relation")
                        }} className={`px-4 py-2 rounded ${activeTab === "relation"
                            ? 'text-[#4871B7] font-bold  '
                            : 'bg-white text-[#9CA3AF]'
                            }`}>
                            Relationships
                            <div className={`  ${activeTab === "relation" ? "border bg-[#4871B7] h-[4px]" : ""}`}>

                            </div>
                        </button>
                        <button onClick={() => {
                            handleTabChange("finance")
                        }} className={`px-4 py-2 rounded ${activeTab === "finance"
                            ? 'text-[#4871B7] font-bold  '
                            : 'bg-white text-[#9CA3AF]'
                            }`}>
                            Financial Accounts
                            <div className={`  ${activeTab === "finance" ? "border bg-[#4871B7] h-[4px]" : ""}`}>

                            </div>
                        </button>

                    </div>

                    {
                        activeTab === "details" ? (
                            <div className='text-[#4871B7] text-2xl p-4 '>
                                <TbEdit
                                    className="cursor-pointer"
                                    onClick={handleEditClient}
                                />
                            </div>
                        ) : null
                    }

                </div>

                <div>
                    {
                        activeTab === "details" ? (
                            <ClientDetails details={clientDetails?.data} />
                        ) : null
                    }

                    {
                        activeTab === "roadmap" ? (
                            <RoadMap AccountID={location?.state?.AccountID} account={clientDetails?.data?.account} loadAccount={loadAccount} />
                        ) : null
                    }

                    {
                        activeTab === "relation" ? (
                            <RelationShip AccountID={location?.state?.AccountID} />
                        ) : null
                    }

                    {
                        activeTab === "finance" ? (
                            <FinancialTab AccountID={location?.state?.AccountID} ContactID={location?.state?.ContactID} clients={location?.state?.clientList} />
                        ) : null
                    }
                </div>

            </div>

            {
                activeTab === "details" ? (
                    <div className="mt-4 ">
                        <LifeStyleGoals AccountID={location?.state?.AccountID} />
                    </div>
                ) : null
            }



        </div>
    )
}

export default Client;
