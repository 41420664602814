import React, { useState,useEffect } from 'react';
import tick from '../../../../../assets/tick.svg';
import { ReactComponent as EditIcon } from "../../../../../assets/edit.svg";
import { ReactComponent as MoreIcon } from "../../../../../assets/more-horizontal.svg";
import { ReactComponent as CancelIcon } from "../../../../../assets/cancel.svg";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { BASE_URL } from '../../../../../appconfig';
import useApi from '../../../../../custom-hooks/useApi';
import Spinner from '../../../../common/Spinner';
import { toast } from 'react-toastify';
import { LiaExclamationSolid } from "react-icons/lia";
import { v4 as uuidv4 } from 'uuid';
import PromotionPopup from './PromotionPopup';

const PromotionTableRow = ({
  NameTitle,
  IsActive,
  LicenseDate,
  ADate,
  AMADate,
  SADate,
  MDDate,
  Certified_field_Trainer,
  Leadership_Training,
  SMDDate,
  ID,
  Level_Description,
  Step_Description,
  Effective_Date,
  change,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedADate, setEditedADate] = useState(ADate);
  const [editedAMADate, setEditedAMADate] = useState(AMADate);
  const [editedLicenseDate, setEditedLicenseDate] = useState(LicenseDate);
  const [editedSADate, setEditedSADate] = useState(SADate);
  const [editedMDDate, setEditedMDDate] = useState(MDDate);
  const [editedCertifiedFieldTrainer, setEditedCertifiedFieldTrainer] = useState(Certified_field_Trainer);
  const [editedLeadershipTraining, setEditedLeadershipTraining] = useState(Leadership_Training);
  const [editedSMDDate, setEditedSMDDate] = useState(SMDDate);
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const dateInputStyle = "appearance-none block w-[85px] bg-neutral-100 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white";
  let pm = ''
  const formatDateString = (dateString) => {
    if (!dateString) {
      return null;
    }
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };   
  
  const FormattedDate = ({ date }) => {
    if (!date) {
      return <span>-</span>;
    }
    const dateObject = new Date(date);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString().slice(-2);
    const formattedDate = `${month}/${day}/${year}`;
    return <span>{formattedDate}</span>;
  };

  const CheckboxCell = ({ value }) => {
    if (value === true) {
      return (
        <div className="whitespace-nowrap font-inter">
          <img
            height="18px"
            width="18px"
            src={tick}
            alt="Tick"
            className="w-4 h-4 text-white"
          />
        </div>
      );
    } else {
      return <div className="whitespace-nowrap font-inter">-</div>;
    }
  };
  
  return (
    <>
      {loading && <Spinner />}
      <tr className="border-b dark:border-neutral-500 ">
        <td
          style={{ maxWidth: "150px", overflow: "hidden" }}
          className="truncate whitespace-nowrap px-4 py-5 font-inter"
        >
          {NameTitle}
        </td>
        <td className="whitespace-nowrap px-4 py-3 font-inter">
          {IsActive === "Y" ? "Yes" : IsActive === "N" ? "No" : "-"}
        </td>

        <td className="whitespace-nowrap px-4 py-3 font-inter">
          {Step_Description || "-"}
        </td>

        <td className={`whitespace-nowrap ${pm} px-4 font-inter`}>
          <FormattedDate date={AMADate} />
        </td>

        <td className={`whitespace-nowrap ${pm} px-4 font-inter`}>
          <FormattedDate date={LicenseDate} />
        </td>

        <td className="whitespace-nowrap px-4 py-3 font-inter">
          {Level_Description || "-"}
        </td>

        <td className="whitespace-nowrap px-4 py-3 font-inter">
          {Effective_Date || "-"}
        </td>

        {isEditing && (
          <PromotionPopup
            key={uuidv4()}
            setIsEdit={() => setIsEditing(false)}
            LicenseDate={LicenseDate}
            ADate={ADate}
            AMADate={AMADate}
            SADate={SADate}
            MDDate={MDDate}
            SMDDate={SMDDate}
            Certified_field_Trainer={Certified_field_Trainer}
            Leadership_Training={Leadership_Training}
            ID={ID}
            change={change}
          />
        )}

        <td className="whitespace-nowrap px-5 py-5 font-inter">
          {!isEditing && (
            <div
              className="flex flex-row gap-2 cursor-pointer"
              onClick={handleEditClick}
            >
              <MoreIcon />
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default PromotionTableRow;
