import React, { useEffect, useState } from 'react';
import { formatNumber } from '../../../utills';
import { FaAngleDown } from 'react-icons/fa6';
import { CiCircleInfo } from "react-icons/ci";
import AgeIncomeChart from './AgeIncomeChart';
import RetirementScale from './RetirementScale';
import { toast } from 'react-toastify';
import Spinner from '../../common/Spinner';
import useApi from '../../../custom-hooks/useApi';
import { BASE_URL, RETIREMENT_BASE_URL } from '../../../appconfig';
import axios from 'axios';


const RetirementCalculator = ({ AccountID, account, onSave }) => {
    const api = useApi();

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        CurrentAge: 35,
        AnnualIncome: 60000,
        CurrentSavings: 30000,
        MonthlyContribution: 500,
        MonthlyIncomeNeeded: 3500, // actual value of monthly income needed
        RetirementAge: 67,
        LifeExpectancy: 95,
        PreRetirementReturn: 0.06,
        PostRetirementReturn: 0.05,
        InflationRate: 0.03,
        IncomeIncrease: 0.02,
    });

    const [totalFutureValue, setTotalFutureValue] = useState(0);
    const [surplus, setSurplus] = useState(0);
    const [requiredSavings, setRequiredSavings] = useState(0);

    const [errors, setErrors] = useState({})

    const [chartData, setChartData] = useState({ labels: [], datasets: [] });

    const [showAdvanceDetails, setShowAdvanceDetails] = useState(true)

    const [incomePercantage, setIncomePercantage] = useState(.07)

    const [retirementAge, setRetirementAge] = useState(formData?.RetirementAge);
    const [MonthlyContribution, setMonthlyContribution] = useState(formData?.MonthlyContribution);
    const [MonthlyIncomeNeeded, setMonthlyIncomeNeeded] = useState(formData?.MonthlyIncomeNeeded);

    const [activeButton, setActiveButton] = useState('Graph');

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };


    useEffect(() => {
        loadRetirementValues()
        // calculateRetirement()
    }, [])


    useEffect(() => {
        // generateChartData()
        // setChartData(generateChartData());
        calculateRetirement()
    }, [formData])

    useEffect(() => {
        const defaultMonthlyIncomeNeeded = (formData.AnnualIncome * 0.7) / 12;
        setFormData((prevData) => ({
            ...prevData,
            MonthlyIncomeNeeded: defaultMonthlyIncomeNeeded,
        }));
    }, [formData.AnnualIncome]);

    const handleChange = (e) => {

        const { name, value } = e.target;
        setErrors({
            ...errors,
            [name]: ""
        })
        setFormData({
            ...formData,
            [name]: parseFloat(value) || value,
        });
    };

    const calculateRetirement = async () => {
        const {
            CurrentAge,
            AnnualIncome,
            CurrentSavings,
            MonthlyContribution,
            MonthlyIncomeNeeded,
            RetirementAge,
            LifeExpectancy,
            PreRetirementReturn,
            PostRetirementReturn,
            InflationRate,
        } = formData;

        // const yearsUntilRetirement = RetirementAge - CurrentAge;
        // const retirementYears = LifeExpectancy - RetirementAge;

        // // Future Value of Current Savings
        // const futureValueSavings = CurrentSavings * Math.pow(1 + PreRetirementReturn, yearsUntilRetirement);

        // // Future Value of Monthly Contributions
        // const monthlyRate = PreRetirementReturn / 12;
        // const numberOfMonths = yearsUntilRetirement * 12;
        // const futureValueContributions =
        //     MonthlyContribution * ((Math.pow(1 + monthlyRate, numberOfMonths) - 1) / monthlyRate);

        // // Total Future Value
        // const totalFutureValue = futureValueSavings + futureValueContributions;

        // // Adjust Monthly Income Needed for Inflation
        // const adjustedMonthlyIncomeNeeded = MonthlyIncomeNeeded * Math.pow(1 + InflationRate, yearsUntilRetirement);

        // // Calculate Required Savings
        // const postMonthlyRate = PostRetirementReturn / 12;
        // const numberOfMonthsInRetirement = retirementYears * 12;
        // const requiredSavings =
        //     adjustedMonthlyIncomeNeeded * ((1 - Math.pow(1 + postMonthlyRate, -numberOfMonthsInRetirement)) / postMonthlyRate);


        const response = await axios.post(`${RETIREMENT_BASE_URL}/retirement/calculate`, {
            formData,
            agencyID: 1
        });
        if (response?.status === 200) {
            setTotalFutureValue(response?.data?.totalFutureValue);
            setRequiredSavings(response?.data?.requiredSavings)
            generateChartData()
        }

        // return {
        //     totalFutureValue,
        //     requiredSavings,
        //     surplus: totalFutureValue - requiredSavings,
        // };
    };

    // const { totalFutureValue, requiredSavings, surplus } = calculateRetirement();



    const toggleAdvanceDetails = () => {
        setShowAdvanceDetails(!showAdvanceDetails)
    }

    const generateChartData = () => {

        const { CurrentAge, RetirementAge, LifeExpectancy, MonthlyContribution, MonthlyIncomeNeeded, PreRetirementReturn, PostRetirementReturn, InflationRate } = formData;

        const ages = [];
        const whatYoullHave = [];
        const whatYoullNeed = [];

        let currentAge = CurrentAge;
        let futureValueSavings = formData.CurrentSavings;
        let futureValueContributions = 0;
        let isRetirement = false;

        const monthlyRate = PreRetirementReturn / 12;


        while (currentAge <= LifeExpectancy) {
            ages.push(currentAge);

            if (currentAge <= RetirementAge) {
                const yearsUntilRetirement = currentAge - CurrentAge;
                const numberOfMonths = yearsUntilRetirement * 12;
                // Before retirement
                futureValueSavings = formData.CurrentSavings * Math.pow(1 + PreRetirementReturn, yearsUntilRetirement);

                futureValueContributions = MonthlyContribution * ((Math.pow(1 + monthlyRate, numberOfMonths) - 1) / monthlyRate);
                whatYoullHave.push(futureValueSavings + futureValueContributions);

                // Calculate what you'll need
                const adjustedMonthlyIncomeNeeded = MonthlyIncomeNeeded * Math.pow(1 + InflationRate, yearsUntilRetirement);
                whatYoullNeed.push(adjustedMonthlyIncomeNeeded * ((1 - Math.pow(1 + PostRetirementReturn / 12, -(LifeExpectancy - RetirementAge) * 12)) / (PostRetirementReturn / 12)));
            } else {
                // After retirement
                if (!isRetirement) {
                    // Set initial values for retirement
                    futureValueContributions = 0; // No more contributions
                    isRetirement = true;
                }

                futureValueSavings = futureValueSavings * (1 + PostRetirementReturn) - MonthlyIncomeNeeded * 12;
                whatYoullHave.push(futureValueSavings);

                // Calculate what you'll need with adjusted inflation
                const adjustedMonthlyIncomeNeeded = MonthlyIncomeNeeded * Math.pow(1 + InflationRate, currentAge - RetirementAge);
                whatYoullNeed.push(adjustedMonthlyIncomeNeeded * ((1 - Math.pow(1 + PostRetirementReturn / 12, -(LifeExpectancy - RetirementAge) * 12)) / (PostRetirementReturn / 12)));
            }

            currentAge += 16;
        }



        setChartData(
            {
                labels: ages,
                datasets: [
                    {
                        label: `What You'll Have`,
                        data: whatYoullHave,
                        borderColor: 'green',
                        backgroundColor: 'rgba(0, 255, 0, 0.2)',
                        fill: true,
                        tension: 0.4, // Curvy line
                    },
                    {
                        label: `What You'll Need`,
                        data: whatYoullNeed,
                        borderColor: 'blue',
                        backgroundColor: 'rgba(0, 0, 255, 0.2)',
                        fill: true,
                        tension: 0.4, // Curvy line
                    }
                ]
            }

        )

        // return {

        // };
    };

    const handleRetirementAgeChange = (newRetirementAge) => {
        setRetirementAge(newRetirementAge);
        setFormData((prevData) => ({
            ...prevData,
            RetirementAge: newRetirementAge,
        }));
    };

    const handleMonthlyContriChange = (value) => {
        setMonthlyContribution(value);
        setFormData((prevData) => ({
            ...prevData,
            MonthlyContribution: value,
        }));
    };


    const handleMonthlyIncomeChange = (value) => {
        setMonthlyIncomeNeeded(value);
        setFormData((prevData) => ({
            ...prevData,
            MonthlyIncomeNeeded: value,
        }));
    };

    const validate = () => {
        const newErrors = {};

        const {
            CurrentAge,
            AnnualIncome,
            CurrentSavings,
            MonthlyContribution,
            RetirementAge,
            LifeExpectancy
        } = formData

        if (!CurrentAge) {
            newErrors["CurrentAge"] = "Current age is required"
        }

        if (!AnnualIncome) {
            newErrors["AnnualIncome"] = "Annual Income is required"
        }

        if (!CurrentSavings) {
            newErrors["CurrentSavings"] = "Curent retirement savings is required"
        }

        if (!MonthlyContribution) {
            newErrors["MonthlyContribution"] = "Monthly Contribution is required"
        }


        if (!RetirementAge) {
            newErrors["RetirementAge"] = "Retirement Age is required"
        }


        if (!LifeExpectancy) {
            newErrors["LifeExpectancy"] = "Life Expectancy  savings is required"
        }


        setErrors(newErrors)

        return Object.keys(newErrors).length === 0; // return true if no errors


    }

    const handleSubmit = async () => {
        try {
            if (!validate()) return;

            setLoading(true)
            const RetirementInfo = {
                ...formData,
                RetirementNeed: totalFutureValue,
                RetirementProjection: requiredSavings
            }
            const response = await api.post(`${BASE_URL}/Account/Retirement/${AccountID}`, { RetirementInfo });
            if (response.status === 200) {
                onSave()
                toast.success("Retirement Information updated", {
                    theme: "dark",
                });
            } else {
                toast.error("Failed to update client Retirement Information", {
                    theme: "dark",
                });
            }
            setLoading(false)

        } catch (error) {
            console.log({ error });
            toast.error("Internal server error")
        }
    }

    const loadRetirementValues = () => {

        const {
            CurrentNetWorth,
            CurrentAge,
            TargetRetirementAge,
            MonthlyIncomeNeeded,
            MonthlyContribution,
            LifeExpectancy,
            CurrentSavings
            // PreRetirementReturn,
            // PostRetirementReturn,
            // IncomeIncrease,
            // InflationRate

        } = account
        if (CurrentNetWorth || CurrentAge || TargetRetirementAge || LifeExpectancy || MonthlyContribution || CurrentSavings) {
            setFormData({
                ...formData,
                AnnualIncome: CurrentNetWorth ? CurrentNetWorth : 60000,
                CurrentAge: CurrentAge ? CurrentAge : 35,
                RetirementAge: TargetRetirementAge ? TargetRetirementAge : 67,
                MonthlyIncomeNeeded: MonthlyIncomeNeeded ? MonthlyIncomeNeeded : 3500,
                MonthlyContribution: MonthlyContribution ? MonthlyContribution : 500,
                LifeExpectancy: LifeExpectancy ? LifeExpectancy : 95,
                CurrentSavings: CurrentSavings ? CurrentSavings : 30000
                // PreRetirementReturn,
                // PostRetirementReturn,
                // IncomeIncrease,
                // InflationRate
            })

        }

        generateChartData()

    }

    return (
        <div className="p-4 flex gap-4 bg-white shadow-md rounded-lg font-inter">
            {
                loading ? (<Spinner />) : null
            }
            <form className="space-y-4 w-1/4">
                <h2 className="text-xl font-bold mb-4">Enter Your Information</h2>
                <div className="flex flex-col">
                    <label htmlFor="CurrentAge" className="font-semibold text-[#4B5563] text-base">Current Age</label>
                    <input
                        type="number"
                        id="CurrentAge"
                        name="CurrentAge"
                        value={formData.CurrentAge}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded"
                    />
                    {errors.CurrentAge?.length > 0 && (
                        <p className="text-red-500 text-xs mt-1">{errors?.CurrentAge}</p>
                    )}
                </div>
                <div className="flex flex-col">
                    <label htmlFor="AnnualIncome" className="font-semibold text-[#4B5563] text-base">Annual pre-tax income</label>
                    <input
                        type="number"
                        id="AnnualIncome"
                        name="AnnualIncome"
                        value={formData.AnnualIncome}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded"
                    />
                    {errors.AnnualIncome?.length > 0 && (
                        <p className="text-red-500 text-xs mt-1">{errors?.AnnualIncome}</p>
                    )}

                </div>
                <div className="flex flex-col">
                    <label htmlFor="CurrentSavings" className="font-semibold text-[#4B5563] text-base">Curent retirement savings</label>
                    <input
                        type="number"
                        id="CurrentSavings"
                        name="CurrentSavings"
                        value={formData.CurrentSavings}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded"
                    />
                    {errors.CurrentSavings?.length > 0 && (
                        <p className="text-red-500 text-xs mt-1">{errors?.CurrentSavings}</p>
                    )}
                </div>
                <div className="flex flex-col">
                    <label htmlFor="MonthlyContribution" className="font-semibold text-[#4B5563] text-base">Monthly Contribution:</label>
                    <input
                        type="number"
                        id="MonthlyContribution"
                        name="MonthlyContribution"
                        value={formData.MonthlyContribution}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded"
                    />
                    <div className="mt-2 text-[#9CA3AF]">
                        10% of monthly income
                    </div>
                    {errors.MonthlyContribution?.length > 0 && (
                        <p className="text-red-500 text-xs mt-1">{errors?.MonthlyContribution}</p>
                    )}
                </div>
                <div className="flex flex-col">
                    <label htmlFor="MonthlyIncomeNeeded" className="font-semibold text-[#4B5563] text-base">Monthly retirement budget</label>
                    <input
                        disabled
                        type="number"
                        id="MonthlyIncomeNeeded"
                        name="MonthlyIncomeNeeded"
                        value={formData.MonthlyIncomeNeeded}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded"
                    />
                    <div className="text-[#9CA3AF] mt-2">
                        70% of pre-retirement income
                    </div>
                    {/* {errors.MonthlyIncomeNeeded?.length > 0 && (
                        <p className="text-red-500 text-xs mt-1">{errors?.MonthlyIncomeNeeded}</p>
                    )} */}
                </div>

                <div

                    className='flex gap-2'
                >
                    <div
                        onClick={toggleAdvanceDetails}
                        className='flex gap-2 text-[#1F293] text-xl font-semibold cursor-pointer'
                    >
                        <FaAngleDown className='mt-1' />
                        <div>Advanced Details</div>

                    </div>
                    <div className="text-xl font-semibold mt-1 ">
                        <CiCircleInfo />

                    </div>

                </div>
                {
                    showAdvanceDetails ? (
                        <div>
                            <div className="flex flex-col">
                                <label htmlFor="RetirementAge" className="font-semibold">Retirement Age:</label>
                                <input
                                    type="number"
                                    id="RetirementAge"
                                    name="RetirementAge"
                                    value={formData.RetirementAge}
                                    onChange={handleChange}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                                {errors?.RetirementAge?.length > 0 && (
                                    <p className="text-red-500 text-xs mt-1">{errors?.RetirementAge}</p>
                                )}
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="LifeExpectancy" className="font-semibold">Life Expectancy:</label>
                                <input
                                    type="number"
                                    id="LifeExpectancy"
                                    name="LifeExpectancy"
                                    value={formData.LifeExpectancy}
                                    onChange={handleChange}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                                {errors?.LifeExpectancy?.length > 0 && (
                                    <p className="text-red-500 text-xs mt-1">{errors?.LifeExpectancy}</p>
                                )}
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="PreRetirementReturn" className="font-semibold">Pre-Retirement Rate of Return (%):</label>
                                <input
                                    type="number"
                                    id="PreRetirementReturn"
                                    name="PreRetirementReturn"
                                    step="0.01"
                                    value={formData.PreRetirementReturn * 100}
                                    disabled
                                    onChange={(e) => handleChange({ target: { name: 'PreRetirementReturn', value: e.target.value / 100 } })}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="PostRetirementReturn" className="font-semibold">Post-Retirement Rate of Return (%):</label>
                                <input
                                    type="number"
                                    id="PostRetirementReturn"
                                    name="PostRetirementReturn"
                                    step="0.01"
                                    disabled
                                    value={formData.PostRetirementReturn * 100}
                                    onChange={(e) => handleChange({ target: { name: 'PostRetirementReturn', value: e.target.value / 100 } })}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="InflationRate" className="font-semibold">Inflation Rate (%):</label>
                                <input
                                    type="number"
                                    id="InflationRate"
                                    name="InflationRate"
                                    step="0.01"
                                    disabled
                                    value={formData.InflationRate * 100}
                                    onChange={(e) => handleChange({ target: { name: 'InflationRate', value: e.target.value / 100 } })}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="IncomeIncrease" className="font-semibold">Annual Income Increase (%):</label>
                                <input
                                    type="number"
                                    id="IncomeIncrease"
                                    name="IncomeIncrease"
                                    step="0.01"
                                    disabled
                                    value={formData.IncomeIncrease * 100}
                                    onChange={(e) => handleChange({ target: { name: 'IncomeIncrease', value: e.target.value / 100 } })}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>
                        </div>
                    ) : null
                }

                <div className='flex gap-2'>
                    {/* <button
                        // onClick={calculateRetirement}
                        style={{
                            background:
                                "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                            height: "2.6rem",
                            flex: 1, // Take equal space
                        }}
                        className="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline border-none"
                        type="button"
                    >
                        Calculate
                    </button> */}

                    <button
                        onClick={handleSubmit}
                        style={{
                            background:
                                "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                            height: "2.6rem",
                            flex: 1, // Take equal space
                        }}
                        className="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline border-none"
                        type="button"
                    >
                        Save
                    </button>
                </div>


            </form>

            {/* RESULT AND GRAPH */}
            <div className="mt-16 w-3/4">
                <div className="p-4 bg-[#E5E7EB] rounded-lg">
                    <div className="text-3xl font-semibold text-[#1F2937]">Monthly retirement budget at age {formData.RetirementAge}</div>
                    <div className="flex space-x-40 mt-2">
                        <div>
                            <p className="text-[#9CA3AF] text-sm font-semibold">What you'll have</p>
                            <p className="mt-2 text-[#6B7280] text-xl font-semibold">${formatNumber(totalFutureValue / 12)}/ mo</p>
                        </div>

                        <div>
                            <p className="text-[#9CA3AF] text-sm font-semibold">Required savings</p>
                            <p className="mt-2 text-[#6B7280] text-xl font-semibold">${formatNumber(requiredSavings / 12)} / mo</p>
                        </div>
                    </div>
                    <div className='text-[#4871B7] font-semibold text-xl mt-10'>
                        How did we calculate your results?
                    </div>
                </div>

                <div className=" bg-[#E5E7EB] rounded-lg mt-6 p-6">
                    <div className='flex'>
                        <div>
                            <button
                                className={`px-4 py-2 bg-[#E5E7EB] rounded font-semibold font-inter ${activeButton === 'Graph'
                                    ? ' text-[#4871B7]'
                                    : '  text-[#9CA3AF]'
                                    }`}
                                onClick={() => handleButtonClick('Graph')}
                            >
                                Graph view
                            </button>
                            {
                                activeButton === "Graph" ? (
                                    <div className='bg-[#4871B7] h-1 mt-2'></div>
                                ) : null
                            }
                        </div>

                        <div>
                            <button
                                className={`px-4 py-2 bg-[#E5E7EB] rounded font-semibold font-inter ${activeButton === 'Summary'
                                    ? ' text-[#4871B7]'
                                    : ' text-[#9CA3AF] '
                                    }`}
                                onClick={() => handleButtonClick('Summary')}
                            >
                                Summary View
                            </button>
                            {
                                activeButton === "Summary" ? (
                                    <div className='bg-[#4871B7] h-1 mt-2'></div>
                                ) : null
                            }
                        </div>

                    </div>
                    <AgeIncomeChart data={chartData} />
                </div>

                <div className="mt-6">
                    <RetirementScale
                        retirementAge={retirementAge}
                        onRetirementAgeChange={handleRetirementAgeChange}
                        MonthlyIncomeNeeded={MonthlyIncomeNeeded}
                        MonthlyContribution={MonthlyContribution}
                        handleMonthlyContriChange={handleMonthlyContriChange}
                        handleMonthlyIncomeChange={handleMonthlyIncomeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default RetirementCalculator;
