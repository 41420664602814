import React from 'react'
import clientsLandingSvg from '../../assets/landingScreensSvg/WealthSmyth Roadmap_2 (1) 1.svg'
import ellipse from '../../assets/landingScreensSvg/clients/users (1).svg'
import group from '../../assets/landingScreensSvg/clients/Group 902.svg'
import users from '../../assets/landingScreensSvg/clients/users.svg'
import { useDispatch } from 'react-redux'
import { setVisitedPages } from '../../redux/slices/commonSlice'

const ClientsLanding = () => {

  const dispatch = useDispatch();

  return (
    <div className="flex items-center justify-center bg-gray-100">
    <div className="flex flex-col items-center m-5 mt-8 bg-white p-3 rounded-lg  w-[90%] h-[85vh] border border-[#E5E7EB] ">
      <div className="flex flex-row items-center w-full">
        {/* Image Part */}
        <div className="flex w-[60%]">
          <img
            src={clientsLandingSvg}
            alt="Landing Image"
            className="rounded-l-lg object-cover"
          />
        </div>
        {/* Content Part */}
        <div className="w-[40%] p-8 flex flex-col justify-start w-10/12">
          <div>
            <h1 className="text-2xl font-semibold text-gray-800 mb-2">
            The Road to Wealth
            </h1>
            <p className="text-base font-medium text-gray-500 mb-6">
            The Client Center allows you to keep track of all the families and companies that do business with you.
            </p>
          </div>

          <div>
            <div className="flex flex-row my-2">
              <img
                src={ellipse}
                alt="Landing Image"
              />
              <h1 className="text-xl font-semibold text-gray-800 ml-2">
              What’s an Account?
              </h1>
            </div>
            <p className="text-base font-medium text-gray-500 mb-6 ml-8">
            An Account is a record that helps you keep track of all the information about an individual.
            </p>
          </div>

          <div>
            <div className="flex flex-row my-2">
              <img
                src={users}
                alt="Landing Image"
              />
              <h1 className="text-xl font-semibold text-gray-800 ml-2">
              What’s a Parent Account?
              </h1>
            </div>
            <p className="text-base font-medium text-gray-500 mb-6 ml-8">
            A Parent Account is a record that helps you keep track of groups of Accounts that are linked as part of the same household or business.
            </p>
          </div>

          <div>
            <div className="flex flex-row my-2">
              <img
                src={group}
                alt="Landing Image"
              />
              <h1 className="text-xl font-semibold text-gray-800 ml-2">
              What’s a Wealth Roadmap?
              </h1>
            </div>
            <p className="text-base font-medium text-gray-500 mb-6 ml-8">
            WealthSmythy’s 7 Wealth Milestones provide roadmap for helping clients achieve financial stability, lifestyle dreams and freedom while avoiding risk.
            </p>
          </div>
          <button className="text-base font-semibold bg-[#4971B8] text-white px-6 py-3 w-full rounded-md" type='button' onClick={() => dispatch(setVisitedPages(window.location.pathname))}>
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ClientsLanding;