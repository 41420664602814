import React from "react";

const joinToTeamCard = () => {
  const points = {
    Essentials: [
      "Team Productivity and Sharing",
      "Gamification and Badges",
      "Digital Playbook",
      "Access Training Center",
      "Lead Management",
    ],
    Professional: [
        "Everything in Essentials, PLUS:",
        "Taprooting Tools",
        "Promotion Gamification",
        "Downline Tracking",
        "Lead Generation",
      ],
      Owner: [
        "Everything in Professional, PLUS:",
        "Back Office Management ",
        "Full Team Tracking",
        "Create Training Courses",
        "Lead Engagement",
      ],
  };
  return (
    <>
      <div className="flex flex-row justify-between flex-wrap gap-7">
        <Card header="Essentials" points={points.Essentials} btnName="Join a Team" />
        <Card header="Professional" points={points.Professional} btnName="Join a Team" />
        <Card header="Owner" points={points.Owner} btnName="Start a Team" />
      </div>
    </>
  );
};

export default joinToTeamCard;

const Card = ({ header, points, btnName }) => {
  return (
    <div className="w-[360px] h-[450px] rounded-[8px] shadow-lg p-8 flex flex-col justify-between bg-gray-200">
      <div>
        <h2 className="font-bold text-[34px] leading-[41.15px] text-gray-700 mb-7">
          {header}
        </h2>
        <ul className="gap-2">
          {points.map((point, index) => (
            <li key={index} className="flex items-center">
              <span className="inline-block w-2 h-2 rounded-full bg-[#4871B7] mr-2"></span>
              <span className="font-normal text-[18px] leading-[40px] text-gray-600">
                {point}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <button className="bg-[#2BB574] text-white py-2 px-4 rounded">
        {btnName}
      </button>
    </div>
  );
};


export const AddOnCard = ({title, about}) => {
    return (
      <div className="w-[348px] h-[147px] flex flex-col rounded-[8px] p-4 bg-white">
        <h2 className="font-semibold text-[20px] leading-[24.2px]">
          {title}
        </h2>
        <p className="font-medium text-[16px] leading-[19.36px] mt-2">
          {about}
        </p>
      </div>
    );
  };

export const  CloudCard = ({heading, about, listheading, allList, img}) => {
    return (
        <div className="w-[531px] h-[500px] bg-white rounded-lg shadow-md flex flex-col p-10">
        {/* Heading */}
        <h3 className="flex flex-row gap-3 text-[#4871B7] font-bold text-3xl leading-tight mb-4">
        <img src={img} alt=""/>
          {heading}
        </h3>
        {/* Paragraph */}
        <p className="text-black font-semibold text-lg leading-relaxed mb-4 w-[80%]">
          {about}
        </p>
        <p className="text-black font-semibold text-lg leading-relaxed mb-4">
          {listheading}
        </p>
        {/* Bullet points */}
        <ul className="text-left">
            {allList.map((val, idx) => {
                return (
                <li className="text-lg leading-relaxed" key={idx}>
                    <span className="inline-block w-2 h-2 rounded-full bg-[#4871B7] mr-2"></span>
                    <span className="font-normal text-[18px] leading-[40px] text-gray-400">{val}</span>
                </li>
                )
            })}
        </ul>
      </div>
    )
} 