import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";

import { ReactComponent as SaveIcon } from "../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../src/assets/cancel.svg";

import useApi from "../../../custom-hooks/useApi";
import { BASE_URL } from "../../../appconfig";
import Spinner from "../../common/Spinner";

const FinancePolicyForm = ({
  AccountID,
  ContactID,
  toggle,
  onSaveSuccess,
  productList,
}) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    InsuredID: null,
    OwnerContactID: ContactID,
    ProductTypeID: null,
    PremiumAmount: null,
    CashValue: null,
    DeathBenefit: null,
    ProviderID: null,
  });
  const [insuredList, setInsuredList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevData) => ({ ...prevData, [name]: "" }));

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.InsuredID) newErrors.InsuredID = "Insured person is required";
    if (!formData.ProductTypeID)
      newErrors.ProductTypeID = "Product Type is required";
    if (!formData.ProviderID) newErrors.ProviderID = "Provider is required";
    if (!formData.CashValue) newErrors.CashValue = "Cash value is required";
    if (!formData.PremiumAmount)
      newErrors.PremiumAmount = "Premium amount is requied";
    if (!formData.DeathBenefit)
      newErrors.DeathBenefit = "Death benefit is requied";
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }
    setLoading(true);
    await saveFinancePolicy();
    setLoading(false);
  };

  const saveFinancePolicy = async () => {
    try {
      const response = await api.post(
        `${BASE_URL}/Account/FinancialPolicy/${AccountID}`,
        formData
      );
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setTimeout(() => {
          setLoading(false);
          toggle();
          onSaveSuccess();
        }, 1000);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchClientsData();
    fetchProvidersData();
    // fetchProductType()
  }, []);

  const fetchClientsData = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `${BASE_URL}/Account/Related_People/${AccountID}`
      );
      if (response?.status === 200) {
        const result = response?.data?.data;
        setInsuredList(result);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchProvidersData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${BASE_URL}/Organization/Provider`);
      if (response?.status === 200) {
        setProviderList(response?.data?.provider);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <tr className="border=[#D1D5DB] border-b">
      {loading && <Spinner />}

      <td className="px-3 py-2">
        <select
          name="InsuredID"
          value={formData.InsuredID}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-400 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        >
          <option value="">Select Insured Person</option>
          {insuredList?.map((insuredItem, index) => (
            <option
              key={`${formData.InsuredID}-${index}`}
              value={insuredItem?.RelatedPersonId}
            >
              {insuredItem?.ContactDetails?.First_Name +
                " " +
                insuredItem?.ContactDetails?.Last_Name}
            </option>
          ))}
        </select>
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.InsuredID ? "visible" : "invisible"
          }`}
        >
          {errors.InsuredID || " "}
        </p>
      </td>

      <td className="px-3 py-2">
        <select
          name="ProviderID"
          value={formData.ProviderID}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-400 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        >
          <option value="">Select Provider</option>
          {providerList?.map((providerItem) => (
            <option key={providerItem?.ID} value={providerItem?.ProviderID}>
              {providerItem?.ProviderName}
            </option>
          ))}
        </select>
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.ProviderID ? "visible" : "invisible"
          }`}
        >
          {errors.ProviderID || " "}
        </p>
      </td>

      <td className="px-3 py-2">
        <select
          name="ProductTypeID"
          value={formData.ProductTypeID}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-400 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        >
          <option value="">Select Product Type</option>
          {productList?.map((productItem) => (
            <option
              key={productItem?.ProductTypeID}
              value={productItem?.ProductTypeID}
            >
              {productItem?.ProductTypeName?.slice(0, 20)}
            </option>
          ))}
        </select>
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.ProductTypeID ? "visible" : "invisible"
          }`}
        >
          {errors.ProductTypeID || " "}
        </p>
      </td>

      <td className="px-3 py-2">
        <input
          name="PremiumAmount"
          placeholder="Premium Amount"
          type="number"
          value={formData.PremiumAmount}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        />
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.PremiumAmount ? "visible" : "invisible"
          }`}
        >
          {errors.PremiumAmount || " "}
        </p>
      </td>

      <td className="px-3 py-2">
        <input
          name="CashValue"
          placeholder="Cash Value"
          type="number"
          value={formData.CashValue}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        />
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.CashValue ? "visible" : "invisible"
          }`}
        >
          {errors.CashValue || " "}
        </p>
      </td>

      <td className="px-3 py-2">
        <input
          name="DeathBenefit"
          placeholder="Death Benefit"
          type="number"
          value={formData.DeathBenefit}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        />
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.DeathBenefit ? "visible" : "invisible"
          }`}
        >
          {errors.DeathBenefit || " "}
        </p>
      </td>

      <td className="p-4 flex gap-2">
        <div className="flex gap-2">
          <button
            className="disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleSubmit}
          >
            <SaveIcon />
          </button>
          <CancelIcon className="cursor-pointer" onClick={toggle} />
        </div>
      </td>
    </tr>
  );
};

export default FinancePolicyForm;
