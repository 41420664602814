import React from "react";
import homeLandingSvg from "../../assets/landingScreensSvg/Timeline Graphic 2 1.svg";
import { useDispatch } from "react-redux";
import { setVisitedPages } from "../../redux/slices/commonSlice";

function HomeLanding() {
   const dispatch = useDispatch();
  return (
    <div className="flex items-center justify-center bg-gray-100">
      <div className="flex flex-col items-center m-5 mt-8 bg-white p-3 rounded-lg  w-[90%] h-[85vh] border border-[#E5E7EB]">
        <div className="flex flex-col items-center">
          {/* Image Part */}
          <div className="flex w-[85%]">
            <img
              src={homeLandingSvg}
              alt="Landing Image"
              className="rounded-l-lg object-cover"
            />
          </div>
          {/* Content Part */}
          <div className="w-full p-8 flex flex-col justify-start w-10/12">
            <h1 className="text-2xl font-semibold text-gray-800 mb-4">
              Let’s get growing
            </h1>
            <p className="text-base font-medium text-gray-500 mb-6">
              This is your home base, with your Digital Playbook for growing
              from a brand new agent into an agency owner.
            </p>
            <button
              className="text-base font-semibold bg-[#4971B8] text-white px-6 py-3 w-[190px] rounded-md"
              type="button"
              onClick={() =>
                dispatch(setVisitedPages(window.location.pathname))
              }
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeLanding;
