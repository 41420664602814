import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import dropDown from "../../../../assets/grayDrowDown.svg";

const CountryStateDropdown = ({ data, stateClick }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleStateClick = (state) => {
    setSelectedState(state.name);
    stateClick({
        HomeCountry: state?.country?.id === 39 ? "Canada" : "United States",
        HomeState: state?.name
      });
    setIsDropdownOpen(false);
  };


  const filteredResults = Object.entries(data).reduce(
    (acc, [country, states]) => {
      const matchingStates = states.filter((state) =>
        state.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (matchingStates.length > 0) {
        acc.push({
          country,
          matchingStates,
        });
      }
      return acc;
    },
    []
  );

  const MAX_DISPLAY_ENTRIES = 10;

  return (
    <div className="relative inline-block text-left w-full">
      <div>
        <div className="relative">
        <div
        className="flex justify-between items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className="text-gray-400 font-medium text-sm leading-5">
          {selectedState ? selectedState : 'Select'}
        </div>
        <img src={dropDown} alt="" />
      </div>

          {isDropdownOpen && (
            <div className="absolute bg-white max-h-[500px] overflow-scroll rounded-md shadow-md w-full z-10 pb-2"
            onMouseLeave={() => setIsDropdownOpen(false)}>
              <div className="py-1">
                <div className="relative px-1">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <FiSearch className="text-gray-500" />
                  </span>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-input py-2 pl-8 pr-4 w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    placeholder="Search state..."
                  />
                </div>
                {filteredResults.length === 0 ? (
                  <p className="block px-4 py-2 text-sm text-gray-500">
                    No states found
                  </p>
                ) : (
                  filteredResults
                    .slice(0, MAX_DISPLAY_ENTRIES)
                    .map(({ country, matchingStates }) => (
                      <div key={country}>
                        <p className="block px-4 py-2 text-sm font-bold text-gray-700">
                          {country}
                        </p>
                        <div className="grid grid-cols-1 gap-1">
                          {matchingStates.map((state) => (
                            <p
                              key={uuidv4()}
                              className="hover:bg-[#4871B7] hover:text-white cursor-pointer block px-4 py-2 text-sm text-gray-500 ease-in-out"
                              onClick={() => handleStateClick(state)}
                            >
                              {state.name}
                            </p>
                          ))}
                        </div>
                      </div>
                    ))
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountryStateDropdown;
