import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAgentData,
  fetchExchangeLegData,
  fetchAllAgentData,
  updateAgentData,
  fetchAgentStepData,
  fetchAllStepData,
  updateAgentCompletedStep,
  fetchAllPartnersMapping
} from "../thunks/agentThunk";

const agentSlice = createSlice({
  name: "agent",
  initialState: {
    data: null,
    loading: false,
    error: null,
    agentId: null,
    allAgentData: [],
    exchangeLegData: [],
    allStepData: [],
    currentStepData: [],
    formData: null,
    currentStep: 0,
    completedStep: 0,
    showCongratulations: false,
    partnersMapping: [],
    promotionStageStatus: {},
    completedStages: [],
    backOffice: { isChanged: false },
    actionType: "mark-complete",
    exchangeLegSelected: false,
    refreshProfile: false,
    refreshAgent: false,
    refreshEntity: false,
    refreshRelation: false,
  },
  reducers: {
    setAgentData: (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        loading: false,
        error: null,
      };
    },
    setBackOffice: (state, action) => {
      state.backOffice = action.payload;
    },
    setFormData: (state, action) => {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setAgentId: (state, action) => {
      state.agentId = action.payload;
    },
    setExchangeLegData: (state, action) => {
      state.exchangeLegData = action.payload;
    },
    setAllAgentData: (state, action) => {
      state.allAgentData = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    updateAgentData: (state, action) => {
      state.data = action.payload;
      state.formData = action.payload;
      state.loading = false;
      state.error = null;
    },
    setAllStepData: (state, action) => {
      state.allStepData = action.payload;
    },
    setCurrentStepData: (state, action) => {
      return {
        ...state,
        currentStepData: {
          ...state.formData,
          ...action.payload,
        },
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    },
    setPromotionStageStatus: (state, action) => {
      return {
        ...state,
        promotionStageStatus: {
          ...state.promotionStageStatus,
          ...action.payload,
        },
      };
    },
    setCompletedData: (state, action) => {
      state.completedStep = action.payload;
    },
    setShowCongratulations: (state, action) => {
      state.showCongratulations = action.payload;
    },
    setPartnerMapping: (state, action) => {
      state.partnersMapping = action.payload;
    },
    setCompletedStages: (state, action) => {
      state.completedStages = action.payload;
    },
    setActionType: (state, action) => {
      state.actionType = action.payload;
    },
    setExchangeLegSelected: (state, action) => {
      state.exchangeLegSelected = action.payload;
    },
    setRefreshProfile: (state, action) => {
      state.refreshProfile = action.payload;
    },
    setRefreshAgent: (state, action) => {
      state.refreshAgent = action.payload;
    },
    setRefreshEntity: (state, action) => {
      state.refreshEntity = action.payload;
    },
    setRefreshRelation: (state, action) => {
      state.refreshRelation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentData.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(fetchAgentData.fulfilled, (state, action) => {
        // state.loading = false;
        state.data = action.payload.organizations[0];
        if (!action.payload.organizations[0].max_stepid) {
          state.currentStep = action.payload.organizations[0].max_stepid
            ? action.payload.organizations[0].max_stepid
            : 1;
        }
      })
      .addCase(fetchAgentData.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchExchangeLegData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExchangeLegData.fulfilled, (state, action) => {
        state.loading = false;
        state.exchangeLegData = action.payload;
      })
      .addCase(fetchExchangeLegData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllAgentData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllAgentData.fulfilled, (state, action) => {
        state.loading = false;
        state.allAgentData = action.payload;
      })
      .addCase(fetchAllAgentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAgentData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAgentData.fulfilled, (state, action) => {
        state.loading = false;
        state.currentStepData = action.payload;
        state.formData = action.payload;
        state.actionType = "save-stage";
        state.refreshProfile = true;
        state.refreshEntity = true;
        state.refreshRelation = true;
      })
      .addCase(updateAgentData.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload;
        state.formData = action.payload;
      })
      .addCase(updateAgentCompletedStep.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAgentCompletedStep.fulfilled, (state, action) => {
        state.completedStages = [...state.completedStages, action.payload];
        state.completedStep = action.payload;
        if (
          state.allStepData &&
          state.allAgentData.length &&
          state.allStepData.length >= action.payload
        ) {
          state.currentStep = action.payload;
        }
        state.actionType = "mark-complete";
        state.loading = false;
      })
      .addCase(updateAgentCompletedStep.rejected, (state, action) => {
        state.loading = false;
        state.currentStep = action.payload;
      })
      .addCase(fetchAgentStepData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAgentStepData.fulfilled, (state, action) => {
        state.loading = false;
        state.currentStepData = action.payload;
        state.formData = action.payload;
      })
      .addCase(fetchAgentStepData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllStepData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllStepData.fulfilled, (state, action) => {
        state.loading = false;
        state.allStepData = action.payload;
      })
      .addCase(fetchAllStepData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllPartnersMapping.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllPartnersMapping.fulfilled, (state, action) => {
        state.loading = false;
        state.partnersMapping = action.payload;
      })
      .addCase(fetchAllPartnersMapping.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setAgentData,
  setFormData,
  setBackOffice,
  setAgentLoading,
  setAgentError,
  setAgentId,
  setAllAgentData,
  setExchangeLegData,
  setAllStepData,
  setCurrentStepData,
  setCurrentStep,
  setCompletedData,
  setShowCongratulations,
  setPromotionStageStatus,
  setCompletedStages,
  setExchangeLegSelected,
  setActionType,
  setRefreshAgent,
  setRefreshProfile,
  setRefreshEntity,
  setRefreshRelation
} = agentSlice.actions;
export default agentSlice.reducer;
