import React, { useEffect, useState } from 'react'
import useApi from '../../../../custom-hooks/useApi';
import { BASE_URL } from '../../../../appconfig';
import Spinner from '../../../common/Spinner';

function CampaignImportHistory({campaign, toggle}) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const api = useApi();

    const handleDownloadErrorFile = async () => {
      await getDownloadFileSasUrl("error");
    }

    const handleDownloadImportedFile = async () => {
      await getDownloadFileSasUrl("imported");
    };

    const getDownloadFileSasUrl = async(type) => {
      let downloadApiUrl = `/Campaign/${campaign}/`;
      if(type === 'error') {
        downloadApiUrl += "DownloadErrorCsv";
      } else {
        downloadApiUrl += "DownloadImportedCsv";
      }

      let url = "";

      try {
        const response = await api.get(downloadApiUrl);
        if (response.status === 200 && response.data.url) {
          url = response.data.url;
        } else {
          url = "";
        }
      } catch (error) {
        console.error("Failed to fetch template url: ", error.message);
        url = "";
      }

      if (!url || url === "") {
        return "Couldn't get download url.";
      }

      try {
        const a = document.createElement("a");
        a.href = url;
        document.body.appendChild(a);
        a.click();
      } catch (error) {
        console.log("Failed downloading file, reason: ", error.message);
      }
    }
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await api.get(`${BASE_URL}/Campaign/History/${campaign}`);
          setData(response.data?.history);
        } catch (error) {
          console.error("Error fetching data:", error);
          setData(null);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }, []);

    const formatDateString = (dateString) => {
      if (!dateString) {
        return null;
      }
      const date = new Date(dateString);
          const formattedDate = `${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${date
            .getDate()
            .toString()
            .padStart(2, "0")}/${date.getFullYear().toString()} ${
            date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
          }:${date.getMinutes().toString().padStart(2, "0")} ${
            date.getHours() >= 12 ? "PM" : "AM"
          }`;

      return formattedDate;
  };
  
    const HistoryTableRow = ({ data }) => {
      return (
        <tr
          key={`the key is ${data.ID}`}
          className="border-b dark:border-neutral-500"
        >
          <td className="truncate py-6 px-2 whitespace-nowrap font-inter">
            <span
              className="underline decoration-blue-600 cursor-pointer"
              onClick={handleDownloadImportedFile}
            >
              {data?.File_Path?.split("-").slice(1).join("-") || "-"}
            </span>
          </td>
          <td className="truncate py-6 px-2 whitespace-nowrap font-inter">
            <span>{formatDateString(data?.createdAt) || "-"}</span>
          </td>
          <td className="truncate py-6 pl-4 whitespace-nowrap font-inter">
            <span>{data.IsFileProcessed ? "Processed" : "Not-Processed"}</span>
          </td>
          <td className="truncate py-6  pl-[1.8rem]  whitespace-nowrap font-inter">
            <span className="truncate whitespace-normal">
              {data.FileUploadComment &&
              data.FileUploadComment.match(/[0-9/]+/g)
                ? data.FileUploadComment.match(/[0-9/]+/g)[0]
                : data.FileUploadComment
                ? data.FileUploadComment
                : "-"}
            </span>
          </td>
          <td className="truncate py-6 pr-2 whitespace-nowrap font-inter">
            <span
              className="underline decoration-blue-600 cursor-pointer"
              onClick={handleDownloadErrorFile}
            >
              {data?.HasErrorFile
                ? data.File_Path.split("-")
                    .slice(1)
                    .join("-")
                    .split(".csv")
                    .slice(0)
                    .join("-Error.csv")
                : ""}
            </span>
          </td>
        </tr>
      );
    };
  
    return (
      <div>
        {loading && <Spinner />}
        <div
          className="fixed inset-0 flex items-center justify-center text-left bg-gray-800 bg-opacity-50 z-20 w-50 h-50"
          style={{ margin: 0 }}
        >
          <div
            className="bg-white p-8 rounded shadow-md relative"
            style={{ width: "60%" }}
          >
            <div className="flex items-center justify-between mb-5">
              <h2 className="text-black font-inter text-2xl font-medium">
                Import History
              </h2>
              <button
                onClick={() => toggle()}
                className="text-gray-600 hover:text-blue-500 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            {data ? <table className="min-w-full mx-auto text-left z-0">
              <thead
                style={{ backgroundColor: "#E5E7EB" }}
                className="font-inter text-xs"
              >
                <tr className="">
                  <th className="border-b border-gray-300 py-4 px-2 text-left">
                    File Name
                  </th>
                  <th className="border-b border-gray-300 py-4 px-2 pl-[0.6rem] text-left">
                    Date & Time
                  </th>
                  <th className="border-b border-gray-300 py-4 px-2 pl-[0.9rem] text-left">
                    Status
                  </th>
                  <th className="border-b border-gray-300 py-4 px-2 pl-[1.7rem] text-left">
                    Processed Records
                  </th>
                  <th className="border-b border-gray-300 py-4  pr-2 text-left">
                    Error Details
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-700 font-inter text-sm font-medium leading-5">
                {data && (
                  <HistoryTableRow
                    key={`import-history-${campaign}`}
                    data={data}
                  />
                )}
              </tbody>
            </table> : <div className='text-center w-full p-4'>No data available ....!</div>}
          </div>
        </div>
      </div>
    )
}

export default CampaignImportHistory