import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCountriesData = createAsyncThunk(
  "common/fetchCountriesData",
  async ({ api }, thunkAPI) => {
    try {
      const response = await api.get(`/Organization/Country`);
      if (
        response.status === 200 &&
        response.data.country &&
        response.data.country.length > 0
      ) {
        return response.data.country;
      } else {
        console.error("Error fetching countries data: ", response);
        return [];
      }
    } catch (error) {
      console.error("Error calling country master api: ", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchEntityTypeData = createAsyncThunk(
  "common/fetchEntityTypeData",
  async ({ api }, thunkAPI) => {
    try {
      const response = await api.get(`/Organization/Constant/Entity`);
      if (
        response.status === 200 &&
        response.data.constant &&
        response.data.constant.length > 0
      ) {
        return response.data.constant;
      } else {
        console.error("Error fetching Entity Type data: ", response);
        return [];
      }
    } catch (error) {
      console.error("Error calling Entity Type master api: ", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchRcStatusList = createAsyncThunk(
  "common/fetchRcStatusList",
  async ({ api }, thunkAPI) => {
    try {
      const response = await api.get(`/Organization/Constant/RCStatus`);
      if (
        response.status === 200 &&
        response.data.constant &&
        response.data.constant.length > 0
      ) {
        return response.data.constant;
      } else {
        console.error("Error fetching RC Status data: ", response);
        return [];
      }
    } catch (error) {
      console.error("Error calling RC Status master api: ", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchSignOffStatusList = createAsyncThunk(
  "common/fetchSignOffStatusList",
  async ({ api }, thunkAPI) => {
    try {
      const response = await api.get(`/Organization/Constant/SignOffStatus`);
      if (
        response.status === 200 &&
        response.data.constant &&
        response.data.constant.length > 0
      ) {
        return response.data.constant;
      } else {
        console.error("Error fetching Sign Off Status data: ", response);
        return [];
      }
    } catch (error) {
      console.error("Error calling Sign Status master api: ", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchCampaignGroupTypeList = createAsyncThunk(
  "common/fetchGroupTypeList",
  async ({ api }, thunkAPI) => {
    try {
      const response = await api.get(`/Organization/Constant/LeadType`);
      if (
        response.status === 200 &&
        response.data.constant &&
        response.data.constant.length > 0
      ) {
        return response.data.constant;
      } else {
        console.error("Error fetching RC Status data: ", response);
        return [];
      }
    } catch (error) {
      console.error("Error calling RC Status master api: ", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchRelationshipData = createAsyncThunk(
  "common/fetchRelationshipData",
  async ({ api }, thunkAPI) => {
    try {
      const response = await api.get(`/Organization/Constant/Partner`);
      if (
        response.status === 200 &&
        response.data.constant &&
        response.data.constant.length > 0
      ) {
        return response.data.constant;
      } else {
        console.error("Error fetching Realtionship Type data: ", response);
        return [];
      }
    } catch (error) {
      console.error("Error calling Realtionship Type master api: ", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchStatesData = createAsyncThunk(
  "common/fetchStatesData",
  async ({ countryId, api }, thunkAPI) => {
    try {
        let queryParams = {};
        if(countryId > 0) {
            queryParams["CountryID"] = countryId;
        }
      const response = await api.get(`/Organization/State`, {params: queryParams});
      if (
        response.status === 200 &&
        response.data.state &&
        response.data.state.length > 0
      ) {
        return response.data.state;
      } else {
        console.error("Error fetching countries data: ", response);
        return [];
      }
    } catch (error) {
      console.error("Error calling state master api: ", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
