import React from "react";
import leadLandingSvg from "../../assets/landingScreensSvg/Lead Qualification Funnel_3 1.svg";
import downloadSvg from "../../assets/landingScreensSvg/leads/download.svg";
import filterSvg from "../../assets/landingScreensSvg/leads/filter.svg";
import usersSvg from "../../assets/landingScreensSvg/leads/users.svg";
import { setVisitedPages } from "../../redux/slices/commonSlice";
import { useDispatch } from "react-redux";

const LeadsLanding = () => {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center justify-center bg-gray-100">
      <div className="flex flex-col items-center m-5 mt-8 bg-white p-3 rounded-lg  w-[90%] h-[85vh] border border-[#E5E7EB] ">
        <div className="flex flex-row items-center w-full">
          {/* Image Part */}
          <div className="flex w-[60%]">
            <img
              src={leadLandingSvg}
              alt="Landing Image"
              className="rounded-l-lg object-cover"
            />
          </div>
          {/* Content Part */}
          <div className="p-8 flex flex-col justify-start w-[40%]">
            <div>
              <h1 className="text-2xl font-semibold text-gray-800 mb-2">
              Manage Your Prospecting
              </h1>
              <p className="text-base font-medium text-gray-500 mb-6">
              The Leads Center allows you to keep track of all your leads from different sources in one view.
              </p>
            </div>

            <div>
              <div className="flex flex-row my-2">
                <img src={usersSvg} alt="Landing Image" />
                <h1 className="text-xl font-semibold text-gray-800 ml-2">
                  What’s a Lead?
                </h1>
              </div>
              <p className="text-base font-medium text-gray-500 mb-6 ml-8">
                Leads are potential business partners or clients that have not
                yet been qualified as a Client or Business Partner.
              </p>
            </div>

            <div>
              <div className="flex flex-row my-2">
                <img src={filterSvg} alt="Landing Image" />
                <h1 className="text-xl font-semibold text-gray-800 ml-2">
                  What is a Qualified Lead?
                </h1>
              </div>
              <p className="text-base font-medium text-gray-500 mb-6 ml-8">
                Leads are qualified as they meet certain fit criteria, engage
                with content, or express direct interest in a conversation with
                you.
              </p>
            </div>

            <div>
              <div className="flex flex-row my-2">
                <img src={downloadSvg} alt="Landing Image" />
                <h1 className="text-xl font-semibold text-gray-800 ml-2">
                  Lead Conversion
                </h1>
              </div>
              <p className="text-base font-medium text-gray-500 mb-6 ml-8">
                When you convert a lead, the information from the lead is used
                to automatically create records for a Business Partner, Account,
                Case, or all three.
              </p>
            </div>
            <button
              className="text-base font-semibold bg-[#4971B8] text-white px-6 py-3 w-full rounded-md"
              type="button"
              onClick={() =>
                dispatch(setVisitedPages(window.location.pathname))
              }
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsLanding;
