import React, { useEffect, useState } from "react";
import StageForm from "./StageForm";
import { useSelector, useDispatch } from "react-redux";
import { updateAgentData } from "../../../../redux/thunks/agentThunk";
import { setFormData } from "../../../../redux/slices/agentSlice";
import useApi from "../../../../custom-hooks/useApi";
import { fetchStatesData } from "../../../../redux/thunks/commonThunk";
import Spinner from "../../../common/Spinner";
import SearchDropDown from "../../backOffice/Tabs/Profile/SearchDropDown";
import { isValid, format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Stage5 = ({ handleChange }) => {
  const agentId = useSelector((state) => state.agent.agentId);
  const [ErrorState,setErrorState]=useState(null)
  const dispatch = useDispatch();
  const api = useApi();
  const countries = useSelector((state) => state.common.countries);
  const states = useSelector((state) => state.common.states);
  const [loading, setLoading] = useState(false);
  const commonLoader = useSelector((state) => state.common.loading);


  useEffect(() => {
    setLoading(commonLoader);
  }, [commonLoader])

  const formData = useSelector((state) => state.agent.formData);
  const stepId = useSelector((state) => state.agent.currentStep);;

  const currentStepData = useSelector((state) => state.agent.currentStepData);

  const [isEditing, setEditing] = useState(false);

  const toggleEditing = () => {
    if (isEditing) {
      if(!formData.StateName && !formData.HomeState){
        setErrorState(true)
        return
      }
      dispatch(updateAgentData({ stepId, agentId, formData, currentStepData, api }));
    }
    setEditing(!isEditing);
  };

  const cancelEditing = () => {
    dispatch(setFormData(currentStepData));
    setEditing(false);
    setErrorState(false);
  };

  const selectCountryAndFetchState = (e) => {
    let countryId = e.target.value;
    dispatch(setFormData({ HomeState: null }));
    dispatch(fetchStatesData({ countryId , states ,api}));
    handleSelectChange(e);
  }

  useEffect(() => {
    if(formData && states.length === 0) {
       dispatch(fetchStatesData({ states, api }));
     }
   }, [api, dispatch, formData, states.length]);

  const handleSelectChange = (e) => {
    const { selectedOptions, name } = e.target;
    switch (name) {
      case "HomeState":
        dispatch(
          setFormData({ StateName: selectedOptions[0].innerText })
        );
        break;
      case "Country":
        dispatch(
          setFormData({ CountryName: selectedOptions[0].innerText })
        );
        break;
      default:
        break;
    }
    console.log(selectedOptions.innerText);

    handleChange(e);
  };

   const setDate = (date, name) => {
     try {
       console.log(date);

       if (isValid(date)) {
         const formattedDate = format(date, "yyyy-MM-dd");
         dispatch(setFormData({ [name]: formattedDate }));
       } else {
         console.error("Invalid date");
       }
     } catch (error) {
       console.error("Error parsing date:", error);
     }
   };
  const handleSelect = (state, e) => {
   
    dispatch(setFormData({Country: state.CountryID}))
   dispatch(setFormData({StateName: state.State_Name}))
    dispatch(setFormData({HomeState: state.ID}));
   
  }

    return (
      <>
        {loading && <Spinner />}
        <StageForm
          stageNum={stepId}
          isEditable={false}
          cancelEditing={cancelEditing}
          isEditing={isEditing}
          toggleEditing={toggleEditing}
        >
          {formData !== null && currentStepData !== null && (
            <>
              <div className="flex flex-row md:flex-row items-center">
                {/* Row 1 */}
                <div className="text-gray-500 mb-2 md:w-1/2">
                  <span className="stage-label">
                    Train-the-Trainer Course&nbsp;
                    
                  </span>
                </div>
                <div className="mb-2 md:w-1/2">
                    <div
                      className={`w-4 h-4 border border-gray-300 rounded mr-2 ${
                        formData.Train_The_Trainer_Course ? "bg-blue-700" : ""
                      }`}
                    >
                      {formData.Train_The_Trainer_Course && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="12"
                          viewBox="0 0 10 8"
                          fill="none"
                          className=" text-white mx-auto my-auto"
                        >
                          <path
                            d="M9 1L3.5 6.5L1 4"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>
                </div>
              </div>
              <div className="flex flex-row md:flex-row items-center">
                {/* Row 2 */}
                <div className="text-gray-500 mb-2 md:w-1/2">
                  <span className="stage-label">
                    Appointments Done&nbsp;
                    
                  </span>
                </div>
                <div className="mb-2 md:w-1/2">
                  <span>{currentStepData.Appointments_Done || "-"}</span>
                </div>
              </div>
              <div className="flex flex-row md:flex-row items-center">
                {/* Row 3 */}
                <div className="text-gray-500 mb-2 md:w-1/2">
                  <span className="stage-label">Business Partners</span>
                </div>
                <div className="mb-2 md:w-1/2">
                  <span>{currentStepData.Business_Partners || "-"}</span>
                </div>
              </div>
              <div className="flex flex-row md:flex-row items-center">
                {/* Row 4 */}
                <div className="text-gray-500 mb-2 md:w-1/2">
                  <span className="stage-label">
                    Client Applications&nbsp;
                    
                  </span>
                </div>
                <div className="mb-2 md:w-1/2">
                  <span>{currentStepData.Client_Applications || "-"}</span>
                </div>
              </div>
              <div className="flex flex-row md:flex-row items-center">
                {/* Row 5 */}
                <div className="text-gray-500 mb-2 md:w-1/2">
                  <span className="stage-label">
                    Sign Off&nbsp;
                    
                  </span>
                </div>
                <div className="mb-2 md:w-1/2">
                    <span>
                      {currentStepData.Sign_Off_Date !== null
                        ? isValid(
                            new Date(currentStepData.Sign_Off_Date)
                          )
                          ? format(
                              new Date(currentStepData.Sign_Off_Date),
                              "M/d/yyyy"
                            )
                          : "-"
                        : "-"}
                    </span>
                </div>
              </div>
            </>
          )}
        </StageForm>
      </>
    );
};
export default Stage5;


