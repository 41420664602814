import React from "react";
import RecipientRow from "./RecipientRow";

const RecipientTable = ({ data, change, ConstStatus }) => {

  return (
    <div>
      
      <table className="mt-4 min-w-full mx-auto text-left z-0">
        <thead className="font-inter text-base text-gray-400">
          <tr className="px-4 py-4">
            <th className="border-b border-gray-300 py-2 px-4">Name</th>
            <th className="border-b border-gray-300 py-2 px-4">Phone</th>
            <th className="border-b border-gray-300 py-2 px-[0.5rem] pr-1">Last Updated</th>
            <th className="border-b border-gray-300 py-2 pr-2">SMS Status</th>
            <th className="border-b border-gray-300 py-2 px-2 pr-1">Deliverability Note</th>
          </tr>
        </thead>
        <tbody className="text-gray-700 font-inter text-sm font-medium leading-5">
          {data.map((item) => (
            <RecipientRow data={item} change={change} ConstStatus={ConstStatus}/>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RecipientTable;
