import "react-toastify/dist/ReactToastify.css";
import Table from "../table/Table";
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import TeamsLanding from "../landingScreens/teamLanding";


const Dashboard = () => {
  const authToken = useSelector((state) => state.auth.authToken);
  const visitedPages = useSelector((state) => state.common.visitedPages)

 if(authToken) {
  return <>{!visitedPages.includes(window.location.pathname) ? <TeamsLanding /> : <Table />}</>;
 } else {
  window.location.href="/";
 }
};

export default Dashboard;
