import React, { useEffect, useState } from "react";
import useApi from "../../../../custom-hooks/useApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignGroupTypeList } from "../../../../redux/thunks/commonThunk";
import { ReactComponent as DownloadFileLogo } from "../../../../../src/assets/download.svg";
import FileUpload from "./FileUpload";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../../../common/Spinner";
import { LEADS_TEMPLATE_FILE_PATH } from "../../../../appconfig";

function LeadImport({ toggleImportPopup }) {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const dispatch = useDispatch();
  const leadTypes = useSelector((state) => state.common.campaignGroupTypeList);
  const user = useSelector((state) => state.auth.user);
  const [file, setFile] = useState(null);

  const setFileState = (selectedFile) => {
    setFile(selectedFile);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getTemplateUrl = async () => {
    try {
      const response = await api.get(
        `/Campaign/TemplateUrl?fileName=${LEADS_TEMPLATE_FILE_PATH}`
      );
      if (response.status === 200 && response.data.url) {
        return response.data.url;
      }
      return "";
    } catch (error) {
      console.error("Failed to fetch template url: ", error.message);
      return "";
    }
  };

  const handleDownloadTemplate = async () => {
    const url = await getTemplateUrl();

    if (!url || url === "") {
      return "Couldn't get upload url.";
    }

    try {
      const a = document.createElement("a");
      a.href = url;
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log("Failed downloading file, reason: ", error.message);
    }
  };

  const getUploadUrl = async () => {
    try {
      const response = await api.get(`/Campaign/GetSaasToken?isLead=true`);
      if (response.status === 200 && response.data.url) {
        return response.data.url;
      }
      return "";
    } catch (error) {
      return "";
    }
  };

  const handleUpload = async (userId, leadType) => {
    const url = await getUploadUrl();
    if (!url || url === "") {
      return "Couldn't get upload url.";
    }
    try {
      const fileData = new Blob([file], { type: file.type });
      const splittedUrlArray = url && url !== "" ? url.split("?") : [];
      let generatedUrl = "";
      let fileName = `${userId}-${leadType}-${file.name}`;
      if (splittedUrlArray.length > 1) {
        generatedUrl = splittedUrlArray[0];
        generatedUrl += `/${fileName}?${splittedUrlArray[1]}`;
      } else {
        generatedUrl = url;
      }
      const contentType = fileData.type || "application/octet-stream";
      const response = await axios.put(generatedUrl, fileData, {
        headers: {
          withCredentials: true,
          "Content-Type": contentType,
          "x-ms-blob-type": "BlockBlob",
        },
      });
      if(response.status === 200 || response.status === 201) {
        const fileUpdateResponse = await api.post(
          `/Campaign/leads-entry?baseShopId=${user.BaseShopID}`,
          {
            File_Path: `${fileName}`,
            LeadTypeID: `${leadType}`,
          }
        );
        if(fileUpdateResponse.status === 200 && fileUpdateResponse.data.success) {
          return "File uploaded successfully.";
        } else {
          return `File uploaded but was not updated in db, filePath: /${user.BaseShopID}/campaigns/${fileName}`;
        }
      } else {
        return "File upload failed, Unknown reason.";
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return "Upload failed, reason - " + error.message;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please Upload a csv file to proceed");
      return;
    }
    try {
      setLoading(true);
      if (file) {
        const fileUploaded = await handleUpload(user.id, formData.Lead_Type);
        console.log(fileUploaded);
        toast.success("Leads imported successfully", {
          theme: "dark",
        });
        toggleImportPopup();
      } else {
        const errorMessage = "Failed to Import Leads";
        toast.error(errorMessage, {
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message, {
        theme: "dark",
      });
      console.error("Error updating data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchCampaignGroupTypeList({ api }));
  }, []);

  return (
    <div>
      {loading && <Spinner />}
      <div
        className="fixed inset-0 flex items-center justify-center text-left bg-gray-800 bg-opacity-50 z-20 w-50 h-50"
        style={{ margin: 0 }}
      >
        <div
          className="bg-white p-8 rounded shadow-md relative"
          style={{ width: "500px" }}
        >
          <div className="flex items-center justify-between mb-2">
            <h2 className="text-black font-inter text-2xl font-medium">
              Import Leads
            </h2>
            <button
              onClick={() => toggleImportPopup()}
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <div className="flex justify-center">
            <div className="flex flex-wrap ">
              <div className="mt-3 mb-3 flex justify-between font-inter">
                <div className="text-base bg-transparent text-blue-500 hover:text-blue-800 font-semibold py-2 px-8 border border-blue-500 cursor-pointer rounded-lg font-inter">
                  <FileUpload setFileState={setFileState} />
                </div>
                <button
                  className="p-2 rounded font-inter border border-transparent bg-white text-blue-500 hover:text-blue-800"
                  onClick={handleDownloadTemplate}
                >
                  <span className="flex gap-2 ml-3 text-14 text-secondary font-inter font-medium text-14 leading-18">
                    <DownloadFileLogo />
                    Download CSV Template
                  </span>
                </button>
              </div>
              <form className="w-full max-w-lg" onSubmit={handleSubmit}>
                <div>
                  {file && (
                    <div className="px-4 flex-1 text-blue-500">{file.name}</div>
                  )}
                  <div className="mt-2 mb-2 w-full font-inter px-2">
                    <label
                      className="block tracking-wide text-black text-xs font-bold mb-2 "
                      htmlFor="name"
                    >
                      Lead Type
                    </label>
                    <select
                      className="appearance-none block w-1/2 bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white cursor-pointer"
                      name="Lead_Type"
                      value={formData.Lead_Type ? formData.Lead_Type : ""}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select Lead Type
                      </option>
                      {leadTypes?.length > 0 ? (
                        leadTypes?.map((item) => (
                          <option key={item.ID} value={item.ID}>
                            {item.Name}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled>
                          No data available
                        </option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="mt-5 flex justify-center font-inter">
                  <button
                    style={{
                      background:
                        "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                      height: "3rem",
                      width: "280px",
                    }}
                    className="text-white p-2 rounded font-inter"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadImport;
