import React, { useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../../../../common/Spinner";
import axios from "axios";
import useApi from '../../../../../custom-hooks/useApi';
import DatePicker from "react-datepicker";
import { LiaExclamationSolid } from "react-icons/lia";
import { BASE_URL } from '../../../../../appconfig';
import tick from '../../../../../assets/tick.svg';
import { LuCalendarDays } from "react-icons/lu";



function PromotionPopup({
    LicenseDate,
    ADate,
    AMADate,
    SADate,
    MDDate,
    Certified_field_Trainer,
    Leadership_Training,
    SMDDate,
    ID,
    change,
    setIsEdit
  }) {

      const [isEditing, setIsEditing] = useState({
          LicenseDate: false,
          ADate: false,
          AMADate: false,
          SADate: false,
          MDDate: false,
          SMDDate: false
         });
      const [editedADate, setEditedADate] = useState(ADate);
      const [editedAMADate, setEditedAMADate] = useState(AMADate);
      const [editedLicenseDate, setEditedLicenseDate] = useState(LicenseDate);
      const [editedSADate, setEditedSADate] = useState(SADate);
      const [editedMDDate, setEditedMDDate] = useState(MDDate);
      const [isOpenPopup , setIsOpenPopup] = useState(true);
      const [data, setData]= useState("");
      const [ ischeckbox, setIscheckbox] = useState(false)
      const [editedCertifiedFieldTrainer, setEditedCertifiedFieldTrainer] = useState(Certified_field_Trainer);
      const [editedLeadershipTraining, setEditedLeadershipTraining] = useState(Leadership_Training);
      const [editedSMDDate, setEditedSMDDate] = useState(SMDDate);
      const api = useApi();
      const [loading, setLoading] = useState(false);
      const dateInputStyle = " border border-gray-500 w-[112px] h-[24px] px-4 bg-neutral-100 border border-grey-300  text-neutral-700 text-sm  leading-tight focus:outline-none focus:bg-white text-gray-300";
      let pm = ''
      const formatDateString = (dateString) => {
        if (!dateString) {
          return null;
        }
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    
      const handleCancelClick = () => {
        setIsEditing(!isEditing);
      };
      const handleSaveClick = async (e) => {
        e.preventDefault();     
        const json = {
          ADate: formatDateString(editedADate),
          SADate: formatDateString(editedSADate),
          LicenseDate: formatDateString(editedLicenseDate),
          MDDate: formatDateString(editedMDDate),
          SMDDate: formatDateString(editedSMDDate),
          AMADate: formatDateString(editedAMADate),
          Certified_field_Trainer: editedCertifiedFieldTrainer,
          Leadership_Training: editedLeadershipTraining
        };
        let shouldCallChange = true;
        try {
          if (isEditing) {
            const amaDate = editedAMADate ? new Date(editedAMADate) : null;
            const licenseDate = editedLicenseDate ? new Date(editedLicenseDate) : null;
            const aDate = editedADate ? new Date(editedADate) : null;
            const saDate = editedSADate ? new Date(editedSADate) : null;
            const mdDate = editedMDDate ? new Date(editedMDDate) : null;
            const smdDate = editedSMDDate ? new Date(editedSMDDate) : null;
    
            if (
              (amaDate && (amaDate === licenseDate || amaDate === aDate || amaDate === saDate || amaDate === mdDate || amaDate === smdDate)) ||
              (licenseDate && (licenseDate === aDate || licenseDate === saDate || licenseDate === mdDate || licenseDate === smdDate)) ||
              (aDate && (aDate === saDate || aDate === mdDate || aDate === smdDate)) ||
              (saDate && (saDate === mdDate || saDate === smdDate)) ||
              (mdDate && mdDate === smdDate)
            ) {
              toast.error("Dates should not be equal", {
                theme: "dark",
              });
              shouldCallChange = false;
              return;
            }
            // Existing checks
            if (amaDate && licenseDate && amaDate > licenseDate) {
              toast.error("Registration Date should not be greater than License Date", {
                theme: "dark",
              });
              shouldCallChange = false;
              return;
            }
            if (licenseDate && aDate && licenseDate > aDate) {
              toast.error("License Date should not be greater than Associate Date", {
                theme: "dark",
              });
              shouldCallChange = false;
              return;
            }
            if (aDate && saDate && aDate > saDate) {
              toast.error("Associate Date should not be greater than SADate", {
                theme: "dark",
              });
              shouldCallChange = false;
              return;
            }
            if (saDate && mdDate && saDate > mdDate) {
              toast.error("SADate should not be greater than MDDate", {
                theme: "dark",
              });
              shouldCallChange = false;
              return;
            }
            if (mdDate && smdDate && mdDate > smdDate) {
              toast.error("MDDate should not be greater than SMD Date", {
                theme: "dark",
              });
              shouldCallChange = false;
              return;
            }
          
           // Additional checks
          if (amaDate && (licenseDate && licenseDate <= amaDate)) {
            toast.error("License date should be greater than Registration date", {
              theme: "dark",
            });
            shouldCallChange = false;
            return;
          }
    
          if (aDate && (licenseDate && licenseDate >= aDate)) {
            toast.error("License date should be less than Associate date", {
              theme: "dark",
            });
            shouldCallChange = false;
            return;
          }
    
          if (aDate && (amaDate && amaDate >= aDate)) {
            toast.error("Registration date should be less than Associate date", {
              theme: "dark",
            });
            shouldCallChange = false;
            return;
          }
    
          if (saDate && (aDate && aDate >= saDate)) {
            toast.error("Associate date should be less than SA date", {
              theme: "dark",
            });
            shouldCallChange = false;
            return;
          }
    
          if (mdDate && (saDate && saDate >= mdDate)) {
            toast.error("SA date should be less than MD date", {
              theme: "dark",
            });
            shouldCallChange = false;
            return;
          }
    
          if (smdDate && (mdDate && mdDate >= smdDate)) {
            toast.error("MD date should be less than SMD date", {
              theme: "dark",
            });
            shouldCallChange = false;
            return;
          }
          // SMD date should be greater than all other dates
          if (smdDate && (
            amaDate && smdDate <= amaDate ||
            licenseDate && smdDate <= licenseDate ||
            aDate && smdDate <= aDate ||
            saDate && smdDate <= saDate ||
            mdDate && smdDate <= mdDate
          )) {
            toast.error("SMD date should be greater than all other dates", {
              theme: "dark",
            });
            shouldCallChange = false;
            return;
          }
        }
                
          setLoading(true);
          const response = await api.put(
            `${BASE_URL}/Backoffice/Promotion/${encodeURIComponent(btoa(String(ID)))}`,
            json
          );
      
          if (response.data.success) {
            toast.success("Data updated successfully", {
              theme: "dark",
            });
            shouldCallChange = true;
          } else {
            const errorMessage = response.data?.error || "Failed to update the data";
            toast.error(errorMessage, {
              theme: "dark",
            });
          }
        } catch (error) {
          console.error('Error updating data:', error);
        } finally {
          setLoading(false);
          setIsEditing(false);
          if (shouldCallChange) {
            change();
          }
        }
      };
      
      const FormattedDate = ({ date }) => {
        if (!date) {
          return <span>--/--/----</span>;
        }
        const dateObject = new Date(date);
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        const year = dateObject.getFullYear().toString().slice(-2);
        const formattedDate = `${month}/${day}/${year}`;
        return <span>{formattedDate}</span>;
      };
    
      const CheckboxCell = ({ value }) => {
        if (value === true) {
          return (
            <div className="whitespace-nowrap font-inter">
              <img
                height="18px"
                width="18px"
                src={tick}
                alt="Tick"
                className="w-4 h-4 text-white"
              />
            </div>
          );
        } else {
          return <div className="whitespace-nowrap font-inter">-</div>;
        }
      };

     
      const handleSetClick =()=>{
        setIsEditing(false);
        
      }

      const toggleEdit = (fieldName) => {
        setIsEditing(prevState => ({
          ...prevState,
          [fieldName]: !prevState[fieldName]
        }));
      };
    
     


    return (
 
    <div>
    
      <div
        className="fixed inset-0 p-6 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20 w-50 h-50"
        style={{ margin: 0 }}
      >           
        <div
          className="bg-white  rounded shadow-md relative"
          style={{ width: "500px" }}
        >  
    <div className="flex justify-end ">
    <button  
            onClick={setIsEdit} 
             className="text-gray-600  hover:text-blue-500 focus:outline-none"
           >
             <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
               className="w-6 h-6"
             >
               <path
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 strokeWidth="2"
                 d="M6 18L18 6M6 6l12 12"
               ></path>
             </svg>
           </button>

           </div>

          <div className="px-6 mx-4">
          <div className="flex  items-center justify-between ">
            <h2 className="text-black font-inter text-2xl font-medium mb-2 mt-1">
            Associate Effective Dates
            </h2>
          
          </div>

       
        
          <div className=" flex justify-center">
            <form onSubmit={handleSaveClick} className="w-full max-w-lg">
              
              <div className="flex flex-wrap -mx-3">

             {/* Row -- 1 (Registration Date) */}

              <div className=" w-full px-3 font-inter ">
                 
                 <div
                    required
                   className="appearance-none flex justify-between w-full font-bold bg-neutral-100 text-black text-md  py-2 px-4 leading-tight focus:outline-none focus:bg-white">
                 <div className={` flex items-center  ${isEditing['AMADate']? "w-[50%]" : "w-[70%]"} `} >Registration Date:</div>
             
               
              <div className={`whitespace-nowrap ${pm} font-inter  flex   ${isEditing['AMADate'] ? "mr-6 w-[50%]" : "mr-0 w-[30%] "} `}>
              <div className={`flex items-center pl-2 pr-2  text-blue-700 border-none focus:outline-none ${isEditing['AMADate'] ? "mr-0 bg-white py-2" : "mr-4 py-3"}`}>
             {/* < LuCalendarDays  onClick={handleEditClick}/>  */}
             <LuCalendarDays onClick={() => toggleEdit('AMADate')} />         
             </div>  
       { isEditing['AMADate'] ? (
        <div className="flex space-x-3">
         <DatePicker
         toggleCalendarOnIconClick
         name="AMADate"
         className=" border-none  w-[112px]  bg-white text-neutral-400  font-medium leading-tight focus:outline-none focus:bg-white "
         selected={editedAMADate ? new Date(editedAMADate) : null}
         onChange={(date) => setEditedAMADate(date || null)}
         placeholderText="--/--/----"
         placeholder="m/d/Y"
         dateFormat="MM/dd/yy"
         showYearDropdown
          yearDropdownItemNumber={50}
          scrollableYearDropdown
       /> 
       
       <button
       className='disabled:opacity-50 disabled:cursor-not-allowed bg-[#4871B7] text-white font-normal rounded-md px-3 w-[3.8rem] h-[33px] mt-[2.2px] '
       onClick={(e)=>handleSetClick(1,e)}
>
Set
</button>
</div>
        ) :  (  
         <div className=" text-neutral-400 flex items-center font-normal text-base"> <FormattedDate date={editedAMADate}  /> </div>
        

        )}
      </div>
        
    
      </div>
     </div>


{/* Row -- 2 (LicenseDate) */}

               <div className="mt-2 w-full px-3 font-inter ">
                 
                 <div
                    required

                   className="appearance-none flex justify-between w-full font-bold bg-neutral-100 text-black text-md  py-2 px-4 leading-tight focus:outline-none focus:bg-white">
                 <div  className={` flex items-center  ${isEditing['LicenseDate']? "w-[50%]" : "w-[70%]"} `}  > License Date:</div>
               
                 <div className={`whitespace-nowrap ${pm} font-inter flex   ${isEditing['LicenseDate'] ? "mr-6 w-[50%]" : "mr-0 w-[30%]"} `}>
                 <div className={`flex items-center pl-2 pr-2 text-blue-700 border-none focus:outline-none ${isEditing['LicenseDate'] ? "mr-0 bg-white py-2" : "mr-4 py-3"}`}>
                 {/* < LuCalendarDays   onClick={handleEditClick}/> */}
                 <LuCalendarDays onClick={() => toggleEdit('LicenseDate')} />
                 </div>
                 {isEditing['LicenseDate']  ? (
       <div className='flex space-x-3'>
         <DatePicker
         name="LicenseDate"
         className="border-none w-[112px]  bg-white text-neutral-400  font-medium leading-tight focus:outline-none focus:bg-white "
         selected={editedLicenseDate ? new Date(editedLicenseDate) : null}
         onChange={(date) => setEditedLicenseDate(date || null)}
         placeholderText="--/--/----"
         placeholder="m/d/Y"
         dateFormat="MM/dd/yy"
         showYearDropdown
         yearDropdownItemNumber={50}
         scrollableYearDropdown
       />
        <button
       className='disabled:opacity-50 disabled:cursor-not-allowed bg-[#4871B7] text-white font-normal rounded-md px-3 w-[3.8rem] h-[33px] mt-[2.2px] '
       onClick={(e)=>handleSetClick(2,e)}
>
Set
</button>

       </div>       
        ) : (
          <div className=" text-neutral-400 flex items-center font-normal text-base"> <FormattedDate date={editedLicenseDate} />  </div>
        )}
         {editedLicenseDate  && 
       (
        (new Date(editedAMADate) >= new Date(editedLicenseDate)) 
      ) &&
       <div className=' p-1  rounded-sm absolute border-2  border-gray-400 z-50 bg-neutral-100 text-neutral-700 mt-8' ><span className='flex text-start'> <LiaExclamationSolid   className='text-white mt-[1px] mr-2 bg-amber-500 rounded-sm w-[6 rem] h-4'/> License Date should be greater</span></div> } 
      </div>
     
      </div>
               </div>




           {/* Row -- 3 (Level 1 Promotion Date) */}  



              <div className="mt-2 w-full px-3 font-inter ">
                 
                 <div
                    required  
                   className="appearance-none flex justify-between w-full font-bold bg-neutral-100 text-black text-md  py-2 px-4 leading-tight focus:outline-none focus:bg-white">
                 <div  className={` flex items-center  ${isEditing['ADate']? "w-[50%]" : "w-[70%]"} `}  >Level 1 Promotion Date:</div>
               
                 <div className={`whitespace-nowrap ${pm} font-inter flex  ${isEditing['ADate'] ? "mr-6 w-[50%]" : "mr-0 w-[30%]"} `}>
                 <div className={`flex items-center pl-2 pr-2 text-blue-700 border-none focus:outline-none ${isEditing['ADate'] ? "mr-0 bg-white py-2" : "mr-4 py-3"}`}>
                 {/* < LuCalendarDays   onClick={handleEditClick}/> */}
                 <LuCalendarDays onClick={() => toggleEdit('ADate')} />
                 </div>
        {isEditing['ADate'] ? (
          <div className='flex space-x-3'>
          <DatePicker
            name="ADate"
            className="border-none w-[112px]  bg-white text-neutral-400  font-medium leading-tight focus:outline-none focus:bg-white "
            selected={editedADate ? new Date(editedADate) : null}
            onChange={(date) => setEditedADate(date || null)}
            placeholderText="--/--/----"
            placeholder="m/d/Y"
            dateFormat="MM/dd/yy"
            showYearDropdown
            yearDropdownItemNumber={50}
         scrollableYearDropdown

          />
           <button
       className='disabled:opacity-50 disabled:cursor-not-allowed bg-[#4871B7] text-white font-normal rounded-md px-3 w-[3.8rem] h-[33px] mt-[2.2px]  '
       onClick={(e)=>handleSetClick(3,e)}
>
Set
</button>

           </div>
           
        ) : (
          
          <div className=" text-neutral-400 flex items-center font-normal text-base"> <FormattedDate date={editedADate} />  </div>
        )}
         {editedADate  &&
           (
            (new Date(editedAMADate) >= new Date(editedADate)) ||
            (new Date(editedLicenseDate) >= new Date(editedADate))
          ) &&
          <div className=' p-1 rounded-sm absolute border-2  border-gray-400 z-50 bg-neutral-100 text-neutral-700 mt-8' ><span className='flex text-start'> <LiaExclamationSolid   className='text-white mt-[1px] mr-2 bg-amber-500 rounded-sm w-[6 rem] h-4'/> Level 1 Promotion Date should be greater</span></div> } 
      </div>
    

                   </div>
               </div>

 {/* Row -- 4 (Level 2 Promotion Date) */}

               <div className="mt-2 w-full px-3 font-inter ">
                 
                 <div
                    required
                   className="appearance-none flex justify-between w-full font-bold bg-neutral-100 text-black text-md  py-2 px-4 leading-tight focus:outline-none focus:bg-white">
                 <div  className={` flex items-center  ${isEditing['SADate']? "w-[50%]" : "w-[70%]"} `}  >Level 2 Promotion Date:</div>
               
                 <div className={`whitespace-nowrap ${pm} font-inter flex    ${isEditing['SADate'] ? "mr-6 w-[50%]" : "mr-0 w-[30%]"}`}>
                 <div className={`flex items-center pl-2 pr-2 text-blue-700 border-none focus:outline-none ${isEditing['SADate'] ? "mr-0 bg-white py-2" : "mr-4 py-3"}`}>
                 {/* < LuCalendarDays   onClick={handleEditClick}/> */}
                 <LuCalendarDays onClick={() => toggleEdit('SADate')} />
                 </div>
      {isEditing['SADate'] ? (
        <div className='flex space-x-3'>
         <DatePicker
         name="SADate"
         className="border-none w-[112px] z-50000  bg-white text-neutral-400  font-medium leading-tight focus:outline-none focus:bg-white "
         selected={editedSADate ? new Date(editedSADate) : null}
         onChange={(date) => setEditedSADate(date || null)}
         placeholderText="--/--/----"
         placeholder="m/d/Y"
         dateFormat="MM/dd/yy"
         showYearDropdown
         yearDropdownItemNumber={50}
         scrollableYearDropdown
       />
        <button
       className='disabled:opacity-50 disabled:cursor-not-allowed bg-[#4871B7] text-white font-normal rounded-md px-3 w-[3.8rem] h-[33px] mt-[2.2px]  '
       onClick={(e)=>handleSetClick(4,e)}
>
Set
</button>
       
       </div>       
        ) : (
          <div className=" text-neutral-400 flex items-center font-normal text-base">  <FormattedDate date={editedSADate} />  </div>
        )}
         {editedSADate  &&
        (
          (new Date(editedAMADate) >= new Date(editedSADate)) ||
          (new Date(editedLicenseDate) >= new Date(editedSADate)) ||
          (new Date(editedADate) >= new Date(editedSADate))
        ) && <div className=' p-1 rounded-sm absolute border-2  border-gray-400 z-50 bg-neutral-100 text-neutral-700 mt-8' ><span className='flex text-start'> <LiaExclamationSolid   className='text-white mt-[1px] mr-2 bg-amber-500 rounded-sm w-[6 rem] h-4'/> Level 2 Promotion Date should be greater</span></div> } 
      </div>
       

                   </div>
               </div>

    {/* Row -- 5 (Certified Trainer Checkbox) */}

               <div className="mt-2 w-full px-3 font-inter ">
                      <div
                    required
                   className="appearance-none flex justify-between relative w-full font-bold bg-neutral-100 text-black text-md  py-2 px-4 leading-tight focus:outline-none focus:bg-white">
                 <div  className={` flex items-center  w-[90%] `}  >Certified Trainer Checkbox:</div>
                 
                 <div className={`whitespace-nowrap ${pm} font-inter flex w-[10%]`}>
                 <div className={`flex items-center pl-2 pr-2 text-blue-700 border-none focus:outline-none `}>
              
                 </div>
          <input
          type="checkbox"
          name="OJT_Certification"
          checked={editedCertifiedFieldTrainer}
          onChange={(e) => setEditedCertifiedFieldTrainer(e.target.checked)}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          
          /> 
      </div>
      </div>
    </div>
 

{/* Row --6 (Level 3 Promotion Date) */}

               <div className="mt-2 w-full px-3 font-inter ">
                 
                 <div
                    required
                   className="appearance-none flex justify-between w-full text-black font-bold bg-neutral-100  text-md  py-2 px-4 leading-tight focus:outline-none focus:bg-white">
                 <div  className={` flex items-center  ${isEditing['MDDate']? "w-[50%]" : "w-[70%]"} `}  >Level 3 Promotion Date:</div>
                
                 <div className={`whitespace-nowrap ${pm} font-inter   flex  ${isEditing['MDDate'] ? "mr-6 w-[50%]" : "mr-0 w-[30%]"}`}>
                 
                 <div className={`flex items-center pl-2 pr-2 text-blue-700 border-none focus:outline-none ${isEditing['MDDate'] ? "mr-0 bg-white py-2" : "mr-4 py-3"}`}>
                 {/* < LuCalendarDays  onClick={handleEditClick}/> */}
                 <LuCalendarDays onClick={() => toggleEdit('MDDate')} />
                 </div>
      {isEditing['MDDate'] ? (
        <div className='flex space-x-3'>
         <DatePicker
         name="MDDate"
         className=" border-none w-[112px]  bg-white text-neutral-400  font-medium leading-tight focus:outline-none focus:bg-white "
         selected={editedMDDate ? new Date(editedMDDate) : null}
         onChange={(date) => setEditedMDDate(date || null)}
         placeholderText="--/--/----"
         placeholder="m/d/Y"
         dateFormat="MM/dd/yy"
         showYearDropdown
         yearDropdownItemNumber={50}
         scrollableYearDropdown
       /> 
        <button
       className='disabled:opacity-50 disabled:cursor-not-allowed bg-[#4871B7] text-white font-normal rounded-md px-3 w-[3.8rem] h-[33px] mt-[2.2px]  '
       onClick={(e)=>handleSetClick(5,e)}
>
Set
</button>

       </div>
        ) : (
          <div className=" text-neutral-400 flex items-center font-normal text-base"> <FormattedDate date={editedMDDate} />  </div>
        )}
         {editedMDDate && 
       (
        (new Date(editedAMADate) >= new Date(editedMDDate)) ||
        (new Date(editedLicenseDate) >= new Date(editedMDDate)) ||
        (new Date(editedADate) >= new Date(editedMDDate)) ||
        (new Date(editedSADate) >= new Date(editedMDDate))
      )
        &&  <div className=' p-1 rounded-sm absolute border-2  border-gray-400 z-50 bg-neutral-100 text-neutral-700 mt-8' ><span className='flex text-start'> <LiaExclamationSolid   className='text-white mt-[1px] mr-2 bg-amber-500 rounded-sm w-[6 rem] h-4'/> Level 3 Promotion Date should be greater</span></div> } 
      </div> 
       
                   </div>
               </div>

    {/* Row --7 (Leadership Development Checkbox) */}
               <div className="mt-2 w-full px-3 font-inter ">
                      <div
                    required
                   className="appearance-none flex justify-between w-full font-bold bg-neutral-100 text-black text-md  py-2 px-4 leading-tight focus:outline-none focus:bg-white">
                 <div  className={` flex items-center w-[90%] `}  >Leadership Development Checkbox:</div>
                 
                 <div className={`whitespace-nowrap ${pm} font-inter flex  w-[10%]`}>
                 <div className={`flex items-center pl-2 pr-2 text-blue-700 border-none focus:outline-none `}>
              
                 </div>
    
          <input
          type="checkbox"
          name="OJT_Certification"
          checked={editedLeadershipTraining}
           onChange={(e) => setEditedLeadershipTraining(e.target.checked)}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          
        />  
      </div>
      </div>
      </div> 






               {/* Row --8 (Level 4 Promotion Date) */}

               <div className="mt-2 w-full px-3 font-inter ">
                 
                 <div
                    required
                   className="appearance-none flex justify-between w-full font-bold bg-neutral-100 text-black text-md  py-2 px-4 leading-tight focus:outline-none focus:bg-white">
                 <div  className={` flex items-center  ${isEditing['SMDDate']? "w-[50%]" : "w-[70%]"} `}  >Level 4 Promotion Date:</div>
                 
                 <div className={`whitespace-nowrap ${pm} font-inter flex   ${isEditing['SMDDate'] ? "mr-6 w-[50%]" : "mr-0 w-[30%]"}`}>
                 <div className={`flex items-center pl-2 pr-2 text-blue-700 border-none focus:outline-none ${isEditing['SMDDate'] ? "mr-0 bg-white py-2" : "mr-4 py-3"}`}>
                 {/* < LuCalendarDays   onClick={handleEditClick}/> */}
                 <LuCalendarDays onClick={() => toggleEdit('SMDDate')} />
                 </div>
      {isEditing['SMDDate'] ? (
        <div className='flex space-x-3'>
         <DatePicker
         showYearDropdown
         yearDropdownItemNumber={50}
         scrollableYearDropdown
         name="SMDDate"
         className="border-none w-[112px]  bg-white text-neutral-400  font-medium leading-tight focus:outline-none focus:bg-white "
         selected={editedSMDDate ? new Date(editedSMDDate) : null}
         onChange={(date) => setEditedSMDDate(date || null)}
         placeholderText="--/--/----"
         placeholder="m/d/Y"
         dateFormat="MM/dd/yy"
       />   
        <button
       className='disabled:opacity-50 disabled:cursor-not-allowed bg-[#4871B7] text-white font-normal rounded-md px-3 w-[3.8rem] h-[33px] mt-[2.2px]  '
       onClick={(e)=>handleSetClick(6,e)}
>
Set
</button>

      
       </div>    
        ) : (
          <div className=" text-neutral-400 flex items-center font-normal text-base"> <FormattedDate date={editedSMDDate} />  </div>
       
       )}
        { editedSMDDate &&
      (
        (new Date(editedAMADate) >= new Date(editedSMDDate)) ||
        (new Date(editedLicenseDate) >= new Date(editedSMDDate)) ||
        (new Date(editedADate) >= new Date(editedSMDDate)) ||
        (new Date(editedSADate) >= new Date(editedSMDDate)) ||
        (new Date(editedMDDate) >= new Date(editedSMDDate))
      ) &&  <div className=' p-1 rounded-sm absolute border-2  border-gray-400 z-50 bg-neutral-100 text-neutral-700 mt-8' ><span className='flex text-start'> <LiaExclamationSolid   className='text-white mt-[1px] mr-2 bg-amber-500 rounded-sm w-[6 rem] h-4'/> Level 4 Promotion Date should be greater</span></div> } 
      </div> 
   
           </div>
       </div>
       
    


      
                <div className=" w-full mt-[1rem] px-3 font-inter">
                 
                
                  <div className="text-gray-500 md:w-1/2"></div>
                   <div className="flex mb-2">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 "
                      checked={ischeckbox} 
                      onChange={(e) => {setIscheckbox(e.target.checked)}}
                      required
                    
                   />
                       
                    <p className="text-gray-400 font-inter text-sm font-normal leading-5  pl-2">
                    I acknowledge that the dates above are accurate to the best of my knowledge.  I understand that these dates impact organizational reporting for calculations such as commission rates.
                     
                    </p>
                  </div> 
                </div>
              </div>
              <div className=" flex justify-between font-inter mb-4 ">
                <button  onClick={handleCancelClick}
                  className="text-green-500 p-2 rounded font-inter cursor-pointer"
                  type="cancel"
                 
                >
                  Cancel 
                </button>

                <button style={{
                  background:
                    "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)" ,
                  height: "2.8rem",
                  width: "5.6rem",
                }}
                  className="text-white p-2 rounded font-inter"
                  disabled={
                    (!editedAMADate && !editedLicenseDate && !editedADate && !editedMDDate && !editedSADate && !editedSMDDate) ||
                    (
                      ((editedAMADate && editedLicenseDate && new Date(editedAMADate) >= new Date(editedLicenseDate)) ||
                        (editedAMADate && editedADate && new Date(editedAMADate) >= new Date(editedADate)) ||
                        (editedAMADate && editedSADate && new Date(editedAMADate) >= new Date(editedSADate)) ||
                        (editedAMADate && editedMDDate && new Date(editedAMADate) >= new Date(editedMDDate)) ||
                        (editedAMADate && editedSMDDate && new Date(editedAMADate) >= new Date(editedSMDDate))) ||
                      ((editedLicenseDate && editedADate && new Date(editedLicenseDate) >= new Date(editedADate)) ||
                        (editedLicenseDate && editedSADate && new Date(editedLicenseDate) >= new Date(editedSADate)) ||
                        (editedLicenseDate && editedMDDate && new Date(editedLicenseDate) >= new Date(editedMDDate)) ||
                        (editedLicenseDate && editedSMDDate && new Date(editedLicenseDate) >= new Date(editedSMDDate))) ||
                      ((editedADate && editedSADate && new Date(editedADate) >= new Date(editedSADate)) ||
                        (editedADate && editedMDDate && new Date(editedADate) >= new Date(editedMDDate)) ||
                        (editedADate && editedSMDDate && new Date(editedADate) >= new Date(editedSMDDate))) ||
                      ((editedSADate && editedMDDate && new Date(editedSADate) >= new Date(editedMDDate)) ||
                        (editedSADate && editedSMDDate && new Date(editedSADate) >= new Date(editedSMDDate))) ||
                      ((editedMDDate && editedSMDDate && new Date(editedMDDate) >= new Date(editedSMDDate)))
                    )
                  }
                 type="submit"
                >Submit</button>
              
              </div>
              
            </form>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionPopup;



