import React, { useRef, useState, useEffect } from "react";
import CampSideBar from "../CampSideBar";
import useApi from "../../../../custom-hooks/useApi";
import { useSelector } from "react-redux";
import { ReactComponent as BackIcon } from "../../../../../src/assets/chevron-left.svg";
import CalIcon from "../../../../../src/assets/calender.svg";
import downArrow from "../../../../../src/assets/down-arrow-black.svg";
import resume from "../../../../../src/assets/Group 340.svg";
import PendingSvg from "../../../../../src/assets/pending.svg";
import DeliveredSvg from "../../../../../src/assets/delivered.svg";
import RepliedSvg from "../../../../../src/assets/replied.svg";
import ErrorsSvg from "../../../../../src/assets/errors.svg";
import RecipientRow from "./RecipientRow";
import { IoChevronDownOutline, IoSearchOutline } from "react-icons/io5";

const SampleCampaign = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [data, setData] = useState([]);
  const [isRejected, setIsRejected] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [campaignClicked, setCampaignClicked] = useState(null);
  const api = useApi();
  const userRole = useSelector((state) => state.auth.role);
  const adminRoles = useSelector((state) => state.auth.adminRoles);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [firstCampaign, setFirstCampaign] = useState(null);
  const [actionsPopup, setActionsPopup] = useState(false);
  const actionPopupRef = useRef(null);



  const dataArr = [
    {
      ID: 1,
      First_Name: "John",
      Last_Name: "Doe",
      Email_Address: "john.doe@example.com",
      CountryCode: "+1",
      Mobile_Phone: "1234567890",
      updatedAt: "2024-07-28T12:34:56Z",
      RecipientStatusID: 48,
      DeliverabilityNotes: "Delivered successfully",
      RCSMSStatusID: 48,
    },
    {
      ID: 2,
      First_Name: "Jane",
      Last_Name: "Smith",
      Email_Address: "jane.smith@example.com",
      CountryCode: "+1",
      Mobile_Phone: "9876543210",
      updatedAt: "2024-07-28T14:20:30Z",
      RecipientStatusID: 50,
      DeliverabilityNotes: "Received and read",
      RCSMSStatusID: 50,
    },
    {
      ID: 3,
      First_Name: "Alice",
      Last_Name: "Johnson",
      Email_Address: "alice.johnson@example.com",
      CountryCode: "+1",
      Mobile_Phone: "5555555555",
      updatedAt: "2024-07-28T09:15:00Z",
      RecipientStatusID: 49,
      DeliverabilityNotes: "Failed to deliver",
      RCSMSStatusID: 49,
    },
  ];
  
  const ConstStatus = [
    { ID: 45, Name: "Queued" },
    { ID: 46, Name: "Sending" },
    { ID: 47, Name: "Sent" },
    { ID: 48, Name: "Delivered" },
    { ID: 49, Name: "Failed" },
    { ID: 50, Name: "Received" },
    { ID: 51, Name: "Read" },
  ];
  


  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleCampaignDetails = (campaign) => {
    setCampaignClicked(campaign);
  };

  useEffect(() => {
    setIsAdmin(userRole === "super_admin");
  }, [adminRoles, userRole]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/Campaign/ShortCampaign`);
        setData(response.data?.check);
        setIsPending(response.data?.check?.Status === 23);
        setIsRejected(response.data?.check?.Status === 25);
        setIsApproved(response.data?.check?.Status === 24);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const handleClickOutside = (event) => {
    if (actionPopupRef.current && !actionPopupRef.current.contains(event.target)) {
      setActionsPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const actioinHandler = () => {
    setActionsPopup(false);
  };
  const toggleNewActionsPopup = () => {
    console.log(actionsPopup)
    setActionsPopup(!actionsPopup);
  };
  return (
    <>
      <div className="flex">
        <div>
          <CampSideBar
            setFirstCampaign={setFirstCampaign}
            campaignClick={toggleCampaignDetails}
            CountryCode={data.CountryCode}
            number={data.PhoneNo}
            status={data.Status}
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div className="w-full flex flex-col bg-gray-100 px-8 py-8">
          <div className="flex items-center font-inter mt-2 text-green-500">
            <div className="inline-flex flex-row cursor-pointer items-center">
              <span className="inline-flex items-center ">
                <BackIcon className="h-4 text-green-500 dark:text-green" />
                Back
              </span>
            </div>
          </div>
          <div className="mt-5">
            <div className="flex">
              <img src={CalIcon} alt="cal-icon" />
              <span className="text-xs text-gray-500 ml-1">24 Jan 2025</span>
            </div>
          </div>

          <div className="flex text-[28px] justify-between items-center mt-1 min-w-full">
          <h1 className="text-[28px] font-semibold">
          Sample Campaign
          </h1>
        </div>

        <div className="bg-white border border-gray-300 my-4 rounded">
        <div className="flex flex-row justify-between py-5 pl-5 font-inter text-center text-base ">
          <StatsCard label={'Sent'} number={'0'}/>
          <StatsCard label={'Delivered'} number={'0'}/>
          <StatsCard label={'Replies'} number={'0'}/>
          <StatsCard label={'Conversations'} number={'0'}/>
          <StatsCard label={'Appt set'} number={'0'}/>
          <StatsCard label={'Appt done'} number={'0'}/>
          <StatsCard label={'Agent Apps'} number={'0'}/>
          <StatsCard label={'Client Apps'} number={'0'}/>
        </div>
        </div>

        <h4 className="text-gray-500 font-medium ml-1">
        Your file has been received and is being processed for compliance and deliverability.  This can take up to 5 minutes.  
        </h4>

        <div className="bg-white border border-gray-300 my-4 p-5 flex flex-col">
          <div className=" flex justify-between mb-4">
            <div
              onClick={toggleExpand}
              className="cursor-pointer flex p-2 max-w-[200px]"
            >
              <span className="font-semibold">Campaign Progress</span>
              <img
                className={`ml-2 transition-transform transform ${
                  isExpanded ? "rotate-0" : "rotate-180"
                }`}
                src={downArrow}
                alt="down-up-arrow"
              />
            </div>
            <div>
              <img src={resume} alt="" />
            </div>
          </div>

          {
            isExpanded ? (<div className="flex flex-col">

              <div className="w-full h-[1px] bg-gray-300 my-4"></div>
              <div className="flex flex-col gap-3">
                <div className="font-semibold text-gray-800">Messages</div>
                <div className="flex flex-row justify-between items-center">
                  <div className="font-medium text-gray-500">Sent to All Recipients</div>
                  <div className="flex flex-row gap-4 items-center">
                    <button className="p-2 px-6 bg-gray-200 text-gray-500 font-semibold rounded-3xl text-[13px]">Not Sent</button>
                    <div className="font-medium text-gray-400">Dec 07, 2023</div>
                    <div className="font-medium text-gray-400">09:31 AM</div>
                  </div>
                </div>
                <div className="font-medium text-sm text-gray-400">Hi WealthSmyth Customer Success!  This is [My Full Name].  This my first SMS Campaign.  Just wanted to say hello and I’m looking forward to working with you! 😊</div>
              </div>

            </div>) : null      
          }

        </div>

        <h4 className="text-gray-500 font-medium my-2 ml-1">
        Your file has been received and is being processed for compliance and deliverability.  This can take up to 5 minutes.  
        </h4>

        <div className="bg-white border border-gray-300 my-3 p-6">
          <div className="flex justify-between">
            <span className="text-[20px] font-semibold">Recipients</span>
            <div className="flex items-center justify-center">
              <img
                src={PendingSvg}
                alt="pending-svg"
                className="w-[24px] h-[24px] rounded-full bg-[#4971B8] mr-2"
              />
              <span className="text-[#9CA3AF] font-semibold pb-1 mr-4 ">
                Pending
              </span>
              <img
                src={DeliveredSvg}
                alt="pending-svg"
                className="w-[24px] h-[24px] rounded-full bg-[#4971B8] mr-2"
              />
              <span className="text-[#4971B8] font-semibold pb-1 mr-4">
                Delivered
              </span>
              <img
                src={RepliedSvg}
                alt="pending-svg"
                className="w-[24px] h-[24px] rounded-full bg-[#4971B8] mr-2"
              />
              <span className="text-[#198955] font-semibold pb-1 mr-4">
                Replied
              </span>
              <img
                src={ErrorsSvg}
                alt="pending-svg"
                className="w-[24px] h-[24px] rounded-full bg-[#4971B8] mr-2"
              />
              <span className="text-[#EA2D38] font-semibold pb-1 mr-4">
                Errors
              </span>
            </div>
          </div>
          <div className="mt-5 border-t border-[#E5E7EB] h-1"></div>
          <>
            <div className="flex justify-between items-center p-4">
              <div className=" flex flex-col justify-center items-center gap-2 mb-9 mt-3">
                <div className="text-[13px] font-semibold text-left w-full">Filter by</div>
                <select
                  className="appearance-none block h-[40px] w-[250px] bg-neutral-100 text-neutral-700 text-sm border-0  px-4 leading-tight focus:outline-none focus:bg-white"
                  name="Status"
                  defaultValue=""
                  required
                >
                  <option value="">All</option>
                  <option value="">option 2</option>
                  <option value="">option 3</option>
                  <option value="">option 4</option>
                </select>
              </div>
              <div className="relative w-[270px] h-[50px]">
                <IoSearchOutline className="absolute left-3 w-6 h-6 top-[10px] text-gray-500" />
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full h-[2.7rem] focus:outline-none pl-10 text-sm border-none rounded gap-4  bg-[#F3F4F6]"
                />
              </div>
              <div ref={actionPopupRef}>
                <button
                  onClick={toggleNewActionsPopup}
                  style={{
                    height: "2.7rem",
                  }}
                  className="flex flex-row justify-between w-[193px] h-[2.7rem]  bg-transparent text-[#4871B7] border-[#4871B7] text-sm font-semibold  py-2 px-4 mb-1 border rounded font-inter flex items-center"
                >
                  Actions{" "}
                  <IoChevronDownOutline className=" mt-1 font-bold text-xl ml-3 text-[#4871B7]" />
                </button>

                {actionsPopup && (
                  <>
                    <div
                      className="absolute z-10 w-[240px] bg-white border rounded shadow-lg mt-1 mr-40 right-[45px]"
                      onClick={actioinHandler}
                      onMouseLeave={() => setActionsPopup(false)}
                    >
                      <ul className="border">
                        <li className="border-0 px-4 py-2 cursor-pointer hover:bg-[#2BB574] hover:text-white text-left">
                          Export
                        </li>
                        <li className="border-0 px-4 py-2 cursor-pointer hover:bg-[#2BB574] hover:text-white text-left">
                          Import
                        </li>
                        <li className="border-0 px-4 py-2 cursor-pointer hover:bg-[#2BB574] hover:text-white text-left">
                          View Import History
                        </li>
                        <li className="border-0 px-4 py-2 cursor-pointer hover:bg-[#2BB574] hover:text-white text-left">
                          Delete
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <button
                style={{
                  background:
                    "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                }}
                className="h-[2.75rem] bg-transparent text-white text-sm font-semibold hover:text-white py-2 px-6 mb-1 border hover:border-transparent rounded font-inter"
              >
                + New
              </button>
            </div>
          </>

          <div>
      
      <table className=" min-w-full mx-auto text-left z-0">
        <thead className="font-medium text-sm text-gray-400">
          <tr className="px-4 py-4">
            <th className="border-b border-gray-300 py-2 px-4">Name</th>
            <th className="border-b border-gray-300 py-2 px-4">Phone</th>
            <th className="border-b border-gray-300 py-2 px-[0.5rem] pr-1">Last Updated</th>
            <th className="border-b border-gray-300 py-2 pr-2">SMS Status</th>
            <th className="border-b border-gray-300 py-2 px-2 pr-1">Deliverability Note</th>
          </tr>
        </thead>
        <tbody className="text-gray-700 font-inter text-sm font-medium leading-5">
          {dataArr.map((item) => (
            <RecipientRow data={item} change={()=>{}} ConstStatus={ConstStatus}/>
          ))}
        </tbody>
      </table>
    </div>
        </div>

        </div>
      </div>
    </>
  );
};

export default SampleCampaign;

const StatsCard = ({ label, number }) => {
  return (
    <div className="flex flex-col flex-1">
              <span className="mb-1 font-semibold text-left">{label}</span>
              <span className="text-gray-500 text-left">{number}</span>
  </div>
  );
};

