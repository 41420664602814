import React, { useEffect, useState } from "react";
import CountryStateDropdown from "./dropdowns/CountryStateDropdown";
import AreaCodeDropdown from "./dropdowns/AreaCodeDropdown";
import useApi from "../../../custom-hooks/useApi";
import { BASE_URL } from "../../../appconfig";
import { ReactComponent as BackIcon } from "../../../assets/chevron-left.svg";
import Spinner from "../../common/Spinner";
import { toast } from "react-toastify";
import { LiaExclamationSolid } from "react-icons/lia";
import NoPhoneCampaigns from "./campaignDetails/NoPhoneCampaigns";
import campaignsLandingSvg from "../../../assets/Send SMS Graphic 1.svg";
import wavingHandSvg from "../../../assets/Waving-hand-gestures-emoji-on-transparent-background-PNG 1.svg";
import crossSvg from "../../../assets/x.svg";

function NoPhone({ isPending, isRejected }) {
  const [statesData, setStatesData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const [areaCode, setAreaCode] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [isStateIdError, setisStateIdError] = useState(false);
  const [isAreaError, setisAreaError] = useState(false);
  const [isPhoneLineAdded, setIsPhoneLineAdded] = useState(false);

  const api = useApi();
   const handleSubmit1 = () => {
     setIsPhoneLineAdded(true);
   };


  const handleStateClick = (state) => {
    setState(state);
  };

  const handleAreaClick = (areaCode) => {
    setAreaCode(areaCode);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchCountryData = async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `${BASE_URL}/RingCentral/Get_All_States`
        );
        if (isMounted) {
          setStatesData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!isPending && !isRejected) {
      fetchCountryData();
    }

    return () => {
      isMounted = false;
    };
  }, [isPending, isRejected]);

  useEffect(() => {
    if (state.id) {
      setisStateIdError(false);
    }

    if (areaCode) {
      setisAreaError(false);
    }
  }, [areaCode, state.id]);

  useEffect(() => {
    const fetchAreaData = async () => {
      try {
        if (Object.keys(state).length !== 0) {
          const response = await api.get(
            `${BASE_URL}/RingCentral/Get_Area_Codes?stateId=${state.id}`
          );
          setAreaData(response.data);
          setAreaCode(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAreaData();
  }, [state]);

  const handleSubmit = async () => {
    if (!state.id && !areaCode) {
      setisStateIdError(true);
      setisAreaError(true);
      return;
    }
    const json = {
      State: state.name,
      AreaCode: areaCode,
      CountryCode: state.country.id,
    };
    if (!isButtonClicked) {
      setIsButtonClicked(true);
      try {
        const response = await api.post(
          `${BASE_URL}/Campaign/OrgCampaign`,
          json
        );
        if (response.status === 200 && response.data.success) {
          toast.success("Data added successfully", {
            theme: "dark",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          const errorMessage = response.message || "Failed to add data";
          toast.error(errorMessage, {
            theme: "dark",
          });
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          theme: "dark",
        });
        console.error("Error updating data:", error);
      }
    }
  };

  let content = "";

  if (loading) {
    content = <Spinner />;
  } else if (isPending) {
    content = (
      <div className="h-[400px]">
        <h2 className="text-[28px] font-semibold text-gray-700 pt-5 mt-5">
          Request Pending
        </h2>
        <h4 className="font-medium text-gray-500 text-sm p-3">
          Your request is in the pending state. Please contact the
          administrator.
        </h4>
      </div>
    );
  } else if (isRejected) {
    content = (
      <div className="h-[400px]">
        <h2 className="text-[28px] font-semibold text-gray-700 pt-5 mt-5">
          Request Rejected
        </h2>
        <h4 className="font-medium text-gray-500 text-sm p-3">
          Your request is rejected. Please contact the administrator.
        </h4>
      </div>
    );
  } else {
    content =  !isPhoneLineAdded ? (
     
      <>
        <div className="flex flex-row items-center justify-between bg-[#ED7E18] p-6">
          <div className="flex flex-row gap-2">
            <img src={wavingHandSvg} alt="" />
            <div className="flex flex-col gap-1 ">
              <div className="font-semibold text-white">Phone Line Alert</div>
              <div className="text-[13px] font-normal text-white">
                You have no phone line linked. Please add a phone line to your
                account to start managing and sending SMS via Campaigns.
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="px-2 py-2 bg-white font-semibold  text-[#4971B8]">
                Add Phone Line
              </div>
            </div>
          </div>
          <div>
            <img src={crossSvg} alt="" />
          </div>
        </div>
        <div className="flex flex-col items-center">
          {/* Image Part */}
          <div className="flex justify-center ">
            <img
              src={campaignsLandingSvg}
              alt="Landing Image"
              className="rounded-l-lg object-cover"
            />
          </div>
          {/* Content Part */}
          <div className="flex flex-col justify-center items-center ml-16">
            <div className="w-[80%] p-8 flex flex-col justify-center items-center">
              <h1 className="text-[28px] font-semibold text-gray-800 mb-4 text-center">
                Send SMS Campaigns with WealthSmyth
              </h1>
              <p className="font-medium text-gray-500 mb-6 text-center">
                Make external phone calls and run SMS outreach campaigns that
                reach hundreds of prospects and clients at once with
                personalized messages. In order to get started, you must first
                add a phone line.
              </p>
              <button
                onClick={handleSubmit1}
                className="font-semibold bg-[#4971B8] text-white px-6 py-3 rounded-md w-full"
              >
                Add Phone Line
              </button>
            </div>
          </div>
        </div>
        </>
        
      ) : (
        <>
      <div>
        <h2 className="text-[28px] font-semibold text-gray-700 pt-5 mt-5">
          External Phone Number and SMS Campaigns
        </h2>
        <h4 className="font-medium text-gray-500 text-sm p-3">
          Make external phone calls and run SMS outreach campaigns.
        </h4>
        <div className="flex justify-center mt-5 space-x-10">
          <div className="flex flex-col gap-2">
          <CountryStateDropdown
            stateClick={handleStateClick}
            data={statesData}
          />
          {isStateIdError &&  <div className=' rounded-md gap-2 border border-none w-68 h-10 flex items-center border-gray-200  bg-neutral-100 text-neutral-700' style={{textWrap:"wrap"}}><span className='flex text-start'> <LiaExclamationSolid   className='text-white mt-[1px] bg-amber-500 rounded-sm w-5 h-5'/> Please Select Country/State</span></div> }
          </div>
          <div className="flex flex-col gap-2">
          <AreaCodeDropdown handleChange={handleAreaClick} data={areaData} />
          {isAreaError &&  <div className=' rounded-md gap-2 border border-none w-68 h-10 flex items-center border-gray-200  bg-neutral-100 text-neutral-700' style={{textWrap:"wrap"}}><span className='flex text-start'> <LiaExclamationSolid   className='text-white mt-[1px] bg-amber-500 rounded-sm w-5 h-5'/> Please Select Area Code</span></div> }
          </div>
        </div>
        <button
          onClick={handleSubmit}
          style={{
            background:
              "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
            height: "3rem",
          }}
          className="w-[235px] text-white p-2 rounded font-inter mt-10"
          type="submit"
        >
          Upgrade to Professional
        </button>
        <p className="text-gray-500 text-base font-medium mt-3">
          Recommended for teams that prospect.
        </p>
        <p className="text-green-500 text-base font-medium mt-5 mb-10">
          View our pricing plans
        </p>
      </div>
      </>
      )};
  
  return (
    <>
      <div className="flex items-center font-inter mt-2 text-green-500 px-2 py-2">
        <div className="inline-flex flex-row cursor-pointer items-center">
          <span
            className="inline-flex items-center ml-2"
            onClick={() => window.history.back()}
          >
            <BackIcon className="w-4 h-4 text-green-500 dark:text-green" />
            Back
          </span>
        </div>
      </div>
      <div className="bg-white border border-gray-300 rounded-lg px-4 py-4 m-6 items-center justify-center text-center">
        {content}
      </div>
    </>
  );
}

export default NoPhone;
