import React from 'react';
import CircularRings from './CircularRings';
import BarGraph from './Bargraph';
import BarlineChart from './BarlineChart';
import Circle from '../../../../../assets/Circletick.png';

function Performancebody() {
  return (
    <div>
        <div className='bg-neutral-100'>

       {/* two 50-50 boxes */}
       <div className='w-[100%] my-6 flex space-x-4'>
            {/* 1st box */}
<div className='bg-white w-[50%] h-[348px] border shadow-md rounded py-6 px-8'>
<h className="text-neutral-700 text-xl font-semibold font-inter">Net Points This Month</h>
<div className=' bg-neutral-100 w-[8.4rem] h-[7.2rem] py-3 px-5 mt-6 rounded'>
    <p className=' text-neutral-700 font-bold font-inter text-3xl pt-4'>400k</p>
    <p className=' text-neutral-500 font-inter text-base  pt-1'>Net Points</p>
</div>

<div className=' py-2 pt-[2.1rem]'>
    <h className="text-neutral-500 font-inter font-medium text-base">Net Points Goal</h>
    <div className='w-[100%] flex pb-1'>
    <div className=" mt-2 w-[90%] " >
             <div className=" bg-neutral-200 h-[0.5rem] rounded-full mb-3" >
             <div id="progressBar"  className=" bg-[#4871B7]  h-full rounded-full" style={{width:"90%"}}></div>
             </div>
            {/* <input type="range" className="w-full appearance-none bg-green-500 rounded-full h-4" min="0" max="100" value="0" id="slider"/> */}
             </div>
             <div className=" w-[10%] font-semibold text-sm pl-3" style={{color:"#4871B7"}} >90%</div>
             </div>

    <h className="text-neutral-500 font-inter font-medium text-base ">Growth Year Over Year</h>
    <div className='w-[100%] flex'>
     <div className=" mt-2 w-[90%]" >
             <div className=" bg-neutral-200 h-[0.5rem] rounded-full mb-3" >
             <div id="progressBar"  className=" bg-[#4871B7]  h-full rounded-full" style={{width:"71%"}}></div>
             </div>
            {/* <input type="range" className="w-full appearance-none bg-green-500 rounded-full h-4" min="0" max="100" value="0" id="slider"/> */}
             </div>
             <div className=" w-[10%] font-semibold text-sm pl-3" style={{color:"#4871B7"}} >71%</div>
             </div>
</div>

     </div>
     {/* 2nd box */}
     <div className='bg-white w-[50%] h-[348px] border shadow-md rounded py-6 px-8'>
<h className="text-neutral-700 text-xl font-semibold font-inter">Open Applications</h>


<div className='py-2 pt-4'>
    <h className="text-neutral-500 font-inter font-medium text-base">Sent for Signatures</h>
    <div className='w-[100%] flex pb-1'>
    <div className=" mt-2 w-[90%] " >
             <div className=" h-[0.5rem] rounded-full mb-3" >
             <div id="progressBar"  className=" bg-[#4871B7]  h-full rounded-full" style={{width:"92%"}}></div>
             </div>
            {/* <input type="range" className="w-full appearance-none bg-green-500 rounded-full h-4" min="0" max="100" value="0" id="slider"/> */}
             </div>
             <div className=" w-[10%] font-semibold text-sm pl-3" style={{color:"#4871B7"}} >754</div>
             </div>

    <h className="text-neutral-500 font-inter font-medium text-base ">Apps Submitted</h>
    <div className='w-[100%] flex'>
     <div className=" mt-2 w-[90%]" >
             <div className="h-[0.5rem] rounded-full mb-3" >
             <div id="progressBar"  className=" bg-[#4871B7]  h-full rounded-full" style={{width:"80%"}}></div>
             </div>
            {/* <input type="range" className="w-full appearance-none bg-green-500 rounded-full h-4" min="0" max="100" value="0" id="slider"/> */}
             </div>
             <div className=" w-[10%] font-semibold text-sm pl-3" style={{color:"#4871B7"}} >489</div>
             </div>

             <h className="text-neutral-500 font-inter font-medium text-base ">Outstanding Requirements</h>
    <div className='w-[100%] flex'>
     <div className=" mt-2 w-[90%]" >
             <div className=" h-[0.5rem] rounded-full mb-3" >
             <div id="progressBar"  className=" bg-[#4871B7]  h-full rounded-full" style={{width:"65%"}}></div>
             </div>
            {/* <input type="range" className="w-full appearance-none bg-green-500 rounded-full h-4" min="0" max="100" value="0" id="slider"/> */}
             </div>
             <div className=" w-[10%] font-semibold text-sm pl-3" style={{color:"#4871B7"}} >289</div>
             </div>

        <h className="text-neutral-500 font-inter font-medium text-base ">Medical Records</h>
    <div className='w-[100%] flex'>
     <div className=" mt-2 w-[90%]" >
             <div className=" h-[0.5rem] rounded-full mb-3" >
             <div id="progressBar"  className=" bg-[#4871B7]  h-full rounded-full" style={{width:"50%"}}></div>
             </div>
            {/* <input type="range" className="w-full appearance-none bg-green-500 rounded-full h-4" min="0" max="100" value="0" id="slider"/> */}
             </div>
             <div className=" w-[10%] font-semibold text-sm pl-3" style={{color:"#4871B7"}} >15</div>
             </div>

        <h className="text-neutral-500 font-inter font-medium text-base ">Labs</h>
    <div className='w-[100%] flex'>
     <div className=" mt-2 w-[90%]" >
             <div className="  h-[0.5rem] rounded-full mb-3" >
             <div id="progressBar"  className=" bg-[#4871B7]  h-full rounded-full" style={{width:"28%"}}></div>
             </div>
            {/* <input type="range" className="w-full appearance-none bg-green-500 rounded-full h-4" min="0" max="100" value="0" id="slider"/> */}
             </div>
             <div className=" w-[10%] font-semibold text-sm pl-3" style={{color:"#4871B7"}} >8</div>
             </div>
             
                               
</div>

     </div>

          </div>

{/* 2nd boxes  */}


<div className='w-[100%] my-6 flex space-x-4'>
            {/* 1st box */}
<div className='bg-white w-[50%] h-[262px] border shadow-md rounded py-6 px-8'>
<h className="text-neutral-700 text-xl font-semibold font-inter">Recruit to Clients</h>
<div className='flex space-x-8'>
<div className=' flex  text-white bg-[#159959] w-[12rem] h-[7.4rem] py-2 pl-5  mt-6 rounded'>
    <div className='py-3' >
    <p className='  font-bold font-inter text-3xl pt-4'>25-32</p>
    <p className='  font-inter text-base '>Current Actuals</p>
</div>
<div className=' pl-4 w-10 h-6 '><img  src={Circle}/></div>

</div>

<div className='  bg-neutral-100 w-[12rem] h-[7.4rem] py-3 px-5 mt-6 rounded'>
    <p className='  text-neutral-700 font-bold font-inter text-3xl pt-4'>20-20-30</p>
    <p className=' text-neutral-500  font-inter text-base '>Monthly Goal</p>
</div>
</div>
</div>

{/* 2nd box  */}

<div className='bg-white w-[50%] h-[262px] border shadow-md rounded py-6 px-8'>
<h className="text-neutral-700 text-xl font-semibold font-inter">Recruit to Net License</h>
<div className='flex  '>

<CircularRings progressList={[60, 70, 90]} />

        <div className="flex flex-col  space-y-5 pl-24 pt-10 ">
          <div className="flex gap-4">
          <div className="w-4 h-4 rounded-sm bg-[#2BB574]">  </div>
          <div className=" text-neutral-400 font-base font-inter text-sm ">43 Business Partners</div>
          </div>
          <div className="flex gap-4">
           <div className="bg-[#4871B7] rounded-sm w-4 h-4"></div>
           <div className=" text-neutral-400 font-base font-inter  text-sm ">34 First Paychecks</div>
           </div>
           <div className="flex gap-4">
           <div className="bg-[#FF51B9] rounded-sm w-4 h-4"></div>
           <div className=" text-neutral-400 font-base font-inter  text-sm "> 17 Net Licensed</div>
           </div>
</div>
</div>
</div>
</div>

{/* 3rd Boxes */}

<div className='w-[100%] my-6 flex  space-x-4'>
            {/* 1st box */}
<div className='bg-white w-[100%]  border shadow-md rounded py-6 pl-6'>
    <div className=' flex flex-col'>
        <div className='flex justify-between' >
<h className="text-neutral-700 text-xl font-semibold font-inter">Licensed Agents by Cash Flow Milestone</h>
<div className='flex pr-16 '>
<div className='bg-[#4871B7] w-[0.9rem] h-[0.9rem] rounded-sm mt-[5px] mr-[7px]'></div>
<p className=' text-neutral-400 font-normal font-inter text-sm'>Licensed Agents</p>
</div>
</div>
<h className="text-neutral-700 font-inter font-medium text-xs pt-3">Licensed Agents</h>
</div>
<BarGraph/>

</div>
</div>



{/* 4th boxes */}

<div className='w-[100%] my-6 flex space-x-4'>
            {/* 1st box */}
<div className='bg-white w-[100%]  border shadow-md rounded py-6 px-8'>
<div className=' flex flex-col'>
    <div className='flex justify-between'>
<h className="text-neutral-700 text-xl font-semibold font-inter">This Year vs Last Year</h>
<div className='flex pr-16 space-x-4'>
    <div className='flex'>
    <div className=' bg-[#FF51B9] w-[0.9rem] h-[0.9rem] rounded-sm mt-[5px] mr-[7px]'></div>
<p className=' text-neutral-400 font-normal font-inter text-sm'>Recruits</p>
</div>
<div className='flex '>
<div className='bg-[#4871B7] w-[0.9rem] h-[0.9rem] rounded-sm mt-[5px] mr-[7px]'></div>
<p className=' text-neutral-400 font-normal font-inter text-sm'>Last Year</p>
</div>
<div className='flex'>
<div className='bg-[#2BB574] w-[0.9rem] h-[0.9rem] rounded-sm mt-[5px] mr-[7px] '></div>
<p className=' text-neutral-400 font-normal font-inter text-sm'>Current year</p>
</div>
</div>
</div>
<div className='flex justify-between'>
<h className="text-neutral-700 font-inter font-medium text-xs pt-3">Recruits</h>
<h className="text-neutral-700 font-inter font-medium text-xs pt-3 pr-8">Points</h>
</div>
</div>
<BarlineChart/>
</div>
</div>
     
        </div>
    </div>
  )
}

export default Performancebody