import React from "react";
import TapUserIcon from '../../../assets/tap-user.svg';
import ProfileIcon from "../../../assets/profile.svg";
import { useSelector } from "react-redux";
import MyCampaigns from "./campaignTaproot/MyCampaigns";
import { IMAGE_URL } from "../../../appconfig";
import {ReactComponent as SideBarIndicatorIcon} from "../../../assets/SideBarIndicator.svg"

function CampSideBar({campaignClick, status, isSidebarOpen,CountryCode, number, toggleSidebar  , setFirstCampaign}) {
  const Fullname = useSelector((state) => state.auth.fullName);
  const user = useSelector((state) => state.auth.user);

  const splitPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        const formattedPhoneNumber = `(${match[1]}) ${match[2]}-${match[3]}`;
        return formattedPhoneNumber;
      } else {
        return phoneNumber;
      }
    } else {
      return phoneNumber;
    }
  };

  const formattedPhoneNumber = splitPhoneNumber(number);

  return (
    <aside
      style={{
        width: "260px",
        marginLeft: isSidebarOpen ? "0" : "-12rem",
      }}
      className={`bg-gray-800 text-white min-h-screen h-full transition-all duration-300`}
    >
      <div
        className={`flex items-center p-5 ${
          isSidebarOpen ? "justify-start" : "justify-end"
        }`}
      >
        <SideBarIndicatorIcon
          className={`w-6 h-6 cursor-pointer transform ${
            isSidebarOpen ? "rotate-0" : "rotate-180"
          }`}
          onClick={() => toggleSidebar()}
        />
      </div>
      <div
        className={`flex items-center p-5 gap-2  ${
          isSidebarOpen ? "" : "justify-end"
        }`}
      >
        <img className="cursor-pointer rounded-[50%] w-[30px] h-[30px]"
          src={
            user.ImageName ? `${IMAGE_URL}/${user.ImageName}.jpg` : TapUserIcon
          }
          alt="profile"
          // { ...CampSideBar.user?.CampaignName ? user.CampaignName.charAt(0) : ''}

        />
        {isSidebarOpen && (
          <div className="flex flex-col items-left justify-center">
            <span className="text-white text-sm cursor-pointer font-medium">
              {Fullname}
            </span>
            <div className=" flex">
            <span className="text-white text-xs cursor-pointer font-normal pr-1">
              {CountryCode}
            </span>
            
            {/* <span className="text-white text-xs cursor-pointer font-normal">
              {number ? number : "No phone number"}
            </span> */}
          
            <span className="text-white text-xs cursor-pointer font-normal">
           {formattedPhoneNumber}
            </span> 
            </div>
          </div>
        )}
      </div>
   
      <div className="campaigns-container">
        <MyCampaigns setFirstCampaign={setFirstCampaign} status={status} number={number} campaignClick={campaignClick}  name={Fullname} isSidebarOpen={isSidebarOpen} />
      </div>
    </aside>
  );
}

export default CampSideBar;
