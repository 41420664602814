import React, { useEffect, useRef, useState } from 'react';
import LifeStyleGoalForm from './LifeStyleGoalForm';
import useApi from '../../../custom-hooks/useApi';
import { BASE_URL } from '../../../appconfig';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FiMoreHorizontal } from 'react-icons/fi';
import Spinner from '../../common/Spinner';
import { toast } from 'react-toastify';

function LifeStyleGoals({ AccountID }) {
    const formRef = useRef(null);
    const api = useApi();
    const [goalsList, setGoalsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState([]);

    const [editableGoal, setEditableGoal] = useState(null)


    const [isNewGoal, setIsNewGoal] = useState(false)

    useEffect(() => {
        loadLifeStylesGoals();
    }, []);

    const loadLifeStylesGoals = async () => {
        try {
            setLoading(true);
            const response = await api.get(`${BASE_URL}/Account/Goals/${AccountID}`);
            if (response?.status === 200) {
                setGoalsList(response?.data?.data);
                setDropdownVisible(new Array(response?.data?.data.length).fill(false));
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const formatDate = (dateString) => {
        if (dateString !== "None") {
            const options = { year: 'numeric', month: 'short', day: '2-digit' };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', options);
        } else {
            return "None";
        }
    };

    const toggleDropdown = (index) => {
        const updatedVisibility = dropdownVisible.map((visible, i) => (i === index ? !visible : false));
        setDropdownVisible(updatedVisibility);
    };

    const handleEdit = async (goal) => {
        try {
            setIsNewGoal(true)
            setEditableGoal(goal);
            formRef?.current?.scrollIntoView({ behavior: 'smooth' });
        } catch (error) {
        }
    };

    const handleDelete = async (goalID) => {
        try {
            setLoading(true)
            const response = await api.delete(`${BASE_URL}/Client/Goals/${goalID}`)

            if (response?.status === 200) {
                toast.success(response?.message);
                loadLifeStylesGoals()
            }
            setLoading(false)

        } catch (error) {
            toast.error("Failed to delete gaol");
            setLoading(false)

        }
    };

    const handleNewGoalClick = () => {
        setIsNewGoal(true)
        setEditableGoal(null)
    }

    const handleCancelClick=()=>{
        setIsNewGoal(false)
    }

    return (
        <div>
            <div className='text-[#1F2937] text-lg font-inter font-bold mb-4'>Lifestyle Goals</div>
            {loading && <Spinner />}
            <div className='bg-white p-4'>
                <div className='flex'>
                    <div className='w-3/2 px-4'>
                        <div className='text-[#6B7280] font-normal font-inter'>
                            Goals are dreams you write down. Whether you dream of buying a home,
                            traveling the world, starting a business, or saving for education,
                            you can set goals below and track your progress towards achieving them.
                        </div>
                    </div>
                    <button
                        onClick={handleNewGoalClick}
                        style={{
                            background: "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                            height: "2.7rem",
                        }}
                        className="w-1/4 bg-transparent text-white text-sm font-semibold hover:text-white py-2 px-4 mr-4 border hover:border-transparent rounded font-inter"
                    >
                        + New Goal
                        {/* {!isNewGoal ? "+ New Goal" : "Cancel"} */}
                    </button>
                </div>
                <div ref={formRef}>
                    {
                        isNewGoal ? (
                            <LifeStyleGoalForm AccountID={AccountID} reloadGoals={loadLifeStylesGoals} goalToBeEdit={editableGoal} cancel={handleCancelClick} />
                        ) : null
                    }

                </div>
                {/* Show list of goals here */}
                {goalsList?.length > 0 ? (
                    <>
                        {goalsList.map((goalItem, index) => (
                            <div className='bg-white p-4 border mt-4' key={goalItem?.id}>
                                <div className='flex justify-between'>
                                    <div className='text-base text-[#1F2937] font-bold'>
                                        {goalItem?.GoalName}
                                    </div>
                                    <div className='relative text-[#4871B7] '>
                                        <FiMoreHorizontal
                                            className='cursor-pointer font-bold  text-lg'
                                            onClick={() => toggleDropdown(index)}
                                        />
                                        {dropdownVisible[index] && (
                                            <div className="absolute z-10 w-auto bg-white border rounded shadow-lg mt-2 right-0">
                                                <ul className="border rounded">
                                                    <li
                                                        className="border-0 rounded px-4 py-2 text-black cursor-pointer hover:bg-green-500 hover:text-white"
                                                        onClick={() => handleEdit(goalItem)}
                                                    >
                                                        Edit
                                                    </li>
                                                    <li
                                                        onClick={() => handleDelete(goalItem?.GoalID)}

                                                        className="border-0 rounded px-4 py-2 text-black cursor-pointer hover:bg-green-500 hover:text-white"
                                                    >
                                                        Delete
                                                    </li>

                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='flex space-x-[200px] mt-10'>
                                    <div>
                                        <div className='text-[#9CA3AF] font-medium text-sm'>Target Value</div>
                                        <div className='mt-4 text-[#3A4253] font-semibold'>$ {goalItem?.TargetValue}</div>
                                    </div>
                                    <div>
                                        <div className='text-[#9CA3AF] font-medium text-sm'>Actual Value</div>
                                        <div className='mt-4 text-[#3A4253] font-semibold'>$ {goalItem?.ActualValue}</div>
                                    </div>
                                    <div>
                                        <div className='text-[#9CA3AF] font-medium text-sm'>Target Date</div>
                                        <div className='mt-4 text-[#3A4253] font-semibold'>{formatDate(goalItem?.TargetDate)}</div>
                                    </div>

                                    <div className='flex space-x-4'>
                                        <div className='mt-4' style={{ width: '60px', height: '60px', marginTop: "-20px", marginLeft: "60px" }}>
                                            <CircularProgressbar
                                                value={goalItem?.PercentOfGoal}
                                                text={`${Math.round(goalItem?.PercentOfGoal)}%`}
                                                styles={buildStyles({
                                                    pathColor: `#2BB574`,
                                                    textColor: '#2BB574',
                                                    trailColor: '#d6d6d6',
                                                })}
                                            />
                                        </div>

                                        <div className='' style={{ marginTop: "-20px" }}>
                                            <div className='flex gap-2'>
                                                <div className='bg-[#2BB574] h-5 w-5'></div>
                                                <div>Actual Value</div>
                                            </div>
                                            <div className='flex gap-2'>
                                                <div className='bg-[#D1D5DB] h-5 w-5'></div>
                                                <div>Target Value</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                ) : null}
            </div>
        </div>
    );
}

export default LifeStyleGoals;
