import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import houseHoldIcon from "../../../assets/houseHold.svg";
import businessIcon from '../../../assets/ic_baseline-business.svg';
import whiteBuisnessIcon from "../../../assets/white-buisness.svg";
import blackHouseHoldIcon from "../../../assets/black-household.svg";
import Spinner from '../../common/Spinner';

const NewClientPopup = ({ title, toggle, change, campaignData }) => {
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');
    const navigate = useNavigate();

    const handleNext = () => {
        navigate('/clients/new', { state: { clientType: type } });
    };

    return (
        <div>
            {loading && <Spinner />}
            <div
                className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20"
                style={{ margin: 0 }}
            >
                <div
                    className="bg-white p-8 rounded shadow-md relative"
                    style={{ width: '550px' }}
                >
                    <div className="flex items-center justify-between mb-5">
                        <h2 className="text-black font-inter text-2xl font-medium">
                            Create a New Client Account
                        </h2>
                        <button
                            onClick={toggle}
                            className="text-gray-600 hover:text-blue-500 focus:outline-none"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <p className="text-neutral-400 font-inter text-sm">
                        Please select the type of account: Household Account for personalized family services, or Business Account for tailored commercial solutions.
                    </p>
                    <div className="flex space-x-4 mt-8">
                        <button
                            className={`flex-1 p-4 border rounded-lg w-[267px] ${type === 'household' ? 'bg-[#4871B7] text-white' : 'bg-[#E5E7EB]'}`}
                            onClick={() => setType('household')}
                        >
                            <div className="flex  space-x-2">
                                <span className="material-icons"> <img className='h-9 w-9' src={type === "household" ? houseHoldIcon : blackHouseHoldIcon} /> </span>
                                <span className={`mt-4 text-lg ${type === 'household' ? 'text-white' : 'text-[#4B5563]'}`}>Household</span>
                            </div>
                        </button>
                        <div className="flex items-center justify-center">
                            <span className="text-[#1F2937]">OR</span>
                        </div>
                        <button
                            className={`flex-1 p-4 border rounded-lg ${type === 'business' ? 'bg-[#4871B7] text-white' : 'bg-[#E5E7EB]'}`}
                            onClick={() => setType('business')}
                        >
                            <div className="flex  space-x-2">
                                <span className="material-icons"> <img className='h-10 w-9' src={ type==="business"? whiteBuisnessIcon :businessIcon} /> </span>
                                <span className={`mt-3 text-lg ${type === 'business' ? 'text-white' : 'text-[#4B5563]'}`}>Business</span>
                            </div>
                        </button>
                    </div>
                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={handleNext}
                            disabled={!type}
                            className={`px-6 py-2 rounded text-white ${type ? 'bg-[#4871B7] hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'}`}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewClientPopup;
