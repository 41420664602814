import React, { useState } from "react";
import useApi from "../../../../custom-hooks/useApi";
import { toast } from "react-toastify";
import Spinner from "../../../common/Spinner";
import DateTimePicker from "./DateAndTime";
import { ReactComponent as DownloadFileLogo } from "../../../../assets/download.svg"; 
import FileUpload from "./FileUpload";
import axios from "axios";
import { useSelector } from "react-redux"
import { CAMPAIGN_TEMPLATE_FILE_PATH } from "../../../../appconfig";
import { ReactComponent as ArrowIcon } from "../../../../assets/chevron-down.svg";


const NewCampaignPopup = ({
  campaignGroupId,
  campClick,
  change,
  newAdded,
  toggle,
}) => {
  const [formData, setFormData] = useState({
    Campaign_GroupID: campaignGroupId
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const user = useSelector((state) => state.auth.user);
  const [message, setMessage] = useState("");
  const [dateAndTime, setDateAndTime] = useState('');
  const [isOpen, setIsOpen] = useState(false);


  const setFileState = (selectedFile) => {
    setFile(selectedFile);
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  }

  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
      setMessage(newMessage);
  };

  const handleDateAndTime = (date) => {
    setDateAndTime(date);
  }
     const toggleDropdown = () => {
       setIsOpen(!isOpen);
     };


  const diffInDays = (new Date(dateAndTime) );

  const getUploadUrl = async () => {
    try {
      const response = await api.get(`/Campaign/GetSaasToken`);
      if(response.status === 200 && response.data.url) {
        return response.data.url
      }
      return "";
    } catch (error) {
      return "";
    }
  }

  const getTemplateUrl = async () => {
    try {
      const response = await api.get(
        `/Campaign/TemplateUrl?fileName=${CAMPAIGN_TEMPLATE_FILE_PATH}`
      );
      if (response.status === 200 && response.data.url) {
        return response.data.url;
      }
      return "";
    } catch (error) {
      console.error("Failed to fetch template url: ", error.message);
      return "";
    }
  };

  const getRowCount = async(file) => {
    const contents = await file.text();
    const rows = contents.split("\n");
    const rowCount = rows.length - 1;
    return rowCount;
  };

  const handleUpload = async (campaignID) => {

    const url = await getUploadUrl();

    if(!url || url==="") {
      return "Couldn't get upload url.";
    }

    try {

      const rowCount = await getRowCount(file);

      const requestBody = {
        Campaign_StatusID: 61,
      };

      if (rowCount > 2000) {
        const response = await api.put(
          `/Campaign/${campaignID}/UpdateStatus`,
          requestBody
        );

        if (
          response.status === 200 &&
          response.data.success &&
          response.data.campaign
        ) {
          console.warn("Failed campaign. Rejected upload file. More than 2000 records.")
        }
        throw new Error("Cannot upload more than 2000 records.");
      }

      const fileData = new Blob([file], { type: file.type });

      const splittedUrlArray = url && url !== "" ? url.split("?") : [];

      let generatedUrl = "";

      let fileName = `${campaignID}-${file.name}`;

      if (splittedUrlArray.length > 1) {
        generatedUrl = splittedUrlArray[0];
        generatedUrl += `/${fileName}?${splittedUrlArray[1]}`;
      } else {
        generatedUrl = url;
      }

      const contentType = fileData.type || "application/octet-stream";

      const response = await axios.put(generatedUrl, fileData, {
        headers: {
          withCredentials: true,
          "Content-Type": contentType,
          "x-ms-blob-type": "BlockBlob", // header for uploading blob
        },
      });
      if(response.status === 200 || response.status === 201) {
        const fileUpdateResponse = await api.put(
          `/Campaign/${campaignID}/update-file`,
          {
            File_Path: `${fileName}`,
          }
        );
        if(fileUpdateResponse.status === 200 && fileUpdateResponse.data.success) {
          return "File uploaded successfully.";
        } else {
          return `File uploaded but was not updated in db, filePath: /${user.BaseShopID}/campaigns/${fileName}`;
        }
      } else {
        return "File upload failed, Unknown reason.";
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return "Upload failed, reason - " + error.message;
    }
  };

  const handleDownloadTemplate = async () => {
    const url = await getTemplateUrl();

    if (!url || url === "") {
      return "Couldn't get upload url.";
    }

    try {
      const a = document.createElement("a");
      a.href = url;
      document.body.appendChild(a);
      a.click();
    } catch(error) {
      console.log('Failed downloading file, reason: ', error.message);
    }

  };

  const maxLength = 160;
  const remainingCharacters = maxLength - (message.length || 0);
  const isOverLimit = remainingCharacters < 0;

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const json = {...formData,
      Schedule_Date: dateAndTime,
      Campaign_Message: message,
    };
    
    try {
      setLoading(true);
        const response = await api.post(`/Campaign`, json);
        if (response.status === 200 && response.data.success) {

          if (file && response.data.ID) {
            const fileUploaded = await handleUpload(response.data.ID);
            console.log(fileUploaded);
          } else {
            console.log("No file selected for upload");
          }

          toast.success("Campaign created successfully", {
            theme: "dark",
          });
          newAdded(response?.data?.ID);
          campClick(response?.data?.campaign);
          change();
        } else {
          const errorMessage = response.data?.error || "Failed to create campaign";
          toast.error(errorMessage, {
            theme: "dark",
          });
        }
    } catch (error) {
      toast.error(error.response?.data?.message, {
        theme: "dark",
      });
      console.error("Error updating data:", error);
    } finally {
      setLoading(false);
      toggle();
    } 
  };

const putVariableInMessage = async (e) => {
  const variable = e;
  const textbox = document.getElementById("messageTextarea");

  // Get the cursor position
  const cursorPosition = textbox.selectionStart;

  // Get the value before and after the cursor
  const valueBeforeCursor = message.substring(0, cursorPosition);
  const valueAfterCursor = message.substring(cursorPosition);

  // Update the message value with the selected variable inserted at the cursor position
  const newMessage = valueBeforeCursor + variable + valueAfterCursor;
  setMessage(newMessage);

  // Set the cursor position after the inserted variable
  textbox.setSelectionRange(
    cursorPosition + variable.length,
    cursorPosition + variable.length
  );
  setIsOpen(false);
};


  return (
    <div>
      {loading && <Spinner />}
      <div
        className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-20 w-50 h-50"
        style={{ margin: 0 }}
      >
        <div
          className="bg-white p-8 rounded shadow-md relative"
          style={{ width: "500px" }}
        >
          <div className="flex  items-center justify-between mb-1">
            <h2 className="text-black font-inter text-2xl font-medium">
              Create Campaign
            </h2>

            <button
              onClick={toggle}
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>

          <h2 className="text-black font-inter text-lg font-medium px-[3px]">
            Recipient List
          </h2>

          <div className="mt-1 flex justify-between font-inter">
            <div className="p-1 rounded font-inter border border-transparent bg-white text-blue-500 hover:border-blue-500">
              <FileUpload setFileState={setFileState} />
              {/* <span className="flex gap-2 text-14 text-secondary font-inter font-medium text-14 leading-18">
                <UploadFileLogo />
                Import CSV
              </span> */}
            </div>
            <button
              className="p-1 rounded font-inter border border-transparent bg-white text-blue-500 hover:border-blue-500"
              onClick={handleDownloadTemplate}
            >
              <span className="flex gap-2 text-14 text-secondary font-inter font-medium text-14 leading-18">
                <DownloadFileLogo />
                Download CSV Template
              </span>
            </button>
          </div>
          {file && <div className="px-4 flex-1 text-blue-500">{file.name}</div>}
          <div className="mt-2 flex justify-center">
            <form className="w-full max-w-lg" onSubmit={handleSubmit}>
              <input
                type="hidden"
                value={campaignGroupId}
                name="Campaign_GroupID"
              />
              <div className="flex flex-wrap -mx-3">
                <div className="mt-1 w-full px-3 font-inter ">
                  <label
                    className="block tracking-wide text-black text-xs font-bold mb-2 "
                    htmlFor="Campaign_Name"
                  >
                    Campaign Name
                    <span className="text-red-500"> *</span>
                  </label>
                  <input
                    name="Campaign_Name"
                    placeholder="Campaign Name"
                    required
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-2 w-full px-3 font-inter">
                  <div className="flex flex-row justify-between mt-2 mb-2">
                    <label
                      className="block tracking-wide text-black text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      Message
                      <span className="text-red-500"> *</span>
                    </label>
                    <div className="relative w-40">
                      <button
                        type="button"
                        className="block w-full bg-gray-100 text-neutral-700  border-gray-200 text-sm border py-2.5 leading-tight focus:outline-none focus:bg-white mb-2"
                        onClick={toggleDropdown}
                      >
                        <span className="inline-flex w-full justify-between px-2">
                          Choose Variable&nbsp;
                          <ArrowIcon />
                        </span>
                      </button>

                      {isOpen && (
                        <ul
                          id="variableDropdown"
                          className="w-full absolute text-gray-400 bg-[#f3f4f6] border border-gray-200  text-sm z-10 "
                        >
                          <li
                            className="px-4 py-2 cursor-pointer hover:text-gray-800 hover:bg-white"
                            onClick={() => putVariableInMessage("[First_Name]")}
                          >
                            First Name
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer hover:text-gray-800 hover:bg-white"
                            onClick={() => putVariableInMessage("[Last_Name]")}
                          >
                            Last Name
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer hover:text-gray-800 hover:bg-white"
                            onClick={() => putVariableInMessage("[Company]")}
                          >
                            Company
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer hover:text-gray-800 hover:bg-white"
                            onClick={() =>
                              putVariableInMessage("[Next Review Date]")
                            }
                          >
                            Next Review Date
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer hover:text-gray-800 hover:bg-white"
                            onClick={() => putVariableInMessage("[Birthday]")}
                          >
                            Birthday
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>

                  <textarea
                    id="messageTextarea"
                    rows={4}
                    required
                    placeholder="Message"
                    value={message}
                    onChange={handleMessageChange}
                    className="appearance-none block w-full h-[8.40rem] bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                  ></textarea>
                  <div className="text-sm text-gray-500 mt-2">
                    {isOverLimit ? (
                      <>
                        <span className="text-red-500">{message.length}</span>
                        /160{" "}
                      </>
                    ) : (
                      <span>{message.length}/160</span>
                    )}
                  </div>
                </div>

                <div className="mt-2 mb-4 w-full px-3 font-inter ">
                  <DateTimePicker handleTime={handleDateAndTime} />
                </div>
                {/* checkbox */}
                <div className="mb-1 w-full px-3 font-inter">
                  {/* Row 1 */}
                  <div className="text-gray-500 md:w-1/2"></div>
                  <div className="flex mb-2">
                    <input
                      type="checkbox"
                      required
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <p className="text-gray-400 font-inter text-sm font-normal leading-5 mb-1 pl-2">
                      I have permission to text each contact listed above.
                    </p>
                  </div>
                  {/* Row 2 */}
                  <div className="text-gray-500 mb-2 md:w-1/2"></div>
                  <div className="flex mb-2">
                    <input
                      type="checkbox"
                      required
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mb-6"
                    />
                    <p className="text-gray-400 font-inter text-sm font-normal leading-5 mb-2 pl-2">
                      I understand that each contact will receive a “Text STOP
                      to opt out” message the first time they received a text.
                      This opt notice is 22 characters long and may result in
                      additional messages.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-1 flex justify-between font-inter">
                <button
                  className="text-green-500 p-2 rounded font-inter"
                  type="cancel"
                  onClick={toggle}
                >
                  Cancel Message
                </button>

                {dateAndTime ? (
                  <button
                    style={{
                      background:
                        "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                      height: "3rem",
                      width: "7.5rem",
                    }}
                    className="text-white p-2 rounded font-inter"
                    type="submit"
                  >
                    Schedule
                  </button>
                ) : (
                  <button
                    style={{
                      background:
                        "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                      height: "3rem",
                      width: "7.5rem",
                    }}
                    className="text-white p-2 rounded font-inter"
                    type="submit"
                  >
                    Send
                  </button>
                )}

                {/* <button
                  style={{
                    background:
                      "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                    height: "3rem",
                    width: "7.5rem",
                  }}
                  className="text-white p-2 rounded font-inter"
                  type="submit"
                >
                  Save
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCampaignPopup;
