import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { setActionType, setAgentId, setCurrentStep, setFormData, setRefreshAgent } from "../../../redux/slices/agentSlice";
import Stage1 from "./form/Stage1";
import Stage2 from "./form/Stage2";
import Stage3 from "./form/Stage3";
import Stage4 from "./form/Stage4";
import Stage5 from "./form/Stage5";
import Stage6 from "./form/Stage6";
import Stage7 from "./form/Stage7";
import HomeLanding from "../../landingScreens/homeLanding";
import {ReactComponent as BackIcon} from "../../../assets/chevron-left.svg";
import {ReactComponent as MapPinIcon} from "../../../assets/map-pin.svg";
import {
  fetchAgentData,
  fetchAgentStepData,
  fetchExchangeLegData,
  fetchAllStepData,
  fetchAllAgentData,
  fetchAllPartnersMapping,
} from "../../../redux/thunks/agentThunk";
import { format, isValid, sub } from "date-fns";
import useApi from "../../../custom-hooks/useApi";
import "./agentDetailForm.css";
import StepBar from "./form/stepBar";
import { fetchCountriesData } from "../../../redux/thunks/commonThunk";
import Profile from "../backOffice/Tabs/Profile/Profile";
import Tabs from "../backOffice/Tabs/Tabs";
import BadgeComponent from "../backOffice/Tabs/Profile/BadgeComponent";
import SideNav from "../backOffice/SideNav";
import HamburgerMenu from "react-hamburger-menu";
import CampSummary from "../campaigns/CampSummary";

const AgentDetail = () =>{

  const dispatch = useDispatch();
  const { agentId } = useParams();
  const api = useApi();
  const navigate = useNavigate();

  const authToken = useSelector((state) => state.auth.authToken);
  const user = useSelector((state) => state.auth.user);
  const [activeTab, setActiveTab] = useState("Performance");
  const [showSideNav, setShowSideNav] = useState(true);
  const [profileChange, setProfileChange] = useState(false);
  const visitedPages = useSelector((state) => state.common.visitedPages)


  const tabList = [
    "Performance",
    "Badges",
    ];

    const checkTaproot = () => {
      setProfileChange(!profileChange)
    }
  
  useEffect(() => {
    dispatch(setAgentId(agentId));
    dispatch(fetchAgentData({agentId, api}));
    dispatch(fetchAllStepData({api}));
    dispatch(fetchAllAgentData(api));
    dispatch(fetchCountriesData({api}));
    dispatch(fetchAllPartnersMapping({api}));
    dispatch(fetchExchangeLegData({ agentId, api }));
    
  }, [dispatch, agentId, api]);

  const refreshAgentData = useSelector((state) => state.agent.refreshAgent);
  const agentData = useSelector((state) => state.agent.data);
  const allStepData = useSelector((state) => state.agent.allStepData);
  const [sortedSteps, setSortedSteps] = useState([]);

  const currentStep = useSelector((state) => state.agent.currentStep);
  const completedStep = useSelector((state) => state.agent.completedStep);
  const actionType = useSelector((state) => state.agent.actionType);
 

  useEffect(() => {
    if(refreshAgentData) {
      dispatch(fetchAgentData({ agentId, api }));
      dispatch(setActionType("refresh-step"));
      dispatch(setCurrentStep(currentStep));
      dispatch(setRefreshAgent(false));
    }
  
  }, [agentId, api, currentStep, dispatch, refreshAgentData]);
  

  useEffect(() => {
    if (allStepData && allStepData.length) {
      setSortedSteps([...allStepData].sort((a, b) => a.Step_Order - b.Step_Order));
    }
  }, [allStepData]);

    useEffect(() => {
      if (actionType === 'mark-complete') {
        if (
          agentData &&
          sortedSteps.length &&
          sortedSteps[sortedSteps.length - 1]["Step_ID"] !== completedStep
        ) {
          dispatch(setCurrentStep(parseInt(completedStep || 0) + 1));
        } else if (agentData) {
          dispatch(setCurrentStep(parseInt(completedStep || 0)));
        }
      }
    }, [actionType, agentData, completedStep, dispatch, sortedSteps]);
  
  useEffect(() => {
      if (currentStep && allStepData.length && agentData) {
        let selectedStep = allStepData.find((s) => s.Step_ID === currentStep);
        if (
          selectedStep !== undefined &&
          selectedStep !== null 
         
        ) {
          dispatch(fetchAgentStepData({ currentStep, agentId, api }));
        }
      }
  }, [actionType, agentData, agentId, allStepData, api, currentStep, dispatch])
  
  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === "checkbox") {
      dispatch(setFormData({ [name]: checked }));
    } else {
      dispatch(setFormData({ [name]: value }));
    }
  };

  const navigateStep = (stepId) => {
    dispatch(setActionType("navigate-step"));
    dispatch(setCurrentStep(stepId));
  }
  
  const toggleSidebar = () => {
    setShowSideNav(!showSideNav);
  }

  const StepComponent = () => {

    let stageComponent;

    if(!agentData || !user) {
      return null;
    }

    switch (currentStep) {
      case 1:
        stageComponent = (
          <Stage1 handleChange={handleChange} tapRootChange={checkTaproot} />
        );
        break;
      case 2:
        stageComponent = (
          <Stage2 handleChange={handleChange} />
        );
        break;
      case 3:
        stageComponent = (
          <Stage3 handleChange={handleChange} />
        );
        break;
      case 4:
        stageComponent = (
          <Stage4 handleChange={handleChange} />
        );
        break;
      case 5:
        stageComponent = (
          <Stage5 handleChange={handleChange} />
        );
        break;
      case 6:
        stageComponent = (
          <Stage6 handleChange={handleChange} />
        );
        break;
      case 7:
        stageComponent = (
          <Stage7 handleChange={handleChange} />
        );
        break;
      default:
        stageComponent = null;
        break;
    }

    return stageComponent;

  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "Performance":
        return  <CampSummary/>;
      
      // case "Profile":
      //   if (agentData && agentData.AgentID) {
      //     return <Profile agentRootId={agentData.AgentID} className={`mt-3`} />;
      //   } else {
      //     return <div></div>;
      //   }
      case "Badges":
        return <div></div>;
      default:
        return null;
    }
  };

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
  };

  if (!authToken) {
    navigate('/');
    return null;
  }
  return (
    <>
      {/* {loading && <Spinner />} */}
      {!visitedPages.includes(window.location.pathname) ? (
        <HomeLanding />
      ) : (
        <div className="flex h-full">
          <div className="flex-none">
            <SideNav
              isSidebarOpen={showSideNav}
              isProfileChanged={profileChange}
              toggleSidebar={toggleSidebar}
            />
          </div>
          <div className="flex flex-grow relative flex-col bg-gray-100">
            <div className="flex items-center font-inter mt-4 text-green-500 px-6 py-4">
              <div className="inline-flex flex-row cursor-pointer items-center">
                {/* <HamburgerMenu
                isOpen={showSideNav}
                menuClicked={toggleSidebar}
                width={22}
                height={15}
                strokeWidth={2}
                rotate={0}
                color="#4971B8"
                borderRadius={0}
                className="cursor-pointer"
                animationDuration={0.5}
              /> */}
                <span
                  className="inline-flex items-center ml-2"
                  onClick={() => navigate(`/dashboard`)}
                >
                  <BackIcon className="w-4 h-4 text-green-500 dark:text-green" />
                  Back
                </span>
              </div>
            </div>
            {agentData && (
              <div className="flex flex-col items-start font-inter text-2xl font-semibold px-6 py-4 text-black mb-5">
                <p className="flex items-center text-gray-700 text-sm font-normal address-color mb-1">
                  <MapPinIcon className="w-4 h-4 mr-2" />
                  {agentData.HomeCity || "-"}, {agentData.CountryName || "-"}
                </p>
                <h4 className="text-3xl font-semibold">
                  {agentData.NameTitle || "-"}
                </h4>
              </div>
            )}

            {/* body starts from  here  */}
            <div className="grid grid-cols-1 gap-4 px-6 py-1 font-inter">
              {agentData && (
                <div className="bg-white border border-gray-300 rounded p-4 font-inter">
                  <div
                    className="grid grid-cols-6 p-2 gap-4 font-inter text-base"
                    style={{
                      gridTemplateColumns:
                        "repeat(3, minmax(0, 1fr)) minmax(0, 1fr) repeat(2, minmax(0, 1fr))",
                    }}
                  >
                    <div className="flex flex-col">
                      <span className="mb-1 font-semibold">Level</span>
                      <span className="text-gray-500 truncate">
                        {agentData.level_description || "-"}
                      </span>
                    </div>

                    {/* Column 2 */}
                    <div className="flex flex-col">
                      <span className="mb-1 font-semibold">Agent ID</span>
                      <span className="text-gray-500">
                        {agentData.AgentID || "-"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="mb-1 font-semibold">Phone</span>
                      <span className="text-gray-500">
                        {agentData.Mobile || "-"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="mb-1 font-semibold">
                        Cash flow Milestone
                      </span>
                      <span className="text-green-500 break-all truncate">
                        {agentData.Cashflow_Milestone || "-"}
                      </span>
                    </div>

                    {/* Column 2 */}
                    <div className="flex flex-col">
                      <span className="mb-1 font-semibold">
                        Licensed Agents
                      </span>
                      <span className="text-gray-500">
                        {agentData.Licensed_Agents || "-"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="mb-1 font-semibold">Upline Agent</span>
                      <span className="text-green-500">
                        {agentData.UplineName || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {currentStep !== null && (
                <StepBar
                  key={`stepbar`}
                  completedStep={parseInt(completedStep || 0)}
                  allSteps={allStepData}
                  currentStep={currentStep}
                  navigateStep={navigateStep}
                />
              )}
              {/* forms */}
              {currentStep !== null && <StepComponent />}
            </div>
            <BadgeComponent />
            {agentData && (
              <Tabs
                renderTabContent={renderTabContent}
                tabList={tabList}
                activeTab={activeTab}
                changeActiveTab={changeActiveTab}
                className={`flex flex-col font-inter bg-white border border-gray-300 rounded-lg px-6 py-4 mt-4 mx-6`}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default AgentDetail;