import React, { useEffect, useState } from 'react';
import { formatNumber } from '../../../utills';
import { FaAngleDown } from 'react-icons/fa6';
import { CiCircleInfo } from "react-icons/ci";
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { BASE_URL } from '../../../appconfig';
import useApi from '../../../custom-hooks/useApi';
import { toast } from 'react-toastify';
import Spinner from '../../common/Spinner';


const ProperProtection = ({ onSave, AccountID, account }) => {
    const api = useApi();

    const [formData, setFormData] = useState({
        Debt: 50000,
        AnnualIncome: 60000,
        MortageBalanace: 200000,
        EducationExpenses: 150000,
        DependetChildren: 3,
        CurrentLifeInsurance: 250000,
        SafeMoneySaving: 100000
    });
    const [showAdvanceDetails, setShowAdvanceDetails] = useState(true)
    const [loading, setLoading] = useState(false);


    const [percentage, setPercentage] = useState(0);

    const [TotalInsurableNeed, setTotalInsurableNeed] = useState(0);


    useEffect(() => {
        generateChartData();
        calculateProtection();
        // setChartData(generateChartData());
    }, [formData])

    // useEffect(() => {
    //     const defaultMonthlyIncomeNeeded = (formData.AnnualIncome * 0.7) / 12;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         MonthlyIncomeNeeded: defaultMonthlyIncomeNeeded,
    //     }));
    // }, [formData.AnnualIncome]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: parseFloat(value) || value,
        });
    };

    const calculateProtection = () => {
        const {
            Debt,
            AnnualIncome,
            MortageBalanace,
            EducationExpenses,
        } = formData;

        const TotalInsurableNeed = Debt + (AnnualIncome * 10) + MortageBalanace + EducationExpenses;

        setTotalInsurableNeed(TotalInsurableNeed)

        if (TotalInsurableNeed > 0) {
            setPercentage(((TotalInsurableNeed - (formData?.MortageBalanace + formData?.Debt)) / TotalInsurableNeed) * 100)
        }
        else {
            setPercentage(0)
        }

    };


    const toggleAdvanceDetails = () => {
        setShowAdvanceDetails(!showAdvanceDetails)
    }

    const generateChartData = () => {

    };

    const validate = () => {


    }

    const handleSubmit = async () => {
        try {
            // if (!validate()) return;

            setLoading(true)
            const ProperProtectionInfo = {
                ...formData,
                TotalInsurableNeed: TotalInsurableNeed,
            }
            const response = await api.post(`${BASE_URL}/Account/Protection/${AccountID}`, { ProperProtectionInfo });
            if (response.status === 200) {
                onSave()
                toast.success("Protection Information updated", {
                    theme: "dark",
                });
            } else {
                toast.error("Failed to update client Protection Information", {
                    theme: "dark",
                });
            }
            setLoading(false)

        } catch (error) {
            console.log({ error });
            toast.error("Internal server error")
        }
    }

    return (
        <div className="p-4 flex gap-4 bg-white shadow-md rounded-lg font-inter">
            {
                loading && <Spinner/>
            }
            <form className="space-y-4 w-1/4">
                <h2 className="text-xl font-bold mb-4">Enter Your Information</h2>
                <div className="flex flex-col">
                    <label htmlFor="Debt" className="font-semibold text-[#4B5563] text-base">Debt</label>
                    <input
                        type="number"
                        id="Debt"
                        name="Debt"
                        value={formData.Debt}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="AnnualIncome" className="font-semibold text-[#4B5563] text-base">Annual pre-tax income</label>
                    <input
                        type="number"
                        id="AnnualIncome"
                        name="AnnualIncome"
                        value={formData.AnnualIncome}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded"
                    />
                    <div className="text-[#9CA3AF] mt-2">
                        Assuming 10 years to survivors
                    </div>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="MortageBalanace" className="font-semibold text-[#4B5563] text-base">Mortgage balance</label>
                    <input
                        type="number"
                        id="MortageBalanace"
                        name="MortageBalanace"
                        value={formData.MortageBalanace}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded"
                    />

                </div>
                <div className="flex flex-col">
                    <label htmlFor="EducationExpenses" className="font-semibold text-[#4B5563] text-base">Education expenses</label>
                    <input
                        type="number"
                        id="EducationExpenses"
                        name="EducationExpenses"
                        value={formData.EducationExpenses}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded"
                    />

                </div>

                <div

                    className='flex gap-2'
                >
                    <div
                        onClick={toggleAdvanceDetails}
                        className='flex gap-2 text-[#1F293] text-xl font-semibold cursor-pointer'
                    >
                        <FaAngleDown className='mt-1' />
                        <div>Advanced Details</div>

                    </div>
                    <div className="text-xl font-semibold mt-1 ">
                        <CiCircleInfo />

                    </div>

                </div>
                {
                    showAdvanceDetails ? (
                        <div>
                            <div className="flex flex-col">
                                <label htmlFor="DependetChildren" className="font-semibold">Dependet Children</label>
                                <input
                                    type="number"
                                    id="DependetChildren"
                                    name="DependetChildren"
                                    value={formData.DependetChildren}
                                    onChange={handleChange}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="CurrentLifeInsurance" className="font-semibold">Current Life Insurance</label>
                                <input
                                    type="number"
                                    id="CurrentLifeInsurance"
                                    name="CurrentLifeInsurance"
                                    value={formData.CurrentLifeInsurance}
                                    onChange={handleChange}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="SafeMoneySaving" className="font-semibold">Safe money savings</label>
                                <input
                                    type="number"
                                    id="SafeMoneySaving"
                                    name="SafeMoneySaving"
                                    value={formData.SafeMoneySaving}
                                    onChange={(e) => handleChange({ target: { name: 'PreRetirementReturn', value: e.target.value / 100 } })}
                                    className="mt-1 p-2 border border-gray-300 rounded"
                                />
                            </div>
                        </div>
                    ) : null
                }

                <div className='flex gap-2'>
                    <button
                        onClick={handleSubmit}
                        style={{
                            background:
                                "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                            height: "2.6rem",
                            flex: 1, // Take equal space
                        }}
                        className="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline border-none"
                        type="button"
                    >
                        Save
                    </button>
                </div>

            </form>

            {/* RESULT AND GRAPH */}
            <div className="mt-16 w-3/4 bg-[#E5E7EB]">
                <div className="p-4  rounded-lg">
                    <div className="text-3xl font-semibold text-[#1F2937]">Insurable need estimate</div>
                    <div className="flex space-x-40 mt-2">
                        <div>
                            <p className="text-[#9CA3AF] text-sm font-semibold">
                                What you have protected
                            </p>
                            <p className="mt-2 text-[#6B7280] text-xl font-semibold">${formatNumber(formData?.MortageBalanace + formData?.Debt)}</p>
                        </div>

                        <div>
                            <p className="text-[#9CA3AF] text-sm font-semibold">What you need to protect</p>
                            <p className="mt-2 text-[#6B7280] text-xl font-semibold">${formatNumber(TotalInsurableNeed)}</p>
                        </div>
                    </div>
                    <div className='text-[#4871B7] font-semibold text-xl mt-10'>
                        How did we calculate your results?
                    </div>
                </div>

                <div className=" rounded-lg mt-6 p-10 w-full" >
                    <div style={{ width: '300px', height: '300px' }}>
                        <CircularProgressbarWithChildren
                            value={percentage}
                            styles={buildStyles({
                                pathColor: `#2BB574`,
                                textColor: '#000000',  // Black text color
                                trailColor: '#9CA3AF',  // Gradient trail color
                            })}
                        >
                            <div style={{ fontSize: '20px', color: '#000000' }}>  {/* Black color for percentage */}
                                {`${Math.round(percentage)}%`}
                            </div>
                            <div style={{ fontSize: '14px', marginTop: '5px', color: '#000000' }}>  {/* Black color for "Protected" */}
                                Protected
                            </div>
                        </CircularProgressbarWithChildren>
                        {/* <svg style={{ height: 0 }}>
                            <defs>
                                <linearGradient id="gradient" gradientTransform="rotate(90)">
                                    <stop offset="0%" stopColor="#4871B7" />
                                    <stop offset="100%" stopColor="#2BB574" />
                                </linearGradient>
                            </defs>
                        </svg> */}
                    </div>

                </div>


            </div>
        </div>
    );
};

export default ProperProtection;
