import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useApi from '../../../custom-hooks/useApi';
import { BASE_URL } from '../../../appconfig';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Spinner from '../../common/Spinner';

function LifeStyleGoalForm({ AccountID, reloadGoals, goalToBeEdit, cancel }) {
    const user = useSelector((state) => state.auth);
    const api = useApi();

    const [formValues, setFormValues] = useState({
        GoalName: '',
        GoalTypeID: '',
        TargetValue: 0,
        ActualValue: 0,
        TargetDate: '',
        PercentOfGoal: 0,
    });

    const [errors, setErrors] = useState({});
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [goalsTypeList, setGoalsTypeList] = useState([]);

    useEffect(() => {
        if (formValues.TargetValue > 0) {
            setPercentage((formValues.ActualValue / formValues.TargetValue) * 100);
            setFormValues(prevValues => ({ ...prevValues, PercentOfGoal: (formValues.ActualValue / formValues.TargetValue) * 100 }));
        } else {
            setPercentage(0);
            setFormValues(prevValues => ({ ...prevValues, PercentOfGoal: 0 }));
        }
    }, [formValues.TargetValue, formValues.ActualValue]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const validate = () => {
        let tempErrors = {};
        if (!formValues.GoalName) tempErrors.GoalName = 'Goal Name is required';
        if (!formValues.GoalTypeID) tempErrors.GoalTypeID = 'Goal Type is required';
        if (!formValues.TargetValue && formValues.TargetValue !== 0) tempErrors.TargetValue = 'Target Value is required';
        if (formValues.TargetValue < 0) tempErrors.TargetValue = 'Target value cannot be negative';
        if (formValues.ActualValue < 0) tempErrors.ActualValue = 'Actual value cannot be negative';
        if (!formValues.TargetDate) tempErrors.TargetDate = 'Target Date is required';
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        try {
            setLoading(true);
            const convertedData = {
                ...formValues,
                GoalTypeID: Number(formValues.GoalTypeID),
                AccountID,
                createdBy: user?.user?.id,
                modifiedBy: user?.user?.id,
            };

            let response;
            if (goalToBeEdit) {
                response = await api.put(`${BASE_URL}/Account/Goal/${goalToBeEdit?.GoalID}`, convertedData);
            } else {
                response = await api.post(`${BASE_URL}/Account/Goal`, convertedData);
            }

            if (response?.status === 200) {
                toast.success(goalToBeEdit ? "Goals Updated Successfully" : response?.message);
                reloadGoals();
                cancel()
                setFormValues({
                    GoalName: '',
                    GoalTypeID: '',
                    TargetValue: 0,
                    ActualValue: 0,
                    TargetDate: '',
                    PercentOfGoal: 0,
                });
            } else {
                toast.error("Failed to add/update goal");
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Failed to add/update goal");
        }
    };

    useEffect(() => {
        loadGoalsType();
    }, []);

    useEffect(() => {
        if (goalToBeEdit) {
            setFormValues({
                GoalName: goalToBeEdit?.GoalName,
                GoalTypeID: goalToBeEdit?.GoalTypeID,
                TargetValue: goalToBeEdit?.TargetValue,
                ActualValue: goalToBeEdit?.ActualValue,
                TargetDate: goalToBeEdit?.TargetDate,
                PercentOfGoal: goalToBeEdit?.PercentOfGoal,
            });
            setPercentage(goalToBeEdit?.PercentOfGoal);
        }
        else {
            setFormValues({
                GoalName: '',
                GoalTypeID: '',
                TargetValue: 0,
                ActualValue: 0,
                TargetDate: '',
                PercentOfGoal: 0,
            });
            setPercentage(0);

        }
    }, [goalToBeEdit]);

    const loadGoalsType = async () => {
        try {
            setLoading(true);
            const response = await api.get(`${BASE_URL}/Organization/GoalTypes`);
            setGoalsTypeList(response?.data?.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleCancelClick = async () => {
        cancel()
    }

    return (
        <form className='bg-[#F3F4F6] mt-4 p-4' onSubmit={handleSubmit}>
            {loading && <Spinner />}
            <div className='flex justify-between'>
                <div className='flex space-x-4'>
                    <div>
                        <input
                            className='bg-white p-2 w-[299px] border-none focus:outline-none focus:border-none'
                            placeholder='Goal Name'
                            name="GoalName"
                            value={formValues.GoalName}
                            onChange={handleChange}
                        />
                        {errors.GoalName && <p className='font-base text-red-600'>{errors.GoalName}</p>}
                    </div>

                    <div>
                        <select
                            className='bg-white p-2 w-[299px] border-none focus:outline-none focus:border-none'
                            name="GoalTypeID"
                            value={formValues.GoalTypeID}
                            onChange={handleChange}
                        >
                            <option value="">Select Goal Type</option>
                            {goalsTypeList?.length > 0 && goalsTypeList?.map((goalTypeItem) => (
                                <option key={goalTypeItem?.GoalTypeID} value={goalTypeItem?.GoalTypeID}>{goalTypeItem?.GoalTypeName}</option>
                            ))}
                        </select>
                        {errors.GoalTypeID && <p className='font-base text-red-600'>{errors.GoalTypeID}</p>}
                    </div>
                </div>
                <div>
                    <button
                        style={{ height: "2.7rem" }}
                        className="w-[101px] bg-[#4871B7] text-white text-sm font-semibold hover:text-white py-2 px-4 mr-4 border hover:border-transparent rounded font-inter"
                    >
                        {goalToBeEdit ? "Edit" : "Create"}
                    </button>
                    <button
                        type='button'
                        onClick={handleCancelClick}
                        style={{ height: "2.7rem" }}
                        className="w-[101px] bg-[#4871B7] text-white text-sm font-semibold hover:text-white py-2 px-4 mr-4 border hover:border-transparent rounded font-inter"
                    >
                        Cancel
                    </button>
                </div>
            </div>


            <div className='flex space-x-20 mt-8'>
                <div>
                    <div className='text-[#9CA3AF] text-sm mb-2 font-medium'>Target Value</div>
                    <input
                        className='bg-white p-2 border-none focus:outline-none focus:border-none'
                        placeholder='Target Value'
                        name="TargetValue"
                        type="number"
                        step="0.01"
                        value={formValues.TargetValue}
                        onChange={handleChange}
                    />
                    {errors.TargetValue && <p className='font-base text-red-600'>{errors.TargetValue}</p>}
                </div>

                <div>
                    <div className='text-[#9CA3AF] text-sm mb-2 font-medium'>Actual Value</div>
                    <input
                        className='bg-white p-2 border-none focus:outline-none focus:border-none'
                        placeholder='Actual Value'
                        name="ActualValue"
                        type="number"
                        step="0.01"
                        value={formValues.ActualValue}
                        onChange={handleChange}
                    />
                    {errors.ActualValue && <p className='font-base text-red-600'>{errors.ActualValue}</p>}
                </div>

                <div>
                    <div className='text-[#9CA3AF] text-sm mb-2 font-medium'>Target Date</div>
                    <input
                        className='bg-white p-2 border-none focus:outline-none focus:border-none'
                        name="TargetDate"
                        type="date"
                        value={formValues.TargetDate}
                        onChange={handleChange}
                        min={new Date().toISOString().split('T')[0]}
                    />
                    {errors.TargetDate && <p className='font-base text-red-600'>{errors.TargetDate}</p>}
                </div>
                <div className='flex space-x-4'>
                    <div className='mt-4' style={{ width: '60px', height: '60px', marginTop: "-20px", marginLeft: "60px" }}>
                        <CircularProgressbar
                            value={percentage}
                            text={`${Math.round(percentage)}%`}
                            styles={buildStyles({
                                pathColor: `#2BB574`,
                                textColor: '#2BB574',
                                trailColor: '#d6d6d6',
                            })}
                        />
                    </div>

                    <div style={{ marginTop: "-20px" }}>
                        <div className='flex gap-2'>
                            <div className='bg-[#2BB574] h-5 w-5' />
                            <div>Actual Value</div>
                        </div>
                        <div className='flex gap-2'>
                            <div className='bg-[#D1D5DB] h-5 w-5' />
                            <div>Target Value</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default LifeStyleGoalForm;
