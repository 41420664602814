import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../../../../../assets/edit.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../../assets/cancel.svg";
import { ReactComponent as MoreIcon } from "../../../../../assets/more-horizontal.svg";
import useApi from "../../../../../custom-hooks/useApi";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../../../common/Spinner";
import { toast } from "react-toastify";
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { IoMdCloseCircle } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import { BsArrowRightCircleFill } from "react-icons/bs";

const CampaignActivationRow = ({
  agentRootId,
  dataItem,
  index,
  fetchAgentCampaignList,
}) => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageCount, setMessageCount] = useState(null);
  const [isActionOpen, setIsActionOpen] = useState(false);
  const monthlyLimit = 1000;
  const api = useApi();
  const [isEditing, setIsEditing] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const role = useSelector((state) => state.auth.role);
  const adminRoles = useSelector((state) => state.auth.adminRoles);
  const rcStatusList = useSelector((state) => state.common.rcStatusList);
  const restrictedCountries = ["US", "CA"];

  const handleUpdateCampaign = () => {
    const form = document.querySelector("#campaign-update-form-" + index);

    if (form && form.checkValidity()) {
      const formId = "campaign-update-form-" + index;
      const inputName = "PhoneNo";
      const mobileInput = document.querySelector(
        `input[form="${formId}"][name="${inputName}"]`
      );

      if (!formData["PhoneNo"] || !isValidPhoneNumber(formData["PhoneNo"])) {
        mobileInput.setCustomValidity("Please enter a valid phone number.");

        mobileInput.reportValidity();
        return;
      } else {
        mobileInput.setCustomValidity("");
      }

      updateCampaignActivation();
    } else if (form) {
      form.querySelector('input[type="submit"]').click();
    }
  };

  const updateCampaignActivation = async (id) => {
    try {
      setLoading(true);
      let updatedPhoneNo = formData.PhoneNo;
      if (updatedPhoneNo.startsWith('+1')) {
        updatedPhoneNo = updatedPhoneNo.slice(2);
      }
    
      const updatedFormData = {
        ...formData,
        PhoneNo: updatedPhoneNo,
      };
    
      const response = await api.put(
        `/Campaign/OrgCampaign/${dataItem.ID}/${dataItem.AgentID}`,
        updatedFormData
      );
      if (response.status === 200) {
        fetchAgentCampaignList();
        setTimeout(() => {
          setIsEditing(false);
          toast.success(response.data.message, { theme: "dark" });
          setFormData(null);
          setLoading(false);
        }, 2000);
      } else {
        toast.error("Create Failed", { theme: "dark" });
        console.error("Create Failed: ", response);
        setLoading(false);
      }
    } catch (error) {
      console.error("Creation of entity failed: ", error);
      let errorMessage =
        error && error?.response && error.response?.data?.message
          ? error.response.data.message
          : "Creation of Entity Failed";
      toast.error(errorMessage, { theme: "dark" });
      setLoading(false);
    }
  };

  const deleteCampaign = async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(
        `/Campaign/OrgCampaign/${dataItem.ID}/${dataItem.AgentID}`
      );
      if (response.status === 200) {
        fetchAgentCampaignList();
        toast.success(response.data.message, { theme: "dark" });
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        toast.error("Delete Failed", { theme: "dark" });
        console.error("Delete Failed: ", response);
        setLoading(false);
      }
    } catch (error) {
      console.error("Deletion of entity failed: ", error);
      let errorMessage =
        error && error?.response && error.response?.data?.message
          ? error.response.data.message
          : "Deletion of Entity Failed";
      toast.error(errorMessage, { theme: "dark" });
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };
  const handleEditClick = () => {
    setFormData(dataItem);
    setIsEditing(true);
  };
  const handleMoreClick = () => {
    setIsActionOpen(!isActionOpen);
    setIsEditing(false);

  };
  const handleDeleteClick = () => {
    deleteCampaign();
  };
  const handleConvertClick = () => {
    handleConvertClick();
  }

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const calculateRatio = () => {
    if (messageCount !== null) {
      const currentMonthSent = dataItem.MessageCount;
      const ratio = currentMonthSent / monthlyLimit;
      return `${currentMonthSent} / ${monthlyLimit} - ${ratio.toFixed(2)}`;
    }
  };

  const handlePhoneInputChange = (value) => {
    if (value !== undefined && isValidPhoneNumber(value.toString())) {
    setFormData((prevData) => ({
      ...prevData,
      PhoneNo: value,
    }));
    const formId = "campaign-update-form-" + index;
    const inputName = "PhoneNo";
    const mobileInput = document.querySelector(
      `input[form="${formId}"][name="${inputName}"]`
    );
    mobileInput.setCustomValidity("");
  };
 }

  return (
    <>
      {loading && <Spinner />}
      <tr key={dataItem.AgentID}>
        <td className="w-1/9 whitespace-nowrap  py-5 font-inter ">
          {!isEditing && (
            <span className="py-2.5">
              {rcStatusList.length > 0 &&
              rcStatusList.find((s) => s.ID === dataItem.Status)
                ? //     ? rcStatusList.find((s) => s.ID === dataItem.Status)["Name"]
                  (() => {
                    const status = rcStatusList.find(
                      (s) => s.ID === dataItem.Status
                    );
                    switch (status.Name) {
                      case "Pending Approval":
                        return (
                          <BsArrowRightCircleFill className=" ml-6 text-gray-500 text-lg" />
                        );
                      case "Approved":
                        return (
                          <FaCheckCircle className=" ml-6 text-lime-600 text-lg" />
                        );
                      case "Rejected":
                        return (
                          <IoMdCloseCircle className=" ml-6 text-red-600 text-xl  " />
                        );
                      default:
                        return "-";
                    }
                  })()
                : "-"}
            </span>
          )}
          {isEditing && (
            <select
              className="appearance-none block w-[11rem] bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
              name="Status"
              form={`campaign-update-form-${index}`}
              value={formData.Status ? formData.Status : ""}
              required
              onChange={(e) => handleChange(e)}
            >
              <option
                value=""
                disabled
                className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                style={{ height: "1.5rem", width: "0.5rem" }}
              >
                Select Status
              </option>

              {rcStatusList.length > 0 &&
                rcStatusList.map((item) => (
                  <option key={"status" + item.ID} value={item.ID}>
                    {item.Name}
                  </option>
                ))}
            </select>
          )}
        </td>

        <td className="w-1/9 whitespace-nowrap py-2.5 font-inter ">
          <span className="py-2.5">{dataItem.Nametitle?.trim() || "-"}</span>
        </td>
        {/* <td
          className="w-1/9 whitespace-nowrap py-2.5 px-4 font-inter"
        >
          <span className="py-2.5">{dataItem.WorkEmail || "-"}</span>
        </td> */}
        <td className="w-1/9 whitespace-nowrap py-2.5  font-inter">
          <span className="py-2.5">{dataItem.AgentID || "-"}</span>
        </td>

        <td className="w-1/9 whitespace-nowrap py-2.5   font-inter">
          <span className="py-2.5">{calculateRatio() || "-"}</span>
        </td>

        <td className="w-1/9 whitespace-nowrap py-5 font-inter">
          {!isEditing && (
            <span className="py-2.5">{`${dataItem.CountryCode}${dataItem.PhoneNo}`}</span>
          )}
          {isEditing && (
            <PhoneInput
              addInternationalOption={false}
              required
              international
              countryCallingCodeEditable={false}
              defaultCountry="US"
              countries={restrictedCountries}
              form={`campaign-update-form-${index}`}
              value={formData.PhoneNo || ""}
              placeholder="Phone number"
              name="PhoneNo"
              onChange={handlePhoneInputChange}
              countryOptions={restrictedCountries.map((country) => ({
                value: country,
                label: country,
              }))}
              error={
                formData.PhoneNo
                  ? isValidPhoneNumber(formData.PhoneNo)
                    ? undefined
                    : "Invalid phone number"
                  : "Phone number required"
              }
            />
          )}
        </td>
        <td className="w-1/9 whitespace-nowrap px-5 py-5 font-inter">
          {isEditing ? (
            <div className="flex flex-row items-center gap-4">
              <SaveIcon
                title="Save"
                className="cursor-pointer"
                onClick={handleUpdateCampaign}
              />
              <CancelIcon
                title="Cancel"
                className="cursor-pointer"
                onClick={handleCancelClick}
              />
            </div>
          ) : (
            <div className="flex flex-row gap-4 cursor-pointer">
              <MoreIcon onClick={handleMoreClick} />
              {isActionOpen && adminRoles.includes(role) && (
                <>
                  <div className="absolute z-10 w-auto bg-white border rounded shadow-lg mt-5 mr-16 right-0">
                    <ul className="border rounded">
                      <li
                        className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                        onClick={() => handleEditClick()}
                      >
                        Edit
                      </li>
                      <li
                        className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                        onClick={() => handleDeleteClick()}
                      >
                        Delete
                      </li>
                      <li
                        className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                        onClick={() => handleConvertClick()}
                      >
                        Convert
                      </li>
                    </ul>
                  </div>
                </>
              )}
              {isActionOpen &&
                !adminRoles.includes(role) &&
                user.AgentID === agentRootId && (
                  <>
                    <div className="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg">
                      <ul className="border rounded">
                        <li
                          className="border rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                          onClick={() => handleEditClick()}
                        >
                          Edit
                        </li>
                        <li
                          className="border rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                          onClick={() => handleConvertClick()}
                        >
                          Convert
                        </li>
                      </ul>
                    </div>
                  </>
                )}
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default CampaignActivationRow;
