import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";

import { ReactComponent as SaveIcon } from "../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../src/assets/cancel.svg";

import useApi from "../../../custom-hooks/useApi";
import { BASE_URL } from "../../../appconfig";
import Spinner from "../../common/Spinner";

const FinancialAccountForm = ({
  AccountID,
  toggle,
  relationShipList,
  onSaveSuccess,
  productList,
  reloadItems,
}) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    AccountName: "",
    ProductTypeID: null,
    Contribution: null,
    Balance: null,
    ManagedBy: null,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevData) => ({ ...prevData, [name]: "" }));
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.AccountName)
      newErrors.AccountName = "Financial Account is required";
    if (!formData.ProductTypeID)
      newErrors.ProductTypeID = "Account Type is required";
    if (!formData.Balance) newErrors.Balance = "Balance is required";
    if (!formData.Contribution)
      newErrors.Contribution = "Contribution is required";
    if (!formData.ManagedBy) newErrors.ManagedBy = "ManagedBy is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }
    setLoading(true);
    await saveFinancialAccount();
    setLoading(false);
  };

  const saveFinancialAccount = async () => {
    try {
      const response = await api.post(
        `${BASE_URL}/Account/FinancialAccount/${AccountID}`,
        formData
      );
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setLoading(false);
        toggle();
        onSaveSuccess();
        // setTimeout(() => {

        // }, 1000);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <tr className="border=[#D1D5DB] border-b">
      {loading && <Spinner />}

      <td className="px-3 py-2">
        <input
          name="AccountName"
          placeholder="Financial Account"
          type="text"
          value={formData.AccountName}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-400 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        />
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.AccountName ? "visible" : "invisible"
          }`}
        >
          {errors.AccountName || " "}
        </p>
      </td>

      <td className="px-3 py-2">
        <select
          name="ProductTypeID"
          value={formData.ProductTypeID}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-400 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        >
          <option value="">Account Type</option>
          {productList?.map((productItem) => (
            <option
              key={productItem?.ProductTypeID}
              value={productItem?.ProductTypeID}
            >
              {productItem?.ProductTypeName}
            </option>
          ))}
        </select>
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.ProductTypeID ? "visible" : "invisible"
          }`}
        >
          {errors.ProductTypeID || " "}
        </p>
      </td>

      <td className="px-3 py-2">
        <input
          type="number"
          placeholder="Contribution"
          onChange={handleChange}
          name="Contribution"
          value={formData.Contribution}
          className="appearance-none block w-full bg-neutral-100 text-neutral-400 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        />
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.Contribution ? "visible" : "invisible"
          }`}
        >
          {errors.Contribution || " "}
        </p>
      </td>

      <td className="px-3 py-2">
        <input
          name="Balance"
          placeholder="Balance"
          type="number"
          value={formData.Balance}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-400 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        />
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.Balance ? "visible" : "invisible"
          }`}
        >
          {errors.Balance || " "}
        </p>
      </td>

      <td className="px-3 py-2">
        <select
          name="ManagedBy"
          value={formData.ManagedBy}
          onChange={handleChange}
          className="appearance-none block w-full bg-neutral-100 text-neutral-400 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
        >
           <option value="">Relationships</option>
          {relationShipList?.map((relationShipItem) => (
            <option
              key={relationShipItem?.index + 50}
              value={relationShipItem?.Relation}
            >
              {relationShipItem?.Relation}
            </option>
          ))}
        </select>
        <p
          className={`text-red-500 mt-1 text-xs h-4 ${
            errors.ManagedBy ? "visible" : "invisible"
          }`}
        >
          {errors.ManagedBy || " "}
        </p>
      </td>

      <td className="p-4 flex gap-2">
        <div className="flex gap-2">
          <button
            className="disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleSubmit}
          >
            <SaveIcon />
          </button>
          <CancelIcon className="cursor-pointer" onClick={toggle} />
        </div>
      </td>
    </tr>
  );
};

export default FinancialAccountForm;
