// import React from 'react';

// const CircularRings = ({ progressList }) => {
//   const getPath = (progress, radius) => {
//     const circumference = 2 * Math.PI * radius;
//     return `M 50 50 m 0 -${radius} a ${radius} ${radius} 0 1 1 0 ${2 * radius} a ${radius} ${radius} 0 1 1 0 -${2 * radius} Z`;
//   };

//   const innerRadius = 25;
//   const middleRadius = 40;
//   const outerRadius = 55;
//   const strokeWidth = 8;

//   return (
//     <div style={{ position: 'relative', width: '100px', height: '100px' }}>
//       {progressList.map((progress, index) => (
//         <svg key={index} viewBox="0 0 180 180" className="w-[200px] h-[200px]" style={{ position: 'absolute', top: 0, left: 0 }}>
//           <path
//             d={getPath(progress, index === 0 ? innerRadius : index === 1 ? middleRadius : outerRadius)}
//             stroke={index === 0 ? "#FF51B9" : index === 1 ? "#4871B7" : "#2BB574"} // Use different colors for each ring
//             fill="none"
//             strokeWidth={strokeWidth}
//             strokeDasharray={`${progress / 100 * 2 * Math.PI * (index === 0 ? innerRadius : index === 1 ? middleRadius : outerRadius)} ${2 * Math.PI * (index === 0 ? innerRadius : index === 1 ? middleRadius : outerRadius)}`}
//             strokeDashoffset="0"
//             strokeLinecap="round" // To ensure the stroke caps are rounded
//           />
//         </svg>
//       ))}
//     </div>
//   );
// };

// export default CircularRings;

import React from 'react';

export const innerRadius = 25;
export const middleRadius = 40;
export const outerRadius = 56;
export const strokeWidth = 8;

const CircularRings = ({ progressList }) => {
  const getPath = (progress, radius) => {
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;
    return `M 50 50 m 0 -${radius} a ${radius} ${radius} 0 1 1 0 ${2 * radius} a ${radius} ${radius} 0 1 1 0 -${2 * radius} Z`;
  };

  return (
    <div  className="" style={{ position: 'relative', width: '100px', height: '100px' }}>
      {progressList.map((progress, index) => (
        <svg key={index} viewBox="-10 0 220 100" className="w-[280px] h-[180px]" style={{ position: 'absolute',  }}>
          <path
            d={getPath(progress, index === 0 ? innerRadius : index === 1 ? middleRadius : outerRadius)}
            stroke={index === 0 ? "#FF51B9" : index === 1 ? "#4871B7" : "#2BB574"} // Use different colors for each ring
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="round" // To ensure the stroke caps are rounded
          />
          <path
            d={getPath(100, index === 0 ? innerRadius : index === 1 ? middleRadius : outerRadius)}
            stroke="#ccc" // Grey color for remaining border
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeDasharray={`${(100 - progress) / 100 * 2 * Math.PI * (index === 0 ? innerRadius : index === 1 ? middleRadius : outerRadius)} ${2 * Math.PI * (index === 0 ? innerRadius : index === 1 ? middleRadius : outerRadius)}`}
            strokeDashoffset={`-${progress / 100 * 2 * Math.PI * (index === 0 ? innerRadius : index === 1 ? middleRadius : outerRadius)}`}
          />
        </svg>
      ))}
    </div>
  );
};

export default CircularRings;











  

  


