import Intercom from "@intercom/messenger-js-sdk";
import { useEffect } from "react";
import { INTERCOM_APP_ID } from "../../../appconfig";
import { useSelector } from "react-redux";

const PublicRouteLayout = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const fullName = useSelector((state) => state.auth.fullName);

  useEffect(() => {
    if (user && user.id && user.email && fullName) {
      Intercom({
        app_id: INTERCOM_APP_ID,
        name: fullName,
        email: user.email.toLowerCase(),
        user_hash: user.userHash
      });
    } else {
      Intercom({
        app_id: INTERCOM_APP_ID,
      });
    }
  }, [user, fullName]);

  return (
    <>
    {children}
    </>
  );
};

export default PublicRouteLayout;
