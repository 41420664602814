import React from "react";

const Filter = ({ title, id, value, setSelectedValue, selectedValue }) => {
  const getClassNames = () => {
    if (id === selectedValue[0] || title === selectedValue[1]) {
      return "text-center text-[#4871B7] font-bold text-[14px]";
    }
    return "text-center text-black font-bold  text-[14px]";
  };

  const borderClassNames = () => {
    if (id === selectedValue[0] || title === selectedValue[1]) {
      return "w-full mt-2  h-1  bg-[#4871B7]";
    }
    return "hidden";
  };

  const selectSlot = () => {
    setSelectedValue([id, title]);
  };

  return (
    <div className={`${getClassNames()}`}
    onClick={selectSlot}>
      <div className={getClassNames()}>
        {title}
      </div>
      <div className={`${getClassNames()}`}>
        {value}
      </div>
      <div className={borderClassNames()}></div>
    </div>
  );
};

export default Filter;
