import React, { useEffect, useState } from 'react';
import addIcon from '../../../assets/add-round.svg';
import NewTaproot from './popups/NewTaproot';
import TreeView from './TapMembers';
import useApi from '../../../custom-hooks/useApi';
import { BASE_URL } from '../../../appconfig';
import Spinner from '../../common/Spinner';
import { useSelector } from 'react-redux';

function TapRoot({ name, isSidebarOpen }) {
  const [isTapPopupOpen, setIsTapPopupOpen] = useState(false);
  const [tapData, setTapData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const back = useSelector((state) => state.agent.backOffice);
  const api = useApi();

  const toggleTapPopup = () => {
    setIsTapPopupOpen(!isTapPopupOpen);
  };

  const toggleChange = () => {
    setChanged(!changed);
  };

  function conversion(arr) {
    let taproot = [];
    let associate = [];

    arr.forEach((item) => {
      if (item.IsTapRoot) {
        const taprootItem = { ...item, associateArr: [] };
        taproot.push(taprootItem);
      } else {
        associate.push(item);
      }
    });

    taproot = taproot.sort((a, b) => a.TapRootID - b.TapRootID);

    for (let i = 0; i < taproot.length; i++) {
      let agentId = taproot[i].AgentRootID;
      while (agentId !== "") {
        let foundMatch = false;
        for (let j = 0; j < associate.length; j++) {
          if (agentId === associate[j].ParentAgentID) {
            taproot[i].associateArr.push(associate[j]);
            agentId = associate[j].AgentRootID;
            foundMatch = true;
          }
        }
        if (!foundMatch) {
          agentId = "";
        }
      }
    }
    setTapData([...taproot]);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`${BASE_URL}/Backoffice/TapRoot`);
        conversion(response.data.data);
      } catch (error) {
        setTapData(null);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [changed, back.isChanged]);

  return (
    <div>
      {loading && <Spinner />}
      <div className="flex items-center justify-between p-5">
        <p className="text-sm">My Taproots</p>
        <img
          onClick={() => setIsTapPopupOpen(true)}
          src={addIcon}
          alt="add-icon"
          className="w-5 h-5 mr-2 cursor-pointer"
        />
      </div>
      {isTapPopupOpen && (
        <NewTaproot
          title={name}
          toggle={toggleTapPopup}
          change={toggleChange}
        />
      )}
      {tapData &&
        tapData.map((e) => (
          <TreeView
            change={toggleChange}
            key={e.TapRootID}
            data={e}
            isSidebarOpen={isSidebarOpen}
          />
        ))}
    </div>
  );
}

export default TapRoot;
