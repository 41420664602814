import React from 'react';
import { CiCircleInfo } from "react-icons/ci";

import "./Client.css";

const RetirementScale = ({ retirementAge, onRetirementAgeChange, MonthlyIncomeNeeded, MonthlyContribution , handleMonthlyContriChange ,handleMonthlyIncomeChange }) => {
    // Calculate the percentage of the slider that is filled
    const filledPercentage = ((retirementAge - 35) / (95 - 35)) * 100;
    const filledPercentageMContri = ((MonthlyContribution) / (5000)) * 100;

    const filledPercentageNeed = ((MonthlyIncomeNeeded) / (10000)) * 100;


    return (
        <div className="mt-8 font-inter">
            {/* Retirement Age Slider */}
            <div className="text-xl font-semibold">
                Refine your results
            </div>
            <div className="flex font-semibold  mt-2">
                <div className=" text-[#9CA3AF] text-base">
                    Use the sliders to play with the numbers and find your ideal plan.
                </div>
                <div className="mt-1 text-lg ml-2 cursor-pointer ">
                    <CiCircleInfo />
                </div>
            </div>


            <div className="items-center mt-10">
                <div className="text-[#3A4253] text-xs font-semibold">
                    Monthly contribution
                </div>
                <div className="flex items-center relative">
                    <p className="mt-2 mr-4">$0</p>
                    <div className="relative w-full">
                        <input
                            type="range"
                            id="retirementAge"
                            name="retirementAge"
                            min="0"
                            max="5000"
                            step="1"
                            value={MonthlyContribution}
                            onChange={(e) => handleMonthlyContriChange(parseInt(e.target.value))}
                            className="mr-4 w-full"
                            style={{
                                background: `linear-gradient(to right, #4871B7 ${filledPercentageMContri}%, #9CA3AF ${filledPercentageMContri}%)`,
                                borderRadius: '5px',
                                height: '3px',
                                appearance: 'none',
                                outline: 'none',
                                cursor: 'pointer',
                            }}
                        />
                        {/* Value indicator */}
                        <div
                            className="absolute text-center p-1"
                            style={{
                                left: `calc(${filledPercentageMContri}% - 20px)`,
                                top: '-25px',
                                width: '40px',
                                color: 'white',
                                borderRadius: "10px",
                                backgroundColor: "#2BB574",
                                fontWeight: 'bold',
                            }}
                        >
                            {MonthlyContribution}
                        </div>
                    </div>
                    <p className="mt-2 ml-4">$ 5K</p>
                </div>
            </div>

            <div className="items-center mt-10">
                <div className="text-[#3A4253] text-xs font-semibold">
                    Retirement age
                </div>
                <div className="flex items-center relative">
                    <p className="mt-2 mr-4">35</p>
                    <div className="relative w-full">
                        <input
                            type="range"
                            id="retirementAge"
                            name="retirementAge"
                            min="35"
                            max="95"
                            step="1"
                            value={retirementAge}
                            onChange={(e) => onRetirementAgeChange(parseInt(e.target.value, 10))}
                            className="mr-4 w-full"
                            style={{
                                background: `linear-gradient(to right, #4871B7 ${filledPercentage}%, #9CA3AF ${filledPercentage}%)`,
                                borderRadius: '5px',
                                height: '3px',
                                appearance: 'none',
                                outline: 'none',
                                cursor: 'pointer',
                            }}
                        />
                        {/* Value indicator */}
                        <div
                            className="absolute text-center p-1"
                            style={{
                                left: `calc(${filledPercentage}% - 20px)`,
                                top: '-25px',
                                width: '40px',
                                color: 'white',
                                borderRadius: "10px",
                                backgroundColor: "#2BB574",
                                fontWeight: 'bold',
                            }}
                        >
                            {retirementAge}
                        </div>
                    </div>
                    <p className="mt-2 ml-4">95</p>
                </div>
            </div>

            <div className="items-center mt-10">
                <div className="text-[#3A4253] text-sm font-semibold">
                    Monthly retirement budget                </div>
                <div className="flex items-center relative">
                    <p className="mt-2 mr-4">$0</p>
                    <div className="relative w-full">
                        <input
                            type="range"
                            id="retirementAge"
                            name="retirementAge"
                            min="0"
                            max="10000"
                            step="1"
                            value={MonthlyIncomeNeeded}
                            onChange={(e) => handleMonthlyIncomeChange(parseInt(e.target.value))}
                            className="mr-4 w-full"
                            style={{
                                background: `linear-gradient(to right, #4871B7 ${filledPercentageNeed}%, #9CA3AF ${filledPercentageNeed}%)`,
                                borderRadius: '5px',
                                height: '3px',
                                appearance: 'none',
                                outline: 'none',
                                cursor: 'pointer',
                            }}
                        />
                        {/* Value indicator */}
                        <div
                            className="absolute text-center p-1"
                            style={{
                                left: `calc(${filledPercentageNeed}% - 20px)`,
                                top: '-25px',
                                width: '60px',
                                color: 'white',
                                borderRadius: "10px",
                                backgroundColor: "#2BB574", fontWeight: 'bold',
                            }}
                        >
                            {MonthlyIncomeNeeded}
                        </div>
                    </div>
                    <p className="mt-2 ml-4">$ 10K</p>
                </div>
            </div>
        </div>
    );
};

export default RetirementScale;
