import React, { useState } from "react";
import StageForm from "./StageForm";
import { useSelector, useDispatch } from "react-redux";
import { updateAgentData } from "../../../../redux/thunks/agentThunk";
import { setFormData } from "../../../../redux/slices/agentSlice";
import useApi from "../../../../custom-hooks/useApi";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { isValid, format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Stage1 = ({ handleChange, tapRootChange }) => {
  const stepId = useSelector((state) => state.agent.currentStep);
  // const phonePattern = "^\\+?[2-9]([0-9](?![0-9]{10}))?[2-9][0-9]{2}[2-9][0-9]{6}$";
  // const phonePattern = "^[+]?[(]?[0-9]{3}[)]?[-.\\s]?[0-9]{3}[-.\\s]?[0-9]{4,6}$";
  const phonePattern = RegExp(
    "^[+]?[(]?[0-9]{3}[)]?[- .]?[0-9]{3}[- .]?[0-9]{4,6}$"
  );

  const agentId = useSelector((state) => state.agent.agentId);
  const dispatch = useDispatch();
  const api = useApi();

  const formData = useSelector((state) => state.agent.formData);
  const currentStepData = useSelector((state) => state.agent.currentStepData);
  const allAgentData = useSelector((state) => state.agent.allAgentData);

  const [isEditing, setEditing] = useState(false);
  
    const toggleEditing = () => {
      if (isEditing) {
        dispatch(
          updateAgentData({ stepId, agentId, formData, currentStepData, api })
        );
      }
      setEditing(!isEditing);
    };

  const cancelEditing = () => {
    dispatch(setFormData(currentStepData));
    setEditing(false);
  };

    const setDate = (date, name) => {
      try {
        console.log(date);

        if (isValid(date)) {
          const formattedDate = format(date, "yyyy-MM-dd");
          dispatch(setFormData({ [name]: formattedDate }));
        } else {
          console.error("Invalid date");
        }
      } catch (error) {
        console.error("Error parsing date:", error);
      }
    };

  const getUplineName = (uplineID) => {
    let uplineAgent =
      allAgentData.find((agent) => agent.AgentID === uplineID) || null;
    if (uplineAgent) {
      return uplineAgent.FirstName + " " + uplineAgent.LastName;
    } else {
      return "-";
    }
  };

 
  if (formData !== null && currentStepData !== null) {
    return (
      <>
        <StageForm
          stageNum={stepId}
          isEditable={true}
          cancelEditing={cancelEditing}
          isEditing={isEditing}
          toggleEditing={toggleEditing}
          tapRootChange={tapRootChange}
        >
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 1 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Sign Up for Licensing Course&nbsp;
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              {!isEditing && (
                <div
                  className={`w-4 h-4 border border-gray-300 rounded mr-2 ${
                    formData.Sign_Up_For_Licensing_Course ? "bg-blue-700" : ""
                  }`}
                >
                  {formData.Sign_Up_For_Licensing_Course && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="12"
                      viewBox="0 0 10 8"
                      fill="none"
                      className=" text-white mx-auto my-auto"
                    >
                      <path
                        d="M9 1L3.5 6.5L1 4"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              )}
              {isEditing && (
                <input
                  type="checkbox"
                  name="Sign_Up_For_Licensing_Course"
                  checked={formData.Sign_Up_For_Licensing_Course}
                  onChange={(e) => handleChange(e)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-4 mb-4"
                />
              )}
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 2 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">
                Exam Scheduled Date&nbsp;
                <span className="font-bold font-inter text-red-700">*</span>
              </span>
            </div>
            <div className="mb-2 md:w-1/2">
              {!isEditing && (
                <span>
                  {currentStepData.Exam_Scheduled !== null
                    ? isValid(new Date(currentStepData.Exam_Scheduled))
                      ? format(
                          new Date(currentStepData.Exam_Scheduled),
                          "M/d/yyyy"
                        )
                      : "-"
                    : "-"}
                </span>
              )}
              {isEditing && (
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  name="Exam_Scheduled"
                  className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  //  selected={
                  //    isValid(new Date(formData.Exam_Scheduled))
                  //     ? new Date(formData.Exam_Scheduled)
                  //     : null
                  //             }
                  selected={
                    formData.Exam_Scheduled
                      ? new Date(formData.Exam_Scheduled)
                      : null
                  }
                  onChange={(date) => setDate(date, "Exam_Scheduled")}
                  placeholderText="mm/dd/yyyy"
                  placeholder="m/d/Y"
                  dateFormat="M/d/yyyy"
                  showYearDropdown
                  dropdownMode="select"
                  required
                />
              )}
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 3 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">Client Experience&nbsp;</span>
            </div>
            <div className="mb-2 md:w-1/2">
              {!isEditing && (
                <div
                  className={`w-4 h-4 border border-gray-300 rounded mr-2 ${
                    formData.Client_Experience ? "bg-blue-700" : ""
                  }`}
                >
                  {formData.Client_Experience && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="12"
                      viewBox="0 0 10 8"
                      fill="none"
                      className=" text-white mx-auto my-auto"
                    >
                      <path
                        d="M9 1L3.5 6.5L1 4"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              )}
              {isEditing && (
                <input
                  type="checkbox"
                  name="Client_Experience"
                  checked={formData.Client_Experience}
                  onChange={(e) => handleChange(e)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-4 mb-4"
                />
              )}
            </div>
          </div>
          <div className="flex flex-row md:flex-row items-center">
            {/* Row 4 */}
            <div className="text-gray-500 mb-2 md:w-1/2">
              <span className="stage-label">New Agent School&nbsp;</span>
            </div>
            <div className="mb-2 md:w-1/2">
              {!isEditing && (
                <div
                  className={`w-4 h-4 border border-gray-300 rounded mr-2 ${
                    formData.New_Agent_School ? "bg-blue-700" : ""
                  }`}
                >
                  {formData.New_Agent_School && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="12"
                      viewBox="0 0 10 8"
                      fill="none"
                      className=" text-white mx-auto my-auto"
                    >
                      <path
                        d="M9 1L3.5 6.5L1 4"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              )}
              {isEditing && (
                <input
                  type="checkbox"
                  name="New_Agent_School"
                  checked={formData.New_Agent_School}
                  onChange={(e) => handleChange(e)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mt-4 mb-4"
                />
              )}
            </div>
          </div>
        </StageForm>
      </>
    );
  }
};
export default Stage1;
