import React, { useEffect, useState } from "react";
import downArrow from "../../../../assets/downarrow.svg";
import upArrow from "../../../../assets/uparrow.svg";
import deleteIcon from "../../../../assets/delete.svg";
import {ReactComponent as CampaignGroupIcon} from "../../../../assets/CampaignGroupIcon.svg";
import { toast } from "react-toastify";
import Spinner from "../../../common/Spinner";
import useApi from "../../../../custom-hooks/useApi";
import NewCampaignPopup from "../popups/NewCampaignPopup";

const getInitials = (name) => {
  const words = name.split(" ");
  let initials = "";
  if (words.length >= 1) {
    initials += words[0] && words[0] !== "" ? words[0][0].toUpperCase() : "";
  }
  if (words.length >= 2) {
    initials += words[1] && words[1].toString().trim() !== "" ? words[1][0].toUpperCase() : "";
  }
  return initials;
};

const CampaignItem = ({
  campaign,
  isLastChild,
  onDelete,
  onCampaignClick,
  groupColor,
}) => (
  <div className="px-6 py-2">
    <div className="flex items-center">
      <div className="w-full flex items-center">
        {/* <div
          className="flex cursor-pointer h-[30px] w-[30px] gap-2 border-[2px] items-center justify-center rounded-[50%] bg-gray-400 text-white text-[10px]"
          style={{ borderColor: groupColor }}
        >
          <span className="flex justify-center items-center text-center h-[30px] w-[30px] font-bold">
            {getInitials(campaign.Campaign_Name)}
          </span>
        </div> */}
        <div className="ml-4 w-full flex items-center justify-between">
          <div
            onClick={() => onCampaignClick(campaign)}
            className="cursor-pointer text-white text-sm flex-1 pr-2 truncate whitespace-wrap w-16"
          >
            {campaign.Campaign_Name}
          </div>
            {campaign.IsActive ? (
              <span className="h-2 w-2 rounded-full bg-green-500" />
            ) : (
              <span className="h-2 w-2 rounded-full bg-red-500" />
            )}

          <div className="w-[15%]">
            {campaign.IsActive && (
              <img
                style={{
                  cursor: "pointer",
                  height: "18px",
                  width: "18px",
                  marginLeft: "0.5rem",
                }}
                src={deleteIcon}
                alt="delete-icon"
                onClick={() => onDelete(campaign)}
                className="cursor-pointer"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const CampaignList = ({ campaigns, deleteCampaign, onCampaignClick, groupColor }) => (
  <div className="mb-1">
    {campaigns.map((campaign, index) => (
      <CampaignItem
        key={Math.random()}
        isLastChild={index === campaigns.length - 1}
        campaign={campaign}
        onDelete={() => deleteCampaign(campaign.ID)}
        onCampaignClick={() => onCampaignClick(campaign)}
        groupColor={groupColor}
      />
    ))}
  </div>
);

const TreeNode = ({
  campaignClick,
  status,
  newCamp,
  campId,
  number,
  node,
  change,
  isSidebarOpen,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [campaignPopup, setCampaignPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const api = useApi();

  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  const deleteCampaign = async (campaignId) => {
    if (isDeleting) {
      return;
    }
    try {
      setIsDeleting(true);
      const response = await api.delete(`/Campaign/${campaignId}`);

      if (response.status === 200) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        change();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to delete record", {
        theme: "dark",
      });
      console.error("Error deleting data:", error);
    } finally {
      setIsDeleting(false);
      setLoading(false);
    }
  };

  const deleteCampaignGroup = async (campaignGroupId) => {
    if (isDeleting) {
      return;
    }
    try {
      setIsDeleting(true);
      const response = await api.delete(`/Campaign/Group/${campaignGroupId}`);

      if (response.status === 200) {
        toast.success("Record deleted successfully", {
          theme: "dark",
        });
        change();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to delete record", {
        theme: "dark",
      });
      console.error("Error deleting data:", error);
    } finally {
      setIsDeleting(false);
      setLoading(false);
    }
  };

  const toggleCampaign = () => {
    setCampaignPopup(!campaignPopup);
  };

  const handleCampaignNameClick = (campaign) => {
    newCamp(campaign?.ID || null);
    campaignClick(campaign);
  };

  useEffect(() => {
    const hasMatchingCampaign = node.campaigns.some(
      (campaign) => campaign.ID === campId
    );
    if (hasMatchingCampaign) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [campId, node.campaigns]);
  
  return (
    <div className="py-2">
      {loading && <Spinner />}
      <div className="flex flex-row gap-2 px-6">
      <div className=" flex justify-center w-[3px] h-unset rounded-full bg-gray-700">
          <div style={{ background: node.Group_Color }} className={`w-[5px] h-[35px]  rounded-full`}></div>
        </div>
      <div className="">
      <div className="flex items-center mt-1">
        <div className="w-full flex items-center">
          {isSidebarOpen && (
            <div className="w-full flex items-center justify-between gap-2 ml-1">
              <div className="flex w-full items-center gap-2">
                <CampaignGroupIcon
                  className={`cursor-pointer border-[2px] w-[30px] h-[30px] rounded-[50%]`}
                  style={{ borderColor: node.Group_Color }}
                />

                <div className="text-white text-sm cursor-pointer w-28">
                  <p className=" truncate" onClick={() => handleCampaignNameClick}>
                    {node.Group_Name}
                  </p>
                </div>
              </div>
              <div className="items-center w-full ">
                {node.campaigns.length !== 0 && (
                  <img
                    onClick={() => deleteCampaignGroup(node.ID)}
                    className="cursor-pointer h-[20px] w-[20px]"
                    src={deleteIcon}
                    alt="delete"
                  />
                )}
              </div>
              <div className="cursor-pointer w-full " onClick={toggleExpand}>
                <img
                  className=""
                  src={isExpanded ? downArrow : upArrow}
                  alt="down-up-arrow"
                />
              </div>
            </div>
          )}
          {!isSidebarOpen && (
            <div className="w-full flex items-center justify-end">
              <div className="flex items-center gap-2">
                <CampaignGroupIcon
                  className={`ml-2 cursor-pointer border-[2px] w-[30px] h-[30px] rounded-[50%]`}
                  style={{ borderColor: node.Group_Color }}
                />{" "}
              </div>
            </div>
          )}
        </div>
      </div>
      {isSidebarOpen && isExpanded && node.campaigns && (
        <>
          <CampaignList
            deleteCampaign={deleteCampaign}
            campaigns={node.campaigns}
            onCampaignClick={handleCampaignNameClick}
            groupColor={node.Group_Color}
          />
        </>
      )}
      </div>
      </div>
      {isSidebarOpen && (
        <div className="mb-4 mt-2 flex items-center justify-center">
          {status === 24 && number !== null && (
            <p
              onClick={toggleCampaign}
              className="text-green-600 text-xs font-bold mr-4 cursor-pointer"
            >
              + New Campaign
            </p>
          )}
          {campaignPopup && (
            <NewCampaignPopup
              campClick={campaignClick}
              newAdded={newCamp}
              change={change}
              campaignGroupId={node.ID}
              toggle={toggleCampaign}
            />
          )}
        </div>
      )}
    </div>
  );
};

const CampaignTreeView = ({
  campaignClick,
  status,
  campId,
  newCamp,
  number,
  key,
  group,
  change,
  isSidebarOpen,
  expandedState,
  updateExpandedState
}) => (
  <div>
    <TreeNode
      status={status}
      campId={campId}
      newCamp={newCamp}
      number={number}
      key={key}
      node={group}
      change={change}
      isSidebarOpen={isSidebarOpen}
      campaignClick={campaignClick}
      expandedState={expandedState}
      updateExpandedState={updateExpandedState}
    />
  </div>
);

export default CampaignTreeView;
