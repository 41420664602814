import React, { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "../../../../assets/edit.svg";
import { ReactComponent as SaveIcon } from "../../../../assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../assets/cancel.svg";
import fileTextSvg from "../../../../assets/file-text.svg";
import monitorSvg from "../../../../assets/monitor.svg";
import downloadBlueSvg from "../../../../assets/downloadblue.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  updateAgentCompletedStep,
  requestStepSignOff,
} from "../../../../redux/thunks/agentThunk";
import useApi from "../../../../custom-hooks/useApi";
import { toast } from "react-toastify";
import { setBackOffice } from "../../../../redux/slices/agentSlice";
import YouTubeVideo from "./YouTubeVideo";

const StageForm = ({
  stageNum,
  cancelEditing,
  isEditable,
  isEditing,
  toggleEditing,
  children,
  tapRootChange,
}) => {
  const [isCompletable, setStepCompletable] = useState(false);
  const [stepCompleted, setStepCompleted] = useState(false);
  const [signOffCompleted, setSignOffCompleted] = useState(false);
  const dispatch = useDispatch();
  const agentId = useSelector((state) => state.agent.agentId);
  const api = useApi();
  const allStepData = useSelector((state) => state.agent.allStepData);
  const currentStepData = useSelector((state) => state.agent.currentStepData);
  const promotionStageStatus = useSelector(
    (state) => state.agent.promotionStageStatus
  );
  const completedStages = useSelector((state) => state.agent.completedStages);
  const formData = useSelector((state) => state.agent.formData);
  const user = useSelector((state) => state.auth.user);
  const agentData = useSelector((state) => state.agent.data);
  const currentStep = useSelector((state) => state.agent.currentStep);
  const back = useSelector((state) => state.agent.backOffice);
  const adminRoles = useSelector((state) => state.auth.adminRoles);
  const role = useSelector((state) => state.auth.role);

  useEffect(() => {
    if (
      completedStages &&
      completedStages.length &&
      completedStages.includes(parseInt(stageNum))
    ) {
      setStepCompleted(true);
    }
    if (tapRootChange) {
      dispatch(setBackOffice({ isChanged: !back.isChanged }));
    }
  }, [completedStages, stageNum]);

  useEffect(() => {
    if (stageNum < 5) {
      setSignOffCompleted(true);
    } else if (
      currentStepData &&
      currentStepData.Sign_Off_Date &&
      currentStepData.Sign_Off_Status === 66
    ) {
      setSignOffCompleted(true);
    } else {
      setSignOffCompleted(false);
    }
  }, [currentStepData, stageNum]);

  // For enable/disable mark stage as complete
  useEffect(() => {
    let allDataFilled = true;
    if (currentStepData && stageNum !== 7 && stageNum !== 5 && stageNum !== 6) {
      for (const [key, value] of Object.entries(currentStepData)) {
        if (
          key === "HomeStreet2" ||
          key === "Master_the_Scripts" ||
          key === "Cash_Flow_1k" ||
          key === "Train_The_Trainer_Course"
        ) {
          continue;
        }

        if (
          value === null ||
          value === undefined ||
          value === "" ||
          value === false
        ) {
          allDataFilled = false;
          break;
        }
      }
    } else if (stageNum < 5) {
      allDataFilled = false;
    }

    if (stageNum === 7) {
      allDataFilled = true;
      if (formData) {
        for (const [key, value] of Object.entries(formData)) {
          if (key === "ExchangeLeg")
            if (
              value === null ||
              value === undefined ||
              value === "" ||
              value === false
            ) {
              allDataFilled = false;
              break;
            }
        }
      }
    }

    let mandatoryStepsCompleted = true;
    if (currentStepData && stageNum > 4) {
      mandatoryStepsCompleted = false;
      switch (stageNum) {
        case 5:
          if (currentStepData.Sign_Off_Date) {
            mandatoryStepsCompleted = true;
          }
          break;
        case 6:
          if (currentStepData.Sign_Off_Date) {
            mandatoryStepsCompleted = true;
          }
          break;
        case 7:
          if (currentStepData.Sign_Off_Date) {
            mandatoryStepsCompleted = true;
          }

          // if (
          //   currentStepData?.Active === undefined ||
          //   currentStepData?.Active === null ||
          //   currentStepData?.Active !== "Y"
          // ) {
          //   mandatoryStepsCompleted = false;
          // }
          break;
        default:
          mandatoryStepsCompleted = false;
          break;
      }
    } else if (stageNum > 4) {
      mandatoryStepsCompleted = false;
    }

    let allPrevStageCompleted = true;

    if (completedStages && completedStages.length) {
      for (let i = 1; i < stageNum; i++) {
        if (!completedStages.includes(i)) {
          allPrevStageCompleted = false;
          break;
        }
      }
    } else if (stageNum !== 1) {
      allPrevStageCompleted = false;
    }
    let promotingSelf = true;

    if (
      stageNum === 7 &&
      user?.AgentID?.toString().toUpperCase() !==
        agentData?.AgentID?.toString().toUpperCase()
    ) {
      promotingSelf = false;
    }

    let stageAlreadyCompleted = false;
    if (
      completedStages &&
      completedStages.length &&
      completedStages.includes(parseInt(stageNum))
    ) {
      stageAlreadyCompleted = true;
    }
    // console.log(allDataFilled);
    if (
      allDataFilled &&
      mandatoryStepsCompleted &&
      allPrevStageCompleted &&
      promotingSelf &&
      !stageAlreadyCompleted
    ) {
      setStepCompletable(true);
    } else {
      setStepCompletable(false);
    }
  }, [
    currentStepData,
    promotionStageStatus,
    stageNum,
    completedStages,
    formData,
    user.AgentID,
    agentData.AgentID,
  ]);

  const saveFormData = (e) => {
    e.preventDefault();
    const form = document.querySelector("#stage-form");

    const checkboxes = form.querySelectorAll('input[type="checkbox"]');

    if (checkboxes.length) {
      let allFieldsChecked = true;
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) {
          allFieldsChecked = false;
        }
      });
      if (!allFieldsChecked) {
        toast.error("All Checkboxes should be checked in this step", {
          theme: "dark",
        });
        return;
      }
    }

    if ((isEditing || stageNum === 7) && form && form.checkValidity()) {
      toggleEditing();
      if (stageNum === 7) {
        let promotionEligible = true;
        if (promotionStageStatus) {
          Object.values(promotionStageStatus).forEach((value) => {
            if (value === null || value === undefined || value === "") {
              promotionEligible = false;
            }
          });
        } else {
          promotionEligible = false;
        }
        if (promotionEligible) {
          // dispatch(updateAgentCompletedStep({ stageNum, agentId, api })); pavithra will mark update in save api in step 9
        }
      }
    } else if (isEditing && form) {
      form.querySelector('input[type="submit"]').click();
    } else {
      toggleEditing();
    }
  };

  const handleStepComplete = (e) => {
    if (stageNum === 7) {
      saveFormData(e);
    } else {
      dispatch(updateAgentCompletedStep({ stageNum, agentId, api }));
    }
  };

  const handleSignOffRequest = (e) => {
    dispatch(requestStepSignOff({ stageNum, agentId, api }));
  };

  const handleStageSubmit = (e) => {
    console.log(e, "submitted data");
    e.preventDefault();
    saveFormData(e);
  };

  return (
    <>
      <div className="bg-white border border-gray-300 rounded p-4 font-inter">
        <div className="grid font-inter p-2">
          <div className="flex flex-row gap-8 mb-4 items-center">
            <div className="flex w-1/2 flex-row items-center gap-2">
              {/* <span className="mb-4 text-gray-700 font-semibold text-xl">
                Current Stage:
              </span> */}
              <span className="mb-4 text-black font-extrabold text-xl">
                {allStepData.length > 0 &&
                  allStepData.find((s) => s.Step_ID === stageNum)[
                    "Step_Description"
                  ]}
              </span>
            </div>

            <div className="w-1/2 flex flex-row items-center justify-end pr-5">
              {signOffCompleted && (
                <button
                  style={
                    !isCompletable
                      ? {}
                      : {
                          background:
                            "linear-gradient(281deg, #4971B8 11.19%, #2BB673 103.48%)",
                        }
                  }
                  className={`text-white py-4 px-6 rounded font-inter ${
                    !isCompletable ? "bg-gray-600" : "cursor-pointer"
                  } `}
                  disabled={
                    !isCompletable ||
                    (!adminRoles.includes(role) &&
                      user.organizationId !== atob(agentId))
                  }
                  onClick={handleStepComplete}
                >
                  {stepCompleted ? "Stage Completed" : "Mark Stage as Complete"}
                </button>
              )}

              {!signOffCompleted && (
                <button
                  style={
                    !completedStages.includes(stageNum - 1) ||
                    (!currentStepData.Sign_Off_Date &&
                      currentStepData.Sign_Off_Status === 65)
                      ? {}
                      : {
                          background:
                            "linear-gradient(281deg, #4971B8 11.19%, #2BB673 103.48%)",
                        }
                  }
                  className={`text-white py-4 px-6 rounded font-inter ${
                    !completedStages.includes(stageNum - 1) ||
                    (!currentStepData.Sign_Off_Date &&
                      currentStepData.Sign_Off_Status === 65)
                      ? "bg-gray-600"
                      : "cursor-pointer"
                  } `}
                  disabled={
                    !completedStages.includes(stageNum - 1) ||
                    (!currentStepData.Sign_Off_Date &&
                      currentStepData.Sign_Off_Status === 65) ||
                    (!adminRoles.includes(role) &&
                      user.organizationId !== atob(agentId))
                  }
                  onClick={handleSignOffRequest}
                >
                  {!currentStepData.Sign_Off_Status
                    ? "Request Sign Off"
                    : "Sign Off Pending"}
                </button>
              )}
            </div>
          </div>
          <div className="flex gap-8">
            <div className="flex w-1/2 flex-col gap-4 border-r pr-6">
              <div className="flex flex-row justify-between font-inter items-start mb-2 border-r-2-gray-500">
                <div className="flex gap-10">
                  <p className="text-gray-700 text-base font-medium">
                    KEY FIELDS
                  </p>
                </div>
                {isEditable && (
                  <>
                    {adminRoles.includes(role) && (
                      <div className="flex flex-row items-center gap-4">
                        {isEditing && (
                          <div
                            className="flex flex-row gap-2 items-center cursor-pointer"
                            onClick={() => cancelEditing()}
                          >
                            {isEditing && <CancelIcon />}
                            <p className="flex items-center text-gray-500">
                              Cancel
                            </p>
                          </div>
                        )}
                        <div
                          className="flex flex-row gap-2 cursor-pointer"
                          onClick={handleStageSubmit}
                        >
                          {!isEditing && <EditIcon />}
                          {isEditing && <SaveIcon />}
                          <p className="flex items-center text-green-500">
                            {isEditing ? "Save" : "Edit"}
                          </p>
                        </div>
                      </div>
                    )}
                    {!adminRoles.includes(role) &&
                      user.organizationId == atob(agentId) && (
                        <div className="flex flex-row items-center gap-4">
                          {isEditing && (
                            <div
                              className="flex flex-row gap-2 items-center cursor-pointer"
                              onClick={() => cancelEditing()}
                            >
                              {isEditing && <CancelIcon />}
                              <p className="flex items-center text-gray-500">
                                Cancel
                              </p>
                            </div>
                          )}
                          <div
                            className="flex flex-row gap-2 cursor-pointer"
                            onClick={handleStageSubmit}
                          >
                            {!isEditing && <EditIcon />}
                            {isEditing && <SaveIcon />}
                            <p className="flex items-center text-green-500">
                              {isEditing ? "Save" : "Edit"}
                            </p>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>
              <div className="flex gap-2 flex-col text-sm font-medium">
                <form id="stage-form" onSubmit={handleStageSubmit}>
                  {children}
                  <input type="submit" style={{ display: "none" }} />
                </form>
              </div>
            </div>

            {/* YouTube Video Column */}
            <div className="w-1/2 flex flex-col justify-end gap-3">
              {/* Embed your YouTube video here */}
              {allStepData.length > 0 && (
                <YouTubeVideo
                  height="315"
                  videoURL={
                    allStepData.length > 0 &&
                    allStepData.find((s) => s.Step_ID === currentStep)[
                      "Video_URL"
                    ]
                  }
                />
              )}
            <div className="flex flex-row justify-between w-full pr-5">
             <DownloadButton img={fileTextSvg} label={'Onboarding Wo...'} text={'Download PDF'}/>
             <DownloadButton img={monitorSvg} label={'Onboarding Pre...'} text={'Download PDF'}/>
             <DownloadButton img={downloadBlueSvg} label={'2 documents'} text={'Download all'}/>
            </div>
            </div>
         
          </div>
        </div>
      </div>
    </>
  );
};
export default StageForm;

const DownloadButton = ({img, label, text}) => {
  return (
    <div className="flex flex-row gap-2 cursor-pointer">
      <div><img src={img} alt="" /></div>
      <div className="flex flex-col gap-[2px]">
        <div className="font-semibold text-[9px] leading-[10.89px] text-[#4871B7] truncate">
          {label}
        </div>
        <div className="font-normal text-[8px] leading-[9.68px] text-gray-500 truncate">
          {text}
        </div>
      </div>
    </div>
  );
};
