import React from "react";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, IMAGE_URL, INTERCOM_APP_ID } from "../../../../../appconfig";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {
  setAuthToken,
  setFullName,
  setRefreshToken,
  setRole,
  setUser,
} from "../../../../../redux/slices/authSlice";
import { setLoading } from "../../../../../redux/slices/commonSlice";
import Intercom from "@intercom/messenger-js-sdk";

function ProfileDetailsStatic({ agentRootId }) {
  const user = useSelector((state) => state?.auth?.user);
  const [url, setUrl] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.common.loading);
  const fullName = useSelector((state) => state.auth.fullName);

  useEffect(() => {
    if (user && user.id && user.email && fullName) {
      Intercom({
        app_id: INTERCOM_APP_ID,
        name: fullName,
        email: user.email.toLowerCase(),
        user_hash: user.userHash,
      });
    }
  }, [user, fullName]);

  useEffect(() => {
    const fetchToken = async () => {
      await dispatch(setLoading(true));
      try {
        const response = await axios.post(BASE_URL + "/auth/getToken", {
          id: id,
        });
        const { data } = response;
        if (data.success) {
          await dispatch(setAuthToken(data.data.accessToken));
          await dispatch(setRefreshToken(data.data.refreshToken));
          await dispatch(setFullName(data.data.fullName));
          let decoded = jwtDecode(data.data.accessToken);
          await dispatch(setUser(decoded.user));
          await dispatch(setRole(decoded.user.roleid));
          if (decoded.user?.ImageName) {
            setUrl(`${IMAGE_URL}/${decoded.user.ImageName}.jpg`);
          } else {
            setUrl(null);
          }
          navigate(`/profile/${id}`);
        } else {
          console.error("Failed to log in:", data.message);
          // Handle login failure
        }
      } catch (error) {
        console.error("Error fetching token:", error);
        // Handle network or other errors
      } finally {
        await dispatch(setLoading(false));
      }
    };
    fetchToken();
  }, [dispatch, id]);

  useEffect(() => {
    if (user && "ImageName" in user) {
      if (user.ImageName) {
        setUrl(`${IMAGE_URL}/${user.ImageName}.jpg`);
      } else {
        setUrl(null);
      }
    } else {
      // Handle the case when 'ImageName' is not present in the user object
      setUrl(null); // or set to a default URL
    }
  }, [user]);

  if (isLoading) {
    return;
  }
  return (
    <></>
  );
}

export default ProfileDetailsStatic;
