import React from "react";
import MyLeadsRow from "./MyLeadsRow";

function LeadsTable({ data, ConstStatus, leadSource, change }) {
  return (
    <div className="min-h-[400px]">
      <table className="min-w-full mx-auto text-left z-0">
        <thead
          style={{ backgroundColor: "#E5E7EB" }}
          className="font-inter text-xs"
        >
          <tr className="">
            <th className="border-b border-gray-300  pl-2 pr-2 text-left">Name</th>
            <th className="border-b border-gray-300 pl-[0.5rem] pr-2 text-left">Lead Source</th>
            <th className="border-b border-gray-300 pl-[0.54rem] pr-2 text-left">Email</th>
            <th className="border-b border-gray-300 pl-[0.8rem] pr-2 text-left">Phone</th>
            <th className="border-b border-gray-300 pl-[0.28rem] pr-2 text-left">Lead Status</th>
            <th className="border-b border-gray-300  text-left"> Notes</th>
            <th className="border-b border-gray-300 p-4 text-left"></th>
          </tr>
        </thead>
        <tbody className="text-gray-700 font-inter text-sm font-medium leading-5">
          {data?.map((item, index) => (
            <MyLeadsRow
              key={index}
              data={item}
              index={index}
              leadSource={leadSource}
              change={change}
              ConstStatus={ConstStatus}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LeadsTable;
