import React, { useState, useEffect, useRef } from "react";
import Brand from "../../../assets/brand.png";
import Hamburger from "../../../assets/hamburger.png";
import TapUserIcon from "../../../assets/tap-user.svg";
import Line from "../../../assets/line.png";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Profile from "../../../assets/profile.svg";
import Bell from "../../../assets/bell.png";
import Settings from "../../../assets/settings.png";
import { ReactComponent as OutlookIcon } from "../../../assets/outlook.svg";
// import {ReactComponent as ReportsIcon} from "../../../assets/reports.svg";
import { ReactComponent as TeamsIcon } from "../../../assets/teams.svg";
import { ReactComponent as BookingsIcon } from "../../../assets/bookings.svg";
import { ReactComponent as ExcelIcon } from "../../../assets/excel.svg";
import { ReactComponent as BackOfficeIcon } from "../../../assets/BackOffice.svg";

import { GiArmorUpgrade } from "react-icons/gi";


import DropdownItem from "../dropdown/DropdownItem";
import Dropdown from "../dropdown/Dropdown";
import "./Header.css";
import { useSelector } from "react-redux";
import BaseShopDropDown from "../../table/BaseShopDropDown";
import BaseShopSearch from "../../table/BaseShopSearch";
import { BASE_URL, IMAGE_URL } from "../../../appconfig";
import { FaEllipsis } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import ChangePassword from "../../pages/backOffice/Tabs/Profile/Popups/ChangePassword";

const Header = () => {
  const premimumInfo = useSelector((state) => state?.auth?.premiumInfo);


  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [ProfileDropdown, setProfileDropdown] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const handlePassword = () => {
    setIsPopup(!isPopup);
  };
  const Fullname = useSelector((state) => state.auth.fullName);
  const role = useSelector((state) => state.auth.role);
  const agentData = useSelector((state) => state.agent.data);
  const user = useSelector((state) => state.auth.user);
  const [url, setUrl] = useState(null);
  const adminRoles = ["super_admin"];
  const navigate = useNavigate();
  const { AgentID } = useParams();

  const dropdownRef = useRef(null);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setProfileDropdown(false);
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const DownArrowIcon = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    if (user && "ImageName" in user) {
      if (user.ImageName) {
        setUrl(`${IMAGE_URL}/${user.ImageName}.jpg?${new Date().getTime()}`);
      } else {
        setUrl(null);
      }
    } else {
      // Handle the case when 'ImageName' is not present in the user object
      setUrl(null); // or set to a default URL
    }
  }, [user]);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  if (!role || !Fullname) {
    return;
  }

  const handleProfileClick = () => {
    navigate(
      `/profile/${encodeURIComponent(btoa(String(user.organizationId)))}`
    );
    setProfileDropdown(false);
  };

  return (
    <nav
      style={{ backgroundColor: "#4971B8", height: "3.5rem" }}
      className="flex items-center justify-between p-4 relative"
      id="nav-bar"
    >
      <div className="flex items-center relative" ref={menuRef}>
        <img
          style={{ height: "1.5rem" }}
          src={Hamburger}
          alt="menuicon"
          className="mr-4 cursor-pointer"
          onClick={toggleDropdown}
          id="hamburger-icon"
        />
        <img src={Brand} alt="brandlogo" style={{ height: "1.5rem" }} />
        {isDropdownOpen && (
          <div>
            <Dropdown fun={() => setDropdownOpen(false)}>
              <DropdownItem icon={<BackOfficeIcon />} text="Base Shop" />
              <DropdownItem icon={<OutlookIcon />} text="Outlook" />
              <DropdownItem icon={<TeamsIcon />} text="Teams" />
              <DropdownItem icon={<BookingsIcon />} text="Bookings" />
              <DropdownItem icon={<ExcelIcon />} text="Excel" />
              {/* <DropdownItem icon={<ReportsIcon />} text="Report" /> */}
            </Dropdown>
          </div>
        )}
      </div>
      {isPopup && <ChangePassword toggle={handlePassword} />}
      <div className="flex group items-center space-x-2 relative">
        {/* Removed BaseShopDropDown and Implemented BaseShopSearch */}
        {role && adminRoles.includes(role) && <BaseShopSearch />}
        {
          !premimumInfo?.isUpgraded || !premimumInfo?.isExpired ?
            (
              <GiArmorUpgrade title="Upgrade Now" className="text-2xl cursor-pointer text-[#FFCD29]" />
            ) : null

        }
        <img
          style={{ cursor: "pointer", marginLeft: "2rem", width: "1.5rem" }}
          src={Bell}
          alt="bellicon"
        />
        <img
          style={{ cursor: "pointer", marginLeft: "2rem", width: "1.5rem" }}
          src={Settings}
          alt="settings"
        />
        <img
          style={{ cursor: "pointer", marginLeft: "2rem" }}
          src={Line}
          alt=""
        />
        <div ref={dropdownRef}>
          <div
            className=" text-white relative text-lg cursor-pointer"
            onClick={() => setProfileDropdown(!ProfileDropdown)}
          >
            {ProfileDropdown && (
              <ul
                className=" absolute border-2 border-gray-300 right-0 top-6 mt-5  bg-white rounded-md shadow-lg z-[1000]"
                style={{ width: "20rem" }}
              >
                <div className="flex mt-4 ml-4">
                  <div className="mt-1">
                    {url ? (
                      <img
                        src={url}
                        alt="User"
                        style={{
                          width: "50px",
                          height: "50px",
                          flexShrink: 0,
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      />
                    ) : (
                      <img
                        style={{ cursor: "pointer", marginRight: "0.5rem" }}
                        src={TapUserIcon}
                        alt="profile"
                      />
                    )}
                  </div>

                  <div className="ml-2">
                    <span className=" text-neutral-800 text-2xl  font-semibold">
                      {Fullname && Fullname.toString()}
                    </span>

                    <div
                      className=" text-sm font-inter  cursor-pointer text-neutral-500 "
                      style={{ fontWeight: "400" }}
                    >
                      {user.email}
                    </div>
                    <div
                      className=" text-sm font-inter cursor-pointer text-neutral-500 "
                      style={{ fontWeight: "400" }}
                    >
                      Agent Code: {user.AgentID}
                    </div>
                  </div>
                </div>

                <li
                  onClick={handleProfileClick}
                  className="font-medium font-inter px-6 py-4 mt-2 cursor-pointer text-gray-600  hover:bg-green-400 text-base hover:text-white"
                >
                  Profile & Settings
                </li>

                {/* <li
                  onClick={() =>
                    window.open("https://myaccount.microsoft.com/", "_blank")
                  }
                  className="font-medium font-inter  px-6 py-4 cursor-pointer text-neutral-600  hover:bg-green-400 text-base hover:text-white"
                >
                  Manage account
                </li> */}

                <li
                  onClick={handleProfileClick}
                  className="font-medium font-inter  px-6 py-4 cursor-pointer text-neutral-600  hover:bg-green-400 text-base hover:text-white"
                >
                  Billing & Subscriptions
                </li>

                {user?.SignedInUsingEmail && (
                  <li
                    onClick={handlePassword}
                    className="font-medium font-inter px-6 py-4 cursor-pointer text-neutral-600 hover:bg-green-400 text-base hover:text-white"
                  >
                    Change Password
                  </li>
                )}

                {/* <li
                  onClick={() =>
                    window.open(
                      "https://salessmythmastermind.sharepoint.com/sites/customersuccess",
                      "_blank"
                    )
                  }
                  className="font-medium font-inter px-6 py-4 cursor-pointer text-neutral-600 hover:bg-green-400 text-base hover:text-white"
                >
                  Help & support
                </li> */}

                {/* <li
                  onClick={() =>
                    window.open("https://portal.office.com/", "_blank")
                  }
                  className=" font-medium font-inter  px-6 py-4 cursor-pointer text-neutral-600  hover:bg-green-400 text-base hover:text-white"
                >
                  Give feedback
                </li> */}

                <div className="py-4  px-6 cursor-pointer bg-slate-100 ">
                  <li className=" font-medium  py-3 cursor-pointer font-inter text-neutral-600  ">
                    Onboarding
                  </li>
                  <div className="flex justify-between font-medium text-gray-400 ">
                    <div>Progress</div>
                    <div>2%</div>
                  </div>

                  <div className="w-full mt-2">
                    <div className=" bg-white h-3 rounded-full mb-3">
                      <div
                        id="progressBar"
                        className="bg-green-400 h-full rounded-full"
                        style={{ width: "6%" }}
                      ></div>
                    </div>
                    {/* <input type="range" className="w-full appearance-none bg-green-500 rounded-full h-4" min="0" max="100" value="0" id="slider"/> */}
                  </div>
                </div>

                <li>
                  {user !== null && (
                    <div
                      onClick={() => navigate("/logout")}
                      className=" font-medium font-inter  px-6 py-4 cursor-pointer text-neutral-600  hover:bg-green-400 text-base hover:text-white"
                    >
                      Log Out
                    </div>
                  )}
                </li>

                <hr className="mx-6 " />

                <div className=" text-neutral-500 mx-6 font-inter text-sm font-normal my-4">
                  <a
                    href="https://wealthsmyth.com/terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-inter text-[13px] font-bold leading-[18px]"
                  >
                    Terms Of Service,
                  </a>{" "}
                  <a
                    href="https://wealthsmyth.com/privacy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-inter text-[13px] font-bold leading-[18px]"
                  >
                    Privacy Policy,
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://wealthsmyth.com/code-of-conduct/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-inter text-[13px] font-bold leading-[18px]"
                  >
                    Code of Conduct
                  </a>
                </div>
              </ul>
            )}

            <Link style={{ textDecoration: "none" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: ".5rem",
                }}
              >
                {url ? (
                  <img
                    src={url}
                    alt="User"
                    style={{
                      width: "32px",
                      height: "32px",
                      flexShrink: 0,
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  />
                ) : (
                  <img
                    style={{ cursor: "pointer", marginRight: "0.5rem" }}
                    src={TapUserIcon}
                    alt="profile"
                  />
                )}
                <div className="flex flex-col items-center justify-center">
                  <div className="flex items-center">
                    <span className="text-white text-sm font-semibold mr-2">
                      {Fullname && Fullname.toString()}
                    </span>
                    <DownArrowIcon />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
