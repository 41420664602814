// FileUpload.jsx

import React, { useState } from "react";
import { ReactComponent as UploadFileLogo } from "../../../../assets/upload.svg";
import axios from "axios";

const FileUpload = ({ setFileState }) => {


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileState(selectedFile);
  };

  return (
    <>
      <input
        type="file"
        id="fileInput"
        name="file"
        className="absolute opacity-0 border-none"
        onChange={handleFileChange}
        accept=".csv"
        style={{maxWidth: '120px'}}
      />
      <span className="flex gap-2 text-14 text-secondary font-inter font-medium text-14 leading-18 border-none">
        <UploadFileLogo />
        Import CSV
      </span>
    </>
  );
};

export default FileUpload;
