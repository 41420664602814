import React from 'react';
import PromotionTableRow from './PromotionTableRow.';
import { v4 as uuidv4 } from 'uuid';

const PromotionTable = ({ data, change }) => {
 
  return (
    <table className="promotion-table-container min-w-full mx-auto text-left text-sm font-light z-0">
      <thead className="promotion-table-head font-inter text-lg font-medium">
        <tr className="px-4 py-4">
          <th className="border-b border-gray-300 py-2 px-4">Name</th>
          <th className="border-b border-gray-300 py-2 px-4">Active</th>
          <th className="border-b border-gray-300 py-2 px-4">Current Status </th>
          <th className="border-b border-gray-300 py-2 px-4">Registration Date</th>   <th className="border-b border-gray-300 py-2 px-4">License Date</th>

          <th className="border-b border-gray-300 py-2 px-4" >Level</th>
          <th className="border-b border-gray-300 py-2 px-4"> Effective Date</th>
          <th className="border-b border-gray-300 py-2 px-4"></th>
        </tr>
      </thead>
      <tbody className="text-gray-700 font-inter text-sm font-medium leading-5">
      {data.map((entry) => ( 
        <PromotionTableRow key={uuidv4()} {...entry} change={change} />
))}     
      </tbody>
    </table>
  );
};

export default PromotionTable;