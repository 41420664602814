import React from 'react';

function Error({ accessError }) {
  return (
    <div>
      {accessError ? (
        <div className="bg-red-500 p-4 text-white">
          <p className="font-bold">Access Denied!</p>
          <p>You don't have access to this page.</p>
        </div>
      ) : (
        <>
          <h1>Error</h1>
        </>
      )}
    </div>
  );
}

export default Error;
