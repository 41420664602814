import React, { useState } from "react";
import applicationsInfoSvg from "../../../assets/Applicatonsinfo.svg";
import { Text, Dropdown, Info } from "./FormComponent";

const NewBusinessApplication = () => {
  const list = ["option1", "option2", "option3", "option1"];
  return (
    <div>
      <div className="flex flex-col m-10 gap-8 cursor-default">
        {/* Heading */}
        <div className="flex flex-col gap-3">
          <div className="text-gray-400 font-medium text-lg leading-5">
            Application
          </div>
          <div className="text-gray-800 font-semibold text-[28px] leading-8">
            New Application
          </div>
        </div>

        {/* info */}
        <div className="w-full flex flex-row items-center rounded-md border border-gray-300 p-6 gap-20 bg-white">
          <Info info={"Writing Agent"} name={"Writing Agent"} />
          <Info info={"Split Agent"} name={"Gary Gunnison"} />
          <Info info={"Provider"} name={"National Life Group"} />
          <Info info={"Health Assessor"} name={"Exam One"} />
          <div>
            <div className="text-gray-600 font-semibold text-base leading-5">
              Submitted Date
            </div>
            <div className="truncate py-1 whitespace-nowrap font-inter t">
              Nov 27, 2023
            </div>
          </div>
          <div>
            <div className="text-gray-600 font-semibold text-base leading-5">
              Issue Date
            </div>
            <div className="truncate py-1 whitespace-nowrap font-inter text-[#4871B7] underline h-8"></div>
          </div>
        </div>

        {/* new applicaton */}
        <div className=" flex flex-col gap-5 mt-2">
          <div className="text-gray-800 font-semibold text-2xl leading-[29.05px]">
            New Application
          </div>
          <div className="flex flex-row w-full gap-10">
            {/* left side Application form */}
            <div className="w-[70%] flex flex-col rounded-md border border-gray-300 p-6  bg-white gap-7">
              <div className="flex flex-row items-center gap-4 mt-3 justify-start text-gray-800 font-semibold text-xl leading-[24.2px] w-full text-center">
                Client Information <img src={applicationsInfoSvg} alt="" />
              </div>

              <div className="flex flex-col gap-4">
                <div className="text-gray-600 font-medium text-base leading-[19.36px]">
                  Who is the Client Team?
                </div>
                <div className="flex flex-row gap-10 w-full justify-between mb-2">
                  <div className="flex flex-col w-full">
                    <Text size="small-title" label="Writing Agent" />
                    <input
                      type="text"
                      placeholder="Search"
                      className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <Text size="small-title" label="Split Case?" />
                    <Dropdown
                      placeholder={"No"}
                      list={[
                        "Yes (10% / 90%)",
                        "Yes (20% / 80%)",
                        "Yes (30% / 70%)",
                        "Yes (10% / 10% / 80%)",
                        "Yes (10% / 20% / 70%)",
                        "Yes (10% / 30% / 60%)",
                      ]}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-10 w-full justify-between">
                  <div className="flex flex-col w-full">
                    <Text size="small-title" label="Split Agent 1" />
                    <input
                      type="text"
                      placeholder="Search"
                      className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <Text size="small-title" label="Split Agent 2" />
                    <input
                      type="text"
                      placeholder="Search"
                      className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full h-[1px] bg-gray-300 my-5"></div>

              <div className="flex flex-col gap-6">
                <div className="text-gray-600 font-medium text-base leading-[19.36px]">
                  Who will be the Policy Owner?
                </div>
                <div className="flex flex-row gap-10 w-full justify-between">
                  <div className="flex flex-col gap-3 w-full">
                    <div className="flex flex-row gap-2 items-center">
                      <input
                        type="radio"
                        id="choose-client"
                        name="policy-owner"
                        className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                      />
                      <Text
                        size={"mid-title"}
                        label={"Choose Existing Client"}
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Search for Client"
                      className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                    />
                  </div>
                  <div className="flex items-end mb-3 font-medium">OR</div>
                  <div className="flex flex-col gap-3 w-full">
                    <div className="flex flex-row gap-2 items-center">
                      <input
                        type="radio"
                        id="choose-client"
                        name="policy-owner"
                        className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                      />
                      <Text size={"mid-title"} label={"Create New Client"} />
                    </div>
                    <Dropdown placeholder={"Business"} list={list} />
                  </div>
                </div>
                <div className="flex flex-row justify-between gap-5 mt-5">
                  <div className="w-full">
                    <Text size="small-title" label="Business Name" />
                    <input
                      type="text"
                      placeholder="Business Name"
                      className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                    />
                  </div>
                  <div className="w-full">
                    <Text size="small-title" label="Entity Type" />
                    <Dropdown placeholder={"Entity Type"} list={list} />
                  </div>
                  <div className="w-full">
                    <Text size="small-title" label="State / Countrys" />
                    <Dropdown placeholder={"State / Country"} list={list} />
                  </div>
                </div>
              </div>

              <div className="w-full h-[1px] bg-gray-300 my-8"></div>

              <div>
                <div className="flex flex-col gap-6">
                  <div className="text-gray-600 font-medium text-base leading-[19.36px]">
                    Who will be the Primary Contact?
                  </div>
                  <div className="flex flex-row gap-10 w-full justify-between">
                    <div className="flex flex-col gap-3 w-full">
                      <div className="flex flex-row gap-2 items-center">
                        <input
                          type="radio"
                          id="choose-client"
                          name="primary-client"
                          className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                        />
                        <Text
                          size={"mid-title"}
                          label={"Choose Existing Client"}
                        />
                      </div>
                      <input
                        type="text"
                        placeholder="Search for Client"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                    <div className="flex items-end mb-3 font-medium">OR</div>
                    <div className="flex flex-col gap-3 w-full">
                      <div className="flex flex-row gap-2 items-center">
                        <input
                          type="radio"
                          id="choose-client"
                          name="primary-client"
                          className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                        />
                        <Text size={"mid-title"} label={"Create New Client"} />
                      </div>
                      <Dropdown placeholder={"Business"} list={list} />
                    </div>
                  </div>
                  <div className="flex flex-row gap-10 w-full justify-between">
                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text size="small-title" label="First Name" />
                      </div>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                    <div className="flex items-end mb-3 font-medium w-10"></div>

                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text size="small-title" label="Last Name" />
                      </div>
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-10 w-full justify-between">
                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text size="small-title" label="Email Address" />
                      </div>
                      <input
                        type="text"
                        placeholder="Email Address"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                    <div className="flex items-end mb-3 font-medium w-10"></div>

                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text size="small-title" label="Mobile Phone" />
                      </div>
                      <input
                        type="text"
                        placeholder="Mobile Phone"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full h-[1px] bg-gray-300 my-12"></div>

                <div className="flex flex-col gap-6 my-8">
                  <div className="text-gray-600 font-medium text-base leading-[19.36px]">
                    Who will be the Insured?
                  </div>
                  <div className="flex flex-row gap-10 w-full justify-between">
                    <div className="flex flex-col gap-3 w-full">
                      <div className="flex flex-row gap-2 items-center">
                        <input
                          type="radio"
                          id="choose-client"
                          name="client"
                          className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                        />
                        <Text
                          size={"mid-title"}
                          label={"Choose Existing Client"}
                        />
                      </div>
                      <Dropdown placeholder={"Search for Client"} list={list} />
                    </div>
                    <div className="flex items-end mb-3 font-medium">OR</div>
                    <div className="flex flex-col gap-3 w-full">
                      <div className="flex flex-row gap-2 items-center">
                        <input
                          type="radio"
                          id="choose-client"
                          name="client"
                          className="form-radio text-blue-600 bg-[#F3F4F6] border border-gray-300"
                        />
                        <Text size={"mid-title"} label={"Create New Client"} />
                      </div>
                      <Dropdown
                        placeholder={"Choose Account Type"}
                        list={list}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-10 w-full justify-between">
                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text size="small-title" label="First Name" />
                      </div>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                    <div className="flex items-end mb-3 font-medium w-10"></div>

                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text size="small-title" label="Last Name" />
                      </div>
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-10 w-full justify-between">
                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text size="small-title" label="Email Address" />
                      </div>
                      <input
                        type="text"
                        placeholder="Email Address"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                    <div className="flex items-end mb-3 font-medium w-10"></div>

                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text size="small-title" label="Mobile Phone" />
                      </div>
                      <input
                        type="text"
                        placeholder="Mobile Phone"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-10 w-full justify-between">
                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text
                          size="small-title"
                          label="State / Country of Residence"
                        />
                      </div>
                      <input
                        type="text"
                        placeholder="State / Country of Residence"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                    <div className="flex items-end mb-3 font-medium w-10"></div>

                    <div className="flex flex-col w-full">
                      <div className="pl-2">
                        <Text size="small-title" label="Date of Birth" />
                      </div>
                      <input
                        type="text"
                        placeholder="Date of Birth"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full h-[1px] bg-gray-300 mt-12"></div>

                <div className="text-gray-800 font-semibold text-xl leading-[24.2px] w-full text-start mt-9 mb-5">
                  Policy information
                </div>
                <div className="flex flex-col gap-6">
                  <div>
                    <Text size="small-title" label="Provider" />
                    <input
                      type="text"
                      placeholder="National Life Group"
                      className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                    />
                  </div>
                  <div>
                    <Text size="small-title" label="Policy Number" />
                    <input
                      type="text"
                      placeholder="Policy Number"
                      className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                    />
                  </div>
                  <div>
                    <Text size="small-title" label="Product" />
                    <Dropdown placeholder={"Select"} list={list} />
                  </div>
                  <div>
                    <Text size="small-title" label="Application Status" />
                    <Dropdown placeholder={"New"} list={list} />
                  </div>
                  <div>
                    <Text size="small-title" label="Date Submitted" />
                    <Dropdown placeholder={"Select"} list={list} />
                  </div>

                  <div className="flex flex-row justify-between gap-5">
                    <div className="w-full">
                      <Text size="small-title" label="Target Premium" />
                      <input
                        type="text"
                        placeholder="Value"
                        className="flex items-center h-[44px] bg-[#F3F4F6] rounded-md p-[12px_16px] gap-[10px] w-full text-gray-700 font-medium text-sm leading-5 border-none outline-none focus:ring-0"
                      />
                    </div>
                    <div className="w-full">
                      <Text size="small-title" label="Premium Mode" />
                      <Dropdown placeholder={"Select"} list={list} />
                    </div>
                    <div className="w-full">
                      <Text size="small-title" label="Bank Draft Terms" />
                      <Dropdown placeholder={"Select"} list={list} />
                    </div>
                  </div>

                  <div className="w-full h-[1px] bg-gray-300 mt-4"></div>

                  <div className="flex flex-row gap-6 flex-wrap my-4">
                    <button
                      style={{
                        background:
                          "linear-gradient(281.44deg, #4971B8 11.19%, #2BB673 103.48%)",
                      }}
                      className="w-[11.625rem] h-[3.19rem] bg-transparent text-white text-sm font-semibold hover:text-white py-2 px-4 mr-4 border  rounded font-inter"
                    >
                      Save
                    </button>
                    <button className="w-[11.625rem] h-[3.19rem] bg-transparent  text-sm font-semibold hover:text-white py-2 px-4 mr-4 border border-[#2BB574] text-[#2BB574]  rounded font-inter">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* right side Account about */}
            <div className="w-[30%] h-fit rounded-md border border-gray-300 p-6 bg-white">
              <div className="">
                <div className="text-gray-600 font-semibold text-base leading-[19.36px] ">
                  Accounts
                </div>
                <div className="w-full h-[1px] bg-gray-300 my-5"></div>
                <div className="text-gray-400 font-normal text-sm leading-[18px] mb-5">
                  All clients have an account for themselves and a parent
                  account
                </div>
                <div className="text-gray-500 font-semibold text-[13px] leading-[18px]">
                  Account:
                </div>
                <div className="text-gray-400 font-normal text-sm leading-[18px] mb-5">
                  An individual’s client account.
                </div>
                <div className="text-gray-500 font-semibold text-[13px] leading-[18px]">
                  Parent Account:
                </div>
                <div className="text-gray-400 font-normal text-sm leading-[18px] mb-5">
                  A group of individual accounts, for a household or business.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBusinessApplication;
