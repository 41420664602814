import React, { useEffect, useState } from "react";
import Spinner from "../../../common/Spinner";
import useApi from "../../../../custom-hooks/useApi";
import { BASE_URL } from "../../../../appconfig";

const ImportHistoryPopUp = ({ toggle }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const MAX_VISIBLE_PAGES = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [totalcount, setTotalcount] = useState(0);

  const handleDownloadErrorFile = async (leadId) => {
    await getDownloadFileSasUrl("error", leadId);
  };

  const handleDownloadImportedFile = async (leadId) => {
    await getDownloadFileSasUrl("imported", leadId);
  };

  const getDownloadFileSasUrl = async (type, leadId) => {
    let downloadApiUrl = `/Campaign/Lead/${leadId}/`;
    if (type === "error") {
      downloadApiUrl += "DownloadLeadErrorCsv";
    } else {
      downloadApiUrl += "DownloadLeadImportCsv";
    }

    let url = "";

    try {
      const response = await api.get(downloadApiUrl);
      if (response.status === 200 && response.data.url) {
        url = response.data.url;
      } else {
        url = "";
      }
    } catch (error) {
      console.error("Failed to fetch template url: ", error.message);
      url = "";
    }

    if (!url || url === "") {
      return "Couldn't get download url.";
    }

    try {
      const a = document.createElement("a");
      a.href = url;
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      console.log("Failed downloading file, reason: ", error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`${BASE_URL}/Campaign/LeadsImport?pageNumber=${currentPage}&pageSize=${itemsPerPage}`);
        setData(response.data?.history);
        setTotalcount(response?.data?.totalcount);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage]);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const calculateVisiblePages = () => {
      const pages = [];
      const startPage = Math.max(
        1,
        currentPage - Math.floor(MAX_VISIBLE_PAGES / 2)
      );
      const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      const remainingPages = MAX_VISIBLE_PAGES - pages.length;

      if (startPage > 1 && endPage < totalPages) {
        const padStart = Math.min(startPage - 1, remainingPages);
        const padEnd = Math.min(
          totalPages - endPage,
          remainingPages - padStart
        );

        for (let i = startPage - padStart; i >= 1; i--) {
          pages.unshift(i);
        }

        for (let i = endPage + 1; i <= endPage + padEnd; i++) {
          pages.push(i);
        }
      }

      return pages;
    };

    const visiblePageNumbers = calculateVisiblePages();

    if (visiblePageNumbers.length > 1) {
      return (
        <>
          <div className="flex items-center mr-2">
            {currentPage > 1 && currentPage <= totalPages && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 cursor-pointer"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            )}
            {visiblePageNumbers.map((pageNumber, index) => (
              <button
                key={`page-${pageNumber}-${index}`}
                style={{
                  borderRadius: "3rem",
                  backgroundColor:
                    currentPage === pageNumber ? "#3182CE" : "white",
                  color: currentPage === pageNumber ? "white" : "black",
                }}
                type="button"
                onClick={() => handlePageChange(pageNumber)}
                className="font-inter mt-2 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 me-2 mb-2 dark:hover:bg-gray-800 focus:outline-none dark:focus:ring-blue-800"
              >
                {pageNumber}
              </button>
            ))}
            {totalPages > 1 && currentPage < totalPages && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 cursor-pointer"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            )}
          </div>
        </>
      );
    }
  };
  const totalPages = Math.ceil(totalcount / itemsPerPage);

  const formatDateString = (dateString) => {
    if (!dateString) {
      return null;
    }
    const date = new Date(dateString);
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear().toString()} ${date.getHours() > 12 ? date.getHours() - 12 : date.getHours()}:${date.getMinutes().toString().padStart(2, "0")} ${date.getHours() >= 12 ? "PM" : "AM"}`;
    return formattedDate;
};



  const HistoryTableRow = ({ data }) => {
    return (
      <tr
        key={`the key is ${data.ID}`}
        className="border-b dark:border-neutral-500"
      >
        <td className="truncate py-6 px-2 whitespace-nowrap font-inter">
          <span
            className="underline decoration-blue-600 cursor-pointer"
            onClick={() => handleDownloadImportedFile(data.ID)}
          >
            {data.File_Path.split("-").slice(2).join("-")}
          </span>
        </td>
        <td className="truncate py-6 px-2 whitespace-nowrap font-inter">
          <span>{formatDateString(data.createdAt)}</span>
        </td>
        <td className="truncate py-6 pl-4 whitespace-nowrap font-inter">
          <span>{data.IsFileProcessed ? "Processed" : "Not-Processed"}</span>
        </td>
        <td className="truncate py-6 pl-[1.8rem] whitespace-nowrap font-inter">
          <span className="truncate whitespace-normal">
            {data.FileUploadComment && data.FileUploadComment.match(/[0-9/]+/g)
              ? data.FileUploadComment.match(/[0-9/]+/g)[0]
              : data.FileUploadComment
              ? data.FileUploadComment
              : "-"}
          </span>
        </td>

       

        <td className="truncate py-6 pl-[1.595rem] pr-1 whitespace-nowrap font-inter ">
          <span
            className="underline decoration-blue-600 cursor-pointer"
            onClick={() => handleDownloadErrorFile(data.ID)}
          >
            {data?.HasErrorFile
              ? data.File_Path.split("-")
                  .slice(2)
                  .join("-")
                  .split(".csv")
                  .slice(0)
                  .join("-Error.csv")
              : ""}
          </span>
        </td>
      </tr>
    );
  };

  return (
    <div>
      {loading && <Spinner />}
      <div
        className="fixed inset-0 flex items-center justify-center text-left bg-gray-800 bg-opacity-50 z-20 w-50 h-50"
        style={{ margin: 0 }}
      >
        <div
          className="bg-white p-8 rounded shadow-md relative"
          style={{ width: "60%" }}
        >
          <div className="flex items-center justify-between mb-5">
            <h2 className="text-black font-inter text-2xl font-medium">
              Import History
            </h2>
        
            <button
              onClick={() => toggle()}
              className="text-gray-600 hover:text-blue-500 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          {data.length > 0 ? (
            <table className="min-w-full mx-auto text-left z-0">
              <thead
                style={{ backgroundColor: "#E5E7EB" }}
                className="font-inter text-xs"
              >
                <tr className="">
                  <th className="border-b border-gray-300 py-4 px-2 text-left">
                    File Name
                  </th>
                  <th className="border-b border-gray-300 py-4 px-2 pl-[0.6rem] text-left">
                    Date & Time
                  </th>
                  <th className="border-b border-gray-300 py-4 px-2 pl-[1.1rem] text-left">
                    Status
                  </th>
                  <th className="border-b border-gray-300 py-4 px-2 pl-[1.7rem] text-left">
                    Processed Records
                  </th>
                  <th className="border-b border-gray-300 py-4  pl-5 text-left">
                    Error Details
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-700 font-inter text-sm font-medium leading-5">
                {data?.map((item, index) => (
                  <HistoryTableRow key={index} data={item} />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center w-full p-4">
              No data available ....!
            </div>
          )}
          {totalPages > 0 && (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportHistoryPopUp;
