import { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../appconfig";
import { setAuthToken } from "../redux/slices/authSlice";
import useRefreshAuthToken from "./useRefreshAuthToken";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  timeout: 60000, // 60 seconds
});

const useApi = () => {
  const authToken = useSelector((state) => state.auth.authToken);
  const dispatch = useDispatch();
  const refreshAuthToken = useRefreshAuthToken();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [requestQueue, setRequestQueue] = useState([]);

  const processQueue = (token) => {
    setIsRefreshing(false);
    dispatch(setAuthToken(token));

    // Update the Authorization header for subsequent requests
    api.defaults.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

    // Process queued requests
    while (requestQueue.length > 0) {
      const queuedRequest = requestQueue.shift();

      // Update the Authorization header for queued requests
      queuedRequest.config.headers.Authorization = `Bearer ${localStorage.getItem(
        "accessToken"
      )}`;

      // Reattempt the failed request
      api(queuedRequest.config)
        .then((response) => queuedRequest.resolve(response))
        .catch((error) => queuedRequest.reject(error));
    }
  };

  // Add a request interceptor to include headers
  api.interceptors.request.use(async (config) => {
    let token = authToken;
    if (!authToken) {
      token = localStorage.getItem("accessToken");
    }

    config.headers.Authorization = `Bearer ${token}`;

    // If token is being refreshed, add the request to the queue
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        setRequestQueue((prevQueue) => [
          ...prevQueue,
          { config, resolve, reject },
        ]);
      });
    }

    return config;
  });

  // Add a response interceptor to handle unauthorized responses
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          setIsRefreshing(true);

          try {
            const response = await refreshAuthToken();
            if (response && response.data.accessToken) {
              dispatch(setAuthToken(response.data.accessToken));
              // If token is being refreshed, add the request to the queue
              setTimeout(() => {
                processQueue(response.data.accessToken);
              }, 1000);
              api.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`;
              return api(originalRequest);
              
            } else {
              console.log("Couldn't refresh token. Logging out");
              // Handle the case where token refresh fails
              toast.error("Session Expired. Logging out", { theme: "dark" });
              window.location.href = "/logout";
              return Promise.reject(error);
            }
          } catch (refreshError) {
            console.error("Error during token refresh: ", refreshError);
            // Handle the case where token refresh fails
            toast.error("Session Expired. Logging out", { theme: "dark" });
            window.location.href = "/logout";
            return Promise.reject(error);
          } finally {
            setIsRefreshing(false);

          }
        }

        
      } else if (
        error.response &&
        error.response.status === 500 &&
        (error.response.data.message === "jwt malformed" ||
          error.response.data.message === "jwt expired")
      ) {
        console.error("Jwt Malformed: Logging out for security reasons.");
        toast.error("Jwt Malformed: Logging out for security reasons.", {
          theme: "dark",
        });
        window.location.href = "/logout";
      } else {
        return Promise.reject(error);
      }
    }
  );

  return api;
};

export default useApi;
