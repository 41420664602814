// authMiddleware.js

const authMiddleware = () => (next) => async (action) => {
  if (action.payload && action.payload.status === 401) {
    // Handle unauthorized access
    // Example: redirect to login page
    window.location.href = `/`;
    return;
  }

  // Pass the action to the next middleware or reducer
  return next(action);
};

export default authMiddleware;
