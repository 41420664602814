import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logoutApp } from "./redux/slices/authSlice";
import Brand from "./assets/brand.png";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "./appconfig";
import Spinner from "./components/common/Spinner";
import { useNavigate } from "react-router-dom";
import { shutdown } from "@intercom/messenger-js-sdk";

const Logout = () => {
  const dispatch = useDispatch();
   const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const logoutUser = async () => {
      setLoading(true);
      try {
        const reqBody = {
          RefreshToken: localStorage.getItem("refreshToken"),
        };
        const headers = {
          withCredentials: true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`, 
        };
        const response = await axios.post(`${BASE_URL}/auth/logout`, reqBody, {headers});
        if (response.status === 200) {
          // Successful logout, you can perform additional client-side cleanup if needed
          console.log("LoggedOut successfully from backend");
        } else {
          // Handle logout failure, show error message
          toast.error(response.data.message, { theme: "dark" });
        }
      } catch (error) {
        // toast.error(error.response.data.message, { theme: "dark" });
      } finally {
        shutdown();
        await dispatch(logoutApp());
        navigate("/");
        setLoading(false);
      }
    };
    if (
      localStorage.getItem("refreshToken") &&
      localStorage.getItem("accessToken")
    )
      logoutUser();
  },[dispatch, navigate]);

  if(loading) {
    return <Spinner />;
  }

  return (
    <>
      <div
        className="min-w-screen min-h-screen"
        style={{ background: "linear-gradient(to right, #E9E9E9, #F6F6F6)" }}
      >
        <div className="flex justify-center items-center h-screen">
          <div className="bg-white p-8 shadow-md rounded-md">
            <div
              className="flex items-center justify-center mb-6"
              style={{ backgroundColor: "#4971B8", height: "3.5rem" }}
            >
              <img src={Brand} alt="brandlogo" style={{ height: "1.5rem" }} />
            </div>
            <p className="font-inter font-medium text-base">
              Logged out from the application. You can now close the browser
              window.
            </p>
            <p className="my-2 font-inter text-center font-semibold text-base">
              OR
            </p>
            <div className="flex items-start justify-center">
              <a
                href="/"
                style={{ backgroundColor: "#4971B8" }}
                className="text-white px-4 py-2 shadow-md rounded-md"
              >
                Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logout;
