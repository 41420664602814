import axios from "axios";
import { BASE_URL } from "../appconfig";

const useRefreshAuthToken = () => {
  const refreshAuthToken = async () => {
    try {
      const headers = {
        withCredentials: true,
        "Content-Type": "application/json",
      };
      const requestBody = {
        refreshToken: localStorage.getItem("refreshToken"),
      };
      const resp = await axios.post(
        `${BASE_URL}/auth/RefreshToken`,
        requestBody,
        { headers }
      );
      console.log("refreshed token");
      return resp;
    } catch (e) {
      console.error("Error while refreshing token", e);
      return e.response ? e.response : null;
    }
  };

  return refreshAuthToken;
};

export default useRefreshAuthToken;
