import React from "react";
import ApplicationRow from "./ApplicationRow";

function ApplicationTable({ data, change, fetchClientsData}) {
  return (
    <div className="min-h-[400px] mt-7">
      <table className="min-w-full mx-auto text-left z-0">
        <thead
          style={{ backgroundColor: "" }}
          className=" text-sm  text-[#9CA3AF]"
        >
          <tr className="">
            <th className="border-b border-gray-200  pl-2 pr-2 text-left pb-3 font-medium"></th>
            <th className="border-b border-gray-200  pl-2 pr-2 text-left pb-3 font-medium">Application Name</th>
            <th className="border-b border-gray-200 pl-[0.5rem] pr-2 text-left pb-3 font-medium">Writing Agent</th>
            <th className="border-b border-gray-200 pl-[0.54rem] pr-2 text-left pb-3 font-medium">State</th>
            <th className="border-b border-gray-200 pl-[0.54rem] pr-2 text-left pb-3 font-medium">Provider</th>
            <th className="border-b border-gray-200 pl-[0.54rem] pr-2 text-left pb-3 font-medium">Policy #</th>
            <th className="border-b border-gray-200 pl-[0.8rem] pr-2 text-left pb-3 font-medium">Target Premium</th>
            <th className="border-b border-gray-200  text-left pb-3 font-medium">Status</th>
            <th className="border-b border-gray-200 pl-[0.28rem] pr-2 text-left pb-3 font-medium"></th>
          </tr>
        </thead>
        <tbody className="text-gray-700 font-inter text-sm font-medium leading-5  mt-8">
          {data?.map((item, index) => (
            <ApplicationRow
              key={index}
              data={item}
              index={index}
              change={change}
              fetchClientsData={fetchClientsData}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ApplicationTable;
