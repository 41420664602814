// store.js
import { configureStore } from "@reduxjs/toolkit";
import {thunk} from "redux-thunk"; // Import the 'thunk' function directly
import rootReducer from "./redux/reducers/rootReducer";
import authMiddleware from "./redux/middlewares/authMiddleware";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleware, thunk),
  // Add other options as needed
});

export default store;
