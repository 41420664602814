import React, { useState, useEffect, useRef } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { ReactComponent as SaveIcon } from "../../../../src/assets/save.svg";
import { ReactComponent as CancelIcon } from "../../../../src/assets/cancel.svg";
import { toast } from "react-toastify";
import useApi from "../../../custom-hooks/useApi";
import Spinner from "../../common/Spinner";
import { BASE_URL } from "../../../appconfig";

function FinanceAccount({
  accountList,
  relationShipList,
  productList,
  reloadItems,
  newForm,
  setEdit
}) {
  const api = useApi();

  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef(null);
  const editButtonRefs = useRef([]);
  const deleteButtonRefs = useRef([]);

  const handleDropdown = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleEdit = async (index, accountItem) => {
    setEditIndex(index);
    setEdit();
    setEditData({ ...accountItem });
  };

  const handleCancelEdit = () => {
    setEditIndex(null);
    setEditData({});
  };

  const handleSaveEdit = async () => {
    try {
      setLoading(true);
      const response = await api.put(
        `${BASE_URL}/Account/FinancialAccount/${editData?.FinancialAccountID}`,
        editData
      );
      if (response?.status === 200) {
        setEditIndex(null);
        toast.success(response?.data?.message);
        reloadItems();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  useEffect(()=>{
    handleCancelEdit();
  },[newForm])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleDelete = async (FinancialAccountID) => {
    try {
      setLoading(true);
      const response = await api.delete(
        `${BASE_URL}/Account/FinancialAccount/${FinancialAccountID}`
      );
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        reloadItems();
        setDropdownIndex(null);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !editButtonRefs.current.some(ref => ref && ref.contains(event.target)) &&
        !deleteButtonRefs.current.some(ref => ref && ref.contains(event.target))
      ) {
        setDropdownIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <tbody>
      {loading && <Spinner />}
      {accountList?.length > 0
        ? accountList.map((accountItem, index) => (
            <tr
              key={index}
              className={`border-[#D1D5DB] ${
                index + 1 === accountList?.length ? "" : "border-b"
              }`}
            >
              <td className="p-2 text-sm text-[#3A4253] font-inter font-normal">
                {editIndex === index ? (
                  <div className="flex gap-2 ">
                    <input
                      type="text"
                      name="AccountName"
                      value={editData.AccountName}
                      onChange={handleInputChange}
                      className="w-full appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                    />
                  </div>
                ) : (
                  accountItem?.AccountName
                )}
              </td>
              <td className="p-4 text-sm text-[#3A4253] font-normal font-inter ">
                {editIndex === index ? (
                  <select
                    name="AccountType"
                    value={editData?.ProductTypeID}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        ProductTypeID: e.target.value,
                      })
                    }
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                  >
                    {productList?.map((productItem) => (
                      <option
                        key={productItem?.ProductTypeID}
                        value={productItem?.ProductTypeID}
                      >
                        {productItem?.ProductTypeName}
                      </option>
                    ))}
                  </select>
                ) : (
                  accountItem?.ProductType?.ProductTypeCode +
                  " " +
                  accountItem?.ProductType?.AccountType
                )}
              </td>
              <td className="p-2 text-sm text-[#3A4253] font-inter font-normal">
                {editIndex === index ? (
                  <input
                    type="number"
                    name="Contribution"
                    value={editData.Contribution}
                    onChange={handleInputChange}
                    className="appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                  />
                ) : (
                  `$ ${accountItem?.Contribution}`
                )}
              </td>
              <td className="p-4 text-sm text-[#3A4253] font-inter font-normal">
                {editIndex === index ? (
                  <input
                    type="text"
                    name="Balance"
                    value={editData.Balance}
                    onChange={handleInputChange}
                    className="appearance-none block bg-neutral-200 text-neutral-700 text-sm border-0 leading-tight focus:outline-none focus:bg-white"
                  />
                ) : (
                  `$ ${accountItem?.Balance}`
                )}
              </td>
              <td className="p-2 text-sm text-[#3A4253] font-inter font-normal">
                {editIndex === index ? (
                  <select
                    name="ManagedBy"
                    value={editData?.ManagedBy}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        ManagedBy: e.target.value,
                      })
                    }
                    className="appearance-none block w-full bg-neutral-100 text-neutral-700 text-sm border-0 py-2.5 px-4 leading-tight focus:outline-none"
                  >
                    {relationShipList?.map((relationShipItem, index) => (
                      <option key={index} value={relationShipItem?.Relation}>
                        {relationShipItem?.Relation}
                      </option>
                    ))}
                  </select>
                ) : (
                  accountItem?.ManagedBy
                )}
              </td>
              <td
                className="relative p-4 font-medium text-[#4871B7] cursor-pointer"
                ref={(el) => (dropdownRef.current = el)}
              >
                {editIndex === index ? (
                  <div className="flex gap-2">
                    <button
                      className="disabled:opacity-50 disabled:cursor-not-allowed"
                      onClick={handleSaveEdit}
                    >
                      <SaveIcon />
                    </button>
                    <CancelIcon
                      className="cursor-pointer"
                      onClick={handleCancelEdit}
                    />
                  </div>
                ) : (
                  <>
                    <FiMoreHorizontal onClick={() => handleDropdown(index)}
                       />
                    {dropdownIndex === index && (
                      <div className="absolute right-0 mt-1 w-48 bg-white">
                        <div className="absolute z-10 w-auto bg-white border rounded shadow-lg mr-10 right-0">
                          <ul className="border rounded text-gray-600">
                            <li
                              className="edit-button border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                              onClick={() => handleEdit(index, accountItem)}
                              ref={(el) => editButtonRefs.current[index] = el}
                            >
                              Edit
                            </li>
                            <li
                            onClick={() =>
                                handleDelete(accountItem?.FinancialAccountID)
                              }
                              ref={(el) => deleteButtonRefs.current[index] = el}
                              className="delete-button border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))
        : null}
    </tbody>
  );
}

export default FinanceAccount;
