import { ReactComponent as HomeIcon } from "../../assets/home.svg";
// import { ReactComponent as TeamsIcon } from "../../assets/side_teams.svg";
// import { ReactComponent as CampaignsIcon } from "../../assets/campaign.svg";
import { ReactComponent as UsersIcon } from "../../assets/users.svg";
// import { ReactComponent as LeadsIcon } from "../../assets/leads.svg";
// import { ReactComponent as BackOfficeIcon } from "../../assets/back-office.svg";
// import { ReactComponent as LogoutIcon } from "../../assets/Logout.svg";
import { ReactComponent as TeamsIcon } from "../../assets/team.svg";
import { ReactComponent as ClientsIcon } from "../../assets/clients.svg";
import { ReactComponent as CampaignsIcon } from "../../assets/campaigns.svg";
import { ReactComponent as BackOfficeIcon } from "../../assets/back-office.svg";
import { ReactComponent as AppsIcon } from "../../assets/apps.svg";
import { ReactComponent as ShareIcon } from "../../assets/share-2.svg";
import { ReactComponent as LeadsIcon } from "../../assets/leads.svg";
import { ReactComponent as Applications } from "../../assets/fluent_shield-task-16-regular.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SidebarMenu = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const userRole = useSelector((state) => state.auth.role);
  const user = useSelector((state) => state.auth.user);
  const premimumInfo = useSelector((state) => state?.auth?.premiumInfo);
  const adminRoles = useSelector((state) => state.auth.adminRoles);
  const navigate = useNavigate();
  // const [togle , setTogle] = useState(false)

  useEffect(() => {
    setIsAdmin(adminRoles.includes(userRole));
  }, [adminRoles, userRole]);


  function handleProfileClick() {
    if (user && user.Role !== "super_admin") {
      navigate(
        `/agent/${encodeURIComponent(
          btoa(String(user.organizationId))
        )}`
      )
    }
    else if (user && user.Role === "super_admin") {
      navigate("/super-admin")
    }
  }

  return (
    <>
      <aside
        style={{ background: "var(--Neutral-900, #111827)" }}
        className="flex flex-col h-full bg-black text-white"
      >
        {/* Sidebar Items */}
        {user !== null && (
          <div
            onClick={handleProfileClick}
            className="flex flex-col gap-2 items-center py-4  text-xs hover:bg-green-500"
          >
            <HomeIcon className="w-6 h-6" />
            Home
          </div>
        )}

        {user !== null && (
          <div
            onClick={() => navigate("/dashboard")}
            className="flex flex-col gap-2 items-center py-4  text-xs hover:bg-green-500"
          >
            <TeamsIcon className="w-6 h-6" />
            Team
          </div>
        )}

        {user !== null && (
          <div
            onClick={() => navigate("/leads")}
            className="flex flex-col gap-2 items-center py-4  text-xs hover:bg-green-500"
          >
            <LeadsIcon className="w-6 h-6" />
            Leads
          </div>
        )}

        {user !== null && (
          <>
            {
              premimumInfo?.isUpgraded && !premimumInfo?.isExpired ?
                (
                  <div
                    onClick={() => navigate("/campaigns")}
                    className="flex flex-col gap-2 items-center py-4  text-xs hover:bg-green-500"
                  >
                    <CampaignsIcon className="w-6 h-6" />
                    Campaigns
                  </div>
                ) : null
            }
          </>
        )}

        {user !== null && (
          <div
            onClick={() => navigate("/clients")}
            className="flex flex-col gap-2 items-center py-4  text-xs hover:bg-green-500"
          >
            <ClientsIcon className="w-6 h-6" />
            Clients
          </div>
        )}
        {user !== null && (
          <div
            onClick={() => navigate("/applications")}
            className="flex flex-col gap-2 items-center py-4  text-xs hover:bg-green-500"
          >
            <Applications className="w-6 h-6" />
            Applications
          </div>
        )}
        {user !== null && (
          <div
            onClick={() => navigate("/pipeline")}
            className="flex flex-col gap-2 items-center py-4  text-xs hover:bg-green-500"
          >
            <AppsIcon style={{ fill: 'red' }} className="w-6 h-6" />
            Pipeline
          </div>
        )}

        {user !== null && (
          <>
            {
              premimumInfo?.isUpgraded && !premimumInfo?.isExpired ?
                (
                  <div
                    onClick={() => navigate("/share")}
                    className="flex flex-col gap-2 items-center py-4  text-xs hover:bg-green-500"
                  >
                    <ShareIcon className="w-6 h-6" />
                    Share
                  </div>
                ) : null
            }
          </>

        )}

        {isAdmin && (
          <div
            onClick={() => navigate("/back-office")}
            className="flex flex-col gap-2 items-center py-4  text-xs hover:bg-green-500"
          >
            <BackOfficeIcon className="w-6 h-6" />
            <div className=" text-center">Back Office</div>
          </div>
        )}

        {/* { user !== null && <div
         onClick={()=> navigate("/logout")}
        className="flex flex-col gap-2 items-center py-4  text-xs hover:bg-green-500 logout">
          <LogoutIcon className="w-6 h-6" />
          Logout
        </div> } */}
      </aside>
    </>
  );
};

export default SidebarMenu;
