import React from "react";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { ReactComponent as MoreIcon } from "../../../assets/more-horizontal.svg";
import { useNavigate } from "react-router-dom";
import { formatUSPhoneNumber } from "../../../utills";

function ClientsRow({ data, index, isDropdownOpen, handleMoreClick }) {
    const navigate = useNavigate();
    const handleEditClick = (AccountID) => {
        navigate("/clients/edit", { state: { AccountID } })
    };

    const goToClientProfile = (AccountID, ContactID) => {
        navigate("/client", {
            state: {
                AccountID,
                ContactID

            }
        })
    }

    const goToAgent = (AccountID) => {
        navigate("/profile")
    }

    return (
        <tr
            key={`the key is ${data.ID}`}
            className="border-b dark:border-neutral-500"
        >
            <td className="w-[3%] ps-2 truncate whitespace-nowrap font-inter ">
                <input
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-black-900 focus:ring-2 dark:bg-black-900 dark:border-black-900"

                /></td>
            <td className="w-[3%] pt-1 truncate whitespace-nowrap font-inter ">
                <span>{`${index + 1}`}</span>
            </td>

            {
                index === 0 ? (
                    <td
                        className="w-[180px] truncate whitespace-nowrap  font-inter  cursor-pointer"
                        data-tooltip-id={index !== 0 ? undefined : `tool-${data.ID}`}
                    >
                        <div className="flex">
                            <span className="text-[#4871B7] underline"
                                onClick={() => {
                                    goToClientProfile(data?.AccountID, data?.ContactID)
                                }}
                            >{`${data?.FirstName} ${data?.LastName}`}</span>

                            <span className="text-[#F3F4F6] " onClick={() => {
                                goToAgent(data?.AccountID)
                            }} >AGENT PROFILE </span>
                        </div>

                        <Tooltip
                            id={`tool-${data.ID}`}
                            place="top"
                            effect="solid"
                            backgroundColor="black"
                            textColor="#FFFFFF"
                            className="custom-tooltip-0 z-index"
                        >
                            <div className="text-white p-6 w-[300px]  bg-black">
                                <div className="text-2xl">{`${data?.FirstName} ${data?.LastName}`}</div>
                                <div className="text-xs mt-1">
                                    Member of {data?.parent?.ParentAccountName?.split(" ")[1]} {data?.parent?.ParentAccountName?.split(" ")[2]}
                                </div>
                                <div className="border border-[#1F2937] mt-4"></div>
                                <div className="flex mt-4">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.6669 11.2802V13.2802C14.6677 13.4659 14.6297 13.6497 14.5553 13.8198C14.4809 13.9899 14.3718 14.1426 14.235 14.2681C14.0982 14.3937 13.9367 14.4892 13.7608 14.5487C13.5849 14.6082 13.3985 14.6303 13.2136 14.6136C11.1622 14.3907 9.19161 13.6897 7.46028 12.5669C5.8495 11.5433 4.48384 10.1777 3.46028 8.56689C2.3336 6.8277 1.63244 4.84756 1.41361 2.78689C1.39695 2.60254 1.41886 2.41673 1.47795 2.24131C1.53703 2.06589 1.63199 1.90469 1.75679 1.76797C1.88159 1.63126 2.03348 1.52203 2.20281 1.44724C2.37213 1.37245 2.55517 1.33374 2.74028 1.33356H4.74028C5.06382 1.33038 5.37748 1.44495 5.62279 1.65592C5.8681 1.86689 6.02833 2.15986 6.07361 2.48023C6.15803 3.12027 6.31458 3.74871 6.54028 4.35356C6.62998 4.59218 6.64939 4.8515 6.59622 5.10081C6.54305 5.35012 6.41952 5.57897 6.24028 5.76023L5.39361 6.60689C6.34265 8.27592 7.72458 9.65786 9.39361 10.6069L10.2403 9.76023C10.4215 9.58099 10.6504 9.45746 10.8997 9.40429C11.149 9.35112 11.4083 9.37053 11.6469 9.46023C12.2518 9.68593 12.8802 9.84248 13.5203 9.92689C13.8441 9.97258 14.1399 10.1357 14.3513 10.3852C14.5627 10.6348 14.6751 10.9533 14.6669 11.2802Z"
                                            stroke="#2BB574"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <p className="text-[#2BB574] ml-4">{formatUSPhoneNumber(data?.contact?.Mobile_Phone)}</p>
                                </div>
                                <div className="flex mt-4">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2.66634 2.6665H13.333C14.0663 2.6665 14.6663 3.2665 14.6663 3.99984V11.9998C14.6663 12.7332 14.0663 13.3332 13.333 13.3332H2.66634C1.93301 13.3332 1.33301 12.7332 1.33301 11.9998V3.99984C1.33301 3.2665 1.93301 2.6665 2.66634 2.6665Z"
                                            stroke="white"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M14.6663 4L7.99967 8.66667L1.33301 4"
                                            stroke="white"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <p className="text-[#FFFFFF] ml-4">{data?.contact?.Email_Address}</p>
                                </div>
                                <div className="flex">
                                    <button onClick={goToClientProfile} className="bg-[#4871B7] text-white text-sm p-4 mt-4">Client Profile</button>
                                    <button className="bg-[#4871B7] text-white text-sm p-4 ml-2 mt-4">Agent Profile</button>
                                </div>
                            </div>
                        </Tooltip>
                    </td>
                ) : (
                    <td
                        className="w-[180px]  truncate  whitespace-nowrap font-inter  cursor-pointer"
                        data-tooltip-id={index === 0 ? undefined : `account-${index}`}
                        data-tooltip-delay-hide={0}

                    >
                        <div className="flex">
                            <span className="text-[#4871B7] underline"
                                onClick={() => {
                                    goToClientProfile(data?.AccountID)
                                }}
                            >{`${data?.FirstName} ${data?.LastName}`}</span>

                            {/* <span className="ml-4 text-[#F3F4F6] " onClick={() => {
                                goToAgent(data?.AccountID)
                            }} >AGENT PROFILE </span> */}
                        </div>

                        <Tooltip
                            id={index === 0 ? undefined : `account-${index}`}
                            place="top"
                            effect="solid"
                            backgroundColor="#111827"
                            textColor="#FFFFFF"
                            className="custom-tooltip z-index"
                        >
                            <div className="text-white p-6 w-[300px] bg-[#111827]">
                                <div className="text-2xl">{`${data?.FirstName} ${data?.LastName}`}</div>
                                <div className="text-xs mt-1">
                                    Member of {data?.parent?.ParentAccountName?.split(" ")[1]} {data?.parent?.ParentAccountName?.split(" ")[2]}
                                </div>
                                <div className="border border-[#1F2937] mt-4"></div>
                                <div className="flex mt-4">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.6669 11.2802V13.2802C14.6677 13.4659 14.6297 13.6497 14.5553 13.8198C14.4809 13.9899 14.3718 14.1426 14.235 14.2681C14.0982 14.3937 13.9367 14.4892 13.7608 14.5487C13.5849 14.6082 13.3985 14.6303 13.2136 14.6136C11.1622 14.3907 9.19161 13.6897 7.46028 12.5669C5.8495 11.5433 4.48384 10.1777 3.46028 8.56689C2.3336 6.8277 1.63244 4.84756 1.41361 2.78689C1.39695 2.60254 1.41886 2.41673 1.47795 2.24131C1.53703 2.06589 1.63199 1.90469 1.75679 1.76797C1.88159 1.63126 2.03348 1.52203 2.20281 1.44724C2.37213 1.37245 2.55517 1.33374 2.74028 1.33356H4.74028C5.06382 1.33038 5.37748 1.44495 5.62279 1.65592C5.8681 1.86689 6.02833 2.15986 6.07361 2.48023C6.15803 3.12027 6.31458 3.74871 6.54028 4.35356C6.62998 4.59218 6.64939 4.8515 6.59622 5.10081C6.54305 5.35012 6.41952 5.57897 6.24028 5.76023L5.39361 6.60689C6.34265 8.27592 7.72458 9.65786 9.39361 10.6069L10.2403 9.76023C10.4215 9.58099 10.6504 9.45746 10.8997 9.40429C11.149 9.35112 11.4083 9.37053 11.6469 9.46023C12.2518 9.68593 12.8802 9.84248 13.5203 9.92689C13.8441 9.97258 14.1399 10.1357 14.3513 10.3852C14.5627 10.6348 14.6751 10.9533 14.6669 11.2802Z"
                                            stroke="#2BB574"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <p className="text-[#2BB574] ml-4">{data?.contact?.Mobile_Phone}</p>
                                </div>
                                <div className="flex mt-4">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2.66634 2.6665H13.333C14.0663 2.6665 14.6663 3.2665 14.6663 3.99984V11.9998C14.6663 12.7332 14.0663 13.3332 13.333 13.3332H2.66634C1.93301 13.3332 1.33301 12.7332 1.33301 11.9998V3.99984C1.33301 3.2665 1.93301 2.6665 2.66634 2.6665Z"
                                            stroke="white"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M14.6663 4L7.99967 8.66667L1.33301 4"
                                            stroke="white"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <p className="text-[#FFFFFF] ml-4">{data?.contact?.Email_Address}</p>
                                </div>
                                <div className="flex">
                                    <button onClick={goToClientProfile} className="bg-[#4871B7] text-white text-sm p-4 mt-4">Client Profile</button>
                                    <button className="bg-[#4871B7] text-white text-sm p-4 ml-2 mt-4">Agent Profile</button>
                                </div>
                            </div>
                        </Tooltip>
                        {/* </td> */}

                    </td>
                )
            }

            <td className="w-[180px] pr-4 truncate whitespace-nowrap font-inter text-[#4871B7] underline cursor-pointer">
                {data?.parent?.ParentAccountName}
            </td>
            {
                index === 0 ? (
                    <td
                        style={{ maxWidth: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        className="w-[180px] truncate  whitespace-normal font-inter text-[#4871B7] underline cursor-pointer"
                        data-tooltip-id={`agent0-${data.index}`}
                        data-tooltip-html={`
                                     <div class="text-white p-6 w-[217px] bg-[#111827]">
                                 <div class="text-2xl" ;">
                                     ${data?.agent?.FirstName + " " + data?.agent?.LastName}
                                 </div>
                                 <div class="text-xs mt-1">
                                     Executive Marketing Director
                                 </div>
                                 <div class="border border-[#1F2937] mt-4">
                                 </div>
                                 <div class="flex mt-4">
                                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M14.6669 11.2802V13.2802C14.6677 13.4659 14.6297 13.6497 14.5553 13.8198C14.4809 13.9899 14.3718 14.1426 14.235 14.2681C14.0982 14.3937 13.9367 14.4892 13.7608 14.5487C13.5849 14.6082 13.3985 14.6303 13.2136 14.6136C11.1622 14.3907 9.19161 13.6897 7.46028 12.5669C5.8495 11.5433 4.48384 10.1777 3.46028 8.56689C2.3336 6.8277 1.63244 4.84756 1.41361 2.78689C1.39695 2.60254 1.41886 2.41673 1.47795 2.24131C1.53703 2.06589 1.63199 1.90469 1.75679 1.76797C1.88159 1.63126 2.03348 1.52203 2.20281 1.44724C2.37213 1.37245 2.55517 1.33374 2.74028 1.33356H4.74028C5.06382 1.33038 5.37748 1.44495 5.62279 1.65592C5.8681 1.86689 6.02833 2.15986 6.07361 2.48023C6.15803 3.12027 6.31458 3.74871 6.54028 4.35356C6.62998 4.59218 6.64939 4.8515 6.59622 5.10081C6.54305 5.35012 6.41952 5.57897 6.24028 5.76023L5.39361 6.60689C6.34265 8.27592 7.72458 9.65786 9.39361 10.6069L10.2403 9.76023C10.4215 9.58099 10.6504 9.45746 10.8997 9.40429C11.149 9.35112 11.4083 9.37053 11.6469 9.46023C12.2518 9.68593 12.8802 9.84248 13.5203 9.92689C13.8441 9.97258 14.1399 10.1357 14.3513 10.3852C14.5627 10.6348 14.6751 10.9533 14.6669 11.2802Z" stroke="#2BB574" stroke-linecap="round" stroke-linejoin="round"/>
                                         </svg>
                                         ${data?.agent?.Mobile ? (
                                <p class="text-[#2BB574] ml-4">${data?.agent?.Mobile} </p>
                            ) : '<p class="text-[#2BB574] ml-4"> (xxx) xxx-xxxx</p>'}
                                         
                                 </div>
                                  <div class="flex mt-4">
                                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M2.66634 2.6665H13.333C14.0663 2.6665 14.6663 3.2665 14.6663 3.99984V11.9998C14.6663 12.7332 14.0663 13.3332 13.333 13.3332H2.66634C1.93301 13.3332 1.33301 12.7332 1.33301 11.9998V3.99984C1.33301 3.2665 1.93301 2.6665 2.66634 2.6665Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                         <path d="M14.6663 4L7.99967 8.66667L1.33301 4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                         </svg>
                                         
                                         <p class="text-[#FFFFFF] ml-4">${data?.agent?.Work_Email} </p>
                
                                 </div>
                                 <div class="flex">
                                 <button class="bg-[#4871B7] text-white text-sm p-4 ml-2 mt-4">Agent  Profile</button>
                                 </div>
                                 </div>
                                 `}
                    >
                        <span>{`${data?.agent?.FirstName + " " + data?.agent?.LastName}`}</span>
                        <Tooltip id={`agent0-${data.index}`} place="top" effect="solid" className="agent-tooltip0" />

                    </td>
                ) : (
                    <td
                        style={{ maxWidth: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        className="w-[180px] truncate  whitespace-normal font-inter text-[#4871B7] underline cursor-pointer"
                        data-tooltip-id={`agent-${data.index}`}
                        data-tooltip-html={`
                                     <div class="text-white p-6 w-[217px] bg-[#111827]">
                                 <div class="text-2xl" ;">
                                     ${data?.agent?.FirstName + " " + data?.agent?.LastName}
                                 </div>
                                 <div class="text-xs mt-1">
                                     Executive Marketing Director
                                 </div>
                                 <div class="border border-[#1F2937] mt-4">
                                 </div>
                                 <div class="flex mt-4">
                                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M14.6669 11.2802V13.2802C14.6677 13.4659 14.6297 13.6497 14.5553 13.8198C14.4809 13.9899 14.3718 14.1426 14.235 14.2681C14.0982 14.3937 13.9367 14.4892 13.7608 14.5487C13.5849 14.6082 13.3985 14.6303 13.2136 14.6136C11.1622 14.3907 9.19161 13.6897 7.46028 12.5669C5.8495 11.5433 4.48384 10.1777 3.46028 8.56689C2.3336 6.8277 1.63244 4.84756 1.41361 2.78689C1.39695 2.60254 1.41886 2.41673 1.47795 2.24131C1.53703 2.06589 1.63199 1.90469 1.75679 1.76797C1.88159 1.63126 2.03348 1.52203 2.20281 1.44724C2.37213 1.37245 2.55517 1.33374 2.74028 1.33356H4.74028C5.06382 1.33038 5.37748 1.44495 5.62279 1.65592C5.8681 1.86689 6.02833 2.15986 6.07361 2.48023C6.15803 3.12027 6.31458 3.74871 6.54028 4.35356C6.62998 4.59218 6.64939 4.8515 6.59622 5.10081C6.54305 5.35012 6.41952 5.57897 6.24028 5.76023L5.39361 6.60689C6.34265 8.27592 7.72458 9.65786 9.39361 10.6069L10.2403 9.76023C10.4215 9.58099 10.6504 9.45746 10.8997 9.40429C11.149 9.35112 11.4083 9.37053 11.6469 9.46023C12.2518 9.68593 12.8802 9.84248 13.5203 9.92689C13.8441 9.97258 14.1399 10.1357 14.3513 10.3852C14.5627 10.6348 14.6751 10.9533 14.6669 11.2802Z" stroke="#2BB574" stroke-linecap="round" stroke-linejoin="round"/>
                                         </svg>
                                         ${data?.agent?.Mobile ? (
                                <p class="text-[#2BB574] ml-4">${data?.agent?.Mobile} </p>
                            ) : '<p class="text-[#2BB574] ml-4"> (xxx) xxx-xxxx</p>'}
                                         
                                 </div>
                                  <div class="flex mt-4">
                                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <path d="M2.66634 2.6665H13.333C14.0663 2.6665 14.6663 3.2665 14.6663 3.99984V11.9998C14.6663 12.7332 14.0663 13.3332 13.333 13.3332H2.66634C1.93301 13.3332 1.33301 12.7332 1.33301 11.9998V3.99984C1.33301 3.2665 1.93301 2.6665 2.66634 2.6665Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                         <path d="M14.6663 4L7.99967 8.66667L1.33301 4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                         </svg>
                                         
                                         ${data?.agent?.WorkEmail ? (
                                <p class="text-[#FFFFFF] ml-4">${data?.agent?.WorkEmail} </p>
                            ) : `<p class="text-[#FFFFFF] ml-4">${data?.agent?.FirstName?.slice(0, 1)}***@example.com </p>`}
                                        
    
                                 </div>
                                 <div class="flex">
                                 <button class="bg-[#4871B7] text-white text-sm p-4 ml-2 mt-4">Agent  Profile</button>
                                 </div>
                                 </div>
                                 `}
                    >
                        <span>{`${data?.agent?.FirstName + " " + data?.agent?.LastName}`}</span>
                        <Tooltip id={`agent-${data.index}`} place="top" effect="solid" className="agent-tooltip" />

                    </td>
                )
            }

            <td className="w-[180px]  truncate py-3 pr-4 whitespace-normal font-inter text-[#4871B7] underline cursor-pointer">
                {formatUSPhoneNumber(data?.agent?.Mobile) || "(xxx) xxx-xxxx"}
            </td>
            <td className="w-[100px] truncate py-3 px-[0.4rem] whitespace-normal font-inter text-center">
                {data?.contact?.Home_Street || "-"}
            </td>
            <td className="w-[180px] truncate py-4 pl-[0.2rem]  whitespace-normal font-inter text-center">
                {data?.FinancialProgramInterests || "-"}

            </td>
            <td className="w-[180px] truncate py-4 pl-[0.2rem]  whitespace-normal font-inter">
                {data?.NextInteraction}

            </td>
            <td className="w-[50px] whitespace-nowrap font-inter">
                <div
                    className="flex flex-row justify-end pr-2 gap-2 cursor-pointer"
                >
                    <MoreIcon onClick={handleMoreClick} />
                    {isDropdownOpen && (
                        <>
                            <div className="absolute z-10 w-auto bg-white border rounded shadow-lg mt-4 mr-14 right-0">
                                <ul className="border rounded">
                                    <li
                                        className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                                        onClick={() => handleEditClick(data?.AccountID)}
                                    >
                                        Edit
                                    </li>
                                    <li
                                        className="border-0 rounded px-4 py-2 cursor-pointer hover:bg-green-500 hover:text-white"
                                    >
                                        Delete
                                    </li>

                                </ul>
                            </div>
                        </>
                    )}
                </div>
            </td>

        </tr>
    );
}

export default ClientsRow;
